import { Measurement } from '@/helpers/measurement'

export default {
  getFractionBase: state => {
    if (state.units === Measurement.CM) {
      return 10
    }
    return state.fractionBase
  },
  getPatternsFontName: state => state.patternsOptions || '',
  isBSFMode: state => state.isLoggedIn && document.location.hostname !== 'designer.patternfast.com',
}
