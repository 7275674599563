import { hasProperties } from '@/helpers'

const getCompatibleNodesList = function (allNodes = [], designAttrIds = []) {
  return allNodes.filter(item => {
    // какой-либо необходимый атрибут не найден
    if (item.requiredAttrIds.length > 0) {
      if (!item.requiredAttrIds.every(
        attrId => designAttrIds.includes(attrId))) {
        return false
      }
    }
    if (item.excludedAttrIds.length > 0) {
      if (item.excludedAttrIds.some(attrId => designAttrIds.includes(attrId))) {
        return false
      }
    }
    return true
  })
}

const extractAttributeIdsFromDesignElements = function (designElements) {
  const extractIdsFromAttributesArray = (acc, attribute) => {
    acc.push(attribute.id)
    return acc
  }

  const joinSingleListIds = (acc, element) => {
    let elAttributes = element.attributes.reduce(extractIdsFromAttributesArray,
      [])
    return [...acc, ...elAttributes]
  }

  const joinListsIds = (acc, nodeCode) => {
    let listIds = designElements[nodeCode].reduce(joinSingleListIds, [])
    return [...acc, ...listIds]
  }

  const isUnique = (item, index, self) => (self.indexOf(item) === index)

  // объединяем все атрибуты, присутствующие в дизайне в плоский список
  return Object.keys(designElements)
    .reduce(joinListsIds, [])
    .filter(isUnique)
}

const transformServerDesignResponseToApplication = function (response) {
  let designElements = {}
  response.forEach(item => {
    designElements[item.node.code] = [item.element]
  })

  return designElements
}

const getDesignFabric = function (designElements = undefined) {
  if (designElements === undefined || !Array.isArray(designElements)) {
    return undefined
  }

  return designElements.reduce((acc, item) => {
    if (acc !== undefined) {
      return acc
    }
    if (Array.isArray(item.attributes)) {
      let foundFabricItem = item.attributes
        .find(attr => attr.type === 'fabric_type')
      if (!foundFabricItem) {
        return acc
      }
      if (foundFabricItem.code === 'fabric:woven') {
        acc = 'woven'
      }
      if (foundFabricItem.code.indexOf('fabric:knit') === 0) {
        acc = 'knit'
      }
    }

    return acc
  }, undefined)
}

const getDesignSettingsCompatibleValues = function (designElements, nodes) {
  return nodes
    .filter(node => !!node.settings && !!designElements[node.code])
    .map(node => {
      let elSettings = designElements[node.code]
        .filter(
          el => Array.isArray(el.settingCodes) && el.settingCodes.length > 0)
        .map(el => el.settingCodes)
      if (node.code.includes('front_necks') && !elSettings.length) {
        let settingArray = []
        node.settings.attrs.forEach(attr => {
          settingArray.push(attr.code)
        })
        elSettings.push(settingArray)
      }

      if (elSettings.length === 0) {
        return undefined
      }

      // если нода из designElements содержит больше 1 настраиваемого элемента, берем пересечение значений, которые содержатся в элементах
      elSettings = elSettings.reduce((acc, item) => {
        if (acc === undefined) {
          return item
        }
        acc = acc.filter(settingCode => item.includes(settingCode))
        return acc
      }, undefined)

      let settings = { ...node.settings }
      settings.attrs = settings.attrs
        .filter(item => elSettings.includes(item.code))

      if (settings.attrs.length === 0) {
        return undefined
      }
      return {
        nodeCode: node.code,
        settings,
      }
    })
    .filter(item => item !== undefined)
}

const getNodeSettingsActiveValues = function (
  designElements,
  designSettings = [],
) {
  let result = {}
  designSettings.forEach(item => {
    if (!Array.isArray(designElements[item.nodeCode])) {
      return
    }
    let elements = [...designElements[item.nodeCode]]
    result[item.settings.attrType] = elements.reduce((acc, designElem) => {
      const attr = designElem.attributes.find(attr => (
        attr.type === item.settings.attrType
      ))
      if (attr) {
        acc = attr.code
      }
      return acc
    }, undefined)
  })
  return result
}

const getNodeAttributeByCode = function (nodes, attrCode) {
  let attr
  nodes.find(node => {
    if (Array.isArray(node.settings?.attrs)) {
      attr = node.settings.attrs.find(attr => attr.code === attrCode)
      if (attr) {
        if (!Array.isArray(node.settings.attrType)) {
          attr.type = node.settings.attrType
        } else {
          const type = node.settings.attrType.find(t => t.indexOf(attrCode.split(':')[0]) === 0)
          if (type) {
            attr.type = type
          }
        }
        return true
      }
    }
    return false
  })
  return attr
}

const getElementsCompatibleWithCode = function (designElements, attrCode, nodes) {
  let list = []
  Object.keys(designElements)
    .forEach(nodeCode => {
      designElements[nodeCode].forEach(elem => {
        if (Array.isArray(elem.settingCodes) &&
          elem.settingCodes.includes(attrCode)) {
          elem.nodeCode = nodeCode
          list.push(elem)
        } else if (attrCode.includes('neckline_facing')) {
          const nodeElement = nodes.find(el => el.code === nodeCode)
          const dressFrontNeckParams = designElements?.['wm_dr_silhouettes']?.[0]?.params
          if (
            nodeElement.settings &&
            nodeElement.settings.attrs?.find(attr => attr.code === attrCode) &&
            dressFrontNeckParams?.[16] !== 1
          ) {
            elem.nodeCode = nodeCode
            list.push(elem)
          }
        }
      })
    })
  return list
}

const designOptionsValidator = function (designOptions) {
  return hasProperties(designOptions, [
    'elementIds',
    'mannequinId',
    'seamAllowance',
    'fit',
    'fabricType',
    'designCategory',
    'eases',
    'settingCodes',
  ]) &&
    Array.isArray(designOptions.settingCodes) &&
    Array.isArray(designOptions.elementIds)
}

export {
  getCompatibleNodesList,
  extractAttributeIdsFromDesignElements,
  transformServerDesignResponseToApplication,
  getDesignFabric,
  getDesignSettingsCompatibleValues,
  getNodeSettingsActiveValues,
  getNodeAttributeByCode,
  getElementsCompatibleWithCode,
  designOptionsValidator,
}
