<template>
  <tr class="EaseSelectOption">
    <td>
      <div class="row">
        <span class="col avenir-font-bold">
          {{ name }}
        </span>
      </div>
    </td>
    <td colspan="3">
      <q-select
        class="label_mobile avenir-font-regular"
        dense
        outlined
        v-model="valueOption"
        :behavior="$q.screen.lt.sm ? 'dialog' : 'menu'"
        :options="options"
        :label="name"
        dropdown-icon='expand_more'
      />
    </td>
  </tr>
</template>

<script>

export default {
  name: 'EaseSelectOption',

  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
    },
  },

  computed: {
    valueOption: {
      get () {
        return this.options.find(item => item.value === this.value)
      },
      set (value) {
        this.$emit('input', value.value)
      },
    },
  },
}
</script>

<style lang="scss">
.label_mobile .q-field__control-container {
  padding-top: 0 !important;
  .q-field__native {
    font-size: 12px !important;
    line-height: 18px !important;
    min-height: 40px !important;
    padding: 6px 0;
  }
  .q-field__label {
    display: none;
  }
}
.EaseSelectOption td {
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
    width: 60%;
  }
}
</style>
