const MANNEQUIN_TYPES = Object.freeze({
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  GIRL: 'GIRL',
  BOY: 'BOY',
  "BOY-TEEN": 'BOY-TEEN',
  "GIRL-TEEN": 'GIRL-TEEN',
})

const validateMannequinType = (type, throwError = true) => {
  let result = Object.values(MANNEQUIN_TYPES).includes(type)
  if (throwError && !result) {
    throw Error('Invalid mannequinType')
  }
  return result
}

export {
  MANNEQUIN_TYPES,
  validateMannequinType,
}
