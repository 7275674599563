<template>
  <layout-base
    ref="layout"
    no-left-sidebar
    no-right-sidebar
  >
    <q-page class="PageSizeRun row">
      <div class="loading" v-if="!items.length">
        Loading...
      </div>
      <div v-else>
        <div class="counter">Ready {{ items.length }} of {{ sizes.length  }}</div>
        <div class="items">
          <div class="item" :class="{error: item.error}" v-for="item in items" :key="item.name">
            <div class="name">{{ item.name }}</div>
            <div class="uid">{{ item.uid }}</div>
            <div v-if="!item.error" class="svg" v-html="item.svg"></div>
            <div v-else>{{ item.error }}</div>
          </div>
        </div>
      </div>
    </q-page>
  </layout-base>
</template>

<script>
import LayoutBase from '@/layouts/LayoutBase'
import {
  fetchStandartSizes,
  fetchPatternsPreview,
} from '@/api'

export default {
  name: 'PageSizeRun',

  components: {
    LayoutBase,
  },

  data: () => ({
    items: [],
    itemsLoaded: false,
    sizes: [],
  }),

  async mounted () {
    let sizes = await fetchStandartSizes(true)
    sizes = sizes.filter(s => s.type === this.$route.query.type)
    if (this.$route.query.sizes) {
      let needSizes = this.$route.query.sizes.split(',').map(s => s.toLowerCase())
      sizes = sizes.filter(s => needSizes.includes(s.size.toLowerCase()))
    }
    this.sizes = sizes
    window.pageSizeRun = this
    const rp = JSON.parse(this.$route.query.params)
    if (!Object.values(rp).length) {
      alert('No params passed')
    }
    const getInt = val => {
      const m = val.match(/\d+/)
      return m ? +m[0] : 0
    }
    sizes.sort((a, b) => {
      let aInt = getInt(a.size)
      let bInt = getInt(b.size)
      if (aInt === bInt) {
        return 0
      } else {
        return aInt > bInt ? 1 : -1
      }
    })
    for (let i in sizes) {
      const size = sizes[i]
      let ease = {}
      let fit = rp.fit
      let fabricType = rp.fabricType
      if (rp.mannequinEase?.[size.mannequinId]) {
        ease = rp.mannequinEase[size.mannequinId].ease
        fit = rp.mannequinEase[size.mannequinId].fit
        fabricType = rp.mannequinEase[size.mannequinId].fabricType
      }
      try {
        let svg = await fetchPatternsPreview(
          rp.internalLines,
          rp.elementIds,
          size.mannequinId,
          fabricType,
          fit,
          rp.seamAllowance,
          ease,
          rp.settingCodes,
          rp.fontName,
          true,
          false,
          true,
          size,
        )
        this.items.push({
          svg,
          name: size.size,
          uid: size.apiUid,
        })
      } catch (e) {
        console.log(e)
        let errorText = ''
        if (e.response?.data?.errorMessage) {
          let message = e.response.data.errorMessage
          const m = message.match(/Incomplete parametrization.+/)
          if (m && m?.length > 0) {
            errorText = m[0]
          } else {
            errorText = message
          }
          if (errorText.indexOf('resulted in a `400 Bad Request` response:') > -1) {
            errorText = errorText.split('resulted in a `400 Bad Request` response:')[1]
          }
          if (errorText.indexOf('Stack trace') > -1) {
            errorText = errorText.split('Stack trace')[0]
          }
          console.error(size.size, message, e.response?.data?.stackTrace || '')
        }
        this.items.push({
          svg: '',
          name: size.size,
          uid: size.apiUid,
          error: errorText || JSON.stringify(e?.response?.data),
        })
      }
    }
  },

}
</script>

<style lang="scss">
  .PageSizeRun {
    display: flex;

    &__logo {
      display: flex;
    }
    .counter {
      font-size: 1.5em;
      padding: 20px 20px 0 20px;
    }
    .items {
      padding: 20px;
      .item {
        padding: 15px 0;
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }
        &.error {
          color: red;
        }
        .name {
          margin-bottom: 10px;
          font-size: 1.5em;
          font-weight: bold;
        }
        .uid {
          margin-bottom: 30px;
        }
      }
    }
    .loading {
      text-align: center;
      width: 100%;
      font-size: 2em;
      margin-top: 50px;
    }
  }
</style>
