<template>
  <div class="size-chart">
    <div class="avenir-font-bold size-chart__title">{{mannequinName}} Size Chart</div>
    <units-toggle-component
      class="col flex justify-end avenir-font-bold q-pb-md"
    />
    <table class="size-chart-style" style="display: flex;">
      <tbody>
        <tr
          class="size-chart-style avenir-font-regular"
          style="z-index:1"
        >
          <th>{{units === 'in' ? 'INCHES' : 'CENTIMETERS'}} ({{mannequinName}})</th>
          <th style="font-weight: bold !important;" v-for="point in allPoint" :key="point">{{ point }}</th>
        </tr>
        <div class="scrollable-block" style="display: flex; overflow-x:scroll; width: 100%;">
        <tr
          class="size-chart-style avenir-font-regular"
          v-for="(size, val) in sizes[type]"
          :key="val"
        >
          <td v-for="(item, index) in size" :key="index">{{ makeCm(item) }}</td>
        </tr>
        </div>
      </tbody>
    </table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import UnitsToggleComponent from '@/components/Units/UnitsToggleComponent'
import { Measurement } from '@/helpers/measurement'

export default {
  components: {
    UnitsToggleComponent,
  },
  props: {
    mannequinType: {
      type: String,
      required: true,
    },
  },
  methods: {
    makeCm (val) {
      if (this.units === 'in' || typeof val === 'number') return val
      if (val.includes('ft')) {
        const valArray = val.split(' ')
        let sum = (Number(valArray[0]) * 30.48) + (val.includes('in') ? Number(valArray[2]) * 2.54 : 0)
        return sum.toFixed(1)
      }
      const valCm = Measurement.inToCm(Measurement.fractionsToDecimals(val)).toFixed(1)
      if (!isNaN(valCm)) return valCm
      else return val
    },
  },
  data: () => ({
    sizes: {
      FEMALE: [
        [0, '5 ft 6 in', '13 7/8', '32 1/2', '27 1/8', '26', '35 1/2', '20 5/8', '13', '10 7/8', '5 7/8', '4 1/2', '13 1/2', '20 3/8', '16 1/4', '13', 'A', 'Average'],
        [2, '5 ft 6 in', '14', '33 1/2', '28', '27', '36 1/2', '21 1/4', '13 1/4', '11 1/8', '6', '4 5/8', '13 5/8', '20 5/8', '16 1/4', '13 1/4', 'A', 'Average'],
        [4, '5 ft 6 in', '14 1/8', '34 1/2', '29', '28', '37 1/2', '21 7/8', '13 1/2', '11 3/8', '6 1/8', '4 3/4', '13 3/4', '21', '16 1/4', '13 1/2', 'A', 'Average'],
        [6, '5 ft 6 in', '14 1/4', '35 1/2', '29 3/4', '29', '38 1/2', '22 1/2', '13 3/4', '11 5/8', '6 1/4', '4 7/8', '14', '21 1/4', '16 1/4', '13 3/4', 'A', 'Average'],
        [8, '5 ft 6 in', '14 1/2', '36 1/2', '30 5/8', '30', '39 1/2', '23 1/8', '14', '12', '6 3/8', '5', '14 1/4', '21 1/2', '16 1/4', '14', 'B', 'Average'],
        [10, '5 ft 6 in', '14 3/4', '37 1/2', '31 1/2', '31', '40 1/2', '23 3/4', '14 1/2', '12 1/2', '6 1/2', '5 1/8', '14 1/2', '21 7/8', '16 1/4', '14 1/4', 'B', 'Average'],
        [12, '5 ft 6 in', '15', '39', '32 7/8', '32 1/2', '42', '24 1/2', '15', '13 1/8', '6 5/8', '5 1/8', '14 3/4', '22 1/4', '16 3/8', '14 1/2', 'B', 'Average'],
        [14, '5 ft 6 in', '15 1/4', '41', '34 3/8', '34 1/2', '43 3/4', '25 1/2', '15 1/2', '13 3/4', '6 3/4', '5 1/4', '15 1/4', '22 1/2', '16 3/8', '14 3/4', 'B', 'Average'],
        [16, '5 ft 6 in', '15 5/8', '43', '36', '34 3/4', '45 1/2', '26 1/2', '16 1/8', '14 3/8', '6 7/8', '5 1/4', '15 3/4', '22 7/8', '16 3/8', '15 1/8', 'C', 'Average'],
        [18, '5 ft 6 in', '16', '45', '37 3/4', '39', '47 1/2', '27 5/8', '16 3/4', '15', '7', '5 3/8', '16 1/4', '23 1/4', '16 1/2', '15 1/2', 'C', 'Average'],
        [20, '5 ft 6 in', '16 3/8', '47', '39 1/2', '41 1/4', '49 1/2', '28 3/4', '17 3/8', '15 5/8', '7 1/8', '5 3/8', '16 3/4', '23 5/8', '16 1/2', '15 7/8', 'C', 'Average'],
        [22, '5 ft 6 in', '16 3/4', '49', '41 1/4', '43 1/2', '51 1/2', '29 7/8', '18', '16 1/4', '7 1/4', '5 1/2', '17 1/4', '24', '16 1/2', '16 1/4', 'C', 'Average'],
        [24, '5 ft 6 in', '17 1/8', '51', '44 7/8', '45 1/2', '53 1/2', '30 7/8', '19', '16 3/4', '7 1/2', '5 5/8', '17 3/4', '24 3/8', '16 1/2', '16 5/8', 'D', 'Average'],
        [26, '5 ft 6 in', '17 1/2', '53', '46 3/4', '47 1/2', '55 1/2', '31 7/8', '19 5/8', '17 3/8', '7 3/4', '5 5/8', '18 1/4', '24 7/8', '16 1/2', '17', 'D', 'Average'],
        [28, '5 ft 6 in', '17 7/8', '55', '48 5/8', '49 1/2', '57 1/2', '32 7/8', '20 1/8', '18', '8', '5 3/4', '18 3/4', '25 3/8', '16 1/2', '17 3/8', 'D', 'Average'],
        [30, '5 ft 6 in', '18 1/4', '57', '50 5/8', '51 1/2', '59 1/2', '33 7/8', '21', '18 5/8', '8 1/4', '5 7/8', '19 1/4', '25 7/8', '16 5/8', '17 3/4', 'D', 'Curvy'],
        [32, '5 ft 6 in', '18 5/8', '59', '52 3/8', '53 1/2', '61 1/2', '34 7/8', '21 1/2', '19 1/4', '8 1/2', '5 7/8', '19 5/8', '26 3/8', '16 5/8', '18 1/8', 'E', 'Curvy'],
        [34, '5 ft 6 in', '19', '61', '54 1/4', '55 1/2', '63 1/2', '36', '22', '19 7/8', '8 3/4', '6', '20', '26 7/8', '16 5/8', '18 5/8', 'E', 'Curvy'],
        [36, '5 ft 6 in', '19 3/8', '63', '56', '57 1/2', '65 1/2', '37', '22 3/4', '20 1/2', '9', '6 1/8', '20 3/8', '27 3/8', '16 5/8', '19 1/8', 'E', 'Curvy'],
        [38, '5 ft 6 in', '19 3/4', '65', '58', '59 1/2', '67 1/2', '38', '23 1/2', '21', '9 1/4', '6 1/4', '20 3/4', '27 7/8', '16 5/8', '19 5/8', 'E', 'Curvy'],
      ],
      MALE: [
        [35, '5 ft 11 in', '15 5/8', '14 7/8', '35', '29 1/2', '34 1/2', '20 3/4', '13 5/8', '11 3/4', '6 3/4', '5 3/4', '17 5/8', '14 1/2', 'A', 'Average'],
        [36, '5 ft 11 in', '16', '15 1/4', '36', '30 1/2', '35 1/2', '21 1/4', '13 3/4', '12', '6 3/4', '5 3/4', '17 3/4', '14 7/8', 'A', 'Average'],
        [37, '5 ft 11 in', '16 1/4', '15 1/2', '37', '31 1/2', '36 1/2', '21 3/4', '14', '12 1/4', '6 7/8', '5 7/8', '17 3/4', '15 2/8', 'A', 'Average'],
        [38, '5 ft 11 in', '16 5/8', '15 7/8', '38', '32 1/2', '37 1/2', '22 1/4', '14 3/8', '12 1/2', '7', '6', '17 3/4', '15 1/2', 'A', 'Average'],
        [39, '5 ft 11 in', '16 7/8', '16 1/8', '39', '33 1/2', '38 1/2', '22 3/4', '14 1/2', '12 3/4', '7 1/8', '6 1/8', '17 3/4', '15 7/8', 'B', 'Average'],
        [40, '5 ft 11 in', '17 1/4', '16 1/2', '40', '34 1/2', '39 1/2', '23 1/4', '14 7/8', '13', '7 1/4', '6 1/4', '17 7/8', '16 1/4', 'B', 'Average'],
        [42, '5 ft 11 in', '17 3/4', '17 1/8', '42', '36 3/4', '41 1/2', '24 1/4', '15 1/4', '13 1/2', '7 1/2', '6 1/2', '17 3/4', '16 3/4', 'B', 'Average'],
        [44, '5 ft 11 in', '18 1/8', '17 5/8', '44', '39', '43 1/4', '25', '15 7/8', '14 1/8', '7 1/2', '6 5/8', '17 3/4', '17 1/4', 'B', 'Average'],
        [46, '5 ft 11 in', '18 1/2', '18 1/8', '46', '41 1/4', '45', '25 3/4', '16 1/2', '14 3/4', '7 3/4', '6 3/8', '17 7/8', '17 5/8', 'B', 'Average'],
        [48, '5 ft 11 in', '18 7/8', '18 5/8', '48', '43 1/2', '46 3/4', '26 1/2', '17 1/8', '15 3/8', '7 7/8', '6 5/8', '17 7/8', '18 1/8', 'B', 'Average'],
        [50, '5 ft 11 in', '19 1/4', '19 1/8', '50', '45 3/4', '48 1/2', '27 1/4', '17 5/8', '16', '8', '6 7/8', '17 7/8', '18 3/4', 'C', 'Average'],
        [52, '5 ft 11 in', '19 5/8', '19 5/8', '52', '48', '50 1/2', '28', '18 3/8', '16 5/8', '8 1/8', '7 1/8', '17 7/8', '19 1/2', 'C', 'Average'],
        [56, '5 ft 11 in', '20 1/2', '20 5/8', '56', '52 1/2', '54 1/2', '29 1/2', '19 1/2', '17 7/8', '8 3/8', '7 3/8', '18', '20 1/2', 'D', 'Average'],
        [60, '5 ft 11 in', '21 3/8', '21 5/8', '60', '57', '58 1/2', '31', '20 3/4', '19 1/8', '8 5/8', '7 5/8', '18 1/4', '21 1/2', 'D', 'Average'],
        [64, '5 ft 11 in', '22 1/4', '22 5/8', '64', '61 1/2', '62 1/2', '32 1/2', '22', '20 3/8', '8 7/8', '7 7/8', '18 3/8', '22 1/2', 'E', 'Average'],
        [68, '5 ft 11 in', '23 1/4', '23 5/8', '68', '66', '66 1/2', '34', '23 3/8', '21 5/8', '9 1/8', '8 1/8', '18 5/8', '23 3/8', 'E', 'Average'],
      ],
      GIRL: [
        [2, '3 ft', '10 1/2', '21', '19 1/4', '20 3/4', '12', '8 3/4', '6', '6 1/2', '4 1/2', '9 1/2', '9'],
        [3, '3 ft 3 in', '10 3/4', '21 1/2', '19 7/8', '21 1/2', '12 1/2', '9 1/4', '6 1/4', '6 5/8', '4 5/8', '10', '9 5/8'],
        [4, '3 ft 6 in', '11 1/8', '22 1/4', '20 1/2', '22 5/8', '13 1/4', '9 3/4', '6 1/2', '6 7/8', '4 7/8', '10 5/8', '10 1/4'],
        [5, '3 ft 9 in', '11 1/2', '23', '21 1/4', '23 3/4', '14', '10 1/4', '6 3/4', '7 1/8', '5', '11 1/8', '10 7/8'],
        [6, '4 ft', '11 7/8', '23 3/4', '22', '22 7/8', '14 7/8', '10 5/8', '7', '7 3/8', '5 1/8', '11 3/4', '11 1/4'],
        [7, '4 ft 2 in', '12 1/4', '25', '22 3/4', '26 1/4', '15 3/4', '10 5/8', '7 1/4', '7 7/8', '4 7/8', '12 3/8', '11 5/8'],
        [8, '4 ft 4 in', '12 5/8', '27', '24', '28', '16 3/4', '11 1/4', '7 5/8', '8 1/4', '5 1/8', '12 7/8', '11 7/8'],
        [10, '4 ft 9 in', '13', '29 1/2', '25 1/2', '31', '18 3/8', '12 3/4', '8 1/8', '9', '5 3/4', '13 1/2', '13'],
        [12, '5 ft 2 in', '13 3/8', '31 1/2', '26 3/4', '33', '19 1/2', '13 1/2', '8 5/8', '9 3/4', '6', '14', '14 1/2'],
        [14, '5 ft 4 in', '13 5/8', '33', '27 1/2', '35', '20 1/2', '13 7/8', '8 3/4', '10 1/4', '6 1/8', '14 1/2', '15 3/8'],
        [16, '5 ft 5 in', '13 7/8', '34 1/2', '28 1/4', '37', '21 1/2', '14 1/8', '8 3/4', '10 3/4', '6 1/8', '15', '15 5/8'],
      ],
      BOY: [
        [2, '3 ft', '10 1/2', '21', '19 1/4', '20 3/4', '12', '8 3/4', '6', '6 1/2', '4 1/2', '9 1/2', '9'],
        [3, '3 ft 3 in', '10 3/4', '21 1/2', '19 7/8', '21 1/2', '12 1/2', '9 1/4', '6 1/4', '6 5/8', '4 5/8', '10', '9 5/8'],
        [4, '3 ft 6 in', '11 1/8', '22 1/4', '20 1/2', '22 5/8', '13 1/4', '9 3/4', '6 1/2', '6 7/8', '4 7/8', '10 5/8', '10 1/4'],
        [5, '3 ft 9 in', '11 1/2', '23', '21 1/4', '23 3/4', '14', '10 1/4', '6 3/4', '7 1/8', '5', '11 1/8', '10 7/8'],
        [6, '4 ft', '11 7/8', '23 3/4', '22', '22 7/8', '14 7/8', '10 5/8', '7', '7 3/8', '5 1/8', '11 3/4', '11 1/4'],
        [7, '4 ft 2 in', '12 1/4', '24 3/4', '23', '26 1/4', '15 7/8', '11 3/8', '7 3/8', '7 7/8', '5 3/8', '12 3/4', '11 1/2'],
        [8, '4 ft 4 in', '12 5/8', '27', '25', '28 3/4', '17 1/8', '12 1/8', '7 7/8', '8 1/4', '5 1/2', '13 1/8', '12 1/8'],
        [10, '4 ft 9 in', '13', '29', '26 3/4', '30 3/4', '18 3/8', '13 1/8', '8 3/8', '9', '6', '14', '12 3/4'],
        [12, '5 ft 1 in', '13 3/8', '31 1/2', '28 1/2', '32 1/2', '19 1/2', '13 5/8', '9', '9 7/8', '6 1/2', '15', '13 1/2'],
        [14, '5 ft 8 in', '14 3/8', '33 1/2', '30', '34 3/4', '20 1/2', '14 3/8', '9 1/8', '10 1/2', '6 5/8', '15 1/2', '15 1/4'],
        [16, '5 ft 10 in', '15 3/8', '35 1/2', '31 1/2', '37', '21 1/2', '15', '9 5/8', '11 1/2', '6 7/8', '16 1/8', '17'],
      ],
    },
  }),
  computed: {
    ...mapState([
      'units',
    ]),
    allPoint () {
      let array = null
      switch (this.type) {
        case 'FEMALE':
          array = [
            `Height`,
            "Neck circumference",
            "Bust/Chest circumference",
            "Under bust circumference",
            "Waist circumference",
            "Hips circumference",
            "Thigh circumference",
            "Knee circumference",
            "Upper arm circumference",
            "Wrist circumference",
            "Shoulder width",
            "Bust height",
            "Front length",
            "Back length",
            "Back width",
            "Belly protuberance",
            "Buttocks",
          ]
          break
        case 'MALE':
          array = [
            `Height`,
            "Shoulder diameter",
            "Neck circumference",
            "Bust/Chest circumference",
            "Waist circumference",
            "Hips circumference",
            "Thigh circumference",
            "Knee circumference",
            "Upper arm circumference",
            "Wrist circumference",
            "Shoulder width",
            "Back length",
            "Back width",
            "Belly protuberance",
            "Buttocks shape",
          ]
          break
        case 'GIRL':
          array = [
            `Height`,
            "Neck circumference",
            "Bust/Chest circumference",
            "Waist circumference",
            "Hips circumference",
            "Thigh circumference",
            "Knee circumference",
            "Ankle circumference",
            "Upper arm circumference",
            "Wrist circumference",
            "Shoulder diameter",
            "Back length",
          ]
          break
        case 'BOY':
          return [
            `Height`,
            "Neck circumference",
            "Bust/Chest circumference",
            "Waist circumference",
            "Hips circumference",
            "Thigh circumference",
            "Knee circumference",
            "Ankle circumference",
            "Upper arm circumference",
            "Wrist circumference",
            "Shoulder diameter",
            "Back length",
          ]
      }
      return array
    },
    mannequinName () {
      if (this.mannequinType.includes('FEMALE')) return 'Women’s'
      if (this.mannequinType.includes('MALE')) return 'Men’s'
      if (this.mannequinType.includes('GIRL')) return 'Girl’s'
      if (this.mannequinType.includes('BOY')) return 'Boy’s'
      return ''
    },
    type () {
      if (this.mannequinType.includes('GIRL')) return 'GIRL'
      if (this.mannequinType.includes('BOY')) return 'BOY'
      return this.mannequinType
    },
  },
}
</script>

<style lang="scss">
.SizeChart {
  .DialogCardBase__closeButton {
    right: 16px;
    top: 16px;
  }
}
.size-chart {
  &__title {
    margin: -16px -16px 16px;
    padding: 16px 32px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.22;
    text-align: center;
  }

  table.size-chart-style {
    width: auto;
    height: 600px;
    display: flex;
    align-items: center;
    border: 0;
    tbody {
      display: flex;
      position: relative;
      align-items: stretch;
      width: 100%;
    }
  }
  .scrollable-block {
    position: absolute;
    padding-left: 230px;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: black;
    }
    &::-webkit-scrollbar-track {
      background: #aaaaab;
    }
  }
  .size-chart-style {
    border: solid 1px #d7d2cb;
    border-collapse: collapse;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    th, td {
      flex-basis: 100%;
      text-align: left;
      display: flex;
      &:nth-child(1) {
        background: black;
        color: #fff;
        justify-content: center;
        font-weight: 600;
      }
    }
    th {
      background: white;
      font-size: 13px;
      color: #1d1f2a;
      text-align: center;
      padding: 5px 14px;
      border-bottom: solid 1px #d7d2cb;
      font-weight: 500;
      &:nth-child(1) {
        justify-content: center;
      }
    }
    td {
      background: #fff;
      font-size: 13px;
      color: #1d1f2a;
      text-align: center;
      padding: 5px 14px;
      border-bottom: solid 1px #d7d2cb;
    }
    &:nth-child(1) th {
      width: 230px;
    }
  }
  tr.size-chart-style {
    &:nth-child(1) {
      width: 230px;
    }
    &:nth-child(2) {
      td {
        width: 75px;
      }
    }
    td:nth-child(2) {
      width: 95px;
    }
  }
}
</style>
