<template>
  <div class="user-top-panel">
    <div class="left-side">
      <slot name="left-side"></slot>
    </div>
    <div class="right-side">
      <div class="search-field">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M8.334 2.25c-3.627 0-6.584 2.957-6.584 6.584s2.957 6.582 6.584 6.582a6.547 6.547 0 0 0 4.088-1.432l4.547 4.547a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.062l-4.547-4.547a6.547 6.547 0 0 0 1.432-4.088c0-3.627-2.955-6.584-6.582-6.584zm0 1.5a5.071 5.071 0 0 1 5.082 5.084 5.07 5.07 0 0 1-5.082 5.082A5.071 5.071 0 0 1 3.25 8.834 5.073 5.073 0 0 1 8.334 3.75z"/>
        </svg>
        <input type="text" placeholder="Search" v-model="search" @keypress="doSearch">
      </div>
      <ButtonSort @sort-order="sortOrder" />
      <div class="sort-field" v-if="sortOptions.length > 0">
        <ButtonSecondary
          class="small"
          :unfolded="sortDropdown"
          @click.native="sortDropdown = !sortDropdown"
        >
          {{ sort }}
        </ButtonSecondary>
        <ul v-if="sortDropdown" v-click-outside="() => sortDropdown = false">
          <template v-for="option in sortOptions">
            <li
              :key="option.value"
              v-if="option.value !== ''"
              :class="[sort === option.name ? 'selected' : '']"
              @click="sortOption(option)"
            >
              {{ option.name }}
            </li>
          </template>
        </ul>
      </div>
      <ButtonPrimary
        class="small add-desktop"
        @click.native="addNewButtonDisabled ? null : $emit('create-new')"
        v-if="addNewButton"
        :disabled="addNewButtonDisabled"
      >
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
            <path d="M10 4.75a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25v4.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5.5a.75.75 0 0 0-.75-.75z"/>
          </svg>
        </template>
        <slot name="button-text">Add new</slot>
      </ButtonPrimary>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ButtonSort from '@/components/Atoms/ButtonSort.vue'

export default {
  name: 'TopPanel',
  components: {
    ButtonPrimary,
    ButtonSecondary,
    ButtonSort,
  },
  props: {
    sortOptions: {
      type: Array,
      default: () => [],
    },
    addNewButton: {
      type: Boolean,
      default: true,
    },
    addNewButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: '',
    sort: 'Sort by',
    sortDropdown: false,
  }),
  methods: {
    doSearch (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.$emit('search', this.search)
      }
    },
    sortOrder (order) {
      this.$emit('sort-order', order)
    },
    sortOption (option) {
      this.sort = option.name
      this.sortDropdown = false
      this.$emit('sort-option', option.value)
    },
  },
}
</script>

<style lang="scss">
.user-top-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  column-gap: 40px;

  .left-side {
    .btn-group {
      width: auto;
      height: 40px;
      padding: 0 25px;
      box-sizing: border-box;
      border: 1px solid var(--Dividers);
      background: var(--Background);
      color: var(--Text-Text_Secondary);

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right-color: var(--Active-State-Strokes);
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: none;
      }

      &.active {
        border-color: var(--Active-State-Strokes);
        color: var(--Active-State-Strokes);
      }
    }
  }

  .right-side {
    display: flex;
    flex-direction: row;
    position: relative;

    & * {
      border-radius: 8px;
    }

    .search-field {
      margin-right: 8px;
      max-width: 185px;
      svg {
        width: 20px;
        height: 20px;
        fill: var(--primary);
        position: absolute;
        top: 10px;
        left: 12px;
      }
      input {
        width: 100%;
        height: 100%;
        border: 1px solid var(--Dividers);
        background: var(--Background);
        box-sizing: border-box;
        padding-left: 40px;
        padding-right: 12px;
      }
    }

    .button-sort {
      margin-right: 8px;
    }

    .sort-field {
      margin-right: 16px;
      position: relative;

      ul {
        position: absolute;
        left: 0;
        top: 48px;
        z-index: 1;
        min-width: 200px;
        max-height: 160px;
        margin: 0;
        padding: 0;

        border-radius: 8px;
        border: 1px solid var(--Dividers);
        background: var(--Basic-White);
        box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.10);

        li {
          list-style: none;
          color: var(--Text-Text_Primary);
          font-family: "Open Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          padding: 8px 16px;
          cursor: pointer;
          transition: all ease-in-out 300ms;
          border-radius: 0;

          &:hover, &.selected {
            background: var(--Surface-Hover);
            color: var(--Button-States-Hover);
          }
        }
      }
    }
  }
}
@media (max-width: 760px) {
  .user-top-panel {
    flex-direction: column;
    align-items: stretch;

    .left-side {
      margin-bottom: 16px;

      .btn-group {
        width: 50%;
      }
    }

    .search-field {
      max-width: none !important;
      flex-grow: 1;
    }

    .sort-field {
      margin-right: 0 !important;

      ul {
        left: auto !important;
        right: 0;
      }
    }

    .add-desktop {
      display: none;
    }
  }
}
</style>
