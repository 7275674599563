<template>
  <div class="fit-charts-new">
    <button class="close" @click="close()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <path d="M5 4.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L8.937 10 4.47 14.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.062 0L10 11.062l4.469 4.47a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.063L11.062 10l4.47-4.469a.75.75 0 0 0 0-1.062A.75.75 0 0 0 15 4.25a.75.75 0 0 0-.531.219L10 8.937 5.531 4.47A.75.75 0 0 0 5 4.25z"/>
      </svg>
      <span>Exit</span>
    </button>

    <section class="mobile-placeholder">
      <h1>Creating and editing fit charts is not available in the mobile version</h1>
      <p>Please use the desktop version</p>
      <ButtonSecondary @click.native="close()">Okay</ButtonSecondary>
    </section>

    <section class="step step-a" v-if="fitChartsStore[fitChartsStore.dataKey].step === 'a'">
      <span class="step-counter">Step 1 / 3</span>
      <h1>How would you like to create a fit chart?</h1>
      <p>Choose from options below</p>
      <div class="option" @click="fitChartsStore[fitChartsStore.dataKey].step = 'b1'">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="icon">
          <path d="M10 6.25a.75.75 0 0 0-.75.75v1.25H3a.75.75 0 1 0 0 1.5h6.25V11a.75.75 0 1 0 1.5 0V7a.75.75 0 0 0-.75-.75Zm4 2a.75.75 0 1 0 0 1.5h7a.75.75 0 1 0 0-1.5Zm2 4a.75.75 0 0 0-.75.75v1.25H3a.75.75 0 1 0 0 1.5h12.25V17a.75.75 0 1 0 1.5 0v-4a.75.75 0 0 0-.75-.75zm4 2a.75.75 0 1 0 0 1.5h1a.75.75 0 1 0 0-1.5z"/>
        </svg>
        <div class="info">
          <h3>Customize fit chart template</h3>
          <span>Choose any template from our library and<br>customize to your needs</span>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="arrow">
          <path d="M10 7.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L12.938 12l-3.47 3.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.063 0l4-4a.75.75 0 0 0 0-1.062l-4-4A.75.75 0 0 0 10 7.25Z"/>
        </svg>
      </div>
      <div class="option" @click="fitChartsStore[fitChartsStore.dataKey].step = 'b2'">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="icon">
          <path d="M5 3.25c-.958 0-1.75.792-1.75 1.75v14c0 .958.792 1.75 1.75 1.75h8.586c.464 0 .908-.184 1.236-.512l5.416-5.416c.328-.328.512-.772.512-1.236V5c0-.958-.792-1.75-1.75-1.75Zm0 1.5h14a.24.24 0 0 1 .25.25v7.25H14c-.958 0-1.75.792-1.75 1.75v5.25H5a.24.24 0 0 1-.25-.25V5A.24.24 0 0 1 5 4.75Zm9 9h5.186c-.004.004-.005.008-.008.012l-5.416 5.416c-.004.003-.008.004-.012.008V14a.24.24 0 0 1 .25-.25z"/>
        </svg>
        <div class="info">
          <h3>Create from scratch</h3>
          <span>Blank template</span>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="arrow">
          <path d="M10 7.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L12.938 12l-3.47 3.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.063 0l4-4a.75.75 0 0 0 0-1.062l-4-4A.75.75 0 0 0 10 7.25Z"/>
        </svg>
      </div>
    </section>

    <section class="step step-b-1" v-if="fitChartsStore[fitChartsStore.dataKey].step === 'b1'">
      <span class="step-counter">Step 2 / 3</span>
      <h1>Choose a clothing category and template</h1>
      <p>Select for whom you want to sew clothes and the fit chart you would like to customize.</p>
      <ChartEditTypesSelect @change="getFitChartsList" />
      <form class="new-fitchart-name">
        <SelectField
          placeholder="Fit chart template"
          :value="selectedTemplate"
          :options="fitChartsOptions"
          @change="setTemplate"
        />
        <InputField type="text" placeholder="Enter name" v-model="fitChartsStore[fitChartsStore.dataKey].title">
          <template v-slot:top-label>New fit chart name</template>
        </InputField>
      </form>
      <nav>
        <ButtonSecondary @click.native="fitChartsStore[fitChartsStore.dataKey].step = 'a'">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
              <path d="M9.549 5.926a.75.75 0 0 0-.412.21L5.803 9.472a.75.75 0 0 0 0 1.06l3.334 3.332a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L8.145 10.75h6.521a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H8.145l2.052-2.053a.75.75 0 0 0 0-1.06.75.75 0 0 0-.648-.211z"/>
            </svg>
          </template>
          Back
        </ButtonSecondary>
        <ButtonPrimary
          :disabled="nextIsDisabled"
          @click.native="goToFromTemplateForm()"
        >
          Next
        </ButtonPrimary>
      </nav>
    </section>

    <section class="step step-b2" v-if="fitChartsStore[fitChartsStore.dataKey].step === 'b2'">
      <span class="step-counter">Step 2 / 3</span>
      <h1>Choose a clothing category and name fit chart</h1>
      <p>Select for whom you want to sew clothes</p>
      <ButtonsGroup class="clothing-buttons">
        <button
          v-for="t in this.fitChartsStore.types"
          :key="t.key"
          :class="[ t.key === fitChartsStore[fitChartsStore.dataKey].type ? 'active' : '']"
          @click="fitChartsStore[fitChartsStore.dataKey].type = t.key"
        >
          {{ t.title }}
        </button>
      </ButtonsGroup>
      <form class="new-fitchart-name">
        <InputField type="text" placeholder="Enter name" v-model="fitChartsStore[fitChartsStore.dataKey].title" >
          <template v-slot:top-label>Size Charts| Full</template>
        </InputField>
      </form>
      <nav>
        <ButtonSecondary @click.native="fitChartsStore[fitChartsStore.dataKey].step = 'a'">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
              <path d="M9.549 5.926a.75.75 0 0 0-.412.21L5.803 9.472a.75.75 0 0 0 0 1.06l3.334 3.332a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L8.145 10.75h6.521a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H8.145l2.052-2.053a.75.75 0 0 0 0-1.06.75.75 0 0 0-.648-.211z"/>
            </svg>
          </template>
          Back
        </ButtonSecondary>
        <ButtonPrimary
          :disabled="fitChartsStore[fitChartsStore.dataKey].title.length === 0"
          @click.native="goToFromScratchForm()"
        >
          Next
        </ButtonPrimary>
      </nav>
    </section>

    <section class="step step-c1" v-if="fitChartsStore[fitChartsStore.dataKey].step === 'c1'">
      <div class="form-heading">
        <span class="step-counter">Step 3 / 3</span>
        <h1>Customize fit chart</h1>
        <p>You can create, delete and edit measurements to suit your needs</p>
      </div>
      <div style="padding: 0 16px;">
        <div class="top-panel">
          <InputField type="text" placeholder="Enter name" class="name-field" v-model="fitChartsStore[fitChartsStore.dataKey].title" >
            <template v-slot:top-label>Fit chart name</template>
          </InputField>
          <ButtonsGroup class="units-buttons">
            <button :class="[fitChartsStore.unit === 'in' ? 'active': '']" @click="fitChartsStore.unit = 'in'">in</button>
            <button :class="[fitChartsStore.unit === 'cm' ? 'active': '']" @click="fitChartsStore.unit = 'cm'">cm</button>
          </ButtonsGroup>
        </div>
      </div>
      <div class="form-body">
        <ChartEdit />
      </div>
      <nav>
        <ButtonSecondary @click.native="fitChartsStore[fitChartsStore.dataKey].step = 'b1'">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
              <path d="M9.549 5.926a.75.75 0 0 0-.412.21L5.803 9.472a.75.75 0 0 0 0 1.06l3.334 3.332a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L8.145 10.75h6.521a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H8.145l2.052-2.053a.75.75 0 0 0 0-1.06.75.75 0 0 0-.648-.211z"/>
            </svg>
          </template>
          Back
        </ButtonSecondary>
        <ButtonPrimary
          :disabled="!fitChartsStore.sizeChartIsValid"
          @click.native="save()"
        >
          Save chart
        </ButtonPrimary>
      </nav>
    </section>
    <section class="step step-c2" v-if="fitChartsStore[fitChartsStore.dataKey].step === 'c2'">
      <div class="form-heading">
        <span class="step-counter">Step 3 / 3</span>
        <h1>Customize fit chart</h1>
        <p>You can create, delete and edit measurements to suit your needs</p>
      </div>
      <div style="padding: 0 16px;">
        <div class="top-panel">
          <InputField type="text" placeholder="Enter name" class="name-field" v-model="fitChartsStore[fitChartsStore.dataKey].title" >
            <template v-slot:top-label>Fit chart name</template>
          </InputField>
          <ButtonsGroup class="units-buttons">
            <button :class="[fitChartsStore.unit === 'in' ? 'active': '']" @click="fitChartsStore.unit = 'in'">in</button>
            <button :class="[fitChartsStore.unit === 'cm' ? 'active': '']" @click="fitChartsStore.unit = 'cm'">cm</button>
          </ButtonsGroup>
        </div>
      </div>
      <div class="form-body">
        <ChartEdit />
      </div>
      <nav>
        <ButtonSecondary @click.native="fitChartsStore[fitChartsStore.dataKey].step = 'b2'" :disabled="this.$route.params.id !== undefined">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
              <path d="M9.549 5.926a.75.75 0 0 0-.412.21L5.803 9.472a.75.75 0 0 0 0 1.06l3.334 3.332a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L8.145 10.75h6.521a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H8.145l2.052-2.053a.75.75 0 0 0 0-1.06.75.75 0 0 0-.648-.211z"/>
            </svg>
          </template>
          Back
        </ButtonSecondary>
        <ButtonPrimary
          :disabled="!fitChartsStore.sizeChartIsValid"
          @click.native="save()"
        >
          Save chart
        </ButtonPrimary>
      </nav>
    </section>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFitChartsStore } from '../../store/pinia/fitCharts'

import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'
import InputField from '@/components/Atoms/InputField.vue'
import SelectField from '@/components/Atoms/SelectField.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ChartEditTypesSelect from '@/components/fitCharts/ChartEditTypesSelect.vue'
import ChartEdit from '@/components/fitCharts/ChartEdit.vue'

export default {
  name: 'FitChartsNew',
  components: {
    ButtonsGroup,
    InputField,
    SelectField,
    ButtonPrimary,
    ButtonSecondary,
    ChartEditTypesSelect,
    ChartEdit,
  },
  data: () => ({
    selectedTemplate: -1,
  }),
  computed: {
    ...mapStores(useFitChartsStore),
    fitChartsOptions () {
      return this.fitChartsStore.standartCharts.list.map((c, i) => ({
        value: i,
        title: c.name,
      }))
    },
    nextIsDisabled () {
      if (this.selectedTemplate === -1) return true
      if (this.fitChartsStore[this.fitChartsStore.dataKey].title.length === 0) return true
      return false
    },
  },
  methods: {
    close () {
      this.$router.push({ name: 'fitChartsCustom' })
      this.fitChartsStore.$reset()
    },
    goToFromTemplateForm () {
      this.fitChartsStore[this.fitChartsStore.dataKey].step = 'c1'
    },
    goToFromScratchForm () {
      if (this.fitChartsStore[this.fitChartsStore.dataKey].sizesList.length === 0) this.fitChartsStore.addNewSize(0)
      this.fitChartsStore[this.fitChartsStore.dataKey].step = 'c2'
    },
    async save () {
      if (!this.$route.name.includes('Standart')) {
        if (this.$route.params.id !== undefined) await this.fitChartsStore.updateChart(this.$route.params.id)
        else await this.fitChartsStore.saveNewChart()
        this.fitChartsStore.$reset()
        this.$router.push('/user/fit-charts-custom')
      } else {
        if (this.$route.params.id !== undefined) await this.fitChartsStore.updateChartStandart(this.$route.params.id)
        else await this.fitChartsStore.saveNewChartStandart()
        this.fitChartsStore.$reset()
        this.$router.push('/user/fit-charts-standart')
      }
    },
    async getFitChartsList (type = 'FEMALE') {
      this.setTemplate(-1)
      this.fitChartsStore.params.type = type
      await this.fitChartsStore.getListStandart()
    },
    setTemplate (i) {
      this.selectedTemplate = i
      this.fitChartsStore.template = i < 0 ? null : this.fitChartsStore.standartCharts.list[i]
    },
  },
  beforeMount () {
    if (this.$route.params.id !== undefined) {
      this.fitChartsStore.dataKey = 'editChart'
      this.fitChartsStore[this.fitChartsStore.dataKey].step = 'c2'
    } else this.getFitChartsList()
  },
}
</script>

<style lang="scss">
.fit-charts-new {
  background: var(--Background);
  position: fixed;
  left: 0;
  top: 80px;
  width: 100%;
  height: calc(100% - 80px);

  .close {
    position: absolute;
    top: 24px;
    left: 16px;
    background: transparent;
    border: none;

    display: flex;
    align-items: center;
    column-gap: 4px;

    color: var(--Primary-Shades-Primary);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--Primary-Shades-Primary);
    }
  }

  .mobile-placeholder {
    display: none;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    height: 100%;

    .step-counter {
      color: var(--Text-Text_Primary);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 8px;
    }

    h1 {
      color: var(--Text-Text_Primary);
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      max-width: 442px;
      margin: 0;
      margin-bottom: 8px;
    }

    p {
      color: var(--Text-Text_Secondary);
      text-align: center;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      max-width: 442px;
      margin: 0;
      margin-bottom: 32px;
    }

    .clothing-buttons {
      padding: 0 16px;
    }

    .new-fitchart-name {
      flex-grow: 1;
      width: 100%;
      max-width: 442px;

      .input-field {
        margin-bottom: 16px;
      }
    }

    nav {
      width: 100%;
      border-top: 1px solid var(--Dividers);
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 16px;

      button {
        width: 212px;
      }
    }

    &.step-a {

      .option {
        width: 100%;
        max-width: 442px;
        border-radius: 8px;
        border: 1px solid var(--Dividers);
        background: var(--Background);
        margin-bottom: 16px;
        padding: 12px;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        column-gap: 16px;

        .icon {
          width: 24px;
          height: 24px;
          fill: var(--Icons-Icons_Secondary);
        }

        .info {
          flex-grow: 1;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          line-height: 150%;

          h3 {
            color: var(--Text-Text_Primary);
            font-size: 16px;
          }

          span {
            color: var(--Text-Text_Secondary);
            font-size: 14px;
          }
        }

        .arrow {
          width: 24px;
          height: 24px;
          fill: var(--Icons-Icon_Primary);
        }
      }
    }

    &.step-c1, &.step-c2 {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto auto 1fr auto;
      align-items: normal;

      .form-heading {
        text-align: center;

        h1, p {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .top-panel {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 23px;

        .name-field {
          max-width: 322px;
        }
      }

      .form-body {
        overflow: auto;
        padding: 0 16px;
      }
    }
  }
}

@media (max-width: 760px) {
  .fit-charts-new {
    .mobile-placeholder {
      display: block;
      padding: 64px 16px;
      text-align: center;
      font-style: normal;

      h1 {
        color: var(--Text-Text_Primary);
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
      }
      p {
        color: var(--Text-Text_Secondary);
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 32px;
      }

      button {
        width: 175px;
        margin: 0 auto;
      }
    }

    .step {
      display: none !important;
    }
  }
}
</style>
