import { defineStore } from 'pinia'
import { request } from '../../api'

export const useUserCollectionsStore = defineStore('userCollections', {
  state: () => ({
    allList: [],
    list: null,
    count: -1,
    designs: null,
    designsCount: -1,
    collectionsParams: {
      limit: 20,
      offset: 0,
      q: '',
      personal: 1,
      sortDirection: 'asc',
      sortField: 'updatedAt',
    },
    designsParams: {
      limit: 20,
      offset: 0,
      q: '',
      sortDirection: 'asc',
      sortField: '',
      collection: '',
    },
    createModal: false,
    renameModal: false,
    delCollectionModal: false,
    delTeamCollectionModal: false,

    renameDesignModal: false,
    delDesignModal: false,

    collectionName: '',
    collectionId: null,

    designName: '',
    designId: null,
    changeDesign: null,

    selectedCollectionId: null,
    selectedCollection: null,
    oneCollection: null,

    loading: false,
  }),
  actions: {
    selectCollectionId (collectionId) {
      this.selectedCollectionId = collectionId
    },
    selectCollection (collection) {
      this.selectedCollection = collection
    },
    createModalToggle () {
      this.createModal = !this.createModal
      this.collectionName = ''
    },
    renameModalToggle () {
      this.renameModal = !this.renameModal
      this.collectionId = null
      this.collectionName = ''
    },
    delCollectionModalToggle () {
      this.delCollectionModal = !this.delCollectionModal
      this.collectionId = null
    },
    renameDesignModalToggle () {
      this.renameDesignModal = !this.renameDesignModal
      this.designId = null
      this.designName = ''
    },
    delDesignModalToggle () {
      this.delDesignModal = !this.delDesignModal
      this.designId = null
    },
    async setSortOrderCollections (order) {
      this.collectionsParams.sortDirection = order
      await this.getList()
    },
    async searchByCollections (search) {
      this.collectionsParams.q = search
      await this.getList()
    },
    async sortCollection (sort) {
      this.collectionsParams.sortField = sort
      await this.getList()
    },
    async togglePersonalCollections (val, isAllCollection) {
      this.allList = []
      this.collectionsParams.personal = val
      if (isAllCollection) this.collectionsParams.offset = 0
      await this.getList(isAllCollection)
    },

    async setSortOrderDesigns (order, id) {
      this.designsParams.sortDirection = order
      await this.getDesigns(id)
    },
    async searchByDesigns (search, id) {
      this.designsParams.q = search
      await this.getDesigns(id)
    },
    async sortDesigns (sort, id) {
      this.designsParams.sortField = sort
      await this.getDesigns(id)
    },

    // async getAllList () {
    //   try {
    //     const { data: { collections: list } } = await request.get(
    //       '/collections',
    //     )
    //     this.list = list
    //   } catch (e) {
    //     console.error(e.message)
    //   }
    // },
    async getList (isAll) {
      this.loading = true
      try {
        const { data: { rows: list, count } } = await request.get(
          '/collections',
          { params: this.collectionsParams },
        )
        if (isAll) {
          this.allList = [...this.allList, ...list]
        } else {
          this.list = list
        }
        this.count = count
        this.loading = false
      } catch (e) {
        console.error(e.message)
        this.loading = false
      }
    },
    async getCollection (id) {
      try {
        const { data } = await request.get('/collections/' + id)
        this.oneCollection = data
      } catch (e) {
        console.error(e.message)
      }
    },
    async create (isAllCollection) {
      try {
        await request.post(
          '/collections',
          JSON.stringify({ title: this.collectionName }),
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          },
        )
        if (isAllCollection) {
          this.collectionsParams.offset = 0
          this.allList = []
          await this.getList(true)
          this.selectedCollection = this.allList.find(el => el.title === this.collectionName)
        } else await this.getList()
        this.createModalToggle()
        this.collectionName = ''
      } catch (e) {
        console.error(e.message)
      }
    },
    async updateCollection () {
      try {
        await request.put(
          '/collections/' + this.collectionId,
          JSON.stringify({ title: this.collectionName }),
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          },
        )
        await this.getList()
        this.renameModalToggle()
        this.collectionId = null
        this.collectionName = ''
      } catch (e) {
        console.error(e.message)
      }
    },
    async deleteCollection () {
      try {
        await request.delete('/collections/' + this.collectionId)
        await this.getList()
        this.delCollectionModalToggle()
        this.collectionId = null
      } catch (e) {
        console.error(e.message)
      }
    },

    async getDesigns (id) {
      try {
        this.designsParams.collection = id
        const { data: { rows: designs, count } } = await request.get(
          '/designs',
          { params: this.designsParams },
        )
        this.designs = designs
        this.designsCount = count
      } catch (e) {
        console.error(e.message)
      }
    },

    async updateDesign (collectionId) {
      try {
        await request.put(
          '/designs/' + this.designId,
          JSON.stringify({
            ...this.changeDesign,
            designName: this.designName,
            designCategory: this.changeDesign.categoryCode,
            elementIds: this.changeDesign.elements.join(','),
            ease: JSON.stringify(this.changeDesign.ease),
            fabricType: String(this.changeDesign.fabricType),
            fit: String(this.changeDesign.fit),
            collectionId: String(this.changeDesign.collection),
          }),
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          },
        )
        await this.getDesigns(collectionId)
        this.renameDesignModalToggle()
        this.designId = null
        this.designName = ''
      } catch (e) {
        console.error(e.message)
      }
    },
    async deleteDesign (collectionId) {
      try {
        await request.delete('/designs/' + this.designId)
        await this.getDesigns(collectionId)
        this.delDesignModalToggle()
        this.designId = null
      } catch (e) {
        console.error(e.message)
      }
    },
  },
})
