<template>
  <div>
    <q-dialog
      class="FabricDialog"
      :value="displayDialog"
      content-class="print-hide"
      @hide="closeDialog"
    >
      <dialog-card-base
        title="Fabric calculator"
        :style="style"
      >
        <div class="col-auto">
          <q-tabs
            dense
            active-color="secondary"
            v-model="calculatorMode"
          >
            <q-tab name="advanced">
              Layout Suggestion
            </q-tab>
            <q-tab name="basic">
              Calculator
            </q-tab>
          </q-tabs>
        </div>

        <fabric-calculator-basic
          v-if="calculatorMode === 'basic'"
          ref="calculator"
          class="col"
          :design-options="designOptions"
          @error="$emit('error', $event)"
          @current-view="handleCalculatorView"
          @selected-width="handleSelectedWidth"
          @close="closeDialog"
        />
        <fabric-calculator-advanced
          v-if="calculatorMode === 'advanced'"
          ref="calculatorAdvanced"
          class="col"
          :design-options="designOptions"
          @error="$emit('error', $event)"
          @current-view="handleCalculatorView"
          @close="closeDialog"
        />
        <template v-slot:actions>
          <div
            v-if="isSelectDisplays || isResultDisplays"
            class="row full-width items-center"
          >
            <div v-if="!$q.screen.lt.sm && !$q.screen.lt.md" class="col"></div>
            <div :class="`col flex ${!$q.screen.lt.sm && !$q.screen.lt.md ? 'justify-center' : 'justify-start'}`">
              <q-btn
                v-if="isSelectDisplays"
                color="secondary"
                size="md"
                @click.prevent="submit"
              >
                Submit
              </q-btn>
              <q-btn
                v-if="isResultDisplays"
                color="secondary"
                size="md"
                @click.prevent="preparePrint"
              >
                Print
              </q-btn>
            </div>
            <units-toggle-component
              class="col flex justify-end"
            />
          </div>
        </template>
      </dialog-card-base>
    </q-dialog>
    <printable-component v-if="printMode">
      <div v-html="printData"></div>
    </printable-component>
  </div>
</template>

<script>
import DialogCardBase from '@/components/Dialog/DialogCardBase'
import UnitsToggleComponent from '@/components/Units/UnitsToggleComponent'
import FabricCalculatorBasic from '@/components/Fabric/FabricCalculatorBasic'
import FabricCalculatorAdvanced from '@/components/Fabric/FabricCalculatorAdvanced'
import PrintableComponent from '@/components/Printable/PrintableComponent'
import { print } from '@/components/Printable/print-mixin'
import { designOptionsValidator } from '@/pages/designerConstructor'

export default {
  name: 'FabricDialog',

  components: {
    DialogCardBase,
    FabricCalculatorBasic,
    FabricCalculatorAdvanced,
    PrintableComponent,
    UnitsToggleComponent,
  },

  mixins: [print],

  props: {
    displayDialog: Boolean,
    designOptions: {
      type: Object,
      required: true,
      validator: val => designOptionsValidator(val),
    },
  },

  data: () => ({
    calculatorMode: 'basic',
    currentCalculatorView: undefined,
    printData: undefined,
    selectedWidth: 88,
  }),

  computed: {
    isResultDisplays () {
      return this.currentCalculatorView === 'result'
    },
    isLoadingInProgress () {
      return this.currentCalculatorView === 'loading'
    },
    isSelectDisplays () {
      return this.currentCalculatorView === 'select'
    },
    style () {
      let largeHeight = document.documentElement.clientHeight
      let selectedWidth = this.selectedWidth * 5 + 'px'
      if (largeHeight - 100 < this.selectedWidth * 5) {
        selectedWidth = '80%'
      }
      let styles = {
        width: '640px',
        height: 'auto',
        'max-width': '90%',
      }
      if (this.calculatorMode === 'basic') {
        styles['min-height'] = this.isResultDisplays ? 'auto' : '270px'
        styles['height'] = this.isResultDisplays ? selectedWidth : 'auto'
      } else {
        styles['min-height'] = this.isResultDisplays ? '500px' : '425px'
        styles['height'] = this.isResultDisplays ? '60%' : 'auto'
      }

      return styles
    },
  },

  methods: {
    submit () {
      switch (this.calculatorMode) {
        case 'basic':
          this.$refs['calculator'].submit()
          break
        case 'advanced':
          this.$refs['calculatorAdvanced'].submit()
          break
        default:
          throw Error('Unknown calculator mode')
      }
    },
    handleCalculatorView (view) {
      this.currentCalculatorView = view
    },
    handleSelectedWidth (width) {
      this.selectedWidth = width
    },
    closeDialog () {
      this.$emit('hide')
    },
    preparePrint () {
      switch (this.calculatorMode) {
        case 'basic':
          this.printData = this.$refs['calculator'].getPrintData()
          break
        case 'advanced':
          this.printData = this.$refs['calculatorAdvanced'].getPrintData()
          break
        default:
          throw Error('Unknown calculator mode')
      }
      this.print()
    },
  },
}
</script>

<style lang="scss">
  .FabricDialog {
    &__result {
      & svg {
        max-height: 100%;
        max-width:  100%;
        width:      100%;
      }
    }
  }
</style>
