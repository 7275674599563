<template>
  <dialog-card-base
    title="Debug params"
  >
    <div class="debug_params__rows">
      <div class="row" v-if="algs">
        <div class="label">Algorithms: </div>
        <div class="value">{{ algs }}</div>
      </div>
      <div class="row" v-if="params.length">
        <div class="label">Params: </div>
        <div class="value">
          <div class="par-group" :key="i" v-for="(p, i) in params">
            <span class="par-number">{{ p.number }}=</span>
            <span class="par-value">{{ p.value }}</span>
            <span class="sep" v-if="i < params.length - 1"> </span>
          </div>
          <a @click.prevent="copyParams" href="#" style="margin-left:5px">Copy</a>
        </div>
      </div>
      <div class="row" v-if="mannequin">
        <div class="label">Mannequin: </div>
        <div class="value">
          <span class="man">{{ mannequin }}</span>
        </div>
      </div>
      <div class="row" v-if="eases.length">
        <div class="label">Ease: </div>
        <div class="value">
          <div class="par-group" :key="i" v-for="(ease, i) in eases">
            <span class="par-number">{{ ease.name }}=</span>
            <span class="par-value">{{ ease.value }}</span>
            <span class="sep" v-if="i < eases.length - 1"> </span>
          </div>
        </div>
      </div>
      <div class="row" v-if="elements.length">
        <div class="label">Elements: </div>
        <div class="value" style="width:100%">
          <div class="element-row" :key="element.id" v-for="element in elements" @click="toggleElementInfo(element.id)">
            <div class="cols">
              <div class="e-col" style="min-width: 75px">{{ element.id  }} </div>
              <div class="e-col" style="flex: 1">{{ element.name }}</div>
              <div class="e-col" style="min-width: 135px">{{ element.nodeCode }}</div>
            </div>
            <div v-if="openedElements.includes(element.id)" @click.stop="" class="more-info">
              <div class="part-links">
                <template v-if="parts[element.id] !== undefined">
                  <div class="part-link" v-for="part in parts[element.id]" :key="part.id">
                    <a href="#" @click.stop.prevent="openLink(`${adminDomain}/part/${part.id}`)">
                      {{ part.id + ' ' + part.name }}
                    </a>
                  </div>
                  <div class="empty" v-if="!parts[element.id].length">No parts found</div>
                </template>
                <template v-else>
                  <div class="parts-loading">Loading parts...</div>
                </template>
              </div>
              <div class="attrs">
                <div class="params">
                  <span class="params-string">{{ getParamsString(element) }}</span>
                </div>

                <template v-if="instructionsInfo[element.id]">
                  <div class="params">Instructions tabs: {{ instructionsInfo[element.id].catNames.join(',') }}</div>
                  <div class="params">Compatible types: {{ instructionsInfo[element.id].types.join(',') }}</div>
                  <div class="params">Updated: {{ getUpdateDate(element) }}</div>
                </template>

                <a href="#" v-if="!showAttrs" @click.stop.prevent="showAttrs = true">Attributes</a>
                <template v-else>
                  <div class="attr" :key="attr.id" v-for="attr in getSortedAttributes(element)">
                    <span class="attr-id">{{ attr.id }}</span>
                    <span class="attr-type">{{ attr.type }}</span>
                    <span class="attr-name">{{ attr.name }}</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="elements.length">
        <div class="label">Element ids: </div>
        <div class="value">{{ elements.map(e => e.id).join(',') }}</div>
      </div>
      <div class="row">
        <div class="label">Links: </div>
        <div class="value">
          <a style="margin-right:10px" v-for="(domain, k) in envs" :key="k"
            :href="generateLink(domain)" target="_blank">{{ getEnvName(k) }}</a>
        </div>
      </div>
      <div class="row" v-if="lastError">
        <div class="label">Last error: </div>
        <div class="value">{{ lastError }}</div>
      </div>
    </div>
  </dialog-card-base>
</template>

<script>
import DialogCardBase from '@/components/Dialog/DialogCardBase'
import { fetchElementParts, fetchInstructionsDebugInfo } from '@/api'

export default {
  name: 'DebugParamsModal',
  components: { DialogCardBase },

  props: {
    debugParams: {
      type: Object,
      required: true,
    },
  },

  methods: {
    apply () {
      this.$emit('apply')
    },
    openLink (link) {
      window.open(link, '_blank')
    },
    getSortedAttributes (el) {
      let attrs = [...el.attributes]
      attrs.sort((a, b) => a.type > b.type)
      return attrs
    },
    generateLink (domain) {
      if (!this.elements.length) {
        return ''
      }
      const params = {
        elementIds: this.elements.map(el => el.id),
        fabricType: window.pageDesigner.fabricType,
        fit: window.pageDesigner.fit,
        seamAllowance: window.pageDesigner.seamAllowance,
        ease: window.pageDesigner.ease,
        settingCodes: window.pageDesigner.designSettingsActiveFlat,
        fontName: window.pageDesigner.getPatternsFontName,
      }
      const size = window.pageDesigner.standartSize
      const category = window.pageDesigner.designCategory
      const mannequinType = window.pageDesigner.mannequinType
      return `${domain}?params=${JSON.stringify(params)}&type=${mannequinType}&` +
        `category=${category}${size ? '&size=' + size : ''}`
    },
    getEnvName (key) {
      return key[0].toUpperCase() + key.substring(1)
    },
    async toggleElementInfo (id) {
      if (this.openedElements.includes(id)) {
        this.openedElements.splice(this.openedElements.indexOf(id), 1)
      } else {
        this.openedElements.push(id)
        this.parts[id] = await fetchElementParts(id)
        this.parts = { ...this.parts }
      }
    },
    getParamsString (el) {
      let ret = ''
      for (let number in el.params) {
        ret += number + ':' + el.params[number] + ' '
      }
      return ret
    },
    copyParams () {
      let paramsStr = ''
      for (let p of this.params) {
        paramsStr += p.number + ' = ' + p.value + '\n'
      }
      console.log(paramsStr)
      window.pageDesigner.copyToClipboard(paramsStr)
    },
    getUpdateDate (element) {
      const date = new Date(element.updated)
      const day = ("0" + date.getDate()).slice(-2)
      const month = ("0" + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      const hours = ("0" + date.getHours()).slice(-2)
      const minutes = ("0" + date.getMinutes()).slice(-2)
      return day + "." + month + "." + year + " " + hours + ":" + minutes
    },
    copyToClipboard (content) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(content)
      } else {
        const textArea = document.createElement("textarea")
        textArea.value = content
        textArea.style.position = "absolute"
        textArea.style.left = "-999999px"
        document.body.prepend(textArea)
        textArea.select()
        try {
          document.execCommand('copy')
        } catch (error) {
          console.error(error)
        } finally {
          textArea.remove()
        }
      }
    },
  },

  mounted () {
    window.debugParams = this.debugParams
    window.debugModal = this
    fetchInstructionsDebugInfo(this.elements.map(el => el.id))
      .then(data => {
        if (!data.elements) {
          return
        }
        data.elements.forEach(el => {
          let types = [el.type]
          if (el.type === 'woman_silhouettes_natural_waist') {
            types.push('woman_back_closures_skirts')
          }
          if (el.type === 'girl_silhouettes_natural_waist') {
            types.push('girl_back_closures_skirts')
          }
          this.instructionsInfo[el.id] = {
            catNames: el.catNames || [],
            types,
          }
        })
      })
  },
  data () {
    return {
      envs: {
        test: 'https://patternfast.tailornova.com',
        prod: 'https://designer.patternfast.com',
      },
      openedElements: [],
      parts: {},
      attrs: {},
      showAttrs: false,
      instructionsInfo: {},
    }
  },

  computed: {
    algs () {
      return this.debugParams.parameterizedKits.kits.map(kit => kit.displayName.replace('.alg', '')).join(', ')
    },
    params () {
      let params = this.debugParams.parameterizedKits.variations.map(v => ({ number: v.categoryUid.value, value: v.value }))
      const getNumber = number => {
        const m = number.match(/\d+/)
        if (m) {
          return parseInt(m[0])
        } else {
          return 1000
        }
      }
      params.sort((a, b) => getNumber(a.number) - getNumber(b.number))
      return params
    },
    mannequin () {
      return this.debugParams.parameterizedKits.mannequin.value
    },
    eases () {
      let ret = []
      for (let name in window.pageDesigner.ease) {
        ret.push({ name, value: window.pageDesigner.ease[name] })
      }
      return ret
    },
    elements () {
      let ret = []
      for (let node of window.pageDesigner.nodes) {
        const nodeEl = window.pageDesigner.designElements[node.code]
        if (!nodeEl || !nodeEl.length) {
          continue
        }
        const el = nodeEl[0]
        ret.push({ ...el, nodeCode: node.code, priority: node.priority })
      }
      ret.sort((a, b) => a.priority - b.priority)
      return ret
    },
    lastError () {
      return window.lastError
    },
    adminDomain () {
      // if (document.location.host.includes('patternfast.loc')) return 'http://localhost:8080'
      return 'https://admin' + (document.location.host.includes('sandbox') ? '-sandbox' : '') + '.tailornova.com'
    },
  },
}
</script>

<style lang="scss">
  .debug_params__rows {
    .row {
      margin-bottom: 10px;
      .label {
        margin-right: 5px;
        font-weight: bold;
      }
      .par-group {
        display: inline-block;
        white-space: nowrap;
        margin-right: 3px;
        .par-number {
        }
      }
      .man {
        word-break: break-all;
      }
      .element-row {
        .cols {
          display: flex;
          cursor: pointer;
          &:hover {
            background: #eee;
          }
          padding: 3px;
        }
        .more-info {
          display: flex;
          .part-links {
            width: 40%;
            padding: 3px;
            .part-link {
              margin-bottom:3px;
            }
          }
          .attrs {
            flex: 1;
            margin-bottom: 5px;
            .params, .node-code {
              margin-bottom: 3px;
              margin-top: 3px;
            }
            .attr {
              span {
                display: inline-block;
              }
              .attr-id {
                min-width: 36px;
              }
              .attr-type {
                min-width: 180px;
              }
            }
          }
        }
        border-bottom: 1px solid #ddd;
      }
    }
  }
</style>
