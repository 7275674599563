<template>
  <q-card
    class="bg-white column no-wrap"
    :class="{'q-pa-md': !noPaddings, 'q-pa-none': noPaddings}"
  >
    <q-btn
      class="DialogCardBase__closeButton"
      icon="close"
      flat
      round
      dense
      v-close-popup
    />

    <q-card-section
      v-if="title"
      class="col-shrink row items-center main-header"
      style="min-height: auto;"
    >
      <div class="modal-header text-center DialogCardBase__title">
        {{ title }}
      </div>
    </q-card-section>

    <q-card-section class="col column q-pa-none" :class="contentContainerClass">
      <slot></slot>
    </q-card-section>

    <q-card-actions
      v-if="hasActionsContent"
      class="DialogCardBase__actions col-shrink column"
      align="center"
    >
      <slot name="actions"></slot>
    </q-card-actions>

  </q-card>
</template>

<script>
export default {
  name: 'DialogCardBase',

  props: {
    title: {
      type: String,
      default: undefined,
    },
    noPaddings: Boolean,
    contentContainerClass: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    hasActionsContent () {
      return !!this.$slots.actions?.[0]
    },
  },
}
</script>

<style lang="scss">
  .DialogCardBase {
    &__title {
      flex-grow: 1;
    }

    &__closeButton {
      position: absolute;
      right:    24px;
      top:      24px;
      z-index:  1;
    }
  }
  .PageDesigner__fitEditorModal .DialogCardBase__closeButton{
    top: 16px;
  }
</style>
