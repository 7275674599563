<template>
  <section class="case-studies">
    <div class="container">
      <p>Case studies</p>
      <h2>What our customers say</h2>
      <div class="row">
        <div class="column">
          <figure>
            <img :src="require('@/assets/home/igigi.jpg')" alt="" />
          </figure>
          <div class="text">
            <h3>“PatternFast made it possible...</h3>
            <p>
              Transitioning to a sustainable, made-to-order model was a big step for IGIGI.<br><br>
              PatternFast made it possible by providing customized configurators and a seamless MTM order management system.<br><br>
              Their support has been invaluable in our journey."
            </p>
            <img :src="require('@/assets/home/igigi-logo.png')" alt="" width="98" height="28" />
          </div>
        </div>
        <div class="column">
          <figure>
            <img :src="require('@/assets/home/couturme.jpg')" />
          </figure>
          <div class="text">
            <h3>“PatternFast revolutionized our bespoke bridal ...</h3>
            <p>
              ... and evening wear process with a smart, AI powered design system that scales custom gowns based on size, style, and budget.<br><br>
              It's been a game-changer for COUTURME."
            </p>
            <img :src="require('@/assets/home/couturme-logo.png')" alt="" width="65" height="70" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheCaseStudies',
}
</script>

<style lang="scss">
.home-page {
  .case-studies {
    background: var(--Surface);
    border-top: 1px solid var(--Dividers);
    .container {
      padding: 160px 80px;
      flex-direction: column;

      & > p {
        color: var(--Text-Text_Secondary);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.18px;
        margin-bottom: 15px;
      }
      & > h2 {
        color: var(--Text-Text_Primary);
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.26px;
        margin-bottom: 60px;
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .column {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          column-gap: 25px;
          width: 100%;
          max-width: 620px;

          figure {
            width: 227px;
            height: 402px;
            border-radius: 8px;
            overflow: hidden;
            flex-shrink: 0;
            margin: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .text {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: baseline;

            h3 {
              color: var(--Text-Text_Primary);
              font-family: Montserrat;
              font-size: 30px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.9px;
              margin-bottom: 25px;
            }
            p {
              color: #385052;
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 160%; /* 25.6px */
              letter-spacing: -0.48px;
              margin: 0;
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .case-studies {
      .container {
        padding: 80px 20px;

        & > h2 {
          font-size: 24px;
          margin-bottom: 40px;
        }

        .row {
          flex-direction: column;
          row-gap: 40px;

          .column {
            flex-direction: column;
            row-gap: 25px;

            figure {
              aspect-ratio: 2 / 1;
              width: 100%;
              height: auto;

              img {
                object-position: left 5%;
              }
            }
            .text {
              h3 {
                font-size: 20px;
              }
              p {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
