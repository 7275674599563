<script src="../router/index.js"></script>
<template>
  <q-layout view="hhh lpR lFr" class="LayoutBase">
    <div class="LayoutBase__main-toolbar">
      <div class="LayoutBase__logoContainer">
        <div
          class="menu-icon"
          @click="displayLeftSidebar = !displayLeftSidebar"
          v-if="!noLeftSidebar"
        >
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24" width="24" height="24">
            <path fill="#09373D" d="M19 16.3h-7c-.4 0-.8.3-.8.8s.3.8.8.8h7c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zm0-5H5c-.4 0-.8.3-.8.8s.3.8.8.8h14c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zM5 7.8h14c.4 0 .8-.3.8-.8s-.4-.7-.8-.7H5c-.4 0-.8.3-.8.7s.4.8.8.8z"/>
          </svg>
        </div>
        <img @click="goHome" :src="logo" alt="logo">
      </div>
      <slot v-if="displayLeftSidebar || ($q.screen.gt.lg || $q.screen.gt.md)" name="header"></slot>
      <div class="LayoutBase__info">
        <a
          class="collection"
          v-if="userCollectionsStore.selectedCollection?.title"
          :href="`/user/collections/${userCollectionsStore.selectedCollection.id}`"
        >
          {{ userCollectionsStore.selectedCollection.title }}
        </a>
        <UserAvatar />
      </div>
    </div>
    <activate-plan class="activate-plan-block" />
    <div class="LayoutBase__sidebarContainer" v-show="displayLeftSidebar || ($q.screen.gt.lg || $q.screen.gt.md)">
      <slot name="leftPanel"></slot>
    </div>
    <div class="LayoutBase__page">
      <slot></slot>
    </div>
    <div class="LayoutBase__footer">
      <slot name="footer"></slot>
    </div>
  </q-layout>
</template>

<script>
import { store } from '@/store'
import activatePlan from '@/components/stripe/activatePlan'
import UserAvatar from '@/components/Atoms/UserAvatar'

import { useUserCollectionsStore } from '@/store/pinia/userCollections'
import { mapStores } from 'pinia'

export default {
  name: 'LayoutBase',
  store,

  props: {
    noLeftSidebar: Boolean,
  },
  components: {
    activatePlan,
    UserAvatar,
  },

  data: () => ({
    displayLeftSidebar: false,
  }),

  computed: {
    ...mapStores(useUserCollectionsStore),
    logo () {
      return require("@/assets/PfLogo.png")
    },
  },

  methods: {
    hideLeftSidebar () {
      return this.$refs.drawerLeft.hide?.()
    },
    goHome () {
      window.location.href = '/'
    },
  },
}
</script>

<style lang="scss">
  @import "src/styles/mixins";
  .LayoutBase:has(.activate-plan-block) {
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:  "header header"
                          "sidebar activate"
                          "sidebar page"
                          "footer footer";
  }
  .LayoutBase {
    display: grid;
    grid-template-columns: 137px 1fr;
    grid-template-rows: auto 1fr auto;
    height: 100vh;
    grid-template-areas:  "header header"
                          "sidebar page"
                          "footer footer";
    .activate-plan-block {
      grid-area: activate;
    }
    &__logoContainer {
      padding: 10px 26px;
      border-right: 1px solid var(--Dividers);
      @include flex(center);
      img {
        width: 85px;
        cursor: pointer;
      }
      .menu-icon {
        margin-right: 8px;
        display: none;
      }
    }
    &__info {
      @include flex(center);
      margin-right: 16px;
      .collection {
        margin: 0;
        color: var(--Text-Text_Secondary);
        font-size: 14px;
        margin-right: 16px;
      }
    }
    &__main-toolbar {
      grid-area: header;
      display: grid;
      grid-template-columns: 137px 1fr auto;
      border-bottom: 1px solid var(--Dividers);
    }
    &__sidebarContainer {
      grid-area: sidebar;
      display:          flex;
      flex-direction:   column;
      width: 137px;
      .area {
        height: 100%;
        border-right: 1px solid var(--Dividers);
      }

      .sidebar-element {
        min-width: 137px;
        border-right: 1px solid var(--Dividers);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 0;
        gap: 4px;
        font-size: 14px;
        img {
          padding: 8px;
        }
      }
    }
    &__page {
      grid-area: page;
    }
    &__footer {
      grid-area: footer;
    }
  }
  @media (max-width: 964px) {
    .LayoutBase:has(.activate-plan-block) {
      grid-template-rows: auto auto auto 1fr auto;
      grid-template-areas:  "header header"
                            "sidebar sidebar"
                            "activate activate"
                            "page page"
                            "footer footer";
    }
    .LayoutBase {
      grid-template-rows: auto auto 1fr auto;
      grid-template-areas:  "header header"
                            "sidebar sidebar"
                            "page page"
                            "footer footer";
      &__sidebarContainer {
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid var(--Dividers);
        overflow-y: scroll;
        height: 97px;
      }
      &__main-toolbar {
        grid-template-columns: 130px 1fr auto;
        grid-template-rows: auto auto;
      }
      &__logoContainer {
        border-right: none;
        .menu-icon {
          display: block;
        }
      }
      .toolbar {
        grid-column: 1 / -1;
        border-top: 1px solid var(--Dividers, #E0E9EA);
      }
      &__info {
        grid-column: 3;
        grid-row: 1;
      }
    }
  }
</style>
