<template>
  <div class="stripe-pay">
    <div v-if="error" class="stripe-pay__error">
      <div class="main-icon error">
        <svg
          width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16.0003" r="12.5833" stroke="#F52727" stroke-width="1.5"/>
          <path d="M16 9.33301V17.333" stroke="#F52727" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M16 21.333L16 21.3331" stroke="#F52727" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="text-holder">
        <h3>Something went wrong</h3>
        <p>{{ error }}</p>
      </div>
      <div class="buttons">
        <ButtonSecondary class="small" @click.native="$emit('close')">
          Maybe later
        </ButtonSecondary>
        <ButtonPrimary class="small" @click.native="confirmPayment">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M15.8 7.4h-4.2c-.4 0-.8-.3-.8-.8s.3-.8.8-.8H15V2.5c0-.4.3-.8.8-.8s.8.3.8.8v4.2c0 .4-.4.7-.8.7z"/>
              <path d="M10 17.4c-.7 0-1.4-.1-2-.3-1.5-.4-2.9-1.4-3.8-2.6-1-1.2-1.5-2.8-1.5-4.4C2.6 8.6 3 7 3.9 5.7c1-1.3 2.3-2.2 3.8-2.7s3.1-.5 4.6 0c1.5.5 2.8 1.4 3.7 2.8.2.3.2.8-.2 1-.3.2-.8.2-1-.2-.7-1-1.8-1.8-3-2.2-1.2-.4-2.4-.4-3.7 0-1.2.4-2.2 1.2-3 2.2-.7 1-1.1 2.3-1.1 3.5 0 1.3.5 2.5 1.2 3.5.8 1 1.9 1.7 3.1 2.1 1.2.3 2.5.3 3.7-.1 1.2-.4 2.2-1.3 2.9-2.3.2-.3.7-.4 1-.2.3.2.4.7.2 1-.9 1.3-2.1 2.4-3.6 2.9-.7.3-1.6.4-2.5.4z"/>
            </svg>
          </template>
          Try again
        </ButtonPrimary>
      </div>
    </div>
    <form class="payment-form" v-show="!error && !loading">
      <div id="payment-element">
      </div>
      <div class="button-block">
        <button @click.prevent="confirmPayment" class="green_button">Subscribe</button>
      </div>
    </form>
  </div>
</template>
<script>
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary'
import { getStripeKey } from '@/api'

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary,
  },
  props: {
    subscriptionData: Object,
  },
  data: () => ({
    stripe: null,
    error: null,
    loading: false,
    options: {
      clientSecret: null,
      appearance: {
        variables: {
          borderRadius: '12px',
          fontSizeBase: '16px',
          fontFamily: 'Open Sans, sans-serif',
          colorPrimary: '#27808D',
          gridRowSpacing: '20px',
        },
        rules: {
          '.Label': {
            marginBottom: '10px',
            color: 'rgba(0, 0, 0, 0.87)',
          },
          '.Input': {
            paddingTop: '19px',
            paddingBottom: '19px',
          },
          '.Input:focus': {
            boxShadow: 'none',
            borderColor: 'black',
          },
        },
      },
      externalPaymentMethodTypes: ['external_paypay'],
    },
    optionsCreate: {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false,
      },
    },
    elements: null,
  }),
  async mounted () {
    const stripeKey = await getStripeKey()
    this.stripe = window.Stripe(stripeKey)
    this.options.clientSecret = this.subscriptionData?.token
    this.elements = this.stripe.elements(this.options)
    const paymentElement = this.elements.create('payment', this.optionsCreate)
    paymentElement.mount('#payment-element')
  },
  methods: {
    async confirmPayment () {
      this.loading = true
      this.error = false
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.href}?succes_page=true`,
        },
      })
      if (error) {
        this.error = error.message
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.stripe-pay {
  .text-holder {
    text-align: center;
  }
  &__error {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    h3 {
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 8px;
    }
    p {
      color: var(--Text-Text_Secondary);
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
    }
    .main-icon {
      border-radius: 50%;
      background: var(--Active-States-Surfaces);
      height: 64px;
      width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .buttons {
      width: 100%;
      max-width: 300px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: stretch;
      column-gap: 16px;

      & > * {
        width: 100%;
      }
      .one {
        width: 202px;
      }
    }
  }
  .button-block {
    margin: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 247px;
    }
  }
  .error-message {
    color: #c10015;
    font-size: 16px;
    margin-bottom: 8px;
  }
}
</style>
