const settingCodesChange = {
  'length:micro_mini': { 3: 1 },
  'length:mini': { 3: 2 },
  'length:above_knee': { 3: 3 },
  'length:knee_length': { 3: 4 },
  'length:below_knees': { 3: 5 },
  'length:midi': { 3: 6 },
  'length:tea_length': { 3: 7 },
  'length:ankle_length': { 3: 8 },
  'length:full_length': { 3: 9 },
  'length:floor_length': { 3: 10 },
  'dress_lining:with_lining': { lining: 1 },
  'dress_lining:no_lininglength:above_knee': { lining: 0 },
  'neckline_facing:with_facing': { пар_facing: 1 },
  'neckline_facing:no_facing': { пар_facing: 0 },
  'top_slits:no_side_slits': { 32: 0 },
  'top_slits:with_side_slits': { 32: 1 },
  'pants_length:floor_length': { 3: 11 },
  'pants_length:full_length': { 3: 10 },
  'pants_length:cropped_length': { 3: 9 },
  'pants_length:ankles_length': { 3: 8 },
  'pants_length:capri': { 3: 7 },
  'pants_length:pedal_pushers': { 3: 6 },
  'pants_length:crops_below_knees': { 3: 5 },
  'pants_length:knee_length_capris': { 3: 4 },
  'pants_length:bermuda_shorts': { 3: 3 },
  'pants_length:jamaica_shorts': { 3: 2 },
  'pants_length:shorts': { 3: 1 },
  'pants_slits:no_slits': { 32: 0 },
  'pants_slits:both_slits': { 32: 1 },
  'skirt_lining:with_lining': { lining: 1 },
  'skirt_lining:no_lining': { lining: 0 },
  'skirt_slits:no_slits': { 32: 0 },
  'skirt_slits:right_slit': { 32: 2 },
  'skirt_slits:left_slit': { 32: 3 },
  'skirt_slits:both_slits': { 32: 1 },
  'skirt_front_slits:no_slits': { 33: 0 },
  'skirt_front_slits:right_slit': { 33: 2 },
  'skirt_front_slits:left_slit': { 33: 3 },
  'skirt_front_slits:both_slits': { 33: 1 },
  'pants_lining:with_lining': { lining: 1 },
  'pants_lining:no_lining': { lining: 0 },
  'loops:with_loops': { loop: 1 },
  'loops:no_loops': { loop: 0 },
  'facing:all_in_one': { пар_facing: 4 },
  'facing:neckline_armhole': { пар_facing: 3 },
  'facing:neckline': { пар_facing: 1 },
  'facing:armhole': { пар_facing: 2 },
  'facing:no': { пар_facing: 0 },
}

const fitChange = {
  1: { 2: 1 },
  2: { 2: 2 },
  3: { 2: 3 },
}

const fabricTypeChange = {
  1: { 1: 1 },
  2: { 1: 2 },
  3: { 1: 3 },
  4: { 1: 4 },
}

export { settingCodesChange, fitChange, fabricTypeChange }
