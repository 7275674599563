import { fetchNodeElements } from '@/api'
import Vue from 'vue'
import { serializeFilters } from '@/helpers'

const state = {
  elements: {},
  scrollPositions: {},
  customizedElements: {},
}

const mutations = {
  SET_ELEMENTS (
    state,
    {
      nodeCode,
      page,
      elements,
      total,
      limitPerPage,
      compatibleElementIds = [],
      filters = {},
      search,
      attrIds = [],
      replacedAttrs = '',
    },
  ) {
    if (!Array.isArray(elements)) {
      throw new Error('Invalid "elements" argument')
    }
    let elIds = [...compatibleElementIds]
    elIds.sort()
    elIds = elIds.join(',')
    if (!state.elements[nodeCode]) {
      Vue.set(
        state.elements,
        nodeCode,
        {
          items: {
            [page]: elements,
          },
          total: Number(total),
          limitPerPage: Number(limitPerPage),
          compatibleElementIds: elIds,
          filters,
          search,
          attrIds,
          replacedAttrs,
        },
      )
    } else {
      Vue.set(
        state.elements,
        nodeCode,
        {
          ...state.elements[nodeCode],
          items: {
            ...state.elements[nodeCode].items,
            [page]: elements,
          },
          total: Number(total),
          limitPerPage: Number(limitPerPage),
          compatibleElementIds: elIds,
          filters,
          search,
          attrIds,
        },
      )
    }
  },
  DELETE_NODE_DATA (state, nodeCode) {
    if ((nodeCode in state.elements)) {
      Vue.delete(state.elements, nodeCode)
    }
  },
  SET_POSITION_DATA (state, { nodeCode, conditions }) {
    Vue.set(state.scrollPositions, nodeCode, conditions)
  },
  RESTORE_STATE (state, { elements, scrollPositions }) {
    Vue.set(state, 'elements', elements)
    Vue.set(state, 'scrollPositions', scrollPositions)
  },
  SET_CUSTOMIZED_ELEMENTS (state, elements) {
    Vue.set(state, 'customizedElements', elements)
  },
}

const actions = {
  // проверить актуальность данных в хранилище и удалить устаревшие
  // вызывается в компоненте ElementsPanel в случае смены limit, compatiblElems и при вызове applyFilters
  async validateNodeData (
    { state, commit },
    { nodeCode, limitPerPage = 20, compatibleElementIds = [], filters = {}, search },
  ) {
    let ids = [...compatibleElementIds]
    ids.sort()
    console.log('ElementsStoreValidator started', { nodeCode })
    if (!state.elements[nodeCode]) {
      console.log('ElementsStoreValidator',
        'node data empty, nothing to validate')
      return
    }
    let filtersList = serializeFilters(filters)
    let storeElementsList = state.elements[nodeCode]?.filters
    if (
      state.elements[nodeCode]?.limitPerPage !== limitPerPage ||
      state.elements[nodeCode]?.compatibleElementIds !== ids.join(',') ||
      (
        !storeElementsList ||
        serializeFilters(storeElementsList).join('|') !== filtersList.join('|')
      ) ||
      state.elements[nodeCode]?.search !== search
    ) {
      console.log('ElementsStoreValidator',
        'Node elements is deprecated. Cleaning',
        {
          nodeCode,
          originalIds: state.elements[nodeCode]?.compatibleElementIds.split(
            ','),
          newIds: ids,
        })
      console.log('validateNodeData', 'deleting node data')
      commit('DELETE_NODE_DATA', nodeCode)
    } else {
      console.log('ElementsStoreValidator', 'data for the node is valid')
    }
  },
  async applyFilters (
    { state, commit, dispatch },
    { nodeCode, filterId, filterItems = {} },
  ) {
    if (!state.elements[nodeCode]) {
      throw new Error(
        `Node ${nodeCode} not exist in storage, cannot apply filters.`)
    }

    let filters = {
      ...state.elements[nodeCode].filters,
      [filterId]: filterItems,
    }
    let filtersList = serializeFilters(filters)
    let storeElementsList = serializeFilters(state.elements[nodeCode]?.filters)

    // если фильтры не заданы либо сериалиованные объекты не равны между собой - задаем и обновляем элементы
    if (storeElementsList.join('|') !== filtersList.join('|')) {
      let ids = [
        ...(state.elements?.[nodeCode]?.compatibleElementIds || '').split(',')]
      console.log('applyFilters', 'deleting node data during update')
      // commit('DELETE_NODE_DATA', nodeCode)
      await dispatch('fetchElements', {
        nodeCode,
        page: 1,
        compatibleElementIds: ids,
        filters,
        deleteNodeDataBeforeUpdate: true,
        search: state.elements?.[nodeCode]?.search,
      })
    }
  },
  async updateCompatibleElemIds (
    { state, commit, dispatch },
    { nodeCode, compatibleElementIds = [] },
  ) {
    let elIds = [...compatibleElementIds]
    elIds.sort()

    if (!state.elements[nodeCode]) {
      throw new Error(
        `Node ${nodeCode} not exist in storage, cannot update compatible elements list.`)
    }

    if (state.elements[nodeCode].compatibleElementIds === elIds.join(',')) {
      // одинаковые, обновление не требуется
      return
    }
    let filters = state.elements[nodeCode].filters
    console.log('updateCompatibleElemIds', 'deleting node data during update', {
      stateUids: state.elements[nodeCode].compatibleElementIds,
      givenUids: elIds.join(','),
    })
    // commit('DELETE_NODE_DATA', nodeCode)
    await dispatch('fetchElements', {
      nodeCode,
      page: 1,
      compatibleElementIds: elIds,
      filters,
      deleteNodeDataBeforeUpdate: true,
      search: state.elements[nodeCode]?.search,
    })
  },
  async fetchElements (
    { state, commit },
    { nodeCode, page, limitPerPage = 20, compatibleElementIds = [], filters = {}, deleteNodeDataBeforeUpdate = false, search = undefined, activeElement = undefined },
  ) {
    console.log('FetchElements', { nodeCode, page })
    let ids = [...compatibleElementIds]
    ids.sort()
    // let replacedAttrs = []
    // ids.forEach(id => {
    //   if (state.customizedElements[id]) {
    //     if (state.customizedElements[id].replacedAttrs) {
    //       replacedAttrs.push(...state.customizedElements[id].replacedAttrs)
    //     }
    //   }
    // })
    /**
     * todo: total должен обновляться, если search запрос или фильтры обновлены
     * Аналогично должны чиститься и элементы в сохраненной ноде.
     * Можно из фильтров и search запроса создавать ключ для кэша
     */
    console.log('FetchElements', 'checking elements for the page ' + page)
    if (!state.elements[nodeCode]?.items?.[page] || deleteNodeDataBeforeUpdate) {
      console.log('FetchElements',
        `elements for the page ${page} not found. Loading...`)
      let data = await fetchNodeElements(
        nodeCode,
        (page - 1) * limitPerPage,
        limitPerPage,
        state.elements[nodeCode]?.total === undefined || deleteNodeDataBeforeUpdate,
        ids,
        filters,
        search,
        // replacedAttrs,
        activeElement,
      )
      if (deleteNodeDataBeforeUpdate || state.elements[nodeCode]?.search !== search
      // || (page === 1 && state.elements[nodeCode]?.replacedAttrs !== JSON.stringify(replacedAttrs))
      ) {
        commit('DELETE_NODE_DATA', nodeCode)
      }
      commit('SET_ELEMENTS', {
        nodeCode,
        page,
        elements: data.items,
        total: ('total' in data) ? data.total : state.elements[nodeCode]?.total,
        limitPerPage: data.limit || limitPerPage,
        compatibleElementIds: ids,
        filters: { ...filters },
        search,
        attrIds: ('attrIds' in data) ? data.attrIds : [],
        // replacedAttrs: JSON.stringify(replacedAttrs),
      })
    }
  },
  async cleanNodeData ({ commit }, nodeCode) {
    console.log('cleanNodeData', 'deleting node data')
    commit('DELETE_NODE_DATA', nodeCode)
  },
  async savePositionData ({ commit }, { nodeCode, conditions }) {
    commit('SET_POSITION_DATA', { nodeCode, conditions })
  },
  async getCurrentState ({ state }) {
    return state
  },
  async restoreState ({ commit }, { elements, scrollPositions }) {
    commit('RESTORE_STATE', { elements, scrollPositions })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
