<template>
  <div
    class="ElementItem cursor-pointer"
    :class="{ElementItem__selected: isSelected, ElementItem__hidden: element.status !== 'ACTIVE'}"
    @click="$emit('select', element)"
    :title="element.name"
  >
    <div class="ElementItem__options">
      <q-btn
        v-if="showFavorites"
        flat
        round
        color="secondary"
        icon="favorite_border"
        @click.stop="$emit('favorite', element)"
      ></q-btn>
    </div>

    <div class="ElementItem__image">
      <svg-render
        :source="element.svgPreview"
        :keep-styles="true"
      />
    </div>

    <div class="ElementItem__name">
      {{ element.name }}
      <div class="tooltiptext">{{ element.name }}</div>
    </div>
  </div>
</template>

<script>

import { hasProperties } from '@/helpers'
import SvgRender from '@/components/Svg/SvgRender'

export default {
  name: 'ElementItem',
  components: { SvgRender },
  props: {
    element: {
      type: Object,
      required: true,
      validator: el => hasProperties(el, ['id', 'name', 'svgPreview']),
    },
    isSelected: Boolean,
    showFavorites: Boolean,
  },
}
</script>

<style lang="scss">
  @import "src/styles/variables";

  .ElementItem {
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    padding: 8px 8px 12px 8px;
    gap: 5px;
    position:        relative;
    display:         flex;
    flex-direction:  column;
    align-items:     center;
    width:           106px;

    .tooltiptext {
      max-width: 100%;
      white-space: break-spaces;
      top: calc(100% + 13px);
      display: none;
      background-color: #09373D;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 4px 12px;
      position: absolute;
      z-index: 1;
      transform: translateX(-50%);
      width: max-content;
      left: 50%;
      transition: opacity 0.3s;
      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #09373D transparent;
      }
    }
    &:hover {
      .tooltiptext {
        display: block;
      }
    }
    &__selected {
      border-color: var(--Active-State-Strokes);
      background: var(--Active-States-Surfaces);
    }

    &__hidden {
      opacity: .3;
    }

    &__options {
      position:        absolute;
      top:             5px;
      width:           100%;
      padding:         0 5px;
      display:         flex;
      justify-content: space-between;
    }

    &__image {
      display:    inline-flex;
      max-width:  100px;
      max-height: 150px;
      background: var(--Surface);
      border-radius: 8px;
      overflow: hidden;
      padding: 8px 0;

      & > svg {
        width:  100%;
        height: 100%;
        fill: #fff;
      }

      * {
        stroke: $primary-svg-stroke;
      }
    }

    &__name {
      max-width:      100%;
      line-height:    normal;
      overflow:       hidden;
      text-overflow:  ellipsis;
      font-size:      12px;
      color:          var(--Text-Text_Primary);
      // text-transform: uppercase;
      text-align:     center;
      white-space:    nowrap;
    }
  }
</style>
