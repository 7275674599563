<template>
  <div class="signUp isSended">
    <p class="title">Almost There</p>
    <p>Dear {{ firstName }} {{ lastName }},
      Thank you for signing up! We have sent an email to your provided address.</p>
    <p>Please check your inbox and click on the confirmation link to complete your registration process.</p>
    <p>If you don't receive this email, please check your spam folder. If the email still doesn't arrive,
      it's possible there may be an issue with the provided address.</p>
    <p>Please enter your alternative email address in the field below and click on the "Send Request"
      button. Our support team will assist you with further steps.</p>
    <div class="input-block">
      <q-input
        v-model="v$.altEmail.$model"
        :class="v$.altEmail.$error ? 'error-input' : ''"
        outlined
        placeholder="name@example.com"
      />
      <div class="error" v-if="v$.altEmail.$error">
        <p>An E-mail should be in the correct format: name@example.com</p>
      </div>
    </div>
    <button :disabled="v$.altEmail.$error" class="green_button" style="width:100%" @click.prevent="sendProblem">
      Send Request
    </button>
    <p v-if="globalError" class="global-error">{{ globalError }}</p>
    <p>Once your email is verified, you can sign in and start using our platform.</p>
    <p>We're looking forward to having you onboard!</p>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required, email } from '@vuelidate/validators'
import { signupProblem } from '@/api'
const validateEmail = (email) => {
  const emailParts = email.split('@')
  const username = emailParts[0]
  // Проверка имени пользователя
  const usernameRegex = /^[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9]$/
  if (!usernameRegex.test(username)) {
    return false
  }
  return true
}
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data: () => ({
    globalError: false,
    altEmail: null,
  }),
  validations () {
    return {
      altEmail: {
        required,
        email,
        maxLength: maxLength(320),
        validateEmail,
      },
    }
  },
  props: {
    user: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
  },
  methods: {
    async sendProblem () {
      if (!this.v$.altEmail.$error) {
        console.log('ok')
        let data = {
          userRegistrationEmail: this.user,
          userContactEmail: this.altEmail,
        }
        try {
          this.globalError = false
          await signupProblem(data)
          this.$router.push('/login')
        } catch (e) {
          this.globalError = e.message
        }
      }
    },
  },
}
</script>

<style lang="scss">
.isSended {
  p {
    margin-bottom: 10px !important;
  }
  .green_button {
    margin-top: 0 !important;
    margin-bottom: 21px;
  }
}
</style>
