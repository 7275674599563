<template>
  <sidebar-base-panel
    ref="basePanel"
    @scroll="loadNextElementsIfRequired"
  >
    <!-- <template
      v-if="nodesList.length > 1"
      v-slot:topList
    >
      <q-btn
        v-for="node in nodesList"
        :key="node.code"
        flat
        :color="node.code === activeNodeCode ? 'secondary' : 'primary'"
        @click.prevent="selectNode(node.code)"
      >
        {{ node.name }}
      </q-btn>
    </template> -->

    <template v-slot:searchInput>
      <div class="title_block">
        <p>Design</p>
        <ButtonsGroup v-if="nodesList.length > 1">
          <button
            v-for="node in nodesList"
            :key="node.code" :class="[node.code === activeNodeCode ? 'active' : '']" @click="selectNode(node.code)">
            {{ node.name }}
          </button>
        </ButtonsGroup>
      </div>
      <InputField
        v-model="filterByNameInput"
        @enter="applyFilterByName(filterByNameInput)"
        placeholder="Search"
      >
        <template v-slot:start-icon>
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 21">
            <path fill="#09373d" d="M18 17.5 13.5 13c.9-1.1 1.4-2.5 1.4-4.1 0-3.6-3-6.6-6.6-6.6s-6.6 3-6.6 6.6c0 3.6 3 6.6 6.6 6.6 1.5 0 3-.5 4.1-1.4l4.5 4.5c.1.1.3.2.5.2s.4-.1.5-.2c.4-.4.4-.8.1-1.1zM3.2 8.8c0-2.8 2.3-5.1 5.1-5.1s5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1z"/>
          </svg>
        </template>
      </InputField>
    </template>

    <template v-slot:filters>
      <div class="filter-open-block">
        <p class="filter-open-block__items">{{ itemsFoundText }}</p>
        <div
          v-if="filtersList.length > 0"
          class="filter-open-block__drop"
          @click="isShowFilters = !isShowFilters"
        >
          {{ isShowFilters ? 'Hide' : 'Open' }} Filters
          <svg :class="isShowFilters ? 'open' : ''" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20" width="20" height="20">
            <path fill="#09373d" d="M13.9 7.8c-.3-.3-.8-.3-1.1 0L10 10.6 7.2 7.8c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l3.3 3.3c.1.1.3.2.5.2s.4-.1.5-.2l3.3-3.3c.5-.3.5-.8.2-1.1z"/>
          </svg>
        </div>
      </div>
      <div v-if="isShowFilters" class="filter-items">
        <filter-item
          v-for="item in filtersList"
          :key="item.attrType"
          :title="item.name"
          :filter-options="item.attrs"
          :selected-values="selectedFilters[item.attrType]"
          :active-attrs="elementsData ? elementsData.attrIds : []"
          @items-selected="updateFilters({filterId: item.attrType, items: $event})"
        ></filter-item>
      </div>
    </template>

    <template v-slot:default>
      <div
        v-if="total === 0 && !loadingInProgress"
        class="row justify-center q-pa-md"
      >
        <p class="text-body1">
          No elements found
        </p>
      </div>
      <div
        v-else
        class="elements-block"
      >
        <empty-element-item
          v-if="activeNode.isOptional"
          :name="'No ' + activeNode.name"
          :is-selected="activeElementIds.length === 0"
          @select="$emit('unselect-all-elements')"
        />
        <element-item
          v-for="item in elementsFiltered"
          :key="item.id"
          :data-id="item.id"
          :data-part-ids="item.part_ids ? item.part_ids.join(',') : ''"
          :ref="'element' + item.id"
          :element="item"
          :is-selected="activeElementIds.includes(item.id)"
          @select="selectElement($event)"
        ></element-item>
        <q-resize-observer @resize="loadNextElementsIfRequired"/>
      </div>

      <div
        v-if="loadingInProgress"
        class="row q-py-md"
      >
        <q-spinner-dots
          class="col-2 offset-5"
          color="primary"
          size="2em"
        />
      </div>
    </template>
  </sidebar-base-panel>
</template>

<script>
import FilterItem from '@/components/Elements/FilterItem'
import { hasProperties } from '@/helpers'
import SidebarBasePanel from '@/components/Sidebar/SidebarBasePanel'
import ElementItem from '@/components/Elements/ElementItem'
import EmptyElementItem from '@/components/Elements/EmptyElementItem'
import pDebounce from 'p-debounce'
import InputField from '@/components/Atoms/InputField.vue'
import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'

// import { fetchNodeElements } from '@/api'
import { mapActions } from 'vuex'
import { store } from '@/store'

export default {
  name: 'ElementsPanel',
  store,

  components: {
    EmptyElementItem,
    ElementItem,
    SidebarBasePanel,
    FilterItem,
    InputField,
    ButtonsGroup,
  },

  props: {
    activeNode: {
      type: Object,
      required: true,
      validator: item => hasProperties(item, ['name', 'code', 'isMultiple', 'isOptional']),
    },
    nodesList: {
      type: Array,
      required: true,
      validator: elems => elems.every(item => hasProperties(item, ['name', 'code'])),
    },
    activeElementIds: {
      type: Array,
      required: true,
    },
    compatibleElementIdsStr: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    filterByNameInput: '',
    filterByNameEntered: '',
    loadingInProgress: false,
    hasLoadingErrors: false,
    isShowFilters: false,
  }),

  async created () {
    // пауза нужна для случаев, когда обновляется сразу несколько элементов, от которых зависит запрос
    this.validateNodeDataDebounced = pDebounce(this.validateNodeData, 40)
    this.getElementsDebounced = pDebounce(this.getElements, 50)
    this.restoreSearchQuery()
    await this.cleanDeprecatedElements()
    if (this.currentPage === 0) {
      await this.getElementsDebounced()
    }
  },

  mounted () {
    this.restoreScroll()
  },

  computed: {
    elementsData () {
      return this.$store.state.nodesElements.elements[this.activeNodeCode]
    },
    filtersList () {
      if (!this.activeNode.filters?.length) {
        return []
      }
      return this.activeNode.filters
    },
    selectedFilters () {
      return this.elementsData?.filters ||
        this.createEmptyFiltersListForCurrentNode()
    },
    compatibleElementIdsArr () {
      if (!this.compatibleElementIdsStr) {
        return []
      }
      return this.compatibleElementIdsStr.split(',')
    },
    total () {
      let total = this.elementsData?.total
      if (this.activeNodeCode === 'wm_dr_pockets' && window.pageDesigner.hasSideClosure) {
        total -= 1
      }
      return total
    },
    limitPerPage () {
      return this.elementsData?.limitPerPage || 20
    },
    itemsFoundText () {
      switch (this.total) {
        case undefined:
          return undefined
        case 1:
          return '1 element found'
        default:
          return `${this.total} elements found`
      }
    },
    activeNodeCode () {
      return this.activeNode.code
    },
    elementsFlat () {
      if (!this.elementsData?.items) {
        return []
      }
      return Object.keys(this.elementsData?.items)
        .reduce((acc, currentPage) => ([...acc, ...this.elementsData?.items[currentPage]]), [])
    },
    elementsFiltered () {
      let res = this.elementsFlat
      if (this.activeNodeCode === 'wm_dr_pockets' && window.pageDesigner.hasSideClosure) {
        res = res.filter(item => item.name.toLowerCase().indexOf('in-seam') === -1)
      }
      return res
    },
    // currentPage будет неактуальна, если список элементов неактуален. Для удаления устаревших элементов из хранилища используйте функцию cleanDeprecatedElements
    currentPage () {
      if (!this.elementsData) {
        return 0
      }
      return Object.keys(this.elementsData?.items).length || 0
    },
    isLastPage () {
      return (this.elementsData && this.total <= this.currentPage * this.limitPerPage) ||
        // принудительно считаем страницу последней, если последний запрос вернул 0 элементов и значение в total неверное
        this.elementsData?.items?.[Object.keys(this.elementsData?.items).length]?.length === 0
    },
  },

  watch: {
    async activeNodeCode (val) {
      if (!val) {
        return
      }
      this.resetData()
      this.restoreSearchQuery()
      await this.cleanDeprecatedElements()
      // this.restoreScroll()
      this.scrollToActiveElement()
      if (this.currentPage === 0) {
        await this.getElementsDebounced()
      }
    },
    async compatibleElementIdsStr (val) {
      this.resetData()
      this.restoreSearchQuery()
      try {
        await this.updateCompatibleElemIds({
          nodeCode: this.activeNodeCode,
          compatibleElementIds: val ? val.split(',') : [],
        })
      } catch (e) {
        await this.getElementsDebounced()
      }
    },
    async limitPerPage () {
      this.resetData()
      this.restoreSearchQuery()
      await this.cleanDeprecatedElements()
      if (this.currentPage === 0) {
        await this.getElementsDebounced()
      }
    },
    elementsFiltered () {
      this.loadNextElementsIfRequired()
    },
  },

  methods: {
    selectNode (nodeCode) {
      if (nodeCode === this.activeNodeCode) {
        return
      }
      this.$emit('select-node', nodeCode)
    },
    selectElement (element) {
      this.saveScrollPosition(element.id)
      this.$emit('select-element', element)
    },
    resetData () {
      this.filterByNameInput = ''
      this.filterByNameEntered = ''
      this.$refs.basePanel.setScrollPosition(0)
      this.hasLoadingErrors = false
    },
    async cleanDeprecatedElements () {
      await this.validateNodeDataDebounced({
        nodeCode: this.activeNodeCode,
        limitPerPage: this.limitPerPage,
        compatibleElementIds: this.compatibleElementIdsArr,
        filters: this.selectedFilters,
        search: this.filterByNameEntered,
      })
    },
    createEmptyFiltersListForCurrentNode () {
      return this.filtersList.reduce((acc, item) => {
        acc[item.attrType] = []
        return acc
      }, {})
    },
    // получить элементы для следующей страницы.
    // todo: передавать в метод нужную страницу вместо того, чтобы брать следующую
    async getElements (page = null) {
      if (this.hasLoadingErrors) {
        return
      }
      this.loadingInProgress = true
      try {
        let filters = { ...this.selectedFilters }
        let search = this.filterByNameEntered
        if (!page) {
          await this.cleanDeprecatedElements()
        }
        if (!this.elementsData?.[this.currentPage + 1] || page) {
          await this.fetchElements({
            nodeCode: this.activeNodeCode,
            page: page || (this.currentPage + 1),
            limitPerPage: this.limitPerPage,
            compatibleElementIds: this.compatibleElementIdsArr,
            filters,
            search,
            deleteNodeDataBeforeUpdate: !!page,
            activeElement: !page && this.activeElementIds.length ? this.activeElementIds[0] : null,
          })
        }
      } catch (e) {
        console.log(e)
        this.$emit('error', { title: 'Error', message: 'Cannot load node elements.' })
        this.hasLoadingErrors = true
      }
      this.loadingInProgress = false
      // если загруженный список виден на 85%, загрузим следующую порцию элементов, если необходимо
      this.$nextTick(async () => {
        let { scrolledInPercents } = this.$refs['basePanel'].getScrollPosition()
        if (scrolledInPercents > 85 && !this.isLastPage) {
          await this.getElements()
        }
      })
    },
    async updateFilters ({ filterId, items }) {
      await this.applyFilters({ nodeCode: this.activeNodeCode, filterId, filterItems: items })
    },
    async applyFilterByName (searchQuery) {
      // todo: отправлять запрос на обновление в хранилище вместо хранения в компоненте
      this.filterByNameEntered = searchQuery
      await this.getElements()
    },
    isLastElementVisible () {
      if (!this.elementsFiltered?.length) {
        return false
      }
      let lastEl = this.elementsFiltered[this.elementsFiltered.length - 1]
      let lastElPosition = this.$refs['element' + lastEl.id][0].$el.getBoundingClientRect()
      let containerRect = this.$refs.basePanel.getElementsContainer().getBoundingClientRect()

      return lastElPosition.top < containerRect.bottom
    },
    async loadNextElementsIfRequired (e) {
      if (this.loadingInProgress || this.isLastPage) {
        return
      }
      await this.$nextTick()
      if (!this.isLastElementVisible()) {
        return
      }
      await this.getElements()
    },
    saveScrollPosition (elementId) {
      this.savePositionData({
        nodeCode: this.activeNodeCode,
        conditions: { elementId },
      })
    },
    scrollToActiveElement () {
      console.log('scroll to active element')
      if (this.activeElementIds.length > 0) {
        let id = this.activeElementIds[0]
        let elemRef = this.$refs['element' + id]
        if (!elemRef?.[0]?.$el || !this.$refs.basePanel) {
          if (this.elementsFiltered.find(el => el.id === id)) {
            console.log('element found, waiting for next tick')
            // элемент ее не прорисован
            this.$nextTick(() => {
              this.scrollToActiveElement()
            })
          } else {
            console.log('element not found')
          }
          return
        }
        let position = elemRef[0].$el.offsetTop
        this.$refs.basePanel.setScrollPosition(position)
      }
    },
    restoreSearchQuery () {
      this.filterByNameInput = this.elementsData?.search
      this.filterByNameEntered = this.elementsData?.search
    },
    // в принципе, можно заменить на scrollToActiveElement.
    restoreScroll () {
      let data = this.$store.state.nodesElements.scrollPositions?.[this.activeNodeCode]
      if (data?.elementId && !!this.elementsFiltered.find(el => el.id === data.elementId)) {
        this.$nextTick(() => {
          let position = this.$refs['element' + data.elementId][0].$el.offsetTop
          this.$refs.basePanel.setScrollPosition(position)
        })
      }
    },
    ...mapActions('nodesElements',
      ['fetchElements', 'savePositionData', 'validateNodeData', 'applyFilters', 'updateCompatibleElemIds']),
  },
}
</script>

<style lang="scss">
@import "src/styles/mixins";
.elements-block {
  @include flex(flex-start, flex-start, row, 8px);
  flex-wrap: wrap;
}
.filter-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .FilterItem {
    flex: 0 0 145px;
    &:first-child {
      flex: 1 1 100%;
    }
  }
  @media (max-width: 480px) {
    .FilterItem {
    flex: 0 0 100%;
  }
  }
}
.filter-open-block {
  @include flex(center, space-between, 8px);
  &__items {
    @include font-styles(18px, 27px, 600, Montserrat);
    margin-bottom: 0;
  }
  &__drop {
    cursor: pointer;
    font-size: 14px;
    text-wrap: nowrap;
    @include flex(center);
    svg {
      transition: all 0.3s ease-in-out;
    }
    svg.open {
      transform: rotate(180deg);
    }
  }
}
</style>
