export default {
  setUnits ({ commit }, { units, saveToLocalStorage = true }) {
    commit('SET_UNITS', units)
    if (saveToLocalStorage && typeof localStorage?.setItem === 'function') {
      localStorage.setItem('units', units)
    }
  },
  setFractionBase ({ commit }, { base, saveToLocalStorage = true }) {
    commit('SET_FRACTION_BASE', base)
    if (saveToLocalStorage && typeof localStorage?.setItem === 'function') {
      localStorage.setItem('fractionBase', base)
    }
  },
  setUnitsIfNoUserPreferences ({ commit, dispatch }, { units, fractionBase }) {
    if (!localStorage?.getItem?.('units')) {
      dispatch('setUnits', {
        units,
        saveToLocalStorage: false,
      })
    }
    if (!localStorage?.getItem?.('fractionBase')) {
      dispatch('setFractionBase', {
        base: fractionBase,
        saveToLocalStorage: false,
      })
    }
  },
  setSeamAllowanceOptions ({ commit }, options) {
    commit('SET_SEAM_ALLOWANCE_OPTIONS', options)
  },
  setSeamAllowance ({ commit }, val) {
    commit('SET_SEAM_ALLOWANCE', { val, selectNearest: true })
  },
  setLogo ({ commit }, logo) {
    if (typeof localStorage?.setItem === 'function') {
      localStorage.setItem('logo', logo)
    }
    commit('SET_LOGO', logo)
  },
  setDesignCategoryPrefixes ({ commit }, options) {
    commit('SET_DESIGN_CATEGORY_PREFIXES', options)
  },
  setMannequinCategories ({ commit }, options) {
    commit('SET_MANNEQUIN_CATEGORIES', options)
  },
}
