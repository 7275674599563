<template>
  <layout-with-header>
    <div v-if="error && this.$route.query.type === 'JOIN'" class="confirm-error">
      <p class="title">Confirmation link has expired.</p>
      <p class="subtitle">Please <a href="/signup">sign up</a> again.</p>
    </div>
    <div v-if="error && this.$route.query.type === 'RESET'" class="confirm-error">
      <p class="title">Reset link has expired.</p>
      <p class="subtitle">Please <a href="/forgot-password">try</a> again.</p>
    </div>
    <div
      :class="`create-pass-form ${sended ? 'sended' : ''}`"
      @keyup.enter="reset"
      v-if="this.$route.query.type === 'RESET' && !error"
    >
      <template v-if="!sended">
        <p class="title">New password</p>
        <div class="input-block">
          <div class="password">
            <svg v-if="isShowPass" @click="isShowPass = false" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_25_1587)">
                <path d="M8.3335 9.99992C8.3335 10.4419 8.50909 10.8659 8.82165 11.1784C9.13421 11.491 9.55814 11.6666 10.0002 11.6666C10.4422 11.6666 10.8661 11.491 11.1787 11.1784C11.4912 10.8659 11.6668 10.4419 11.6668 9.99992C11.6668 9.55789 11.4912 9.13397 11.1787 8.82141C10.8661 8.50885 10.4422 8.33325 10.0002 8.33325C9.55814 8.33325 9.13421 8.50885 8.82165 8.82141C8.50909 9.13397 8.3335 9.55789 8.3335 9.99992Z" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.5 10C15.5 13.3333 13 15 10 15C7 15 4.5 13.3333 2.5 10C4.5 6.66667 7 5 10 5C13 5 15.5 6.66667 17.5 10Z" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_25_1587">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <svg v-else @click="isShowPass = true" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_25_1588)">
                <path d="M8.82097 8.82251C8.50845 9.13513 8.33293 9.5591 8.33301 10.0011C8.33309 10.4432 8.50876 10.8671 8.82138 11.1796C9.13401 11.4921 9.55797 11.6676 10 11.6676C10.4421 11.6675 10.866 11.4918 11.1785 11.1792" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.9008 13.8942C12.7319 14.6256 11.3789 15.0091 10 15C7 15 4.5 13.3334 2.5 10C3.56 8.23336 4.76 6.93503 6.1 6.10503M8.48333 5.15002C8.98253 5.04897 9.49068 4.99871 10 5.00003C13 5.00003 15.5 6.66669 17.5 10C16.945 10.925 16.3508 11.7225 15.7183 12.3917" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 2.5L17.5 17.5" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_25_1588">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <q-input
              v-model="v$.password.$model"
              outlined
              :type="isShowPass ? 'text' : 'password'"
              :class="!valid && v$.password.$dirty ? 'error-input' : ''"
              placeholder="Create Password"
            />
          </div>
          <div class="pass_valid" v-if="v$.password.$dirty">
            <p class="title_valid">Password must:</p>
            <div class="check" v-for="item in validPass" :key="item.message">
              <label :class="'checkbox ' + (item.error ? 'selected' : 'error-check')">
                <input type="checkbox" disabled />
                <svg v-if="item.error" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </label>
              <p>{{item.message}}</p>
            </div>
          </div>
        </div>
        <button :disabled="!valid" class="green_button" style="width:100%" @click.prevent="reset">
          Reset
        </button>
        <button class="white_button" style="width:100%" @click.prevent="$router.push('/')">
          Cancel
        </button>
        <p v-if="globalError" class="global-error">{{ globalError }}</p>
      </template>
      <template v-else>
        <svg width="78" height="77" viewBox="0 0 78 77" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_25_1754)">
            <path d="M38.9996 4.27783C32.2311 4.27783 25.6145 6.28493 19.9867 10.0453C14.3589 13.8057 9.97257 19.1505 7.38237 25.4038C4.79217 31.6571 4.11446 38.538 5.43493 45.1765C6.7554 51.8149 10.0148 57.9128 14.8008 62.6988C19.5869 67.4849 25.6847 70.7442 32.3232 72.0647C38.9616 73.3852 45.8426 72.7075 52.0959 70.1173C58.3491 67.5271 63.6939 63.1407 67.4543 57.5129C71.2147 51.8851 73.2218 45.2686 73.2218 38.5001C73.2218 29.4238 69.6163 20.7192 63.1983 14.3013C56.7804 7.88338 48.0759 4.27783 38.9996 4.27783ZM61.351 27.0142L33.246 55.0978L16.6482 38.5001C16.0809 37.9328 15.7622 37.1634 15.7622 36.3612C15.7622 35.5589 16.0809 34.7895 16.6482 34.2223C17.2155 33.655 17.9848 33.3363 18.7871 33.3363C19.5893 33.3363 20.3587 33.655 20.926 34.2223L33.2887 46.5851L57.116 22.7792C57.3969 22.4983 57.7303 22.2755 58.0973 22.1235C58.4643 21.9715 58.8576 21.8933 59.2549 21.8933C59.6521 21.8933 60.0454 21.9715 60.4124 22.1235C60.7794 22.2755 61.1129 22.4983 61.3937 22.7792C61.6746 23.0601 61.8974 23.3936 62.0495 23.7606C62.2015 24.1275 62.2797 24.5209 62.2797 24.9181C62.2797 25.3153 62.2015 25.7087 62.0495 26.0757C61.8974 26.4427 61.6746 26.7761 61.3937 27.057L61.351 27.0142Z" fill="#1D1F29"/>
          </g>
          <defs>
          <clipPath id="clip0_25_1754">
            <rect width="77" height="77" fill="white" transform="translate(0.5)"/>
          </clipPath>
          </defs>
        </svg>
        <p class="title">New password confirmed!</p>
        <p class="subtitle">Your changes were successfully saved.</p>
        <button class="green_button" style="width:100%" @click.prevent="$router.push('/')">
          Okay
        </button>
      </template>
    </div>
  </layout-with-header>
</template>

<script>
import { confirmEmail, resetPassword, checkToken } from '@/api'
import LayoutWithHeader from '@/layouts/LayoutWithHeader'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  data: () => ({
    error: false,
    password: null,
    globalError: false,
    sended: false,
    isShowPass: false,
  }),
  components: {
    LayoutWithHeader,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  async mounted () {
    if (this.$route.params.token) {
      if (this.$route.query.type === 'JOIN') {
        try {
          await confirmEmail({
            token: this.$route.params.token,
            type: this.$route.query.type,
          })
          this.$router.push('/')
        } catch (e) {
          this.error = true
        }
      } else {
        try {
          await checkToken(this.$route.params.token)
        } catch (e) {
          this.error = true
        }
      }
    }
  },
  computed: {
    validPass () {
      return {
        passLength: {
          message: 'Contain 8 to 30 characters',
          error: this.password?.length > 8 && this.password?.length < 30,
        },
        upperLowerCase: {
          message: 'Contain both lower and uppercase letters',
          error: /[a-z]/.test(this.password) && /[A-Z]/.test(this.password),
        },
        num: {
          message: 'Contain 1 number',
          error: /\d/.test(this.password),
        },
        spec: {
          message: "Contain 1 special charachter +!'$@%&/()=`#",
          error: /[+!'$@%&/()=`#]/.test(this.password),
        },
      }
    },
    valid () {
      const valid = this.validPass
      return valid.passLength.error && valid.upperLowerCase.error && valid.num.error && valid.spec.error
    },
  },
  methods: {
    async reset () {
      try {
        await resetPassword({
          token: this.$route.params.token,
          type: this.$route.query.type,
          password: this.password,
        })
        this.sended = true
      } catch (e) {
        this.error = true
      }
    },
  },
  validations () {
    return {
      password: {
        required,
      },
    }
  },
}
</script>

<style lang="scss">
.confirm-error {
  width: 544px;
  margin: auto;
  padding: 56px;
  border-radius: 12px;
  background-color: white;
  margin: 128px auto;
  color: #1D1F29;
  .subtitle {
    font-size: 16px;
    a {
      color: #4743E0;
      font-weight: 600;
    }
  }
}
.create-pass-form {
  width: 544px;
  margin: auto;
  padding: 56px;
  border-radius: 12px;
  background-color: white;
  margin: 128px auto;
  color: #1D1F29;
  .global-error {
    color: #E30000;
  }
  .check {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .pass_valid {
    margin-top: 8px;
    .title_valid {
      margin-bottom: 8px;
    }
  }
  button {
    width: 100%;
    border-radius: 12px;
    min-height: 56px;
    margin-top: 16px;
    font-size: 16px !important;
  }
}
.sended {
  display: flex;
  align-items: center;
  flex-direction: column;
  .title {
    margin-top: 32px;
  }
  button {
    margin-top: 40px;
  }
  .subtitle {
    font-size: 16px;
    color: #828282;
  }
}
@media (max-width: 544px) {
  .create-pass-form {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .create-pass-form {
    padding: 32px 16px 32px 16px;
    margin: 0;
  }
}
</style>
