<template>
  <layout-user>
    <div class="auto_grading_page">
      <div class="back" @click="goBack">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M11.6663 13.3337L8.33301 10.0003L11.6663 6.66699"
            stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Back
      </div>
      <h1>Auto grading</h1>
      <div
        class="block-button"
      >
        <div class="block-button__inputs">
          <div>
            <p class="size-chart-type">Fit charts</p>
            <div class="select-block-radio">
              <div
                :class="`radio-item ${fitChartsStore.isCustom ? 'active' : ''}`"
                @click="fitChartsModeSelect('custom')"
              >
                Custom
              </div>
              <div
                :class="`radio-item ${!fitChartsStore.isCustom ? 'active' : ''}`"
                @click="fitChartsModeSelect('standart')"
              >
                Standard
              </div>
            </div>
          </div>
          <div class="input-block">
            <Dropdown
              :options="fitChartsOptions"
              v-model="valueOption"
              label="Select fit chart"
              option-label="name"
              placeholder="Choose..."/>
          </div>
        </div>
      </div>
      <div class="selected-size">
        <p class="selected-size__title">Selected sizes: {{ checked.length }}</p>
        <div class="select-all">
          <div class="check">
            <label
              :class="`checkbox ${(checked.length === allSizes.length) && allSizes.length > 0 ? 'selected' : ''}`"
              @click="checkedToggleAll"
            >
              <svg
                v-if="(checked.length === allSizes.length) && allSizes.length > 0"
                width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </label>
            <p>Select all sizes</p>
          </div>
        </div>
      </div>
      <div class="error_message" v-if="sizeErrorMessage">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
          <circle cx="10.0003" cy="10.5003" r="7.58333" stroke="#09373D" stroke-width="1.5"/>
          <path d="M10 6.33301V11.333" stroke="#09373D" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M10 13.833L10 13.8331" stroke="#09373D" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        <p v-html="sizeErrorMessage" />
      </div>
      <div class="auto_grading" ref="auto_grading">
        <div
          v-for="size in valueOption?.mannequins"
          :key="size.mannequinId"
          :class="`auto_grading__item ${checked.includes(size.mannequinId) ? 'active' : ''} ${size.error ? 'error' : ''} ${size.loading ? 'loading' : ''}`"
          @click="size.error || size.loading ? null : checkedToggle(size.mannequinId)"
        >
          <div class="auto_grading__item__title">
            <div class="check">
              <label :class="`checkbox ${checked.includes(size.mannequinId) ? 'selected' : size.error ? 'disabled' : ''}`">
                <svg
                  v-if="checked.includes(size.mannequinId)" width="8" height="6" viewBox="0 0 8 6"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </label>
              <p>{{ size.name }}</p>
            </div>
            <svg v-if="size.error" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <circle cx="12.4004" cy="12.5" r="9.25" stroke="#F52727" stroke-width="1.5"/>
              <path d="M12.4004 7.5V13.5" stroke="#F52727" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M12.4004 16.5L12.4004 16.5001" stroke="#F52727" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <div v-else-if="!size.loading" :class="`${size.hasEase ? 'has_ease' : 'ease'}`">
              <svg
                xmlns="http://www.w3.org/2000/svg" fill="none"
                width="24" height="25" viewBox="0 0 24 25"
                @click="goEase(size)"
              >
                <path
                  stroke="#09373D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M20 15.5h1m-18 0h13m0 2v-4m-2-4h7m-18 0h7m0 2v-4"/>
              </svg>
            </div>
          </div>
          <div v-if="size.loading" class="auto_grading_page__loader">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
              <g clip-path="url(#clip0_719_13172)">
                <path d="M21 10.5V5.25" stroke="#09373D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 21H5.25" stroke="#09373D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                  d="M13.5623 13.5625L9.7998 9.79999" stroke="#09373D" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_719_13172">
                  <rect width="42" height="42" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="auto_grading__item__svg" v-else-if="size.svg" v-html="size.svg"></div>
          <p class="auto_grading__item__error" v-else-if="size.error">{{ size.error }}</p>
        </div>
      </div>
      <div :class="`bottom_buttons ${fixedBlockStyle}`" ref="buttons">
        <ButtonPrimary
          :disabled="!checked.length || downloadLoading"
          style="width:279px"
          @click.native="download"
        >
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20">
            <path d="M15 18.3H5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h10c.4 0 .8.3.8.8s-.4.8-.8.8zm-.3-8.8c-.3-.3-.8-.3-1.1 0l-2.9 2.9V2.5c0-.4-.3-.8-.8-.8s-.7.4-.7.8v9.9L6.4 9.5c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l4.2 4.2h.1c.1 0 .1.1.2.1s.2.1.3.1c.1 0 .2 0 .3-.1.1 0 .2-.1.2-.2l4.2-4.2c.2-.3.2-.7-.1-1z" class="st0"/>
          </svg>
        </template>
          Download sizes
        </ButtonPrimary>
      </div>
      <modal-component :show="openLoadingModal" type="loader" @close="openLoadingModal = false" :closeButton="false">
        <template v-slot:title>Downloading...</template>
        <template v-slot:description>Sewing patterns are being downloaded. <br> This might take a few moments.</template>
        <template v-slot:buttons>
          <ButtonSecondary class="small one" @click.native="stopDownload">Cancel</ButtonSecondary>
        </template>
      </modal-component>
      <modal-component :show="errorModal" type="error" @close="errorModal = false">
        <template v-slot:title>Something went wrong</template>
        <template v-slot:description>Download failed. Please try again</template>
        <template v-slot:buttons>
          <ButtonSecondary class="small" @click.native="errorModal = false">Cancel</ButtonSecondary>
          <ButtonPrimary class="small" @click.native="download">
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M15.8 7.4h-4.2c-.4 0-.8-.3-.8-.8s.3-.8.8-.8H15V2.5c0-.4.3-.8.8-.8s.8.3.8.8v4.2c0 .4-.4.7-.8.7z"/>
                <path d="M10 17.4c-.7 0-1.4-.1-2-.3-1.5-.4-2.9-1.4-3.8-2.6-1-1.2-1.5-2.8-1.5-4.4C2.6 8.6 3 7 3.9 5.7c1-1.3 2.3-2.2 3.8-2.7s3.1-.5 4.6 0c1.5.5 2.8 1.4 3.7 2.8.2.3.2.8-.2 1-.3.2-.8.2-1-.2-.7-1-1.8-1.8-3-2.2-1.2-.4-2.4-.4-3.7 0-1.2.4-2.2 1.2-3 2.2-.7 1-1.1 2.3-1.1 3.5 0 1.3.5 2.5 1.2 3.5.8 1 1.9 1.7 3.1 2.1 1.2.3 2.5.3 3.7-.1 1.2-.4 2.2-1.3 2.9-2.3.2-.3.7-.4 1-.2.3.2.4.7.2 1-.9 1.3-2.1 2.4-3.6 2.9-.7.3-1.6.4-2.5.4z"/>
              </svg>
            </template>
            Try again
          </ButtonPrimary>
        </template>
      </modal-component>
      <modal-component :show="successModal" type="success" iconName="check" @close="successModal = false">
        <template v-slot:title>Your sewing patterns were successfully downloaded!</template>
        <template v-slot:buttons>
          <ButtonPrimary class="small one" @click.native="successModal = false">
            Okay
          </ButtonPrimary>
        </template>
      </modal-component>
    </div>
  </layout-user>
</template>

<script>
import LayoutUser from '@/layouts/LayoutUser'
import ModalComponent from '@/components/Atoms/ModalComponent'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import Dropdown from '@/components/Atoms/Dropdown.vue'

import { useFitChartsStore } from '@/store/pinia/fitCharts'
import { mapStores } from 'pinia'

import {
  downloadSizeRun,
  fetchPatternsPreview,
  downloadStop,
  createMannequin,
} from '@/api'

export default {
  components: {
    LayoutUser,
    ModalComponent,
    ButtonPrimary,
    ButtonSecondary,
    Dropdown,
  },
  data: () => ({
    checked: [],
    valueOption: null,
    openLoadingModal: false,
    errorModal: false,
    successModal: false,
    downloadLoading: false,
    scrolledY: 0,
    autoGradingElement: null,
    buttonsElement: null,
  }),
  watch: {
    valueOption () {
      this.getPrewiev()
      this.checked = []
    },
  },
  computed: {
    ...mapStores(useFitChartsStore),
    fitChartsOptions () {
      if (this.fitChartsStore.isCustom) return this.fitChartsStore.customCharts.list
      else return this.fitChartsStore.standartCharts.list
    },
    sizeErrorMessage () {
      const errorSizes = []
      if (this.valueOption?.mannequins) {
        this.valueOption.mannequins.forEach(el => {
          if (el.error) errorSizes.push(el.name)
        })
      }
      if (errorSizes.length) return `Downloading are not available for <b>${errorSizes.join(', ')}</b> sizes of this pattern`
      else return null
    },
    fixedBlockStyle () {
      if (
        this.scrolledY > this.autoGradingElement?.offsetTop + this.autoGradingElement?.offsetHeight - window.innerHeight + this.buttonsElement?.offsetHeight
      ) return 'stop'
      return ''
    },
    allSizes () {
      let all = []
      if (this.valueOption?.mannequins) {
        this.valueOption.mannequins.forEach(el => {
          if (el.svg) all.push(el.mannequinId)
        })
      }
      return all
    },
  },
  methods: {
    fitChartsModeSelect (mode) {
      this.valueOption = null
      if (mode === 'custom') {
        this.fitChartsStore.isCustom = true
        if (!this.fitChartsStore.customCharts.list.length) this.fitChartsStore.getList()
      } else {
        this.fitChartsStore.isCustom = false
        if (!this.fitChartsStore.standartCharts.list.length) this.fitChartsStore.getListStandart()
      }
    },
    handleScroll () {
      this.scrolledY = window.scrollY
    },
    async goEase (size) {
      const rp = JSON.parse(this.$route.query.params)
      if (!size.mannequinIdNew) {
        await createMannequin(size.params, this.$route.query.type)
          .then((data) => {
            this.valueOption.mannequins = this.valueOption.mannequins.map(el => {
              if (el.mannequinId === size.mannequinId) {
                el.mannequinIdNew = data.mannequinId
                size.mannequinIdNew = data.mannequinId
              } return el
            })
          })
      }
      let option = {
        designCategory: this.$route.query.category,
        eases: {},
        elementIds: rp.elementIds,
        fabricType: rp.fabricType,
        fit: rp.fit,
        mannequinId: size.mannequinIdNew,
        seamAllowance: rp.seamAllowance,
        settingCodes: rp.settingCodes,
        algorithms: rp.algorithms,
        params: rp.params,
        internalLines: rp.internalLines,
      }
      let params = {
        algorithms: rp.algorithms,
        params: rp.params,
      }
      this.$router.push({
        name: 'ease-page',
        query: {
          designOptions: JSON.stringify(option),
          params: JSON.stringify(params),
          designFabric: this.$route.query.designFabric,
          type: this.$route.query.type,
          category: this.$route.query.category,
          mannequinEase: rp.mannequinEase ? JSON.stringify(rp.mannequinEase) : null,
          isCustomModel: this.$route.query.isCustomModel,
          fitChartsId: this.$route.query.fitChartsId,
          mannequinIdNew: size.mannequinId,
          fitChartsIsCustom: this.$route.query.fitChartsIsCustom,
          page: 'auto-grading',
        },
      })
    },
    async stopDownload () {
      await downloadStop('/size-run')
      this.errorModal = false
    },
    goBack () {
      const rp = JSON.parse(this.$route.query.params)
      if (rp.params) delete rp.params
      if (rp.algorithms) delete rp.algorithms
      this.$router.push(
        {
          path: '/designer',
          query: {
            params: JSON.stringify(rp),
            type: this.$route.query.type,
            category: this.$route.query.category,
            isCustomModel: this.$route.query.isCustomModel,
            fitChartsIsCustom: this.fitChartsStore.isCustom,
            fitChartsId: this.$route.query.fitChartsId,
            mannequinIdNew: this.$route.query.mannequinIdNew,
          },
        },
      )
    },
    checkedToggle (size) {
      let index = this.checked.indexOf(size)
      if (index !== -1) {
        this.checked.splice(index, 1)
      } else this.checked.push(size)
    },
    checkedToggleAll () {
      if (this.allSizes.length > 0) {
        if (this.checked.length === this.allSizes.length) {
          this.checked = []
        } else {
          this.checked = this.allSizes.slice(0)
        }
      } else return null
    },
    async download () {
      let selectedSize = []
      const rp = JSON.parse(this.$route.query.params)
      this.valueOption.mannequins.forEach(el => {
        const hasSize = this.checked.find(size => size === el.mannequinId)
        if (hasSize) {
          let ease = {}
          if (rp.mannequinEase?.[el.mannequinId]) ease = rp.mannequinEase[el.mannequinId].ease
          selectedSize.push({
            name: el.name,
            uid: el.apiUid,
            eases: ease,
          })
        }
      })
      let postData = {
        setting_codes: rp.settingCodes,
        element_ids: rp.elementIds,
        algorithms: rp.algorithms,
        params: rp.params,
        format: "dxf-aama",
        mannequins: selectedSize,
        seam_allowance: rp.seamAllowance,
      }
      this.openLoadingModal = true
      this.downloadLoading = true
      let response = await downloadSizeRun(postData)
      this.openLoadingModal = false
      this.downloadLoading = false
      if (response === 'error') {
        this.errorModal = true
      } else if (response === 'ok') {
        this.successModal = true
      }
    },
    async getPrewiev () {
      const rp = JSON.parse(this.$route.query.params)
      for (let i in this.valueOption.mannequins) {
        if (!this.valueOption.mannequins[i].svg) {
          this.valueOption.mannequins[i].loading = true
        }
      }
      for (let i in this.valueOption.mannequins) {
        const size = this.valueOption.mannequins[i]
        let ease = {}
        let fabricType = rp.fabricType
        let fit = rp.fit
        if (rp.mannequinEase?.[size.mannequinId]) {
          ease = rp.mannequinEase[size.mannequinId].ease
          if (Object.keys(ease).length > 0 ||
            rp.mannequinEase[size.mannequinId].fabricType !== rp.fabricType ||
            rp.mannequinEase[size.mannequinId].fit !== rp.fit
          ) {
            this.valueOption.mannequins[i].hasEase = true
          }
          if (rp.mannequinEase[size.mannequinId].fabricType) fabricType = rp.mannequinEase[size.mannequinId].fabricType
          if (rp.mannequinEase[size.mannequinId].fit) fit = rp.mannequinEase[size.mannequinId].fit
        }
        this.valueOption.mannequins[i].loading = true
        if (!size.mannequinIdNew) {
          let { mannequinId } = await createMannequin(size.params, this.$route.query.type)
          this.valueOption.mannequins[i].mannequinIdNew = mannequinId
        }
        fetchPatternsPreview(
          rp.internalLines,
          rp.elementIds,
          this.valueOption.mannequins[i].mannequinIdNew,
          fabricType,
          fit,
          rp.seamAllowance,
          ease,
          rp.settingCodes,
          rp.fontName,
          true,
          false,
        )
          .then((svg) => {
            this.$set(this.valueOption.mannequins[i], 'loading', false)
            this.$set(this.valueOption.mannequins[i], 'svg', svg)
          })
          .catch((e) => {
            console.log('e', e)
            let errorText = "We can't create a sewing pattern for this size. Please check your body measurements or contact our customer support."
            this.$set(this.valueOption.mannequins[i], 'loading', false)
            this.$set(this.valueOption.mannequins[i], 'error', errorText)
          })
      }
    },
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  async mounted () {
    this.autoGradingElement = this.$refs.auto_grading
    this.buttonsElement = this.$refs.buttons
    window.addEventListener('scroll', this.handleScroll)
    const rp = JSON.parse(this.$route.query.params)
    if (!Object.values(rp).length) {
      alert('No params passed')
    }
    let dataKey = 'standartCharts'
    if (this.$route.query.fitChartsIsCustom) {
      this.fitChartsStore.isCustom = true
      dataKey = 'customCharts'
      if (!this.fitChartsStore.customCharts.list.length) await this.fitChartsStore.getList()
    } else {
      this.fitChartsStore.isCustom = false
      if (!this.fitChartsStore.standartCharts.list.length) await this.fitChartsStore.getListStandart()
    }
    this.valueOption = this.fitChartsStore[dataKey].list.find(el => el._id === this.$route.query.fitChartsId)
    if (!this.valueOption) this.valueOption = this.fitChartsStore[dataKey].list[0]
    this.getPrewiev()
  },
}
</script>

<style lang="scss">
.auto_grading_page {
  padding: 16px;
  max-width: 1440px;
  margin: auto;
  color: var(--text_color, #1D1F29);
  .error_message {
    border-radius: 8px;
    border: 1px solid var(--Dividers, #E0E9EA);
    padding: 12px 51px 12px 19px;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 24px;
    font-size: 14px;
    svg {
      margin-right: 12px;
      min-width: 21px;
    }
    p {
      margin: 0;
    }
  }
  .bottom_buttons {
    position: fixed;
    border-top: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
    padding: 16px;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FAFBFB;
  }
  .bottom_buttons.stop {
    position: relative;
    margin-left: -16px;
    width: calc(100% + 32px);
  }
  .block-button {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &__inputs {
      display: flex;
    }
  }
  h1 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin: 16px 0 24px;
  }
  .back {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 16px;
    width: fit-content;
    font-weight: 600;
    svg {
      margin-right: 4px;
    }
  }
  .size-chart-type {
    font-size: 12px;
    margin-bottom: 8px;
    color: #6F8789;
  }
  .input-block {
    margin-left: 40px;
    margin-bottom: 0;
    max-width: 100%;
    min-width: 322px;
  }
  .selected-size {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 24px;
    margin-top: 40px;
    &__title {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
    }
    p {
      margin: 0;
    }
    .select-all {
      display: flex;
      font-weight: 600;
      font-size: 14px;
    }
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 40px);
    svg {
      animation: spin 1.5s linear infinite;
    }
  }
}
.auto_grading {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 98px;
  column-gap: 35px;
  margin-bottom: 32px;
  .check {
    margin-bottom: 0;
  }
  &__item.error {
    .check {
      color: var(--Button-States-Disabled_Txt);
    }
  }
  &__item {
    min-height: 252px;
    border-radius: 12px;
    border: 1px solid var(--contour, rgba(15, 104, 116, 0.14));
    padding: 8px;
    cursor: pointer;
    &__error {
      color: #6F8789;
      padding: 45px 8px 0;
      cursor: no-drop;
    }
    &__loading {
      cursor: no-drop;
    }
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.14));
      padding: 4px 0 8px;
      position: relative;
      min-height: 38px;
      .size-name {
        font-size: 14px;
        margin-bottom: 0;
        padding-left: 8px;
      }
      .ease {
        display: none;
      }
      .has_ease {
        position: absolute;
        right: 0;
        &::after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: #FF6770;
          border-radius: 50%;
          right: 0;
        }
      }
    }
    &__svg {
      margin-top: 8px;
      svg {
        overflow: visible;
      }
    }
  }
  &__item.active {
    border: 1px solid#FF6770;
    .auto_grading__item__title {
      color: #FF6770;
      font-weight: 600;
    }
  }
  &__item:hover {
    .auto_grading__item__title {
      .ease {
        display: flex;
      }
    }
  }
}
@media (max-width: 1100px) {
  .auto_grading {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 40px;
    column-gap: 15px;
  }
}
@media (max-width: 964px) {
  .auto_grading {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
    column-gap: 15px;
  }
  .auto_grading_page .block-button {
    flex-direction: column;
    align-items: baseline;
  }
}
@media (max-width: 768px) {
  .auto_grading_page .block-button__inputs {
    flex-direction: column;
    width: 100%;
  }
  .auto_grading_page .input-block {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  .auto_grading {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
    column-gap: 16px;
    &__item__title .size-name {
    font-size: 14px;
  }
  }
  .auto_grading_page {
    .selected-size {
      margin-top: 32px;
      .check {
        margin-bottom: 0;
      }
    }
    .select-block-radio .radio-item {
      width: 50%;
    }
    h1 {
      font-size: 24px;
      margin: 24px 0 16px;
    }
  }
}
</style>
