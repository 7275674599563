import Vuex from 'vuex'
import Vue from 'vue'
import mutations from '@/store/mutations'
import actions from '@/store/actions'
import getters from '@/store/getters'
import nodesElements from '@/store/modules/nodesElements'
import fitEditor from '@/store/modules/fitEditor'
import user from '@/store/modules/user'
import fitModel from '@/store/modules/fitModel'
import { Measurement } from '@/helpers/measurement'
import designConstructorHistory from '@/store/modules/designConstructorHistory'
import { SEAM_ALLOWANCE_OPTIONS } from '@/components/seamAllowance'

Vue.use(Vuex)

let store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    nodesElements,
    fitEditor,
    designConstructorHistory,
    user,
    fitModel,
  },
  state: {
    logo: localStorage?.getItem?.('logo') || undefined,
    units: localStorage?.getItem?.('units') || Measurement.DEFAULT_UNIT,
    seamAllowanceOptions: SEAM_ALLOWANCE_OPTIONS,
    seamAllowance: 0,
    fractionBase: localStorage?.getItem?.('fractionBase') || Measurement.DEFAULT_BASE,
    patternsOptions: 'AvenirNext LT Pro',
    isProduction: process.env.VUE_APP_ENV === 'production',
    isLoggedIn: !!localStorage?.getItem?.('token') || false,
    designCategoryPrefixes: null,
    mannequinCategories: null,

  },
  mutations,
  getters,
  actions,
})

export { store }
