<template>
  <layout-user>
    <div class="new-designer-page">
      <div class="new-designer-page__steps">
        <div class="exit" @click="$router.push(`/user/collections/${userCollectionsStore.selectedCollection.id}`)">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20" width="20" height="20">
            <path fill="#09373d" d="m11.1 10 4.5-4.5c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0L10 8.9 5.5 4.5c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1L8.9 10l-4.5 4.5c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l4.5-4.5 4.5 4.5c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L11.1 10z"/>
          </svg>
          <p>Exit</p>
        </div>
        <p class="step">Step {{ step }}/4</p>
      </div>
      <h2 class="center-text">{{ title[step-1] }}</h2>
      <div ref="block" class="block-page">
        <template v-if="step === 1">
          <div class="select-block-radio root-cat">
            <div
              v-for="cat in rootCategories"
              :key="cat.code"
              :class="`radio-item ${activeRootCategory?.code === cat.code ? 'active' : ''}`"
              @click="activeRootCategory = cat"
            >
              {{ cat.name }}
            </div>
          </div>
          <p class="center-text choose">Choose type of garment</p>
          <div class="new-designer-page__cats">
            <div
              :class="`cat ${selectedCat === cat.code ? 'active' : ''} ${$store.state?.isProduction && cat.active === '0' ? 'soon' : ''}`"
              v-for="cat in currentCategories"
              :key="cat.code"
              @click="selectedCat = cat.code"
            >
              <div>
                <div :class="`radio-button-block`">
                  <div
                    class="radio-button-item"
                  />
                  <label>{{ cat.name }}</label>
                  <div class="comming" v-if="$store.state?.isProduction && cat.active === '0'">
                    Coming soon
                  </div>
                </div>
              </div>
              <div class="new-designer-page__categoryImg">
                <div
                  v-html="cat.image"
                  class="new-designer-page__categoryImage"
                ></div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="step === 2" class="step2">
          <div class="select-block-radio" v-if="subscriptionPlan !=='STUDENT_EA' && subscriptionPlan !=='INDIE_EA'">
            <div
              :class="`radio-item ${userCollectionsStore.collectionsParams.personal === 1 ? 'active' : ''}`"
              @click="userCollectionsStore.togglePersonalCollections(1, true)"
            >
              Created by me
            </div>
            <div
              :class="`radio-item ${userCollectionsStore.collectionsParams.personal === 0 ? 'active' : ''}`"
              @click="userCollectionsStore.togglePersonalCollections(0, true)"
            >
              Created by team
            </div>
          </div>
          <Dropdown
            :options="userCollectionsStore.allList"
            v-model="userCollectionsStore.selectedCollection"
            label="Choose collection"
            option-label="title"
            placeholder="Choose..."
            :loading="userCollectionsStore.loading"
            :need-loading="userCollectionsStore.count / userCollectionsStore.collectionsParams.offset + userCollectionsStore.collectionsParams.limit > 1"
            @ended="setOffset"
          >
            <template v-slot:option>
              <InputField
                v-model="searchCollection"
                class="base-dropdown__content__search"
                placeholder="Search"
              >
                <template v-slot:start-icon>
                  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 21">
                    <path fill="#09373d" d="M18 17.5 13.5 13c.9-1.1 1.4-2.5 1.4-4.1 0-3.6-3-6.6-6.6-6.6s-6.6 3-6.6 6.6c0 3.6 3 6.6 6.6 6.6 1.5 0 3-.5 4.1-1.4l4.5 4.5c.1.1.3.2.5.2s.4-.1.5-.2c.4-.4.4-.8.1-1.1zM3.2 8.8c0-2.8 2.3-5.1 5.1-5.1s5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1z"/>
                  </svg>
                </template>
              </InputField>
              <div class="dropdown-add-option" @click="userCollectionsStore.createModalToggle()">
                <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20" width="20" height="20">
                  <path fill="#09373d" d="M15 9.3h-4.2V5c0-.4-.3-.8-.8-.8s-.8.4-.8.8v4.2H5c-.4 0-.8.3-.8.8s.3.8.8.8h4.2V15c0 .4.3.8.8.8s.8-.3.8-.8v-4.2H15c.4 0 .8-.3.8-.8s-.4-.7-.8-.7z"/>
                </svg>
                Add New
              </div>
            </template>
          </Dropdown>
          <modal-component
            :show="userCollectionsStore.createModal"
            type="success"
            @close="userCollectionsStore.createModalToggle()"
          >
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 33" width="33" height="33">
                <path d="M20 17.1h-3.2v-3.2c0-.4-.3-.8-.8-.8s-.8.3-.8.8v3.2H12c-.4 0-.8.3-.8.8s.3.8.8.8h3.2v3.2c0 .4.3.8.8.8s.8-.3.8-.8v-3.2H20c.4 0 .8-.3.8-.8s-.4-.8-.8-.8z"/>
                <path d="M26.7 7.8H16.3l-2.1-2.1c-.4-.4-.9-.6-1.5-.6H5.3c-1.1 0-2.1.9-2.1 2.1v18.6c0 1.1.9 2.1 2.1 2.1h21.3c1.1 0 2.1-.9 2.1-2.1v-16c.1-1.1-.9-2-2-2zM4.8 7.2c0-.3.3-.6.6-.6h7.4c.2 0 .3.1.4.2l1 1H4.8v-.6zm22.4 18.6c0 .3-.3.6-.6.6H5.3c-.3 0-.6-.3-.6-.6V9.3h21.9c.3 0 .6.3.6.6v15.9z"/>
              </svg>
            </template>
            <template v-slot:title>New collection</template>
            <template v-slot:description>Name the new collection</template>
            <template v-slot:extra>
              <InputField v-model="userCollectionsStore.collectionName">
                <template v-slot:top-label>
                  Collection Name
                </template>
              </InputField>
            </template>
            <template v-slot:buttons>
              <ButtonSecondary class="small" @click.native="userCollectionsStore.createModalToggle()">
                Cancel
              </ButtonSecondary>
              <ButtonPrimary
                class="small"
                @click.native="userCollectionsStore.create(true)"
                :disabled="userCollectionsStore.collectionName.length === 0"
              >
                Create
              </ButtonPrimary>
            </template>
          </modal-component>
        </div>
        <div v-if="step === 3" class="step3">
          <div class="select-block-radio">
            <div
              :class="`radio-item ${!fitChartsStore.isCustom ? 'active' : ''}`"
              @click="fitChartsModeSelect('standart')"
            >
              Standart
            </div>
            <div
              :class="`radio-item ${fitChartsStore.isCustom ? 'active' : ''} ${demoMode ? 'disabled' : ''}`"
              @click="fitChartsModeSelect('custom')"
            >
              Custom
            </div>
          </div>
          <div class="size">
            <div class="input-block">
              <Dropdown
                :options="fitChartsOptions"
                v-model="valueOption"
                label="Size chart"
                option-label="name"
                placeholder="Choose..."/>
            </div>
            <div class="input-block red">
              <Dropdown
                :disable="!fitChartSizes?.length"
                :options="fitChartSizes"
                v-model="sizeOption"
                label="Choose size"
                option-label="name"
                placeholder="Choose..."/>
            </div>
          </div>
        </div>
        <div v-if="step === 4" class="step4">
          <p class="subtitle">Choose one from the options below</p>
          <ButtonSecondary @click.native="goDesigner">
            <template v-slot:icon>
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24">
                <path fill="#6f8789" d="m19.8 6.8-2.6-2.6c-.7-.7-1.8-.7-2.5 0L3.5 15.5c-.1.1-.2.3-.2.5v4c0 .4.3.8.8.8h4c.2 0 .4-.1.5-.2L19.8 9.2c.3-.3.5-.8.5-1.2 0-.5-.1-.9-.5-1.2zM7.7 19.3H4.8v-2.9L12 9.1l2.9 2.9-7.2 7.3zM18.8 8.2 16 10.9 13.1 8l2.8-2.8c.1-.1.2-.1.4 0l2.6 2.6c-.1.1-.1.2-.1.2v.2z"/>
              </svg>
            </template>
            <div class="center">
              Design manually
            </div>
            <template v-slot:lastIcon>
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24" width="24" height="24">
                <path fill="#09373d" d="M10 16.8c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L13 12 9.5 8.5c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4c.3.3.3.8 0 1.1l-4 4c-.2.2-.4.3-.6.3z"/>
              </svg>
            </template>
          </ButtonSecondary>
          <ButtonSecondary disabled class="left">
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24" width="24" height="24">
                <path d="M17.6 20.8c-.9 0-1.8-.3-2.5-1L4.5 9.2c-.7-.7-1-1.5-1-2.5s.4-1.8 1-2.5c1.4-1.4 3.6-1.4 4.9 0L20 14.8c1.4 1.4 1.4 3.6 0 5-.7.7-1.5 1-2.4 1zM6.9 4.7c-.5 0-1 .2-1.4.6-.3.4-.6.9-.6 1.4s.2 1 .6 1.4l10.6 10.6c.8.8 2.1.8 2.8 0 .8-.8.8-2 0-2.8L8.4 5.3c-.4-.4-.9-.6-1.5-.6zm8.9 2.6c-.3 0-.5-.1-.6-.4l-.7-1.5-1.5-.7c-.2-.1-.4-.4-.4-.6s.1-.5.4-.6l1.5-.7.7-1.5c.2-.5 1-.5 1.3 0l.7 1.5 1.5.7c.2 0 .3.2.3.5s-.1.5-.4.6l-1.5.7-.7 1.5c-.1.3-.4.5-.6.5zm-.3-.9zm.5 0zM13.9 4l1 .5.3.3.5 1 .5-1 .3-.3 1-.5-1-.5-.3-.3-.5-1-.5 1-.3.3-1 .5zm.6 1.4zm2.5 0zm-2.7-.1zm1.2-3.7zm.5 0zm3.8 9.7c-.3 0-.5-.1-.6-.4l-.5-.9-.8-.4c-.2-.1-.4-.4-.4-.6 0-.3.1-.5.4-.6l.8-.4.4-.8c.2-.5 1-.5 1.3 0l.4.8.8.4c.3.1.4.3.4.6s-.1.5-.4.6l-.8.4-.4.8c-.1.3-.4.5-.6.5zm-.3-.9zm.5 0zM18.9 9l.4.2.3.3.2.4.2-.4.3-.3.3-.2-.4-.2-.3-.3-.2-.4-.2.4-.3.3-.3.2zm-.2-1zm.1-.1zm1.9 0zm-1.2-.3zm.5 0zM5.8 18.3c-.3 0-.5-.1-.6-.4l-.5-.9-.8-.4c-.2-.1-.4-.4-.4-.6s.1-.5.4-.6l.8-.4.4-.8c.2-.5 1-.5 1.3 0l.4.8.8.4c.3.1.4.3.4.6s-.1.5-.4.6l-.8.4-.4.8c-.1.3-.4.5-.6.5zM4.9 16l.4.2.3.3.2.4.2-.4.3-.3.3-.2-.4-.2-.3-.3-.2-.4-.2.4-.3.3-.3.2zm2.3.3zm-2.9 0zm2.9-.6zm-2.9 0z"/>
              </svg>
            </template>
            <div class="center">
              Design with AI
              <div class="comming">
                Coming soon
              </div>
            </div>
            <template v-slot:lastIcon>
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24" width="24" height="24">
                <path d="M10 16.8c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L13 12 9.5 8.5c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4 4c.3.3.3.8 0 1.1l-4 4c-.2.2-.4.3-.6.3z"/>
              </svg>
            </template>
          </ButtonSecondary>
        </div>
      </div>
      <div :class="`bottom_buttons ${fixedBlockStyle}`" ref="buttons">
        <ButtonSecondary
          v-if="step !== 1"
          style="width:212px"
          @click.native="step--"
        >
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 21 20" width="21" height="20">
            <path fill="#09373d" d="M14.7 9.3H8.1l2.1-2.1c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0L5.8 9.5c-.1 0-.1.1-.2.2 0 .1-.1.2-.1.3s0 .2.1.3c0 .1.1.2.2.2l3.3 3.3c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L8 10.6h6.5c.4 0 .8-.3.8-.8s-.2-.5-.6-.5z"/>
          </svg>
        </template>
          Back
        </ButtonSecondary>
        <ButtonPrimary
          v-if="step !== 4"
          style="width:212px"
          @click.native="step++"
          :disabled="disabledNextButton"
        >
          Next
        </ButtonPrimary>
      </div>
    </div>
  </layout-user>
</template>

<script>
import LayoutUser from '@/layouts/LayoutUser'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import Dropdown from '@/components/Atoms/Dropdown.vue'
import ModalComponent from '@/components/Atoms/ModalComponent'
import InputField from '@/components/Atoms/InputField.vue'
import { useUserCollectionsStore } from '@/store/pinia/userCollections'
import { useFitChartsStore } from '@/store/pinia/fitCharts'
import { mapStores } from 'pinia'
import { mapGetters } from 'vuex'

import {
  fetchCategories,
  fetchRootCategories,
  createMannequin,
} from '@/api'

export default {
  components: {
    LayoutUser,
    ButtonPrimary,
    ButtonSecondary,
    Dropdown,
    ModalComponent,
    InputField,
  },
  data: () => ({
    collectionName: null,
    newCollectionModal: false,
    valueOption: null,
    selectedCat: null,
    step: 1,
    rootCategories: null,
    categories: null,
    activeRootCategory: null,
    scrolledY: 0,
    sizeOption: null,
    collections: null,
    title: ['Create new design', 'Choose collection', 'Choose fit chart', 'How would you like to proceed?'],
    elementHeight: null,
    buttonsElement: null,
    searchTimer: null,
  }),
  async created () {
    this.rootCategories = await fetchRootCategories()
    this.categories = await fetchCategories()
    this.activeRootCategory = this.rootCategories[0]
    this.userCollectionsStore.collectionsParams.offset = 0
    this.userCollectionsStore.collectionsParams.sortDirection = 'desc'
    this.userCollectionsStore.allList = []
    await this.userCollectionsStore.getList(true)
    this.fitChartsStore.isCustom = false
  },
  watch: {
    async step (val) {
      if (val === 3) {
        const catNorm = this.activeRootCategory.name.charAt(0).toUpperCase() + this.activeRootCategory.name.slice(1).toLowerCase()
        const category = this.fitChartsStore.types.find(el => el.title === catNorm)
        this.fitChartsStore.setMannequinType(category.key, this.fitChartsStore.isCustom ? '' : 'fitChartsStandart')
      }
      this.setHeight()
    },
    activeRootCategory () {
      this.setHeight()
      this.valueOption = null
    },
  },
  computed: {
    ...mapGetters('user', ['demoMode', 'subscriptionPlan']),
    ...mapStores(useFitChartsStore),
    ...mapStores(useUserCollectionsStore),
    fitChartSizes () {
      return this.valueOption?.mannequins || []
    },
    fitChartsOptions () {
      if (this.fitChartsStore.isCustom) return this.fitChartsStore.customCharts.list
      else return this.fitChartsStore.standartCharts.list
    },
    searchCollection: {
      get () {
        return this.userCollectionsStore.collectionsParams.q
      },
      set (value) {
        this.userCollectionsStore.collectionsParams.q = value
        clearTimeout(this.searchTimer)
        this.searchTimer = window.setTimeout(async () => {
          this.userCollectionsStore.collectionsParams.offset = 0
          this.userCollectionsStore.allList = []
          await this.userCollectionsStore.getList(true)
        }, 500)
      },
    },
    disabledNextButton () {
      if (this.step === 1 && !this.selectedCat) return true
      if (this.step === 2 && !this.userCollectionsStore.selectedCollection) return true
      if (this.step === 3 && !this.sizeOption) return true
      return false
    },
    fixedBlockStyle () {
      const buttonsElementHeight = this.buttonsElement?.offsetHeight + 90
      if (
        this.scrolledY > this.elementHeight - window.innerHeight + buttonsElementHeight
      ) {
        return 'stop'
      } return ''
    },
    currentCategories () {
      return this.categories?.filter(cat => {
        // if (this.$store.state.isProduction && cat.active === '0') return
        return cat.parentCode === this.activeRootCategory?.code
      })
    },
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  async mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.buttonsElement = this.$refs.buttons
  },
  methods: {
    fitChartsModeSelect (mode) {
      this.valueOption = null
      if (mode === 'custom') {
        this.fitChartsStore.isCustom = true
        this.fitChartsStore.getList()
      } else {
        this.fitChartsStore.isCustom = false
        this.fitChartsStore.getListStandart()
      }
    },
    async setOffset () {
      let loaded = this.userCollectionsStore.collectionsParams.offset + this.userCollectionsStore.collectionsParams.limit
      if (this.userCollectionsStore.count / loaded > 1) {
        this.userCollectionsStore.collectionsParams.offset = this.userCollectionsStore.collectionsParams.offset + this.userCollectionsStore.collectionsParams.limit
        await this.userCollectionsStore.getList(true)
      }
    },
    async goDesigner () {
      const { mannequinId } = await createMannequin(this.sizeOption.params, this.sizeOption.type)
      this.$router.push(
        {
          name: 'PageDesigner',
          params: {
            designCategory: this.selectedCat,
            mannequinId: mannequinId,
            mannequinType: this.sizeOption.type,
            mannequinName: this.sizeOption.name,
            fitChartsIsCustom: this.fitChartsStore.isCustom,
            mannequinIdNew: this.sizeOption.mannequinId,
            fitChartsId: this.valueOption._id,
            isNew: true,
          },
        },
      )
    },
    setHeight () {
      setTimeout(() => {
        const blockElement = this.$refs.block
        this.elementHeight = blockElement?.offsetTop + blockElement?.offsetHeight
      }, 1)
    },
    handleScroll () {
      this.scrolledY = window.scrollY
    },
  },
}
</script>

<style lang="scss">
@import "src/styles/mixins";
.new-designer-page {
  max-width: 1166px;
  margin: auto;
  padding: 24px 16px;
  font-size: 14px;
  &__categoryImg {
    height: 100%;
    width: 100%;
    @include flex(center, center);
  }
  &__categoryImage {
    // height: 100%;
    width: 100%;
    // @include flex(center, center);
    line, path {
      stroke: #4b5b5e;
    }
  }
  .comming {
    padding: 4px 8px;
    border-radius: 40px;
    background: var(--Surface-Hover);
    font-size: 10px;
    color: var(--Text-Text_Secondary);
    text-align: center;
    margin-left: 4px;
  }
  .step4 {
    .subtitle {
      text-align: center;
      color: var(--Text-Text_Secondary);
      margin-bottom: 56px;
    }
    .btn {
      width: 418px;
      font-weight: 400;
      justify-content: space-between;
      margin: auto;
      &:last-child {
        margin: 16px auto 0;
      }
      .center {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  .step2 {
    width: 442px;
    margin: auto;
    .dropdown-add-option {
      cursor: pointer;
      @include flex(center);
      padding: 16px 8px;
      font-weight: 600;
      color: var(--Primary-Shades-Primary);
    }
  }
  .step3 {
    @include flex(center, flex-start, column, 31px);
    .select-block-radio .radio-item {
      width: 69px;
    }
    .size {
      display: flex;
      column-gap: 40px;
      row-gap: 16px;
      width: 684px;
      .input-block {
        margin-bottom: 0;
        &:first-child {
          width: 100%;
        }
        &:last-child {
          min-width: 202px;
        }
      }
    }
  }
  .block-page {
    margin-bottom: 90px;
  }
  .bottom_buttons {
    position: fixed;
    border-top: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
    padding: 16px;
    @include flex(flex-start, center, row, 16px);
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FAFBFB;
  }
  .bottom_buttons.stop {
    position: absolute;
    bottom: 0;
  }
  p {
    margin: 0;
  }
  h2 {
    @include font-styles(24px, 36px, 600, Montserrat);
  }
  .select-block-radio {
    margin: 17px 0 32px;
    .radio-item {
      width: 100%;
    }
  }
  .choose {
    color: var(--Text-Text_Secondary);
    margin-bottom: 14px;
  }
  &__steps {
    margin-bottom: 8px;
    position: relative;
    .exit {
      position: absolute;
      width: fit-content;
      cursor: pointer;
      @include flex(center);
      svg {
        margin-right: 4px;
        min-width: 20px;
      }
      p {
        font-weight: 600;
      }
    }
    .step {
      text-align: center;
    }
  }
  &__cats {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 40px;
    column-gap: 32px;
    .cat.soon {
      color: var(--Button-States-Disabled_Txt);
      pointer-events: none;
      path, line {
        stroke: var(--Button-States-Disabled_Txt) !important;
      }
    }
    .cat.active {
      border-color: var(--Active-State-Strokes);
      &:hover {
        .radio-button-item {
          border-color: #FF6770;
        }
      }
      .radio-button-block {
        .radio-button-item {
          border-color: #FF6770;
          &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #FF6770;
          }
        }
        label {
          font-weight: 600;
          color: #FF6770;
        }
      }
    }
    .cat {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      border-radius: 12px;
      border: 1px solid var(--Dividers);
      padding: 8px;
      .radio-button-block {
        @include flex(center);
        border-bottom: 1px solid var(--Dividers);
        padding-bottom: 6px;
        margin-bottom: 8px;
        label {
          cursor: pointer;
        }
        .radio-button-item {
          margin-right: 8px;
          @include flex(center, center);
          position: relative;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid #E0E9EA;
        }
      }
      &:hover {
        .radio-button-item {
          border-color: #0E4D56;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .new-designer-page {
    &__cats {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 16px;
    }
  }
}
@media (max-width: 760px) {
  .new-designer-page {
    &__cats {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 16px;
    }
    .root-cat {
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 8px;
      .radio-item  {
        border-radius: 8px;
        width: calc((100% - 32px) / 3);
      }
    }
    .step3 {
      .size {
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }
}
@media (max-width: 540px) {
  .new-designer-page {
    &__steps .step {
      padding-top: 40px;
    }
    &__cats {
      grid-template-columns: repeat(2, 1fr);
    }
    .root-cat {
      .radio-item  {
        width: calc((100% - 16px) / 2);
      }
    }
    .step2 {
      width: 100%;
    }
    .step3 {
      .select-block-radio {
        width: 100%;
        .radio-item {
          width: 100%;
        }
      }
      .size {
        .input-block:last-child {
          width: 100%;
        }
      }
    }
    .step4 {
      .subtitle {
        margin-bottom: 63px;
      }
      .btn {
        width: 100%;
        &:last-child {
          margin: 8px auto 0;
        }
      }
    }
  }
}
</style>
