<template>
  <q-dialog
    v-model="displayDialog"
    persistent
    class="ServiceErrorDialog"
    @hide="$emit('close')"
  >
    <dialog-card-base
      :title="title"
      :style="{width: '450px'}"
    >
      <div class="text-body1 text-center q-pa-md">
        {{ message }}
      </div>

      <template v-slot:actions>
        <div class="row flex-center q-gutter-md">
          <q-btn
            color="secondary"
            size="md"
            @click.prevent="$emit('try-again')"
          >
            Try again
          </q-btn>
          <q-btn
            size="md"
            @click.prevent="$emit('close')"
          >
            close
          </q-btn>
        </div>
      </template>
    </dialog-card-base>
  </q-dialog>
</template>

<script>
import DialogCardBase from '@/components/Dialog/DialogCardBase'

export default {
  name: 'ServiceErrorDialog',

  components: {
    DialogCardBase,
  },

  props: {
    title: {
      type: String,
      default: 'Service unavailable',
    },
    message: {
      type: String,
      default: 'Seems like something is broken. Try again later',
    },
  },

  data: () => ({
    displayDialog: true,
  }),
}
</script>

<style scoped>

</style>
