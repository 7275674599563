<template>
  <div class="request-demo-page">
    <div class="container">
      <div class="form">
        <div class="logo" @click="$router.push({ path: '/' })">
          <img :src="require('@/assets/home/logo-color.png')" alt="Patternfast" class="color" />
        </div>
        <h2>Request a Demo</h2>
        <h1>Experience the PatternFast for&nbsp;your&nbsp;enterprise</h1>
        <div class="row">
          <InputField type="text" v-model="form.firstName">
            <template v-slot:top-label>First name</template>
          </InputField>
          <InputField type="text" v-model="form.lastName">
            <template v-slot:top-label>Last name</template>
          </InputField>
          <InputField type="text" v-model="form.email">
            <template v-slot:top-label>E-mail</template>
          </InputField>
          <PhoneField v-model="form.phone" @validate="phoneValidation">
            <template v-slot:top-label>Phone number</template>
          </PhoneField>
          <InputField type="text" v-model="form.company">
            <template v-slot:top-label>Company name</template>
          </InputField>
          <InputField type="text" v-model="form.position">
            <template v-slot:top-label>Your position</template>
          </InputField>
          <InputField type="number" v-model="form.licenses">
            <template v-slot:top-label>Perspective licenses (number)</template>
          </InputField>
          <SelectField
            :value="form.revenue"
            :options="companyRevenueOptions"
            placeholder="Choose..."
            @change="form.revenue = $event"
          >
            <template v-slot:top-label>Company revenue</template>
          </SelectField>
          <TextareaField v-model="form.message">
            <template v-slot:top-label>Message (optional)</template>
          </TextareaField>
          <ButtonPrimary :disabled="!formIsValid" @click.native="send()">Send Request</ButtonPrimary>
        </div>
      </div>
      <div class="picture">
        <figure>
          <img :src="require('@/assets/home/request.jpg')" alt="" />
        </figure>
        <div class="text">
          "PatternFast revolutionized our bespoke bridal and evening wear process with a smart, AI powered design system that scales custom gowns based on size, style, and budget.<br><br>
          It's been a game-changer for COUTURME."<br><br>
          CouturMe
        </div>
      </div>
    </div>
    <ModalComponent :show="formComplete" @close="formComplete = !formComplete">
      <template v-slot:title>Thank you!</template>
      <template v-slot:description>You request sent</template>
    </ModalComponent>
    <TheFooter />
  </div>
</template>

<script>
import { request } from '@/api'
import InputField from '@/components/Atoms/InputField.vue'
import PhoneField from '@/components/Atoms/PhoneField.vue'
import SelectField from '@/components/Atoms/SelectField.vue'
import TextareaField from '@/components/Atoms/TextareaField.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  name: 'RequestDemo',
  components: {
    InputField,
    PhoneField,
    SelectField,
    TextareaField,
    ButtonPrimary,
    ModalComponent,
    TheFooter,
  },
  data: () => ({
    formComplete: false,
    phoneIsValid: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      position: '',
      licenses: '',
      revenue: '',
      message: '',
    },
    companyRevenueOptions: [
      { title: '$0-100k' },
      { title: '$100k-500k' },
      { title: '$500k-1m' },
      { title: '$1m-2m' },
      { title: '$2m-5m' },
      { title: '$5m-10m' },
      { title: '$10m+' },
    ],
  }),
  computed: {
    formIsValid () {
      const firstName = this.form.firstName.length > 0
      const lastName = this.form.lastName.length > 0
      const company = this.form.company.length > 0
      const position = this.form.position.length > 0
      const licenses = parseInt(this.form.licenses) > 0
      const revenue = this.form.revenue.length > 0
      const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
      const email = emailRegExp.test(this.form.email)
      return firstName && lastName && company && position && licenses && revenue && email && this.phoneIsValid
    },
  },
  methods: {
    phoneValidation (e) {
      this.phoneIsValid = e === true ? e : false
    },
    async send () {
      try {
        await request.post(
          '/user/contact-email',
          JSON.stringify(this.form),
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          },
        )
        this.formComplete = true
      } catch (e) {
        console.warn(e.message)
      }
    },
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
.request-demo-page {
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0;

    flex-direction: row;
    align-items: stretch;
  }

  .form {
    width: 100%;
    padding: 10px 80px 200px 80px;

    .logo {
      cursor: pointer;
      img {
        width: 110px;
        height: 52px;
        margin-bottom: 60px;
      }
    }

    h2 {
      color: var(--Text-Text_Secondary);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.18px;
      margin: 0;
      margin-bottom: 10px;
    }
    h1 {
      max-width: 620px;
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.9px;
      margin: 0;
      margin-bottom: 25px;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 16px;

      .input-field, .phone-field, .select-field {
        max-width: 290px;
      }

      .textarea-field {
        max-width: 596px;
        margin-bottom: 14px;
      }
    }
  }

  .picture {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 590px;
    min-height: 960px;
    padding: 60px 80px;

    display: flex;
    flex-direction: column;
    justify-content: end;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.70) 74.53%);

    .text {
      position: relative;
      z-index: 1;
      color: #FFF;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      letter-spacing: -0.54px;
    }

    figure {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      margin: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 480px) {
  .request-demo-page {
    .container {
      flex-direction: column;
    }

    .form {
      padding: 20px 20px 80px 20px;

      .logo {
        img {
          margin-bottom: 42px;
        }
      }
      h1 {
        font-size: 20px;
        margin-bottom: 40px;
      }
      .row {
        flex-direction: column;

        .input-field, .phone-field, .select-field {
          max-width: 100%;
        }

        .textarea-field {
          max-width: 100%;
          margin-bottom: 30px;
        }
      }
    }
    .picture {
      max-width: 100%;
      min-height: 650px;
      padding: 40px 20px;
    }
  }
}
</style>
