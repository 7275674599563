<template>
  <div>
  <div class="profile" v-if="userStore.isLoggedIn" @click="$router.push({ name: 'userCollections' })">
    <span class="name">{{ userStore.data.firstName }} {{ userStore.data.lastName?.charAt(0) }}</span>
    <figure>
      <img :src="userStore.userPic.data" v-if="userStore.userPic.type === 'image'">
      <div class="userpic-placeholder" v-if="userStore.userPic.type === 'text'">
        <span>{{ userStore.userPic.data }}</span>
      </div>
    </figure>
  </div>
</div>
</template>

<script>
import { useUserStore } from '@/store/pinia/user'
import { mapStores } from 'pinia'

export default {
  computed: mapStores(useUserStore),
  beforeMount () {
    this.userStore.get()
  },
}
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: var(--Text-Text_Primary) !important;

  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  figure {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    overflow: hidden;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .userpic-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
      background: var(--avatar-background-shades-1);

      color: var(--Text-Text_White);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

  }
}
@media (max-width: 480px) {
  .profile .name {
    display: none;
  }
}
</style>
