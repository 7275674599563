<template>
  <layout-user>
    <div class="fit-model-page">
      <div class="close" @click="back">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20">
          <path fill="#09373D" d="M5 4.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L8.937 10 4.47 14.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.062 0L10 11.062l4.469 4.47a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.063L11.062 10l4.47-4.469a.75.75 0 0 0 0-1.062A.75.75 0 0 0 15 4.25a.75.75 0 0 0-.531.219L10 8.937 5.531 4.47A.75.75 0 0 0 5 4.25z"/>
        </svg>
        <span>Close</span>
      </div>
      <div class="fit-model-container">
        <h1>Edit fit model</h1>
        <ButtonsGroup class="size-buttons">
          <button
            :class="sizeSwitcher === 'pattern' ? 'active' : ''"
            @click="setSwitcher('pattern')"
          >
            Edit size
          </button>
          <button
            :class="sizeSwitcher === 'custom' ? 'active' : ''"
            @click="setSwitcher('custom')"
            :disabled="demoMode"
          >
            My fit models
          </button>
        </ButtonsGroup>
        <div class="units-buttons">
          <div class="sizes" v-if="sizeSwitcher === 'pattern'">
            <Dropdown
              :options="fitChartsStore.selectedChart?.mannequins"
              v-model="fitChartsStore.selectedMannequin"
              @input="selectSize"
              label="Pattern Size"
              option-label="name"
              placeholder="Choose..."/>
            <span>Fit Chart</span>
          </div>
          <div class="sizes" v-else>
            <Dropdown
              class="custom-sizes"
              :options="fitModelsStore.modelsForEdit[type]"
              v-model="fitModelsStore.selectedModel"
              @input="selectSize"
              label="Choose fit model"
              option-label="name"
              placeholder="Choose..."
              :loading="fitModelsStore.loading"
              :need-loading="fitModelsStore.countPageFitModel / fitModelsStore.paramsPageFitModel.offset + fitModelsStore.paramsPageFitModel.limit > 1"
              @ended="setOffset"
            />
          </div>
          <units-toggle-component />
        </div>
        <div class="table" v-if="labels" ref="table">
          <div class="table-row title-row pointName">
            <p>Points of measure</p>
            <p>Customize</p>
          </div>
          <div class="table-row" v-for="(l) in labels" :key="l.name">
            <div class="table-row__collumn name">
              <div class="col nameText">
                <span class="named">
                  {{ l.name }}
                  <div
                    v-if="
                      (fields[l.key].cm || fields[l.key].cm === 0) &&
                      fields[l.key].cm !== selectedSizeDefaultVal?.[l.key]?.cm"
                    class="changed" />
                </span>
                <span class="validation" v-if="l.key !== 'bellyProtuberance' && l.key !== 'buttocks'">
                  {{ !isNaN(fields[l.key].min.cm) ? `from ${fields[l.key].min[units]} ${units}` : '' }}
                  {{ !isNaN(fields[l.key].max.cm) ? `to ${fields[l.key].max[units]} ${units}` : '' }}
                </span>
              </div>
              <svg @click="showInfo(l)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18">
                <path fill="#6F8789" d="M9 1.256a7.744 7.744 0 1 0 0 15.488A7.744 7.744 0 0 0 9 1.256zM0 9a9 9 0 1 1 18 0A9 9 0 0 1 0 9Zm9-3.558a.942.942 0 0 0-.942.942.628.628 0 1 1-1.256 0 2.198 2.198 0 1 1 3.316 1.892c-.165.098-.3.204-.39.308-.085.102-.1.168-.1.207v1.046a.628.628 0 0 1-1.256 0V8.791c0-.424.185-.768.4-1.02.21-.248.473-.438.706-.576A.941.941 0 0 0 9 5.442zm0 7.744a.837.837 0 1 0 0-1.674.837.837 0 0 0 0 1.674z"/>
              </svg>
            </div>
            <div class="table-row__collumn">
              <p class="table-row__collumn__title">Customize</p>
              <template v-if="l.key !== 'bellyProtuberance' && l.key !== 'buttocks'">
                <InputField
                  type="text"
                  :after="units"
                  :placeholder="selectedSizeDefaultVal?.[l.key]?.[units] || 0"
                  :value="fields[l.key][units]"
                  :disabled="isNaN(fields[l.key].min.cm) || isNaN(fields[l.key].max.cm)"
                  :class="[fields[l.key].isActive && !fields[l.key].isValid && fields[l.key][units] !== '' ? 'errored' : '']"
                  @change="change($event, l.key)"
                />
              </template>
              <template v-else>
                <SelectField
                  placeholder="0"
                  :value="fields[l.key].cm"
                  :options="dropdowns[l.key]"
                  :disabled="isNaN(fields[l.key].min.cm) || isNaN(fields[l.key].max.cm)"
                  @change="changeDropDown($event, l.key)"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div :class="`bottom_buttons ${fixedBlockStyle}`" ref="buttons">
        <div class="bottom_buttons__container">
          <div class="reset_button" @click="resetAll">
            <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20" width="20" height="20">
              <path fill="#09373d" d="M16.1 5.7c-.9-1.3-2.2-2.3-3.7-2.8s-3.1-.5-4.6 0c-1 .3-2 .9-2.8 1.7V2.5c0-.4-.3-.8-.8-.8s-.8.4-.8.8v4.2c0 .4.3.8.8.8h4.2c.4 0 .8-.3.8-.8s-.5-.8-.9-.8H5.7c.7-.7 1.5-1.2 2.4-1.5 1.2-.4 2.5-.4 3.7 0 1.2.4 2.2 1.2 3 2.2.7 1 1.1 2.3 1.1 3.5 0 1.3-.5 2.5-1.2 3.5-.8 1-1.9 1.7-3.1 2.1-1.2.3-2.5.3-3.7-.2-1.2-.4-2.2-1.3-2.9-2.3-.2-.3-.7-.4-1-.2s-.4.7-.2 1c.9 1.3 2.1 2.4 3.6 2.9.8.3 1.7.5 2.6.5.7 0 1.4-.1 2-.3 1.5-.4 2.9-1.4 3.8-2.6 1-1.3 1.5-2.8 1.5-4.4.1-1.5-.3-3.1-1.2-4.4z"/>
            </svg>
            Reset
          </div>
          <ButtonPrimary class="small main" @click.native="apply" :disabled="!isValid">
            Apply changes
          </ButtonPrimary>
          <ButtonSecondary
            class="small"
            @click.native="demoMode ? null : saveModalShow = true"
            v-if="sizeSwitcher === 'pattern'"
            :disabled="!isValid || demoMode"
          >
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 21 20" width="21" height="20">
                <path d="M13 16.8H3.8s-.1 0-.1-.1V7.5c0-.4-.3-.8-.8-.8s-.7.4-.7.8v9.2c0 .9.7 1.6 1.6 1.6H13c.4 0 .8-.3.8-.8s-.4-.7-.8-.7z"/>
                <path d="M17.2 1.8h-10c-.9 0-1.6.7-1.6 1.6v10c0 .9.7 1.6 1.6 1.6h10c.9 0 1.6-.7 1.6-1.6v-10c0-.9-.8-1.6-1.6-1.6zm0 11.5c0 .1 0 .1 0 0l-10 .1s-.1 0-.1-.1v-10s0-.1.1-.1h10s.1 0 .1.1v10z"/>
                <path d="M14.7 7.6h-1.8V5.8c0-.4-.3-.8-.8-.8s-.8.3-.8.8v1.8H9.7c-.4 0-.8.3-.8.8s.3.8.8.8h1.8V11c0 .4.3.8.8.8s.8-.3.8-.8V9.1h1.8c.4 0 .8-.3.8-.8s-.6-.7-1-.7z"/>
              </svg>
            </template>
            Save as profile
          </ButtonSecondary>
        </div>
      </div>
      <modal-component :show="saveModalShow" type="success" showIcon @close="saveModalShow = false">
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 33">
            <path d="M20 27.8H5.3c-.3 0-.6-.3-.6-.6V12.5c0-.4-.3-.8-.8-.8s-.8.3-.8.8v14.7c0 1.1.9 2.1 2.1 2.1H20c.4 0 .8-.3.8-.8s-.4-.7-.8-.7z" class="st0"/>
            <path d="M26.7 3.8h-16c-1.1 0-2.1.9-2.1 2.1v16c0 1.1.9 2.1 2.1 2.1h16c1.1 0 2.1-.9 2.1-2.1v-16c0-1.2-1-2.1-2.1-2.1zm.5 18c0 .3-.3.6-.6.6h-16c-.3 0-.6-.3-.6-.6v-16c0-.3.3-.6.6-.6h16c.3 0 .6.3.6.6v16z" class="st0"/>
            <path d="M22.7 13.1h-3.2V9.8c0-.4-.3-.8-.8-.8s-.8.3-.8.8V13h-3.3c-.4 0-.8.3-.8.8s.3.8.8.8h3.3v3.2c0 .4.3.8.8.8s.8-.3.8-.8v-3.2h3.2c.4 0 .8-.3.8-.8s-.4-.7-.8-.7z" class="st0"/>
          </svg>
        </template>
        <template v-slot:title>Save as new profile</template>
        <template v-slot:description>Create name for new profile</template>
        <template v-slot:extra>
          <InputField
            type="text"
            v-model="profileName"
          >
            <template v-slot:top-label>Profile name</template>
          </InputField>
        </template>
        <template v-slot:buttons>
          <ButtonSecondary class="small" @click.native="saveModalShow = false">
            Cancel
          </ButtonSecondary>
          <ButtonPrimary class="small" @click.native="saveModel">
            Save
          </ButtonPrimary>
        </template>
      </modal-component>
      <modal-component
        :show="continueModalShow"
        type="success" showIcon
        @close="continueModalShow = false"
        class="continue-modal"
      >
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" width="29" height="29">
            <path fill="#09373D" fill-rule="evenodd" d="M14.667 2.333c-6.812 0-12.334 5.522-12.334 12.334C2.333 21.478 7.855 27 14.667 27 21.478 27 27 21.478 27 14.667c0-6.812-5.522-12.334-12.333-12.334ZM.333 14.668C.333 6.75 6.751.333 14.667.333 22.583.333 29 6.751 29 14.667 29 22.583 22.583 29 14.667 29S.333 22.583.333 14.667ZM14.667 9a1.5 1.5 0 0 0-1.5 1.5 1 1 0 0 1-2 0 3.5 3.5 0 1 1 5.28 3.014 2.483 2.483 0 0 0-.62.49c-.137.162-.16.268-.16.33V16a1 1 0 0 1-2 0v-1.666c0-.675.295-1.222.635-1.623a4.424 4.424 0 0 1 1.127-.918A1.499 1.499 0 0 0 14.667 9Zm0 12.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.666Z" clip-rule="evenodd"/>
          </svg>
        </template>
        <template v-slot:title>Would you like to proceed with created profile? </template>
        <template v-slot:buttons>
          <ButtonSecondary class="small" @click.native="proceedWithProfile">
            Proceed with profile
          </ButtonSecondary>
          <ButtonPrimary class="small" @click.native="continueModalShow = false">
            Continue editing size
          </ButtonPrimary>
        </template>
      </modal-component>
      <modal-component
        class="image-modal"
        :show="imgModal.show"
        :showIcon="false"
        @close="imgModal.show = false"
      >
        <template v-slot:title v-if="imgModal.error">Sorry, no help image were found.</template>
        <template v-slot:extra v-else>
          <img :style="`max-height:${imgHeight}px;`" :src="imageUrl()" />
        </template>
      </modal-component>
    </div>
  </layout-user>
</template>

<script>
import LayoutUser from '@/layouts/LayoutUser'
import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'
import Dropdown from '@/components/Atoms/Dropdown.vue'
import InputField from '@/components/Atoms/InputField.vue'
import SelectField from '@/components/Atoms/SelectField.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ModalComponent from '@/components/Atoms/ModalComponent'
import UnitsToggleComponent from '@/components/Units/UnitsToggleComponentNew'

import { mapState, mapGetters, mapActions } from 'vuex'
import { constants, measurements, fields } from 'sizelib'
import {
  createMannequin,
} from '@/api'
import { mapStores } from 'pinia'
import { useFitChartsStore } from '@/store/pinia/fitCharts'
import { useFitModelsStore } from '@/store/pinia/fitModels'

let size

export default {
  components: {
    LayoutUser,
    ButtonsGroup,
    Dropdown,
    InputField,
    SelectField,
    ButtonPrimary,
    ButtonSecondary,
    ModalComponent,
    UnitsToggleComponent,
  },
  data: () => ({
    imgModal: {
      show: false,
      error: false,
      link: '',
    },
    fields: null,
    type: 'FEMALE',
    scrolledY: 0,
    saveModalShow: false,
    profileName: null,
    userSizes: [],
    selectedUserSize: null,
    paramsUserSize: null,
    normalParamsUserSize: {},
    continueModalShow: false,
    createdSize: null,
    tableData: null,
    userSizesCount: null,
    userSizesLoading: false,
    userSizesPage: 1,
  }),
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  async mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.tableData = this.$refs.table
  },
  async created () {
    this.type = this.$route.params.type
    this.userSizesLoading = true
    this.fitModelsStore.paramsPageFitModel.type = this.type
    if (!this.fitModelsStore.modelsForEdit[this.type]?.length) {
      await this.fitModelsStore.getListFitModelPage()
    }
    this.userSizesLoading = false
    if (!this.fitModelsStore.selectedModel || this.fitModelsStore.selectedModel.type !== this.type) {
      this.fitModelsStore.selectedModel = this.fitModelsStore.modelsForEdit[this.type][0]
    }
    if (this.$route.params.isCustomModel) {
      this.setGlobalSwitcher('custom')
      if (this.fitModelsStore.selectedModel?.params) {
        this.selectSize(this.fitModelsStore.selectedModel)
      }
    } else {
      this.setGlobalSwitcher('pattern')
      if (this.fitChartsStore.selectedMannequin?.params) {
        this.selectSize(this.fitChartsStore.selectedMannequin)
      }
    }
    this.resetAll()
  },
  beforeMount () {
    size = measurements[this.key]()
    this.export()
  },
  watch: {
    selectedSizeDefaultVal (val) {
      if (val && val.bellyProtuberance && val.buttocks) {
        this.changeDropDown(val.bellyProtuberance.cm, 'bellyProtuberance')
        this.changeDropDown(val.buttocks.cm, 'buttocks')
      }
    },
  },
  computed: {
    ...mapStores(useFitChartsStore),
    ...mapStores(useFitModelsStore),
    imgHeight () {
      return window.innerHeight - 150
    },
    ...mapGetters('user', ['demoMode']),
    ...mapState('fitModel', ['sizeSwitcher']),
    ...mapState(['units']),
    isValid () {
      let valid = true
      let OneVal = 0
      const isCustom = this.$route.params.isCustomModel
      const manName = this.$route.params.size
      const isDefault = (isCustom && this.fitModelsStore.selectedModel?.name === manName && this.sizeSwitcher === 'custom') ||
        (!isCustom && this.fitChartsStore.selectedMannequin?.name === manName && this.sizeSwitcher === 'pattern')
      for (let key in this.fields) {
        if (
          (this.fields[key]?.cm || this.fields[key]?.cm === 0) &&
          this.fields[key]?.cm !== this.selectedSizeDefaultVal?.[key]?.cm
        ) {
          OneVal++
          if (!this.fields[key]?.isValid) valid = false
        }
      }
      if (isDefault && OneVal) {
        return valid
      } else if (!isDefault) {
        return valid
      } else return false
    },
    selectedSizeDefaultVal () {
      if (this.sizeSwitcher === 'pattern') return this.createdSize
      else return this.selectedUserSize
    },
    key () {
      return this.type.replaceAll('-', '_')
    },
    fixedBlockStyle () {
      let padding = window.innerWidth < 768 ? 186 : 102
      const tableElement = this.tableData
      const scrollPosition = this.scrolledY + window.innerHeight
      const tableBottom = tableElement?.getBoundingClientRect().bottom + this.scrolledY
      let bottomDistance
      if (scrollPosition > tableBottom - padding) {
        bottomDistance = tableBottom - scrollPosition + padding
      } else {
        bottomDistance = 0
      }
      if (!bottomDistance || bottomDistance > 0) return ''
      else return 'stop'
    },
    labels () {
      return constants[this.key + '_MEASUREMENTS'].map(m => ({ ...constants.MEASUREMENTS[m], key: m }))
    },
    dropdowns () {
      const { buttocks, bellyProtuberance } = fields
      return {
        buttocks: buttocks.map(b => ({ value: b.value, title: b.label })),
        bellyProtuberance: bellyProtuberance.map(b => ({ value: b.value, title: b.label })),
      }
    },
    normalMeasure () {
      let measure = []
      for (let key in size.fields) {
        let val = ''
        if (size.fields[key].cm || size.fields[key].cm === 0) val = size.fields[key]?.cm || 0
        else val = this.selectedSizeDefaultVal[key]?.cm || 0
        measure.push({
          uid: size.fields[key].param,
          value: val,
        })
      }
      return measure
    },
    paramSizeFields () {
      let params = {}
      for (let key in size.fields) {
        params[size.fields[key].param] = key
      }
      return params
    },
  },
  methods: {
    ...mapActions('fitModel', ['setGlobalSwitcher']),
    async setOffset () {
      let loaded = this.fitModelsStore.paramsPageFitModel.offset + this.fitModelsStore.paramsPageFitModel.limit
      if (this.fitModelsStore.countPageFitModel / loaded > 1) {
        this.fitModelsStore.paramsPageFitModel.offset = this.fitModelsStore.paramsPageFitModel.offset + this.fitModelsStore.paramsPageFitModel.limit
        await this.fitModelsStore.getListFitModelPage()
      }
    },
    setSwitcher (val) {
      this.setGlobalSwitcher(val)
      if (val === 'pattern') this.selectSize(this.fitChartsStore.selectedMannequin)
      else this.selectSize(this.fitModelsStore.selectedModel)
    },
    selectSize (val) {
      if (
        this.sizeSwitcher === 'pattern' &&
        this.fitChartsStore.selectedMannequinId !== val.mannequinId
      ) {
        this.fitChartsStore.selectedMannequinId = val.mannequinId
      }
      if (this.sizeSwitcher === 'pattern' && !this.createdSize) this.createdSize = {}
      if (this.sizeSwitcher === 'custom' && !this.selectedUserSize) this.selectedUserSize = {}
      val.params.forEach(el => {
        if (size?.fields) {
          size.fields[this.paramSizeFields[el.uid]].cm = parseFloat(el.value)
          size.calculate()
          if (this.sizeSwitcher === 'pattern') {
            this.createdSize[this.paramSizeFields[el.uid]] = {
              cm: el.value,
              in: size.fields[this.paramSizeFields[el.uid]].in,
            }
          } else {
            this.selectedUserSize[this.paramSizeFields[el.uid]] = {
              cm: el.value,
              in: size.fields[this.paramSizeFields[el.uid]].in,
            }
          }
        }
      })
      this.resetAll()
    },
    resetAll () {
      size = measurements[this.key]()
      this.export()
      if (
        this.selectedSizeDefaultVal &&
        this.selectedSizeDefaultVal.bellyProtuberance &&
        this.selectedSizeDefaultVal.buttocks
      ) {
        this.changeDropDown(this.selectedSizeDefaultVal.bellyProtuberance.cm, 'bellyProtuberance')
        this.changeDropDown(this.selectedSizeDefaultVal.buttocks.cm, 'buttocks')
      }
    },
    proceedWithProfile () {
      this.continueModalShow = false
      this.setSwitcher('custom')
    },
    back () {
      const rp = JSON.parse(this.$route.params.designOptions)
      this.$router.push({
        name: 'PageDesigner',
        query: {
          params: JSON.stringify(rp),
          type: this.$route.params.type,
          category: rp.designCategory,
          size: this.$route.params.size,
          mannequinEase: this.$route.params.mannequinEase,
          isCustomModel: this.$route.params.isCustomModel,
          fitChartsIsCustom: this.fitChartsStore.isCustom,
          fitChartsId: this.$route.params.fitChartsId,
          mannequinIdNew: this.$route.params.mannequinIdNew,
        },
      })
    },
    async apply () {
      let OneVal = 0
      for (let key in this.fields) {
        if (
          (this.fields[key].cm || this.fields[key].cm === 0) &&
          this.fields[key].cm !== this.selectedSizeDefaultVal[key].cm
        ) {
          OneVal++
        }
      }
      const rp = JSON.parse(this.$route.params.designOptions)
      const selected = this.sizeSwitcher === 'custom' ? this.fitModelsStore.selectedModel : this.fitChartsStore.selectedMannequin
      if (OneVal === 0) {
        if (selected.newMennequinId) {
          rp.mannequinId = selected.newMennequinId
        } else {
          const { mannequinId } = await createMannequin(this.normalMeasure, this.type)
          if (mannequinId) {
            if (this.sizeSwitcher === 'custom') {
              this.fitModelsStore.selectedModel.newMennequinId = mannequinId
              this.fitModelsStore.modelsForEdit[this.type].map(el => {
                if (el.mannequinId === selected.mannequinId) { el.newMennequinId = mannequinId }
                return el
              })
            } else {
              this.fitChartsStore.selectedMannequin.newMennequinId = mannequinId
              this.fitChartsStore.setNewMennequin(selected.mannequinId, mannequinId)
            }
            rp.mannequinId = mannequinId
          }
        }
        this.$router.push({
          name: 'PageDesigner',
          query: {
            params: JSON.stringify(rp),
            type: this.$route.params.type,
            category: rp.designCategory,
            // mannequinEase: this.$route.params.mannequinEase,
            isCustomModel: this.sizeSwitcher === 'custom' ? 1 : 0,
            mannequinIdNew: selected.mannequinId,
            fitChartsIsCustom: this.fitChartsStore.isCustom,
            fitChartsId: this.$route.params.fitChartsId,
          },
        })
      } else {
        let dataNewMan = await this.fitChartsStore.createMannequin(this.normalMeasure, this.type)
        const { mannequinId } = await createMannequin(this.normalMeasure, this.type)
        if (dataNewMan) {
          let data = {
            ...dataNewMan,
            name: 'Custom',
            params: dataNewMan.sizeParams,
            newMennequinId: mannequinId,
          }
          if (this.sizeSwitcher === 'custom') {
            this.fitModelsStore.selectedModel = data
            this.fitModelsStore.modelsForEdit[this.type].push(this.fitModelsStore.selectedModel)
          } else {
            this.fitChartsStore.selectedMannequinId = dataNewMan.mannequinId
            this.fitChartsStore.selectedChart.mannequins.push(data)
          }
          rp.mannequinId = mannequinId
          this.$router.push({
            name: 'PageDesigner',
            query: {
              params: JSON.stringify(rp),
              type: this.$route.params.type,
              category: rp.designCategory,
              size: this.$route.query.size,
              mannequinEase: this.$route.params.mannequinEase,
              isCustomModel: this.sizeSwitcher === 'custom' ? 1 : 0,
              mannequinIdNew: dataNewMan.mannequinId,
              fitChartsIsCustom: this.fitChartsStore.isCustom,
              fitChartsId: this.$route.params.fitChartsId,
            },
          })
        }
      }
    },
    async saveModel () {
      this.saveModalShow = false
      const { mannequinId } = await createMannequin(this.normalMeasure, this.type)
      if (mannequinId) {
        const mannequins = []
        mannequins.push({
          mannequinType: this.type,
          name: this.profileName,
          sizeParams: this.normalMeasure,
        })
        await this.fitModelsStore.saveNewChartWithParams(this.profileName, this.type, mannequins, mannequinId)
        this.continueModalShow = true
      }
    },
    handleScroll () {
      this.scrolledY = window.scrollY
    },
    change (v, key) {
      let value = typeof v === 'string' ? v.replaceAll(',', '.') : v
      value = this.units === 'cm' ? parseFloat(value) : v
      size.fields[key][this.units] = value
      size.calculate()
      this.export()
    },
    changeDropDown (v, key) {
      const value = parseFloat(v)
      size.fields[key].cm = value
      size.calculate()
      this.export()
    },
    export () {
      this.fields = size.export()
    },
    imageUrl () {
      try {
        return require(`@/assets/fitModelDescription/${this.imgModal.catalog}/${this.imgModal.img}.png`)
      } catch (a) {
        this.imgModal.error = true
      }
    },
    showInfo (size) {
      let catalog = null
      if (this.type === 'FEMALE' || this.type === 'MALE') catalog = this.type
      else if (this.type.includes('TEEN')) catalog = "TEEN"
      else catalog = "CHILD"
      this.imgModal = {
        show: true,
        img: size.param,
        catalog: catalog,
        error: false,
      }
    },
  },
}
</script>

<style lang="scss">
@import "src/styles/mixins";
.fit-model-page {
  padding: 16px;
  max-width: 1440px;
  margin: auto;
  font-size: 14px;
  .fit-model-container {
    max-width: 684px;
    margin: auto;
  }
  .image-modal.modal-component-overlay .modal-component {
    width: auto;
    max-width: 80%;
    max-height: 90%;
    .extra {
      max-width: 100%;
      img {
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
  .continue-modal.modal-component-overlay .modal-component .buttons {
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    button {
      max-width: 200px;
    }
  }
  .bottom_buttons  {
    height: 80px;
    position: fixed;
    border-top: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FAFBFB;
    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 24px 16px;
      max-width: 1440px;
      margin: auto;
      .btn.primary {
        justify-self: center;
      }
      .btn.secondary {
        justify-self: flex-end;
      }
    }
    button {
      width: fit-content;
    }
    .reset_button {
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
  }
  .bottom_buttons.stop {
    position: absolute;
    bottom: 0;
  }
  h1 {
    @include font-styles(24px, 36px, 600, Montserrat);
    margin-bottom: 16px;
    margin-top: 0;
    text-align: center;
  }
  .close {
    @include flex(center);
    cursor: pointer;
    width: fit-content;
    font-weight: 600;
    svg {
      margin-right: 4px;
    }
  }
  .size-buttons {
    width: 322px;
    margin: 0 auto 24px;
  }
  .units-buttons {
    @include flex(flex-end, space-between);
    margin-bottom: 16px;
    .select-block-radio .radio-item {
      min-width: 41px;
    }
    .sizes {
      @include flex(flex-end);
      gap: 12px;
      .custom-sizes {
        width: 200px;
      }
      span {
        line-height: 40px;
      }
    }
  }
  .table {
    margin-bottom: 102px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
    .table-row {
      border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
      padding: 0 16px;
      height: 56px;
      align-items: center;
      display: grid;
      grid-template-columns: minmax(30%, 70%) minmax(15%, 30%);
      gap: 32px;
      &:last-child {
        border: none;
      }
      &:hover {
        .nameText .validation {
          display: block;
        }
      }
      .nameText {
        align-items: flex-start;
        flex-direction: column;
        .named {
          display: flex;
          align-items: center;
        }
        .changed {
          margin-left: 8px;
          width: 6px;
          height: 6px;
          background: var(--Active-State-Strokes);
          border-radius: 50%;
        }
        .validation {
          font-size: 12px;
          color: var(--Text-Text_Secondary);
          display: none;
        }
      }
      &__collumn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__title {
          display: none;
        }
        svg {
          cursor: pointer;
        }
      }
    }
    .title-row {
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      p {
        margin: 0;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}
@media (max-width: 768px) {
  .fit-model-page {
    .bottom_buttons {
      height: 140px;
      &__container {
        grid-template-columns: repeat(2, 1fr);
        flex-wrap: wrap;
        gap:12px;
        button.main {
          width: 100%;
          order: 3;
          grid-column: span 2;
        }
      }
    }
    .table {
      margin-bottom: 186px;
      border: none;
      .table-row {
        border-radius: 12px;
        border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
        grid-template-columns: 1fr;
        padding: 0;
        gap: 0;
        margin-bottom: 16px;
        height: auto;
        &__collumn {
          height: 56px;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          &__title {
            display: block;
            margin-bottom: 0;
          }
          .input-field, .select-field {
            width: 300px;
          }
        }
        &:last-child {
          margin-bottom: 0;
          border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
        }
        .name {
          grid-column: 1 / 3;
          border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
          padding: 16px;
        }
      }
      .pointName {
        display: none;
      }
    }
  }
}
@media (max-width: 480px) {
  .fit-model-page {
    .units-buttons {
      .select-block-radio .radio-item {
        width: 41px;
      }
    }
    .close {
      margin-bottom: 16px;
    }
    h1 {
      @include font-styles(20px, normal, 600, Montserrat);
    }
    .table .table-row__collumn {
      .input-field {
        width: 104px;
      }
      .select-field {
        width: 156px;
      }
    }
    .size-buttons {
      width: 100%;
      margin-bottom: 32px;
    }
  }
}
</style>
