<template>
  <modal-component
    :show="displayModal"
    :showIcon="false"
    @close="close"
    class="save-design-modal"
  >
    <template v-slot:title>
      Download pattern
    </template>
    <template v-slot:description>
      Select the file format for download
    </template>
    <template v-slot:extra>
      <div
        v-for="format in downloadFormats"
        :key="format"
        class="selected-block"
      >
        <div
          :class="`radio-button-block ${selectedDownloadFormats === format ? 'active' : ''} base-dropdown`"
          v-click-outside="() => format === 'PDF' ? isOpen = false : null"
          @click="selectFormat(format)"
        >
          <div class="radio-button-item" />
          <div class="info">
            <label>{{ format }} {{ format === 'PDF' ? `${selectedPdfFormat ? '(' + selectedPdfFormat + ')' : ''}` : '' }}</label>
            <svg v-if="format === 'PDF'" :class="isOpen ? 'open' : ''" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20" width="20" height="20">
              <path fill="#1d1f29" d="M10 12.4c-.2 0-.4-.1-.5-.2L6.1 8.9c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l2.8 2.8 2.8-2.8c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-3.3 3.3c-.2.1-.4.2-.6.2z"/>
            </svg>
          </div>
          <div
            v-if="isOpen && format === 'PDF'"
            class="base-dropdown__content"
          >
            <div
              v-for="option in pdfFormat"
              :key="option"
              @click.stop="selectedPdfFormat = option; isOpen = false"
              :class="`base-dropdown__content__item ${selectedPdfFormat === option ? 'active' : ''}`"
            >
              {{ option }}
            </div>
          </div>
        </div>
      </div>
      <div class="check" v-if="selectedDownloadFormats === 'PLT' || selectedDownloadFormats === 'PDF'">
        <label :class="`checkbox ${checked ? 'selected' : ''}`">
          <input
            v-model="checked"
            type="checkbox"
          />
          <svg v-if="checked" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </label>
        <p>Print flat patterns on Fold</p>
      </div>
      <div class="demo-warning" v-if="demoMode">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none"><path stroke="#FF6770" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m28.701 25.584-11.547-20a1.333 1.333 0 0 0-2.31 0l-11.546 20c-.513.889.128 2 1.155 2h23.094a1.333 1.333 0 0 0 1.154-2Z"/><path stroke="#FF6770" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.934 22.25h.133v.134h-.133v-.134ZM16 12.917v5.333"/></svg>
        <div>
          <p class="demo-warning__title">You are using the Demo version</p>
          <p>Designs are available for download for non-commercial purposes only.</p>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <ButtonPrimary
        class="one"
        :disabled="!selected || (!downloadLeft && demoMode)"
        @click.native="downloadPatterns(selectedDownloadFormats)"
      >
        <template v-slot:icon v-if="loading">
          <svg class="loading" xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 5.917v-2.5M13.542 7.375l1.792-1.791M15 10.917h2.5M13.542 14.459l1.792 1.791M10 15.917v2.5M6.459 14.459 4.667 16.25M5 10.917H2.5M6.459 7.375 4.667 5.584"/></svg>
        </template>
        Download
      </ButtonPrimary>
    </template>
  </modal-component>
</template>

<script>
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ModalComponent from '@/components/Atoms/ModalComponent'
import { mapGetters } from 'vuex'

export default {
  name: 'PageDesigner',
  components: {
    ButtonPrimary,
    ModalComponent,
  },
  props: {
    displayModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    downloadFormats: ["DXF-AMMA", "PLT"],
    // downloadFormats: ["DXF-AMMA", "PLT", "PDF"],
    pdfFormat: ['A4', 'A3', 'A2', 'A1', 'A0', 'LETTER', 'TABLOID', 'LEGAL', '18 in', '24 in', '36 in', '42 in', '54 in', '60 in', '67 in'],
    selectedDownloadFormats: null,
    isOpen: false,
    selectedPdfFormat: null,
    checked: false,
  }),
  computed: {
    ...mapGetters('user', ['demoMode', 'downloadLeft']),
    selected () {
      if (this.selectedDownloadFormats) {
        if (this.selectedDownloadFormats !== 'PDF') return this.selectedDownloadFormats
        else if (this.selectedPdfFormat) return this.selectedPdfFormat.toUpperCase()
        else return null
      } return null
    },
  },
  methods: {
    selectFormat (format) {
      if (format === 'PDF') {
        this.isOpen = !this.isOpen
      } else {
        this.selectedPdfFormat = null
      }
      this.selectedDownloadFormats = format
    },
    close () {
      this.displayModal = false
      this.$emit('close')
    },
    downloadPatterns () {
      this.$emit('download', {
        format: this.selectedDownloadFormats,
        cutVerticallySymmetric: this.checked,
        pageSize: this.selectedPdfFormat,
      })
    },
  },
}
</script>

<style lang="scss">
@import "src/styles/variables";
.save-design-modal {
  .demo-warning {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: var(--Surface);
    &__title {
      color: var(--Text-Text_Primary) !important;
      margin-bottom: 4px !important;
    }
    svg {
      min-width: 32px;
    }
  }
  .loading {
    animation: spin 2s linear infinite;
  }
  .radio-button-block {
    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      svg {
        width: 20px;
        transition: all 0.3s ease-in-out;
      }
      svg.open {
        transform: rotate(180deg);
      }
    }
  }
  .text-holder {
    text-align: left !important;
  }
  .extra {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .buttons button {
    width: fit-content !important;
    @media (max-width: $breakpoint-sm) {
      width: 100% !important;
    }
  }
  .check p {
    color: var(--Text-Text_Primary);
    font-size: 16px !important;
  }
}
.base-dropdown {
  position: relative;
  &__content {
    z-index: 1;
    width: 100%;
    position: absolute;
    margin-top: 8px;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: var(--Background);
    box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.10);
    max-height: 200px;
    overflow: scroll;
    top: calc(100% + 10px);
    left: 0;
    &__item {
      border-radius: 8px;
      padding: 8px 16px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background: var(--Surface-Hover);
        color: var(--Button-States-Hover);
      }
    }
    &__item.active {
      background: var(--Surface-Hover);
      color: var(--Button-States-Hover);
    }
  }
}
</style>
