<template>
  <component-wrapper-base
    title="Flat Pattern"
    class="relative-position FlatPattern"
  >
    <template slot="menu" v-if="$store.state.isLoggedIn">
      <div class="download-block">
        <div class="download-block__button" @click="isShowMenu = !isShowMenu">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20" width="20" height="20">
            <path fill="#09373D" d="M15 11.6c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-1.7s-.1.1 0 0c-.1.2.1.2 0 0 .1.1 0 0 0 0zm-5 1.7c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-1.7s-.1.1 0 0c-.1.2.1.2 0 0 .1.1 0 0 0 0zm-5 1.7c-.9 0-1.6-.7-1.6-1.6S4.1 8.4 5 8.4s1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-1.7s-.1.1 0 0c-.1.2.1.2 0 0 .1.1 0 0 0 0z"/>
          </svg>
        </div>
        <div
          v-if="isShowMenu"
          class="download-block__items"
          v-click-outside="() => isShowMenu = false"
        >
          <div class="download-block__items__item" @click="showSeamAllowance">
            Seam allowance
          </div>
          <div class="download-block__items__item check" @click="setInternalLines">
            <div :class="`checkbox ${checked ? 'selected' : ''}`">
              <svg v-if="checked" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            Internal lines
          </div>
          <div class="download-block__items__item" v-if="$store.state.isLoggedIn && !$store.state.isProduction" @click="showSizeRun">
            Size run
          </div>
        </div>
      </div>
      <!-- <q-btn
        flat
        round color="primary"
        icon="more_vert"
        style="position:absolute;right:15px;top:15px"
      >
        <q-menu auto-close>
          <q-list>
            <slot name="menuItems"></slot>
          </q-list>
        </q-menu>
      </q-btn> -->
    </template>

    <template v-slot:top>
      <slot name="top"></slot>
    </template>

    <div
      v-if="isLoading"
      class="FlatPattern__loader row justify-center"
    >
      <q-spinner-dots
        class="full-height col-2"
        color="primary"
      />
    </div>

    <div
      v-if="patternsPreview"
      v-html="patternsPreview"
      class="full-height column pattern-image-wrapper"
    ></div>
    <slot v-else name="noPreview"></slot>

    <template v-slot:bottom>
      <slot name="bottom"></slot>
    </template>
  </component-wrapper-base>
</template>

<script>
import ComponentWrapperBase from '@/components/ComponentWrapperBase'

export default {
  name: 'FlatPattern',

  components: {
    ComponentWrapperBase,
  },

  props: {
    patternsPreview: {
      type: String,
    },
    isLoading: Boolean,
  },
  data: () => ({
    checked: false,
    isShowMenu: false,
  }),
  methods: {
    setInternalLines () {
      this.checked = !this.checked
      this.$emit('internalLines', this.checked)
    },
    showSeamAllowance () {
      this.isShowMenu = false
      this.$emit('showSeamAllowance')
    },
    showSizeRun () {
      this.isShowMenu = false
      this.$emit('sizeRun')
    },
  },
}
</script>

<style lang="scss">
  @import "src/styles/mixins";
  .FlatPattern {
    @include loader();
    .check {
      margin-bottom: 0;
    }
    .download-block {
      position: relative;
      &__button {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 50%;
        &:hover {
          background-color: var(--Surface);
        }
      }
      &__items {
        border-radius: 8px;
        border: 1px solid var(--Dividers);
        position: absolute;
        background: var(--Basic-White);
        box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.10);
        z-index: 1;
        font-size: 16px;
        right: 0;
        top: calc(100% + 8px);
        width: 200px;
        &__item {
          border-radius: 8px;
          padding: 8px 16px;
          width: max-content;
          min-width: 100%;
          cursor: pointer;
          &:hover {
            background: var(--Surface-Hover);
          }
        }
      }
    }
  }
  .pattern-image-wrapper svg {
    max-height: calc(100vh - 230px);
  }
</style>
