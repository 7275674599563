<template>
  <div class="sketch-image-wrapper">
    <svg :id="view" :data-fill-color="color" :viewBox="viewBoxString" :style="`height: ${maxHeight}; max-height: ${sketchHeight}`" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs ref="fabricDefs" v-if="fabricImage" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <pattern id="fabric"
          patternUnits="userSpaceOnUse"
          width="100"
          height="100"
          patternTransform="scale(1)">
          <image :xlink:href="fabricImage"
            x="0"
            y="0"
            width="100"
            height="100"
            preserveAspectRatio="xMidYMax slice"
          >
          </image>
        </pattern>
      </defs>
      <g ref="frontView" class="front-view"
         v-if="['both', 'front'].includes(view)">
         <g class="back" v-if="showOtherSketchSideUnder" opacity=".8" :transform="`matrix(-1,0,0,1,${backShift},0)`">
          <path :fill="getFillColor(p)" v-for="p in backPaths" :d="p.attributes.d" :id="p.attributes.id" :data-source-ids="p.attributes['data-source-ids']"
                :style="getPathStyles(p, 'front-backPath')"></path>
        </g>
        <g class="front">
          <path :fill="getFillColor(p)" v-for="p in frontPaths" :d="p.attributes.d"
                :style="getPathStyles(p)" :id="p.attributes.id" :data-source-ids="p.attributes['data-source-ids']"></path>
        </g>
      </g>
      <g ref="backView" class="back-view" :transform="backTransform"
         v-if="['both', 'back'].includes(view)">
        <g class="front" v-if="!isMan && showOtherSketchSideUnder" opacity=".8" :transform="`matrix(-1,0,0,1,${backShift},0)`"> <!-- translate(99) scale(-1, 1) -->
          <path :fill="getFillColor(p)" v-for="p in frontPaths" :d="p.attributes.d"
                :style="getPathStyles(p, 'back-frontPath')"></path>
        </g>
        <g class="back">
          <path :fill="getFillColor(p)" v-for="p in backPaths" :d="p.attributes.d"
                :style="getPathStyles(p)"></path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { parse } from 'svg-parser'
import { preparePaths, renderPaths, isPathClosed } from './svg-tools/build/cadwise-svg-tools.js'
import { SVG } from '@svgdotjs/svg.js'
import { fetchSpecs } from '@/api'
import { designOptionsValidator } from '@/pages/designerConstructor'
import { calculateEdgePoints } from '@/helpers/svg'
import { ShapeInfo, Intersection } from './svg-tools/node_modules/kld-intersections'

const NODES_ORDER = [
  'wm_jm_bodice',
  'wm_jm_front_necks',
  'wm_jm_sleeves',
  'wm_jm_back_necks',
  'wm_jm_silhouettes',
  'wm_pn_silhouettes',
  'wm_pn_waist_bands',
  'wm_pn_pockets',
]

const BACK_VIEW_SCALE = 0.7

const BACK_VIEW_SHIFT = 40

const PIXELS_PER_VIEW_BOX = 2.7

// сколько прцентов вьюбокса оставлять на корректировку толщины линий,
// чтобы элементы не резались по центру линии
const STROKE_THICKNESS_ADJUSTMENT_PERCENT = 8

export default {
  name: 'sketch-image',
  data () {
    return {
      frontPaths: [],
      backPaths: [],
      edgePoints: {
        x: 0,
        y: 0,
        x1: 0,
        y1: 0
      },
      frontNeckEdge: null,
      backNeckEdge: null
    }
  },
  props: {
    designOptions: {
      type: Object,
      required: true,
      validator: val => designOptionsValidator(val),
    },
    elements: {
      type: Array,
    },
    descItems: {
      type: Array,
      required: true,
    },
    view: {
      type: String,
      default: 'both',
    },
    color: {
      type: String,
      default: '#ffffff',
    },
    fabricImage: {
      type: String,
      default: undefined,
    },
    ease: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    setNormalId (svg) {
      const svgObj = SVG(svg)
      if (svgObj?.children()) {
        svgObj.children().forEach(el => {
          if (el.attr('id') && typeof el.attr('id') === 'string') {
            el.attr('id', el.attr('id').split('_')[0])
          }
        })
        return svgObj.svg()
      } else return svg
    },
    isBaseElement (element) {
      return element.nodeCode.indexOf('prebuilt') > -1 || element.nodeCode.indexOf('silhouettes') > -1
    },
    async getPaths (side) {
      let paths = []
      let deps = []
      const baseElement = this.sortedElements.find(el => this.isBaseElement(el))
      let finalElements = this.sortedElements
      finalElements.forEach(el => {
        const nodeCodeParts = el.nodeCode.split('_')
        const nodeCodeAttr = nodeCodeParts.length > 1 ? nodeCodeParts[nodeCodeParts.length - 1] : el.nodeCode
        if (el.svgFront) {
          el.svgFront = el.svgFront.replace('<path', `<path data-source-node="${nodeCodeAttr}"`)
        }
        if (el.svgBack) {
          el.svgBack = el.svgBack.replace('<path', `<path data-source-node="${nodeCodeAttr}"`)
        }
      })
      let isSarafan = false
      if (baseElement) {
        baseElement.svgFront = this.setNormalId(baseElement.svgFront)
        baseElement.svgBack = this.setNormalId(baseElement.svgBack)

        const makePathShoulder = (shoulder, bodice) => {
          const bodiceArray = bodice.array()
          let bodicePoint = {}
          if (bodiceArray[0][2] > bodiceArray[bodiceArray.length - 1][bodiceArray[1].length-1]) {
            bodicePoint.y = bodiceArray[bodiceArray.length - 1][bodiceArray[1].length-1]
            bodicePoint.x = bodiceArray[bodiceArray.length - 1][bodiceArray[1].length-2]
          } else {
            bodicePoint.y = bodiceArray[0][2]
            bodicePoint.x = bodiceArray[0][1]
          }
          const shoulderArray = shoulder.array()
          if (shoulderArray[0][2] > shoulderArray[1][2]) {
            shoulderArray[0][1] = bodicePoint.x
            shoulderArray[0][2] = bodicePoint.y
          } else {
            shoulderArray[1][1] = bodicePoint.x
            shoulderArray[1][2] = bodicePoint.y
          }
          return shoulderArray.toString()
        }

        if (baseElement.nodeCode.includes('wm_bd_silhouettes')) {
          const makeSleeve = (side, sleeve, isOneShoulder) => {
            let bodiceRight
            let bodiceLeft
            const SVGObjSleeve = SVG(sleeve)
            SVGObjSleeve.children().forEach(p => {
              if (p.attr('id') && p.attr('id').includes(side === 'back' ? 'left' : 'right') && isOneShoulder) p.remove()
              if (p.attr('id') && p.attr('id').toLowerCase().includes(`bodice${side === 'back' ? '-back' : ''}-right`)) {
                bodiceRight = p
              }
              else if (p.attr('id') && p.attr('id').toLowerCase().includes(`bodice${side === 'back' ? '-back' : ''}-left`)) {
                bodiceLeft = p
              }
              else if (p.attr('id') && p.attr('id').includes(side === 'back' ? 'backside' : 'frontside')) p.remove()
              else {
                p.attr('data-not-contour', '1')
                p.attr('data-keep', '1')
              }
            })
            return {
              bodiceRight: bodiceRight,
              bodiceLeft: bodiceLeft,
              svg: SVGObjSleeve.svg()
            }
          }
          const makeNeck = (side, neck, bodiceLeft, bodiceRight) => {
            const SVGObjFNeck = SVG(neck)
            if (SVGObjFNeck?.children()) {
              let shoulderLeft
              let shoulderRight
              SVGObjFNeck.children().forEach(p => {
                if (p.attr('id')) {
                  p.attr('id', p.attr('id').split('_')[0])
                  if (p.attr('id').includes(`neckline${side === 'back' ? '-back' : ''}-shoulder-left`)) {
                    shoulderLeft = p
                  }
                  if (p.attr('id').includes(`neckline${side === 'back' ? '-back' : ''}-shoulder-right`)) {
                    shoulderRight = p
                  }
                  if (p.attr('id').includes('sleeve')) p.remove()
                  if (p.attr('id') && !p.attr('id').includes('neckline')) {
                    p.attr('data-not-contour', '1')
                    p.attr('data-keep', '1')
                  }
                }
                else {
                  p.attr('data-not-contour', '1')
                  p.attr('data-keep', '1')
                }
              })
              if (shoulderLeft && bodiceLeft) {
                shoulderLeft.attr('d', makePathShoulder(shoulderLeft, bodiceLeft))
              }
              if (shoulderRight && bodiceRight) {
                shoulderRight.attr('d', makePathShoulder(shoulderRight, bodiceRight))
              }
              return SVGObjFNeck.svg()
            }
          }
          baseElement.svgBack = baseElement.svgBack.replace(/<path[^>]*id="Neckline[^"]*"[^>]*>[^<]*<\/path>/g, '')
          baseElement.svgFront = baseElement.svgFront.replace(/<path[^>]*id="Neckline[^"]*"[^>]*>[^<]*<\/path>/g, '')
          const sleeve = finalElements.find(el => el.nodeCode.includes('sleeves'))
          const frontNeck = finalElements.find(el => el.nodeCode.includes('front_necks'))
          const isOneShoulder = frontNeck?.attributes.find(a => a.code === 'neckline:one_shoulder')
          const backNeck = finalElements.find(el => el.nodeCode.includes('back_necks'))
          const sleeveBack = makeSleeve('back', sleeve.svgBack, isOneShoulder)
          const sleeveFront = makeSleeve('front', sleeve.svgFront, isOneShoulder)
          sleeve.svgBack = sleeveBack.svg
          sleeve.svgFront = sleeveFront.svg
          backNeck.svgBack = makeNeck('back', backNeck.svgBack, sleeveBack.bodiceLeft, sleeveBack.bodiceRight)
          frontNeck.svgFront = makeNeck('front', frontNeck.svgFront, sleeveFront.bodiceLeft, sleeveFront.bodiceRight)
        }

        const womenSW = baseElement.nodeCode.includes('wm_sw_silhouettes')
        if (this.isChild || baseElement.nodeCode.includes('wm_sw_silhouettes')) {
          const frontNeck = finalElements.find(el => el.nodeCode.includes('front_necks'))
          const backNeck = finalElements.find(el => el.nodeCode.includes('back_necks'))
          const isCollar = frontNeck?.attributes.find(a => a.code === 'collar:with_collar')
          if (isCollar && backNeck) {
            frontNeck.svgBack = this.setNormalId(frontNeck.svgBack)
            const collarSvgObj = SVG(frontNeck.svgBack)
            const backNeckSvgObj = SVG(backNeck.svgBack)
            const find = (query, svg) => {
              const result = svg.find(query)
              return (result && result[0]) ? result[0] : null
            }
            const collarBottom = find(`path#collar-bottom-line`, collarSvgObj)
            const neckleft = find(`path#neckline-back-neck-left`, backNeckSvgObj)
            const neckRight = find(`path#neckline-back-neck-right`, backNeckSvgObj)
            const backLine = find(`path#back-line`, collarSvgObj)
            if (neckleft?.bbox().y2 > collarBottom?.bbox().y2) {
              const length = neckleft.bbox().y2 - collarBottom.bbox().y2
              const point1 = neckleft.pointAt(neckleft.length())
              const point2 = neckRight.pointAt(neckRight.length())
              collarBottom.attr('d', neckleft.attr('d') + `L${point1.x + 1} ${point1.y - 2}` + neckRight.attr('d') + `L${point2.x - 1} ${point2.y - 2}`)
              if (backLine) backLine.height(backLine.height() + length)
              frontNeck.svgBack = collarSvgObj.svg()
            }
          }
          const dress = baseElement.nodeCode.includes('dr_silhouettes')
          if (!dress) {
            const silhouettes = SVG(baseElement.svgFront)
            silhouettes.children().forEach(el => {
              if (el.attr('id')?.includes('button') || el.attr('id')?.includes('inner-detail')) {
                el.attr('data-keep', '1')
                el.attr('data-not-contour', '1')
                el.attr('data-element', 'button')
              }
              if (el.attr('id')?.includes('placket')) {
                el.attr('data-not-contour', '1')
              }
            })
            baseElement.svgFront = silhouettes.svg()
          }
          const sleeve = finalElements.find(el => el.nodeCode.includes('sleeves'))
          const neck = finalElements.find(el => el.nodeCode.includes('necklines'))
          const pockets = finalElements.find(el => el.nodeCode.includes('pockets'))
          const backStyle = finalElements.find(el => el.nodeCode.includes('back_styles'))
          if (backStyle && !womenSW) {
            const filterPaths = svg => {
              const SVGObj = SVG(svg)
              let paths = SVGObj?.children()
              paths?.forEach((p, index) => {
                p.attr('data-keep', '1')
                p.attr('data-order', 1000 + index)
              })
              return SVGObj.svg()
            }
            backStyle.svgBack = filterPaths(backStyle.svgBack)
            backStyle.svgFront = filterPaths(backStyle.svgFront)
          }
          if (pockets && pockets.svgFront) {
            const SVGObj = SVG(pockets.svgFront)
            SVGObj.children().forEach(p => {
              if (p.attr('id')?.includes('button')) {
                p.attr('data-fillable', '0')
              } else if (p.attr('id')?.includes('kangaroo')) {
                const silhouettes = SVG(baseElement.svgFront)
                const bottomSide = silhouettes.children().reduce((acc, obj) => {
                  if (!acc) return obj
                  if (obj.bbox().width > 40) {
                    return obj.bbox().y2 < acc.bbox().y2 ? obj : acc
                  }
                  return acc
                }, null)
                if (baseElement.nodeCode === 'wm_sw_silhouettes') {
                  p.y(bottomSide.bbox().y2 - p.bbox().height - 5)
                } else p.y(bottomSide.bbox().y2 - p.bbox().height)
              }
            })
            pockets.svgFront  = SVGObj.svg()
          }
          let bodiceRight
          let bodiceLeft
          if (sleeve) {
            const isRaglanSleeve = sleeve.attributes.find(a => a.code === 'shirt_sleeve:raglan_sleeve')
            if (isRaglanSleeve) {
              const filterPaths = svg => {
                const SVGObj = SVG(svg)
                let paths = SVGObj.children()
                paths.forEach(p => {
                  if (!p.attr('id')) {
                    p.attr('data-not-contour', '1')
                    p.attr('data-keep', '1')
                  }
                })
                return SVGObj.svg()
              }
              if (neck.description.includes('collar')) {
                sleeve.svgBack = sleeve.svgBack.replace('<svg>', '<svg><path id="left-bodice" class="st4" d="M58.4,30.1c2.9,6.7,10.1,10.7,14.3,29.5"/><path id="right-bodice" class="st4" d="M27.1,59.6c4.2-18.8,11.8-22.9,14.7-29.6"/>')
              } else sleeve.svgBack = sleeve.svgBack.replace('<svg>', '<svg><path id="left-bodice" class="st4" d="M39.6,29.8c-2.9,6.7-8.3,11.1-12.5,29.9"/><path id="right-bodice" class="st4" d="M72.7,59.7c-4.2-18.8-9.6-23.2-12.5-29.9"/>')
              sleeve.svgFront = sleeve.svgFront.replace('<svg>', '<svg><path id="right-bodice" class="st4" d="M72.7,59.7c-1.2-5-2.7-8.5-3.7-11.4c-2.6-8.3-10-9.2-12.8-11.8"/><path id="left-bodice" class="st4" d="M43.6,36.5c-2.8,2.3-10.1,3.7-12.8,11.9c-0.9,2.9-2.5,6.3-3.7,11.4"/>')
              sleeve.svgBack = filterPaths(sleeve.svgBack)
              sleeve.svgFront = filterPaths(sleeve.svgFront)

              if (neck) {
                const find = (query, svg) => {
                  const result = svg.find(query)
                  return (result && result[0]) ? result[0] : null
                }
                const raglanBodiceSVG = SVG(sleeve.svgBack)
                const neckSVG = SVG(neck.svgBack)
                neckSVG.children().forEach(el => {
                  if (el.attr('id')) {
                    el.attr('id', el.attr('id').split('_')[0])
                  }
                })
                const bodiceHeight = side => {
                  const neck = find(`path#back-collar-bottom-${side}`, neckSVG)
                  const bodice = find(`path#${side}-bodice`, raglanBodiceSVG)
                  if (neck && bodice) {
                    const neckBBox = neck.bbox()
                    const bodiceBbox = bodice.bbox()
                    const raznicha = neckBBox.y - bodiceBbox.y
                    bodice.height(bodiceBbox.height - raznicha)
                    bodice.y(bodiceBbox.y + raznicha)
                  }
                  bodice.attr('data-keep', '1')
                  bodice.attr('data-not-contour', '1')
                }
                bodiceHeight('left')
                bodiceHeight('right')
                sleeve.svgBack  = raglanBodiceSVG.svg()
              }
            } else {
              const filterPaths = svg => {
                const SVGObj = SVG(svg)
                let paths = SVGObj.children()
                paths.forEach(p => {
                  if (p.attr('id') && p.attr('id').toLowerCase() === 'bodice-right') bodiceRight = p
                  else if (p.attr('id') && p.attr('id').toLowerCase() === 'bodice-left') bodiceLeft = p
                  else {
                    p.attr('data-not-contour', '1')
                    p.attr('data-keep', '1')
                  }
                })
                return SVGObj.svg()
              }
              sleeve.svgBack = filterPaths(sleeve.svgBack)
              sleeve.svgFront = filterPaths(sleeve.svgFront)
            }
          }
          if (neck) {
            const filterPaths = svg => {
              const SVGObj = SVG(svg)
              let paths = SVGObj.children()
              let shoulderLeft
              let shoulderRight
              paths.forEach(p => {
                if (p.attr('id')) {
                  p.attr('id', p.attr('id').split('_')[0])
                  if (p.attr('id').includes('neckline-shoulder-left')) shoulderLeft = p
                  if (p.attr('id').includes('neckline-shoulder-right')) shoulderRight = p
                }
                if (p.attr('id') && !p.attr('id').includes('neckline')) {
                  p.attr('data-not-contour', '1')
                  p.attr('data-keep', '1')
                  if (p.attr('id').includes('hood')) {
                    p.attr('fill-white', '1')
                  }
                  if (p.attr('id').includes('button')) {
                    p.attr('data-fillable', '0')
                  }
                }
                if (!p.attr('id')) {
                  p.attr('data-not-contour', '1')
                  p.attr('data-keep', '1')
                }
              })
              if (shoulderLeft && shoulderRight && bodiceRight && bodiceLeft) {
                shoulderLeft.attr('d', makePathShoulder(shoulderLeft, bodiceLeft))
                shoulderRight.attr('d', makePathShoulder(shoulderRight, bodiceRight))
              }
              return SVGObj.svg()
            }
            neck.svgFront = filterPaths(neck.svgFront)
            neck.svgBack = filterPaths(neck.svgBack)
          }
          if (baseElement.nodeCode.includes('sw_silhouettes')) {
            if (baseElement.svgFront.includes('zipper')) {
              const SVGObjNeck = SVG(neck.svgFront)
              const SVGObjBase = SVG(baseElement.svgFront)
              let neckSvg = '<svg></svg>'
              SVGObjNeck.children().forEach(el => {
                if ((el.attr('id') && !el.attr('id').includes('shoulder')) || !el.attr('id')) {
                  neckSvg = neckSvg.replace('<svg>', `<svg><path d="${el.attr('d')}"/>`)
                }
              })
              const neckSvgObj = SVG(neckSvg)
              SVGObjBase.children().forEach(el => {
                if (el.attr('id') && el.attr('id').includes('zipper')) {
                  el.attr('data-not-contour', 1)
                  el.attr('data-keep', 1)
                  el.height(el.bbox().height - (neckSvgObj.bbox().y2 - el.bbox().y))
                  el.y(neckSvgObj.bbox().y2)
                }
              })
              baseElement.svgFront = SVGObjBase.svg()
            }
          }
        }
        // фикс переднего выреза
        const frontNeck = finalElements.find(el => {
          return el.nodeCode.includes('front_necks') ||
          (el.nodeCode.includes('bo') && el.nodeCode.includes('necklines')) ||
          (el.nodeCode.includes('wm') && el.nodeCode.includes('necklines'))
        })
        if (frontNeck) {
          const neckSVG = SVG(frontNeck.svgFront)
          if (neckSVG?.children()) {
            let paths = neckSVG.children()
            if (paths.length > 1) {
              paths.forEach(p => {
                let contourIds = [
                  'front-neckline-neck-right',
                  'front-neckline-neck-left',
                  'neckline-neck-right',
                  'neckline-neck-left'
                ]
                if (!p.attr('id') || !contourIds.includes(p.attr('id'))) { p.remove() }
              })
            }
            this.frontNeckEdge = neckSVG.bbox()
          }
        }
        const buttonDownPlacket = baseElement.attributes.find(a => a.code === 'closure:button_down_placket')
        if (
          buttonDownPlacket &&
          (frontNeck.description === 'asymmetrical-neckline' || frontNeck.description === 'square-neck')
        ) {
          const SVGObj = SVG(baseElement.svgFront)
          let path = SVGObj.children()
          path.sort(((a, b) => {
            return b.bbox().height - a.bbox().height
          }))
          path[0].attr('data-only-slice', '1')
          baseElement.svgFront = SVGObj.svg()
        }
        if (frontNeck && frontNeck.description?.includes('v-neck')) {
          const buttonDown = baseElement.attributes.find(a => a.code === 'closure:button_down')
          const buttonDownPlacket = baseElement.attributes.find(a => a.code === 'closure:button_down_placket')
          if (buttonDown || buttonDownPlacket) {
            const neckSVG = SVG(frontNeck.svgFront)
            let rightNeck
            let leftNeck
            neckSVG.children().forEach(el => {
              if (el.attr('id') === 'neckline-neck-right') rightNeck = el
              else if (el.attr('id') === 'neckline-neck-left') leftNeck = el
            })
            if (buttonDown) {
              const SvgObj = SVG(baseElement.svgFront)
              const closureLine = SvgObj.children().reduce((maxEl, currentEl) => {
                return currentEl.bbox().height > maxEl.bbox().height ? currentEl : maxEl
              })
              const rightNeckP = ShapeInfo.path(rightNeck.attr('d'))
              const rightPoint0 = rightNeck.pointAt(0)
              leftNeck.width(leftNeck.bbox().width + 2.5)
              leftNeck.height(leftNeck.bbox().height + 2.5)
              const leftLastPoint = leftNeck.pointAt(leftNeck.length())
              closureLine.y(leftLastPoint.y)
              const leftNeckP = ShapeInfo.path(leftNeck.attr('d'))
              const intersect = Intersection.intersect(rightNeckP, leftNeckP)
              if (rightNeck.attr('class') !== 'changed') {
                if (frontNeck.description?.includes('deep') || !intersect.points.length) {
                  rightNeck.height(rightNeck.bbox().height)
                  if (frontNeck.description?.includes('wide-high-v-neck')) {
                    rightNeck.height(rightNeck.bbox().height + 1)
                  }
                  rightNeck.attr('d', `${rightNeck.attr('d')} L${leftLastPoint.x} ${leftLastPoint.y}`)
                } else {
                  rightNeck.attr('d', `M${rightPoint0.x} ${rightPoint0.y} L${intersect.points[0].x} ${intersect.points[0].y} L${leftLastPoint.x} ${leftLastPoint.y}`)
                }
              }
              rightNeck.attr('class', `changed`)
              baseElement.svgFront = SvgObj.svg()
            }
            if (buttonDownPlacket) {
              if (leftNeck.attr('class') !== 'changed') {
                leftNeck.width(leftNeck.bbox().width - 2.5)
                leftNeck.height(leftNeck.bbox().height - 5)
                const leftLastPoint = leftNeck.pointAt(leftNeck.length())
                leftNeck.attr('d', `${leftNeck.attr('d')} L${leftLastPoint.x + 3} ${leftLastPoint.y}`)
                leftNeck.attr('class', `changed`)
              }
              if (rightNeck.attr('class') !== 'changed') {
                rightNeck.width(rightNeck.bbox().width - 2.5)
                rightNeck.x(rightNeck.bbox().x + 2.5)
                rightNeck.height(rightNeck.bbox().height - 5)
                const rightLastPoint = rightNeck.pointAt(rightNeck.length())
                rightNeck.attr('d', `${rightNeck.attr('d')} L${rightLastPoint.x - 3} ${rightLastPoint.y}`)
                rightNeck.attr('class', `changed`)
              }
            }
            frontNeck.svgFront = neckSVG.svg()
          }
        }
        if (womenSW) {
          baseElement.svgFront = baseElement.svgFront.replace(/id="zipper-[^"]+"/ig, '$& data-not-contour="1" data-keep="1"')
          const SvgObj = SVG(baseElement.svgFront)
          SvgObj.children().forEach(el => {
            if (el.attr('id')?.includes('zipper-top')) {
              el.y(this.frontNeckEdge.y2)
            } else if (
              (el.attr('id')?.includes('zipper-center') ||
              el.attr('id')?.includes('zipper-right') ||
              el.attr('id')?.includes('zipper-left')) &&
              this.frontNeckEdge.y2 > el.bbox().y
            ) {
              let dif = this.frontNeckEdge.y2 - el.bbox().y
              el.y(this.frontNeckEdge.y2)
              el.height(el.bbox().height - dif)
            }
          })
          baseElement.svgFront = SvgObj.svg()
        }
        const frontHole = baseElement.svgFront.includes('front-hole')
        if (frontNeck && frontHole) {
          const neckSVG = SVG(frontNeck.svgFront)
          const holeSVG = SVG(baseElement.svgFront)
          const find = (query, svg) => {
            const result = svg.find(query)
            return (result && result[0]) ? result[0] : null
          }
          const holeLeft = find('path#front-hole-left', holeSVG)
          const holeRight = find('path#front-hole-right', holeSVG)
          const neckLeft = find('path#neckline-neck-left', neckSVG)
          const bbox = neckLeft?.bbox()
          let holePaths = ''
          if (holeLeft && bbox && holeRight) {
            holeLeft.height(holeLeft.bbox().height - (bbox.y2 - holeLeft.bbox().y))
            holeRight.height(holeRight.bbox().height - (bbox.y2 - holeRight.bbox().y))
            holeLeft.y(bbox.y2)
            holeRight.y(bbox.y2)
            holePaths = `<path d="${holeLeft.attr('d')}" id="${holeLeft.attr('id')}" /><path d="${holeRight.attr('d')}" id="${holeRight.attr('id')}" />`
            holeLeft.remove()
            holeRight.remove()
          }
          frontNeck.svgFront = frontNeck.svgFront.replace('<svg>', '<svg>' + holePaths)
          baseElement.svgFront = holeSVG.svg()
        }
        const sleeveAttr = baseElement.attributes.find(a => a.type === 'sleeve')
        if (sleeveAttr) {
          if (sleeveAttr.code === 'sleeve:set_in_sleeve') {
            if (baseElement.attributes.some(a => ['one_shoulder:left', 'one_shoulder:right'].includes(a.code))) {
              finalElements = finalElements.filter(el => el.nodeCode.indexOf('bodice') === -1)
            }
          } else if (sleeveAttr.code === 'sleeve:straps' || sleeveAttr.code === 'sleeve:tank_top') {
            finalElements = finalElements.filter(el => el.nodeCode.indexOf('bodice') === -1)
            isSarafan = true
          } else if (sleeveAttr.code === 'sleeve:raglan_sleeve') {
            const raglanBodice = finalElements.find(el => el.nodeCode.indexOf('bodice') > -1)
            if (raglanBodice) {
              const varType = baseElement.attributes.find(a => a.type === 'variation_type')
              const isSurplice = varType && varType.name.toLowerCase().indexOf('surplice') > -1
              const neck = finalElements.find(el => el.nodeCode.includes('back_necks'))
              raglanBodice.svgFront = raglanBodice.svgFront.replace('54.14 33.95', '50.14 29.95')
              if (isSurplice) {
                raglanBodice.svgFront = raglanBodice.svgFront.replace('M44.75', 'M50.75')
                raglanBodice.svgBack = this.setKeep(raglanBodice.svgBack)
              }
              else {
                raglanBodice.svgBack = this.setKeep(raglanBodice.svgBack)
                if (neck) {
                  const neckSVG = SVG(neck.svgBack)
                  const raglanBodiceSVG = SVG(raglanBodice.svgBack)

                  let neckBigPath = ''
                  neckSVG.children().forEach(el => {
                    neckBigPath = neckBigPath + ' ' + el.attr('d')
                  })
                  const p0 = ShapeInfo.path(neckBigPath)
                  raglanBodiceSVG.children().forEach(bodice => {
                    const bodiceArray = bodice.array()
                    const p1 = ShapeInfo.path(bodice.attr('d'))
                    const intersect = Intersection.intersect(p1, p0)
                    if(intersect.points && intersect.points[0]) {
                      if (((bodiceArray[0][2] - intersect.points[0].y) < 2)) {
                        bodiceArray[0][1] = intersect.points[0].x
                        bodiceArray[0][2] = intersect.points[0].y
                      } else {
                        bodiceArray[bodiceArray.length - 1][bodiceArray[bodiceArray.length - 1].length - 2] = intersect.points[0].x
                        bodiceArray[bodiceArray.length - 1][bodiceArray[bodiceArray.length - 1].length - 1] = intersect.points[0].y
                      }
                    }
                    bodice.attr('d', bodiceArray.toString())
                  })
                  raglanBodice.svgBack = raglanBodiceSVG.svg()
                }
              }
            }
            const sleeves = finalElements.find(el => el.nodeCode && el.nodeCode.includes('sleeves'))
              if (sleeves) {
                const filterPaths = svg => {
                  const SVGObj = SVG(svg)
                  let paths = SVGObj.find('path')
                  paths.sort(((a, b) => {
                    return b.length() - a.length()
                  }))
                  paths.forEach((p, index) => {
                    if (index > 1) {
                      p.attr('data-not-contour', '1')
                      p.attr('data-keep', '1')
                    }
                  })
                  return SVGObj.svg()
                }
                sleeves.svgBack = filterPaths(sleeves.svgBack)
                sleeves.svgFront = filterPaths(sleeves.svgFront)
              }
          } else if (sleeveAttr.code === 'sleeve:batwing_sleeve') {
            const bodice = finalElements.find(el => el.nodeCode && el.nodeCode.includes('bodice'))
            const sleeves = finalElements.find(el => el.nodeCode && el.nodeCode.includes('sleeves'))
            const front_necks = finalElements.find(el => el.nodeCode && el.nodeCode.includes('front_necks'))
            const back_necks = finalElements.find(el => el.nodeCode && el.nodeCode.includes('back_necks'))
            const silhouetteType = baseElement.attributes.find(el => el.type === 'silhouette_type')
            const filterPaths = (bodice, sleeves, neck, baseElement) => {
              baseElement = baseElement.replace(/<path[^>]*id="waist-right[^"]*"[^>]*>[^<]*<\/path>/g, '').replace(/<path[^>]*id="waist-left[^"]*"[^>]*>[^<]*<\/path>/g, '')
              const SVGObj = SVG(bodice)
              SVGObj.children().forEach(p => {
                p.attr('data-not-contour', '1')
                p.attr('data-keep', '1')
                p.attr('id', '')
              })
              bodice = SVGObj.svg()
              const sleevesSVGObj = SVG(sleeves)
              let arraySilhoette = ['silhouette:cocoon', 'silhouette:trapeze', 'silhouette:tent']
              sleevesSVGObj.children().forEach(p => {
                if (arraySilhoette.includes(silhouetteType?.code)) {
                  if (p.attr('id')?.includes('sleeve') && p.attr('id')?.includes(silhouetteType.code.split(':')[1])) {
                    neck = neck.replace('</svg>', `<path d="${p.attr('d')}"/></svg>`)
                    p.remove()
                  } else if (p.attr('id')?.includes('sleeve') || p.attr('id')?.includes('bodice')) {
                    p.remove()
                  } else {
                    p.attr('data-not-contour', '1')
                    p.attr('data-keep', '1')
                  }
                } else {
                  if (p.attr('id')?.includes('bodice')) {
                    neck = neck.replace('</svg>', `<path d="${p.attr('d')}"/></svg>`)
                    p.remove()
                  } else if (p.attr('id')?.includes('sleeve')) {
                    p.remove()
                  } else {
                    p.attr('data-not-contour', '1')
                    p.attr('data-keep', '1')
                  }
                }
              })
              return {
                bodice: SVGObj.svg(),
                neck: neck,
                baseElement: baseElement,
                sleeve: sleevesSVGObj.svg()
              }
            }
            let el = {
              front: filterPaths(bodice.svgFront, sleeves.svgFront, front_necks.svgFront, baseElement.svgFront),
              back: filterPaths(bodice.svgBack, sleeves.svgBack, back_necks.svgBack, baseElement.svgBack),
            }
            front_necks.svgFront = el.front.neck
            bodice.svgFront = el.front.bodice
            baseElement.svgFront = el.front.baseElement
            sleeves.svgFront = el.front.sleeve
            back_necks.svgBack = el.back.neck
            bodice.svgBack = el.back.bodice
            baseElement.svgBack = el.back.baseElement
            sleeves.svgBack = el.back.sleeve
          }
        }
        const pockets = finalElements.find(el => el.nodeCode && el.nodeCode.includes('pockets'))
        const sideSeamPocket = pockets?.attributes.find(el => el.code === 'pocket_source:side_seam')
        const sidePocket = pockets?.attributes.find(el => el.code === 'pocket_source:side')
        if (sidePocket) {
          const SVGObjPockets = SVG(pockets.svgFront)
          const SVGObjBase = SVG(baseElement.svgFront)
          let pocket = { left: null, right: null }
          let base = { left: null, right: null }
          SVGObjPockets.children().forEach(el => {
            if (el.attr('id')?.includes('pocket-right')) pocket.right = el
            if (el.attr('id')?.includes('pocket-left')) pocket.left = el
          })
          SVGObjBase.children().forEach(el => {
            if (el.attr('id')?.includes('skirt-left')) base.right = el
            if (el.attr('id')?.includes('skirt-right')) base.left = el
          })
          console.log('base', base)
          console.log('pocket', pocket)
          let lineShapeInfo = ShapeInfo.path(`M${base.left?.bbox()?.x - 2} ${pocket.right?.bbox()?.y2} L${base.right?.bbox()?.x2 + 2} ${pocket.right?.bbox()?.y2}`)
          let intersectLeft = Intersection.intersect(ShapeInfo.path(base.left.attr('d')), lineShapeInfo)
          let intersectRight = Intersection.intersect(ShapeInfo.path(base.right.attr('d')), lineShapeInfo)
          if (base.left.pointAt(0).x - intersectLeft.points[0].x > 3) {
            pocket.left.width(pocket.left.bbox().width + 4)
            pocket.right.width(pocket.right.bbox().width + 4)
          }
          pocket.left.x(intersectLeft.points[0].x)
          pocket.right.x(intersectRight.points[0].x - pocket.right.bbox().width)
          pockets.svgFront = SVGObjPockets.svg()
        }
        if (sideSeamPocket) {
          const SVGObj = SVG(pockets.svgFront)
          let silhouettes = ''
          const SVGObjBase = SVG(baseElement.svgFront)
          SVGObjBase.children().forEach(el => {
            silhouettes = silhouettes + ' ' + el.attr('d')
          })
          const edgePoints = calculateEdgePoints(`<svg><path d="${silhouettes}"/></svg>`)
          const p1 = ShapeInfo.path(silhouettes)
          const p2 = ShapeInfo.path(`M${edgePoints.x-10} ${SVGObj.children()[0].bbox().y} L${edgePoints.x1+10} ${SVGObj.children()[0].bbox().y}`)
          const p3 = ShapeInfo.path(`M${edgePoints.x-10} ${SVGObj.children()[0].bbox().y2} L${edgePoints.x1+10} ${SVGObj.children()[0].bbox().y2}`)
          const intersectTop = Intersection.intersect(p1, p2)
          const intersectBottom = Intersection.intersect(p1, p3)
          const intersectTopPoints = intersectTop.points.filter(el => el.y + 1 > SVGObj.children()[0].bbox().y && el.y - 1 < SVGObj.children()[0].bbox().y)
          const intersectBottomPoints = intersectBottom.points.filter(el => el.y + 1 > SVGObj.children()[0].bbox().y2 && el.y - 1 < SVGObj.children()[0].bbox().y2)
          const topPoint = {
            left: intersectTopPoints.sort((a,b) => a.x - b.x)[0],
            right: intersectTopPoints.sort((a,b) => b.x - a.x)[0]
          }
          const bottomPoint = {
            left: intersectBottomPoints.sort((a,b) => a.x - b.x)[0],
            right: intersectBottomPoints.sort((a,b) => b.x - a.x)[0]
          }
          const makePockets = side => {
            if (topPoint[side] && bottomPoint[side]) {
              let length = 0.5
              if (
                (topPoint[side].x < bottomPoint[side].x && bottomPoint[side].x - topPoint[side].x < 1) || (topPoint[side].x > bottomPoint[side].x && topPoint[side].x - bottomPoint[side].x < 1)
              ) length = 1.5
              else if (
                (topPoint[side].x < bottomPoint[side].x && bottomPoint[side].x - topPoint[side].x < 2) || (topPoint[side].x > bottomPoint[side].x && topPoint[side].x - bottomPoint[side].x < 2)
              ) length = 1.1
              else if (
                (topPoint[side].x > bottomPoint[side].x && topPoint[side].x - bottomPoint[side].x > 5.6) || (topPoint[side].x < bottomPoint[side].x && bottomPoint[side].x - topPoint[side].x > 5.6)
              ) length = -1
              else if (
                (topPoint[side].x > bottomPoint[side].x && topPoint[side].x - bottomPoint[side].x > 3.4) || (topPoint[side].x < bottomPoint[side].x && bottomPoint[side].x - topPoint[side].x > 3.4)
              ) length = 0.2
              let qPointX
              if (side === 'left') {
                qPointX = topPoint[side].x < bottomPoint[side].x ? topPoint[side].x - length : bottomPoint[side].x - length
              } else {
                qPointX = topPoint[side].x > bottomPoint[side].x ? topPoint[side].x + length : bottomPoint[side].x + length
              }
              return `M${topPoint[side].x} ${topPoint[side].y} Q ${qPointX} ${topPoint[side].y + (bottomPoint[side].y - topPoint[side].y)/2} ${bottomPoint[side].x} ${bottomPoint[side].y} L${topPoint[side].x} ${topPoint[side].y}`
            }
          }
          const pocketDLeft = makePockets('left')
          const pocketDRight = makePockets('right')
          SVGObj.children()[0].attr('d', pocketDRight)
          SVGObj.children()[1].attr('d', pocketDLeft)
          pockets.svgFront = SVGObj.svg()
        }
        const isPatchPocket = pockets?.attributes.find(el => el.code === 'pocket_source:patch')
        const silhouettes = ['silhouette:straight', 'silhouette:pencil', 'silhouette:a_line', 'silhouette:tent', 'silhouette:cocoon', 'silhouette:trapeze']
        const needSilhouette = baseElement.attributes.find(el => silhouettes.includes(el.code))
        if (isPatchPocket && baseElement.nodeCode.includes('dr_silhouettes') && needSilhouette) {
          const SVGPocketsObj = SVG(pockets.svgFront)
          SVGPocketsObj.children().forEach(el => {
            el.y(el.bbox().y + 8)
          })
          pockets.svgFront = SVGPocketsObj.svg()
        }
        if (baseElement.nodeCode.includes('dr_silhouettes') || baseElement.nodeCode.includes('wm_jm_silhouettes')) {
          const isHalter = baseElement.attributes.find(el => el.code?.includes('halter'))
          if (isHalter) {
            if (baseElement.svgBack.includes('skirt-bottom')) {
              const SVGObjBackS = SVG(baseElement.svgBack)
              SVGObjBackS.children().forEach(el => {
                if (!el.attr('id')) {
                  el.attr('data-not-contour', 1)
                  el.attr('data-keep', 1)
                }
              })
              baseElement.svgBack = SVGObjBackS.svg()
            }
            const backNeck = finalElements.find(el => el.nodeCode?.includes('back_necks'))
            const frontNeck = finalElements.find(el => el.nodeCode?.includes('front_necks'))
            if (backNeck?.svgBack) {
              backNeck.svgBack = backNeck.svgBack.replace(/<path[^/]+id="neckline-back-shoulder-left"[^/]+\/>/, '').replace(/<path[^/]+id="neckline-back-shoulder-right"[^/]+\/>/, '')
              const SVGObjBackNeck = SVG(backNeck.svgBack)
              SVGObjBackNeck?.children()?.forEach(el => {
                if (!el.attr('id') || (el.attr('id') && !el.attr('id').includes('neckline-back-neck'))) {
                  el.attr('data-not-contour', 1)
                  el.attr('data-keep', 1)
                }
              })
              backNeck.svgBack = SVGObjBackNeck.svg()
            }
            if (frontNeck?.svgFront) {
              frontNeck.svgFront = frontNeck.svgFront.replace(/<path[^/]+id="neckline-shoulder-right"[^/]+\/>/, '').replace(/<path[^/]+id="neckline-shoulder-left"[^/]+\/>/, '')
            }
          }
        }
        if (baseElement.nodeCode.includes('dr_silhouettes')) {
          if (baseElement.svgFront.includes('waist-bottom-front') && side === 'front') {
            const isPrincessSeamsEmpire = finalElements.find(el => {
              return el.nodeCode.includes('wm_dr_silhouettes') && el.attributes.find(attr => {
                return (attr.code.includes('princess_seams') && !attr.code.includes('surplice')) ||
                (attr.code.includes('raglan') && attr.type === 'variation_type')
              })
            })
            const isFlared = baseElement.attributes.find(el => el.code === 'silhouette:flared')
            const isFittedBodice = baseElement.attributes.find(el => el.code === 'silhouette:fitted_bodice')
            if (isPrincessSeamsEmpire || isFlared || isFittedBodice) {
              baseElement.svgFront = baseElement.svgFront.replace(/data-element="(skirt|pants)"/g, '').replace(/<path[^\/]+id="waist-bottom-front"[^\/]+\/>/, '').replace(/id="skirt-top"/, '$& data-not-contour="1" data-keep="1"').replace(/id="waist-bottom-front"/, '$& data-not-contour="1" data-keep="1"')
            } else {
              baseElement.svgFront = baseElement.svgFront.replace(/data-element="(skirt|pants)"/g, '').replace(/<path[^\/]+id="waist-bottom-front"[^\/]+\/>/, '').replace(/id="skirt-top"/, '$& data-not-contour="1" data-keep="1"')
            }
          }
          if (baseElement.svgBack.includes('waist-bottom-back')) {
            const isClosuresPar22 = this.elements.find(el => el.nodeCode.includes('dr_back_closures') && el.params[22] === 5)
            if (isClosuresPar22 && this.isChild) {
              const filterPaths = svg => {
                const SVGObj = SVG(svg)
                let paths = SVGObj.find('path')
                const contourIds = [
                  'skirt-top',
                  'skirt-bottom',
                  'skirt-left',
                  'skirt-right'
                ]
                let skirt = ''
                paths.forEach(p => {
                  if (p.attr('id') && contourIds.includes(p.attr('id'))) {
                    skirt = `${skirt}<path d="${p.attr('d')}" stroke="${p.attr('stroke')}" fill="${p.attr('fill')}" stroke-width="${p.attr('stroke-width')}"/>`
                    p.remove()
                  }
                })
                skirt = `<svg>${skirt}</svg>`
                const skirtSVGObj = SVG(skirt)
                const parseSkirt = parse(skirtSVGObj.svg()).children
                const params = {}
                params.allowSelfClosed = true
                const cutPaths = preparePaths(parseSkirt, [], 'svgBack', params)
                const svgObj = SVG(renderPaths(cutPaths))
                if (svgObj.children() && svgObj.children().length){
                  svgObj.children()[0].attr('data-not-contour', 1)
                  svgObj.children()[0].attr('data-keep', 1)
                  svgObj.children()[0].addTo(SVGObj)
                }
                return SVGObj.svg()
              }
              baseElement.svgBack = filterPaths(baseElement.svgBack)
            } else {
              baseElement.svgBack = baseElement.svgBack.replace(/data-element="(skirt|pants)"/g, '').replace(/<path[^\/]+id="waist-bottom-back"[^\/]+\/>/, '').replace(/id="skirt-top"/, '$& data-not-contour="1" data-keep="1"').replace(/id="waist-bottom-back"/, '$& data-not-contour="1" data-keep="1"')
              const filterPaths = svg => {
                const SVGObj = SVG(svg)
                let paths = SVGObj.find('path')
                paths.forEach(p => {
                  if (!p.attr('id')) {
                    p.attr('data-not-countour', '1')
                  }
                })
                return SVGObj.svg()
              }
              baseElement.svgBack = filterPaths(baseElement.svgBack)
            }
          }
          const isWaistband = baseElement.attributes.find(el => el.code.includes('waistband'))
          if (isWaistband) {
            const filterPaths = svg => {
              const SVGObj = SVG(svg)
              let paths = SVGObj.find('path')
              paths.forEach(p => {
                if (p.attr('id') && p.attr('id').includes('waistband')) {
                  p.attr('data-fillable', '0')
                  p.attr('data-keep', '1')
                }
              })
              return SVGObj.svg()
            }
            baseElement.svgBack = filterPaths(baseElement.svgBack)
          }
          //  Фикс для передней части сборных платьев (топ, юбка) выточек
          const frontNeckSurplice = finalElements.find(el => el.nodeCode.includes('wm_dr_front_necks') && el.svgFront.includes('neckline-front-surplice'))
          const armhole = baseElement.attributes.find(a => a.type === 'variation_type' && a.code.includes('armhole_princess'))
          const waist = baseElement.attributes.find(a => a.type === 'waist' && a.code.includes('no_waist'))
          if (armhole && !waist) {
            const filterPaths = (svg, neck) => {
              const darts = svg.includes('darts')
              if (darts) {
                const SVGobg = SVG(svg)
                const find = (query, svg) => {
                  const result = svg.find(query)
                  return (result && result[0]) ? result[0] : null
                }
                const skirtTop = SVGobg.children().filter(el => {
                  if (el.attr('id') && el.attr('id').includes('skirt-top')) {
                    return el
                  }
                })
                let p1 = skirtTop[0] ? ShapeInfo.path(skirtTop[0].attr('d')) : null
                makeDarts('path#darts-left')
                if (neck) {
                  const neckSVG = SVG(neck.svgFront)
                  const surplice = find('path#neckline-front-surplice', neckSVG)
                  p1 = surplice ? ShapeInfo.path(surplice.attr('d')) : null
                }
                makeDarts('path#darts-right')
                function makeDarts(position) {
                  const dart = find(position, SVGobg)
                  if (dart) {
                    const dartsArray = dart.array()
                    const p0 = ShapeInfo.path(dart.attr('d'))
                    const intersect = Intersection.intersect(p1, p0)
                    const intersection = intersect.points
                    if (
                      intersection[0] &&
                      Math.round(intersection[0].x) !== Math.round(dartsArray[dartsArray.length - 1][dartsArray[dartsArray.length - 1].length-2]) &&
                      Math.round(intersection[0].y) !== Math.round(dartsArray[dartsArray.length - 1][dartsArray[dartsArray.length - 1].length-1])
                    ) {
                      if (intersection[0].y < dartsArray[1][dartsArray[1].length-1]) {
                        dartsArray[1][dartsArray[1].length-1] = intersection[0].y
                        dartsArray[1][dartsArray[1].length-2] = intersection[0].x
                      }
                      if (dartsArray[0][dartsArray[0].length-1] = intersection[0].y) {
                        dartsArray[0][2] = intersection[0].y
                      }
                      else {
                        dartsArray[0][1] = intersection[0].x
                        dartsArray[0][2] = intersection[0].y
                        if (neck) {
                          dartsArray[1][2] = intersection[0].y
                        }
                      }
                      dart.attr('d', dartsArray.toString())
                    }
                  }
                }
                return SVGobg.svg()
              } else return svg
            }
            baseElement.svgFront = filterPaths(baseElement.svgFront, frontNeckSurplice)
            baseElement.svgBack = filterPaths(baseElement.svgBack)
          }
          const isStrap = this.elements.find(el => el.nodeCode && el.nodeCode.indexOf('wm_dr_straps') > -1)
          if (isStrap && !waist) {
            const filterPaths = svg => {
              const SVGObj = SVG(svg)
              let paths = SVGObj.find('path')
              paths.forEach(p => {
                if (!p.attr('id')) {
                  p.attr('data-not-contour', '1')
                  p.attr('data-keep', '1')
                }
              })
              return SVGObj.svg()
            }
            baseElement.svgFront = filterPaths(baseElement.svgFront)
          }

          const backNeckSurplice = finalElements.find(el => el.nodeCode.includes('wm_dr_back_necks') && el.svgBack?.includes('neckline-back-surplice'))

          //  Фикс для передней и задней части платьев с запАхом (surplice front)
          const filterPaths = (neck, svg, svgSide) => {
            const neckSVG = SVG(neck)
            const skirtSVG = SVG(svg)
            const find = (query, svg) => {
              const result = svg.find(query)
              return (result && result[0]) ? result[0] : null
            }
            const surplice = find(`path#neckline-${svgSide}-surplice`, neckSVG)
            const skirtTop = skirtSVG.children().filter(el => {
              if (el.attr('id') && el.attr('id').includes('skirt-top')) {
                return el
              }
            })
            const waistband = find('path#waistband', skirtSVG)
            surplice.attr('data-not-contour', '1')
            const surplicebbox = surplice.bbox()
            const p0 = ShapeInfo.path(surplice.attr('d'))
            let p1
            if (waistband) {
              p1 = ShapeInfo.path(waistband.attr('d'))
            } else if (skirtTop[0]) {
              p1 = ShapeInfo.path(skirtTop[0].attr('d'))
            }
            const intersect = Intersection.intersect(p1, p0)
            const intersection = intersect.points
            if (intersection.length) {
              if (intersection.length > 1) {
                const i = intersection[0].y < intersection[1].y ? 0 : 1
                surplice.attr('d', `M${svgSide === 'front' ? surplicebbox.x : surplicebbox.x2} ${surplicebbox.y} L${intersection[i].x} ${intersection[i].y}`)
              } else {
                surplice.attr('d', `M${svgSide === 'front' ? surplicebbox.x : surplicebbox.x2} ${surplicebbox.y} L${intersection[0].x} ${intersection[0].y}`)
              }
            }
            return neckSVG.svg()
          }
          if (frontNeckSurplice) {
            frontNeckSurplice.svgFront = filterPaths(frontNeckSurplice.svgFront, baseElement.svgFront, 'front')
          }
          if (backNeckSurplice) {
            backNeckSurplice.svgBack = filterPaths(backNeckSurplice.svgBack, baseElement.svgBack, 'back')
          }

          //  Фикс для задней части платьев с запАхом (surplice back)
          // длина backClosure
          if (this.descElements.includes('surplice-back') || backNeckSurplice) { // && baseElement.svgBack.includes('skirt-top')
            const svgBack = SVG(baseElement.svgBack)
            const seam = svgBack.findOne('[id*=seam]')
            const skirtTop = svgBack.findOne('#skirt-top')

            const backClosure = this.sortedElements.find(el => el.nodeCode === 'wm_dr_back_closures')
            const svgBackClosure = SVG(backClosure.svgBack)
            const closurePaths = svgBackClosure.find('path')
            let closure
            let closureY

            if (closurePaths.length === 0) {
              if (seam) seam.remove()
            } else {
              closurePaths.sort((a, b) => a.bbox().y - b.bbox().y)
              closure = closurePaths[0]
              closureY = closure.bbox().y
            }

            if (skirtTop) {
              if (seam) seam.y(skirtTop.bbox().y2)
              if (closure && closureY < skirtTop.bbox().y2) closure.y(skirtTop.bbox().y2)
            } else {
              const paths = svgBack.find('path')
              paths.sort((a, b) => (b.bbox().width * b.bbox().height) - (a.bbox().width * a.bbox().height))
              const skirt = paths[0]
              if (seam) seam.y(skirt.bbox().y)
              if (closure && closureY < skirt.bbox().y) closure.y(skirt.bbox().y)
            }

            backClosure.svgBack = svgBackClosure.svg()
            baseElement.svgBack = svgBack.svg()
          }
        }
        const dressCodes = ['dr_silhouettes', 'tp_silhouettes']
        const dressCodeMatch = dressCodes.some(code => baseElement.nodeCode.includes(code))
        if (dressCodeMatch) {
          const backClosure = this.sortedElements.find(el => el.nodeCode.endsWith('_back_closures'))
          if (backClosure && backClosure.attributes.some(a => a.code === 'back_closure:no_seam')) {
            baseElement.svgBack = baseElement.svgBack.replace(/<path[^>]*?id="center-back-seam"[^>]+?\/>/, '')
            baseElement.svgBack = baseElement.svgBack.replace(/<path[^\/]+id="center-back-seam"[^\/]+\/path>/, '')
          }
          if (backClosure) {
            baseElement.svgBack = baseElement.svgBack.replace(/id="center-back-seam"/, '$& data-only-slice="1"')
            const hasButtons = backClosure.attributes.some(a => a.code.indexOf('buttoned') > -1)
            if (hasButtons) {
              backClosure.svgBack = backClosure.svgBack.replace(/id="back-button-\d+"/gi, '$& data-element="button" data-keep="1" data-not-contour="1"')
              baseElement.svgBack = baseElement.svgBack.replace(/id="center-back-seam"/, '$& data-only-slice="1"')
            }
          }
          const isRichedBust = baseElement.attributes.find(a => a.code.includes('ruched_bust'))
          if (isRichedBust) {
            const SVGObj = SVG(baseElement.svgFront)
            let paths = SVGObj.children()
            let leftRushed = ''
            let mainSvg = '<svg></svg>'
            let mainD = ''
            paths.forEach(el => {
              if (el.attr('id') && el.attr('id').includes('ruched-bust-left')) {
                leftRushed = leftRushed + el.attr('d')
              } else if (!el.attr('id') || (el.attr('id') && !el.attr('id').includes('ruched-bust-right'))) {
                mainSvg = mainSvg.replace('<svg>', `<svg><path d="${el.attr('d')}"/>`)
                mainD = `${mainD} ${el.attr('d')}`
              }
            })

            const leftRushedSvg = '<svg><path d="' + leftRushed + '"/></svg>'
            const leftRushedSVG = SVG(leftRushedSvg)
            const leftRushedBbox = leftRushedSVG.bbox()

            const mainSVGObj = SVG(mainSvg)
            const mainBboxX = mainSVGObj.bbox().x > mainSVGObj.bbox().x2 ? mainSVGObj.bbox().x2 : mainSVGObj.bbox().x
            const mainBboxX2 = mainSVGObj.bbox().x < mainSVGObj.bbox().x2 ? mainSVGObj.bbox().x2 : mainSVGObj.bbox().x

            const p0 = ShapeInfo.path(mainD)
            const p1 = ShapeInfo.path(
              `M${mainBboxX - 10} ${leftRushedBbox.y + (leftRushedBbox.width / 2)}
              L${mainBboxX2 + 10} ${leftRushedBbox.y + (leftRushedBbox.width / 2)}Z`
            )
            const intersect = Intersection.intersect(p1, p0)
            const intersection = intersect.points
            if (intersection && intersection.length > 1) {
              paths.forEach(el => {
                if (el.attr('id') && el.attr('id').includes('ruched-bust-right')) {
                  el.attr('data-not-contour', '1')
                  el.attr('data-keep', '1')
                  if (intersection[0].x > intersection[1].x) {
                    el.x(intersection[0].x - el.bbox().width - 0.6)
                  } else {
                    el.x(intersection[1].x - el.bbox().width -0.6)
                  }
                } else if (el.attr('id') && el.attr('id').includes('ruched-bust-left')) {
                  el.attr('data-not-contour', '1')
                  el.attr('data-keep', '1')
                  if (intersection[0].x > intersection[1].x) {
                    el.x(intersection[1].x + 0.6)
                  } else {
                    el.x(intersection[0].x + 0.6)
                  }
                }
              })
            }
            baseElement.svgFront = SVGObj.svg()
          }
        }
        /* if (baseElement.nodeCode === 'wm_dr_silhouettes' && baseElement.svgFront) {
          baseElement.svgFront = baseElement.svgFront.replace(/data-element="(skirt|pants)"/g, '').replace(/<path[^\/]+id="(skirt-pants)-top"[^\/]+\/>/, '')
          baseElement.svgBack = baseElement.svgBack.replace(/data-element="(skirt|pants)"/g, '').replace(/<path[^\/]+id="(skirt-pants)-top"[^\/]+\/>/)
        } */
        if (baseElement.nodeCode === 'mn_ts_silhouettes') {
          const fakeBodice = `<path id="bodice-left" class="st0" d="M31.3,63c0-8.7-1.4-16.1-4.8-22.6"/>
            <path id="bodice-right" class="st0" d="M69.2,63c0-8.7,1.4-16.1,4.8-22.6"/>`
          if (!baseElement.svgFront.includes(fakeBodice)) {
            baseElement.svgFront = baseElement.svgFront.replace('<svg>', '<svg>' + fakeBodice)
            baseElement.svgBack = baseElement.svgBack.replace('<svg>', '<svg>' + fakeBodice.replace('bodice-', 'bodice-back-'))
          }
          const sleeve = finalElements.find(el => el.nodeCode?.includes('sleeve'))
          if (sleeve) {
            sleeve.svgFront = this.setKeep(sleeve.svgFront)
            sleeve.svgBack = this.setKeep(sleeve.svgBack)
          }
        }
        if (baseElement.nodeCode === 'mn_sh_silhouettes') {
          const fakeBodice = `<path id="bodice-left" d="M26.51 40.33 C26.51 40.33 26.51 40.33 26.51 40.33 26.51 40.33 26.51 40.33 31.29 62.95" style="stroke-width: 0.35px; stroke: rgb(78, 94, 97);"></path>
	        <path id="bodice-right" d="M73.96 40.33 73.96 40.33 73.96 40.33 73.96 40.33 73.96 40.33 69.18 62.95" style="stroke-width: 0.35px; stroke: rgb(78, 94, 97);"></path>
          `
          baseElement.svgFront = baseElement.svgFront.replace('<svg>', '<svg>' + fakeBodice)
          baseElement.svgBack = baseElement.svgBack.replace('<svg>', '<svg>' + fakeBodice.replace('bodice-', 'bodice-back-'))
          const sleeve = finalElements.find(el => el.nodeCode && el.nodeCode.indexOf('sleeve') > -1)
          if (sleeve) {
            sleeve.svgBack = sleeve.svgBack.replace(/<path/g, '$& data-contour="1"')
            sleeve.svgFront = this.setKeep(sleeve.svgFront)
            sleeve.svgBack = this.setKeep(sleeve.svgBack)
          }
          baseElement.svgFront = baseElement.svgFront.replace(/id="button-\d+"/gi, '$& data-element="button" data-keep="1" data-not-contour="1"')
        }
        if (baseElement.nodeCode === 'mn_ts_silhouettes' || baseElement.nodeCode === 'mn_sh_silhouettes') {
          baseElement.svgFront = baseElement.svgFront.replace(/id="placket-[^"]+"/ig, '$& data-not-contour="1"')
          // baseElement.svgFront = baseElement.svgFront.replace(/id="zipper-[^"]+"/ig, '$& data-not-contour="1" data-keep="1"')
          const frontNeck = finalElements.find(el => el.nodeCode.includes('necklines'))
          if (frontNeck) {
            const neckSVG = SVG(frontNeck.svgFront)
            this.frontNeckEdge = neckSVG.bbox()
          }
        }
        if (
          baseElement.nodeCode === 'mn_ts_silhouettes' ||
          (baseElement.nodeCode.includes('bo_') && baseElement.nodeCode.includes('silhouettes'))
        ) {
          const SVGObj = SVG(baseElement.svgFront)
          let paths = SVGObj.find('path')
          paths.forEach(p => {
            if (p.attr('id') && p.attr('id').includes('front-button')) {
              p.attr('id', p.attr('id').split('_')[0])
            }
          })
          baseElement.svgFront = SVGObj.svg()
        }
        if (baseElement.nodeCode === 'wm_jm_silhouettes') {
          const isStrap = baseElement.attributes.find(a => a.code === 'sleeve:straps' || a.code === 'sleeve:tank_top')
          if (isStrap) {
            const filterPaths = svg => {
              const obj = SVG(svg)
              let waistBottom
              let waistLeftBack
              obj.children().forEach(el => {
                if (el.attr('id') && el.attr('id').includes('waist-bottom')) {
                  waistBottom = el
                }
                if (el.attr('id') && el.attr('id').includes('waist-left-back')) {
                  waistLeftBack = el
                }
              })
              obj.children().forEach(el => {
                if (!el.attr('id') && (el.bbox().y < waistBottom.bbox().y || el.bbox().y2 < waistBottom.bbox().y) && el.bbox().height < 190) {
                  el.attr('data-keep', '1')
                  el.attr('data-no-cut', '1')
                }
              })
              if (waistBottom && waistLeftBack) {
                waistBottom.attr('d', waistLeftBack.attr('d') + ' ' + waistBottom.attr('d'))
                waistLeftBack.remove()
              }
              return obj.svg()
            }
            baseElement.svgFront = filterPaths(baseElement.svgFront)
            baseElement.svgBack = filterPaths(baseElement.svgBack)
          }

          const isOneShoulder = baseElement.attributes.find(a => a.code === 'neckline:one_shoulder')
          if (isOneShoulder) {
            baseElement.svgBack = baseElement.svgBack.replace(/<path[^\/]+id="center-back-seam"[^\/]+\/>/, '')
          }
          const silhouettes = SVG(baseElement.svgFront)
          const closure = silhouettes.find('path#front-closure-zipper-track')[0]
          if (closure) {
            closure.attr('data-keep', '1')
            closure.attr('data-element', 'closure')
            const bbox = closure.bbox()
            if (bbox.y >= this.frontNeckEdge?.y2) {
              closure.attr('data-no-cut', '1')
            } else {
              closure.attr('data-not-cut-direction', 'bottom')
            }
          }
          baseElement.svgFront = silhouettes.svg()

          // обрезка выточек по горловине
          const filterPaths = (svg, side) => {
            const SVGObj = SVG(svg)
            const neck = finalElements.find(el => el.nodeCode.includes(`${side}_necks`))
            if (neck) {
              const neckSVG = SVG(side === 'front' ? neck.svgFront : neck.svgBack)
              const rigthNeck = neckSVG.find(`path#neckline${side === 'back' ? '-back' : ''}-neck-right`)[0]
              const leftNeck = neckSVG.find(`path#neckline${side === 'back' ? '-back' : ''}-neck-left`)[0]
              if (rigthNeck && leftNeck) {
                const neckline = ShapeInfo.path(rigthNeck.attr('d') + ' ' + leftNeck.attr('d'))
                let paths = SVGObj.find('path')
                paths.forEach(p => {
                  if (p.attr('id') && p.attr('id') === 'waistband') {
                    p.attr('data-keep', '1')
                    p.attr('data-no-cut', '1')
                  }
                  if (!p.attr('id') && !p.attr('data-element')) {
                    const p2 = ShapeInfo.path(p.attr('d'))
                    const intersect = Intersection.intersect(neckline, p2)
                    if (intersect.points.length > 0) {
                      p.attr('data-only-slice', '1')
                      const pArray = p.array()
                      if (pArray[0][2] > intersect.points[intersect.points.length - 1].y) {
                        if ((intersect.points[intersect.points.length - 1].y - pArray[pArray.length - 1][pArray[pArray.length - 1].length - 1]) > 2) {
                        pArray[pArray.length - 1][pArray[pArray.length - 1].length - 2] = intersect.points[intersect.points.length - 1].x
                        pArray[pArray.length - 1][pArray[pArray.length - 1].length - 1] = intersect.points[intersect.points.length - 1].y
                        pArray[pArray.length - 1][pArray[pArray.length - 1].length - 4] = intersect.points[intersect.points.length - 1].x
                        pArray[pArray.length - 1][pArray[pArray.length - 1].length - 3] = intersect.points[intersect.points.length - 1].y
                        }
                      } else {
                        pArray[0][1] = intersect.points[intersect.points.length - 1].x
                        pArray[0][2] = intersect.points[intersect.points.length - 1].y
                        if (pArray[1][2] < intersect.points[intersect.points.length - 1].y) {
                          pArray[1][2] = intersect.points[intersect.points.length - 1].y
                        }
                      }
                      p.attr('d', pArray.toString())
                    }
                  }
                })
              }
            }
            return SVGObj.svg()
          }
          const isDroppedShoulder = baseElement.attributes.find(a => a.code === 'sleeve:dropped_shoulder')
          if (!isDroppedShoulder) {
            baseElement.svgFront = filterPaths(baseElement.svgFront, 'front')
            baseElement.svgBack = filterPaths(baseElement.svgBack, 'back')
          } else {
            const SVGObj = SVG(baseElement.svgBack)
            const neck = finalElements.find(el => el.nodeCode.includes(`back_necks`))
            const neckSVG = SVG(neck.svgBack)
            const objectWithMaxY = neckSVG.children()
              .filter(obj => !obj.attr('id').includes('shoulder'))
              .reduce((max, current) => {
                return (current.bbox().y2 > max.bbox().y2) ? current : max
              })
            SVGObj.children().forEach((p, index) => {
              if (p.attr('id')?.includes('center-line')) {
                if (p.bbox().y < objectWithMaxY.bbox().y2) {
                  p.height(p.bbox().height - (objectWithMaxY.bbox().y2 - p.bbox().y))
                  p.y(objectWithMaxY.bbox().y2)
                  p.attr('data-keep', '1')
                }
              }
              if (p.bbox().height < 100 && !p.attr('id')) {
                p.attr('data-fillable', '0')
                p.attr('data-keep', '1')
                p.attr('data-order', 1000 + index)
              }
            })
            baseElement.svgBack = SVGObj.svg()
          }
        }
        baseElement.svgFront = baseElement.svgFront.replace(/id="front-button-+\d+"/gi, '$& data-no-cut="1"')
        if (baseElement.svgFront.indexOf('front-button') > -1) {
          const svg = SVG(baseElement.svgFront)
          const paths = svg.find('path')
          paths.forEach(el => {
            if (
              el.attr('id') && (el.attr('id') === 'center' || el.attr('id') === 'center_x5F_front_x5F_seam')
            ) el.remove()
          })
          baseElement.svgFront = svg.svg()
          let intersection
          paths.sort((a, b) => b.bbox().height - a.bbox().height )

          for (let i = 1; i < paths.length; i++) {
            const p0 = ShapeInfo.path(paths[0].attr('d'))
            const pi = ShapeInfo.path(paths[i].attr('d'))
            const intersect = Intersection.intersect(p0, pi)
            intersection = intersect.points

            if (intersection.length > 0) break
          }
          if (intersection.length > 1) intersection.sort((a, b) => a.y - b.y)
          if (intersection[0]) window.bottomEdge = intersection[0].y
        }
        if (baseElement.nodeCode === 'wm_tp_silhouettes') {
          const zipper = baseElement.attributes.find(a => a.code === 'closure:front_zipper')
          if (zipper) {
            const silhouettes = SVG(baseElement.svgFront)
            const closure = silhouettes.find('path')
            closure.sort((a, b) => {
              if (a.bbox().height < b.bbox().height) return 1
              else return -1
            })
            closure[0].attr('data-only-slice', '1')
            baseElement.svgFront = silhouettes.svg()
          }
          const slits = baseElement.attributes.find(a => a.code === 'top_slits:with_side_slits')
          if (slits) {
            const filterPaths = svg => {
              const SVGObj = SVG(svg)
              let paths = SVGObj.find('path')
              paths.forEach(el => {
                if (el.attr('id') && el.attr('id').includes('slit')) {
                  el.attr('data-not-contour', '1')
                  el.attr('data-keep', '1')
                }
              })
              let pathArray = paths.array()
              pathArray.forEach((el, index) => {
                let pathArrayLength = el.length
                let lastArray = el[pathArrayLength-1][0].toLowerCase().includes('z') ? el[pathArrayLength-2] : el[pathArrayLength-1]
                if (pathArrayLength < 20) {
                  if (
                    (el[0][el[0].length-1] === lastArray[lastArray.length-1])
                    && (el[0][el[0].length-2] === lastArray[lastArray.length-2])
                    && !paths[index].attr('id')
                    && el[0][el[0].length-1] > 35
                  ) {
                    paths[index].attr('data-not-contour', '1')
                    paths[index].attr('data-keep', '1')
                  }
                }
              })
              return SVGObj.svg()
            }
            baseElement.svgFront = filterPaths(baseElement.svgFront)
            baseElement.svgBack = filterPaths(baseElement.svgBack)
          }
        }

        // pantSlits
        const isPantSlits = this.designOptions.settingCodes.find(el => el?.includes('pants_slits'))
        if (isPantSlits && !isPantSlits.includes('no_slits')) {
          const filterPaths = svg => {
            const SVGObj = SVG(svg)
            const SVGObjBbox = SVGObj.bbox()
            let pantPath = ''
            SVGObj.children().forEach(p => pantPath = pantPath + p.attr('d'))
            const bottomPath = ShapeInfo.path(`M${SVGObjBbox.x} ${SVGObjBbox.y2 - 2.5} L${SVGObjBbox.x2} ${SVGObjBbox.y2 - 2.5}`)
            const pantShape = ShapeInfo.path(pantPath)
            const intersectBottom = Intersection.intersect(bottomPath, pantShape)
            let slitsLength = SVGObjBbox.height / 3 + (this.ease?.ease_side_slit_length * 1.8 || 0) + 1
            let slitsWidth = 1.5
            if (slitsLength < 7) slitsLength = 7
            const topPath = ShapeInfo.path(`M${SVGObjBbox.x} ${SVGObjBbox.y2 - slitsLength} L${SVGObjBbox.x2} ${SVGObjBbox.y2 - slitsLength}`)
            const intersectTop = Intersection.intersect(topPath, pantShape)
            if (
              intersectBottom && intersectBottom.points?.length > 3 &&
              intersectTop && intersectTop.points?.length > 1
            ) {
              const leftBottomPoint = intersectBottom.points.sort((a,b) => b.x < a.x ? 1 : -1)[0]
              const leftTopPoint = intersectTop.points.sort((a,b) => b.x < a.x ? 1 : -1)[0]
              const rightBottomPoint = intersectBottom.points.sort((a,b) => a.x < b.x ? 1 : -1)[0]
              const rightTopPoint = intersectTop.points.sort((a,b) => a.x < b.x ? 1 : -1)[0]
              if (leftBottomPoint.x > leftTopPoint.x && leftBottomPoint.x - leftTopPoint.x > 2) {
                slitsWidth = leftBottomPoint.x - leftTopPoint.x - 0.5
              }
              const leftPath = SVGObj.path(`M${leftBottomPoint.x} ${leftBottomPoint.y} L${leftBottomPoint.x - slitsWidth} ${leftBottomPoint.y - 1} L${leftTopPoint.x} ${leftTopPoint.y}`)
              leftPath.attr('data-not-contour', '1')
              leftPath.attr('data-keep', '1')
              const rightpath = SVGObj.path(`M${rightBottomPoint.x} ${rightBottomPoint.y} L${rightBottomPoint.x + slitsWidth} ${rightBottomPoint.y - 1} L${rightTopPoint.x} ${rightTopPoint.y}`)
              rightpath.attr('data-not-contour', '1')
              rightpath.attr('data-keep', '1')
            }
            return SVGObj.svg()
          }
          baseElement.svgFront = filterPaths(baseElement.svgFront)
          baseElement.svgBack = filterPaths(baseElement.svgBack)
        }
        const waistband = finalElements.find(el => el.nodeCode && el.nodeCode.includes('waist_bands'))
        if (waistband) {
          const makeWaistband = (svg, wSide) => {
            const waistbandSVGObj = SVG(svg)
            waistbandSVGObj?.children().forEach(el => {
              if (
                this.designOptions.settingCodes.includes('loops:no_loops') &&
                el.attr('id') &&
                el.attr('id').includes('loop')
              ) el.remove()
            })
            return waistbandSVGObj?.svg()
          }
          waistband.svgFront = makeWaistband(waistband.svgFront, 'front')
          waistband.svgBack = makeWaistband(waistband.svgBack, 'back')
        }
        if (baseElement.nodeCode.includes('sk_silhouettes')) {
          const waistband = finalElements.find(el => el.nodeCode && el.nodeCode.includes('sk_waist_bands'))
          const frontClosures = finalElements.find(el => el.nodeCode && el.nodeCode.includes('front_closures'))
          const backClosures = finalElements.find(el => el.nodeCode && el.nodeCode.includes('back_closures'))
          const filterPaths = (svg, side) => {
            const SVGObj = SVG(svg)
            let paths = SVGObj.find('path')
            let skirtBottom
            let skirtTop
            let silhouettes = ''
            paths.forEach(p => {
              const contourIds = [
                'skirt-top',
                'skirt-bottom',
                'skirt-left',
                'skirt-right'
              ]
              let normalId = null
              if (p.attr('id')) {
                normalId = p.attr('id').split('_')
                normalId = normalId && normalId[0] ? normalId[0].trim() : null
                if (normalId === 'skirt-top') skirtTop = p
                else if (normalId === 'skirt-bottom') {
                  if (!skirtBottom) skirtBottom = p
                  else if (skirtBottom.bbox().width < p.bbox().width) {
                    skirtBottom = p
                  }
                }
              }
              if (!p.attr('id') || !contourIds.includes(normalId)) {
                p.attr('data-not-contour', '1')
                p.attr('data-keep', '1')
              }
              if (contourIds.includes(normalId)) {
                silhouettes = silhouettes + ' ' + p.attr('d')
              }
            })
            const isFrontSlits = this.designOptions.settingCodes.find(el => el.includes('skirt_front_slits'))
            if (isFrontSlits && !isFrontSlits.includes('no_slits')) {
              let skirtDartsRight = null
              let skirtDartsLeft = null
              paths.forEach(p => {
                if (p.attr('id') === 'skirt-darts-right') skirtDartsRight = p
                if (p.attr('id') === 'skirt-darts-left') skirtDartsLeft = p
              })
              let leftBottomPoint = skirtBottom.pointAt(0)
              let rightBottomPoint = skirtBottom.pointAt(skirtBottom.length())
              if (skirtBottom.pointAt(skirtBottom.length()).x < skirtBottom.pointAt(0).x) {
                leftBottomPoint = skirtBottom.pointAt(skirtBottom.length())
                rightBottomPoint = skirtBottom.pointAt(0)
              }
              const skirtHeight = rightBottomPoint.y - skirtTop.bbox().y2
              const slitsLength = skirtHeight / 3 + (this.ease?.ease_front_slit_length * 1.3 || 0)
              const slitsWidth = 1
              const p1 = ShapeInfo.path(skirtBottom.attr('d'))
              const topLength = skirtTop.length()
              let topPoint = skirtTop.pointAt(topLength)
              if (
                side === 'front' && 
                (isFrontSlits.includes('left') || isFrontSlits.includes('both'))
              ) {
                const p2 = ShapeInfo.path(`M${rightBottomPoint.x - 7} ${rightBottomPoint.y + 10} L ${rightBottomPoint.x - 7 - slitsWidth} ${rightBottomPoint.y - slitsLength} L${rightBottomPoint.x - 7 - slitsWidth - slitsWidth} ${rightBottomPoint.y + 10}`)
                const intersect = Intersection.intersect(p1, p2)
                if (intersect.points.length === 2) {
                  let leftPoint = intersect.points[0]
                  let rightPoint = intersect.points[1]
                  if (intersect.points[1].x < intersect.points[0].x) {
                    leftPoint = intersect.points[1]
                    rightPoint = intersect.points[0]
                  }
                  if (skirtDartsLeft) {
                    let topPointSkirtDartsLeft = skirtDartsLeft.pointAt(0)
                    const dartsLeftPath = SVGObj.path(`M${leftPoint.x} ${leftPoint.y} L${topPointSkirtDartsLeft.x} ${topPointSkirtDartsLeft.y}`)
                    dartsLeftPath.attr('data-keep', '1')
                    const topSlitPath = dartsLeftPath.pointAt(slitsLength)
                    skirtDartsLeft.remove()
                    const rightpath = SVGObj.path(`M${rightPoint.x} ${rightPoint.y} L ${topSlitPath.x} ${topSlitPath.y} L${leftPoint.x - slitsWidth} ${leftPoint.y}`)
                    dartsLeftPath.attr('d', `M${topSlitPath.x} ${topSlitPath.y} L${topPointSkirtDartsLeft.x} ${topPointSkirtDartsLeft.y}`)
                    rightpath.attr('data-keep', '1')
                  } else {
                    const dartsLeftPath = SVGObj.path(`M${leftPoint.x} ${leftPoint.y} L${topPoint.x - topLength/3.5} ${topPoint.y}`)
                    dartsLeftPath.attr('data-keep', '1')
                    const topSlitPath = dartsLeftPath.pointAt(slitsLength)
                    const rightpath = SVGObj.path(`M${rightPoint.x} ${rightPoint.y} L ${topSlitPath.x} ${topSlitPath.y} L${leftPoint.x - slitsWidth} ${leftPoint.y}`)
                    dartsLeftPath.attr('d', `M${topSlitPath.x} ${topSlitPath.y} L${topPoint.x - topLength/3.5} ${topPoint.y}`)
                    rightpath.attr('data-keep', '1')
                  }
                }
              }
              if (
                side === 'front' && 
                (isFrontSlits.includes('right') || isFrontSlits.includes('both'))
              ) {
                const p2 = ShapeInfo.path(`M${leftBottomPoint.x + 7} ${leftBottomPoint.y + 10} L ${leftBottomPoint.x + 7 + slitsWidth} ${leftBottomPoint.y - slitsLength} L${leftBottomPoint.x + 7 + slitsWidth + slitsWidth} ${leftBottomPoint.y + 10}`)
                const intersect = Intersection.intersect(p1, p2)
                if (intersect.points.length === 2) {
                  let leftPoint = intersect.points[0]
                  let rightPoint = intersect.points[1]
                  if (intersect.points[1].x < intersect.points[0].x) {
                    leftPoint = intersect.points[1]
                    rightPoint = intersect.points[0]
                  }
                  if (skirtDartsRight) {
                    let topPointSkirtDartsRight = skirtDartsRight.pointAt(0)
                    const dartsRightPath = SVGObj.path(`M${rightPoint.x} ${rightPoint.y} L${topPointSkirtDartsRight.x} ${topPointSkirtDartsRight.y}`)
                    dartsRightPath.attr('data-keep', '1')
                    const topSlitPath = dartsRightPath.pointAt(slitsLength)
                    skirtDartsRight.remove()
                    const leftpath = SVGObj.path(`M${rightPoint.x + slitsWidth} ${rightPoint.y} L ${topSlitPath.x} ${topSlitPath.y} L${leftPoint.x} ${leftPoint.y}`)
                    dartsRightPath.attr('d', `M${topSlitPath.x} ${topSlitPath.y} L${topPointSkirtDartsRight.x} ${topPointSkirtDartsRight.y}`)
                    leftpath.attr('data-keep', '1')
                  } else {
                    const skirtTop1 = skirtTop.pointAt(0)
                    const dartsRightPath = SVGObj.path(`M${rightPoint.x} ${rightPoint.y} L${skirtTop1.x + topLength/3.5} ${topPoint.y}`)
                    dartsRightPath.attr('data-keep', '1')
                    const topSlitPath = dartsRightPath.pointAt(slitsLength)
                    const leftpath = SVGObj.path(`M${rightPoint.x} ${rightPoint.y} L ${topSlitPath.x} ${topSlitPath.y} L${leftPoint.x - slitsWidth} ${leftPoint.y}`)
                    dartsRightPath.attr('d', `M${topSlitPath.x} ${topSlitPath.y} L${skirtTop1.x + topLength/3.5} ${topPoint.y}`)
                    leftpath.attr('data-keep', '1')
                  }
                }
              }
            }
            const isSlits = this.designOptions.settingCodes.find(el => el.includes('skirt_slits'))
            if (isSlits && !isSlits.includes('no_slits')) {
              let leftBottomPoint = skirtBottom.pointAt(0)
              let rightBottomPoint = skirtBottom.pointAt(skirtBottom.length())
              if (skirtBottom.pointAt(skirtBottom.length()).x < skirtBottom.pointAt(0).x) {
                leftBottomPoint = skirtBottom.pointAt(skirtBottom.length())
                rightBottomPoint = skirtBottom.pointAt(0)
              }
              const skirtHeight = rightBottomPoint.y - skirtTop.bbox().y2
              const slitsLength = skirtHeight / 3 + (this.ease?.ease_side_slit_length * 1.8 || 0)
              const slitsWidth = slitsLength > 21 ? 2 : 1.5
              const p1 = ShapeInfo.path(silhouettes)
              const p2 = ShapeInfo.path(`M${leftBottomPoint.x - 10} ${leftBottomPoint.y - slitsLength} L ${rightBottomPoint.x + 10} ${rightBottomPoint.y - slitsLength}`)
              const intersect = Intersection.intersect(p1, p2)
              if (intersect.points.length === 2) {
                let leftTopPoint = intersect.points[0]
                let rightTopPoint = intersect.points[1]
                if (intersect.points[1].x < intersect.points[0].x) {
                  leftTopPoint = intersect.points[1]
                  rightTopPoint = intersect.points[0]
                }
                if (
                  (isSlits.includes('right') && side === 'front') ||
                  (isSlits.includes('left') && side === 'back') ||
                  isSlits.includes('both')
                ) {
                  const leftPath = SVGObj.path(`M${leftBottomPoint.x} ${leftBottomPoint.y - 0.5} L${leftBottomPoint.x - slitsWidth} ${leftBottomPoint.y - 1} L${leftTopPoint.x} ${leftTopPoint.y}`)
                  leftPath.attr('data-not-contour', '1')
                  leftPath.attr('data-keep', '1')
                }
                if (
                  (isSlits.includes('left') && side === 'front') ||
                  (isSlits.includes('right') && side === 'back') ||
                  isSlits.includes('both')
                ) {
                  const rightpath = SVGObj.path(`M${rightBottomPoint.x} ${rightBottomPoint.y - 0.5} L${rightBottomPoint.x + slitsWidth} ${rightBottomPoint.y - 1} L${rightTopPoint.x} ${rightTopPoint.y}`)
                  rightpath.attr('data-not-contour', '1')
                  rightpath.attr('data-keep', '1')
                }
              }
            }
            if (waistband) {
              if (waistband.params[27] === 0 && side === 'front') {
                const waistbandSVGObj = SVG(waistband.svgFront)
                const waistbandbbox = waistbandSVGObj.bbox()
                const skirtTopBbox = skirtTop.bbox()
                const centerX = skirtTopBbox.x2 - skirtTopBbox.x / 2
                let p0 = ShapeInfo.path(`M${centerX} ${waistbandbbox.y2} L${centerX} ${skirtBottom.bbox().y2 + 5}`)
                const p1 = ShapeInfo.path(skirtBottom.attr('d'))
                const intersect = Intersection.intersect(p1, p0)
                if (intersect && intersect.points[0]) {
                  waistband.svgFront = waistband.svgFront?.replace(
                    '</svg>',
                    `<path data-keep="1" data-not-contour="1" d="M${centerX} ${waistbandbbox.y2} L${centerX} ${intersect.points[0].y}"/></svg>`
                  )
                } else {
                  waistband.svgFront = waistband.svgFront?.replace(
                    '</svg>',
                    `<path data-not-contour="1" d="M${centerX} ${waistbandbbox.y2} L${centerX} ${skirtBottom.bbox().y2}"/></svg>`
                  )
                }
              }
              const isButtonDownFront = frontClosures?.attributes.find(attr => attr.type === 'skirt_front_closure' && attr.code.includes('button_down'))
              const isButtonSeamBack = backClosures?.attributes.find(attr => attr.type === 'back_closure_type' && attr.code.includes('buttoned_seam'))
              const makeWaistband = (svg, wSide) => {
                const waistbandSVGObj = SVG(svg)
                // waistband с пуговицей и closure с пуговицей (подгонка линии застежки и пуговицы пояса)
                if (isButtonDownFront || isButtonSeamBack) {
                  let closureSVGObj = SVG(wSide === 'back' ? backClosures.svgBack : frontClosures?.svgFront)
                  const find = (query, svg) => {
                    const result = svg.find(query)
                    return (result && result[0]) ? result[0] : null
                  }
                  const waistbandClosure = find(`path#closure`, waistbandSVGObj)
                  const waistbandButton = find(`path#button`, waistbandSVGObj)
                  const closureRightLine = find(`path#${wSide === 'back' ? 'left-line' : 'right-line'}`, closureSVGObj)
                  const closureButton = find(`path#back-button-7`, closureSVGObj)
                  if (waistbandClosure && closureRightLine) {
                    waistbandClosure.x(closureRightLine.bbox().x)
                    if (waistbandButton && closureButton) {
                      waistbandButton.x(closureButton.bbox().x)
                    }
                  }
                }
                waistbandSVGObj.children().forEach(el => {
                  if (el.attr('id') && el.attr('id').includes('line')) {
                    el.attr('fill-black', '1')
                    el.attr('data-fillable', '0')
                  }
                })
                return waistbandSVGObj.svg()
              }
              waistband.svgFront = makeWaistband(waistband.svgFront, 'front')
              waistband.svgBack = makeWaistband(waistband.svgBack, 'back')
            }
            return SVGObj.svg()
          }
          baseElement.svgFront = filterPaths(baseElement.svgFront, 'front')
          baseElement.svgBack = filterPaths(baseElement.svgBack, 'back')
        }
      }
      const menNeck = finalElements.find(el => el.nodeCode && el.nodeCode.includes('mn_ts_necklines'))
      if (menNeck) {
        const filterPaths = svg => {
            if (!svg) {
              return ''
            }
            const SVGObj = SVG(svg)
            const paths = SVGObj.find('path')
            paths.forEach(p => {
              if (!p.attr('id') || !p.attr('id').includes('neckline')) {
                p.attr('data-not-contour', '1')
                p.attr('data-keep', '1')
              }
            })
            return SVGObj.svg()
          }
          menNeck.svgFront = filterPaths(menNeck.svgFront)
          menNeck.svgBack = filterPaths(menNeck.svgBack)
      }
      const neck = finalElements.find(el => el.nodeCode && el.nodeCode.indexOf('back_necks') > -1)
      if (neck) {
        const savedNeck = { ...neck }
        finalElements.splice(finalElements.indexOf(neck), 1)
        finalElements.push(savedNeck)
        const neckSVG = SVG(neck.svgBack)
        this.backNeckEdge = neckSVG.bbox()
        neckSVG.children().forEach(el => {
          if (el.attr('id') && el.attr('id').includes('neckline-back-neck-left')) {
            this.backNeckEdge = el.bbox()
          }
        })
      }
      const backClosure = finalElements.find(el => el.nodeCode.indexOf('_back_closures') > -1)
      if (backClosure && side === 'back') {
        const filterPaths = svg => {
          const SVGObj = SVG(svg)
          let paths = SVGObj.find('path')
          const find = (query, svg) => {
            const result = svg.find(query)
            return (result && result[0]) ? result[0] : null
          }
          const backVent = find(`path#back-vent`, SVGObj)
          if (
            backVent &&
            (
              this.designOptions.settingCodes.includes('length:knee_length') ||
              this.designOptions.settingCodes.includes('length:above_knee')
            )
          ) {
            const baseSvgObj = SVG(baseElement.svgBack)
            let contour = baseSvgObj.children().filter(el => el.attr('id') !== 'center-back-seam')
            const baseBbox = contour.bbox()
            baseBbox.sort((a,b) => b.y2 - a.y2)
            backVent.y(baseBbox[0].y2 - 10)
          }
          const bbox = SVGObj.bbox()
          let closureLength = backClosure.attributes.find(el => el.type === "closure_length_type")
          if (
            this.backNeckEdge &&
            (
              this.isChild ||
              (
                !closureLength.code.includes('22_closure') &&
                !closureLength.code.includes('full_length_closure') &&
                !closureLength.code.includes('no_back_closure')
              )
            )
          ) {
            paths.forEach(p => {
              let addPoint = 10
              if (!this.isChild) addPoint = 20
              const y = p.bbox().y + this.backNeckEdge.y2 - bbox.y - addPoint
              p.y(y)
            })
          }
          return SVGObj.svg()
        }
        backClosure.svgBack = filterPaths(backClosure.svgBack)
      }
      const collar = finalElements.find(el => el.nodeCode && el.nodeCode.indexOf('mn_sh_necklines') > -1)
      if (collar) {
        const SVGObj = SVG(collar.svgFront)
        const paths = SVGObj.find('path')
        paths.forEach(p => {
          if (p.attr('id') && p.attr('id').includes('button')) {
            p.attr('data-fillable', 0)
          }
          if (!p.attr('id') || (p.attr('id') && !p.attr('id').match(/front-neckline-(neck|shoulder)-(left|right)/))) {
            p.attr('data-not-contour', '1')
            p.attr('data-keep', '1')
          }
        })
        collar.svgFront = SVGObj.svg()
      }
      const cuffs = finalElements.find(el => el.nodeCode && el.nodeCode.indexOf('cuffs') > -1)
      if (cuffs) {
        cuffs.svgBack = cuffs.svgBack.replace(/<path/g, '$& data-force-fill="1"')
        if (baseElement.nodeCode === 'mn_sh_silhouettes' || (baseElement.nodeCode.includes('bo_') && baseElement.nodeCode.includes('silhouettes'))) {
          const filterPaths = svg => {
            const SVGObj = SVG(svg)
            let paths = SVGObj.find('path')
            paths.forEach(p => {
              if (p.attr('fill') === '#000000') {
                p.attr('data-fillable', '0')
                p.attr('fill-black', '1')
              }
            })
            return SVGObj.svg()
          }
          cuffs.svgBack = filterPaths(cuffs.svgBack)
        }
      }
      let forceKeepCodeParts = ['ruffles', 'bindings', 'waist_bands', 'cuffs', 'pockets']
      // для сохранения cut-out рукавов, которые не являются частью контура
      if (isSarafan) {
        forceKeepCodeParts.push('sleeves')
      }
      forceKeepCodeParts.forEach(part => {
        const element = finalElements.find(el => el.nodeCode && el.nodeCode.indexOf('_' + part) > -1)
        if (element) {
          if (element.svgFront) {
            element.svgFront = this.setKeep(element.svgFront)
          }
          if (element.svgBack) {
            element.svgBack = this.setKeep(element.svgBack)
          }
        }
      })
      const waistBand = finalElements.find(el => el.nodeCode.indexOf('waist_bands') > -1)
      if (waistBand) {
        waistBand.svgFront = waistBand.svgFront?.replace(/<path/g, (m, offset) => {
          const order = 1000 + offset
          return `<path data-order="${order}"`
        })
        waistBand.svgBack = waistBand.svgBack?.replace(/<path/g, (m, offset) => {
          const order = 1000 + offset
          return `<path data-order="${order}"`
        })
      }
      const pockets = finalElements.find(el => el.nodeCode.indexOf('pn_pockets') > -1)
      if (pockets) {
        pockets.svgFront = pockets.svgFront.replace(/<path/g, (m, offset) => {
          const order = 999 + offset
          return `<path data-order="${order}"`
        })
      }
      const isSemiRaglan = baseElement.attributes.some(a => a.code === 'sleeve:semi_raglan')
      if (isSemiRaglan) {
        const leftEdge = [34.4, 31.81]
        const rightEdge = [64.57, 31.88]
        const frontNeck = finalElements.find(el => el.nodeCode.indexOf('_front_necks') > -1)
        if (frontNeck) {
          let frontNeckSVGObj = SVG(frontNeck.svgFront)
          // todo remove next
          window.frontNeckSVGObj = frontNeckSVGObj
          const leftShoulderResult = frontNeckSVGObj.find('path#neckline-shoulder-left')
          if (leftShoulderResult) {
            let leftShoulder = leftShoulderResult[0]
            if (leftShoulder) {
              leftShoulder.attr('d', leftShoulder.attr('d').replace(/M ?[\d.]+ [\d.]+/, 'M'+ leftEdge[0] + ' ' + leftEdge[1]))
            }
          }
          const rightShoulderResult = frontNeckSVGObj.find('path#neckline-shoulder-right')
          if (rightShoulderResult) {
            let rightShoulder = rightShoulderResult[0]
            if (rightShoulder) {
              rightShoulder.attr('d', rightShoulder.attr('d').replace(/[\d.]+ [\d.]+ ?$/, rightEdge[0] + ' ' + leftEdge[1]))
            }
          }
          frontNeck.svgFront = frontNeckSVGObj.svg()
        }
        const backNeck = finalElements.find(el => el.nodeCode.indexOf('_back_necks') > -1)
        if (backNeck) {
          let backNeckSVGObj = SVG(backNeck.svgBack)
          const leftShoulderResult = backNeckSVGObj.find('path#neckline-back-shoulder-left')
          if (leftShoulderResult) {
            let leftShoulder = leftShoulderResult[0]
            if (leftShoulder) {
              leftShoulder.attr('d', leftShoulder.attr('d').replace(/M ?[\d.]+ [\d.]+/, 'M'+ leftEdge[0] + ' ' + leftEdge[1]))
            }
          }
          const rightShoulderResult = backNeckSVGObj.find('path#neckline-back-shoulder-right')
          if (rightShoulderResult) {
            let rightShoulder = rightShoulderResult[0]
            if (rightShoulder) {
              rightShoulder.attr('d', rightShoulder.attr('d').replace(/[\d.]+ [\d.]+ ?$/, rightEdge[0] + ' ' + leftEdge[1]))
            }
          }
          backNeck.svgBack = backNeckSVGObj.svg()
        }
        const backClosure = finalElements.find(el => el.nodeCode.indexOf('_back_closures') > -1)
        if (backClosure) {
          // backClosure.svgBack = backClosure.svgBack.replace(/<path/g, '<path data-not-cut-direction="bottom"')
        }
      }
      const PeplumsAndRuffles = finalElements.find(el => el.nodeCode && el.nodeCode.includes('ruffles'))
      if (PeplumsAndRuffles) {
        PeplumsAndRuffles.svgFront = PeplumsAndRuffles.svgFront.replace(/data-fillable="1"/g, '')
        PeplumsAndRuffles.svgBack = PeplumsAndRuffles.svgBack.replace(/data-fillable="1"/g, '')
        if (baseElement.params[16] && (baseElement.params[16] !== 0 && baseElement.params[16] !== 5 && baseElement.params[16] !== 4) && PeplumsAndRuffles.params[2007] !== 0) {
          const SVGObj = SVG(baseElement.svgFront)
          const bbox = SVGObj.bbox()
          const PeplumsAndRufflesSVG = SVG(PeplumsAndRuffles.svgFront)
          const PeplumsAndRufflesBbox = PeplumsAndRufflesSVG.bbox()
          const paths = PeplumsAndRufflesSVG.find('path')
          let x = bbox.width / 2 + bbox.x
          let y1 = PeplumsAndRufflesBbox.y + 0.5
          let y2 = PeplumsAndRufflesBbox.y2 + 0.1
          let y3 = null
          if (baseElement.params[16] === 3) {
            x = x + 2.6
          }
          let p0 = ShapeInfo.path(`M${x + 3} ${y2} L${x} ${y1 - 3}`)
          let p2 = ShapeInfo.path(`M${x - 3} ${y2} L${x} ${y1 - 3}`)
          if (baseElement.params[16] === 1) {
            p0 = ShapeInfo.path(`M${x + 2.7} ${y2} L${x + 2.7} ${y1 - 3}`)
            p2 = ShapeInfo.path(`M${x - 2.2} ${y2} L${x - 2.2} ${y1 - 3}`)
          }
          const p1 = (ShapeInfo.path(paths[0].attr('d')))
          const intersectRight = Intersection.intersect(p1, p0)
          const intersectionRight = intersectRight.points
          const intersectLeft = Intersection.intersect(p1, p2)
          const intersectionLeft = intersectLeft.points
          if (intersectionRight.length === 2) {
            y1 = intersectionRight[0].y < intersectionRight[1].y ? intersectionRight[0].y + 0.5 : intersectionRight[1].y + 0.5
            y2 = intersectionRight[1].y > intersectionRight[0].y ? intersectionRight[1].y + 0.5 : intersectionRight[0].y + 0.5
          }
          if (intersectionLeft.length === 2) {
            y3 = intersectionLeft[1].y > intersectionLeft[0].y ? intersectionLeft[1].y + 0.5 : intersectionLeft[0].y + 0.5
          }
          if (baseElement.params[16] === 1) {
            p0 = ShapeInfo.path(`M${x} ${y2} L${x} ${y1 + 10}`)
            const intersectCenter = Intersection.intersect(p1, p0)
            const intersectionCenter = intersectCenter.points?.[0]
            let centerY = y2 - 0.3
            if (intersectionCenter && (intersectionCenter.y < y2 || intersectionCenter.y < y3)) {
              centerY = intersectionCenter.y - 0.3
            }
            const SVGObjPaths = SVGObj.find('path')
            const SVGObjPathsSort = SVGObjPaths.sort((a, b) => {
              if (a.bbox().height > b.bbox().height) return -1
              else return 1
            })
            const centerPlanca = SVGObjPathsSort[0].array()
            PeplumsAndRuffles.svgFront = PeplumsAndRuffles.svgFront.replace(
              '</svg>',
              `<path stroke-width="0.21751" data-keep="1" data-not-contour="1" data-source-node="ruffles" d="M${centerPlanca[0][1]} ${y2} L${centerPlanca[0][1]} ${y1 - 8} L ${centerPlanca[0][1] - 4.6} ${y1 - 8} L ${centerPlanca[0][1] - 4.6} ${y3 ? y3 : y2}"/></svg>`
            ).replace(
              '</svg>',
              `<path data-keep="1" data-not-contour="1" data-source-node="ruffles" id="ruffle-split-strap" d="M${centerPlanca[0][1] - 0.2} ${centerY} L${centerPlanca[0][1] - 0.3} ${y1 - 10} L ${centerPlanca[0][1] - 4.3} ${y1 - 10} L ${centerPlanca[0][1] - 4.3} ${centerY}"/></svg>`
            )
          }
          else {
            PeplumsAndRuffles.svgFront = PeplumsAndRuffles.svgFront.replace(
              '</svg>',
              `<path stroke-width="0.21751" data-keep="1" data-not-contour="1" data-source-node="ruffles" id="ruffle-split" d="M${x + 3} ${y2} L${x} ${y1} ${x - 3} ${y3 ? y3 : y2}"/></svg>`
            )
          }
        }
      }
      const sleevesTop = finalElements.find(el => el.nodeCode && el.nodeCode.includes('sleeves'))
      if (sleevesTop && sleevesTop.svgFront && sleevesTop.svgBack) {
        sleevesTop.svgFront = sleevesTop.svgFront.replace(/data-fillable="1"/g, '')
        sleevesTop.svgBack = sleevesTop.svgBack.replace(/data-fillable="1"/g, '')
      }
      finalElements.forEach(el => {
        if (el === baseElement) {
          paths = parse(this.getSideSvg(el, side)).children.filter(p => p && p.name === 'path')
        } else {
          const isStrap = el.nodeCode.indexOf('straps') > -1
          if (isStrap) {
            el.svgFront = this.setKeep(el.svgFront)
            el.svgBack = this.setKeep(el.svgBack)
          }
          const isClosure = el.nodeCode.indexOf('closure') !== -1
          if (isClosure) {
            el.svgBack = (el.svgBack || '').replace(/<path/g, '<path data-source-id="closure"')
          }
          const isSarafanNeckline = isSarafan && el.svgFront && el.nodeCode.indexOf('neck') > -1
          if (isSarafanNeckline) {
            el.svgFront = el.svgFront.replace(/<path/g, '<path data-source-id="neck"')
          }
          const isBackNeck = el.nodeCode.indexOf('_back_necks') > -1
          if (isBackNeck) {
            el.svgBack = el.svgBack.replace(/id="neckline-back-dart-(left|right)"/, '$& data-not-contour="1" data-keep="1" data-order="1"')
            if (el.svgBack.indexOf('neckline-back-hole') > -1) {
              const isWaistband = baseElement.svgBack.includes('waistband')
              if (isWaistband) {
                const baseSvgObj = SVG(baseElement.svgBack)
                const obj = SVG(el.svgBack)

                const holeLeft =  obj.children().filter(el => el.attr('id') && el.attr('id').includes('neckline-back-hole-left'))[0]
                const holeRight = obj.children().filter(el => el.attr('id') && el.attr('id').includes('neckline-back-hole-right'))[0]

                const waistband = baseSvgObj.children().filter(el => el.attr('id') && el.attr('id').includes('waistband'))
                const p0 = (ShapeInfo.path(waistband[0].attr('d')))
                const p1 = (ShapeInfo.path(holeLeft.attr('d')))
                const intersect = Intersection.intersect(p1, p0)

                if (intersect && intersect.points.length > 0) {
                  const holeLeftArray = holeLeft.array()
                  const holeRightArray = holeRight.array()
                  if (holeRightArray.length === 2 && holeLeftArray.length === 2) {
                    holeRightArray[1][holeRightArray[1].length-1] = holeRightArray[1][holeRightArray[1].length-1] - 6.5
                    holeLeftArray[1][holeLeftArray[1].length-1] = holeLeftArray[1][holeLeftArray[1].length-1] - 6.5
                    holeLeft.attr('d', holeLeftArray.toString())
                    holeRight.attr('d', holeRightArray.toString())
                    el.svgBack = obj.svg()
                  }
                }
              }
              el.svgBack = this.mergeKeyholePaths(el.svgBack)
            }
          }
          const isFrontNeckHole = el.nodeCode?.includes('front_necks') && el.svgFront?.includes('front-hole')
          if (isFrontNeckHole) {
            el.svgFront = this.mergeKeyholePaths(el.svgFront)
          }
          deps.push({
            element: {
              svgFront: el.svgFront,
              svgBack: el.svgBack,
              nodeCode: el.nodeCode,
            },
            contourPart: !isClosure,
          })
        }
      })
      let order = 1
      paths.map(p => {
        if (p.attributes && !p.attributes['data-order']) {
          p.attributes['data-order'] = order
          order++
        }
        return p
      })
      const params = {}
      if (this.elements.some(el => el.nodeCode && (el.nodeCode.includes('sk_silhouettes') || el.nodeCode === 'mn_sh_silhouettes'))) {
        params.allowSelfClosed = true
      }
      window.pathsBeforeCont = window.pathsBeforeCont || {}
      window.pathsBeforeCont[side] = paths
      let cutPaths = []
      if (this.noContour) {
        cutPaths = paths
      } else {
        cutPaths = preparePaths(paths, deps, 'svg' + (side === 'front' ? 'Front' : 'Back'), params)
      }
      // отверстия горловин являются частью контура, образованного неклайном и замкнуты
      const isNeckHole = p => p.attributes['data-source-ids'] && p.attributes['data-source-ids'].indexOf('neck') > -1 &&
        (p.attributes.d.toLowerCase().indexOf('z') || p.attributes['data-source-ids'].indexOf('hole') > -1)
      window.cutPathsBeforeSort = window.cutPathsBeforeSort || {}
      window.cutPathsBeforeSort[side] = cutPaths
      cutPaths.sort((a, b) => {
        // отверстия горловин являются контуром, но должны быть наверху
        const aNeck = +isNeckHole(a) || 0
        const bNeck = +isNeckHole(b) || 0
        if (aNeck !== bNeck) {
          return bNeck - aNeck
        }
        if (this.isChild && a.attributes['id']?.includes('bodice')) {
          const aBodice = -(a.attributes['id']?.includes('bodice') || 0)
          const bBodice  = -(b.attributes['id']?.includes('bodice') || 0)
          return aBodice - bBodice
        }
        if (this.isChild && a.attributes['id']?.includes('front-collar')) {
          const aCollar = (a.attributes['id']?.includes('button') && a.attributes['id']?.includes('front-collar') || 0)
          const bCollar  = +(b.attributes['id']?.includes('front-collar') || 0)
          return aCollar - bCollar
        }
        if (collar) {
          const aCollar = -(a.attributes['id']?.includes('front-collar') || 0)
          const bCollar  = -(b.attributes['id']?.includes('front-collar') || 0)
          if (aCollar !== bCollar) {
            return aCollar - bCollar
          }
        }

        // другие контуры должны быть внизу, под зависимыми элементами
        const aCont = +(a.attributes['data-contour'] || 0)
        const bCont = +(b.attributes['data-contour'] || 0)
        if (aCont !== bCont) {
          return bCont - aCont
        } else {
          const aOrder = a.attributes['data-order'] || Infinity
          const bOrder = b.attributes['data-order'] || Infinity
          return +aOrder - +bOrder
        }
      })
      if (['wm_dr_silhouettes'].includes(baseElement.nodeCode)) {
        let haveNeck = cutPaths.some((el) => el.attributes.id === "Neckline_2_")
        if (haveNeck) {
          cutPaths.forEach((el, index) => {
            if (el.attributes.id === "Neckline_2_") {
              cutPaths.splice(index, 1).splice(1, 0, el)
              cutPaths.splice(1, 0, el)
            }
          })
        } else {
          cutPaths.forEach((el, index) => {
            if (el.attributes['data-source-ids'] && el.attributes['data-source-ids'].includes('skirt-bottom')) {
              cutPaths.splice(index, 1)
              cutPaths.splice(0, 0, el)
            }
          })
        }
      }
      window.cutPathsAfterSort = window.cutPathsAfterSort || {}
      window.cutPathsAfterSort[side] = cutPaths
      return await this.adjustPaths(cutPaths)
    },
    async adjustPaths (injectedPaths) {
      const hasBackClosure = injectedPaths.some(p => p.attributes && p.attributes['data-element'] === 'back-closure')
      const newButtons = injectedPaths.filter(p => p.attributes.id &&
        (p.attributes.id.toString().indexOf('front-button-') === 0 || p.attributes.id.toString().indexOf('back-button-') === 0))
      if (hasBackClosure || newButtons.length > 0) {
        injectedPaths.sort((a, b) => {
        // пуговицы перенести вниз
        const aCont = +(a.attributes['id'] && a.attributes['id'].includes('front-button-') || 0)
        const bCont = +(b.attributes['id'] && b.attributes['id'].includes('front-button-') || 0)
          if (aCont !== bCont) {
            return aCont - bCont
          }
        })
        let svgObj = SVG(renderPaths(injectedPaths))
        window.svgObj = svgObj
        if (hasBackClosure) {
          svgObj = this.adjustBackClosure(svgObj)
        } else if (newButtons.length > 0) {
          svgObj = await this.adjustButtonsCount(svgObj, newButtons)
          svgObj = this.adjustBackSeam(svgObj)
        }
        injectedPaths = parse(svgObj.svg()).children
      }
      return injectedPaths
    },
    async adjustButtonsCount (svgObj, newButtons) {
      newButtons.pop()
      const sides = ['front', 'back']
      let specs = await this.getSpecs()
      if (!specs || !specs.length) {
        console.error('Could not fetch specs for adjusting buttons quantity')
        // return svgObj
      }
      for (let k = 0; k < sides.length; k++) {
        const side = sides[k]
        const specCode = `spec_${side}_buttons_quantity`
        let needButtons
        if (this.ease?.[specCode]) {
          needButtons = Math.round(+this.ease[specCode])
        } else {
          const spec = specs.find(el => el.code.indexOf(specCode) > -1)
          if (spec) {
            needButtons = Math.round(+spec.value)
          }
        }
        if (needButtons > 0) {
          const buttonId = side === 'front' ? 'front-button-' : 'back-button-'
          let buttonObjects = svgObj.find(`[id^="${buttonId}"]`)
          // пуговицы должны иметь ид вида button_N, и N должно идти по порядку. Самая верхняя 1, следующая 2 и т.д.
          if (buttonObjects.length) {
            const sortingFunc = (a, b) => {
              const getId = b => {
                b.attr('id', b.attr('id').split('_')[0])
                const matched = b.id().match(/-(\d+)$/)
                if (matched) {
                  return matched[1]
                } else {
                  console.error(b.id() + ' not valid id')
                }
              }
              return getId(a) - getId(b)
            }
            buttonObjects.sort(sortingFunc)
            let firstButton = buttonObjects[0]
            // опускаем пуговицу от горловины
            if (side === 'front' && this.frontNeckEdge) {
              const topGap = this.frontNeckEdge.y2 + 2
              firstButton.y(topGap)
            }
            if (side === 'back' && this.backNeckEdge) {
              const backNeck = this.sortedElements.find(el => el.nodeCode.includes('back_necks'))
              let topGap = this.backNeckEdge.y2 + 2
              if (backNeck) {
                const isStrap = backNeck.attributes.find(attr => attr.code === 'sleeve:straps')
                if (isStrap && backNeck.params?.[6] === 401)  {
                  topGap = this.backNeckEdge.y + 2
                }
              }
              firstButton.y(topGap)
            }
            let bottomEdge
            const bbox = svgObj.bbox()
            if (side === 'front') {
              bottomEdge = window.bottomEdge
            } else {
              const plucketEdgeObj = svgObj.find('#back-buttons-bottom')
              const centerBackSeamObj = svgObj.find('#center-back-seam')
              const centerBackSeam = centerBackSeamObj ? centerBackSeamObj[0] : null
              let intersection
              if (centerBackSeam) {
                centerBackSeam.height(bbox.y2 - centerBackSeam.y())
                const paths = svgObj.find('path')
                const p0 = ShapeInfo.path(centerBackSeam.attr('d'))
                const p1 = (paths[0].attr('id') === paths[1].attr('id') && paths[1].bbox().y2 > paths[0].bbox().y2)
                  ? ShapeInfo.path(paths[1].attr('d'))
                  : ShapeInfo.path(paths[0].attr('d'))
                const intersect = Intersection.intersect(p1, p0)
                intersection = intersect.points
              }
              if (plucketEdgeObj && plucketEdgeObj[0]) {
                bottomEdge = plucketEdgeObj[0].y()
              } else if (intersection && intersection.length) {
                bottomEdge = intersection[0].y
              } else {
                const objWithoutButton = svgObj.children().filter(el => !el.attr('id') || (el.attr('id') && !el.attr('id').includes('button') && !el.attr('id').includes('center-back-seam')))
                if (objWithoutButton.length > 0) {
                  let objWithoutButtonBbox = objWithoutButton.bbox()
                  objWithoutButtonBbox.sort((a,b) => b.y2 - a.y2)
                  bottomEdge = objWithoutButtonBbox[0].y2
                } else bottomEdge = bbox.y2
              }
            }
            const RuffleSplitStrap = svgObj.find('path#ruffle-split-strap')
            if (RuffleSplitStrap.length > 0) {
              const RuffleSplitStrapBbox = RuffleSplitStrap.bbox()
              bottomEdge = RuffleSplitStrapBbox[0].y2
            }
            if (this.isMan || this.isChild) {
              const MenTShirt = this.elements.find(el => el.nodeCode && el.nodeCode.includes('mn_ts_silhouettes'))
              const BoyTShirt = this.elements.find(el => el.nodeCode && el.nodeCode.includes('bo_') && el.nodeCode.includes('silhouettes'))
              const TShirt = MenTShirt || BoyTShirt
              const ButtonedPolo = TShirt?.attributes.find(el => el.type === 'shirt_front_closure' && el.code.includes('buttoned_polo'))
              const paths = svgObj.find('path')
              const pathArray  = paths.filter(el => el.attr('data-source-ids'))
              const collarBottom = svgObj.find('#front-collar-bottom-left')
              if (collarBottom && collarBottom[0]) {
                if (collarBottom[0].bbox().y2 > this.frontNeckEdge.y2) {
                  firstButton.y(collarBottom[0].bbox().y2 + 2)
                }
              }
              const shirtBottom = svgObj.find('#shirt-front-bottom')
              const innerDetail = svgObj.find('#inner-detail')
              if (side === 'front') {
                if (innerDetail && ButtonedPolo) bottomEdge = innerDetail.bbox()[0].y2
                else if (pathArray.length > 0) bottomEdge = pathArray.bbox()[0].y2
                else if (shirtBottom) bottomEdge = shirtBottom.bbox()[0].y2
              }
            }
            if (buttonObjects.length > 1) {
              buttonObjects[buttonObjects.length - 1].remove()
              buttonObjects.pop()
            }
            let lastButton = buttonObjects[buttonObjects.length - 1]
            if (newButtons.length > needButtons) {
              // на скетче лишние пуговицы
              const needToRemove = newButtons.length - needButtons
              let removedCount = 0
              if (needButtons === 1) {
                for (let i = 1; i < buttonObjects.length; i++) {
                  buttonObjects[i].remove()
                  removedCount++
                }
              } else {
                while (removedCount < needToRemove) {
                  // удаляем начиная с предпоследней, чтобы равномерно распределить оставшиеся
                  buttonObjects[buttonObjects.length - removedCount - 2].remove()
                  removedCount++
                }
              }
            } else {
              // на скетче не хватает
              const needToAdd = needButtons - newButtons.length
              const lastId = lastButton.id().match(/-(\d+)$/)[1]
              let added = 0
              while (added < needToAdd) {
                const toAdd = lastButton.clone()
                toAdd.attr('id', buttonId + '-' + (lastId + 1))
                toAdd.insertAfter(lastButton)
                lastButton = toAdd
                added++
              }
            }
            // расстояние между первой и последней и кол-во позволяет понять новый размер промежутка
            const newButtonObjects = svgObj.find(`[id^="${buttonId}"]`).sort(sortingFunc)
            const newGap = (bottomEdge - (firstButton.y() + firstButton.height())) / needButtons
            for (let i = 1; i < newButtonObjects.length; i++) {
              // меняем координаты относительно верхней пуговицы (она всегда неподвижна)
              newButtonObjects[i].y(firstButton.y() + newGap * i)
            }
          }
        }
      }
      return svgObj
    },
    adjustBackSeam (svgObj) {
      // при наличии плашки с пуговицами и центрального шва, двигать шов вниз
      // если full length closure - полностью удалять шов
      const centerBackSeamObj = svgObj.find('#center-back-seam')
      const centerBackSeam = centerBackSeamObj ? centerBackSeamObj[0] : null
      if (centerBackSeam) {
        // есть задние пуговицы
        if (svgObj.find('[id^=back-button-]')) {
          // есть нижняя граница плашки с пуговицами
          const buttonsBottomObj = svgObj.find('#back-buttons-bottom')
          if (buttonsBottomObj && buttonsBottomObj[0]) {
            const buttonsBottom = buttonsBottomObj[0]
            const bbox = svgObj.bbox()
            centerBackSeam.y(buttonsBottom.y())
            const paths = svgObj.find('path')
            const p0 = ShapeInfo.path(centerBackSeam.attr('d'))
            const p1 = ShapeInfo.path(paths[0].attr('d'))
            const intersect = Intersection.intersect(p1, p0)
            const intersection = intersect.points
            if (intersection.length) {
              centerBackSeam.height(intersection[0].y - centerBackSeam.y())
            } else {
              centerBackSeam.height(bbox.y2 - centerBackSeam.y())
            }
          } else {
            // есть пуговицы, но нет нижнией границы. это означает, что пуговицы идут по всей длине
            centerBackSeam.remove()
          }
        }
      }
      return svgObj
    },
    adjustNeckline (dependencies) {
      const backNeck = dependencies.find(d => d.name === 'necklines_back')
      const sleeves = dependencies.find(d => d.name === 'sleeves')
      let neckObj = null
      let sleevesObj = null
      if (backNeck && backNeck.element && backNeck.element.svgBack) {
        neckObj = SVG(backNeck.element.svgBack)
      }
      if (sleeves && sleeves.element && sleeves.element.svgBack) {
        sleevesObj = SVG(sleeves.element.svgBack)
      }
      if (neckObj && sleevesObj) {
        const shoulderLeftQuery = neckObj.find('#neckline-shoulder-left')
        const shoulderRightQuery = neckObj.find('#neckline-shoulder-right')
        const sleeveLeftQuery = sleevesObj.find('#sleeve-back-left')
        const sleeveRightQuery = sleevesObj.find('#sleeve-back-right')

        const shoulderLeft = shoulderLeftQuery[0]
        const sleeveLeft = sleeveLeftQuery[0]
        const shoulderRight = shoulderRightQuery[0]
        const sleeveRight = sleeveRightQuery[0]

        if (shoulderLeft && shoulderRight && sleeveLeft && sleeveRight) {
          const shoulderLeftArray = shoulderLeft.array()
          shoulderLeftArray[0][1] = sleeveLeft.bbox().x2
          shoulderLeftArray[0][2] = sleeveLeft.bbox().y
          shoulderLeft.attr('d', shoulderLeftArray.toString())

          const shoulderRightArray = shoulderRight.array()
          shoulderRightArray[1][shoulderRightArray[1].length - 2] = sleeveRight.bbox().x
          shoulderRightArray[1][shoulderRightArray[1].length - 1] = sleeveRight.bbox().y
          shoulderRight.attr('d', shoulderRightArray.toString())
          backNeck.element.svgBack = neckObj.svg()
        }
      }
    },
    adjustBackClosure (svgObj) {
      const bottomY = Math.max(
        ...svgObj.find('path').filter(p => p.attr('data-element') !== 'back-closure').map(p => p.bbox().y2))
      if (bottomY > 0) {
        svgObj.find('[data-element="back-closure"]').forEach(p => {
          const bbox = p.bbox()
          if (bbox.y2 > bottomY) {
            p.height(bbox.height - (bbox.y2 - bottomY))
          }
        })
      }
      return svgObj
    },
    getPathStyles (path, view) {
      let styles = ''
      const coloring = 'none'
      let strokeWidth = 0.65
      if (
        view === 'front-backPath' &&
        path.attributes['id'] &&
        (
          path.attributes['id'].includes('button') ||
          path.attributes['id'].includes('delFrontBackPath')
        )
      ) {
        styles += 'display:none;'
      }
      if (
        view === 'back-frontPath' &&
        path.attributes['id'] &&
        path.attributes['id'].includes('front-button-')
      ) {
        styles += 'display:none;'
      }
      if (path.attributes && path.attributes['stroke-width'] && +path.attributes['stroke-width'] > 0.35) {
        strokeWidth = +path.attributes['stroke-width']
      }
      const fillable = !!path.attributes['data-fillable']
      if (coloring === 'none' && !fillable) {
        styles += 'stroke-width:0.35px;'
      } else if (fillable) {
        styles += 'stroke-width:0.05px;fill: #4e5e61;'
      } else {
        styles += `stroke-width:${strokeWidth}px;`
      }
      if (this.required) {
        styles += 'stroke: #e0557e;'
      } else {
        styles += 'stroke: #4e5e61;'
      }
      if (path.attributes && path.attributes['stroke-dasharray']) {
        styles += 'stroke-dasharray:' + path.attributes['stroke-dasharray'] + ';'
      }
      if (path.attributes['id'] === 'ruffle-split') {
        styles += `opacity:0.5;stroke:black;`
      }
      if (path.attributes['id'] === 'ruffle-split-strap') {
        styles += `opacity:1;stroke:white;`
      }
      if (path.attributes['fill-black']) {
        styles += `stroke:none;`
      }
      return styles
    },
    prepareBack (svg) {
      if (!svg) {
        return ''
      }
      let needRemoveSeam = false
      const backClosure = this.elements.find(el => el.nodeCode.indexOf('back_closure') > -1)
      if (backClosure) {
        const hasAttr = code => backClosure.attributes.some(a => a.code === code)
        needRemoveSeam = hasAttr('back_closure:no_seam') && hasAttr('zipper:no_zipper') && hasAttr('vent:no_vent')
      }
      if (needRemoveSeam) {
        svg = svg.replace(
          'd="M49.43 23.12L49.43 30.27 49.43 36.96 49.43 43.64 49.43 50.32 49.43 60.35 49.43 70.37 49.43 77.05 49.43 83.73 49.43 90.42 49.43 97.1 49.43 103.78 49.43 110.46 49.43 117.15 49.43 120.49 49.43 130.51 49.43 137.19 49.43 143.87 49.43 153.9 49.43 160.58 49.43 170.6 49.43 177.29 49.43 183.97 49.43 190.65 49.43 197.33 49.43 204.02 49.43 210.7 49.43 214.04 49.43 217.38"',
          'd="M49.43 23.12 L49.43 23.12"')
      }
      return svg
    },
    getSideSvg (el, side) {
      if (side === 'front') {
        return el.svgFront
      } else if (side === 'back') {
        return this.prepareBack(el.svgBack)
      }
    },
    setKeep (svg) {
      return svg.replace(/<path/g, '<path data-keep="1" data-not-contour="1"')
    },
    getFillColor (p) {
      if (p.attributes['fill-white']) {
        return 'white'
      }
      if (p.attributes['fill-black']) {
        return 'black'
      }
      if (
        p.attributes['id'] === 'ruffle-split' ||
        p.attributes['id'] === 'ruffle-split-strap' ||
        p.attributes['id'] === 'collar' ||
        p.attributes['id'] === 'collar-bottom-line'
      ) {
        return 'white'
      }
      if (this.fabricImage) {
        return 'url(#fabric)'
      }
      if (!this.hasSleeves && p.attributes && p.attributes['data-element'] === 'sleeve' && this.color === '#ffffff') {
        return 'none'
      }
      if (!isPathClosed(p.attributes.d) && !p.attributes['data-force-fill']) {
        return 'none'
      }
      return this.color
    },
    async getSpecs () {
      let { specs } = await fetchSpecs({ ...this.designOptions })
      return specs
    },
    async getPathsForSides () {
      this.frontPaths = await this.getPaths('front')
      this.backPaths = await this.getPaths('back')
    },
    emitSVGChange () {
      this.$nextTick(() => {
        let fullSVG = this.$el.innerHTML
        if (this.fabricImage && this.$refs.fabricDefs) {
          fullSVG = fullSVG.replace(this.$refs.fabricDefs.outerHTML, '')
        }
        const ns = 'xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"'
        if (!this.$route.query.onlySketch) {
          this.$emit('change', {
            both: fullSVG,
            front: `<svg data-fill-color="${this.color}" ${ns}><g class="front-view">` + this.$refs.frontView?.innerHTML +
              '</g></svg>',
            back: `<svg data-fill-color="${this.color}" ${ns}><g class="back-view">` + this.$refs.backView?.innerHTML +
              '</g></svg>',
          })
        } else this.$emit('change')
      })
    },
    draw () {
      return this.getPathsForSides().then(() => {
        this.edgePoints = calculateEdgePoints(this.$el.innerHTML, STROKE_THICKNESS_ADJUSTMENT_PERCENT)
        this.emitSVGChange()
      })
    },
    mergeKeyholePaths (svg) {
      const obj = SVG(svg)
      let holeLeft
      let neckLeft
      let holeRightFront
      let neckRightFront
      obj.children().forEach(el => {
        if (el.attr('id')?.includes('neckline-back-hole-left')) {
          holeLeft = el
        } else if (el.attr('id')?.includes('neckline-back-neck-left')) {
          neckLeft = el
        } else if (el.attr('id')?.includes('front-hole-right')) {
          holeRightFront = el
        } else if (el.attr('id')?.includes('neckline-neck-right')) {
          neckRightFront = el
        }
      })
      if (holeLeft && neckLeft) {
        holeLeft.attr('d', neckLeft.attr('d') + ' ' + holeLeft.attr('d'))
        neckLeft.remove()
      }
      if (holeRightFront && neckRightFront) {
        holeRightFront.attr('d', neckRightFront.attr('d') + ' ' + holeRightFront.attr('d'))
        neckRightFront.remove()
      }
      return obj.svg()
    }
  },

  mounted () {
    window.sketch = this
  },
  created () {
    this.draw()
  },
  computed: {
    sketchHeight () {
      let content = document.getElementsByClassName("ComponentWrapperBase__content")
      let bottom = document.getElementsByClassName("ComponentWrapperBase__bottom")
      if (window.innerWidth < 800) {
        return 'fit-content'
      } else if (content?.[0]?.offsetTop) {
        return window.innerHeight - content[0].offsetTop - bottom[0]?.offsetHeight - 16 + 'px'
      }
      return "100%"
    },
    backTransform () {
      if (this.view !== 'both') {
        return ''
      }
      const scale = BACK_VIEW_SCALE
      const translateY = (this.edgePoints.y1 - this.edgePoints.y) * 0.5 + this.edgePoints.y * scale
      const width = this.edgePoints.x1 - this.edgePoints.x
      const translateX = width + BACK_VIEW_SHIFT + this.edgePoints.x * scale
      const translateScale = (1 - scale) * width / 2
      return `scale(${scale}) translate(${translateX} ${translateY})`
    },
    hasSleeves () {
      const sleeve = this.elements.find(el => el.nodeCode.indexOf('_sleeves') > -1)
      if (!sleeve) {
        return false
      }
      return !sleeve.attributes.some(a => a.code === 'sleeve_length:sleeveless')
    },
    isMan () {
      return this.elements.some(el => el.nodeCode && el.nodeCode.indexOf('mn_') === 0)
    },
    isChild () {
      if (
        this.elements.some(el => el.nodeCode && el.nodeCode.includes('bo_')) ||
        this.elements.some(el => el.nodeCode && el.nodeCode.includes('gl_'))
      ) {
        return true
      }
      return false
    },
    backShift () {
      if (this.isMan) {
        return 100.5
      } else if (this.isChild) {
        if (this.elements.some(el => el.nodeCode && el.nodeCode.includes('_sk_'))) {
          return 98.9
        }
        return 100
      } else if (this.elements.some(el => el.nodeCode && el.nodeCode.includes('wm_sw_'))) {
        return 100.4
      } else {
        if (this.elements.some(el => el.nodeCode && el.nodeCode.includes('_pn_'))) {
          return 98.6
        }
        return 99
      }
    },
    viewBoxString () {
      const vb = this.viewBox
      return [vb.x, vb.y, vb.w, vb.h].join(' ')
    },
    viewBox () {
      const defaultViewBox = {
        x: -25,
        y: 0,
        w: 250,
        h: 200,
      }
      if (!Object.values(this.edgePoints).some(p => p > 0)) {
        return defaultViewBox
      }

      const viewBox = {
        x: this.edgePoints.x,
        y: this.edgePoints.y,
        w: this.edgePoints.x1 - this.edgePoints.x,
        h: this.edgePoints.y1 - this.edgePoints.y
      }
      if (this.view === 'both') {
        viewBox.w += viewBox.w * BACK_VIEW_SCALE + BACK_VIEW_SHIFT
        // скетч задней части начинается ровно от центра передней, поэтому нужно увеличить высоту
        // вьюбокса на превышение высоты задней части над половиной передней
        if (BACK_VIEW_SCALE > 0.5) {
          viewBox.h += viewBox.h * (BACK_VIEW_SCALE - 0.5 + 2 * STROKE_THICKNESS_ADJUSTMENT_PERCENT * 0.01)
        }
      }
      return viewBox
    },
    maxHeight () {
      if (!this.viewBox.h) {
        return '100%'
      }
      return (this.viewBox.h * PIXELS_PER_VIEW_BOX) + 'px'
    },
    descElements () {
      return this.descItems.map(el => {
        return el.text.toLowerCase().replaceAll(' ', '-')
      })
    },
    sortedElements () {
      const sortedElements = this.elements.sort((a, b) => {
        const getPlace = el => NODES_ORDER.indexOf(el.nodeCode)
        const aPlace = getPlace(a)
        const bPlace = getPlace(b)
        if (aPlace !== bPlace) {
          return aPlace - bPlace
        }
        return 0
      })

      return sortedElements.map(el => {
        const description = this.descItems.find(d => d.nodeCode === el.nodeCode)
        return {
          ...el,
          description: description
            ? description.text.toLowerCase().replaceAll(' ', '-')
            : ''
        }
      })
    },
    showOtherSketchSideUnder () {
      if (window?.pageDesigner?.designElements?.wm_sk_silhouettes) {
        return window?.pageDesigner?.designElements?.wm_sk_silhouettes[0]?.id !== 38303
      }
      if (window?.pageDesigner?.designElements?.gl_tp_prebuilt) {
        return false
      }
      return true
    }
  },
  watch: {
    elements () {
      this.draw()
    },
    ease () {
      this.draw()
    },
  },
}
</script>

<style lang="scss">
.sketch-image-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  > svg {
    width: 100%;
    min-height: 80%;
  }
}
</style>
