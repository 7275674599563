<template>
  <div class="user-profile" v-if="userStore.data">
    <h1>My profile</h1>
    <div class="userpic">
      <figure>
        <img :src="userStore.userPic.data" alt="" v-if="userStore.userPic.type === 'image'" />
        <div class="userpic-placeholder" v-if="userStore.userPic.type === 'text'">
          <span>{{ userStore.userPic.data }}</span>
        </div>
      </figure>
      <ButtonIcon class="secondary small" @click.native="userPicModal = true">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
          <path d="M14.666 3.3c-.428 0-.855.163-1.178.485l-3.011 3.012a.75.75 0 0 0-.008.006.75.75 0 0 0-.006.008l-7.326 7.326a.75.75 0 0 0-.221.529v3.668a.75.75 0 0 0 .75.75h3.668a.75.75 0 0 0 .53-.22l7.333-7.335 3.018-3.017a1.679 1.679 0 0 0 0-2.358l-2.37-2.369a1.665 1.665 0 0 0-1.179-.484zm.002 1.493c.041 0 .081.017.117.053l2.37 2.369c.07.071.07.165 0 .236L14.667 9.94l-2.607-2.607 2.488-2.486a.168.168 0 0 1 .119-.053zM11 8.395 13.607 11l-6.584 6.584H4.416v-2.607L11 8.395z" color="#000"></path>
        </svg>
      </ButtonIcon>
    </div>
    <div class="preview" v-if="$route.params.action !== 'edit-info'">
      <h2>{{ userStore.data.firstName }} {{ userStore.data.lastName }}</h2>
      <p class="secondary">{{ userStore.data.company.manager_jobTitle }}</p>
      <div class="contact-info">
        <p>{{ userStore.data.email }}</p>
        <p>{{ userStore.data.phoneNumber }}</p>
      </div>
      <h2>{{ userStore.data.company.name }}</h2>
      <p class="secondary">{{ userStore.companyType }} | {{ userStore.companyRevenue }}</p>
      <div class="buttons">
        <ButtonSecondary class="small" @click.native="$router.push('/user/profile/edit-info')">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
              <path d="M14.666 3.3c-.428 0-.855.163-1.178.485l-3.011 3.012a.75.75 0 0 0-.008.006.75.75 0 0 0-.006.008l-7.326 7.326a.75.75 0 0 0-.221.529v3.668a.75.75 0 0 0 .75.75h3.668a.75.75 0 0 0 .53-.22l7.333-7.335 3.018-3.017a1.679 1.679 0 0 0 0-2.358l-2.37-2.369a1.665 1.665 0 0 0-1.179-.484zm.002 1.493c.041 0 .081.017.117.053l2.37 2.369c.07.071.07.165 0 .236L14.667 9.94l-2.607-2.607 2.488-2.486a.168.168 0 0 1 .119-.053zM11 8.395 13.607 11l-6.584 6.584H4.416v-2.607L11 8.395z" color="#000" style="-inkscape-stroke:none"/>
            </svg>
          </template>
          Edit profile
        </ButtonSecondary>
        <ButtonSecondary class="small" @click.native="getChangePassEmail()">
          Change password
        </ButtonSecondary>
      </div>
    </div>
    <div class="edit" v-else>
      <h2>Personal info</h2>
      <div class="fieldset">
        <InputField v-model="userStore.updateForm.firstName" :class="{ 'errored': userStore.errors.firstName }" @input="userStore.validate()">
          <template v-slot:top-label>
            First name
          </template>
          <template v-slot:bottom-label v-if="userStore.errors.firstName">
            {{ userStore.errors.firstName }}
          </template>
        </InputField>
        <InputField v-model="userStore.updateForm.lastName" :class="{ 'errored': userStore.errors.lastName }" @input="userStore.validate()">
          <template v-slot:top-label>
            Last name
          </template>
          <template v-slot:bottom-label v-if="userStore.errors.lastName">
            {{ userStore.errors.lastName }}
          </template>
        </InputField>
        <InputField v-model="userStore.updateForm.email" disabled>
          <template v-slot:top-label>
            E-mail
          </template>
        </InputField>
        <PhoneField v-model="userStore.updateForm.phoneNumber" :class="{ 'errored': userStore.errors.phoneNumber }" @validate="validatePhoneNumber">
          <template v-slot:top-label>
            Phone number
          </template>
          <template v-slot:bottom-label v-if="userStore.errors.phoneNumber">
            {{ userStore.errors.phoneNumber }}
          </template>
        </PhoneField>
      </div>
      <h2>Company info</h2>
      <div class="fieldset">
        <InputField v-model="userStore.updateForm.companyName" :class="{ errored: userStore.errors.companyName }">
          <template v-slot:top-label>
            Company name
          </template>
          <template v-slot:bottom-label v-if="userStore.errors.companyName">
            {{ userStore.errors.companyName }}
          </template>
        </InputField>
        <InputField v-model="userStore.updateForm.jobTitle" :class="{ errored: userStore.errors.jobTitle }">
          <template v-slot:top-label>
            Position
          </template>
          <template v-slot:bottom-label v-if="userStore.errors.jobTitle">
            {{ userStore.errors.jobTitle }}
          </template>
        </InputField>
      </div>
      <ButtonPrimary class="small save-changes" @click.native="updateInfo()">Save changes</ButtonPrimary>
    </div>
    <div class="password-reset" v-if="$route.params.action === 'password-reset'">
      <div class="container">
        <button class="close" @click="$router.push('/user/profile')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path d="M12.5 3.416a.75.75 0 0 0-.531.219L6.137 9.469a.75.75 0 0 0 0 1.06l5.832 5.834a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.06L7.727 10l5.304-5.303a.75.75 0 0 0 0-1.062.75.75 0 0 0-.531-.219Z"/>
          </svg>
          <span>Back</span>
        </button>
        <div class="text-block">
          <h2>Password reset link<br>has been sent</h2>
          <p>
            We sent the link to <span>{{ userStore.data.email }}</span>.<br>
            Don't forget to check your spam folder as well.
          </p>
          <div class="counter-link">
            <a href="#" @click.prevent="resendChangePassEmail()" v-if="emailCounter === 0">Resend link</a>
            <span v-else>Resend link in {{ emailCounter }}</span>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent :show-icon="false" :show="userPicModal" @close="userPicModal = !userPicModal">
      <template v-slot:extra>
        <div class="change-userpic">
          <figure @click="$refs.userpic.click()">
            <img class="new-userpic" :src="newUserPic" alt="" v-if="newUserPic.length > 0" />
            <img :src="userStore.userPic.data" v-if="userStore.userPic.type === 'image'" />
            <div class="userpic-placeholder" v-if="userStore.userPic.type === 'text'">
              <span>{{ userStore.userPic.data }}</span>
            </div>
          </figure>
          <h3>Change profile image</h3>
          <input type="file" ref="userpic" accept="image/png, image/jpeg" class="image-field" @change="getNewUserpic">
          <div class="error-message" v-if="userPicError.length > 0">
            <span v-html="userPicError"></span>
          </div>
          <ButtonPrimary class="small upload-button" :disabled="newUserPic === ''" @click.native="uploadUserPic(newUserPic)">
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
                <path d="M10.383 3.424a.75.75 0 0 0-.414.21l-5 5a.75.75 0 0 0 0 1.063.75.75 0 0 0 1.062 0L9.75 5.98v9.853a.75.75 0 1 0 1.5 0V5.979l3.719 3.718a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.062l-5-5a.75.75 0 0 0-.648-.211z"/>
              </svg>
            </template>
            Upload new
          </ButtonPrimary>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/store/pinia/user'

import ButtonIcon from '@/components/Atoms/ButtonIcon.vue'
import InputField from '@/components/Atoms/InputField.vue'
import PhoneField from '@/components/Atoms/PhoneField.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'

export default {
  name: 'UserProfile',
  components: {
    ButtonIcon,
    InputField,
    PhoneField,
    ButtonPrimary,
    ButtonSecondary,
    ModalComponent,
  },
  computed: mapStores(useUserStore),
  data: () => ({
    emailCounter: 0,
    userPicModal: false,
    newUserPic: '',
    userPicError: '',
  }),
  methods: {
    async updateInfo () {
      try {
        await this.userStore.updateInfo()
        await this.userStore.get()
        this.$router.push('/user/profile')
      } catch (e) {
        console.warn(e.message)
      }
    },
    validatePhoneNumber (val) {
      this.userStore.phoneIsValid = val
      this.userStore.validate()
    },
    async getChangePassEmail () {
      await this.userStore.getChangePassEmail()
      this.emailCounter = 60
      this.startCounter()
      this.$router.push('/user/profile/password-reset')
    },
    async resendChangePassEmail () {
      await this.userStore.getChangePassEmail()
      this.emailCounter = 60
      this.startCounter()
    },
    startCounter () {
      const counter = setInterval(() => {
        this.emailCounter--
        if (this.emailCounter <= 0) clearInterval(counter)
      }, 1000)
    },
    getNewUserpic (e) {
      this.userPicError = ''
      const image = e.target.files[0]
      const imageSize = (image.size / 1e6).toFixed(1)
      const imageTypes = ['image/jpeg', 'image/png', 'image/gif']
      if (parseFloat(imageSize) > 4) {
        this.userPicError = 'The file size is too large.<br>The maximum file size is 4MB'
        return false
      }
      if (!imageTypes.includes(image.type)) {
        this.userPicError = 'Wrong image format'
        return false
      }
      var reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.newUserPic = e.target.result
      }
    },
    async uploadUserPic (data) {
      await this.userStore.uploadUserPic(data)
      await this.userStore.get()
      this.newUserPic = ''
      this.userPicModal = false
    },
  },
  async beforeMount () {
    await this.userStore.get()
  },
}
</script>

<style lang="scss">
.user-profile {
  padding: 0 40px;

  h1 {
    color: var(--Text-Text_Primary);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 0;
    margin-bottom: 32px;
  }

  .userpic {
    width: 120px;
    height: 120px;
    position: relative;
    margin-bottom: 32px;

    figure, img {
      width: 100%;
      height: 100%;
    }
    figure {
      border-radius: 120px;
      overflow: hidden;
      margin: 0;
    }

    button {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .userpic-placeholder {
    width: 100%;
    height: 100%;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background: var(--avatar-background-shades-1);
    color: var(--Text-Text_White);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }

  .preview {
    color: var(--Text-Text_Primary);

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    h2 {
      color: var(--Text-Text_Primary);

      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
    p {
      margin: 0;

      &.secondary {
        color: var(--Text-Text_Secondary);
      }
    }

    .contact-info {
      margin: 16px 0 24px 0;
    }
    .buttons {
      display: flex;
      gap: 16px;
      margin-top: 40px;
    }
  }

  .edit {
    h2 {
      color: var(--Text-Text_Primary);

      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 16px;
    }

    .fieldset {
      width: fit-content;
      display: grid;
      grid-template-columns: repeat(2, 322px);
      grid-template-rows: repeat(2, auto);
      column-gap: 40px;
      row-gap: 10px;
      margin-bottom: 40px;
    }
  }
  .password-reset {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--Surface);
    border-top: 1px solid var(--Dividers);

    .container {
      position: relative;
      max-width: 1440px;
      margin: 0 auto;

      display: flex;
      justify-content: center;
      align-items: baseline;
      padding: 50px;
    }

    .close {
      position: absolute;
      top: 24px;
      left: 16px;
      background: transparent;
      border: none;

      display: flex;
      align-items: center;
      column-gap: 4px;

      color: var(--Primary-Shades-Primary);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      svg {
        width: 20px;
        height: 20px;
        fill: var(--Primary-Shades-Primary);
      }
    }

    .text-block {
      width: 100%;
      height: auto;
      max-width: 512px;
      padding: 48px;
      border-radius: 12px;
      border: 1px solid var(--Dividers);
      background: var(--Background);
      text-align: center;

      h2 {
        color: var(--Text-Text_Primary);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 8px;
      }

      p {
        color: var(--Text-Text_Secondary);
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
        margin-bottom: 32px;

        span {
          color: var(--Text-Text_Primary);
        }
      }
      .counter-link {
        color: var(--Button-States-Disabled_Txt);
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        a {
          color: var(--Primary-Shades-Primary);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .change-userpic {
    figure {
      width: 120px;
      height: 120px;
      border-radius: 120px;
      overflow: hidden;
      margin: 0 auto;
      margin-bottom: 24px;

      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h3 {
      text-align: center;
      margin-bottom: 24px;
    }

    button {
      margin: 0 auto;
    }

    .image-field {
      display: none;
    }

    .error-message {
      color: var(--System-Error);
      text-align: center;
      font-family: "Open Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 16px;
    }

    .upload-button {
      width: 200px;
    }

    .delete-button {
      display: block;
      border: none;
      background: transparent;
      color: var(--Primary-Shades-Primary);
      text-align: center;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 760px) {
  .user-profile {
    padding: 0 0 40px 0 !important;

    h1 {
      font-size: 24px !important;
      button {
        display: block;
      }
    }

    .userpic {
      margin-left: auto;
      margin-right: auto;
    }

    .preview {
      text-align: center;

      .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;

        button {
          width: 100%;
        }
      }
    }

    .save-changes {
      width: 100%;
    }

    .edit {
      .fieldset {
        width: 100%;
        grid-template-columns: 100%;
        grid-template-rows: repeat(4, auto);
      }
    }

    .password-reset {
      .container {
        padding: 46px 16px;

        .close {
          top: 16px;
        }

        .text-block {
          padding: 24px;

          h2 {
            font-size: 20px;
            line-height: normal;
          }
        }
      }
    }

  }
}
</style>
