import { Measurement } from '@/helpers/measurement'

const SEAM_ALLOWANCE_OPTIONS = Object.freeze([
  0,
  1,
  1.6,
])

const SEAM_ALLOWANCE_DEFAULT = 0

const getSeamAllowanceLabel = function (value, units, base) {
  if (value === 0) {
    return 'None'
  }
  const computed = Measurement.applyFractionBase(
    (new Measurement(value, Measurement.CM)).getValue(units), base)

  return `${computed} ${units}`
}

/**
 * получить ближайшее значение из списка опций к переданному.
 * @param {Number[]} options - массив со списком опций.
 * @param {Number} value - значение, к которому из списка нужно подобрать ближайшее
 * @returns {Number}
 */
const getNearestValue = function (options, value) {
  if (options.includes(value)) {
    return value
  }

  let nearestValueIdx = options.reduce((acc, item, index) => {
    if (acc === undefined) {
      return index
    }
    if (Math.abs(item - value) <
      Math.abs(item - options[acc])) {
      return index
    }
    return acc
  }, undefined)

  return options[nearestValueIdx]
}

export {
  SEAM_ALLOWANCE_OPTIONS,
  SEAM_ALLOWANCE_DEFAULT,
  getSeamAllowanceLabel,
  getNearestValue,
}
