<template>
  <div class="table-row" v-if="isNeedOption">
    <div class="row flex-center name">
      <div class="col column">
        <div class="col nameText">
          {{ name }} <div v-if="markWithStar" class="required" />
        </div>
        <span v-if="error" class="error text-negative">{{ error }}</span>
        <div v-else>
          <span
            class="col description"
            v-if="unsupportedUnits"
          >
            {{
              name.includes('buttons quantity') ?
                `from ${minValue - placeholder} ${srcUnit}
                to ${maxValue - placeholder} ${srcUnit}` :
                `from ${isChangeRange ? minValue - allUserValue : minValue} ${srcUnit}
                to ${isChangeRange ? maxValue - allUserValue : maxValue} ${srcUnit}`
            }}
          </span>
          <span
            class="col description"
            v-else
          >
            from <measure-view-component
            :fraction-base="getFractionBase"
            :output-units="units"
            floor-value
            :value-in-cm="
              Number((isChangeRange ? minValue - allUserValue : minValue).toFixed(1))
            "
          /> to <measure-view-component
            :fraction-base="getFractionBase"
            :output-units="units"
            floor-value
            :value-in-cm="
              Number((isChangeRange ? maxValue - allUserValue : maxValue).toFixed(1))
            "
          />
          </span>
        </div>
      </div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        class="icon"
        @click="displayDescription = true"
      >
        <path fill="#6F8789" d="M10.75 1.5c-5.10863 0-9.25 4.14137-9.25 9.25 0 5.1086 4.14137 9.25 9.25 9.25 5.1086 0 9.25-4.1414 9.25-9.25 0-5.10863-4.1414-9.25-9.25-9.25ZM0 10.75C0 4.81294 4.81294 0 10.75 0 16.6871 0 21.5 4.81294 21.5 10.75c0 5.9371-4.8129 10.75-10.75 10.75C4.81294 21.5 0 16.6871 0 10.75ZM10.75 6.5c-.6213 0-1.125.50368-1.125 1.125 0 .41421-.33579.75-.75.75s-.75-.33579-.75-.75C8.125 6.17525 9.30025 5 10.75 5c1.4497 0 2.625 1.17525 2.625 2.625 0 .96334-.5194 1.80457-1.2897 2.26037-.1968.11643-.3592.24353-.465.36823-.1028.121-.1203.2-.1203.2464v1.25c0 .4142-.3358.75-.75.75s-.75-.3358-.75-.75V10.5c0-.50589.2215-.91636.4766-1.21699.252-.29699.5663-.52381.8449-.68862.3329-.197.5535-.55784.5535-.96939 0-.62132-.5037-1.125-1.125-1.125Zm0 9.25c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1Z"/>
      </svg>

      <q-dialog v-if="displayDescription" v-model="displayDescription">
        <dialog-card-base
          content-container-class="col-shrink no-wrap"
        >
          <div
            v-if="imageLoadError"
            class="col col-shrink q-pa-md text-body text-center"
          >
            Sorry, no help image were found.
          </div>
          <div
            v-else
            class="imgContainer"
          >
            <img
              :src="imageUrl()"
              alt="Measure yourself"
            />
          </div>
        </dialog-card-base>
      </q-dialog>
    </div>
    <div class="custom">
      <p class="name-table">Custom ease</p>
      <units-input-component
        ref="unitsInput"
        dense
        input-class=""
        :suffix="unsupportedUnits ? srcUnit : units"
        :after="unsupportedUnits ? srcUnit : units"
        :initial-value-in-cm="initialValue"
        :min-in-cm="
          name.includes('buttons quantity') ?
          minValue - placeholder :
          Number((isChangeRange ? minValue - allUserValue : minValue).toFixed(1))
        "
        :max-in-cm="
          name.includes('buttons quantity') ?
          maxValue - placeholder :
          Number((isChangeRange ? maxValue - allUserValue : maxValue).toFixed(1))
        "
        :src-unit="srcUnit"
        placeholder="0"
        @changed="change"
        @error="makeError"
      />
      <!-- <units-input-component2
        ref="unitsInput"
        dense
        input-class=""
        :suffix="unsupportedUnits ? srcUnit : units"
        :initial-value-in-cm="initialValue"
        :min-in-cm="
          name.includes('buttons quantity') ?
          minValue - placeholder :
          Number((isChangeRange ? minValue - allUserValue : minValue).toFixed(1))
        "
        :max-in-cm="
          name.includes('buttons quantity') ?
          maxValue - placeholder :
          Number((isChangeRange ? maxValue - allUserValue : maxValue).toFixed(1))
        "
        :src-unit="srcUnit"
        placeholder="0"
        @changed="change"
        @error="makeError"
      /> -->
    </div>
    <div class="preset">
      <p class="name-table">Preset ease</p>
      <span>
        {{
          placeholder && placeholder !== '0' ?
          `${units === 'in' ? placeholder : Number(placeholder.toFixed(1))}` :
          allUserValueChanged
        }}
        {{ unsupportedUnits ? srcUnit : units }}
      </span>
    </div>
    <div class="pattern">
      <p class="name-table">Pattern with ease</p>
      <measure-view-component
        :fraction-base="unsupportedUnits ? 10 : getFractionBase"
        :output-units="unsupportedUnits ? srcUnit : units"
        :value-in-cm="patternValue"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Measurement } from '@/helpers/measurement'
// import UnitsInputComponent2 from '@/components/Units/UnitsInputComponent'
import UnitsInputComponent from '@/components/Units/UnitsInputComponentNew'
import MeasureViewComponent from '@/components/Units/MeasureViewComponent'
import DialogCardBase from '@/components/Dialog/DialogCardBase'

export default {
  name: 'EaseOption',
  components: { MeasureViewComponent, UnitsInputComponent, DialogCardBase },
  // все входящие и исходящие значения в сантиметрах
  props: {
    name: {
      type: String,
      required: true,
    },
    markWithStar: Boolean,
    minValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    placeholderValue: {
      type: Number,
      required: true,
    },
    initialValue: {
      type: [Number, undefined],
      default: undefined,
    },
    patternValue: {
      type: Number,
      required: true,
    },
    srcUnit: {
      type: String,
      required: true,
    },
    designCategory: {
      type: String,
      required: true,
    },
    allUserValue: {
      type: Number,
      default: 0,
    },
    isChangeRange: {
      type: Boolean,
      default: true,
    },
    code: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    value: '',
    error: null,
    displayDescription: false,
    imageLoadError: false,
  }),

  computed: {
    isNeedOption () {
      const needDelete = [
        'ease_bust',
        'ease_waist',
        'ease_hips',
      ]
      if (
        needDelete.includes(this.code) ||
        this.code.includes('buttons_quantity') || this.code.includes('button_diameter')
      ) {
        return false
      } else return true
    },
    placeholder () {
      if (this.units === this.srcUnit || this.unsupportedUnits) {
        return this.placeholderValue
      }
      return Measurement.decimalsToFractions(Measurement.cmToIn(this.placeholderValue)) || 0
    },
    allUserValueChanged () {
      if (this.units === this.srcUnit || this.unsupportedUnits) {
        if (!this.allUserValue) return 0
        return Number(this.allUserValue.toFixed(1))
      }
      return Measurement.decimalsToFractions(Measurement.cmToIn(this.allUserValue)) || 0
    },
    unsupportedUnits () {
      try {
        return !Measurement.checkUnit(this.srcUnit)
      } catch (e) {
        return true
      }
    },
    ...mapState([
      'units',
    ]),
    ...mapGetters([
      'getFractionBase',
    ]),
  },

  methods: {
    imageUrl () {
      if (!this.name) {
        return undefined
      }
      const designCatArray = this.designCategory.split('_')
      let wear = designCatArray[designCatArray.length - 1]
      const topsVarians = ['tshirts', 'sweatshirts', 'shirts', 'tops']
      if (
        (designCatArray[0] === 'bo' || designCatArray[0] === 'gl') &&
        topsVarians.includes(designCatArray[designCatArray.length - 1])
      ) {
        wear = 'tops'
      }
      let wearStyle = designCatArray[designCatArray.length - 1]
      if (designCatArray[designCatArray.length - 2] === 'tank' && designCatArray[designCatArray.length - 1] === 'tops') {
        wearStyle = 'tank-tops'
      }
      try {
        return require(`@/assets/easeDescriptionImages/${designCatArray[0]}/${wear}/${wearStyle}/${this.name.toLowerCase().trim().replace(/ /ig, '_')}.png`)
      } catch (e) {
        try {
          return require(`@/assets/easeDescriptionImages/${designCatArray[0]}/${wear}/${this.name.toLowerCase().trim().replace(/ /ig, '_')}.png`)
        } catch (e) {
          try {
            return require(`@/assets/easeDescriptionImages/${designCatArray[0]}/${this.name.toLowerCase().trim().replace(/ /ig, '_')}.png`)
          } catch (e) {
            try {
              return require(`@/assets/easeDescriptionImages/${this.name.toLowerCase().trim().replace(/ /ig, '_')}.png`)
            } catch (e) {
              this.imageLoadError = true
            }
          }
        }
      }
    },
    applyFractionBase: (value, base = 10) => Measurement.applyFractionBase(value, base),
    rangeValidator (val) {
      let errorNumberMin = 0
      let errorNumberMax = 0
      if (this.units === 'in') {
        errorNumberMin = 0.15
        errorNumberMax = 0.2
      }
      if (this.name.includes('buttons quantity')) {
        return val >= this.minValue - this.placeholder && val <= this.maxValue - this.placeholder
      }
      return (
        val + errorNumberMax >= (Number((this.isChangeRange ? this.minValue - this.allUserValue : this.minValue).toFixed(1))) &&
        val - errorNumberMin <= Number((this.isChangeRange ? this.maxValue - this.allUserValue : this.maxValue).toFixed(1))
      )
    },
    change (val) {
      if (!val || this.rangeValidator(val)) {
        this.$emit('change', val)
      }
    },
    makeError (val) {
      this.error = val
      this.$emit('validationError', val)
    },
    reset () {
      if (this.$refs['unitsInput']) this.$refs['unitsInput'].reset()
      this.value = ''
    },
  },

}
</script>

<style lang="scss">
  @import "src/styles/variables";

  // $description-size: $base-font-size * 0.85;
  .table-row {
    .name-table {
      display: none;
    }
    .custom .q-field__native {
      font-weight: normal !important;
    }
    .nameText {
      display: flex;
      align-items: center;
    }
    .required {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #FF6770;
      margin-left: 8px;
    }

    // td {
    //   border: 1px solid $custon-grey;
    //   &:first-child {
    //     background: #fff;
    //   }
    // }

    .icon {
      cursor: pointer;
    }

    .description {
      display: none;
      font-size: 12px;
      color: #6F8789;
    }
    .error {
      font-size: 12px;
    }

    &:hover {
      background-color: var(--Surface);
      .unit-component {
        background: white;
        border-radius: 8px;
      }
      .description {
        display: block;
        // opacity: 0.7;
      }
    }
  }
  .imgContainer img {
    max-width: 100%;
  }
  @media (max-width: 964px) {
    .table-row {
      .name-table {
        display: block;
      }
    }
  }
  @media (max-width: 450px) {
    .imgContainer img {
      max-height: 350px;
    }
  }
</style>
