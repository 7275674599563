<template>
  <tr class="FitOption">
    <td class="">
      <div class="row flex-center">
        <div class="col column avenir-font-bold">
          <span class="col">
            {{ name }}
          </span>
          <span v-if="errorMessage" class="FitOption__error text-negative">{{ errorMessage }}</span>
          <span
            class="col FitOption__description"
            v-else
          >
            min: {{ minValueConverted }} {{ units }}; max: {{ maxValueConverted }} {{ units }}
          </span>
        </div>

        <q-icon
          v-if="description"
          name="help_outline"
          size="sm"
          class="FitOption__icon cursor-pointer self-lg-end"
          @click="openDescription"
        />

        <q-dialog v-model="displayDescription">
          <dialog-card-base
            content-container-class="col-shrink no-wrap"
            :style="dialogStyles"
          >
            <div
              v-if="imageLoadError"
              class="col col-shrink q-pa-md text-body1 text-center"
            >
              Sorry, no help image were found.
            </div>
            <div
              v-else
              :class="`col ${verticalDisplay ? 'FitOption__imageContainerVertical' : 'FitOption__imageContainer'} q-px-md`"
            >
              <img
                v-if="image"
                :src="imageUrl"
                :alt="description"
                @load="imageLoadHandler('load')"
                @error="imageLoadHandler('error')"
              />
            </div>

          </dialog-card-base>
        </q-dialog>
      </div>
    </td>
    <td class="avenir-font-bold">
      <option-select-component
        style="width:100px;"
        v-if="selectOptions"
        dense
        :options="selectOptions.options"
        :initial-value="initialValueInCm"
        :name="name"
        @changed="change"
      />
      <units-input-component
        v-else
        dense
        input-class="text-right"
        :min-in-cm="minInCm"
        :max-in-cm="maxInCm"
        :src-unit="Measurement.CM"
        :suffix="units"
        :disable="!editAvailable"
        :placeholder="placeholder"
        :placeholderValue="placeholder"
        @changed="change"
        @error="makeError"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import UnitsInputComponent from '@/components/Units/UnitsInputComponent'
import DialogCardBase from '@/components/Dialog/DialogCardBase'
import { Measurement } from '@/helpers/measurement'
import { selectItems } from '@/components/Fit/selectOptions'
import OptionSelectComponent from '@/components/Units/OptionSelectComponent'
import { getSizeParamImageUrl } from '@/api'

export default {
  name: 'FitOption',
  components: {
    OptionSelectComponent,
    DialogCardBase,
    UnitsInputComponent,
  },
  props: {
    optionId: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    image: {
      type: String,
      default: undefined,
    },
    minInCm: {
      type: Number,
      required: true,
    },
    maxInCm: {
      type: Number,
      required: true,
    },
    initialValueInCm: {
      type: Number,
      default: undefined,
    },
    editAvailable: Boolean,
  },

  data: () => ({
    imageLoadError: false,
    displayDescription: false,
    errorMessage: null,
    verticalDisplay: false,
  }),

  created () {
    this.Measurement = Measurement
    // начальное изменение селекта до ближайшего, если значение не входит в список
    if (this.selectOptions) {
      const valueValid = this.selectOptions.options.find(item => item.value === this.initialValueInCm)
      if (!valueValid) {
        let minDiff, value
        this.selectOptions.options.forEach(item => {
          if (minDiff === undefined || Math.abs(item.value - this.initialValueInCm) < value) {
            value = item.value
          }
        })
        if (value !== undefined) {
          this.$emit('change', value)
        }
      }
    }
  },

  computed: {
    placeholder () {
      return this.units === 'in'
        ? Measurement.decimalsToFractions(Measurement.cmToIn(this.initialValueInCm))
        : this.initialValueInCm
    },
    dialogStyles () {
      return this.imageLoadError ? {} : {
        'min-height': this.verticalDisplay ? '' : '500px',
        'height': this.verticalDisplay ? 'auto' : '100%',
      }
    },
    selectOptions () {
      return selectItems.find(item => item.uid === this.optionId)
    },
    imageUrl () {
      if (!this.image) {
        return undefined
      }
      let obj = window.pageDesigner || window.fitEditor
      let type = obj.mannequinType || 'FEMALE'
      return getSizeParamImageUrl(this.image) + '?mannequinClass=' + type
    },
    fractionBase () {
      return this.getFractionBase
    },
    minValueConverted () {
      return this.units === 'in'
        ? Measurement.decimalsToFractions(Measurement.cmToIn(this.minInCm))
        : this.minInCm
    },
    maxValueConverted () {
      return this.units === 'in'
        ? Measurement.decimalsToFractions(Measurement.cmToIn(this.maxInCm))
        : this.maxInCm
    },
    ...mapState([
      'units',
    ]),
    ...mapGetters([
      'getFractionBase',
    ]),
  },

  methods: {
    applyFractionBase: (value, base = 10) => Measurement.applyFractionBase(value, base),
    change (val) {
      this.$emit('change', val)
    },
    makeError (val) {
      this.errorMessage = val
      this.$emit('validationError', val)
    },
    imageLoadHandler (type) {
      switch (type) {
        case 'error':
          this.imageLoadError = true
          break
        case 'load':
          this.imageLoadError = false
          break
      }
    },
    openDescription () {
      this.imageLoadError = false
      this.displayDescription = true
    },
  },
  mounted () {
    const windowInnerWidth = document.documentElement.clientWidth
    const windowInnerHeight = document.documentElement.clientHeight
    if (windowInnerWidth < windowInnerHeight || windowInnerHeight > 1080) {
      this.verticalDisplay = true
    }
  },
}
</script>

<style lang="scss">
  @import "src/styles/variables";
  .FitOption {

    td {
      border: 1px solid rgba(0, 0, 0, 0.12);
      &:first-child {
        background: #fff;
      }
      &:last-child {
        display: flex;
        justify-content: end;
        height: 100%;
      }
    }
    &__icon {
      color: $custom-black !important;
    }

    &__error {
      font-size: $base-font-size * 0.85;
    }

    &__description {
      opacity:   0;
      font-size: $base-font-size * 0.85;
    }

    &:hover &__description {
      opacity: 0.7;
    }

    &__imageContainer {
      img {
        width:      100%;
        height:     100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    &__imageContainerVertical {
      text-align: center;
      img {
        max-width:      100%;
        height:     100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    @media (max-width: 450px) {
      &__imageContainerVertical {
        img {
          max-height: 350px;
        }
      }
    }

  }
</style>
