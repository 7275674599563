import render from './renderCommands'
import {DEBUG} from '../utils/logger'

const translate = {
  'svgFront': 'translate(61, 50)',
  'svgBack': 'translate(280, 75) scale(-0.7,0.7) translate(61, 50)'
}

/**
 * Get html element to draw debug purpose paths and run callback
 * @param cb
 */
const getContainer = (cb) => {
  try {
    const container = document.querySelector('.main-area')
    if (container && DEBUG) {
      cb(container, document)
    }
  } catch (e) {}
}

/**
 * Get bbox for path bounds
 * @param x1
 * @param y1
 * @param x2
 * @param y2
 * @returns {string}
 */
const createPathForRect = (x1, y1, x2, y2) => {
  return `M${x1} ${y1} H${x2} V${y2} H${x1} Z`
}

/**
 * Append rendered svg element to drawing area
 * @param name
 * @param attributes
 */
const injectToDocument = (name, attributes) => {
  getContainer((container, document) => {
    const svgns = 'http://www.w3.org/2000/svg'
    const element = document.createElementNS(svgns, name)
    attributes.forEach(attribute => {
      element.setAttributeNS(null, attribute[0], attribute[1])
    })
    container.appendChild(element)
  })
}

/**
 * Draw points as circles on given side with color
 * @param points
 * @param side
 * @param color
 */
export const visualizePoints = (points, side = 'svgFront', color = 'red') => {
  points.forEach(point => {
    const attributes = [
      ['cx', point.x],
      ['cy', point.y],
      ['r', 1],
      ['transform', translate[side]],
      ['fill', color],
      ['stroke', 'black'],
      ['stroke-width', 0.025],
      ['class', 'debug-point']
    ]
    injectToDocument('circle', attributes)
  })
}

/**
 * Draw path on side with color and name
 * @param path
 * @param side
 * @param color
 * @param name
 */
export const visualizePath = (path, side = 'svgFront', color = 'red', name) => {
  const attributes = [
    ['name', name],
    ['d', path],
    ['transform', translate[side]],
    ['stroke', color],
    ['fill', 'none'],
    ['stroke-width', 0.5],
    ['class', 'debug-path']
  ]
  injectToDocument('path', attributes)
}
/**
 * Render path command to debug area constructing algorithm
 * @param command
 * @param side
 * @param color
 * @param name
 */
export const visualizeCommand = (command, side = 'svgFront', color = 'red', name) => {
  const path = [{code: 'M', x: command.x0, y: command.y0}, command]
  visualizePath(render(path), side, color, name)
}

/**
 * Render rectangle as path
 * @param rect
 * @param side
 * @param color
 * @param name
 */
export const visualizeRect = (rect, side = 'svgFront', color = 'red', name) => {
  const path = createPathForRect(...rect)
  visualizePath(path, side, color, name)
}

/**
 * Render array of path commands
 * @param commands
 * @param side
 * @param color
 * @param name
 */
export const visualizeCommands = (commands, side = 'svgFront', color = 'red', name) => {
  const path = [commands[0].code === 'M' ? null : {code: 'M', x: commands[0].x0, y: commands[0].y0}, ...commands]
  visualizePath(render(path), side, color, name)
}

/**
 * Remove all rendered debug elements
 */
export const clear = () => {
  getContainer(container => {
    const debugElements = container.querySelectorAll('.debug-point, .debug-path')
    debugElements.forEach(el => el.remove())
  })
}
