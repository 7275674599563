<template>
  <dialog-card-base
    title="Ease"
    :style="largeHeight ? 'height: 75rem;' : 'height:100%;'"
    contentContainerClass="normalBlock"
    class="PageDesigner__easeModal"
  >
    <!-- <q-markup-table flat separator="none">
      <thead>
      <tr>
        <th class="text-left">Point of Measure</th>
      </tr>
      </thead>
    </q-markup-table> -->

    <template v-if="loadingInProgress">
      <div class="EaseComponent__loader row">
        <q-spinner-dots
          class="col-2 offset-5"
          color="primary"
          size="2em"
        />
      </div>
    </template>
    <div class="column col-grow">
      <q-scroll-area style="height: 100%; min-height: 100px;" visible>
        <q-markup-table flat separator="none" square class="EaseTable">
          <tbody class="ease-table">
          <ease-select-option
            name="Fabric Type"
            :options="fabricTypeOptions"
            v-model="fabricType"
          />
          <ease-select-option
            name="Fit"
            :options="fitOptions"
            v-model="fit"
          />
          <tr
            class="pointer"
            @click="isShowAdvancedOptions = !isShowAdvancedOptions"
          >
            <td class="text-left avenir-font-bold" colspan="3">Advanced Ease Options</td>
            <td class="text-right">
              <i
                style="font-size: 24px; color:rgba(0, 0, 0, 0.54)"
                :class="`.q-select__dropdown-icon ${isShowAdvancedOptions ? 'rotate-180' : ''} material-icons`"
              >
                expand_more
              </i>
            </td>
          </tr>
          <tr v-show="isShowAdvancedOptions">
            <td colspan="4" style="padding-right:0">
              <units-toggle-component
                class="col flex justify-end avenir-font-bold"
              />
            </td>
          </tr>
          <tr v-show="isShowAdvancedOptions" class="avenir-font-bold title">
            <th class="text-center relative-position">
              <div class="point-of-measure">Point of Measure</div></th>
            <th class="text-center">Custom<br>Ease</th>
            <th class="text-center">Preset<br>Ease</th>
            <th class="text-center">Pattern<br>with Ease</th>
          </tr>
          <ease-option
            v-show="isShowAdvancedOptions"
            ref="easeOptions"
            v-for="option in options"
            :key="option.code"
            :min-value="
              (changedRange[option.code] && changedRange[option.code].min) ?
              changedRange[option.code].min :
              (firstRange[option.code] && firstRange[option.code].min === option.minValue) ?
              firstRange[option.code].min :
              option.minValue"
            :max-value="
              (changedRange[option.code] && changedRange[option.code].max) ?
              changedRange[option.code].max :
              firstRange[option.code] && firstRange[option.code].min === option.minValue ?
              firstRange[option.code].max :
              option.maxValue"
            :name="option.name"
            :pattern-value="option.patternValue"
            :placeholder-value="option.originalValue"
            :initial-value="easeUserValues[option.code]"
            :mark-with-star="
              easeUserValues[option.code] !== undefined ||
              allUserValue[option.code] !== undefined ||
              designOptions.eases[option.code] !== undefined
            "
            :src-unit="option.unit"
            :design-category="designOptions.designCategory"
            :all-user-value="Number(((designOptions.eases[option.code] ? designOptions.eases[option.code] - zeroEases[option.code] : 0) + (isEditPage ? allUserValue[option.code] || 0 : 0)).toFixed(2))"
            :is-change-range="
              changedRange[option.code] ?
              true :
              (firstRange[option.code] && firstRange[option.code].min === option.minValue)
            "
            @change="setEase(option.code, $event)"
            @validationError="makeError"
          />
          </tbody>
        </q-markup-table>
      </q-scroll-area>
    </div>
    <!-- <div class="q-pa-md justify-between flex items-center avenir-font-regular">
      <span><span class="text-secondary">*</span> - custom values</span>
      <units-toggle-component
        v-if="isEditPage"
        class="col flex justify-end"
      />
    </div> -->

    <template v-slot:actions>
      <div class="row full-width justify-end">
        <!-- <a
          :href="stretchCalculatorUrl"
          download
          target="_blank"
        >
          <q-icon name="insert_drive_file" size="md"/>
          Download Our Stretch Calculator
        </a> -->
        <q-btn
          flat
          color="primary"
          @click.prevent="allReset"
          class="avenir-font-bold"
          style="font-size:14px"
        >
          <svg style="margin-right:5px;" width="18" height="18" viewBox="0 0 72.1 72.4"  fill="#D7D2CB">
            <path d="M10.4 40.8a26.16 26.16 0 102.9-17H1.9A36.33 36.33 0 11.13 41h10.3z"/>
            <path d="M24.4 27.5H0V3.6l24.4 23.9z" />
          </svg>
          Reset
        </q-btn>
      </div>
      <div :class="`row full-width items-center ${isEditPage ? 'editPage' : ''}`">
        <!-- <div v-if="!$q.screen.lt.sm && !$q.screen.lt.md" class="col"></div> -->
        <div :class="`relative-position avenir-font-bold col flex ${!$q.screen.lt.sm && !$q.screen.lt.md ? 'justify-center' : 'justify-start'}`">
          <q-btn
            size="md"
            color="secondary"
            :disable="validationError"
            class="black-button"
            no-caps
            @click.prevent="apply">
            Apply
          </q-btn>
          <q-btn
            outline
            color="primary"
            size="md"
            no-caps
            style="margin-left:10px;"
            class="outline-button"
            @click.prevent="$emit('close')">
            {{ isEditPage ? 'Cancel' : 'Close' }}
          </q-btn>
          <q-btn
            v-if="isEditPage"
            size="md"
            color="secondary"
            :disabled="validationError"
            class="black-button absolute absolute-right"
            no-caps
            @click.prevent="$emit('save')"
          >
            Finish
          </q-btn>
        </div>
        <!-- <units-toggle-component
          class="col flex justify-end avenir-font-bold"
        /> -->
      </div>
    </template>
  </dialog-card-base>
</template>

<script>
import EaseOption from '@/components/Ease/EaseOption'
import DialogCardBase from '@/components/Dialog/DialogCardBase'
import UnitsToggleComponent from '@/components/Units/UnitsToggleComponent'
import EaseSelectOption from '@/components/Ease/EaseSelectOption'
import {
  defaultValues,
  fitSelect,
  getFabricTypeDefaultValue,
  getFabricTypeSelectOptionsList,
} from '@/components/Ease/easeSelects'
import { fetchEases } from '@/api'
import { stretchCalculatorUrl } from '@/filesLinks'
import { designOptionsValidator } from '@/pages/designerConstructor'
import { mapActions, mapState } from 'vuex'
// import { Measurement } from '@/helpers/measurement'
// import pDebounce from 'p-debounce'

export default {
  name: 'EaseComponent',

  components: {
    EaseSelectOption,
    UnitsToggleComponent,
    EaseOption,
    DialogCardBase,
  },

  props: {
    designOptions: {
      type: Object,
      required: true,
      validator: val => designOptionsValidator(val),
    },
    designFabric: {
      type: String,
      required: true,
      validator: item => ['woven', 'knit'].includes(item),
    },
    isEditPage: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    validationError: false,
    options: [],
    easeUserValues: {},
    fitOptions: fitSelect,
    fabricType: undefined,
    fit: undefined,
    loadingInProgress: false,
    stretchCalculatorUrl,
    largeHeight: document.documentElement.clientHeight > 1300,
    isShowAdvancedOptions: false,
    firstEases: {},
    allUserValue: {},
    zeroEases: {},
    firstRange: {},
    firstPattern: {},
    changedRange: {},
  }),

  async created () {
    if (this.isEditPage) this.isShowAdvancedOptions = true
    // this.loadEasesDebounced = pDebounce(this.loadEases, 50)
    this.fabricType = this.designOptions.fabricType
    this.fit = this.designOptions.fit
    await this.loadEases()
    this.options.forEach(el => {
      this.firstEases[el.code] = el.originalValue
    })
    // await this.loadEasesDebounced()
  },

  computed: {
    ...mapState([
      'units',
    ]),
    fabricTypeOptions () {
      return getFabricTypeSelectOptionsList(this.designFabric)
    },
    // удалить значения из ввода, которые отсутствуют в опциях. например, при загрузке старого ввода для новых значений
    easesFiltered () {
      let userValues = this.easeUserValues
      let options = this.options
      let userValuesFiltered = {}
      Object.keys(userValues).forEach(easeCode => {
        let foundOption = options.find(option => option.code === easeCode)
        if (foundOption && userValues[easeCode] !== undefined) {
          userValuesFiltered[easeCode] = +userValues[easeCode].toFixed(2)
        }
      })

      return userValuesFiltered
    },
  },

  watch: {
    // elementIds () {
    //   this.loadEasesDebounced()
    // },
    // mannequinId () {
    //   this.loadEasesDebounced()
    // },
    // fabricType () {
    //   this.loadEasesDebounced()
    // },
    // fit () {
    //   this.loadEasesDebounced()
    // },
    // seamAllowance () {
    //   this.loadEasesDebounced()
    // },
    validationError (val) {
      this.$emit('errorValidations', val)
    },
    fabricType (val, oldval) {
      this.changedEmit()
    },
    fit (val, oldval) {
      this.changedEmit()
    },
  },

  methods: {
    makeOptions () {
      let val = {}
      this.options.forEach(el => {
        if (
          Object.keys(this.designOptions.eases).includes(el.code) &&
          !Object.keys(this.allUserValue).includes(el.code) &&
          !Object.keys(this.easesFiltered).includes(el.code)
        ) {
          val[el.code] = this.designOptions.eases[el.code]
        } else if (
          (Object.keys(this.allUserValue).includes(el.code) ||
          Object.keys(this.easesFiltered).includes(el.code)) &&
          !Object.keys(this.designOptions.eases).includes(el.code)
        ) {
          val[el.code] = this.firstEases[el.code] + (this.easesFiltered[el.code] || 0) + (this.allUserValue[el.code] || 0)
        } else if (
          (Object.keys(this.allUserValue).includes(el.code) ||
          Object.keys(this.easesFiltered).includes(el.code)) &&
          Object.keys(this.designOptions.eases).includes(el.code)
        ) {
          if (!this.firstEases[el.code]) {
            val[el.code] = this.designOptions.eases[el.code] + (this.easesFiltered[el.code] || 0) + (this.isEditPage ? this.allUserValue[el.code] || 0 : 0)
          } else {
            val[el.code] = this.firstEases[el.code] + (this.easesFiltered[el.code] || 0) + (this.allUserValue[el.code] || 0)
          }
        }
      })
      return val
    },
    changedEmit () {
      const val = this.makeOptions()
      if (this.isEditPage) {
        this.$emit('changed', {
          easeOptions: val,
          fabricType: this.fabricType,
          fit: this.fit,
        })
      }
    },
    makeError (val) {
      if (val) {
        this.validationError = true
      } else this.validationError = false
    },
    async loadEases () {
      this.loadingInProgress = true
      try {
        // если опции не были загружены - передаем текущие занчения изов
        let easesForRequest = this.options.length > 0 ? this.easesFiltered : this.designOptions.eases
        let { eases } = await fetchEases({
          ...this.designOptions,
          fabricType: this.fabricType,
          fit: this.fit,
          eases: easesForRequest,
        })
        this.options = eases
        let zeroEases = await fetchEases({
          ...this.designOptions,
          fabricType: this.fabricType,
          fit: this.fit,
          eases: {},
        })
        zeroEases.eases.forEach(el => {
          this.firstRange[el.code] = {
            min: el.minValue,
            max: el.maxValue,
          }
          const ease = eases.find(ease => ease.code === el.code)
          if (
            (el.patternValue - ease.patternValue > 0.1 || ease.patternValue - el.patternValue > 0.1) &&
            !el.originalValue
          ) {
            const diff = ease.patternValue - el.patternValue
            this.changedRange[el.code] = {
              min: ease.minValue - diff,
              max: ease.maxValue - diff,
            }
            ease.minValue = ease.minValue - diff
            ease.maxValue = ease.maxValue - diff
          }
          this.zeroEases[el.code] = el.originalValue
          this.firstPattern[el.code] = el.patternValue
        })
      } catch (e) {
        this.$emit('error', { title: 'Error', message: e.message })
      }
      this.loadingInProgress = false
    },
    setEase (optionId, value) {
      this.$set(this.easeUserValues, optionId, value)
      this.changedEmit()
    },
    async apply () {
      let userValuesFiltered = this.easesFiltered

      this.loadingInProgress = true
      try {
        // let val = {}
        const allVal = this.makeOptions()
        Object.keys(allVal).forEach(el => {
          allVal[el] = Number(allVal[el].toFixed(2))
        })
        let { eases } = await fetchEases({
          ...this.designOptions,
          fabricType: this.fabricType,
          fit: this.fit,
          eases: allVal,
        })
        this.options.forEach(el => {
          if (userValuesFiltered[el.code] || userValuesFiltered[el.code] === 0) {
            this.allUserValue[el.code] = (this.allUserValue[el.code] || 0) + userValuesFiltered[el.code]
            // if (this.units === 'in') {
            //   const orginalValueIn = Measurement.decimalsToFractions(Measurement.cmToIn(el.originalValue))
            //   const originalValueCm = Measurement.inToCm(Measurement.fractionsToDecimals(orginalValueIn))
            //   val[el.code] = userValuesFiltered[el.code] + originalValueCm
            // } else val[el.code] = userValuesFiltered[el.code] + el.originalValue
          }
        })
        const newVal = {}
        eases.forEach(el => {
          if (
            (
              el.patternValue - this.firstPattern[el.code] > 0.1 ||
              this.firstPattern[el.code] - el.patternValue > 0.1
            ) &&
            !this.allUserValue[el.code]
          ) {
            if (!el.originalValue) {
              const diff = el.patternValue - this.firstPattern[el.code]
              el.minValue = el.minValue - diff
              el.maxValue = el.maxValue - diff
              this.changedRange[el.code] = {
                min: el.minValue,
                max: el.maxValue,
              }
            }
          }
          if (allVal[el.code] || allVal[el.code] === 0) {
            newVal[el.code] = Number(allVal[el.code].toFixed(2))
          }
          if (!this.firstEases[el.code]) {
            this.firstEases[el.code] = el.originalValue
          } else if (this.firstEases[el.code] !== el.originalValue && !this.allUserValue[el.code]) {
            this.firstEases[el.code] = el.originalValue
          }
          if (!this.firstRange[el.code]) {
            this.firstRange[el.code] = {
              min: el.minValue,
              max: el.maxValue,
            }
          }
        })
        this.$emit('apply', {
          easeOptions: newVal,
          fabricType: this.fabricType,
          fit: this.fit,
        })
        this.markMannequinChanged()
        this.options = eases
        this.reset()
      } catch (e) {
        this.$emit('error', { title: 'Error', message: e.message })
      }
      this.loadingInProgress = false
    },
    async allReset () {
      this.reset()
      this.changedRange = {}
      this.allUserValue = {}
      this.designOptions.eases = {}
      this.fit = defaultValues.FIT_SELECT
      this.fabricType = getFabricTypeDefaultValue(this.designFabric)
      this.changedEmit()
      await this.loadEases()
      this.options.forEach(el => {
        this.firstEases[el.code] = el.originalValue
        this.firstRange[el.code] = {
          min: el.minValue,
          max: el.maxValue,
        }
      })
    },
    reset () {
      this.easeUserValues = {}
      this.$refs['easeOptions'].forEach(input => {
        input.reset()
      })
    },
    ...mapActions('fitEditor', ['markMannequinChanged']),
  },

}
</script>

<style lang="scss">
  @import 'src/styles/global-z-indexes';
  @import "src/styles/variables";

  .EaseComponent {
    &__loader {
      position:         absolute;
      width:            100%;
      height:           100%;
      z-index:          $z-modal-loader;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  .PageDesigner__easeModal .main-header {
    margin: -16px -16px 16px;
    padding: 16px 32px;
  }
  .PageDesigner__easeModal .DialogCardBase__closeButton {
    top: 16px;
  }
  .PageDesigner__easeModal .modal-header {
    font-family: $avenir-font;
    font-style: normal;
    font-weight: bold;
  }
  .PageDesigner__easeModal .editPage .q-btn__wrapper {
    padding: 4px 16px;
    min-height: 2.572em;
    border-radius: inherit;
    width: 100%;
    height: 100%;
  }
  .normalBlock, .normalBlock + .DialogCardBase__actions {
    min-height: auto !important;
  }
  .EaseTable {
    margin: 0 16px;
    th, td {
      white-space: break-spaces;
    }
  }
  .ease-table {
    tr td:first-child {
      min-width: 150px;
      width: 41%;
    }
    tr td {
      width: 20%;
    }
    .pointer {
      cursor: pointer;
      td {
        &:last-child {
          padding-right: 0;
        }
        &:first-child {
          padding-left: 0;
        }
      }
    }
    .title {
      background: $custom-black;
      color: white;
      th {
        border-right: 1px solid $custon-grey;
        &:last-child {
          border-right: 0;
        }
      }
    }
    .point-of-measure {
      position: absolute;
      bottom: 7px;
      width: calc(100% - 32px);
      left: 16px;
      margin: 0 auto;
    }
  }
</style>
