<template>
  <header :class="['info-pages', headerScrolled || !menuFolded ? 'scrolled' : '']">
    <div class="container">
      <div class="logo" @click="logoNav()">
        <img :src="require('@/assets/home/logo.png')" alt="Patternfast" class="white" />
        <img :src="require('@/assets/home/logo-color.png')" alt="Patternfast" class="color" />
      </div>
      <div class="nav-profile">
        <nav>
          <RouterLink :to="{ name: 'pricing' }">Pricing</RouterLink>
          <RouterLink v-if="!userStore.isLoggedIn" :to="{ name: 'signIn' }">Log in</RouterLink>
          <div v-if="!userStore.isLoggedIn" class="nav-profile__buttons">
            <ButtonSecondary class="small" @click.native="$router.push({ name: 'signUp' })">Sign Up</ButtonSecondary>
            <ButtonPrimary class="small" @click.native="$router.push({ name: 'demo' })">Request a Demo</ButtonPrimary>
          </div>
        </nav>
        <div class="profile" v-if="userStore.isLoggedIn" @click="$router.push({ name: 'userCollections' })">
          <span class="name">{{ userStore.data.firstName }} {{ userStore.data.lastName }}</span>
          <figure>
            <img :src="userStore.userPic.data" v-if="userStore.userPic.type === 'image'">
            <div class="userpic-placeholder" v-if="userStore.userPic.type === 'text'">
              <span>{{ userStore.userPic.data }}</span>
            </div>
          </figure>
        </div>
        <button class="mobile-menu-button" @click="menuFolded = !menuFolded">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path v-if="menuFolded" d="M11 6.25a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h8a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-8zm-6 5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h14a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H5zm6 5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h8a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-8z"/>
            <path v-else d="M6 5.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L10.937 12 5.47 17.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.062 0L12 13.063l5.469 5.468a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.062L13.063 12l5.468-5.469a.75.75 0 0 0 0-1.062A.75.75 0 0 0 18 5.25a.75.75 0 0 0-.531.219L12 10.937 6.531 5.47A.75.75 0 0 0 6 5.25z"/>
          </svg>
        </button>
      </div>
    </div>
    <div :class="['mobile-menu', menuFolded ? 'folded' : '']" @click="menuFolded = !menuFolded">
      <RouterLink v-if="!userStore.isLoggedIn" :to="{ name: 'signIn' }">Login</RouterLink>
      <RouterLink v-if="!userStore.isLoggedIn" :to="{ name: 'signUp' }">Sign Up</RouterLink>
      <RouterLink :to="{ name: 'pricing' }">Pricing</RouterLink>
      <template v-if="userStore.isLoggedIn">
        <RouterLink :to="{ name: 'userCollections' }">Collections</RouterLink>
        <RouterLink :to="{ name: 'fitModels' }">Fit Models</RouterLink>
        <RouterLink :to="{ name: 'fitChartsCustom' }">Fit charts</RouterLink>
        <RouterLink :to="{ name: 'user_team' }">My team</RouterLink>
        <RouterLink :to="{ name: 'userProfile' }">My profile</RouterLink>
        <RouterLink :to="{ name: 'user_billing' }">Billing</RouterLink>
        <span @click="sendLogout()">Log out</span>
      </template>
    </div>
  </header>
</template>

<script>
import { RouterLink } from 'vue-router'
import { mapStores } from 'pinia'
import { logout } from '@/api'
import { useUserStore } from '../../store/pinia/user'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

export default {
  name: 'TheHeader',
  components: {
    RouterLink,
    ButtonPrimary,
    ButtonSecondary,
  },
  props: {
    scrollY: {
      type: Number,
      default: 800,
    },
  },
  data: () => ({
    headerScrolled: false,
    menuFolded: true,
  }),
  computed: mapStores(useUserStore),
  methods: {
    logoNav () {
      if (this.$route.name !== 'home') this.$router.push({ name: 'home' })
    },
    scroll () {
      this.headerScrolled = window.scrollY >= this.scrollY
    },
    async sendLogout () {
      try {
        await logout()
        window.location.pathname = '/'
      } catch (e) {
        alert('Failed to log out, please try again')
      }
    },
  },
  created () {
    window.addEventListener('scroll', this.scroll)
    this.scroll()
  },
  beforeMount () {
    this.userStore.get()
  },
  unmounted () {
    window.removeEventListener('scroll', this.scroll)
  },
}
</script>

<style lang="scss">
header.info-pages {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  border-bottom: 1px solid transparent;

  &.scrolled {
    background-color: var(--Background);
    border-bottom: 1px solid var(--Dividers);

    img.white {
      opacity: 0 !important;
    }
    img.color {
      opacity: 1 !important;
    }

    nav a {
      color: var(--Primary-Button-Surface) !important;
    }

    .profile {
      color: var(--Text-Text_Primary) !important;
    }

    .mobile-menu-button svg {
      fill: var(--Primary-Button-Surface) !important;
    }
  }

  .container {
    padding: 10px 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    position: relative;
    width: 110px;
    height: 52px;
    cursor: pointer;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: opacity 300ms ease-in-out;

      &.white {
        opacity: 1;
      }
      &.color {
        opacity: 0;
      }
    }
  }

  .nav-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 25px;

    a, a:visited {
      color: var(--Basic-White);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      transition: color 300ms ease-in-out;
    }
  }

  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    color: var(--Basic-White);

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    figure {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      overflow: hidden;
      margin: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .userpic-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        background: var(--avatar-background-shades-1);

        color: var(--Text-Text_White);
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

    }
  }
  .mobile-menu, .mobile-menu-button {
    display: none;
  }
}

@media (max-width: 480px) {
  header.info-pages {
    .container {
      padding: 20px 20px 10px 20px;
    }

    .nav-profile {
      gap: 16px;
      &__buttons {
        button:first-child {
          display: none;
        }
      }
    }

    nav a {
      display: none;
    }
    .profile .name {
      display: none;
    }

    .mobile-menu {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 24px;
      width: 100%;
      height: auto;
      position: absolute;
      z-index: -1;
      width: 100%;
      top: 0;
      left: 0;
      padding-top: 106px;
      padding-bottom: 24px;
      background: var(--Basic-White);

      transition: transform ease-in-out 100ms;

      &.folded {
        transform: translateY(-100%);
      }

      a, span {
        color: var(--Primary-Shades-Primary);
        text-align: center;

        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
      }
    }
    .mobile-menu-button {
      display: block;
      width: 24px;
      height: 24px;
      border: none;
      background: transparent;

      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: var(--Basic-White);
      }
    }
  }
}
</style>
