<template>
  <div class="column">
    <div class="cell head">
      <span>{{ columnName || name }}</span>
    </div>
    <div class="cell" v-for="l in labels" :key="l.name">
      <span v-if="l.key !== 'bellyProtuberance' && l.key !== 'buttocks'">
        {{ fields[l.key][units] }}
      </span>
      <span v-else>
        {{ dropdowns[l.key][fields[l.key]['cm']] }}
      </span>
    </div>
  </div>
</template>

<script>
import { constants, measurements, fields } from 'sizelib'

let size

export default {
  name: 'ChartViewColumnSize',
  props: {
    units: {
      type: String,
      default: 'cm',
    },
    type: {
      type: String,
      default: 'FEMALE',
    },
    mannequin: {
      type: Object,
      required: true,
    },
    columnName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    name: '',
    fields: null,
  }),
  computed: {
    key () {
      return this.type.replaceAll('-', '_')
    },
    labels () {
      return constants[this.key + '_MEASUREMENTS'].map(m => ({ ...constants.MEASUREMENTS[m], key: m }))
    },
    dropdowns () {
      const { buttocks, bellyProtuberance } = fields
      const buttocksList = {}
      const bellyList = {}
      buttocks.forEach(b => {
        buttocksList[b.value] = b.label
      })
      bellyProtuberance.forEach(b => {
        bellyList[b.value] = b.label
      })

      return {
        buttocks: buttocksList,
        bellyProtuberance: bellyList,
      }
    },
  },
  methods: {
    change (v, key) {
      const value = this.units === 'cm' ? parseFloat(v) : v
      size.fields[key][this.units] = value
      size.calculate()
      this.export()
    },
    export () {
      this.fields = size.export()
      this.$emit('export', {
        name: this.name,
        fields: this.fields,
      })
    },
    enter (label, row) {
      this.$emit('hover', {
        min: this.fields[label.key].min,
        max: this.fields[label.key].max,
        row,
        label,
      })
    },
  },
  async beforeMount () {
    size = measurements[this.key]()
    this.fields = size.export()
    for (const key in this.fields) {
      const field = this.fields[key]
      const sizeParam = this.mannequin.params.find(s => s.uid === field.param)
      size.fields[key]['cm'] = parseFloat(sizeParam.value)
    }
    this.name = this.mannequin.name
    size.calculate()
    this.fields = size.export()
  },
}
</script>

<style lang="scss">
.column {
  .align-center {
    text-align: center;
  }
}
</style>
