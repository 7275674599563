<template>
  <label class="input-field" :class="[error ? 'errored' : isFocused ? 'focused' : '']">
    <span class="top-label" v-if="$slots['top-label']">
      <slot name="top-label" />
    </span>
    <div class="field">
      <div class="start icon" v-if="$slots['start-icon']">
        <slot name="start-icon" />
      </div>
      <div>
        <p v-if="after && value" class="after-text">
          {{ value }} {{ after }}
        </p>
        <input
          :class="paddings"
          v-bind="$attrs"
          v-bind:value="value"
          v-on:input="$emit('input', $event.target.value)"
          @change="$emit('change', $event.target.value)"
          @focus="focus()"
          @blur="blur()"
          @keyup.enter="$emit('enter')"
          @keydown="$emit('keydown', $event)"
          :placeholder="(placeholder ? placeholder : '') + (after ? ' ' +after : '')"
        />
      </div>
      <div class="end icon" v-if="$slots['end-icon']">
        <slot name="end-icon" />
      </div>
      <div class="end icon" v-if="$slots['end-icon-1']">
        <slot name="end-icon-1" />
      </div>
    </div>
    <span class="bottom-label" v-if="$slots['bottom-label']">
      <slot name="bottom-label" />
    </span>
  </label>
</template>

<script>
export default {
  name: 'InputField',
  props: ['value', 'placeholder', 'after', 'error'],
  data: () => ({
    isFocused: false,
  }),
  methods: {
    focus () {
      this.isFocused = true
      this.$emit('focus')
    },
    blur () {
      this.isFocused = false
      this.$emit('blur')
    },
  },
  computed: {
    paddings () {
      return [
        this.$slots['start-icon'] ? 'left-padding' : '',
        this.$slots['end-icon'] ? 'right-padding' : '',
      ]
    },
  },
}
</script>

<style lang="scss">
.input-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  color: var(--Text-Text_Secondary);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  & * {
    transition: all ease-in-out 300ms;
  }

  .field {
    position: relative;

    .after-text {
      position: absolute;
      margin-bottom: 0;
      line-height: 40px;
      margin-left: 13px;
      font-size: 14px;
      color: var(--Text-Text_Primary);
    }
    .icon {
      position: absolute;
      z-index: 1;
      width: 20px;
      height: 20px;
      top: 10px;

      &.start {
        left: 12px;
      }
      &.end {
        right: 12px;
      }

      svg {
        display: block;
        margin: 0;
        width: 100%;
        height: 100%;
        fill: var(--Icons-Icons_Black);
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: var(--Background);
    padding: 10px 12px;
    outline: none;

    &.left-padding { padding-left: 40px; }
    &.right-padding { padding-right: 40px; }

    color: var(--Text-Text_Primary);

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    &::placeholder {
      color: var(--Text-Text_Secondary);
    }
  }

  &:hover {
    color: var(--Button-States-Hover);
    input { border: 1px solid var(--Button-States-Hover); }
    .icon svg { fill: var(--Button-States-Hover); }
  }

  &[disabled] {
    opacity: 1 !important;
    color: var(--Button-States-Disabled_Txt);
    .icon svg {
      fill: var(--Button-States-Disabled_Txt);
    }
    input {
      opacity: 1 !important;
      color: var(--Button-States-Disabled_Txt);
      border: 1px solid var(--Dividers);
    }
  }

  &.focused {
    color: var(--Basic-Black);
    .icon svg { fill: var(--Basic-Black); }
    input { border: 1px solid var(--Basic-Black); }
  }

  &.errored {
    color: var(--System-Error);
    input { border: 1px solid var(--System-Error); }
    .icon svg { fill: var(--System-Error); }
  }
}
</style>
