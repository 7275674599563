<template>
  <div class="column">
    <div class="cell" v-if="editName">
      <InputField type="text" placeholder="0" v-model="name" @blur="changeName" />
    </div>
    <div v-else class="cell head">
      <div class="add-button" v-if="!isModel" @click="$emit('add-new')">
        <div class="line"></div>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 20 20">
            <path d="M10 4.25a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25V15a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5a.75.75 0 0 0-.75-.75z"/>
          </svg>
        </button>
      </div>
      <span>{{ isModel ? 'Customize' : name }}</span>
      <button class="edit-menu-button" v-if="!isModel" @click="dropdown = !dropdown">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 21 20">
          <path d="M5.773 8.416c-.865 0-1.584.718-1.584 1.584 0 .866.719 1.584 1.584 1.584.866 0 1.582-.718 1.582-1.584 0-.866-.716-1.584-1.582-1.584zm5 0c-.865 0-1.584.718-1.584 1.584 0 .866.719 1.584 1.584 1.584.866 0 1.582-.718 1.582-1.584 0-.866-.716-1.584-1.582-1.584zm5 0c-.865 0-1.584.719-1.584 1.584 0 .865.719 1.584 1.584 1.584.866 0 1.582-.718 1.582-1.584 0-.866-.716-1.584-1.582-1.584z"/>
        </svg>
        <ul class="dropdown" v-if="dropdown">
          <li @click="rename()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
              <path d="M14.666 3.3c-.428 0-.855.163-1.178.485l-3.011 3.012a.75.75 0 0 0-.008.006.75.75 0 0 0-.006.008l-7.326 7.326a.75.75 0 0 0-.221.529v3.668a.75.75 0 0 0 .75.75h3.668a.75.75 0 0 0 .53-.22l7.333-7.335 3.018-3.017a1.679 1.679 0 0 0 0-2.358l-2.37-2.369a1.665 1.665 0 0 0-1.179-.484zm.002 1.493c.041 0 .081.017.117.053l2.37 2.369c.07.071.07.165 0 .236L14.667 9.94l-2.607-2.607 2.488-2.486a.168.168 0 0 1 .119-.053zM11 8.395 13.607 11l-6.584 6.584H4.416v-2.607L11 8.395z" color="#000" style="-inkscape-stroke:none"/>
            </svg>
            <span>Rename</span>
          </li>
          <li @click="remove()" v-if="!isFirst">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
              <path d="M8.25 2c-.912 0-1.666.754-1.666 1.666V4.75H3.666a.75.75 0 1 0 0 1.5H4.75v12.084c0 .912.754 1.666 1.666 1.666h9.168c.912 0 1.666-.754 1.666-1.666V6.25h1.084a.75.75 0 1 0 0-1.5h-2.918V3.666c0-.912-.754-1.666-1.666-1.666Zm0 1.5h5.5c.101 0 .166.065.166.166V4.75H8.084V3.666c0-.1.065-.166.166-.166Zm-2 2.75h9.5v12.084c0 .101-.065.166-.166.166H6.416c-.1 0-.166-.065-.166-.166Z"/>
            </svg>
            <span>Delete</span>
          </li>
        </ul>
      </button>
    </div>
    <div class="cell" v-for="(l, i) in labels" :key="l.name">
      <template v-if="l.key !== 'bellyProtuberance' && l.key !== 'buttocks'">
        <InputField
          type="text"
          placeholder="0"
          :value="fields[l.key][units]"
          :disabled="isNaN(fields[l.key].min.cm) || isNaN(fields[l.key].max.cm)"
          @change="change($event, l.key)"
          @focus="enter(l, i)"
          @blur="$emit('leave')"
          :class="['fitchart-column-field', fields[l.key].isActive && !fields[l.key].isValid && fields[l.key][units] !== '' ? 'errored' : '']"
        />
      </template>
      <template v-else>
        <SelectField
          :value="fields[l.key].cm"
          :options="dropdowns[l.key]"
          :disabled="isNaN(fields[l.key].min.cm) || isNaN(fields[l.key].max.cm)"
          @change="changeDropDown($event, l.key)"
          @focus="enter(l, i)"
          @blur="$emit('leave')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { constants, measurements, fields } from 'sizelib'
import InputField from '@/components/Atoms/InputField.vue'
import SelectField from '@/components/Atoms/SelectField.vue'

export default {
  name: 'ChartEditColumnSize',
  components: {
    InputField,
    SelectField,
  },
  props: {
    units: {
      type: String,
      default: 'cm',
    },
    type: {
      type: String,
      default: 'FEMALE',
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isModel: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    name: 0,
    fields: null,
    dropdown: false,
    editName: false,
    size: null,
  }),
  computed: {
    key () {
      return this.type.replaceAll('-', '_')
    },
    labels () {
      return constants[this.key + '_MEASUREMENTS'].map(m => ({ ...constants.MEASUREMENTS[m], key: m }))
    },
    dropdowns () {
      const { buttocks, bellyProtuberance } = fields
      return {
        buttocks: buttocks.map(b => ({ value: b.value, title: b.label })),
        bellyProtuberance: bellyProtuberance.map(b => ({ value: b.value, title: b.label })),
      }
    },
  },
  methods: {
    rename () {
      this.editName = !this.editName
      this.dropdown = !this.dropdown
    },
    remove () {
      this.dropdown = !this.dropdown
      this.$emit('remove')
    },
    changeName () {
      this.editName = !this.editName
      this.export()
    },
    change (v, key) {
      let value = typeof v === 'string' ? v.replaceAll(',', '.') : v
      value = this.units === 'cm' ? parseFloat(value) : v
      this.size.fields[key][this.units] = value
      this.size.calculate()
      this.export()
    },
    changeDropDown (v, key) {
      let value = parseFloat(v)
      this.size.fields[key].cm = value
      this.size.calculate()
      this.export()
    },
    export () {
      this.fields = this.size.export()
      this.$emit('export', {
        name: this.name,
        fields: this.fields,
      })
    },
    enter (label, row) {
      this.$emit('hover', {
        min: this.fields[label.key].min,
        max: this.fields[label.key].max,
        row,
        label,
      })
    },
    importSize (data) {
      const { name, sizeParams } = data
      for (const key in this.fields) {
        const field = this.fields[key]
        const sizeParam = sizeParams.find(s => s.uid === field.param)
        this.size.fields[key]['cm'] = parseFloat(sizeParam.value)
      }
      this.name = name
      this.size.calculate()
      this.export()
    },
  },
  beforeMount () {
    this.size = measurements[this.key]()
    this.export()
  },
}
</script>

<style lang="scss">
.fitchart-column-field {
  input:focus::placeholder {
    font-size: 0;
  }
}
</style>
