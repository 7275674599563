import { SVG } from '@svgdotjs/svg.js'

export const calculateEdgePoints = (svgString, adjustmentPercent = 0) => {
  const svgObj = SVG(svgString)

  const minX = Math.min(...svgObj.find('path').map(p => p.bbox().x))
  const maxX = Math.max(...svgObj.find('path').map(p => p.bbox().x2))
  const minY = Math.min(...svgObj.find('path').map(p => p.bbox().y))
  const maxY = Math.max(...svgObj.find('path').map(p => p.bbox().y2))

  const ret = {
    x: minX,
    y: minY,
    x1: maxX,
    y1: maxY,
  }
  if (adjustmentPercent) {
    const xShift = (ret.x1 - ret.x) * 0.01 * adjustmentPercent
    const yShift = (ret.y1 - ret.y) * 0.01 * adjustmentPercent
    ret.x -= xShift
    ret.x1 += xShift
    ret.y -= yShift
    ret.y1 += yShift
  }
  return ret
}

export const setViewBox = svg => {
  const ep = calculateEdgePoints(svg, 3)
  const box = [
    ep.x,
    ep.y,
    ep.x1 - ep.x,
    ep.y1 - ep.y,
  ]
  const val = box.join(' ')
  return svg.replace('<svg', `<svg viewBox="${val}"`)
}
