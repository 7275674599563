const ELEMENT_OPTIONS = Object.freeze({
  COLOR: {
    id: 'color',
    name: 'color',
  },
  FABRIC: {
    id: 'fabric',
    name: 'fabric',
  },
})

export { ELEMENT_OPTIONS }
