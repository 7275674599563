<template>
  <div>Preview</div>
</template>

<script>
export default {
  name: 'FitPreview',
}
</script>

<style scoped>

</style>
