<template>
  <div class="user-collections">
    <h1>
      <span>Collections</span>
      <ButtonIcon class="primary small" @click.native="userCollectionsStore.createModalToggle">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M10 4.75a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25v4.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5.5a.75.75 0 0 0-.75-.75z"/>
        </svg>
      </ButtonIcon>
    </h1>
    <TopPanel
      @search="userCollectionsStore.searchByCollections"
      @sort-order="userCollectionsStore.setSortOrderCollections"
      @sort-option="userCollectionsStore.sortCollection"
      @create-new="userCollectionsStore.createModalToggle"
      :sort-options="sortOptions"
    >
      <template v-slot:left-side v-if="subscriptionPlan !=='STUDENT_EA' && subscriptionPlan !=='INDIE_EA'">
        <button :class="['btn-group', userCollectionsStore.collectionsParams.personal === 1 ? 'active' : '']" @click="userCollectionsStore.togglePersonalCollections(1)">Created by me</button>
        <button :class="['btn-group', userCollectionsStore.collectionsParams.personal === 0 ? 'active' : '']" @click="userCollectionsStore.togglePersonalCollections(0)">Created by team</button>
      </template>
    </TopPanel>
    <EmptyGrid v-if="userCollectionsStore.count === 0" @create-new="userCollectionsStore.createModalToggle()">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 33">
          <path d="M5.334 5.084c-1.142 0-2.084.94-2.084 2.082v18.668c0 1.142.942 2.082 2.084 2.082h21.332c1.142 0 2.084-.94 2.084-2.082v-16a2.096 2.096 0 0 0-2.084-2.084H16.312l-2.058-2.057c-.39-.39-.92-.609-1.473-.609H5.334zm0 1.5h7.447a.58.58 0 0 1 .412.17l.996.996H4.75v-.584c0-.331.253-.582.584-.582zM4.75 9.25h21.916c.331 0 .584.253.584.584v16a.572.572 0 0 1-.584.582H5.334a.572.572 0 0 1-.584-.582V9.25zm10.584 3.834a4.094 4.094 0 0 0-4.084 4.082 4.096 4.096 0 0 0 4.084 4.084c.853 0 1.648-.265 2.305-.717l1.83 1.83a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-1.832-1.832c.452-.658.719-1.451.719-2.305a4.092 4.092 0 0 0-4.082-4.082zm0 1.5a2.57 2.57 0 0 1 2.582 2.582 2.571 2.571 0 0 1-2.582 2.584 2.573 2.573 0 0 1-2.584-2.584 2.571 2.571 0 0 1 2.584-2.582z"/>
        </svg>
      </template>
      <template v-slot:title>You haven't created any collections yet</template>
      <template v-slot:text>Here you can create your new collections and come up with names for them. All your collections will be available to your team members.</template>
      <template v-slot:button-text>Create your first collection</template>
    </EmptyGrid>
    <template v-if="userCollectionsStore.count > 0">
      <ElementsGrid>
        <OneCollection
          v-for="collection in userCollectionsStore.list"
          :key="collection.id"
          :collection="collection"
          @edit="editModal"
          @delete="delModal"
        />
      </ElementsGrid>
      <PaginationComponent
        :limit="userCollectionsStore.collectionsParams.limit"
        :offset="userCollectionsStore.collectionsParams.offset"
        :total="userCollectionsStore.count"
        @setOffset="setOffset"
      />
    </template>
    <ModalComponent @close="userCollectionsStore.createModalToggle()" :show="userCollectionsStore.createModal" >
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 32 33">
          <path d="M5.334 5.084c-1.142 0-2.084.94-2.084 2.082v18.668c0 1.142.942 2.082 2.084 2.082h21.332c1.142 0 2.084-.94 2.084-2.082v-16a2.096 2.096 0 0 0-2.084-2.084H16.311l-2.057-2.057c-.39-.39-.92-.609-1.473-.609H5.334zm0 1.5h7.447a.58.58 0 0 1 .412.17l.996.996H4.75v-.584c0-.331.253-.582.584-.582zM4.75 9.25h21.916c.331 0 .584.253.584.584v16a.572.572 0 0 1-.584.582H5.334a.572.572 0 0 1-.584-.582V9.25zM16 13.084a.75.75 0 0 0-.75.75v3.25H12a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h3.25v3.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-3.25H20a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-3.25v-3.25a.75.75 0 0 0-.75-.75z" color="#000" style="-inkscape-stroke:none"/>
        </svg>
      </template>
      <template v-slot:title>New collection</template>
      <template v-slot:description>Name the new collection</template>
      <template v-slot:extra>
        <label class="new-collection-field">
          <span>Collection Name</span>
          <input type="text" placeholder="" v-model="userCollectionsStore.collectionName">
        </label>
      </template>
      <template v-slot:buttons>
        <ButtonSecondary class="small" @click.native="userCollectionsStore.createModalToggle()">Cancel</ButtonSecondary>
        <ButtonPrimary
          class="small"
          :disabled="userCollectionsStore.collectionName?.length === 0"
          @click.native="userCollectionsStore.create()"
        >
          Create
        </ButtonPrimary>
      </template>
    </ModalComponent>
    <ModalComponent @close="userCollectionsStore.renameModalToggle()" :show="userCollectionsStore.renameModal" >
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 32 32">
          <path d="M21.334 5.14a2.08 2.08 0 0 0-1.475.606l-4.39 4.39L4.803 20.804a.75.75 0 0 0-.219.531v5.332a.75.75 0 0 0 .75.75h5.332a.75.75 0 0 0 .531-.219L21.863 16.53l4.39-4.388a2.096 2.096 0 0 0 0-2.948l-3.446-3.447a2.076 2.076 0 0 0-1.473-.605zm0 1.49c.148 0 .295.06.412.177l3.447 3.447a.574.574 0 0 1 0 .826l-3.859 3.86-4.271-4.272 3.859-3.861a.581.581 0 0 1 .412-.176zM16 11.73 20.273 16l-9.918 9.916H6.084v-4.271L16 11.729z"/>
        </svg>
      </template>
      <template v-slot:title>Rename collection</template>
      <template v-slot:extra>
        <label class="edit-collection-field">
          <span>Collection Name</span>
          <input type="text" placeholder="" v-model="userCollectionsStore.collectionName">
        </label>
      </template>
      <template v-slot:buttons>
        <ButtonSecondary class="small" @click.native="userCollectionsStore.renameModalToggle()">Cancel</ButtonSecondary>
        <ButtonPrimary
          class="small"
          :disabled="userCollectionsStore.collectionName?.length === 0"
          @click.native="userCollectionsStore.updateCollection()"
        >
          Rename
        </ButtonPrimary>
      </template>
    </ModalComponent>
    <ModalComponent @close="userCollectionsStore.delCollectionModalToggle()" type="error" :show="userCollectionsStore.delCollectionModal" >
      <template v-slot:title>Delete collection</template>
      <template v-slot:description>Are you sure? You will lose the designs that are in it</template>
      <template v-slot:buttons>
        <ButtonSecondary class="small" @click.native="userCollectionsStore.delCollectionModalToggle()">Cancel</ButtonSecondary>
        <ButtonPrimary class="small" @click.native="userCollectionsStore.deleteCollection()">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 21 20">
              <path d="M8 1.75c-.866 0-1.584.718-1.584 1.584v.916H3.834a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h.916v10.916c0 .866.718 1.584 1.584 1.584h8.332c.866 0 1.584-.718 1.584-1.584V5.75h.916a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-2.582v-.916c0-.866-.718-1.584-1.584-1.584H8zm0 1.5h5c.055 0 .084.03.084.084v.916H7.916v-.916c0-.055.03-.084.084-.084zm-1.75 2.5h8.5v10.916c0 .055-.03.084-.084.084H6.334c-.055 0-.084-.03-.084-.084V5.75z" color="#000" style="-inkscape-stroke:none"/>
            </svg>
          </template>
          Delete
        </ButtonPrimary>
      </template>
    </ModalComponent>
    <ExpiredDemoMode />
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { mapGetters } from 'vuex'
import { useUserCollectionsStore } from '@/store/pinia/userCollections'

import ButtonIcon from '@/components/Atoms/ButtonIcon.vue'
import TopPanel from '@/components/UserCollections/TopPanel.vue'
import EmptyGrid from '@/components/Atoms/EmptyGrid.vue'
import ElementsGrid from '@/components/Atoms/ElementsGrid.vue'
import OneCollection from '@/components/UserCollections/OneCollection.vue'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import PaginationComponent from '@/components/Atoms/PaginationComponentLimit.vue'
import ExpiredDemoMode from '@/components/ExpiredDemoMode.vue'

export default {
  name: 'UserCollections',
  components: {
    ExpiredDemoMode,
    ButtonIcon,
    TopPanel,
    EmptyGrid,
    ElementsGrid,
    OneCollection,
    ModalComponent,
    ButtonPrimary,
    ButtonSecondary,
    PaginationComponent,
  },
  computed: {
    ...mapStores(useUserCollectionsStore),
    ...mapGetters('user', ['subscriptionPlan']),
  },
  data: () => ({
    sortOptions: [
      { name: 'Sort by', value: '' },
      { name: 'Name', value: 'title' },
      { name: 'Last edited', value: 'updatedAt' },
      { name: 'Number of designs', value: 'designsCount' },
    ],
  }),
  methods: {
    delModal (payload) {
      this.userCollectionsStore.delCollectionModalToggle()
      this.userCollectionsStore.collectionId = payload.id
    },
    editModal (payload) {
      this.userCollectionsStore.renameModalToggle()
      this.userCollectionsStore.collectionId = payload.id
      this.userCollectionsStore.collectionName = payload.name
    },
    async setOffset (offset) {
      this.userCollectionsStore.collectionsParams.offset = offset
      await this.userCollectionsStore.getList()
    },
  },
  async beforeMount () {
    await this.userCollectionsStore.getList()
  },
}
</script>

<style lang="scss">
.user-collections {
  padding: 0 16px 40px 40px;

  h1 {
    color: var(--text_color);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      display: none;
    }
  }
}

.new-collection-field, .edit-collection-field {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 8px;

  span {
    color: var(--Text-Text_Secondary);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  input {
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: var(--Background);
    padding: 10px 12px;

    color: var(--Text-Text_Primary);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

@media (max-width: 760px) {
  .user-collections {
    padding: 0 0 40px 0 !important;

    h1 {
      font-size: 24px !important;
      button {
        display: block;
      }
    }
  }
}
</style>
