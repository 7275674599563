<template>
  <div class="privacy">
    <TheHeader :scrollY="0" />
    <section>
      <div class="container">
        <h1>Privacy Policy</h1>
        <p>Last Updated: February 12, 2025</p>
        <h3>Introduction</h3>
        <p>PatternFast.com (the "Service") is owned and operated by PatternFast, Inc. ("PatternFast," "we," "us," or "our"), a legally registered entity and the data controller for your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Software as a Service (SaaS) platform.</p>
        <h4>Contact Information:</h4>
        <ul>
          <li>Email: <a href= "mailto:info@patternfast.com">info@patternfast.com</a></li>
          <li>Phone: <a href="tel:8888833840">(888) 883-3840</a></li>
          <li>Address: 1900 South Norfolk Street Suite 350, Office 357, San Mateo, CA 94403</li>
        </ul>
        <h3>Scope and Consent</h3>
        <p>By accessing or using PatternFast's services, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your information as described herein. If you do not agree with our policies and practices, please do not use our Service.</p>
        <h3>Information We Collect</h3>
        <h4>Automatically Collected Information</h4>
        <p>We automatically collect certain information when you visit, use, or navigate our Service:</p>
        <ul>
          <li>IP address and location data</li>
          <li>Device information and hardware specifications</li>
          <li>Browser type and settings</li>
          <li>Interaction data (clicked links and viewed content)</li>
          <li>Usage patterns and preferences</li>
        </ul>
        <h4>Information You Provide</h4>
        <p>We collect the following information when you actively provide it through our Service:</p>
        <ul>
          <li>Personal identification (name, date of birth, gender)</li>
          <li>Contact information (email address, phone number, physical address)</li>
          <li>Account credentials</li>
          <li>Payment information</li>
          <li>Body measurements and physical characteristics (height, weight, body measurements)</li>
          <li>Physical attributes (eye color, hair color, skin color)</li>
          <li>Auto-fill data and preferences</li>
        </ul>
        <h3>How We Use Your Information</h3>
        <p>We use your information for the following purposes:</p>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To process and fulfill your requests for personalized, made-to-measure apparel patterns</li>
          <li>To communicate with you about your account or our Services</li>
          <li>To improve and optimize our Service</li>
          <li>To protect our legal rights and comply with applicable laws</li>
          <li>To detect and prevent fraud or other malicious activities</li>
        </ul>
        <h3>Data Sharing and Disclosure</h3>
        <h4>Within PatternFast</h4>
        <p>We share your information with employees and team members who need access to provide our Services and support your needs.</p>
        <h4>Third-Party Disclosure</h4>
        <p>We do not sell your personal information. We may share your information in the following circumstances:</p>
        <ul>
          <li>When required by law or legal process</li>
          <li>To protect our legal rights or comply with regulatory requirements</li>
          <li>In connection with a business transaction (e.g., merger, acquisition, or sale)</li>
          <li>With service providers who assist in operating our Service, subject to strict confidentiality obligations</li>
        </ul>
        <h3>International Data Transfers</h3>
        <p>We process and store information in the United States, Canada, the European Union, and other countries where our partners operate. By using our Service, you consent to the transfer of your information across national borders. We implement appropriate safeguards, including standard contractual clauses and adequacy decisions, to protect your data during international transfers.</p>
        <h3>Data Security</h3>
        <p>We implement industry-standard security measures to protect your information:</p>
        <ul>
          <li>Strong browser encryption</li>
          <li>Secure server facilities</li>
          <li>Strict employee confidentiality agreements</li>
          <li>Regular security assessments and updates</li>
        </ul>
        <p>While we take reasonable precautions to protect your data, no method of transmission over the internet is 100% secure. We cannot guarantee absolute security of your information.</p>
        <h3>Your Rights Under GDPR</h3>
        <p>For users in the European Union and United Kingdom, we comply with GDPR requirements. You have the following rights:</p>
        <ul>
          <li>Right to be informed about our data practices</li>
          <li>Right to access your personal data</li>
          <li>Right to correct inaccurate or incomplete data</li>
          <li>Right to request deletion of your data</li>
          <li>Right to restrict or object to data processing</li>
          <li>Right to data portability</li>
          <li>Right to withdraw consent</li>
        </ul>
        <h3>Data Retention</h3>
        <p>We retain your personal information for as long as necessary to provide our Services and fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law. When we no longer need your information, we will securely delete or anonymize it.</p>
        <h3>Changes to This Policy</h3>
        <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website and updating the "Last Updated" date. Your continued use of the Service after such modifications constitutes your acknowledgment of the modified Privacy Policy.</p>
        <h3>Contact Us</h3>
        <p>For questions, concerns, or requests related to your privacy rights, please contact our privacy team at: <a href= "mailto:privacy@patternfast.com">privacy@patternfast.com</a></p>
        <p>Please provide sufficient information to identify your account and describe your request with as much detail as possible.</p>
      </div>
    </section>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/Home/TheHeader.vue'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    TheHeader,
    TheFooter,
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
.privacy {
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    align-items: baseline;
    padding: 132px 80px 154px 80px;

    & > h4 {
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin: 0;
      margin-bottom: 5px;
      margin-top: 16px;
    }

    & > h2 {
      color: var(--Text-Text_Secondary);
      width: 100%;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.18px;
      margin: 0;
      margin-bottom: 10px;
    }

    & > h1 {
      color: var(--Text-Text_Primary);
      width: 100%;
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.5px;
      margin: 0;
      margin-bottom: 25px;
    }

    & > h3 {
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin: 0;
      margin-bottom: 5px;
      margin-top: 16px;
    }

    & > p {
      width: 100%;
      margin: 0;
      margin-bottom: 8px;
    }

    & > ul {
      padding-left: 13px;
      margin-bottom: 8px;
    }
  }
}
@media (max-width: 480px) {
  .privacy  {
    .container {
      padding: 122px 20px 63px 20px;

      & > h1 {
        font-size: 30px;
      }
      & > h3 {
        font-size: 18px;
      }
      & > h4 {
        font-size: 14px;
      }
    }
  }
}
</style>
