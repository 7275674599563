<template>
  <div class="fit-chart" v-if="fitChartsStore.previewChart">
    <button class="back-button" @click="$router.push({ name: 'fitChartsCustom' })">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 20 20">
        <path d="M11.969 3.637 6.137 9.47a.75.75 0 0 0 0 1.06l5.832 5.832a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L7.727 10l5.302-5.303a.75.75 0 0 0 0-1.06.75.75 0 0 0-1.06 0z"></path>
      </svg>
      <span>Back to fit charts</span>
    </button>
    <h1>
      <span>{{ fitChartsStore.previewChart.name }}</span>
      <div class="buttons">
        <ButtonSecondary class="small" @click.native="$router.push({ name: 'fitChartsEdit', params: { id: $route.params.id } })">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
              <path d="M14.666 3.3c-.428 0-.855.163-1.178.485l-3.011 3.012a.75.75 0 0 0-.008.006.75.75 0 0 0-.006.008l-7.326 7.326a.75.75 0 0 0-.221.529v3.668a.75.75 0 0 0 .75.75h3.668a.75.75 0 0 0 .53-.22l7.333-7.335 3.018-3.017a1.679 1.679 0 0 0 0-2.358l-2.37-2.369a1.665 1.665 0 0 0-1.179-.484zm.002 1.493c.041 0 .081.017.117.053l2.37 2.369c.07.071.07.165 0 .236L14.667 9.94l-2.607-2.607 2.488-2.486a.168.168 0 0 1 .119-.053zM11 8.395 13.607 11l-6.584 6.584H4.416v-2.607L11 8.395z" color="#000" style="-inkscape-stroke:none"/>
            </svg>
          </template>
          Edit
        </ButtonSecondary>
        <ButtonSecondary class="small" @click.native="fitChartsStore.delChartModalToggle()">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
              <path d="M8.25 2c-.912 0-1.666.754-1.666 1.666V4.75H3.666a.75.75 0 1 0 0 1.5H4.75v12.084c0 .912.754 1.666 1.666 1.666h9.168c.912 0 1.666-.754 1.666-1.666V6.25h1.084a.75.75 0 1 0 0-1.5h-2.918V3.666c0-.912-.754-1.666-1.666-1.666Zm0 1.5h5.5c.101 0 .166.065.166.166V4.75H8.084V3.666c0-.1.065-.166.166-.166Zm-2 2.75h9.5v12.084c0 .101-.065.166-.166.166H6.416c-.1 0-.166-.065-.166-.166Z"/>
            </svg>
          </template>
          Delete
        </ButtonSecondary>
      </div>
    </h1>
    <div class="created-at" v-if="fitChartsStore.previewChart?.user_id !== userStore.data?.id">
      <span class="by">Created by</span>
      <span class="name">Courtney Fox</span>
      <div class="avatar">CF</div>
    </div>
    <div class="units-panel">
      <ButtonsGroup class="units-buttons">
        <button :class="[fitChartsStore.unit === 'in' ? 'active': '']" @click="fitChartsStore.unit = 'in'">in</button>
        <button :class="[fitChartsStore.unit === 'cm' ? 'active': '']" @click="fitChartsStore.unit = 'cm'">cm</button>
      </ButtonsGroup>
    </div>
    <div class="chart-edit-table desktop">
      <div class="table-container">
        <div class="table" v-if="fitChartsStore.previewChart !== null">
          <ChartEditColumnLabels
            :type="fitChartsStore.previewChart.type"
            :units="fitChartsStore.unit"
          />
          <ChartViewColumnSize
            v-for="mannequin in fitChartsStore.previewChart.mannequins"
            :key="mannequin._id"
            :mannequin="mannequin"
            :type="fitChartsStore.previewChart.type"
            :units="fitChartsStore.unit"
          />
        </div>
      </div>
    </div>

    <div class="chart-edit-table mobile" v-for="mannequin in fitChartsStore.previewChart.mannequins" :key="mannequin._id">
      <div class="table-container">
        <div class="table" v-if="fitChartsStore.previewChart !== null">
          <ChartEditColumnLabels
            :type="fitChartsStore.previewChart.type"
            :units="fitChartsStore.unit"
          />
          <ChartViewColumnSize
            :mannequin="mannequin"
            :type="fitChartsStore.previewChart.type"
            :units="fitChartsStore.unit"
            class="mobile-column-size"
          />
        </div>
      </div>
    </div>

    <ModalComponent @close="fitChartsStore.delChartModalToggle()" :show="fitChartsStore.delChartModal" type="error">
      <template v-slot:title>Are you sure you want to delete this size chart? </template>
      <template v-slot:description>You can not undo this action</template>
      <template v-slot:buttons>
        <ButtonSecondary @click.native="fitChartsStore.delChartModalToggle()">Cancel</ButtonSecondary>
        <ButtonPrimary @click.native="delFitChart()">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
              <path d="M8 1.75c-.866 0-1.584.718-1.584 1.584v.916H3.834a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h.916v10.916c0 .866.718 1.584 1.584 1.584h8.332c.866 0 1.584-.718 1.584-1.584V5.75h.918a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-2.586v-.916c0-.866-.716-1.584-1.582-1.584H8zm0 1.5h5c.055 0 .082.03.082.084v.916H7.916v-.916c0-.055.03-.084.084-.084zm-1.75 2.5h8.5v10.916c0 .055-.03.084-.084.084H6.334c-.055 0-.084-.03-.084-.084V5.75z"/>
            </svg>
          </template>
          Delete chart
        </ButtonPrimary>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFitChartsStore } from '@/store/pinia/fitCharts'
import { useUserStore } from '@/store/pinia/user'

import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'
import ChartEditColumnLabels from '@/components/fitCharts/ChartEditColumnLabels.vue'
import ChartViewColumnSize from '@/components/fitCharts/ChartViewColumnSize.vue'

export default {
  name: 'FitCharts',
  components: {
    ButtonSecondary,
    ButtonPrimary,
    ButtonsGroup,
    ChartEditColumnLabels,
    ChartViewColumnSize,
    ModalComponent,

  },
  computed: {
    ...mapStores(useFitChartsStore),
    ...mapStores(useUserStore),
  },
  data: () => ({
  }),
  methods: {
    async delFitChart () {
      this.fitChartsStore.chartId = this.$route.params.id
      await this.fitChartsStore.deleteChart()
      this.$router.push({ name: 'fitChartsCustom' })
    },
  },
  async beforeMount () {
    await this.fitChartsStore.getOne(this.$route.params.id)
  },
}
</script>

<style lang="scss">
.fit-chart {
  padding: 0 16px 40px 40px;

  .back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: transparent;
    margin-bottom: 16px;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--Primary-Shades-Primary);
      margin-right: 4px;
    }
    span {
      color: var(--Primary-Shades-Primary);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }

  h1 {
    color: var(--text_color);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }
  .created-at {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    .by {
      color: var(--Text-Text_Secondary);
      margin-right: 12px;
    }
    .name {
      color: var(--Text-Text_Primary);
      margin-right: 4px;
    }
    .avatar {
      width: 26px;
      height: 26px;
      line-height: 25px;
      border-radius: 26px;
      text-align: center;
      background: var(--avatar-background-shades-1);
      color: var(--Text-Text_White);
      font-weight: 600;
    }
  }

  .units-panel {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .chart-edit-table {
    margin-top: 24px;

    &.mobile {
      display: none;
    }
  }
}

@media (max-width: 760px) {
  .fit-chart {
    padding: 0 0 40px 0 !important;
    position: relative;

    .back-button {
      margin-bottom: 28px;
    }

    h1 {
      font-size: 24px !important;
      flex-direction: column;

      span {
        margin-bottom: 50px;
      }

      .buttons {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
      }

      button {
        display: block;
      }
    }

    .created-at {
      position: absolute;
      transform: translateY(-105px);
      width: 100%;
      justify-content: center;
    }

    .buttons-group.units-buttons {
      width: 100%;
    }

    .chart-edit-table.desktop {
      display: none;
    }

    .chart-edit-table.mobile {
      display: block;

      .table-container {
        margin-bottom: 16px;
        .table {
          .column.labels {
            width: auto;
            flex-shrink: 1;
          }
        }
      }

      .mobile-column-size {
        width: 105px !important;
        flex-shrink: 0;
      }
    }
  }
}
</style>
