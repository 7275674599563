<template>
  <div class="SidebarNodesList">
    <div
      v-for="eg in visibleNodes"
      :key="eg.code"
      class="SidebarNodesList__item"
      :class="{active: eg.code === activeCode}"
      :disabled="disabled"
      @click="selectItem(eg.code)"
    >
      <div v-if="eg.image" v-html="eg.image" class="SidebarNodesList__imageContainer"></div>
      <span class="SidebarNodesList__itemTitle">
        {{ eg.groupName }}
      </span>
    </div>
  </div>
  <!-- <q-list class="SidebarNodesList">
    <q-item
      v-for="eg in visibleNodes"
      :key="eg.code"
      class="SidebarNodesList__item relative-position"
      :class="{active: eg.code === activeCode}"
      v-ripple="!disabled"
      :clickable="!disabled"
      :disabled="disabled"
      @click="$emit('select', eg.code)"
    >
      <div v-if="eg.image" v-html="eg.image" class="SidebarNodesList__imageContainer"></div>
      <span class="SidebarNodesList__itemTitle">
        {{ eg.groupName }}
      </span>
    </q-item>
  </q-list> -->
</template>

<script>
import { hasProperties } from '@/helpers'

export default {
  name: 'SidebarNodesList',
  props: {
    nodes: {
      type: Array,
      required: true,
      validator: elems => elems.every(item => hasProperties(item, ['code', 'groupName'])),
    },

    designAttrIds: {
      type: Array,
      required: true,
    },

    activeCode: {
      type: String,
      default: undefined,
    },

    disabled: Boolean,
  },

  computed: {
    visibleNodes () {
      // подготовим список бэксайд нод - они несамостоятельны и не должны отображаться
      let backsideList = []
      this.nodes.forEach(item => {
        if (item.backsideCode !== null) {
          backsideList.push(item.backsideCode)
        }
      })

      return this.nodes.filter(item => {
        // виртуальные никогда не отображаются
        // todo: декор скрыт на первое время
        if (item.isVirtual || item.isDecor) {
          return false
        }
        // нода относится к бэксайду и должна быть отображена внутри открывающейся панели, а не в боковом меню

        if (backsideList.includes(item.code)) {
          return false
        }

        // какой-либо необходимый атрибут не найден
        if (item.requiredAttrIds.length > 0) {
          if (!item.requiredAttrIds.every(
            attrId => this.designAttrIds.includes(attrId))) {
            return false
          }
        }

        // либо какой-то несовместимый атрибут найден
        if (item.excludedAttrIds.length > 0) {
          if (item.excludedAttrIds.some(attrId => this.designAttrIds.includes(attrId))) {
            return false
          }
        }
        return true
      })
    },
  },
  methods: {
    selectItem (code) {
      if (!this.disabled) {
        this.$emit('select', code)
      }
    },
  },
}
</script>

<style lang="scss">
  @import "src/styles/variables";

  .SidebarNodesList {

    &__item {
      position:       relative;
      display:        flex;
      flex-direction: column;
      align-items:    center;
      align-content:  center;
      width:          137px;
      padding:        12px 8px;
      text-align:     center;
      cursor:         pointer;
      border-bottom: 1px solid var(--Dividers);
      border-right: 1px solid var(--Dividers);
      &:last-child {
        border-bottom: none;
      }

      &.active {
        color: var(--Button-States-Pressed);
        background: var(--Surface);
        border-right: none;
      }
      &:disabled {
        pointer-events: none;
      }
    }

    &__imageContainer {
      width: 48px;
      height: 48px;
      background-color: #f5f6f7;
      border-radius: 50%;
    }

    &__imageContainer > img,
    &__imageContainer > svg {
      margin-top: 5px;
      height:     35px;
      stroke:     #4e5b5e;
    }

    &__item.active &__imageContainer > img,
    &__item.active &__imageContainer > svg {
      stroke: #4e5b5e;
    }

    &__itemTitle {
      font-size:      14px;
      user-select:    none;
      white-space: nowrap;
      margin-top: 4px;
    }
  }
  @media (max-width: 964px) {
    .SidebarNodesList {
      display: flex;
      &__item {
        border-bottom: none;
      }
    }
  }
</style>
