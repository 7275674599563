<template>
  <div>
    <q-dialog
      v-for="(msg, i) in messages"
      :key="i"
      :value="true"
      content-class="AlertDialog"
      @hide="removeMessage(i)"
    >
      <q-card class="bg-white">
        <q-card-section class="AlertDialog__header row no-wrap justify-between">
          <div class="modal-header">
            {{ msg.title }}
          </div>

          <q-btn
            class="AlertDialog__closeButton"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>
        <q-card-section class="AlertDialog__message">
          {{ msg.message }}
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            size="md"
            color="secondary"
            v-close-popup
          >
            Ok
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>

export default {
  name: 'AlertDialog',

  data: () => ({
    messages: [],
  }),

  methods: {
    addMessage (title, message) {
      this.messages.push({
        title,
        message,
      })
    },
    removeMessage (i) {
      this.$delete(this.messages, i)
    },
  },
}
</script>

<style lang="scss">
  .AlertDialog {
    .q-card {
      width:     auto;
      min-width: 300px;
    }

    &__closeButton {
      margin-left: 10px;
    }

    &__message {
      white-space: pre-wrap;
    }
  }
</style>
