<template>
  <div class="one-plan">
    <div>
      <div class="current-badge">Current plan</div>
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
      <div class="price">
        <span v-if="oldPrice" class="old">${{ oldPrice }}</span>
        <span>{{oldPrice ? ' ' : ''}}${{ price }}</span> / month
      </div>
      <ul>
        <li v-for="(a, i) in scope" :key="i" :class="[a.isAvailable ? 'yes' : 'no']">{{ a.text }}</li>
      </ul>
    </div>
    <ButtonSecondary :disabled="buttonDisabled" class="small" @click.native="$emit('action')">{{ buttonText }}</ButtonSecondary>
  </div>
</template>

<script>
import ButtonSecondary from '@/components/Atoms/ButtonSecondary'

export default {
  name: 'OnePlan',
  components: {
    ButtonSecondary,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    oldPrice: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    scope: {
      type: Array,
      default: () => [],
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.one-plan {
  width: 100%;
  max-width: 400px;
  padding: 25px;
  border-radius: 20px;
  border: 1px solid var(--Dividers);
  background: var(--Background);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: background ease-in-out 300ms;

  &:hover {
    background: var(--Surface);

    button {
      background: var(--Primary-Shades-Primary);
      color: var(--Primary-Button-Text);
    }
  }

  &.current {
    border-color: var(--Active-State-Strokes);
    background: var(--Active-States-Surfaces);

    .current-badge {
      display: block;
    }
  }

  &.custom {
    align-self: baseline;
    .description {
      margin: 73px 0 40px 0;
    }
  }

  .current-badge {
    background: var(--Active-State-Strokes);
    border-radius: 24px;
    padding: 4px 16px;
    position: absolute;
    top: 0;
    left: 24px;
    transform: translateY(-50%);
    display: none;

    color: var(--Background);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  h3 {
    color: var(--Text-Text_Primary);
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    margin-bottom: 5px;
  }
  p {
    color: #385052;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 20px;
  }

  .price {
    color: var(--Text-Text_Secondary);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;

    .old {
      font-size: 20px;
      color: #6F8789;
      font-style: normal;
      text-decoration: line-through;
    }

    &.custom {
      color: var(--Text-Text_Primary);
      font-size: 18px;
      font-weight: 600;
      line-height: 150%;
    }

    span {
      color: var(--Text-Text_Primary);
      font-size: 30px;
      font-weight: 600;
      letter-spacing: -0.9px;
    }
  }

  ul {
    border-top: 1px solid var(--Dividers);
    margin-bottom: 30px;
    flex-grow: 1;

    li {
      margin-top: 15px;
      list-style: none;
      padding-left: 32px;

      background-position: left center;
      background-repeat: no-repeat;

      &.yes {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADASURBVHgB7ZNBDoIwEEVnBA16BYTgzhMoCzV6I28AN1N27Iw3QIR9lyagtTUxaUyJ1LKQpG/VZpr303YGwGD4eyzoACfcBkPXP9nelNTF9SzWBqAJlyN9HAAo292TzzqCBqKcYrW7pWnWWUAbOUf6ROPlej9ZbGLQlDcGcCjSSBaiIudIu6gu89T2fL6MR26AVXk5/iJvDHiFFHkihljeLFOVc75+shOuIqQYMy9hp4mKHKDFoL1vgoBzVbnB0BOeSy5vpwAuBxEAAAAASUVORK5CYII=)
      }

      &.no {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADHSURBVHgB7ZRBDoMgEEVnSDdt0/QWorfjBuIJmt7OcIomlXTHdGjKDhHQnb6ExMTvfJk/AHCwT6auU++21SX6SUoVeyeiXzgHgqjPMbGsQeceSHSHEj5N01spKWXii3uNZS3UkDJZXTxlUlIcIQNv4hA1r+EXGufDS1+NGWArwk5K2yJyhU4IjD0vccoR+Z6Htvz/SHMmcBtHDWuJBZozwtXFNzPJGcVqk5I5DyZzd1E0ZCJ6cZjqYswTFjjzWWATIESCg33yBTDnkVqdtxcHAAAAAElFTkSuQmCC)
      }

    }
  }
  .btn {
    width: 100%;
  }
}

</style>
