<template>
  <component-wrapper-base
    v-if="!$route.query.onlySketch"
    class="TechnicalSketch"
    title="Flat Sketch"
    :content-class="{'relative-position': true}"
  >
    <template slot="menu">
      <q-btn
        flat
        size="sm"
        v-if="false"
      >
        <q-menu auto-close>
          <q-list>
            <q-item clickable>
              <q-item-section
                @click="currentView = 'both'"
              >
                Both
              </q-item-section>
            </q-item>

            <q-item clickable>
              <q-item-section
                @click="currentView = 'front'"
              >
                Front
              </q-item-section>
            </q-item>

            <q-item clickable>
              <q-item-section
                @click="currentView = 'back'"
              >
                Back
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <div class="download-block">
        <div class="download-block__button" @click="isShowDownloadMenu = !isShowDownloadMenu">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20">
            <path fill="#09373D" d="M15 11.6c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-1.7s-.1.1 0 0c-.1.2.1.2 0 0 .1.1 0 0 0 0zm-5 1.7c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-1.7s-.1.1 0 0c-.1.2.1.2 0 0 .1.1 0 0 0 0zm-5 1.7c-.9 0-1.6-.7-1.6-1.6S4.1 8.4 5 8.4s1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-1.7s-.1.1 0 0c-.1.2.1.2 0 0 .1.1 0 0 0 0z"/>
          </svg>
        </div>
        <div
          v-if="isShowDownloadMenu"
          class="download-block__items"
          v-click-outside="() => isShowDownloadMenu = false"
        >
          <div
            :class="`download-block__items__item ${demoMode ? 'disable' : ''}`"
            @click="demoMode ? null : isShowDownloadModal = true"
          >
            <div class="tooltiptext" v-if="demoMode">Upgrade your plan<br>to download flat sketch</div>
            Download flat sketch
          </div>
        </div>
      </div>
    </template>
    <modal-component
      class="sketch-download-modal"
      :show="isShowDownloadModal"
      :showIcon="false"
      @close="isShowDownloadModal = false"
    >
      <template v-slot:extra>
        <div class="select-format">
          <h3>Download flat sketch</h3>
          <div class="select-block-radio">
            <div
              :class="`radio-item ${selectedFormat === 'svg' ? 'active' : ''}`"
              @click="selectedFormat = 'svg'"
            >
              SVG
            </div>
            <div
              :class="`radio-item ${selectedFormat === 'png' ? 'active' : ''}`"
              @click="selectedFormat = 'png'"
            >
              PNG
            </div>
          </div>
        </div>
        <div
          :class="`radio-button-block ${selectedView === 'both' ? 'active' : ''}`"
          @click="selectedView = 'both'"
        >
          <div class="radio-button-item" />
          <label>Front and Back views</label>
        </div>
        <div
          :class="`radio-button-block ${selectedView === 'front' ? 'active' : ''}`"
          @click="selectedView = 'front'"
        >
          <div class="radio-button-item" />
          <label>Front only</label>
        </div>
        <div
          :class="`radio-button-block ${selectedView === 'back' ? 'active' : ''}`"
          @click="selectedView = 'back'"
        >
          <div class="radio-button-item" />
          <label>Back only</label>
        </div>
      </template>
      <template v-slot:buttons>
        <ButtonPrimary class="small one" @click.native="downloadSketch">
          Download
        </ButtonPrimary>
      </template>
    </modal-component>
    <template slot="top">
      <slot name="top"></slot>
    </template>
    <div
      v-if="isLoading"
      class="TechnicalSketch__loader row justify-center"
    >
      <q-spinner-dots
        class="full-height col-2"
        color="primary"
      />
    </div>
    <sketch-image
      v-if="!isLoading && elementIds && elementIds.length"
      :design-options="designOptions"
      :elements="sketchElements"
      :desc-items="designDetailsDescItems"
      :view="view || currentView"
      :color="color"
      :fabric-image="fabricImage"
      :ease="ease"
      @change="handleSketchChange"
    />
    <template slot="bottom">
      <sketch-description
        :desc-items="designDetailsDescItems"
        @click="$emit('node-selected', $event)"
      />
    </template>
  </component-wrapper-base>
  <div v-else>
    <sketch-image
      v-if="!isLoading && elementIds && elementIds.length"
      :design-options="designOptions"
      :elements="sketchElements"
      :desc-items="designDetailsDescItems"
      :view="view"
      :color="color"
      :fabric-image="fabricImage"
      :ease="ease"
      @change="handleSketchChange"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import ComponentWrapperBase from '@/components/ComponentWrapperBase'
import { technicalSketchOptions } from '@/components/demoData'
import SketchImage from '@/components/Sketch/SketchImage.vue'
import SketchDescription from '@/components/Sketch/SketchDescription'
import { designOptionsValidator } from '@/pages/designerConstructor'
import ModalComponent from '@/components/Atoms/ModalComponent'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import { calculateEdgePoints } from '@/helpers/svg'
import { mapGetters } from 'vuex'

export default {
  name: 'TechnicalSketchContainer',

  components: {
    SketchDescription,
    ComponentWrapperBase,
    SketchImage,
    ModalComponent,
    ButtonPrimary,
  },

  props: {
    nodes: {
      type: Array,
      required: true,
    },
    designElements: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: undefined,
    },
    fabricImage: {
      type: String,
      default: undefined,
    },
    isLoading: Boolean,
    designOptions: {
      type: Object,
      required: true,
      validator: val => designOptionsValidator(val),
    },
    ease: {
      type: Object,
      default: () => {},
    },
    designDetailsDescItems: {
      type: Array,
      default: () => [],
    },
    view: {
      type: String,
    },
  },

  data: () => ({
    currentView: 'both',
    isShowDownloadMenu: false,
    isShowDownloadModal: false,
    selectedFormat: 'svg',
    selectedView: 'both',
    sketch: null,
  }),

  computed: {
    ...mapGetters('user', ['demoMode']),
    elementIds () {
      return this.designOptions.elementIds
    },
    designCategory () {
      return this.designOptions.designCategory
    },
    sketchElements () {
      return Object.keys(this.designElements).reduce((acc, nodeCode) => {
        let elements = this.designElements[nodeCode].map(item => ({
          attributes: item.attributes,
          params: item.params,
          svgFront: item.svgFront,
          svgBack: item.svgBack,
          nodeCode,
        }))
        return [...acc, ...elements]
      }, [])
    },

    sketchOptions () {
      return technicalSketchOptions
    },
  },

  methods: {
    filename (name, ext) {
      let filename = name
      let now = new Date()
      filename += '-' + [now.getFullYear(), now.getMonth() + 1, now.getDate()]
        .map(val => val.toString()
          .replace(/^([0-9])$/, '0$1'))
        .join('-')
      filename += '-' + [now.getHours(), now.getMinutes(), now.getSeconds()].join('') + ext
      return filename
    },
    async downloadSketch () {
      if (this.selectedFormat === 'png') {
        let sketch = await this.makeSketch(document.getElementById('both')?.outerHTML, this.selectedView)
        saveAs(sketch, this.filename('Technical-Sketch', '.png'))
      }
      if (this.selectedFormat === 'svg') {
        const svg = new DOMParser().parseFromString(this.sketch[this.selectedView], "text/html").getElementsByTagName('svg')[0]
        if (this.selectedView !== 'both') {
          const edgePoints1 = calculateEdgePoints(this.sketch[this.selectedView])
          const width = edgePoints1.x1 - edgePoints1.x
          const height = edgePoints1.y1 - edgePoints1.y
          svg.setAttribute('viewBox', `${edgePoints1?.x - 5} ${edgePoints1?.y - 5} ${width + 10} ${height + 10}`)
          console.log('svg', svg)
        }
        const blob = new Blob([svg.outerHTML], { type: 'application/svg' })
        saveAs(blob, this.filename('Technical-Sketch', '.svg'))
      }
      this.isShowDownloadModal = false
    },
    makeSketch (bothSvg, svgSide) {
      return new Promise((resolve, reject) => {
        const length = 1800
        const edgePoints = calculateEdgePoints(bothSvg)
        const widthImg = edgePoints.x1 - edgePoints.x
        const heightImg = edgePoints.y1 - edgePoints.y

        const svgPreload = (side, scale) => {
          const svg = new DOMParser().parseFromString(bothSvg, "text/html").getElementsByTagName('svg')[0]
          let gBack = svg.getElementsByClassName('back-view')[0]
          let gFront = svg.getElementsByClassName('front-view')[0]
          gBack.removeAttribute("transform")
          svg.removeChild(side === 'Back' ? gBack : gFront)
          svg.removeAttribute("style")
          svg.setAttribute('height', length / 1.2 * scale)
          svg.setAttribute('width', length / 2 * scale)
          svg.setAttribute('viewBox', `${edgePoints.x - widthImg * 0.15 / 2} ${edgePoints.y - heightImg * 0.15 / 2} ${widthImg + widthImg * 0.15} ${heightImg + heightImg * 0.15}`)
          // console.log('svg.outerHTML', svg.outerHTML)
          const blob = new Blob([svg.outerHTML], { type: 'image/svg+xml' })
          return blob
        }
        let blobBack = null
        if (svgSide === 'back') blobBack = svgPreload('Front', 1)
        else blobBack = svgPreload('Front', 0.7)
        const blobFront = svgPreload('Back', 1)
        const win = window.URL || window.webkitURL || window
        const urlFront = win.createObjectURL(blobFront)
        const urlBack = win.createObjectURL(blobBack)
        const canvas = document.createElement("canvas")
        document.body.appendChild(canvas)
        canvas.style = 'display:none'
        const ctx = canvas.getContext('2d')

        if (svgSide === 'both') {
          const imgFront = new Image()
          const imgBack = new Image()
          imgFront.src = urlFront
          imgBack.src = urlBack
          canvas.width = length
          canvas.height = length
          ctx.fillStyle = "white"
          ctx.fillRect(0, 0, length, length)
          imgFront.onload = () => {
            imgBack.onload = () => {
              if (widthImg < heightImg) {
                ctx.drawImage(imgFront, (length - (imgFront.width + imgBack.width + 100)) / 2, 0)
                ctx.drawImage(imgBack, imgFront.width + 100 + ((length - (imgFront.width + imgBack.width + 100)) / 2), length - imgBack.height)
              } else {
                ctx.drawImage(imgFront, 0, (length - (imgFront.height / 2 + imgBack.height)) / 2)
                ctx.drawImage(imgBack, length - imgBack.width, (length - (imgFront.height / 2 + imgBack.height)) / 2 + imgFront.height / 2)
              }
              win.revokeObjectURL(urlFront)
              win.revokeObjectURL(urlBack)
              const result = canvas.toDataURL('image/png')
              document.body.removeChild(canvas)
              resolve(result)
            }
          }
          imgFront.onerror = reject
          imgBack.onerror = reject
        } else {
          let image = new Image()
          if (svgSide === 'front') {
            image.src = urlFront
          } else {
            image.src = urlBack
          }
          image.onload = () => {
            canvas.width = image.width
            canvas.height = image.height
            ctx.drawImage(image, 0, 0)

            let minX = image.width
            let minY = image.height
            let maxX = 0
            let maxY = 0
            const imageData = ctx.getImageData(0, 0, image.width, image.height).data
            for (let y = 0; y < image.height; y++) {
              for (let x = 0; x < image.width; x++) {
                const index = (y * image.width + x) * 4
                if (imageData[index + 3] > 0) {
                  if (x < minX) minX = x
                  if (x > maxX) maxX = x
                  if (y < minY) minY = y
                  if (y > maxY) maxY = y
                }
              }
            }
            const cropWidth = maxX - minX + 1
            const cropHeight = maxY - minY + 1
            const squareSize = Math.max(cropWidth, cropHeight)
            const croppedCanvas = document.createElement("canvas")
            croppedCanvas.width = squareSize
            croppedCanvas.height = squareSize
            const croppedCtx = croppedCanvas.getContext('2d')
            croppedCtx.fillStyle = "white"
            croppedCtx.fillRect(0, 0, squareSize, squareSize)
            croppedCtx.drawImage(canvas, minX, minY, cropWidth, cropHeight, (squareSize - cropWidth) / 2, (squareSize - cropHeight) / 2, cropWidth, cropHeight)

            win.revokeObjectURL(urlFront)
            document.body.removeChild(canvas)
            resolve(croppedCanvas.toDataURL('image/png'))
          }
          image.onerror = reject
        }
      })
    },
    handleSketchChange (svg) {
      this.sketch = svg
      this.$emit('sketch-updated', svg)
    },
  },

}
</script>

<style lang="scss">
  @import "src/styles/mixins";
  .TechnicalSketch {
    > .ComponentWrapperBase__content {
      display: flex;
    }
    .ComponentWrapperBase__header {
      position: relative;
    }
    .download-block {
      position: relative;
      &__button {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 50%;
        &:hover {
          background-color: var(--Surface);
        }
      }
      &__items {
        border-radius: 8px;
        border: 1px solid var(--Dividers);
        position: absolute;
        background: var(--Basic-White);
        box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.10);
        z-index: 1;
        font-size: 16px;
        right: 0;
        top: calc(100% + 8px);
        &__item.disable {
          color: var(--Button-States-Disabled_Txt);
          cursor: no-drop;
          &:hover {
            background: inherit;
          }
        }
        &__item {
          border-radius: 8px;
          padding: 8px 16px;
          width: max-content;
          cursor: pointer;
          position: relative;
          .tooltiptext {
            display: none;
            font-size: 12px;
            top: calc(100% + 13px);
            background-color: #09373D;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 4px 12px;
            position: absolute;
            z-index: 1;
            transform: translateX(-50%);
            width: max-content;
            left: 50%;
            transition: opacity 0.3s;
            &::after {
              content: '';
              position: absolute;
              bottom: 100%;
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent transparent #09373D transparent;
            }
          }
          &:hover {
            background: var(--Surface-Hover);
            .tooltiptext {
              display: block;
            }
          }
        }
      }
    }
    .sketch-download-modal {
      .modal-component  {
        width: 569px;
        .extra {
          max-width: 410px;
        }
        button {
          width: max-content !important;
        }
      }
      .select-format {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin-bottom: 24px;
      }
      .select-block-radio .radio-item {
        min-width: 46px;
        width: 46px;
      }
      .radio-button-block.active {
        .radio-button-item {
          border-color: #FF6770;
          &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #FF6770;
          }
        }
      }
      .radio-button-block {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--Dividers);
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        .radio-button-item {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid #E0E9EA;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        label {
          margin-left: 16px;
          font-size: 16px;
        }
      }
    }
    .ComponentWrapperBase__menu button {
      transform: rotate(90deg);
      position: absolute;
      top: 0;
      right: 0;
      font-size: 10px;
    }

    @include loader();
  }
</style>
