import { hasProperties } from '@/helpers'

const EDIT_MODES = {
  NONE: 0,
  ANY: 1,
  BASIC: 2,
  ALL: 3,
}

const getEditModeByVal = function (value) {
  if (typeof value === 'string') {
    value = value.toLowerCase()
  }
  switch (value) {
    case 0:
    case '0':
    case false:
    case 'none':
      return EDIT_MODES.NONE
    case 1:
    case '1':
    case true:
    case 'any':
      return EDIT_MODES.ANY
    case 2:
    case '2':
    case 'basic':
      return EDIT_MODES.BASIC
    case 3:
    case '3':
    case 'all':
      return EDIT_MODES.ALL
    default:
      throw Error('Unknown edit mode type')
  }
}

class FitParameter {
  id
  name
  minValue
  maxValue
  defaultValue
  isAdvanced
  value
  units
  mode
  selectOptions

  constructor (
    id, name, minValue, maxValue, defaultValue, isAdvanced, value, units = 'cm',
    mode = 'input', selectOptions = undefined) {
    this.id = id
    this.name = name
    this.minValue = minValue
    this.maxValue = maxValue
    this.defaultValue = defaultValue
    this.isAdvanced = isAdvanced
    this.value = value
    this.units = units
    this.mode = mode
    this.selectOptions = selectOptions
  }

  isValid (value) {
    return this.minValue <= value && this.maxValue >= value
  }
}

const measureProps = Object.freeze([
  'description',
  'imageName',
  'isMain',
  'min',
  'max',
  'name',
  'uid',
])

const validateMeasurements = function (measures) {
  return Array.isArray(measures) &&
    measures.every(item => hasProperties(item, measureProps))
}

const isInputValid = function (measureItem, value) {
  if (!(measureItem.min <= value && measureItem.max >= value)) {
    console.log({ item: measureItem, value })
  }
  return measureItem.min <= value && measureItem.max >= value
}

export {
  EDIT_MODES,
  getEditModeByVal,
  FitParameter,
  validateMeasurements,
  isInputValid,
}
