<template>
  <div class="one-custom-fit-chart" :class="[selected ? 'selected' : '']" @click="$router.push({ name: 'fitChart', params: { id: fitChart._id } })">
    <div class="top-panel">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
        <path d="M6.668 4.584c-1.142 0-2.084.94-2.084 2.082v18.668c0 1.142.942 2.082 2.084 2.082h18.666c1.142 0 2.084-.94 2.084-2.082V6.666a2.094 2.094 0 0 0-2.084-2.082zm0 1.5h8.582v5.166H6.084V6.666c0-.331.253-.582.584-.582zm10.082 0h8.584c.331 0 .584.25.584.582v4.584H16.75ZM6.084 12.75h9.166v6.5H6.084Zm10.666 0h9.168v6.5H16.75Zm-10.666 8h9.166v5.166H6.668a.572.572 0 0 1-.584-.582zm10.666 0h9.168v4.584a.572.572 0 0 1-.584.582H16.75Z"/>
      </svg>
      <button class="edit-menu-button" @click.stop="selected = !selected">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 25 24">
          <path d="M12.4 4.25c-.957 0-1.75.792-1.75 1.75s.793 1.75 1.75 1.75c.958 0 1.75-.792 1.75-1.75s-.792-1.75-1.75-1.75zm0 6c-.957 0-1.75.792-1.75 1.75s.793 1.75 1.75 1.75c.958 0 1.75-.792 1.75-1.75s-.792-1.75-1.75-1.75zm0 6c-.957 0-1.75.792-1.75 1.75s.793 1.75 1.75 1.75c.958 0 1.75-.792 1.75-1.75s-.792-1.75-1.75-1.75z"/>
        </svg>
      </button>
    </div>
    <h3 :title="fitChart.name">{{ title }}</h3>
    <div class="owner">
      <span class="label">by</span>
      <span class="avatar">{{ fitChart.owner.firstName.charAt(0).toUpperCase() }}{{ fitChart.owner.lastName.charAt(0).toUpperCase() }}</span>
      <span class="name">{{ fitChart.owner.firstName }} {{ fitChart.owner.lastName }}</span>
    </div>
    <div class="bottom-panel">
      <p class="count">{{ fitChart.mannequins[0].name }} - {{ fitChart.mannequins[fitChart.mannequins.length - 1].name }}</p>
      <p class="date">Size Range</p>
    </div>
    <ul class="dropdown" v-if="selected" v-click-outside="() => selected = false">
      <li @click.stop="editFitChart(fitChart)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
          <path d="M14.666 3.3c-.428 0-.855.163-1.178.485l-3.011 3.012a.75.75 0 0 0-.008.006.75.75 0 0 0-.006.008l-7.326 7.326a.75.75 0 0 0-.221.529v3.668a.75.75 0 0 0 .75.75h3.668a.75.75 0 0 0 .53-.22l7.333-7.335 3.018-3.017a1.679 1.679 0 0 0 0-2.358l-2.37-2.369a1.665 1.665 0 0 0-1.179-.484zm.002 1.493c.041 0 .081.017.117.053l2.37 2.369c.07.071.07.165 0 .236L14.667 9.94l-2.607-2.607 2.488-2.486a.168.168 0 0 1 .119-.053zM11 8.395 13.607 11l-6.584 6.584H4.416v-2.607L11 8.395z" color="#000" style="-inkscape-stroke:none"/>
        </svg>
        <span>Edit</span>
      </li>
      <li @click.stop="deleteFitChart(fitChart)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
          <path d="M8.25 2c-.912 0-1.666.754-1.666 1.666V4.75H3.666a.75.75 0 1 0 0 1.5H4.75v12.084c0 .912.754 1.666 1.666 1.666h9.168c.912 0 1.666-.754 1.666-1.666V6.25h1.084a.75.75 0 1 0 0-1.5h-2.918V3.666c0-.912-.754-1.666-1.666-1.666Zm0 1.5h5.5c.101 0 .166.065.166.166V4.75H8.084V3.666c0-.1.065-.166.166-.166Zm-2 2.75h9.5v12.084c0 .101-.065.166-.166.166H6.416c-.1 0-.166-.065-.166-.166Z"/>
        </svg>
        <span>Delete</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'OneFitChart',
  data: () => ({
    selected: false,
  }),
  props: {
    fitChart: {
      type: Object,
      requred: true,
    },
  },
  computed: {
    title () {
      const titleLength = 45
      const title = this.fitChart.name
      if (title.length <= titleLength) return title
      return title.substring(0, titleLength).trim() + '...'
    },
    edited () {
      const date = new Date(this.fitChart.updatedAt)
      const options = {
        day: 'numeric',
        year: 'numeric',
        month: 'numeric',
      }
      return date.toLocaleString("en-US", options)
    },
  },
  methods: {
    editFitChart (fitChart) {
      this.$emit('edit', fitChart)
      this.selected = !this.selected
    },
    deleteFitChart (fitChart) {
      this.$emit('delete', fitChart)
      this.selected = !this.selected
    },
  },
}
</script>

<styles lang="scss">
.one-custom-fit-chart {
  width: 200px;
  border-radius: 12px;
  border: 1px solid var(--Dividers);
  background: var(--Surface);
  padding: 16px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  row-gap: 0;
  position: relative;

  transition: all ease-in-out 300ms;

  & * { transition: all ease-in-out 300ms; }

  // To component
  .dropdown {
    width: 200px;
    position: absolute;
    top: 52px;
    right: 16px;
    z-index: 1;

    margin: 0;
    padding: 0;

    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: var(--Basic-White);
    box-shadow: 0 6px 8px 0 rgba(146, 158, 159, 0.10);

    & * { transition: all ease-in-out 300ms; }

    li {
      list-style: none;
      padding: 8px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        background: var(--Surface-Hover);

        svg {
          fill: var(--Button-States-Hover);
        }
        span {
          color: var(--Button-States-Hover);
        }
      }
    }

    svg {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      fill: var(--Icons-Icon_Primary);
    }

    span {
      color: var(--Text-Text_Primary);
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .top-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 17px;

    svg {
      width: 32px;
      height: 32px;
      fill: var(--Icons-Icons_Secondary);
    }

    button {
      border: none;
      background: transparent;
      opacity: 0;
      svg {
        width: 24px;
        height: 24px;
        fill: var(--Button-States-Hover);
      }
    }
  }

  h3 {
    color: var(--Text-Text_Primary);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    height: 60px;
    margin-bottom: 4px;
  }

  .owner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 17px;

    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .label {
      color: var(--Text-Text_Secondary);
    }

    .avatar {
      width: 26px;
      height: 26px;
      border-radius: 999px;
      overflow: hidden;
      text-align: center;
      line-height: 26px;
      color: var(--Text-Text_White);
      background-color: var(--avatar-background-shades-1);
    }

    .name {
      color: var(--Text-Text_Primary);
    }

  }

  .bottom-panel {
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .count {
      color: var(--Text-Text_Primary);
      margin: 0;
    }
    .date {
      color: var(--Text-Text_Secondary);
      margin: 0;
    }
  }

  &:hover {
    border-color: var(--Button-States-Hover);

    .top-panel {
      button {
        opacity: 1;
      }
    }

    h3 {
      color: var(--Button-States-Hover);
    }

    .bottom-panel {
      .count {
        color: var(--Button-States-Hover);
      }
    }
  }

  &.selected {
    border-color: var(--Active-State-Strokes);
    background: var(--Active-States-Surfaces);

    .top-panel {
      svg {
        fill: var(--Active-State-Strokes);
      }
    }

    h3 {
      color: var(--Active-State-Strokes);
    }

    .bottom-panel {
      .count {
        color: var(--Active-State-Strokes);
      }
    }
  }
}

@media (max-width: 760px) {
  .one-fit-chart {
    width: 164px;
    height: 181px;
    row-gap: 16px;

    // &:nth-child(odd) {}
    // &:nth-child(even) {}

    .top-panel {
      button {
        opacity: 1 !important;
      }
    }

    h3 {
      font-size: 14px;
    }
  }
}
</styles>
