const selectItems = [
  {
    uid: 'рз_belly_protuberance',
    options: [
      { label: 'A', value: 0 },
      { label: 'B', value: 0.45 },
      { label: 'C', value: 0.55 },
      { label: 'D', value: 0.7 },
      { label: 'E', value: 0.8 },
      { label: 'F', value: 0.9 }],
  },
  {
    uid: 'рз_buttocks',
    options: [
      { label: 'Very Curvy', value: 1 },
      { label: 'Curvy', value: 0.75 },
      { label: 'Average', value: 0.5 },
      { label: 'Flat', value: 0.25 },
      { label: 'Very Flat', value: 0 }],
  },
]

export { selectItems }
