<template>
  <div class="input-block unit-component" ref="block">
    <q-input
      v-if="fractionBase === 8"
      v-model="value"
      v-bind="$attrs"
      :input-class="(hasFormatError || hasRangeError) ? 'customInputError' : 'customInput'"
      outlined
      @change="inputUpdated"
    />
    <q-input
      v-if="fractionBase === 10"
      v-model="value"
      outlined
      v-bind="$attrs"
      :input-class="(hasFormatError || hasRangeError) ? 'customInputError' : 'customInput'"
      @change="inputUpdated"
    />
  </div>
</template>

<script>
import { Measurement } from '@/helpers/measurement'
import { store } from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitsInputComponent',
  store,

  props: {
    // todo: т.к. введен параметр src-unit, начальные значения следует переименовать, удалив InCm
    initialValueInCm: {
      type: Number,
      default: undefined,
    },
    minInCm: {
      type: Number,
      default: undefined,
    },
    maxInCm: {
      type: Number,
      default: undefined,
    },
    srcUnit: {
      type: String,
      required: true,
    },
    placeholderValue: {
      type: [Number, String, Boolean],
      default: false,
    },
  },

  data: () => ({
    // переменная для ввода. В единицах измерения, выбранных пользователем
    value: '',
  }),

  computed: {
    unsupportedUnits () {
      try {
        return !Measurement.checkUnit(this.srcUnit)
      } catch (e) {
        return true
      }
    },
    unitsInput () {
      return this.unsupportedUnits ? this.srcUnit : this.$store.state.units
    },
    fractionBase () {
      return this.unsupportedUnits ? 10 : this.getFractionBase
    },
    // значение, преобразованное из ввода пользователя в десятичные и см
    userInputParsed () {
      if (this.value === '' || this.value === undefined) {
        return undefined
      }
      if (this.unsupportedUnits) {
        return Number(this.value)
      }
      if (this.fractionBase === 8 && this.value !== '-') {
        // распарсить ввод, преобразовать в десятичные и в см
        const inputRegex = /^(-?)(\d{0,})\s?((1\/2)|([1-3]\/4)|([1-7]\/8))?$/
        const compare = String(this.value).match(inputRegex)
        if (compare === null) {
          return undefined
        }
        return Measurement.inToCm(Measurement.fractionsToDecimals(this.value))
      }
      return this.value
    },
    hasFormatError () {
      return !(this.value === '' || this.userInputParsed !== undefined)
    },

    placeholderValueParsed () {
      if (!this.placeholderValue) return undefined
      if (this.unsupportedUnits) {
        return Number(this.placeholderValue)
      }
      if (this.fractionBase === 8) {
        // распарсить ввод, преобразовать в десятичные и в см
        const inputRegex = /^(-?)(\d{0,})\s?((1\/2)|([1-3]\/4)|([1-7]\/8))?$/
        const compare = String(this.value).match(inputRegex)
        if (compare === null) {
          return undefined
        }
        return Measurement.inToCm(Measurement.fractionsToDecimals(this.placeholderValue))
      }
      return this.placeholderValue
    },
    hasRangeError () {
      let errorNumberMin = 0
      let errorNumberMax = 0
      if (this.unitsInput === 'in') {
        errorNumberMin = 0.15
        errorNumberMax = 0.2
      }
      if (this.userInputParsed === undefined && !this.placeholderValueParsed) {
        return false
      }
      if (this.minInCm === undefined && this.maxInCm === undefined) {
        return false
      }
      if (this.minInCm === undefined) {
        return Number(this.userInputParsed) - errorNumberMin > this.maxInCm || this.placeholderValueParsed - errorNumberMin > this.maxInCm
      }
      if (this.maxInCm === undefined) {
        return Number(this.userInputParsed) + errorNumberMax < this.minInCm || this.placeholderValueParsed + errorNumberMax < this.minInCm
      }
      return Number(this.userInputParsed) - errorNumberMin > this.maxInCm || Number(this.userInputParsed) + errorNumberMax < this.minInCm || this.placeholderValueParsed - errorNumberMin > this.maxInCm || this.placeholderValueParsed + errorNumberMax < this.minInCm
    },
    validationErrorMessage () {
      if (this.hasFormatError) {
        return 'Enter value in format: ' + (this.fractionBase === 8 ? '12 1/8 or 12 1/4 or 12 1/2' : '12.8')
      }
      if (this.hasRangeError) {
        const values = {}
        if (this.unitsInput === 'in') {
          values.min = Measurement.decimalsToFractions(Measurement.cmToIn(this.minInCm))
          values.max = Measurement.decimalsToFractions(Measurement.cmToIn(this.maxInCm))
        } else {
          values.min = this.minInCm
          values.max = this.maxInCm
        }

        return `from ${values.min} ${this.unitsInput} to ${values.max} ${this.unitsInput}`
      }
      return undefined
    },
    ...mapGetters(['getFractionBase']),
  },

  created () {
    this.setInputValue()
  },

  watch: {
    initialValueInCm (val, oldVal) {
      this.setInputValue()
    },
    unitsInput (val) {
      this.changeInputUnits()
      if (this.value && !this.initialValueInCm) {
        if (val === Measurement.CM) {
          // распарсить ввод, преобразовать в десятичные и в см
          const inputRegex = /^(-?)(\d{0,})\s?((1\/2)|([1-3]\/4)|([1-7]\/8))?$/
          const compare = String(this.value).match(inputRegex)
          if (compare === null) {
            return undefined
          }
          let isNegative = compare[1] === '-'
          let parsed = Math.abs(Number(String(compare[2])))
          if (!Number.isInteger(parsed)) {
            return undefined
          }
          const octa = Number(compare[4])
          if (Number.isInteger(octa)) {
            parsed += octa / 8
          }
          if (isNegative) {
            parsed *= -1
          }
          this.value = (new Measurement(parsed, Measurement.INCH)).getValue(val, true)
        } else {
          this.value = this.applyFractionBase(
            (new Measurement(this.value, Measurement.CM)).getValue(val, true),
            this.fractionBase,
          )
        }
      }
    },
    validationErrorMessage (val) {
      this.$emit('error', val)
    },
  },
  mounted () {
    if (this.validationErrorMessage) {
      this.$emit('error', this.validationErrorMessage)
    }
  },

  methods: {
    setInputValue () {
      if (this.initialValueInCm !== undefined) {
        if (this.unsupportedUnits || this.fractionBase === 10) {
          this.value = this.initialValueInCm
          return
        }
        this.value = Measurement.decimalsToFractions(Measurement.cmToIn(this.initialValueInCm))
      }
    },
    reset () {
      this.value = ''
    },
    changeInputUnits () {
      if (!this.value) {
        return
      }
      this.setInputValue()
    },
    applyFractionBase: (value, base = 10) => Measurement.applyFractionBase(value, base),
    inputUpdated () {
      if (this.fractionBase === 10 && this.value) {
        const hasNegativeSign = this.value.startsWith('-')
        if (hasNegativeSign && this.value.length === 1) return
        this.value = Number(
          this.value
            .replace(/,/g, '.')
            .replace(/[^0-9.-]/g, '')
            .replace(/(?!^)-/g, '')
            .replace(/(\..*?)\..*/g, '$1'))
        if (isNaN(this.value)) this.value = 0
      }
      if (Number.isFinite(this.userInputParsed)) {
        this.$emit('changed', this.userInputParsed)
        return
      }
      this.$emit('changed', undefined)
    },
  },
}
</script>

<style lang="scss">
@import "src/styles/variables";
.unit-component {
  position: relative;
  input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
  input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
  input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
  input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
  input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
  input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
  input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
  input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
  // .q-field__suffix {
  //   font-weight: bold;
  // }
  .customInput {
    // font-family: $avenir-font;
    // font-style: normal;
    // font-weight: bold;
    // color: rgba(0, 0, 0, 0.5);
    // text-align: left;
  }
  .customInputError {
    color: var(--q-color-negative);
    font-weight: bold;
  }
  .customInputError + .q-field__suffix {
    color: var(--q-color-negative);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
