<template>
  <div class="model-edit-table">
    <div class="table-container">
      <div class="table">
        <ChartEditColumnLabels
          :current-cell="fitModelsStore[fitModelsStore.dataKey].currentCell"
          :type="fitModelsStore[fitModelsStore.dataKey].type"
          :units="fitModelsStore.unit"
        />
        <ChartEditColumnSize
          v-for="(key, i) in fitModelsStore[fitModelsStore.dataKey].sizesList"
          :key="key"
          :type="fitModelsStore[fitModelsStore.dataKey].type"
          :units="fitModelsStore.unit"
          :isFirst="fitModelsStore[fitModelsStore.dataKey].sizesList.length === 1"
          :isModel="true"
          :ref="key"
          @export="fitModelsStore.setSize($event, key)"
          @remove="fitModelsStore.removeSize(i)"
          @add-new="fitModelsStore.addNewSize(i)"
          @hover="fitModelsStore.setCurrentCell"
          @leave="fitModelsStore.setCurrentCell({})"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFitModelsStore } from '../../store/pinia/fitModels'

import ChartEditColumnLabels from './ChartEditColumnLabels.vue'
import ChartEditColumnSize from './ChartEditColumnSize.vue'

export default {
  name: 'ModelEdit',
  components: {
    ChartEditColumnLabels,
    ChartEditColumnSize,
  },
  computed: mapStores(useFitModelsStore),
  beforeMount () {
    if (this.$route.params.id !== undefined) {
      this.fitModelsStore.getForEdit(this.$route.params.id, this.$refs)
    }
  },
}
</script>

<style lang="scss">
.model-edit-table {
  width: 100%;
  max-width: 684px;
  margin: 0 auto;

  &.fit-model .table-container .table .column {
    border-left: none;

    .cell .field input {
      text-align: left;
    }
  }

  .table-container {
    width: 100%;
    height: auto;
    overflow: auto;
    margin-bottom: 50px;

    color: var(--Text-Text_Primary);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    .table {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: nowrap;
      border: 1px solid var(--Dividers);
      border-radius: 12px;

      .column {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-left: 1px solid var(--Dividers);

        &:first-child {
          border-left: none;
        }

        &.labels {
          width: 483px;
          flex-shrink: 0;

          .cell.head {
            justify-content: flex-start;
          }
        }

        .cell {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          height: 56px;
          box-sizing: border-box;
          padding: 8px;
          border-top: 1px solid var(--Dividers);

          &:first-child {
            border-top: none;
          }

          &.head {
            position: relative;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            padding: 16px;
            column-gap: 10px;

            &:hover {
              .edit-menu-button {
                display: block;
              }
              .add-button {
                display: block;
              }
            }

            .add-button {
              width: 24px;
              height: 56px;
              position: absolute;
              top: 0;
              left: -12px;
              z-index: 1;
              display: none;

              .line {
                width: 1px;
                height: 56px;
                background: var(--primary);
                position: absolute;
                z-index: 1;
                top: 0;
                left: 11px;
              }

              button {
                width: 24px;
                height: 24px;
                position: absolute;
                z-index: 2;
                left: 0;
                top: 17px;
                border-radius: 8px;
                border: 1px solid var(--primary);
                background: var(--Basic-White);

                svg {
                  display: block;
                  width: 100%;
                  height: 100%;
                  fill: var(--primary);
                }
              }
            }

            .edit-menu-button {
              width: 20px;
              height: 20px;
              border: none;
              background: transparent;
              display: none;
              position: relative;
              svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: var(--Button-States-Hover);
              }
            }

            /* To component */
            .dropdown {
              width: 200px;
              position: absolute;
              top: 29px;
              right: 0;
              z-index: 1;

              margin: 0;
              padding: 0;

              border-radius: 8px;
              border: 1px solid var(--Dividers);
              background: var(--Basic-White);
              box-shadow: 0 6px 8px 0 rgba(146, 158, 159, 0.10);

              & * { transition: all ease-in-out 300ms; }

              li {
                list-style: none;
                padding: 8px 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                &:hover {
                  background: var(--Surface-Hover);

                  svg {
                    fill: var(--Button-States-Hover);
                  }
                  span {
                    color: var(--Button-States-Hover);
                  }
                }
              }

              svg {
                width: 22px;
                height: 22px;
                margin-right: 8px;
                fill: var(--Icons-Icon_Primary);
              }

              span {
                color: var(--Text-Text_Primary);
                font-family: "Open Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
              }
            }

          }

          &.label {
            justify-content: space-between;
            padding: 0 16px;

            // &:hover {
            //   .validation { display: block; }
            // }

            span {
              display: block;
            }

            .validation {
              color: var(--Text-Text_Secondary);
              font-size: 12px;
              // display: none;
              margin-top: 4px;
            }
            button {
              width: 18px;
              height: 18px;
              border: none;
              background: transparent;
              flex-shrink: 0;

              svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: var(--Text-Text_Secondary);
              }
            }
          }

          & .field input {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
