<template>
  <layout-user>
    <div class="specs-page">
      <div class="close" @click="goBack">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20">
          <path fill="#09373d" d="m11.1 10 4.5-4.5c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0L10 8.9 5.5 4.5c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1L8.9 10l-4.5 4.5c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l4.5-4.5 4.5 4.5c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L11.1 10z"/>
        </svg>
        Close
      </div>
      <div class="specs-page__block">
        <h2>Specs</h2>
        <ButtonsGroup>
          <button :class="[selectedValue === 'pattern' ? 'active' : '']" @click="selectedValue = 'pattern'">
            Pattern
          </button>
          <button :class="[selectedValue === 'body' ? 'active' : '']" @click="selectedValue = 'body'">
            Body
          </button>
        </ButtonsGroup>
        <div class="advanced">
          <h4>Measure on <span>1/2 circumference</span></h4>
          <units-toggle-component />
        </div>
        <div class="table" ref="printable">
          <div class="table-row title-row pointName">
            <p>Point of Measure</p>
            <p>Measure</p>
          </div>
          <template v-if="selectedValue === 'pattern'">
            <spec-measurement-element
              v-for="el in patternMeasurements"
              :key="el.id"
              :value="el.value"
              :name="el.name"
              :code="el.code"
              :type="type"
              :src-unit="el.unit"
            />
          </template>
          <template v-else>
            <template v-for="el in bodySizes">
              <spec-measurement-element
                v-if="el.uid !== 'рз_belly_protuberance' && el.uid !== 'рз_buttocks'"
                :key="el.uid"
                :value="el.value"
                :name="el.name"
                :description="el.description"
                :code="el.uid"
                :type="type"
                :src-unit="Measurement.CM"
                is-size-param
              />
              <spec-measurement-drop
                v-else
                :key="el.uid"
                :value="el.value"
                :name="el.name"
                :description="el.description"
                :code="el.uid"
                :type="type"
                is-size-param
              />
            </template>
          </template>
        </div>
        <div :class="`bottom_buttons ${fixedBlockStyle}`" ref="buttons">
          <ButtonPrimary
            style="width:201px"
            @click.native="download"
          >
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20">
              <path d="M15 18.3H5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h10c.4 0 .8.3.8.8s-.4.8-.8.8zm-.3-8.8c-.3-.3-.8-.3-1.1 0l-2.9 2.9V2.5c0-.4-.3-.8-.8-.8s-.7.4-.7.8v9.9L6.4 9.5c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l4.2 4.2h.1c.1 0 .1.1.2.1s.2.1.3.1c.1 0 .2 0 .3-.1.1 0 .2-.1.2-.2l4.2-4.2c.2-.3.2-.7-.1-1z" class="st0"/>
            </svg>
          </template>
            Download
          </ButtonPrimary>
        </div>
        <iframe ref="printFrame" style="display: none;"></iframe>
      </div>
    </div>
  </layout-user>
</template>

<script>
import LayoutUser from '@/layouts/LayoutUser'
import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'
import UnitsToggleComponent from '@/components/Units/UnitsToggleComponentNew'
import pDebounce from 'p-debounce'
import SpecMeasurementElement from '@/components/Measurements/SpecMeasurementElement'
import SpecMeasurementDrop from '@/components/Measurements/SpecMeasurementDrop'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'

import { selectItems } from '@/components/Fit/selectOptions'
import { fetchSpecs } from '@/api'
import { mapGetters, mapActions } from 'vuex'
import { Measurement } from '@/helpers/measurement'

export default {
  components: {
    LayoutUser,
    ButtonsGroup,
    UnitsToggleComponent,
    SpecMeasurementElement,
    SpecMeasurementDrop,
    ButtonPrimary,
  },
  data: () => ({
    selectedValue: 'pattern',
    patternMeasurements: [],
    designOptions: null,
    tableData: null,
    scrolledY: 0,
  }),
  computed: {
    fixedBlockStyle () {
      let padding = window.innerWidth < 768 ? 186 : 102
      const tableElement = this.tableData
      const scrollPosition = this.scrolledY + window.innerHeight
      const tableBottom = tableElement?.getBoundingClientRect().bottom + this.scrolledY
      let bottomDistance
      if (scrollPosition > tableBottom - padding) {
        bottomDistance = tableBottom - scrollPosition + padding
      } else {
        bottomDistance = 0
      }
      if (!bottomDistance || bottomDistance > 0) return ''
      else return 'stop'
    },
    mannequinId () {
      return this.designOptions.mannequinId
    },
    bodySizes () {
      return this.mannequinWithSizeParams[this.mannequinId]?.sizeParams.map(el => {
        const item = selectItems.find(item => item.uid === el.uid)
        if (item) {
          const value = item.options.find(item => item.value === el.value)
          return {
            ...el,
            value: value.label,
          }
        }
        return el
      })
    },
    type () {
      return this.mannequinWithSizeParams[this.mannequinId]?.mannequinType
    },
    ...mapGetters('fitEditor', ['mannequinWithSizeParams']),
  },
  async created () {
    this.designOptions = JSON.parse(this.$route.query.designOptions)
    this.getSpecsDebounced = pDebounce(this.getSpecs, 50)
    this.Measurement = Measurement
    await this.getSpecsDebounced()
    if (!this.mannequinWithSizeParams[this.mannequinId]) {
      this.fetchSizeParamsByMannequinId({ mannequinId: this.mannequinId })
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  async mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.tableData = this.$refs.printable
  },
  methods: {
    handleScroll () {
      this.scrolledY = window.scrollY
    },
    download () {
      const printContents = this.$refs.printable.innerHTML
      const iframe = this.$refs.printFrame
      const doc = iframe.contentDocument || iframe.contentWindow.document
      doc.open()
      doc.write(`
        <html>
          <head>
            <title>Patternfast specs</title>
          </head>
          <style>
            body {
            font-family: "Open Sans", "Noto Sans", Noto, sans-serif;
            }
            .table-row {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid rgba(15, 104, 116, 0.2);
              padding: 8px 0;
              svg {
                display: none;
              }
            }
            .table-row:last-child {
              border-bottom: none;
            }
          </style>
          <body>
            <h1>${this.selectedValue.charAt(0).toUpperCase() + this.selectedValue.slice(1)} specs</h1>
            ${printContents}
          </body>
        </html>
      `)
      doc.close()
      iframe.contentWindow.focus()
      iframe.contentWindow.print()
    },
    ...mapActions('fitEditor', ['fetchSizeParamsByMannequinId']),
    goBack () {
      const rp = JSON.parse(this.$route.query.designOptions)
      let params = {
        ...rp,
        mannequinEase: JSON.parse(this.$route.query.mannequinEase),
      }
      this.$router.push(
        {
          path: '/designer',
          query: {
            params: JSON.stringify(params),
            type: this.$route.query.type,
            category: rp.designCategory,
            isCustomModel: this.$route.query.isCustomModel,
            fitChartsId: this.$route.query.fitChartsId,
            mannequinIdNew: this.$route.query.mannequinIdNew,
            fitChartsIsCustom: this.$route.query.fitChartsIsCustom,
          },
        },
      )
    },
    async getSpecs () {
      try {
        let { specs } = await fetchSpecs({ ...this.designOptions })
        if (!Array.isArray(specs)) {
          throw new Error('Cannot load specs')
        }
        this.patternMeasurements = specs
      } catch (e) {
        this.$emit('error', { title: 'Error', message: e.message })
      }
    },
  },
}
</script>

<style lang="scss">
@import "src/styles/mixins";
.specs-page {
  padding: 16px;
  max-width: 1440px;
  margin: auto;
  .bottom_buttons {
    position: fixed;
    border-top: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
    padding: 16px;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FAFBFB;
  }
  .bottom_buttons.stop {
    position: absolute;
    bottom: 0;
  }
  .close {
    font-size: 14px;
    cursor: pointer;
    margin-top: 8px;
    width: fit-content;
    @include flex(center);
    svg {
      margin-right: 4px;
    }
  }
  &__block {
    max-width: 684px;
    margin: auto;
    @include flex(center, center, column);
    h2 {
      @include font-styles(24px, 36px, 600, Montserrat);
      margin-bottom: 16px;
    }
    .buttons-group button {
      width: 161px;
    }
    .advanced {
      margin-top: 32px;
      margin-bottom: 16px;
      width: 100%;
      h4 {
        @include font-styles(18px, 27px, 600, Montserrat);
      }
      .select-block-radio .radio-item {
        min-width: 20px;
      }
      @include flex(center, space-between)
    }
    .table {
      margin-bottom: 112px;
      width: 100%;
      border-radius: 12px;
      border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
      .table-row {
        border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
        padding: 16px;
        display: grid;
        grid-template-columns: minmax(30%, 70%) minmax(15%, 30%);
        gap: 32px;
        &:last-child {
          border: none;
        }
        &__collumn {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .title-row {
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        p {
          margin: 0;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .specs-page__block {
    h2 {
      @include font-styles(20px, normal, 600, Montserrat);
    }
    .advanced {
      margin-top: 48px;
      .select-block-radio .radio-item {
        width: 31px;
      }
      h4 {
        display: flex;
        flex-direction: column;
      }
    }
    .buttons-group {
      width: 100%;
      button {
        width: 100%;
      }
    }
    .table {
      border: none;
      .table-row {
        border-radius: 12px;
        border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
        grid-template-columns: 1fr 1fr;
        padding: 0;
        gap: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
          border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
        }
        .name {
          grid-column: 1 / 3;
          border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
          padding: 16px;
        }
      }
      .pointName {
        display: none;
      }
    }
  }
}
</style>
