<template>
  <div class="colors-panel">
    <div class="top-panel">
      <button @click="$emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path d="M5 4.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L8.937 10 4.47 14.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.062 0L10 11.062l4.469 4.47a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.063L11.062 10l4.47-4.469a.75.75 0 0 0 0-1.062A.75.75 0 0 0 15 4.25a.75.75 0 0 0-.531.219L10 8.937 5.531 4.47A.75.75 0 0 0 5 4.25z"/>
        </svg>
      </button>
      <h2>Color</h2>
    </div>
    <div class="search-panel">
      <InputField v-model="search" placeholder="Search TCX, sRGB, HEX, LAB...">
        <template v-slot:start-icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21" class="search-icon">
            <path d="M8.334 2.25c-3.627 0-6.584 2.957-6.584 6.584s2.957 6.582 6.584 6.582a6.547 6.547 0 0 0 4.088-1.432l4.547 4.547a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.062l-4.547-4.547a6.547 6.547 0 0 0 1.432-4.088c0-3.627-2.955-6.584-6.582-6.584zm0 1.5a5.071 5.071 0 0 1 5.082 5.084 5.07 5.07 0 0 1-5.082 5.082A5.071 5.071 0 0 1 3.25 8.834 5.073 5.073 0 0 1 8.334 3.75z"/>
          </svg>
        </template>
      </InputField>
    </div>
    <div class="bottom-panel">
      <h2>{{ count }} elements found</h2>
      <button @click="createColorWays" :class="demoMode ? 'demo-create-color-ways' : ''">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M5.994 2.008a1.205 1.205 0 0 0-1.105.88L1.875 14.136a3.678 3.678 0 0 0 2.594 4.49c.307.082.617.12.922.121a.75.75 0 0 0 .025.004h11.643c.649 0 1.191-.542 1.191-1.191v-4.952a.75.75 0 0 0-.049-.261c-.006-.15-.041-.3-.107-.442l-2.092-4.488a1.188 1.188 0 0 0-.674-.611 1.185 1.185 0 0 0-.908.035l-3.426 1.598.979-3.65a1.203 1.203 0 0 0-.842-1.46L6.348 2.047a1.166 1.166 0 0 0-.354-.04zm.264 1.568 4.185 1.121L7.51 15.645a2.153 2.153 0 0 1-1.102 1.367l-.074.035a.75.75 0 0 0-.016.012 2.144 2.144 0 0 1-.81.191h-.092a2.2 2.2 0 0 1-.559-.074 2.155 2.155 0 0 1-1.533-2.653L6.258 3.576zm8.515 4.754 1.83 3.928-7.58 3.535 1.465-5.465 4.285-1.998zm1.977 5.514v3.406H9.447l7.303-3.406zm-11.332.572a.751.751 0 0 0-.752.75.751.751 0 0 0 .752.752.751.751 0 0 0 .75-.752.751.751 0 0 0-.75-.75z"/>
        </svg>
        Create colorways
        <div v-if="demoMode" class="tooltiptext">Upgrade your plan<br>to create colorways</div>
      </button>
    </div>
    <div class="results">
      <div class="one-color" :class="{ selected: activeColor === color.HEX }" v-for="color in res" :key="color.id" @click="setColor(color.HEX)">
        <div class="example" :style="{ backgroundColor: color.HEX }"></div>
        <h6>{{ color.sRGB }}</h6>
        <h5>{{ color.HEX }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import MiniSearch from 'minisearch'
import InputField from '../Atoms/InputField.vue'
import { mapGetters } from 'vuex'

let miniSearch

export default {
  name: 'ColorsPanel',
  components: {
    InputField,
  },
  props: {
    activeColor: {
      type: String,
    },
  },
  data: () => ({
    search: '',
    colors: [],
    res: [],
    error: false,
  }),
  computed: {
    ...mapGetters('user', ['demoMode']),
    count () {
      return this.res.length
    },
  },
  watch: {
    search (v) {
      this.res = v === ''
        ? miniSearch.search(MiniSearch.wildcard)
        : this.res = miniSearch.search(v)
    },
  },
  methods: {
    setColor (hex) {
      const val = hex === this.activeColor ? undefined : hex
      this.$emit('set-color', val)
    },
    createColorWays () {
      if (!this.demoMode) this.$emit('create-colorways')
    },
  },
  async beforeMount () {
    try {
      const req = await fetch('/colors.json')
      const res = await req.json()
      this.colors = res.map((c, i) => ({ ...c, id: `${c.HEX}-${i}`, HEX: `#${c.HEX}` }))
      miniSearch = new MiniSearch({
        fields: ['HEX', 'LAB', 'Name', 'TCX', 'sRGB'],
        storeFields: ['HEX', 'LAB', 'sRGB', 'Name'],
        searchOptions: {
          boost: { Name: 2 },
          prefix: true,
          fuzzy: 0.2,
        },
      })
      miniSearch.addAll(this.colors)
      this.res = miniSearch.search(MiniSearch.wildcard)
    } catch (e) {
      this.error = true
      console.warn(e.message)
    }
  },
  beforeDestroy () {
    miniSearch = undefined
  },
}
</script>

<style lang="scss">
  .colors-panel {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);

    border-right: 1px solid var(--Dividers);
    background: var(--Surface);

    h2 {
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    .top-panel {
      display: flex;
      column-gap: 8px;
      align-items: center;
      padding: 16px;
      padding-bottom: 40px;

      button {
        border: none;
        background: transparent;
        width: 20px;
        height: 20px;

        svg {
          display: block;
          margin: 0;
          width: 100%;
          height: 100%;
          fill: var(--Icons-Icon_Primary);
        }
      }
    }

    .search-panel {
      padding: 0 16px;
      padding-bottom: 40px;

      .search-icon {
        fill: var(--Icons-Icon_Primary);
      }
    }

    .bottom-panel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      padding-bottom: 27px;

      .demo-create-color-ways {
        position: relative;
        color: var(--Button-States-Disabled_Txt);
        svg {
          fill: var(--Button-States-Disabled_Txt);
        }
        .tooltiptext {
          font-size: 12px;
          font-weight: 400;
          top: calc(100% + 13px);
          display: none;
          background-color: #09373D;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 4px 12px;
          position: absolute;
          z-index: 1;
          transform: translateX(-50%);
          width: max-content;
          left: 50%;
          transition: opacity 0.3s;
          &::after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #09373D transparent;
          }
        }
        &:hover {
          .tooltiptext {
            display: block;
          }
        }
      }

      button {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        column-gap: 4px;

        color: var(--Primary-Shades-Primary);
        text-align: center;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        svg {
          display: block;
          margin: 0;
          width: 20px;
          height: 20px;
          fill: var(--Icons-Icon_Primary);
        }
      }
    }

    .results {
      overflow-y: auto;
      padding: 16px;

      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .one-color {
        width: 100px;
        height: 116px;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 4px;
        border: 1px solid var(--Dividers);
        background: var(--Background);

        text-align: center;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;

        cursor: pointer;

        &.selected {
          border-color: var(--Active-State-Strokes);
          h5 {
            color: var(--Active-State-Strokes);
          }
        }

        .example {
          aspect-ratio: 92 / 75;
          width: 100%;
          height: auto;
          border-radius: 8px;
          margin-bottom: 4px;
        }

        h6 {
          color: var(--Text-Text_Secondary);
          font-size: 8px;
          text-transform: uppercase;
          line-height: normal;
          margin-bottom: 4px;
        }
        h5 {
          color: var(--Text-Text_Primary);
          font-size: 10px;
          line-height: normal;
        }
      }
    }
  }
  @media (max-width: 760px) {
    .colors-panel {
      .bottom-panel {
        padding-bottom: 16px;
      }
    }
  }
</style>
