const state = {
  info: null,
}
const getters = {
  subscriptionStatus: state => state.info?.company?.subscription?.status || '',
  lastCardNum: state => state.info?.company?.subscription?.paymentMethod?.last4 || null,
  periodEndDate (state) {
    if (state.info?.company?.subscription?.periodEndDate) {
      const date = new Date(state.info.company.subscription.periodEndDate)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return date.toLocaleDateString('en-US', options)
    }
    return null
  },
  demoMode: state => {
    if (state.info.company?._id === 1) return false
    return state.info?.company?.subscription?.status !== 'active'
  },
  downloadLeft: state => {
    if (
      state.info?.company?.subscription?.status !== 'active' &&
      state.info.company?.downloads < 4
    ) {
      return 3 - state.info.company?.downloads
    }
    return 0
  },
  subscriptionPlan: state => state.info?.company?.plan,
  daysRemaining (state) {
    if (state.info.company?.createdAt) {
      const now = new Date()
      const demoExpiresAt = new Date(state.info.company.createdAt)
      const timeDifference = now - demoExpiresAt
      const daysRemaining = Math.floor(timeDifference / (1000 * 3600 * 24))
      return daysRemaining > 7 ? 0 : 7 - daysRemaining
    }
  },
}
const mutations = {
  SET_USER_INFO (state, info) {
    state.info = info
  },
  SET_USER_SUBSCRIPTION_STATUS (state, subscription) {
    if (state.info?.company?.subscription) {
      state.info.company.subscription = subscription
    }
  },
}
const actions = {
  async setUserInfo ({ commit }, info) {
    commit('SET_USER_INFO', info)
  },
  async setUserSubscriptionStatus ({ commit }, subscription) {
    commit('SET_USER_SUBSCRIPTION_STATUS', subscription)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
