<template>
  <layout-user>
    <div class="body-container">
      <div class="layout-block">
        <div class="tabs">
          <div class="tabs-wrapper">
            <div
              class="tabs__tab"
              :class="{'active': $route.name.includes('userCollection')}"
              @click="$router.push({ name: 'userCollections' })"
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path d="M4 3.25c-.958 0-1.75.792-1.75 1.75v14c0 .958.792 1.75 1.75 1.75h16c.958 0 1.75-.792 1.75-1.75V7c0-.958-.792-1.75-1.75-1.75h-7.688l-1.488-1.488h-.002a1.752 1.752 0 0 0-1.236-.512H4zm0 1.5h5.586c.067 0 .129.025.176.072l.427.428H3.75V5A.24.24 0 0 1 4 4.75zm-.25 2H20a.24.24 0 0 1 .25.25v12a.24.24 0 0 1-.25.25H4a.24.24 0 0 1-.25-.25V6.75z"/>
            </svg>
              Collections
            </div>

            <div
              class="tabs__tab"
              :class="{'active': $route.name.includes('fitModel')}"
              @click="$router.push({name: 'fitModels'})"
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path d="M9 4.25A4.761 4.761 0 0 0 4.25 9 4.761 4.761 0 0 0 9 13.75 4.761 4.761 0 0 0 13.75 9 4.761 4.761 0 0 0 9 4.25zm6 0a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75A3.239 3.239 0 0 1 18.25 9 3.239 3.239 0 0 1 15 12.25a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75A4.761 4.761 0 0 0 19.75 9 4.761 4.761 0 0 0 15 4.25zm-6 1.5A3.239 3.239 0 0 1 12.25 9 3.239 3.239 0 0 1 9 12.25 3.239 3.239 0 0 1 5.75 9 3.239 3.239 0 0 1 9 5.75zm0 9.5c-1.792 0-3.426.475-4.658 1.297C3.11 17.368 2.25 18.59 2.25 20a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-.8.485-1.579 1.424-2.205.94-.626 2.304-1.045 3.826-1.045 1.522 0 2.887.419 3.826 1.045.94.626 1.424 1.404 1.424 2.205a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-1.409-.86-2.632-2.092-3.453-1.232-.822-2.866-1.297-4.658-1.297zm7.977.229a.75.75 0 0 0-.707.576.75.75 0 0 0 .558.902c1.058.25 1.943.711 2.535 1.26.592.548.887 1.152.887 1.783a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-1.11-.542-2.118-1.367-2.883-.826-.765-1.938-1.319-3.211-1.619a.75.75 0 0 0-.195-.02z"/>
            </svg>
              Fit Models
            </div>

            <div
              class="tabs__tab"
              :class="{'active': $route.name.includes('fitChart')}"
              @click="$router.push({name: 'fitChartsCustom'})"
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path d="M15.537 2.477c-.45 0-.9.169-1.238.507l-2.82 2.82a.75.75 0 0 0-.01.008.75.75 0 0 0-.008.01L8.652 8.631a.75.75 0 0 0-.011.01.75.75 0 0 0-.008.01l-2.809 2.808a.75.75 0 0 0-.011.01.75.75 0 0 0-.008.01l-2.82 2.82a1.761 1.761 0 0 0 0 2.474l4.244 4.243a1.761 1.761 0 0 0 2.474 0L21.016 9.7a1.761 1.761 0 0 0 0-2.474l-4.243-4.243a1.742 1.742 0 0 0-1.236-.507zm-.002 1.49c.063 0 .126.026.178.078l4.242 4.242a.24.24 0 0 1 0 .354L8.643 19.955a.24.24 0 0 1-.354 0l-4.244-4.242a.24.24 0 0 1 0-.354l2.299-2.298 1.592 1.591a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L7.404 12l1.768-1.768 1.592 1.59a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-1.59-1.59L12 7.404l1.592 1.59a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-1.59-1.59 2.297-2.3a.248.248 0 0 1 .176-.077z"/>
            </svg>
              Fit charts
            </div>
            <div
              v-if="
                userStore.data?.role === 'MANAGER' &&
                userStore.data?.company?.plan !== 'INDIE_EA' && userStore?.data.company?.plan !== 'STUDENT_EA'"
              class="tabs__tab"
              :class="{'active': $route.name === 'user_team'}"
              @click="$router.push({name: 'user_team'})"
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path d="M8 4.25A3.761 3.761 0 0 0 4.25 8a3.75 3.75 0 0 0 1.25 2.795.75.75 0 0 0 1.059-.059.75.75 0 0 0-.059-1.058A2.236 2.236 0 0 1 5.75 8c0-1.252.998-2.25 2.25-2.25.58 0 1.101.215 1.5.572a.75.75 0 0 0 1.059-.058.75.75 0 0 0-.059-1.059A3.746 3.746 0 0 0 8 4.25zm8 0c-.956 0-1.837.362-2.5.955a.75.75 0 0 0-.059 1.059.75.75 0 0 0 1.059.058c.399-.357.92-.572 1.5-.572 1.252 0 2.25.998 2.25 2.25 0 .671-.289 1.265-.75 1.678a.75.75 0 0 0-.059 1.058.75.75 0 0 0 1.059.059A3.75 3.75 0 0 0 19.75 8 3.761 3.761 0 0 0 16 4.25zm-4 3A3.761 3.761 0 0 0 8.25 11 3.761 3.761 0 0 0 12 14.75 3.761 3.761 0 0 0 15.75 11 3.761 3.761 0 0 0 12 7.25zm0 1.5c1.252 0 2.25.998 2.25 2.25s-.998 2.25-2.25 2.25A2.239 2.239 0 0 1 9.75 11c0-1.252.998-2.25 2.25-2.25zM5.977 13.5a.75.75 0 0 0-.166.023c-.977.257-1.831.678-2.475 1.256-.644.58-1.086 1.355-1.086 2.221a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-.364.175-.732.59-1.105.415-.374 1.06-.714 1.85-.92a.75.75 0 0 0 .535-.916.75.75 0 0 0-.748-.559zm12.046 0a.75.75 0 0 0-.748.559.75.75 0 0 0 .536.916c.788.206 1.434.546 1.85.92.414.373.589.74.589 1.105a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-.866-.442-1.642-1.086-2.22-.644-.58-1.498-1-2.475-1.257a.75.75 0 0 0-.166-.023zM12 16.25c-1.502 0-2.873.357-3.922.986C7.03 17.866 6.25 18.834 6.25 20a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-.49.34-1.022 1.1-1.479.76-.456 1.89-.771 3.15-.771s2.39.315 3.15.771c.76.457 1.1.988 1.1 1.479a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-1.166-.779-2.134-1.828-2.764-1.05-.63-2.42-.986-3.922-.986z"/>
            </svg>
              My team
            </div>
            <div
              class="tabs__tab"
              :class="{'active': $route.name === 'userProfile'}"
              @click="$router.push({name: 'userProfile'})"
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path d="M12 3.25A4.761 4.761 0 0 0 7.25 8 4.761 4.761 0 0 0 12 12.75 4.761 4.761 0 0 0 16.75 8 4.761 4.761 0 0 0 12 3.25zm0 1.5A3.239 3.239 0 0 1 15.25 8 3.239 3.239 0 0 1 12 11.25 3.239 3.239 0 0 1 8.75 8 3.239 3.239 0 0 1 12 4.75zm0 9.5c-1.792 0-3.426.475-4.658 1.297C6.11 16.368 5.25 17.59 5.25 19a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-.8.485-1.579 1.424-2.205.94-.626 2.304-1.045 3.826-1.045 1.522 0 2.887.419 3.826 1.045.94.626 1.424 1.404 1.424 2.205a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75c0-1.409-.86-2.632-2.092-3.453-1.232-.822-2.866-1.297-4.658-1.297z"/>
            </svg>
              My profile
            </div>
            <div
              class="tabs__tab"
              :class="{'active': $route.name === 'user_billing'}"
              @click="$router.push({name: 'user_billing'})"
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path d="M4 4.25c-.958 0-1.75.792-1.75 1.75v12c0 .958.792 1.75 1.75 1.75h16c.958 0 1.75-.792 1.75-1.75V6c0-.958-.792-1.75-1.75-1.75H4zm0 1.5h16a.24.24 0 0 1 .25.25v2.25H3.75V6A.24.24 0 0 1 4 5.75zm-.25 4h16.5v.5H3.75v-.5zm0 2h16.5V18a.24.24 0 0 1-.25.25H4a.24.24 0 0 1-.25-.25v-6.25zM7 14.25a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H7z"/>
            </svg>
              Billing
            </div>
          </div>
          <div
            class="tabs__tab log-out"
            @click="sendLogout"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path d="M10 3.25c-.958 0-1.75.792-1.75 1.75v2a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75V5a.24.24 0 0 1 .25-.25h9a.24.24 0 0 1 .25.25v14a.24.24 0 0 1-.25.25h-9a.24.24 0 0 1-.25-.25v-2a.75.75 0 0 0-.75-.75.75.75 0 0 0-.75.75v2c0 .958.792 1.75 1.75 1.75h9c.958 0 1.75-.792 1.75-1.75V5c0-.958-.792-1.75-1.75-1.75h-9zm2 5a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062l1.719 1.719H4a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h9.188l-1.72 1.719a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.063 0l3-3a.75.75 0 0 0 .194-.338.75.75 0 0 0 0-.13.75.75 0 0 0 .025-.063.75.75 0 0 0-.025-.129.75.75 0 0 0-.02-.098.75.75 0 0 0-.174-.304l-3-3A.75.75 0 0 0 12 8.25z"/>
            </svg>
            Log out
          </div>
        </div>
        <div class="content-wrapper">
          <router-view></router-view>
        </div>
        <modal-component
          :show="errorLogout"
          type="error" showIcon
          @close="errorLogout = false"
          class="continue-modal"
        >
          <!-- <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" width="29" height="29">
              <path fill="#09373D" fill-rule="evenodd" d="M14.667 2.333c-6.812 0-12.334 5.522-12.334 12.334C2.333 21.478 7.855 27 14.667 27 21.478 27 27 21.478 27 14.667c0-6.812-5.522-12.334-12.333-12.334ZM.333 14.668C.333 6.75 6.751.333 14.667.333 22.583.333 29 6.751 29 14.667 29 22.583 22.583 29 14.667 29S.333 22.583.333 14.667ZM14.667 9a1.5 1.5 0 0 0-1.5 1.5 1 1 0 0 1-2 0 3.5 3.5 0 1 1 5.28 3.014 2.483 2.483 0 0 0-.62.49c-.137.162-.16.268-.16.33V16a1 1 0 0 1-2 0v-1.666c0-.675.295-1.222.635-1.623a4.424 4.424 0 0 1 1.127-.918A1.499 1.499 0 0 0 14.667 9Zm0 12.334a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.666Z" clip-rule="evenodd"/>
            </svg>
          </template> -->
          <template v-slot:title>Failed to log out, please try again</template>
          <template v-slot:buttons>
            <ButtonSecondary class="small" @click.native="sendLogout">
              Try again
            </ButtonSecondary>
            <ButtonPrimary class="small" @click.native="errorLogout = false">
              Close
            </ButtonPrimary>
          </template>
        </modal-component>
      </div>
    </div>
  </layout-user>
</template>

<script>
import LayoutUser from '@/layouts/LayoutUser'
import ModalComponent from '@/components/Atoms/ModalComponent'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import { logout } from '@/api'
import { mapStores } from 'pinia'
import { useUserStore } from '../../store/pinia/user'

export default {
  components: {
    LayoutUser,
    ModalComponent,
    ButtonPrimary,
    ButtonSecondary,
  },
  data: () => ({
    errorLogout: false,
  }),
  computed: mapStores(useUserStore),
  methods: {
    async sendLogout () {
      try {
        await logout()
        this.$router.push('/')
      } catch (e) {
        this.errorLogout = true
      }
    },
  },
}
</script>

<style lang="scss">
  .body-container {
    max-width: 1440px;
    margin: auto;
    min-height: 100%;
    display: flex;
    .layout-block {
      width: 100%;
      display: grid;
      grid-template-columns: 217px auto;

      .tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        border-right: 1px solid var(--Dividers);
      }

      .tabs-wrapper {
        position: sticky;
        align-self: flex-start;
        padding: 24px 16px;
        top: 0;
      }

      .tabs__tab {
        padding: 8px 12px;
        width: 150px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        color: var(--text_color);
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        line-height: normal;

        &.log-out {
          position: sticky;
          bottom: 16px;
          align-self: flex-end;
          margin: 0 0 24px 16px;
        }

        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          fill: var(--Icons-Icon_Primary);
        }
      }
      .tabs__tab.active {
        background: var(--Active-States-Surfaces);
        color: var(--Active-State-Strokes);
        svg {
          fill: var(--Active-State-Strokes);
        }
      }
    }

    .content-wrapper {
      padding: 24px 0;
    }
  }
  @media (max-width: 768px) {
    .body-container {
      .layout-block {
        grid-template-columns: auto;
      }
      .tabs {
        display: none !important;
      }

      .content-wrapper {
        padding: 24px 16px;
      }
    }
  }
</style>
