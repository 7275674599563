<template>
  <tr class="EaseOption">
    <td>
      <div class="row flex-center">
        <div class="col column avenir-font-bold">
          <span class="col">
            {{ name }} <span v-if="markWithStar" class="text-secondary">*</span>
          </span>
          <span v-if="error" class="EaseOption__error text-negative">{{ error }}</span>
          <div v-else>
            <span
              class="col EaseOption__description"
              v-if="unsupportedUnits"
            >
              {{
                name.includes('buttons quantity') ?
                  `from ${minValue - placeholder} ${srcUnit}
                  to ${maxValue - placeholder} ${srcUnit}` :
                  `from ${isChangeRange ? minValue - allUserValue : minValue} ${srcUnit}
                  to ${isChangeRange ? maxValue - allUserValue : maxValue} ${srcUnit}`
              }}
            </span>
            <span
              class="col EaseOption__description"
              v-else
            >
              from <measure-view-component
              :fraction-base="getFractionBase"
              :output-units="units"
              floor-value
              :value-in-cm="
                Number((isChangeRange ? minValue - allUserValue : minValue).toFixed(1))
              "
            /> to <measure-view-component
              :fraction-base="getFractionBase"
              :output-units="units"
              floor-value
              :value-in-cm="
                Number((isChangeRange ? maxValue - allUserValue : maxValue).toFixed(1))
              "
            />
            </span>
          </div>
        </div>

        <q-icon
          name="help_outline"
          color="secondary"
          size="sm"
          class="EaseOption__icon cursor-pointer self-lg-end"
          @click="displayDescription = true"
        />

        <q-dialog v-if="displayDescription" v-model="displayDescription">
          <dialog-card-base
            content-container-class="col-shrink no-wrap"
          >
            <div
              v-if="imageLoadError"
              class="col col-shrink q-pa-md text-body text-center"
            >
              Sorry, no help image were found.
            </div>
            <div
              v-else
              class="EaseOption__imgContainer"
            >
              <img
                :src="imageUrl()"
                alt="Measure yourself"
              />
            </div>
          </dialog-card-base>
        </q-dialog>
      </div>
    </td>
    <td>
      <units-input-component
        ref="unitsInput"
        dense
        input-class="text-right avenir-font-bold"
        :suffix="unsupportedUnits ? srcUnit : units"
        :initial-value-in-cm="initialValue"
        :min-in-cm="
          name.includes('buttons quantity') ?
          minValue - placeholder :
          Number((isChangeRange ? minValue - allUserValue : minValue).toFixed(1))
        "
        :max-in-cm="
          name.includes('buttons quantity') ?
          maxValue - placeholder :
          Number((isChangeRange ? maxValue - allUserValue : maxValue).toFixed(1))
        "
        :src-unit="srcUnit"
        placeholder="0"
        @changed="change"
        @error="makeError"
      />
    </td>
    <td class="text-right avenir-font-bold">
      <span>
        {{
          placeholder && placeholder !== '0' ?
          `${units === 'in' ? placeholder : Number(placeholder.toFixed(1))}` :
          allUserValueChanged
        }}
        {{ unsupportedUnits ? srcUnit : units }}
      </span>
    </td>
    <td class="text-right avenir-font-bold">
      <measure-view-component
        :fraction-base="unsupportedUnits ? 10 : getFractionBase"
        :output-units="unsupportedUnits ? srcUnit : units"
        :value-in-cm="patternValue"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Measurement } from '@/helpers/measurement'
import UnitsInputComponent from '@/components/Units/UnitsInputComponent'
import MeasureViewComponent from '@/components/Units/MeasureViewComponent'
import DialogCardBase from '@/components/Dialog/DialogCardBase'

export default {
  name: 'EaseOption',
  components: { MeasureViewComponent, UnitsInputComponent, DialogCardBase },
  // все входящие и исходящие значения в сантиметрах
  props: {
    name: {
      type: String,
      required: true,
    },
    markWithStar: Boolean,
    minValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    placeholderValue: {
      type: Number,
      required: true,
    },
    initialValue: {
      type: [Number, undefined],
      default: undefined,
    },
    patternValue: {
      type: Number,
      required: true,
    },
    srcUnit: {
      type: String,
      required: true,
    },
    designCategory: {
      type: String,
      required: true,
    },
    allUserValue: {
      type: Number,
      default: 0,
    },
    isChangeRange: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    value: '',
    error: null,
    displayDescription: false,
    imageLoadError: false,
  }),

  computed: {
    placeholder () {
      if (this.units === this.srcUnit || this.unsupportedUnits) {
        return this.placeholderValue
      }
      return Measurement.decimalsToFractions(Measurement.cmToIn(this.placeholderValue)) || 0
    },
    allUserValueChanged () {
      if (this.units === this.srcUnit || this.unsupportedUnits) {
        if (!this.allUserValue) return 0
        return Number(this.allUserValue.toFixed(1))
      }
      return Measurement.decimalsToFractions(Measurement.cmToIn(this.allUserValue)) || 0
    },
    unsupportedUnits () {
      try {
        return !Measurement.checkUnit(this.srcUnit)
      } catch (e) {
        return true
      }
    },
    ...mapState([
      'units',
    ]),
    ...mapGetters([
      'getFractionBase',
    ]),
  },

  methods: {
    imageUrl () {
      if (!this.name) {
        return undefined
      }
      const designCatArray = this.designCategory.split('_')
      let wear = designCatArray[designCatArray.length - 1]
      const topsVarians = ['tshirts', 'sweatshirts', 'shirts', 'tops']
      if (
        (designCatArray[0] === 'bo' || designCatArray[0] === 'gl') &&
        topsVarians.includes(designCatArray[designCatArray.length - 1])
      ) {
        wear = 'tops'
      }
      let wearStyle = designCatArray[designCatArray.length - 1]
      if (designCatArray[designCatArray.length - 2] === 'tank' && designCatArray[designCatArray.length - 1] === 'tops') {
        wearStyle = 'tank-tops'
      }
      try {
        return require(`@/assets/easeDescriptionImages/${designCatArray[0]}/${wear}/${wearStyle}/${this.name.toLowerCase().trim().replace(/ /ig, '_')}.png`)
      } catch (e) {
        try {
          return require(`@/assets/easeDescriptionImages/${designCatArray[0]}/${wear}/${this.name.toLowerCase().trim().replace(/ /ig, '_')}.png`)
        } catch (e) {
          try {
            return require(`@/assets/easeDescriptionImages/${designCatArray[0]}/${this.name.toLowerCase().trim().replace(/ /ig, '_')}.png`)
          } catch (a) {
            this.imageLoadError = true
          }
        }
      }
    },
    applyFractionBase: (value, base = 10) => Measurement.applyFractionBase(value, base),
    rangeValidator (val) {
      let errorNumberMin = 0
      let errorNumberMax = 0
      if (this.units === 'in') {
        errorNumberMin = 0.15
        errorNumberMax = 0.2
      }
      if (this.name.includes('buttons quantity')) {
        return val >= this.minValue - this.placeholder && val <= this.maxValue - this.placeholder
      }
      return (
        val + errorNumberMax >= (Number((this.isChangeRange ? this.minValue - this.allUserValue : this.minValue).toFixed(1))) &&
        val - errorNumberMin <= Number((this.isChangeRange ? this.maxValue - this.allUserValue : this.maxValue).toFixed(1))
      )
    },
    change (val) {
      if (!val || this.rangeValidator(val)) {
        this.$emit('change', val)
      }
    },
    makeError (val) {
      this.error = val
      this.$emit('validationError', val)
    },
    reset () {
      this.$refs['unitsInput'].reset()
      this.value = ''
    },
  },

}
</script>

<style lang="scss">
  @import "src/styles/variables";

  $description-size: $base-font-size * 0.85;
  .EaseOption {

    td {
      border: 1px solid $custon-grey;
      &:first-child {
        background: #fff;
      }
    }

    &__icon {
      color: $custom-black !important;
    }

    &__description {
      opacity:   0;
      font-size: $description-size;
    }
    &__error {
      font-size: $description-size;
    }

    &:hover &__description {
      opacity: 0.7;
    }

    &__imgContainer img {
      max-width: 100%;
    }
    @media (max-width: 450px) {
      &__imgContainer img {
        max-height: 350px;
      }
    }
  }
</style>
