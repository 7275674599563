<template>
  <div :class="`toggle ${value ? 'active' : ''}`" @click="$emit('input', !value)">
    <div class="toggle-circle"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    val: false,
  }),
}
</script>

<style lang="scss" scoped>
.toggle {
  width: 40px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  position: relative;
  transition: background-color 0.3s;
  background-color: #B2C3C4;
}

.toggle-circle {
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  position: absolute;
  left: 0;
  transition: left 0.3s;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.toggle.active {
  background-color: #FF6770;
}

.toggle.active .toggle-circle {
  left: 16px;
}
</style>
