<template>
  <div class="column">
    <div v-if="hasSavedManneqins" class="row">
      <div class="col q-px-md q-py-sm">
        <attribute-settings-select
          label="Measurement Profile"
          :attributes="savedManneqinsDropdown"
          :active-element="selectedManneqin"
          @change="selectMannequin($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col q-px-md q-py-sm avenir-font-regular">
        <q-input
          outlined
          dense
          v-model="name"
          ref="fieldName"
          placeholder="Model name"
          :disable="!editAvailable"
          :rules="[val => !!val || 'Field is required']"
        />
      </div>
    </div>
    <div class="q-px-md q-py-sm avenir-font-bold">
      <p style="text-decoration: underline; text-align: end; cursor:pointer;" @click="isShowSizeChart = true">
        Size Chart
      </p>
    </div>
    <div class="row full-width q-px-md q-py-sm">
      <div class="col avenir-font-regular">
        Units
      </div>
      <units-toggle-component
        class="col flex justify-end avenir-font-bold"
      />
    </div>
    <!--
    <div class="row full-width q-px-md q-py-sm">
      <div class="col">
        Type
      </div>
      <mannequin-type-selector
        :disabled="!editAvailable || !mannequinTypeEditable"
        :value="type"
        @input="updateMannequinType"
        class="col flex justify-end"
      />
    </div>
    -->
    <template v-if="params && params.length > 0">
      <div class="q-px-md avenir-font-bold">
        <q-markup-table flat separator="none" class="FitFormTable">
          <thead>
          <tr>
            <th class="text-left">Point of Measure</th>
            <th></th>
            <th class="text-center">
              <div class="row flex justify-end">
                Customize<br>Measurements
              </div>
            </th>
          </tr>
          </thead>
        </q-markup-table>
      </div>
      <div class="col q-px-md" style="min-height: 150px;">
        <q-scroll-area style="height: 100%;" visible>
          <q-markup-table wrap-cells flat separator="none" bordered square>
            <tbody>
            <fit-option
              v-for="option in params"
              :name="option.name"
              :description="option.description"
              :image="option.imageName"
              :key="option.uid"
              :option-id="option.uid"
              :edit-available="editAvailable"
              :min-in-cm="ValidationValues[option.uid] && ValidationValues[option.uid].min ? ValidationValues[option.uid].min : option.min"
              :max-in-cm="ValidationValues[option.uid] && ValidationValues[option.uid].max ? ValidationValues[option.uid].max : option.max"
              :initial-value-in-cm="fitOptions[option.uid] || getFitOption(option.uid)"
              @change="setOption(option.uid, $event)"
              @validationError="makeError"
            />
            </tbody>
          </q-markup-table>
        </q-scroll-area>
      </div>
    </template>

    <div
      v-if="editAvailable"
      class=" q-pa-md avenir-font-regular"
    >
      All fields are required.
    </div>

    <div
      v-if="editAvailable && !isEditPage"
      class="row justify-center q-gutter-md avenir-font-bold"
    >
      <q-btn
        size="md"
        :disable="!isInputValid || validationError"
        class="black-button"
        no-caps
        @click.prevent="save"
      >
        Save
      </q-btn>

      <q-btn
        outline
        size="md"
        color="primary"
        class="outline-button"
        no-caps
        @click.prevent="cancel"
      >
        Cancel
      </q-btn>
    </div>
    <q-dialog v-model="isShowSizeChart">
      <dialog-card-base
        content-container-class="col-shrink no-wrap"
        class="SizeChart"
      >
        <size-chart :mannequinType="mannequinType"/>
      </dialog-card-base>
    </q-dialog>
  </div>
</template>

<script>
import { EDIT_MODES, isInputValid, validateMeasurements } from '@/components/Fit/measurements'
import FitOption from '@/components/Fit/FitOption'
import SizeChart from '@/components/Fit/SizeChart'
import UnitsToggleComponent from '@/components/Units/UnitsToggleComponent'
import { MANNEQUIN_TYPES } from '@/components/Fit/mannequinTypes'
import AttributeSettingsSelect from '@/components/AttributeSettings/AttributeSettingsSelect'
// import MannequinTypeSelector from '@/components/Fit/MannequinTypeSelector'
import DialogCardBase from '@/components/Dialog/DialogCardBase'

export default {
  name: 'FitForm',
  components: {
    UnitsToggleComponent,
    FitOption,
    AttributeSettingsSelect,
    DialogCardBase,
    SizeChart,
    // MannequinTypeSelector,
  },
  props: {
    isEditPage: {
      type: Boolean,
      default: false,
    },
    mannequinName: {
      type: String,
      default: undefined,
    },
    mannequinId: {
      type: String,
      default: '',
    },
    params: {
      type: Array,
      default: undefined,
      validator: validateMeasurements,
    },
    editAvailable: Boolean,
    mannequinType: {
      type: String,
      required: true,
      validator: val => Object.values(MANNEQUIN_TYPES).includes(val),
    },
    savedManneqinsIds: {
      type: Array,
      default () {
        return []
      },
    },
    savedManneqinsNames: {
      type: Array,
      default () {
        return []
      },
    },
    mannequinTypeEditable: Boolean,
  },

  data: () => ({
    validationError: false,
    name: '',
    type: undefined,
    fitOptions: {},
    isShowSizeChart: false,
  }),

  computed: {
    isValid () {
      return !this.isInputValid || this.validationError || !this.name
    },
    isChild () {
      if (this.mannequinType === 'FEMALE' || this.mannequinType === 'MALE') return false
      return true
    },
    ValidationValues () {
      return {
        // height
        рз_1: {
          min: this.isChild ? 86.36 : 133.9,
          max: this.isChild ? 182.88 : 210,
        },
        // bustCircumference
        рз_16: {
          min: this.isChild ? 50.8 : this.mannequinType === 'FEMALE' ? 77.47 : 76.2,
          max: this.isChild ? 93.98 : this.mannequinType === 'FEMALE' ? 205.74 : 203.2,
        },
        // underBustCircumference
        рз_17: {
          min: this.mannequinType === 'FEMALE' ? 64.14 : +(this.fitOptionsWithDefaultValues.рз_16 - 35).toFixed(2),
          max: this.mannequinType === 'FEMALE' ? 189.87 : +(this.fitOptionsWithDefaultValues.рз_16).toFixed(2),
        },
        // upperArmCircumference
        рз_28: {
          min: this.isChild ? 15.24 : this.mannequinType === 'FEMALE' ? 26.35 : 26.67,
          max: this.isChild ? 30.48 : this.mannequinType === 'FEMALE' ? 65.42 : 64.77,
        },
        // waistCircumference
        рз_18: {
          min: this.isChild ? 46.99 : this.mannequinType === 'FEMALE' ? 60.96 : 62.23,
          max: this.isChild ? 83.82 : this.mannequinType === 'FEMALE' ? 191.77 : 201.93,
        },
        // hipsCircumference
        рз_20: {
          min: this.isChild ? 48.26 : this.mannequinType === 'FEMALE' ? 85.09 : 74.93,
          max: this.isChild ? 96.52 : this.mannequinType === 'FEMALE' ? 212.09 : 199.39,
        },
        // thighCircumference
        рз_21: {
          min: this.isChild ? 30.48 : this.mannequinType === 'FEMALE' ? 49.21 : 46.36,
          max: this.isChild ? 55.88 : this.mannequinType === 'FEMALE' ? 117.17 : 97.79,
        },
        // kneeCircumference
        рз_22: {
          min: this.isChild ? 20.32 : this.mannequinType === 'FEMALE' ? 31.75 : 31.12,
          max: this.isChild ? 40.64 : this.mannequinType === 'FEMALE' ? 73.66 : 69,
        },
        // neckCircumference
        рз_13: {
          min: this.isChild ? 25.4 : this.mannequinType === 'FEMALE' ? 34.29 : 33.66,
          max: this.isChild ? 43.18 : this.mannequinType === 'FEMALE' ? 57.79 : 67.63,
        },
        // ankleCircumference
        рз_24: {
          min: this.isChild ? 13.97 : null,
          max: this.isChild ? 26.67 : null,
        },
        // shoulderWidth
        рз_31: {
          min: this.mannequinType === 'FEMALE' ? 10.16 : 13.02,
          max: this.mannequinType === 'FEMALE' ? 17.78 : 22.86,
        },
        // wristCircumference
        рз_29: {
          min: this.isChild ? 10.16 : this.mannequinType === 'FEMALE' ? 13.65 : 15.88,
          max: this.isChild ? 20.32 : this.mannequinType === 'FEMALE' ? 28.58 : 24.89,
        },
        // shoulderDiameter
        рз_53: {
          min: this.isChild ? 20.32 : this.mannequinType === 'MALE' ? 35.56 : null,
          max: this.isChild ? 43.18 : this.mannequinType === 'MALE' ? 66.04 : null,
        },
        // backWidth
        рз_47: {
          min: this.mannequinType === 'FEMALE' ? 31.75 : 32.37,
          max: this.mannequinType === 'FEMALE' ? 58.42 : 67,
        },
        // bustHeight
        рз_35: {
          min: this.mannequinType === 'FEMALE' ? 30 : 25,
          max: this.mannequinType === 'FEMALE' ? 73 : 55,
        },
        // frontLength
        рз_36: {
          min: this.mannequinType === 'FEMALE' ? 44 : 45,
          max: this.mannequinType === 'FEMALE' ? 95 : 75,
        },
        // backLength
        рз_40: {
          min: this.mannequinType === 'FEMALE' ? 35 : this.mannequinType === 'MALE' ? 36 : 20.32,
          max: this.mannequinType === 'FEMALE' ? 53 : this.mannequinType === 'MALE' ? 57 : 45.72,
        },
        // bellyProtuberance
        рз_belly_protuberance: {
          min: 0,
          max: 1,
        },
        // buttocks
        рз_buttocks: {
          min: 0,
          max: 1,
        },
      }
    },
    isInputValid () {
      return this.params.every(item => {
        return this.fitOptionsWithDefaultValues[item.uid] !== undefined && isInputValid(this.ValidationValues[item.uid] && this.ValidationValues[item.uid].min && this.ValidationValues[item.uid].max ? this.ValidationValues[item.uid] : item, this.fitOptionsWithDefaultValues[item.uid])
      })
    },
    // оцпии, введенные пользователем + дополненные опциями, у которых были переданы значения по-умолчанию
    fitOptionsWithDefaultValues () {
      return Object.fromEntries(this.params.map(option => ([
        option.uid,
        this.fitOptions[option.uid] || this.fitOptions[option.uid] === 0 ? this.fitOptions[option.uid] : this.getFitOption(option.uid),
      ])))
    },
    hasSavedManneqins () {
      return !!this.savedManneqinsIds.length
    },
    savedManneqinsDropdown () {
      const options = [
        { code: 'notSelected', name: 'Select a Saved Measurement Profile' },
      ]
      for (let i = 0; i < this.savedManneqinsIds.length; i++) {
        options.push({
          code: this.savedManneqinsIds[i],
          name: this.savedManneqinsNames[i],
        })
      }
      return options
    },
    selectedManneqin () {
      if (this.savedManneqinsIds.includes(this.mannequinId)) return this.mannequinId
      return 'notSelected'
    },
  },

  created () {
    if (this.mannequinName) {
      this.name = this.mannequinName
    }
    this.type = this.mannequinType
  },

  watch: {
    mannequinName (val) {
      this.name = val
    },
    name (val, oldval) {
      this.changedEmit()
    },
    isValid (val) {
      this.$emit('errorValidations', val)
    },
  },

  methods: {
    changedEmit () {
      if (this.isEditPage) {
        let params = {
          fitOptions: Object.keys(this.fitOptionsWithDefaultValues).map(uid => ({
            uid,
            value: this.fitOptionsWithDefaultValues[uid],
          })),
          name: this.name,
          mannequinType: this.type,
        }
        this.$emit('changed', params)
      }
    },
    makeError (val) {
      if (val) {
        this.validationError = true
      } else this.validationError = false
    },
    updateMannequinType (val) {
      this.type = val
      this.$emit('mannequin-type-updated', val)
    },
    selectMannequin (e) {
      const selectedManneqin = this.savedManneqinsDropdown.find(m => m.code === e)
      if (selectedManneqin.code !== 'notSelected') {
        this.$emit('loadQueryParams', {
          measurements: JSON.stringify({
            mannequinId: selectedManneqin.code,
            mannequinName: selectedManneqin.name,
          }),
          permissions: JSON.stringify({
            editMeasurements: 'all',
            changeMannequinType: false,
            measurementsEditor: true,
          }),
          units: JSON.stringify({ unit: 'in', format: 'common' }),
        })
        this.name = selectedManneqin.name
      } else {
        this.$emit('loadQueryParams', null)
        this.name = this.mannequinName || 'New Measurement Profile'
      }
    },
    // получить опцию по-умолчанию из переданных параметров
    getFitOption (optionId) {
      return this.params.find(item => item.uid === optionId)?.value
    },
    setOption (optionId, value) {
      this.$set(this.fitOptions, optionId, value)
      this.changedEmit()
    },
    save () {
      if (this.editMode === EDIT_MODES.NONE) {
        return
      }
      if (!this.$refs.fieldName.validate()) {
        return
      }
      if (!this.isInputValid) {
        this.$emit('error', { title: 'Error', message: 'Input is not valid. Please recheck the fields.' })
        return
      }

      // Валидация сохранённых манекенов
      if (this.hasSavedManneqins) {
        if (Object.keys(this.fitOptions).length > 0) {
          // Если хотя бы в одно поле были внесены измения должно быть создано новое имя для модели
          const index = this.savedManneqinsIds.indexOf(this.mannequinId)
          if (this.name === this.savedManneqinsNames[index]) {
            this.$emit('error', { title: 'Error', message: 'You have changed measurements to a saved Measurements Profile.  To save these changes, please create a new name.' })
            return
          }
          // Если пользователь вводит имя, которое уже есть в дропдауне
          if (this.savedManneqinsNames.includes(this.name)) {
            this.$emit('error', { title: 'Error', message: 'This name is used for another Measurement Profile.  Please create a new Measurement Profile name.  To edit saved Measurement Profiles, please visit the My Account page.' })
            return
          }
        }
      }

      // опции, введенные пользователем + опции по умолчанию
      let params = {
        fitOptions: Object.keys(this.fitOptionsWithDefaultValues).map(uid => ({
          uid,
          value: this.fitOptionsWithDefaultValues[uid],
        })),
        name: this.name,
        mannequinType: this.type,
      }
      this.$emit('save', params)
    },
    cancel () {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
.FitFormTable {
  background:$custom-black;
  color:white;
  border-radius:0;
}
@media (max-width: 600px) {
  .q-dialog__inner--minimized > div {
    min-width: 90%;
  }
}
</style>
