<template>
  <div class="PageLoader bg-white">
    <q-spinner-dots
      color="secondary"
      :size="$q.screen.gt.xs ? '15rem' : '10rem'"
    />
  </div>
</template>

<script>
export default {
  name: 'PageLoader',
}
</script>

<style lang="scss">
  @import "src/styles/global-z-indexes";

  .PageLoader {
    position:        fixed;
    z-index:         $z-page-loader;
    left:            0;
    right:           0;
    bottom:          0;
    top:             0;
    display:         flex;
    justify-content: center;
    align-items:     center;
  }
</style>
