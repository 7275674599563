<template>
  <div class="fit-charts">
    <h1>
      <span>Fit charts</span>
      <ButtonIcon class="primary small" @click.native="$router.push({ name: 'fitChartsNew' })">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M10 4.75a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25v4.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5.5a.75.75 0 0 0-.75-.75z"/>
        </svg>
      </ButtonIcon>
    </h1>
    <TopPanel
      @search="fitChartsStore.search"
      @sort-order="fitChartsStore.setSortOrder"
      @sort-option="fitChartsStore.sort"
      @create-new="$router.push({ name: 'fitChartsNew' })"
      :add-new-button="$route.name === 'fitChartsCustom'"
      :add-new-button-disabled="demoMode"
      :sort-options="sortOptions"
    >
      <template v-slot:left-side>
        <div>
          <ButtonsGroup class="custom-standart">
            <button
              :class="[$route.name === 'fitChartsCustom' ? 'active' : '']"
              @click="toggleCharts('fitChartsCustom')"
            >
              Custom
            </button>
            <button
              :class="[$route.name === 'fitChartsStandart' ? 'active' : '']"
              @click="toggleCharts('fitChartsStandart')"
            >
              Standart
            </button>
          </ButtonsGroup>
          <ButtonsGroup class="standart">
            <button
              v-for="manType in fitChartsStore.types"
              :key="manType.key"
              :class="[fitChartsStore.params.type === manType.key ? 'active' : '']"
              @click="fitChartsStore.setMannequinType(manType.key, $route.name)"
            >
              {{ manType.title }}
            </button>
          </ButtonsGroup>
        </div>
      </template>
      <template v-slot:button-text>New</template>
    </TopPanel>
    <template v-if="$route.name === 'fitChartsCustom'">
      <EmptyGrid v-if="demoMode" :buttonPlusIcon="false" @create-new="$router.push({ name: 'user_billing' })">
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 33">
            <path d="m27.8 10.3-5.7-5.7c-.8-.8-2.1-.8-2.9 0l-15 15.1c-.4.4-.6.9-.6 1.5s.2 1.1.6 1.5l5.7 5.7c.4.4.9.6 1.5.6.5 0 1.1-.2 1.5-.6L28 13.3c.4-.4.6-.9.6-1.5-.1-.6-.4-1.1-.8-1.5zm-1 1.9L11.7 27.3c-.2.2-.6.2-.8 0l-5.7-5.7c-.1-.1-.2-.2-.2-.4s.1-.3.2-.4l3.2-3.2 2.3 2.3c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-2.3-2.3 2.7-2.7 2.3 2.3c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-2.3-2.3L16 10l2.3 2.3c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L17.1 9l3.2-3.2c.1-.1.3-.2.4-.2.2 0 .3.1.4.2l5.7 5.7c.1.1.2.3.2.4 0 0-.1.2-.2.3z"/>
          </svg>
        </template>
        <template v-slot:title>Custom fit charts are not available for your subscription</template>
        <template v-slot:text>Please, upgrade your account</template>
        <template v-slot:button-text>Upgrade account</template>
      </EmptyGrid>
      <EmptyGrid @create-new="$router.push({ name: 'fitChartsNew' })" v-else-if="fitChartsStore.customCharts.count === 0">
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 33">
            <path d="M20.715 4.05a2.08 2.08 0 0 0-1.475.606L15.47 8.428l-3.766 3.765a.75.75 0 0 0-.006.004.75.75 0 0 0-.004.006L7.928 15.97 4.156 19.74a2.096 2.096 0 0 0 0 2.948l5.657 5.656c.807.807 2.14.807 2.947 0l15.084-15.086a2.094 2.094 0 0 0 0-2.945l-5.657-5.657a2.076 2.076 0 0 0-1.472-.605zm-.002 1.491c.148 0 .297.059.414.176l5.656 5.656a.571.571 0 0 1 0 .824L11.7 27.283a.574.574 0 0 1-.826 0l-5.656-5.656a.574.574 0 0 1 0-.826l3.24-3.24 2.299 2.298a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L9.518 16.5l2.71-2.71 2.3 2.298a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-2.299-2.3L16 10.019l2.299 2.298a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-2.298-2.299 3.24-3.24a.58.58 0 0 1 .412-.176z"/>
          </svg>
        </template>
        <template v-slot:title>Your team haven't created any fit chart yet</template>
        <template v-slot:text>You can create a fit chart from scratch or customize standard charts from our templates</template>
        <template v-slot:button-text>Create new fit chart</template>
      </EmptyGrid>
      <ElementsGrid v-else>
        <OneCustomFitChart
          v-for="fitChart in fitChartsStore.customCharts.list"
          :key="fitChart._id"
          :fit-chart="fitChart"
          @edit="$router.push({ name: 'fitChartsEdit', params: { id: fitChart._id } })"
          @delete="delModal"
        />
      </ElementsGrid>
    </template >
    <template v-if="$route.name === 'fitChartsStandart'">
      <ButtonPrimary
        v-if="userStore.data.company._id === 1"
        style="margin-top: 40px;"
        @click.native="$router.push({ name: 'fitChartsNewStandart' })"
      >
        Create new Standart Fit Chart
      </ButtonPrimary>
      <ElementsGrid>
        <OneFitChart
          v-for="fitChart in fitChartsStore.standartCharts.list"
          :key="fitChart._id"
          :fit-chart="fitChart"
          @edit="$router.push({ name: 'fitChartsEditStandart', params: { id: fitChart._id } })"
          @delete="fitChartsStore.deleteChartStandart"
        />
      </ElementsGrid>
    </template>
    <ModalComponent @close="fitChartsStore.delChartModalToggle()" :show="fitChartsStore.delChartModal" type="error">
      <template v-slot:title>Are you sure you want to delete this size chart? </template>
      <template v-slot:description>You can not undo this action</template>
      <template v-slot:buttons>
        <ButtonSecondary @click.native="fitChartsStore.delChartModalToggle()">Cancel</ButtonSecondary>
        <ButtonPrimary @click.native="fitChartsStore.deleteChart()">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
              <path d="M8 1.75c-.866 0-1.584.718-1.584 1.584v.916H3.834a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h.916v10.916c0 .866.718 1.584 1.584 1.584h8.332c.866 0 1.584-.718 1.584-1.584V5.75h.918a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-2.586v-.916c0-.866-.716-1.584-1.582-1.584H8zm0 1.5h5c.055 0 .082.03.082.084v.916H7.916v-.916c0-.055.03-.084.084-.084zm-1.75 2.5h8.5v10.916c0 .055-.03.084-.084.084H6.334c-.055 0-.084-.03-.084-.084V5.75z"/>
            </svg>
          </template>
          Delete chart
        </ButtonPrimary>
      </template>
    </ModalComponent>
    <ExpiredDemoMode />
    <PaginationComponent
        :page="fitChartsStore.params.page"
        :per-page="fitChartsStore.params.per_page"
        :total="$route.name === 'fitChartsStandart' ? fitChartsStore.standartCharts.count : fitChartsStore.customCharts.count"
        @setPage="setPage"
      />
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFitChartsStore } from '@/store/pinia/fitCharts'
import { useUserStore } from '@/store/pinia/user'

import TopPanel from '@/components/UserCollections/TopPanel.vue'
import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'
import ButtonIcon from '@/components/Atoms/ButtonIcon.vue'
import EmptyGrid from '@/components/Atoms/EmptyGrid.vue'
import ElementsGrid from '@/components/Atoms/ElementsGrid.vue'
import OneCustomFitChart from '@/components/fitCharts/OneCustomFitChart.vue'
import OneFitChart from '@/components/fitCharts/OneFitChart.vue'
import PaginationComponent from '@/components/Atoms/PaginationComponent.vue'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ExpiredDemoMode from '@/components/ExpiredDemoMode.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FitCharts',
  components: {
    ExpiredDemoMode,
    TopPanel,
    ButtonsGroup,
    ButtonIcon,
    EmptyGrid,
    ElementsGrid,
    OneCustomFitChart,
    OneFitChart,
    PaginationComponent,
    ModalComponent,
    ButtonPrimary,
    ButtonSecondary,
  },
  computed: {
    ...mapStores(useFitChartsStore),
    ...mapStores(useUserStore),
    ...mapGetters('user', ['demoMode']),
  },
  data: () => ({
    sortOptions: [
      { name: 'Sort by', value: '' },
      { name: 'Name', value: 'name' },
      { name: 'Updated', value: 'updatedAt' },
    ],
  }),
  methods: {
    async getList () {
      if (this.$route.name === 'fitChartsCustom') await this.fitChartsStore.getList()
      if (this.$route.name === 'fitChartsStandart') await this.fitChartsStore.getListStandart()
    },
    delModal (payload) {
      this.fitChartsStore.delChartModalToggle()
      this.fitChartsStore.chartId = payload._id
    },
    async toggleCharts (chartsType) {
      this.$router.push({ name: chartsType })
      this.fitChartsStore.isCustom = this.$route.name === 'fitChartsCustom'
      await this.getList()
    },
    async setPage (pageNum) {
      this.fitChartsStore.params.page = pageNum
      await this.getList()
    },
  },
  async beforeMount () {
    this.fitChartsStore.params.type = 'FEMALE'
    this.fitChartsStore.isCustom = this.$route.name === 'fitChartsCustom'
    await this.getList()
  },
}
</script>

<style lang="scss">
.fit-charts {
  padding: 0 16px 40px 40px;

  h1 {
    color: var(--text_color);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      display: none;
    }
  }

  .buttons-group.custom-standart {
    max-width: 200px;
  }
  .buttons-group.standart {
    margin-top: 16px;
  }
}

@media (max-width: 760px) {
  .fit-charts {
    padding: 0 0 40px 0 !important;

    h1 {
      font-size: 24px !important;
      button {
        display: block;
      }
    }

    .buttons-group.custom-standart {
      width: 100%;
      max-width: none;
    }

    .buttons-group.standart {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
      row-gap: 8px;

      button {
        border-radius: 8px;
      }
    }

    .button-sort {
      margin-right: 0 !important;
    }

    .user-top-panel {
      .right-side {
        column-gap: 8px;

        .search-field {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
