<template>
  <div>
    <Dropdown
      :options="selectOptions"
      v-model="model"
      :label="label"
      option-label="label"
      placeholder="Choose..."
      :disabled="disabled"
      small-label
    >
    </Dropdown>
    <!-- <q-select
      v-model="model"
      dense
      options-dense
      outlined
      :readonly="readonly"
      :behavior="$q.screen.lt.sm ? 'dialog' : 'menu'"
      :label="label"
      :options="selectOptions"
      dropdown-icon='expand_more'
    >
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
          <q-item-section :title="scope.opt.disable ? scope.opt.title : null">
            <q-item-label>{{ scope.opt.label }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select> -->
  </div>
</template>

<script>
import { hasProperties } from '@/helpers'
import Dropdown from '@/components/Atoms/Dropdown.vue'

export default {
  name: 'AttributeSettingsSelect',

  components: {
    Dropdown,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    activeElement: {
      type: String,
    },
    attributes: {
      type: Array,
      required: true,
      validator: items => items.every(item => hasProperties(item, ['code', 'name'])),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    model: {
      get () {
        return this.activeOption
      },
      set (val) {
        this.$emit('change', val.value)
      },
    },
    selectOptions () {
      return this.attributes.map(item => ({
        label: item.name,
        value: item.code,
        disable: item.disable,
        title: item.title,
      }))
    },
    activeOption () {
      return this.selectOptions.find(item => item.value === this.activeElement)
    },
  },

  watch: {
    activeOption (val) {
      if (val.disable) {
        const notActive = this.selectOptions.filter(item => item.value !== this.activeElement && !item.disable)
        this.$emit('change', notActive[0].value)
      }
    },
  },
}
</script>

<style lang="scss">
.PageDesigner__attributeSettingsSelect .base-dropdown {
  min-width: 140px;
  &__content {
    max-height: fit-content;
    overflow: auto;
  }
}
</style>
