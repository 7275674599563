<template>
  <layout-base
    ref="layout"
    no-left-sidebar
    no-right-sidebar
  >
    <q-page class="PageMeasurements row" :style="currentMode === 'measurements' ? `min-height: ${pageHeight}px;` : ''">
      <ease-component
        v-if="Object.keys(designOptions).length && designFabric"
        v-show="currentMode === 'ease'"
        class="col-sm-12 offset-md-1 col-md-10 offset-lg-3 col-lg-6"
        style="height:auto;"
        :design-options="designOptions"
        :design-fabric="designFabric"
        @changed="updateEaseOptions"
        @error="error($event.title, $event.message)"
        @close="cancelEdit"
        @save="save"
        isEditPage
      />
      <fit-editor-component
        v-show="currentMode === 'measurements'"
        class="offset-md-1 col-md-10 offset-lg-3 col-lg-6"
        :mannequin-id="mannequinId"
        :mannequin-name="mannequinName"
        :mannequin-type-editable="canChangeMannequinType"
        :saved-manneqins-ids="savedManneqinsIds"
        :saved-manneqins-names="savedManneqinsNames"
        :src-measurements="srcMeasurements"
        :edit-mode="editMode"
        @loadQueryParams="loadQueryParams"
        @error="error($event.title, $event.message)"
        @changed="saveMeasurements"
        @errorValidations="isErrorMeasurements = $event"
        isEditPage
      />
    </q-page>
    <div
      class="row justify-center q-gutter-md relative-position"
      v-if="currentMode === 'measurements'"
    >
      <q-btn
        size="md"
        color="secondary"
        :disabled="isErrorMeasurements"
        class="black-button"
        no-caps
        @click.prevent="save"
      >
        {{ designId ? 'Next' : 'Apply' }}
      </q-btn>

      <q-btn
        outline
        size="md"
        color="primary"
        class="outline-button"
        no-caps
        @click.prevent="cancelEdit"
      >
        Cancel
      </q-btn>
    </div>
  </layout-base>
</template>

<script>
import LayoutBase from '@/layouts/LayoutBase'
import FitEditorComponent from '@/components/Fit/FitEditorComponent'
import { getUrlParams, postRedirect } from '@/helpers'
import { EDIT_MODES, getEditModeByVal } from '@/components/Fit/measurements'
import { cancelMeasurementsSave, saveMeasurements, getSavedMannequins, fetchDesign, fetchCategoryElementsData } from '@/api'
import { mapActions } from 'vuex'
import { MeasurementsMannequin, MeasurementsStandartSize } from '@/components/Fit/fitEditorProps'

import EaseComponent from '@/components/Ease/EaseComponent'

export default {
  name: 'PageMeasurements',

  components: {
    FitEditorComponent,
    LayoutBase,
    EaseComponent,
  },

  data: () => ({
    currentMode: 'measurements',
    mannequinId: undefined,
    mannequinName: undefined,
    editMode: EDIT_MODES.ANY,
    canChangeMannequinType: false,
    srcMeasurements: undefined,
    savedManneqins: undefined,
    savedManneqinsIds: [],
    savedManneqinsNames: [],
    designFabric: undefined,
    designId: undefined,
    designOptions: {},
    isErrorMeasurements: true,
    params: {},
    pageHeight: document.documentElement.clientHeight - 100,
  }),

  async created () {
    window.addEventListener('resize', this.handleResize)
    this.loadQueryParams()
    this.savedManneqins = await getSavedMannequins()
    this.savedManneqins.forEach(m => {
      this.savedManneqinsIds.push(m.mannequinId)
      this.savedManneqinsNames.push(m.mannequinName)
    })
  },

  mounted () {
    window.pageMeasurements = this
  },

  methods: {
    handleResize () {
      this.pageHeight = document.documentElement.clientHeight - 100
    },
    async getDesignElements () {
      let { design } = await fetchDesign(this.designId)
      this.designOptions = {
        elementIds: design.elements,
        designCategory: design.categoryCode,
        mannequinId: this.mannequinId,
        seamAllowance: design.seamAllowance,
        fit: design.fit,
        fabricType: design.fabricType,
        eases: design.ease,
        settingCodes: design.elementsSettings,
      }
      let designElements = await fetchCategoryElementsData(design.categoryCode, design.elements, design.elementsSettings)
      if (designElements.length > 0) {
        let isSilhouettes = designElements.find(el => el.node.code.includes('silhouettes'))
        let foundFabricItem
        if (isSilhouettes) {
          foundFabricItem = isSilhouettes.element.attributes.find(attr => attr.type === 'fabric_type')
        } else {
          foundFabricItem = designElements[0].element.attributes.find(attr => attr.type === 'fabric_type')
        }
        if (foundFabricItem.code === 'fabric:woven') {
          this.designFabric = 'woven'
        }
        if (foundFabricItem.code.indexOf('fabric:knit') === 0) {
          this.designFabric = 'knit'
        }
      }
    },
    updateEaseOptions ({ easeOptions, fit, fabricType }) {
      Object.keys(easeOptions).forEach(el => {
        easeOptions[el] = Number(easeOptions[el].toFixed(2))
      })
      this.params.easeOptions = easeOptions
      this.params.fit = fit
      this.params.fabricType = fabricType
      this.params.designId = this.designId
    },
    loadQueryParams (localParams = null) {
      let params = localParams || getUrlParams()
      window.pageMeasurements = this
      if (params.designId) {
        this.designId = params.designId
      }
      if (params.measurements) {
        let measurements = JSON.parse(params.measurements)
        if (measurements.mannequinId) {
          this.srcMeasurements = new MeasurementsMannequin(measurements.mannequinId)
          this.mannequinId = measurements.mannequinId
        }
        if (measurements.mannequinName) {
          this.mannequinName = measurements.mannequinName
        }
        if (measurements.size) {
          this.srcMeasurements = new MeasurementsStandartSize(measurements.size, measurements.type)
        }
      }
      if (params.units) {
        let units = JSON.parse(params.units)
        if (units?.unit && units?.format) {
          this.setUnitsIfNoUserPreferences({
            units: units?.unit,
            fractionBase: units.format === 'decimal' ? 10 : 8,
          })
        }
      }
      if (!params.permissions) {
        this.error('Invalid params', 'Params must include permissions')
      }
      let permissions = JSON.parse(params.permissions)
      if (permissions.editMeasurements) {
        this.editMode = getEditModeByVal(permissions.editMeasurements)
      }
      if (permissions.changeMannequinType) {
        this.canChangeMannequinType = !!permissions.changeMannequinType
      }
    },
    error (title, message) {
      this.$emit('error', { title, message })
    },
    saveMeasurements ({ measurements, name, mannequinType }) {
      this.params.sizeParams = measurements
      this.params.mannequinName = name
      this.params.mannequinType = mannequinType
      this.mannequinName = name
    },
    async save () {
      try {
        if (this.designId && this.currentMode === 'measurements') {
          if (Object.keys(this.params).length > 0) {
            let { tokenData } = await saveMeasurements({ ...this.params, currentMode: this.currentMode })
            if (tokenData.mannequinId) this.mannequinId = tokenData.mannequinId
            this.params = {}
          }
          await this.getDesignElements()
          this.currentMode = 'ease'
        } else {
          this.params.mannequinName = this.mannequinName
          let { returnUrl, token } = await saveMeasurements({ ...this.params, currentMode: this.currentMode, mannequinId: this.mannequinId })
          if (!returnUrl || !token) {
            this.error('Error', 'An error occurred during saving measurements')
            return
          }
          this.redirectAfterSave(returnUrl, token)
        }
      } catch (e) {
        this.error('Error', e.message)
      }
    },
    async cancelEdit () {
      try {
        let { returnUrl, token } = await cancelMeasurementsSave()
        console.log({ returnUrl, token })
        this.redirectAfterSave(returnUrl, token)
      } catch (e) {
        this.error('Error', e.message)
      }
    },
    redirectAfterSave: postRedirect,
    ...mapActions(['setUnitsIfNoUserPreferences']),
  },
}
</script>

<style lang="scss">
@import "src/styles/variables";
.PageMeasurements {
  display: flex;

  &__logo {
    display: flex;
  }
  .DialogCardBase__closeButton {
    display: none;
  }
  .q-card {
    box-shadow: none;
  }
}
</style>
