<template>
  <ButtonsGroup class="clothing-buttons">
    <button
      v-for="button in fitChartsStore.types"
      :key="button.key"
      :class="[fitChartsStore.newChart.type === button.key ? 'active' : '']"
      @click="change(button.key)"
    >
      {{ button.title }}
    </button>
  </ButtonsGroup>
</template>

<script>
import { mapStores } from 'pinia'
import { useFitChartsStore } from '../../store/pinia/fitCharts'

import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'

export default {
  name: 'ChartEditTypesSelect',
  components: {
    ButtonsGroup,
  },
  computed: mapStores(useFitChartsStore),
  methods: {
    change (value) {
      this.fitChartsStore.newChart.type = value
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss">
.fit-charts-new {
  .clothing-buttons {
    width: 100%;
    max-width: 926px;
    margin-bottom: 32px;
  }
}
</style>
