<template>
  <layout-user>
    <div class="ease_page-container">
      <div class="ease_page">
        <div class="all" ref="all">
          <div class="back" @click="closeButton">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 8L10 12L14 16" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Back
          </div>
          <div class="title-block">
            <h2>Adjust</h2>
            <div class="input-block red">
              <Dropdown
                :options="fitChartsStore.selectedChart?.mannequins"
                v-model="fitChartsStore.selectedMannequin"
                option-label="name"
                @input="selectSize"
                placeholder="Choose sample size"/>
            </div>
          </div>
          <div :class="`preview-mob ${previewLoading || previewError ? 'flex' : ''}`">
            <div v-if="previewLoading" class="preview-mob__loading">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                <g clip-path="url(#clip0_719_13172)">
                  <path d="M21 10.5V5.25" stroke="#09373D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.5 21H5.25" stroke="#09373D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                    d="M13.5623 13.5625L9.7998 9.79999" stroke="#09373D" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_719_13172">
                    <rect width="42" height="42" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div v-else-if="previewError" class="preview-mob__error">
              <p>Unable to create a pattern with the given adjustments. Please reset your changes and try again.</p>
            </div>
            <div class="preview-mob__svg-block" v-else-if="preview" v-html="preview" />
          </div>
          <EaseComponent
            :design-options="designOptions"
            :design-fabric="this.$route.query.designFabric"
            @apply="updateEaseOptions($event)"
            @close="closeButton"
            @stop="stopScroll($event)"
          />
        </div>
        <div :class="`preview ${previewLoading || previewError ? 'flex' : ''}`">
          <div v-if="previewLoading" class="preview__loading" :style="`left:${fixedBlockStyle}px`">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
              <g clip-path="url(#clip0_719_13172)">
                <path d="M21 10.5V5.25" stroke="#09373D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 21H5.25" stroke="#09373D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                  d="M13.5623 13.5625L9.7998 9.79999" stroke="#09373D" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_719_13172">
                  <rect width="42" height="42" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div v-else-if="previewError" class="preview__error" :style="`left:${fixedBlockStyle}px`">
            <p>Unable to create a pattern with the given adjustments. Please reset your changes and try again.</p>
          </div>
          <div
            v-else-if="preview"
            ref="scrollImg"
            :class="`preview__img ${isStopScroll ? 'stop' : ''}`"
            :style="`left:${fixedBlockStyle}px; top:${isStopScroll}`"
            v-html="preview"
          />
        </div>
      </div>
    </div>
  </layout-user>
</template>

<script>
import LayoutUser from '@/layouts/LayoutUser'
import EaseComponent from '@/components/EaseNew/EaseComponent'
import Dropdown from '@/components/Atoms/Dropdown.vue'

import { mapStores } from 'pinia'
import { useFitChartsStore } from '@/store/pinia/fitCharts'

import {
  fetchPatternsPreview,
  createMannequin,
} from '@/api'

export default {
  components: {
    LayoutUser,
    EaseComponent,
    Dropdown,
  },
  data: () => ({
    isStopScroll: null,
    designOptions: null,
    saveEase: {},
    preview: null,
    previewLoading: false,
    previewError: false,
  }),
  computed: {
    ...mapStores(useFitChartsStore),
    fixedBlockStyle () {
      const allElement = this.$refs.all
      const rect = allElement.getBoundingClientRect()
      return rect.right + 56
    },
  },
  beforeMount () {
    this.saveEase = JSON.parse(this.$route.query.mannequinEase) ? JSON.parse(this.$route.query.mannequinEase) : {}
    const option = JSON.parse(this.$route.query.designOptions)
    const mannequinIdNew = this.$route.query.mannequinIdNew
    if (this.saveEase?.[mannequinIdNew]) {
      this.designOptions = {
        ...option,
        eases: this.saveEase?.[mannequinIdNew].ease,
        fabricType: this.saveEase?.[mannequinIdNew].fabricType,
        fit: this.saveEase?.[mannequinIdNew].fit,
      }
    } else this.designOptions = option
  },
  async mounted () {
    this.fitChartsStore.isCustom = this.$route.query.fitChartsIsCustom === 'true'
    if (!this.fitChartsStore.selectedChart) {
      await this.fitChartsStore.getOneSelected(this.$route.query.fitChartsId)
    }
    this.fitChartsStore.setNewMennequin(this.$route.query.mannequinId, this.$route.query.mannequinIdNew)
    this.fitChartsStore.selectedMannequinId = this.$route.query.mannequinIdNew

    this.fetchPreview()
  },
  methods: {
    async selectSize (val) {
      this.fitChartsStore.selectedMannequinId = val.mannequinId
      if (this.saveEase?.[val.mannequinId]) {
        this.designOptions.eases = Object.assign({}, this.saveEase[val.mannequinId].ease)
        this.designOptions.fabricType = this.saveEase[val.mannequinId].fabricType
        this.designOptions.fit = this.saveEase[val.mannequinId].fit
      } else {
        this.designOptions = JSON.parse(this.$route.query.designOptions)
      }
      if (val.mannequinIdNew) this.designOptions.mannequinId = val.mannequinIdNew
      else {
        const { mannequinId } = await createMannequin(val.params, this.$route.query.type)
        if (mannequinId) {
          this.fitChartsStore.selectedMannequin.newMennequinId = mannequinId
          this.fitChartsStore.setNewMennequin(val.mannequinId, mannequinId)
          this.designOptions.mannequinId = mannequinId
        }
      }
      this.fetchPreview()
    },
    stopScroll (val) {
      const img = this.$refs.scrollImg
      if (img) {
        if (val) {
          const rect = img.getBoundingClientRect()
          const distanceToTop = rect.top + window.scrollY
          this.isStopScroll = distanceToTop + 'px'
        } else {
          img.style.top = ''
          this.isStopScroll = null
        }
      } else {
        this.isStopScroll = '30px'
      }
    },
    fetchPreview () {
      this.previewLoading = true
      const fontName = ''
      console.log(this.fitChartsStore.selectedMannequin)
      let mannequinId = this.fitChartsStore.selectedMannequin?.newMennequinId || this.designOptions.mannequinId
      fetchPatternsPreview(
        this.designOptions.internalLines,
        this.designOptions.elementIds,
        mannequinId,
        this.designOptions.fabricType,
        this.designOptions.fit,
        this.designOptions.seamAllowance,
        this.designOptions.eases,
        this.designOptions.settingCodes,
        fontName,
        true,
        false,
        true,
      )
        .then((svg) => {
          this.previewLoading = false
          this.preview = svg
        })
        .catch((e) => {
          this.previewLoading = false
          this.previewError = true
        })
    },
    updateEaseOptions (el) {
      this.saveEase[this.fitChartsStore.selectedMannequin.mannequinId] = {
        ease: el.ease,
        fit: el.fit.value,
        fabricType: el.fabricType.value,
      }
      this.designOptions.eases = el.ease
      this.designOptions.fit = el.fit.value
      this.designOptions.fabricType = el.fabricType.value
      this.fetchPreview()
    },
    closeButton () {
      if (this.$route.query.page === 'main') {
        const rp = JSON.parse(this.$route.query.designOptions)
        rp.mannequinEase = this.saveEase
        this.$router.push(
          {
            path: '/designer',
            query: {
              params: JSON.stringify(rp),
              type: this.$route.query.type,
              category: this.$route.query.category,
              size: rp.size,
              isCustomModel: this.$route.query.isCustomModel,
              fitChartsId: this.$route.query.fitChartsId,
              mannequinIdNew: this.$route.query.mannequinIdNew,
              fitChartsIsCustom: this.$route.query.fitChartsIsCustom,
            },
          },
        )
      } else if (this.$route.query.page === 'auto-grading') {
        let params = {
          ...JSON.parse(this.$route.query.params),
          ...JSON.parse(this.$route.query.designOptions),
          ...JSON.parse(this.$route.query.mannequinEase),
          mannequinEase: this.saveEase,
        }
        this.$router.push({
          name: 'auto-grading',
          query: {
            params: JSON.stringify(params),
            type: this.$route.query.type,
            category: this.$route.query.category,
            designFabric: this.$route.query.designFabric,
            isCustomModel: this.$route.query.isCustomModel,
            fitChartsId: this.$route.query.fitChartsId,
            mannequinIdNew: this.$route.query.mannequinIdNew,
            fitChartsIsCustom: this.$route.query.fitChartsIsCustom,
            t: +Date.now(),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.ease_page-container {
  position: relative;
}
.ease_page {
  padding: 16px;
  max-width: 1440px;
  margin: auto;
  color: var(--text_color, #1D1F29);
  font-size: 14px;
  display: flex;
  .all {
    width: 100%;
  }
  .preview-mob {
    margin: 16px 0;
    height: 400px;
    align-items: center;
    justify-content: center;
    display: none;
    &__svg-block {
      height: 400px;
    }
  }
  .preview {
    width: 400px;
    min-height: 100%;
    border-left: 1px solid var(--Dividers);
    margin: -16px 0 -16px 40px;
    padding: 210px 10px;
    &__img.stop {
      position: absolute;
    }
    &__img {
      position: fixed;
      right: 16px;
      height: calc(100vh - 440px);
      top: 120px;
    }
    &__loading {
      position: fixed;
      right: 16px;
      height: calc(100vh - 440px);
      top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        animation: spin 1.5s linear infinite;
      }
    }
    &__error {
      position: fixed;
      right: 16px;
      height: calc(100vh - 440px);
      top: 100px;
      color: #FF6770;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .preview.flex {
    display: flex;
    justify-content: center;
  }
  .input-block {
    .label {
      color: #828282;
      font-size: 12px;
    }
    .range {
      font-size: 12px;
      color: #828282;
    }
  }
  .back {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;
    font-weight: 600;
    width: fit-content;
    svg {
      margin-right: 8px;
    }
  }
  h2 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  h4 {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin: 0;
  }
  .title-3 {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 322px;
    margin-bottom: 16px;
    .input-block {
      margin: 0;
      width: 120px;
      margin-left: 9px;
      .label_mobile .q-field__control-container .q-field__native {
        font-weight: 600;
        font-size: 20px !important;
        font-family: Montserrat;
        line-height: normal;
      }
    }
  }
}

@media (max-width: 964px) {
  .ease_page {
    .preview.flex {
      display: none;
    }
    .preview {
      display: none;
    }
    .all {
      width: 100%;
    }
    .preview-mob {
      display: flex;
      &__loading svg {
        animation: spin 1.5s linear infinite;
      }
      &__error {
        color: #FF6770;
        text-align: center;
      }
    }
  }
}
@media (max-width: 564px) {
  .ease_page {
    h1 {
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
    }
    .title-block {
      justify-content: space-between;
      margin-bottom: 11px;
      min-width: 100%;
    }
  }
}
</style>
