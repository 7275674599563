import { validateMannequinType } from '@/components/Fit/mannequinTypes'

const MeasurementsStandartSize = function (size, type) {
  validateMannequinType(type)
  this.size = size.toUpperCase()
  this.type = type.toUpperCase()
}

const MeasurementsMannequin = function (mannequinId) {
  this.mannequinId = mannequinId
}

export {
  MeasurementsStandartSize,
  MeasurementsMannequin,
}
