import { EDIT_MODES } from '@/components/Fit/measurements'
import Vue from 'vue'
import { fetchMannequin, fetchSizeParams } from '@/api'
import { validateMannequinType } from '@/components/Fit/mannequinTypes'

const selectAvailableModes = [EDIT_MODES.BASIC, EDIT_MODES.ALL]

const state = {
  userSelectedMode: EDIT_MODES.ALL,
  // параметры по-умолчанию для разных типов манекенов
  defaultSizeParams: {},
  // размеры и тип для манекенов. Параметры содержат { uid, value }
  mannequinSizes: {
    // mannequinId: {
    //   mannequinType: 'FEMALE',
    //   sizes: [
    //     {
    //       uid: 'рз_1',
    //       value: 999,
    //     },
    //   ],
    // },
  },
  // стандартные размеры и тип. Параметры содержат { uid, value }. Ключи в upperCase
  standartSizes: {
    // FEMALE: {
    //   US6: [
    //     {
    //       uid: 'рз_1',
    //       value: 999,
    //     },
    //   ],
    // },
    isMannequinChanged: false,
  },
}

const getters = {
  // sizeParams из манекена с заполненными атрибутами. Отображаются лишь мерки, которые соответствуют данному типу манекена
  mannequinWithSizeParams: state => {
    return Object.fromEntries(
      Object.keys(state.mannequinSizes)
        .map(mannequinId => {
          let type = state.mannequinSizes[mannequinId].mannequinType
          let sizeParams = state.defaultSizeParams[type]
            .map(param => {
              let size = state.mannequinSizes[mannequinId].sizes
                .find(size => size.uid === param.uid)
              if (!size) {
                return param
              }
              return { ...param, ...size }
            })

          return [
            mannequinId,
            {
              mannequinType: type,
              sizeParams,
            },
          ]
        }),
    )
  },
}

const mutations = {
  SET_USER_MODE (state, mode) {
    if (!selectAvailableModes.includes(mode)) {
      throw Error('Invalid mode selected')
    }
    state.userSelectedMode = mode
  },
  SET_MANNEQUIN_SIZES (state, { mannequinId, sizes, mannequinType }) {
    validateMannequinType(mannequinType)
    // попытка сменить тип манекена. Запрещено.
    // todo: сменить тип манекена можно в пределах сессии, если манекен новосозданный, а не передан по апи
    // let prevType = state.mannequinSizes[mannequinId]?.mannequinType
    // if (prevType && prevType !== mannequinType) {
    //   throw Error('Changing mannequin type is not allowed')
    // }
    Vue.set(state.mannequinSizes, mannequinId, {
      mannequinType,
      sizes: sizes.map(item => ({
        uid: item.uid,
        value: item.value,
      })),
    })
  },
  SET_DEFAULT_SIZE_PARAMS (state, { mannequinType, sizeParams }) {
    validateMannequinType(mannequinType)
    Vue.set(state.defaultSizeParams, mannequinType, sizeParams)
  },
  SET_STANDART_SIZE_VALUES (state, { size, mannequinType, sizes }) {
    validateMannequinType(mannequinType)
    size = size.toUpperCase()
    let newSizes = { ...state.standartSizes[mannequinType] } || {}
    newSizes[size] = sizes
    Vue.set(state.standartSizes, mannequinType, newSizes)
  },
  SET_IS_MANNEQUIN_CHANGED (state, val) {
    state.isMannequinChanged = val
  },
}

const actions = {
  async selectEditMode ({ commit }, mode) {
    commit('SET_USER_MODE', mode)
  },
  async setMannequinSizes (
    { state, commit, dispatch },
    { mannequinId, sizeParams, mannequinType },
  ) {
    if (!state.defaultSizeParams[mannequinType]) {
      await dispatch('fetchDefaultSizeParams', { mannequinType })
    }
    commit(
      'SET_MANNEQUIN_SIZES',
      { mannequinId, sizes: sizeParams, mannequinType },
    )
  },
  async fetchDefaultSizeParams (
    { commit },
    { mannequinType },
  ) {
    validateMannequinType(mannequinType)
    commit('SET_DEFAULT_SIZE_PARAMS', {
      mannequinType,
      sizeParams: await fetchSizeParams({ mannequinType }),
    })
  },
  async fetchSizeParamsByMannequinId (
    { state, commit, dispatch },
    { mannequinId, forceUpdate = false },
  ) {
    if (
      !forceUpdate &&
      Array.isArray(state.mannequinSizes[mannequinId]?.sizeParams)
    ) {
      return
    }
    let { sizeParams, mannequinType } = await fetchMannequin(mannequinId)
    await dispatch('setMannequinSizes',
      { mannequinId, sizeParams, mannequinType })
  },
  async fetchStandartSize (
    { state, commit },
    { size, mannequinType, forceUpdate = false },
  ) {
    if (
      !forceUpdate &&
      Array.isArray(state.standartSizes[mannequinType]?.[size])
    ) {
      return
    }
    let sizes = await fetchSizeParams({ standartSize: size, mannequinType })
    commit('SET_STANDART_SIZE_VALUES', {
      size,
      mannequinType,
      sizes,
    })
  },
  markMannequinChanged ({ commit }) {
    commit('SET_IS_MANNEQUIN_CHANGED', true)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
