import { defineStore } from 'pinia'
import { request } from '../../api'

export const useTeamStore = defineStore('team', {
  state: () => ({
    team: null,
    // params: {
    //   page: 1,
    //   per_page: 20,
    //   sort: '',
    //   sort_direction: 'asc',
    //   search: '',
    // },
    cancelInvitationError: false,
    checkTokenData: null,
    checkTokenLoading: null,
    checkTokenError: false,
  }),
  getters: {},
  actions: {
    async checkToken (token) {
      try {
        this.checkTokenLoading = true
        const info = await request.get(`/user/check-token/${token}`)
        this.checkTokenData = info.data.user
        this.checkTokenLoading = false
      } catch (e) {
        this.checkTokenError = true
        this.checkTokenLoading = false
        throw Error(e)
      }
    },
    async cancelInvitation (id) {
      try {
        await request.delete(`/team/token/${id}`)
        await this.getList()
      } catch (e) {
        this.cancelInvitationError = true
        throw Error(e)
      }
    },
    async copyInviteLink (id) {
      try {
        let info = await request.get(`/team/token/${id}`)
        const link = `${window.location.origin}/join-team/${info.data.token}??firstName=${info.data.user.firstName}&lastName=${info.data.user.lastName}`
        let textarea = document.createElement("textarea")
        textarea.value = link
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand("copy")
        document.body.removeChild(textarea)
      } catch (e) {
        throw Error(e)
      }
    },
    async deleteUser (id) {
      try {
        await request.delete(`/team/${id}`)
        await this.getList()
        return true
      } catch (e) {
        throw Error(e)
      }
    },
    async createAccount (pass, token) {
      try {
        await request.post(
          '/user/join-team',
          JSON.stringify({
            token: token,
            password: pass,
          }),
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          },
        )
        return true
      } catch (e) {
        throw Error()
      }
    },
    async addUser (sendData) {
      try {
        await request.post(
          '/team',
          JSON.stringify(sendData),
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          },
        )
        await this.getList()
        return true
      } catch (e) {
        throw Error(e.response.data.message)
      }
    },
    // async setSortOrder (order) {
    //   this.params.sort_direction = order
    //   await this.getList()
    // },
    // async search (search) {
    //   this.params.search = search
    //   await this.getList()
    // },
    // async sort (sort) {
    //   this.params.sort = sort
    //   await this.getList()
    // },
    async getList () {
      try {
        const { data } = await request.get(
          '/team',
          // { params: this.params },
        )
        this.team = data
      } catch (e) {
        console.error(e.message)
      }
    },
  },
})
