<template>
  <div
    class="EmptyElementItem cursor-pointer"
    :class="{EmptyElementItem__selected: isSelected}"
    @click="$emit('select')"
    :title="name"
  >

    <div class="EmptyElementItem__image full-width"></div>

    <div class="EmptyElementItem__name">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyElementItem',

  props: {
    name: {
      type: String,
      required: true,
    },
    isSelected: Boolean,
  },
}
</script>

<style lang="scss">
  @import "src/styles/variables";

  .EmptyElementItem {
    position:        relative;
    display:         flex;
    flex-direction:  column;
    justify-content: space-around;
    align-content:   center;
    align-items:     center;
    width:           105px;
    height:          150px;
    user-select:     none;
    border:          1px solid transparent;
    border-radius:   5px;

    &__selected {
      border-color: var(--q-color-secondary);
    }

    &__image {
      display:    inline-flex;
      max-width:  100px;
      max-height: 150px;

      &:before {
        content:     '';
        display:     block;
        padding-top: 100%;
      }
    }

    &__name {
      max-width:      80%;
      line-height:    20px;
      overflow:       hidden;
      text-overflow:  ellipsis;
      font-size:      10px;
      color:          $primary-text-color;
      text-transform: uppercase;
      text-align:     center;
      white-space:    nowrap;
    }
  }
</style>
