<template>
  <div class="SidebarBasePanel">
    <div class="SidebarBasePanel__topActions justify-between">
      <div>
        <slot name="topList"></slot>
      </div>

      <!-- <a
        href="#"
        class="SidebarBasePanel__closePanelButton"
        @click.prevent="$emit('close')"
      >close</a> -->
    </div>

    <div class="SidebarBasePanel__search">
      <slot name="searchInput"></slot>
    </div>

    <div class="SidebarBasePanel__filters">
      <slot name="filters"></slot>
    </div>

    <div
      ref="elementsListContainer"
      class="SidebarBasePanel__elementsList"
    >
      <q-scroll-area
        ref="scroll"
        style="height: 100%;"
        @scroll="$emit('scroll', $event)"
      >
      <!-- <div
        ref="scroll"
        style="height: 100%;"
        @scroll="$emit('scroll', $event)"
      > -->
        <slot name="default"></slot>
      <!-- </div> -->
      </q-scroll-area>
    </div>

  </div>
</template>

<script>

export default {
  name: 'SidebarBasePanel',

  methods: {
    setScrollPosition (offset, duration) {
      this.$refs.scroll.setScrollPosition(offset, duration)
    },
    getScrollPosition () {
      let target = this.$refs.scroll.getScrollTarget()
      let scrollHeight = target.scrollHeight
      let totalScrolled = target.scrollTop + target.clientHeight
      return {
        totalScrolled,
        scrollHeight,
        scrolledInPercents: totalScrolled * 100 / scrollHeight,
      }
    },
    getElementsContainer () {
      return this.$refs.elementsListContainer
    },
  },
}
</script>

<style lang="scss">
  @import "src/styles/variables";
  @import "src/styles/mixins";
  .SidebarBasePanel {
    display:          flex;
    flex-direction:   column;
    padding:          16px;
    background-color: var(--Surface);

    a:hover {
      text-decoration: none;
    }

    &__topActions {
      display: flex;
    }

    // &__closePanelButton {
    //   display:        flex;
    //   align-items:    center;
    //   text-transform: uppercase;
    //   font-size:      8px;

    //   &:after {
    //     content:     '×';
    //     position:    relative;
    //     display:     inline;
    //     font-size:   16px;
    //     margin-left: 5px;
    //   }
    // }

    &__filters {
      padding: 10px 0 19px;
    }

    &__search {
      margin-bottom: 32px;
      .title_block {
        @include flex(flex-start, space-between, row, 8px);
        margin-bottom: 40px;
        flex-wrap: wrap;
        p {
          @include font-styles(18px, 27px, 600, Montserrat);
          margin-bottom: 0;
          line-height: 40px;
        }
      }
    }

    &__elementsList {
      flex-grow: 1;
      height:    100%;
    }
  }
  @media (max-width: 800px) {
    .SidebarBasePanel__elementsList {
      height: 350px;
    }
  }
</style>
