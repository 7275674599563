<template>
  <div v-if="demoMode">
    <button
      v-if="isButtonAddPayment"
      class="green_button"
      @click="() => {
        addPayment()
      }"
    >
      Add payment method
    </button>
    <div v-else class="activate-plan" @click="openModal = true">
      Demo version ends in {{ daysRemaining }} {{ daysRemaining > 1 ? ' days' : ' day' }}. Click here to subscribe
    </div>
    <q-dialog v-model="openModal">
      <dialog-card-base-new v-if="load" class="p-margin-0">
        <p>Loading...</p>
      </dialog-card-base-new>
      <dialog-card-base-new v-else-if="confirmPay">
        <stripePay :subscriptionData="subscriptionData" v-if="subscriptionData" @close="closePay" />
      </dialog-card-base-new>
      <dialog-card-base-new v-else-if="succes">
        <div class="p-margin-0" v-if="loading">
          <p>Loading...</p>
        </div>
        <div v-else class="succes_pay">
          <div :class="`main-icon`">
            <svg
              width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Vector" d="M14.3391 5.11172C15.2962 4.29609 16.7038 4.29609 17.6609 5.11172L19.2889 6.49905C19.699 6.84857 20.209 7.0598 20.7461 7.10266L22.8783 7.27281C24.1318 7.37284 25.1272 8.36823 25.2272 9.62174L25.3973 11.7539C25.4402 12.291 25.6514 12.801 26.0009 13.2111L27.3883 14.8391C28.2039 15.7962 28.2039 17.2038 27.3883 18.1609L26.0009 19.7889C25.6514 20.199 25.4402 20.709 25.3973 21.2461L25.2272 23.3783C25.1272 24.6318 24.1318 25.6272 22.8783 25.7272L20.7461 25.8973C20.209 25.9402 19.699 26.1514 19.2889 26.5009L17.6609 27.8883C16.7038 28.7039 15.2962 28.7039 14.3391 27.8883L12.7111 26.5009C12.301 26.1514 11.791 25.9402 11.2539 25.8973L9.12174 25.7272C7.86823 25.6272 6.87284 24.6318 6.77281 23.3783L6.60266 21.2461C6.5598 20.709 6.34857 20.199 5.99905 19.7889L4.61172 18.1609C3.79609 17.2038 3.79609 15.7962 4.61172 14.8391L5.99905 13.2111C6.34857 12.801 6.5598 12.291 6.60266 11.7539L6.77281 9.62174C6.87284 8.36823 7.86823 7.37284 9.12174 7.27281L11.2539 7.10266C11.791 7.0598 12.301 6.84857 12.7111 6.49905L14.3391 5.11172Z" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="Vector_2" d="M20 13.833L14.6667 19.1663L12 16.4997" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <p class="succes_pay__title">
            Payment method was <br> added
          </p>
          <p class="succes_pay__subtitle">You can now enjoy the full potential of <br> your chosen plan</p>
            <button
              class="green_button small_button"
              style="max-width: fit-content; padding: 10px 16px;"
              @click="openModal = false"
            >
              Let’s start
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M8.83337 6.66699L12.1667 10.0003L8.83337 13.3337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
      </dialog-card-base-new>
      <dialog-card-base-new v-else-if="getSubscriptionError" class="p-margin-0">
        <p>Something went wrong, try again later</p>
      </dialog-card-base-new>
      <dialog-card-base-new
        v-else
        title="You are in Demo mode now"
        contentContainerClass="normalBlock"
      >
        <p>Why wait? Add your payment method now to instantly access all features.</p>
        <div class="row full-width justify-center">
          <button class="green_button" @click="addPayment">
            Add payment method
          </button>
          <button class="white-button" @click="openModal = false">
            Later
          </button>
        </div>
      </dialog-card-base-new>
    </q-dialog>
  </div>
</template>

<script>
import stripePay from '@/components/stripe/stripePay'
import { getSubscription, checkSubscription } from '@/api'
import DialogCardBaseNew from '@/components/Dialog/DialogCardBaseNew'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DialogCardBaseNew,
    stripePay,
  },
  props: {
    isButtonAddPayment: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    openModal: false,
    confirmPay: false,
    subscriptionData: null,
    succes: false,
    loading: false,
    load: false,
    getSubscriptionError: false,
  }),
  computed: {
    ...mapGetters('user', ['demoMode', 'daysRemaining']),
  },
  methods: {
    closePay () {
      this.openModal = false
      this.confirmPay = false
      localStorage.setItem('id', null)
    },
    ...mapActions('user', ['setUserSubscriptionStatus']),
    async addPayment () {
      this.load = true
      this.openModal = true
      let getSubscriptionData = await getSubscription()
      if (getSubscriptionData === 'error') this.getSubscriptionError = true
      else {
        this.subscriptionData = getSubscriptionData
        localStorage.setItem('id', this.subscriptionData.subscription.id)
        this.confirmPay = true
      }
      this.load = false
    },
  },
  async mounted () {
    if (this.$route.query.succes_page) {
      const id = localStorage?.getItem?.('id')
      if (id) {
        this.openModal = true
        this.succes = true
        this.loading = true
        let checkData = await checkSubscription(id)
        if (checkData) this.setUserSubscriptionStatus(checkData.subscription)
        localStorage.setItem('id', null)
        this.loading = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.p-margin-0 {
  p {
    margin: 0;
  }
}
.succes_pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 8px;
  }
  &__subtitle {
    color: #6F8789;
    line-height: 24px;
    margin: 0;
  }
  .main-icon {
    border-radius: 50%;
    background: var(--Dividers, #E0E9EA);
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }
  .green_button {
    margin-top: 24px;
    svg {
      margin-left: 8px;
    }
  }
}
.activate-plan {
  background: #27808D;
  text-align: center;
  padding: 6px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}
</style>
