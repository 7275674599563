<template>
  <span class="MeasureViewComponent">{{ output }} {{ outputUnits }}</span>
</template>

<script>
import { Measurement } from '@/helpers/measurement'

export default {
  name: 'MeasureViewComponent',

  props: {
    valueInCm: {
      type: Number,
      required: true,
    },
    outputUnits: {
      type: String,
      required: true,
    },
    fractionBase: {
      type: Number,
      required: true,
      validator: val => [8, 10].includes(val),
    },
    floorValue: Boolean,
  },

  computed: {
    unsupportedUnits () {
      try {
        return !Measurement.checkUnit(this.outputUnits)
      } catch (e) {
        return true
      }
    },
    output () {
      if (this.unsupportedUnits || this.fractionBase === 10) {
        return Number(this.valueInCm.toFixed(1))
      }
      return Measurement.decimalsToFractions(Measurement.cmToIn(this.valueInCm))
    },
  },

  methods: {
    applyFractionBase: (value, base = 10, floor) => Measurement.applyFractionBase(value, base, floor),
  },
}
</script>

<style>
  .MeasureViewComponent {
    white-space: nowrap;
  }
</style>
