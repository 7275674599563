<script>
import { parse } from 'svg-parser'
import bbox from 'svg-path-bounding-box'

export default {
  props: {
    width: {
      default: 100,
    },
    height: {
      default: 100,
    },
    source: {
      default: '',
    },
    keepStyles: {
      type: Boolean,
      default: false,
    },
    keepViewBox: {
      type: Boolean,
      default: true,
    },
    squareViewBox: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      viewBox: '',
    }
  },
  render: function (createElement) {
    return createElement(
      'svg',
      {
        attrs: {
          viewBox: this.viewBox || this.size,
        },
      },
      this.renderChildren(createElement, this.hierarchy),
    )
  },
  computed: {
    size () {
      const paths = this.findPaths(this.hierarchy)
      const epsilon = 25
      const size = paths.reduce((size, path) => {
        const box = bbox(path.attributes.d)
        const isNeck = this.squareViewBox && paths.length === 1 && size.maxX > box.maxX * 5
        const boxMinY = this.squareViewBox ? box.minY - epsilon : box.minY
        return {
          minX: isNeck ? box.minX - epsilon : Math.min(size.minX, box.minX),
          minY: isNeck ? box.minY - epsilon : Math.min(size.minY, boxMinY),
          maxX: Math.max(size.maxX, box.maxX),
          maxY: Math.max(size.maxY, box.maxY),
        }
      }, {
        minX: 0,
        minY: 0,
        maxX: this.squareViewBox ? this.width : 0,
        maxY: this.squareViewBox ? this.height : 0,
      })
      if (this.squareViewBox) {
        if (size.minX < 0) {
          size.minX = size.minX - 2
          size.maxX = size.maxX - size.minX + 2
        }
        if (size.maxX !== size.maxY) {
          if (size.maxY > size.maxX) {
            const spaceNeeds = (size.maxY - size.maxX) / 2
            size.minX = size.minX - spaceNeeds
            size.maxX = (size.maxY)
          }
          if (size.maxX > size.maxY) {
            const spaceNeeds = (size.maxX - size.maxY) / 2
            size.minY = size.minY + spaceNeeds
            size.maxY = (size.maxX)
          }
        }
      }
      return `${size.minX} ${size.minY} ${size.maxX} ${size.maxY + 5}`
    },
    hierarchy () {
      const svgElement = parse(this.source)
      if (svgElement.name === 'svg') {
        if (this.keepViewBox) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.viewBox = svgElement.attributes.viewBox
        }
        return svgElement.children
      }
      return [svgElement]
    },
  },
  methods: {
    findPaths (children) {
      let paths = []
      children.forEach(el => {
        if (el.name === 'path') paths.push(el)
        if (el.name === 'polyline') {
          paths.push({
            ...el,
            attributes: {
              ...el.attributes,
              points: null,
              d: 'M' + el.attributes.points.trim(),
            },
          })
        }
        if (el.name === 'polygon') {
          paths.push({
            ...el,
            attributes: {
              ...el.attributes,
              points: null,
              d: 'M' + el.attributes.points.trim() + 'Z',
            },
          })
        }
        if (el.children && el.children.length) paths = [...paths, ...this.findPaths(el.children)]
      })
      return paths
    },
    renderChildren (createElement, children) {
      const sortedChildren = children.slice(0)
        .sort((a, b) => +a.attributes['data-fillable'] - +b.attributes['data-fillable'])
      return sortedChildren.map(element => {
        const attributes = this.keepStyles
          ? element.attributes
          : {
            ...element.attributes,
            style: '',
          }
        if (element.name === 'path') {
          return createElement('path', {
            attrs: {
              ...attributes,
              'stroke-width': '1px',
            },
          })
        }
        if (element.name === 'polyline') {
          return createElement('path', {
            attrs: {
              ...attributes,
              points: null,
              d: 'M' + element.attributes.points.trim(),
            },
          })
        }
        if (element.name === 'polygon') {
          return createElement('path', {
            attrs: {
              ...attributes,
              points: null,
              d: 'M' + element.attributes.points.trim() + 'Z',
              style: '',
            },
          })
        }
        if (element.name === 'image') {
          return createElement('image', {
            attrs: attributes,
          })
        }
        if (element.name === 'circle') {
          return createElement('circle', {
            attrs: attributes,
          })
        }
        if (element.name === 'ellipse') {
          return createElement('ellipse', {
            attrs: attributes,
          })
        }
        if (element.name === 'g') {
          return this.renderGroup(createElement, element)
        }
      })
    },
    renderGroup (createElement, group) {
      return createElement(
        'g',
        {
          attrs: group.attributes,
        },
        this.renderChildren(createElement, group.children),
      )
    },
  },
}
</script>
