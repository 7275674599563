<template>
  <div class="fit-model-new">
    <button class="close" @click="close()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <path d="M5 4.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L8.937 10 4.47 14.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.062 0L10 11.062l4.469 4.47a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.063L11.062 10l4.47-4.469a.75.75 0 0 0 0-1.062A.75.75 0 0 0 15 4.25a.75.75 0 0 0-.531.219L10 8.937 5.531 4.47A.75.75 0 0 0 5 4.25z"/>
      </svg>
      <span>Exit</span>
    </button>

    <section class="mobile-placeholder">
      <h1>Creating and editing fit charts is not available in the mobile version</h1>
      <p>Please use the desktop version</p>
      <ButtonSecondary @click.native="close()">Okay</ButtonSecondary>
    </section>

    <section class="step step-b2" v-if="fitModelsStore[fitModelsStore.dataKey].step === 'b2'">
      <span class="step-counter">Step 1 / 2</span>
      <h1>Choose a clothing category and name fit model</h1>
      <p>Select for whom you want to sew clothes</p>
      <ButtonsGroup class="clothing-buttons">
        <button
          v-for="t in this.fitModelsStore.types"
          :key="t.key"
          :class="[ t.key === fitModelsStore[fitModelsStore.dataKey].type ? 'active' : '']"
          @click="fitModelsStore[fitModelsStore.dataKey].type = t.key"
        >
          {{ t.title }}
        </button>
      </ButtonsGroup>
      <form class="new-fitchart-name">
        <InputField type="text" placeholder="Enter name" v-model="fitModelsStore[fitModelsStore.dataKey].title" >
          <template v-slot:top-label>Fit model name</template>
        </InputField>
      </form>
      <nav>
        <ButtonPrimary
          :disabled="fitModelsStore[fitModelsStore.dataKey].title.length === 0"
          @click.native="goToFromScratchForm()"
        >
          Next
        </ButtonPrimary>
      </nav>
    </section>

    <section class="step step-c2" v-if="fitModelsStore[fitModelsStore.dataKey].step === 'c2'">
      <div class="form-heading">
        <!-- <span class="step-counter">2 / 2</span> -->
        <h1>Adjust fit model</h1>
        <!-- <p>You can create, delete and edit measurements to suit your needs</p> -->
      </div>
      <div>
        <div class="top-panel">
          <InputField type="text" placeholder="Enter name" class="name-field" v-model="fitModelsStore[fitModelsStore.dataKey].title" >
            <template v-slot:top-label>Fit model name</template>
          </InputField>
          <ButtonsGroup class="units-buttons">
            <button :class="[fitModelsStore.unit === 'in' ? 'active': '']" @click="fitModelsStore.unit = 'in'">in</button>
            <button :class="[fitModelsStore.unit === 'cm' ? 'active': '']" @click="fitModelsStore.unit = 'cm'">cm</button>
          </ButtonsGroup>
        </div>
      </div>
      <div class="form-body">
        <ModelEdit class="fit-model" />
      </div>
      <nav>
        <ButtonSecondary @click.native="fitModelsStore[fitModelsStore.dataKey].step = 'b2'" :disabled="this.$route.params.id !== undefined">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
              <path d="M9.549 5.926a.75.75 0 0 0-.412.21L5.803 9.472a.75.75 0 0 0 0 1.06l3.334 3.332a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L8.145 10.75h6.521a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H8.145l2.052-2.053a.75.75 0 0 0 0-1.06.75.75 0 0 0-.648-.211z"/>
            </svg>
          </template>
          Back
        </ButtonSecondary>
        <ButtonPrimary
          :disabled="!fitModelsStore.sizeChartIsValid"
          @click.native="save()"
        >
          Save chart
        </ButtonPrimary>
      </nav>
    </section>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFitModelsStore } from '../../store/pinia/fitModels'

import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'
import InputField from '@/components/Atoms/InputField.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ModelEdit from '@/components/fitCharts/ModelEdit.vue'

export default {
  name: 'FitModelNewEdit',
  components: {
    ButtonsGroup,
    InputField,
    ButtonPrimary,
    ButtonSecondary,
    ModelEdit,
  },
  computed: mapStores(useFitModelsStore),
  methods: {
    close () {
      this.$router.push({ name: 'fitModels' })
      this.fitModelsStore.$reset()
    },
    goToFromScratchForm () {
      this.fitModelsStore[this.fitModelsStore.dataKey].sizesList = []
      this.fitModelsStore.addNewSize(0)
      this.fitModelsStore[this.fitModelsStore.dataKey].step = 'c2'
    },
    async save () {
      if (this.$route.params.id !== undefined) await this.fitModelsStore.updateChart(this.$route.params.id)
      else await this.fitModelsStore.saveNewChart()
      this.$router.push({ name: 'fitModels' })
    },
  },
  beforeMount () {
    if (this.$route.params.id !== undefined) {
      this.fitModelsStore.dataKey = 'editChart'
      this.fitModelsStore[this.fitModelsStore.dataKey].step = 'c2'
    }
  },
}
</script>

<style lang="scss">
.fit-model-new {
  background: var(--Background);
  position: fixed;
  left: 0;
  top: 80px;
  width: 100%;
  height: calc(100% - 80px);

  .close {
    position: absolute;
    top: 24px;
    left: 16px;
    background: transparent;
    border: none;

    display: flex;
    align-items: center;
    column-gap: 4px;

    color: var(--Primary-Shades-Primary);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--Primary-Shades-Primary);
    }
  }

  .mobile-placeholder {
    display: none;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    height: 100%;

    .step-counter {
      color: var(--Text-Text_Primary);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    h1 {
      color: var(--Text-Text_Primary);
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      max-width: 442px;
      margin: 0;
      margin-bottom: 8px;
    }

    p {
      color: var(--Text-Text_Secondary);
      text-align: center;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      max-width: 442px;
      margin: 0;
      margin-bottom: 32px;
    }

    .clothing-buttons {
      margin-bottom: 32px;
    }

    .new-fitchart-name {
      flex-grow: 1;
      width: 100%;
      max-width: 442px;

      .input-field {
        margin-bottom: 16px;
      }
    }

    nav {
      width: 100%;
      border-top: 1px solid var(--Dividers);
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 16px;

      button {
        width: 212px;
      }
    }

    &.step-a {

      .option {
        width: 100%;
        max-width: 442px;
        border-radius: 8px;
        border: 1px solid var(--Dividers);
        background: var(--Background);
        margin-bottom: 16px;
        padding: 12px;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        column-gap: 16px;

        .icon {
          width: 24px;
          height: 24px;
          fill: var(--Icons-Icons_Secondary);
        }

        .info {
          flex-grow: 1;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          line-height: 150%;

          h3 {
            color: var(--Text-Text_Primary);
            font-size: 16px;
          }

          span {
            color: var(--Text-Text_Secondary);
            font-size: 14px;
          }
        }

        .arrow {
          width: 24px;
          height: 24px;
          fill: var(--Icons-Icon_Primary);
        }
      }
    }

    &.step-c1, &.step-c2 {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto auto 1fr auto;
      align-items: normal;

      .form-heading {
        text-align: center;

        h1, p {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .top-panel {
        width: 100%;
        max-width: 684px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 23px;

        .name-field {
          max-width: 200px;
        }
      }

      .form-body {
        overflow: auto;
      }
    }
  }
}

@media (max-width: 760px) {
  .fit-model-new {
    .mobile-placeholder {
      display: block;
      padding: 64px 16px;
      text-align: center;
      font-style: normal;

      h1 {
        color: var(--Text-Text_Primary);
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
      }
      p {
        color: var(--Text-Text_Secondary);
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 32px;
      }

      button {
        width: 175px;
        margin: 0 auto;
      }
    }

    .step {
      display: none !important;
    }
  }
}
</style>
