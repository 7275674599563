<template>
  <div class="column labels">
    <div class="cell head">Points of measure</div>
    <div class="cell label" v-for="(l, i) in labels" :key="l.name">
      <div>
        <span>{{ l.name }}</span>
        <span class="validation" v-if="i === currentCell.row && !l.param.includes('belly_protuberance') && !l.param.includes('buttocks')">
          {{ validation }}
        </span>
      </div>
      <button @click="showInfo(l)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
          <path d="M9 1.256a7.744 7.744 0 1 0 0 15.488A7.744 7.744 0 0 0 9 1.256zM0 9a9 9 0 1 1 18 0A9 9 0 0 1 0 9Zm9-3.558a.942.942 0 0 0-.942.942.628.628 0 1 1-1.256 0 2.198 2.198 0 1 1 3.316 1.892c-.165.098-.3.204-.39.308-.085.102-.1.168-.1.207v1.046a.628.628 0 0 1-1.256 0V8.791c0-.424.185-.768.4-1.02.21-.248.473-.438.706-.576A.941.941 0 0 0 9 5.442zm0 7.744a.837.837 0 1 0 0-1.674.837.837 0 0 0 0 1.674z"/>
        </svg>
      </button>
    </div>
    <ModalComponent
      class="image-modal"
      :show="showModal"
      :showIcon="false"
      @close="hideInfo()"
    >
      <template v-slot:title v-if="modalError">Sorry, no help image were found.</template>
      <template v-slot:extra v-else>
        <img :src="imageUrl()" :alt="modalLabel.name" />
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import { constants } from 'sizelib'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'

export default {
  name: 'ChartEditColumnLabels',
  components: {
    ModalComponent,
  },
  props: {
    type: {
      type: String,
      default: 'FEMALE',
    },
    units: {
      type: String,
      default: 'cm',
    },
    currentCell: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showModal: false,
    modalError: false,
    modalLabel: {},
  }),
  computed: {
    key () {
      return this.type.replaceAll('-', '_')
    },
    labels () {
      return constants[this.key + '_MEASUREMENTS'].map(m => constants.MEASUREMENTS[m])
    },
    validation () {
      const min = this.currentCell?.min[this.units]
      const max = this.currentCell?.max[this.units]

      const from = !isNaN(this.currentCell.min.cm) ? `from ${min} ${this.units}` : ''
      const to = !isNaN(this.currentCell.max.cm) ? `to ${max} ${this.units}` : ''
      return `${from} ${to}`
    },
    catalog () {
      const keys = {
        FEMALE: 'FEMALE',
        MALE: 'MALE',
        GIRL: 'CHILD',
        BOY: 'CHILD',
        GIRL_TEEN: 'TEEN',
        BOY_TEEN: 'TEEN',
      }
      return keys[this.key]
    },
  },
  methods: {
    showInfo (size) {
      this.showModal = true
      this.modalLabel = size
    },
    hideInfo () {
      this.showModal = false
      this.modalError = false
      this.modalLabel = {}
    },
    imageUrl () {
      try {
        return require(`@/assets/fitModelDescription/${this.catalog}/${this.modalLabel.param}.png`)
      } catch (a) {
        this.modalError = true
      }
    },
  },
}
</script>

<style lang="scss">
.column.labels {
  .image-modal {

    .modal-component {
      width: auto;
      height: 100%;
      max-width: 90vw;
      max-height: calc(100vh - 192px);
      padding: 16px;
      row-gap: 0;

      .extra {
        max-width: 100%;
        width: 100%;
        height: 90%;
        overflow: hidden;
        flex-grow: 1;
        display: grid;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}
</style>
