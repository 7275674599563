<template>
  <layout-with-header>
    <div class="fogot-pass-form" @keyup.enter="send">
      <template v-if="!sended">
        <p class="title">Forgot your password?</p>
        <p class="subtitle">
          Please enter the email associated with your account. <br> We will send you password recovery link.
        </p>
        <div class="input-block">
          <p class="label">E-mail</p>
          <q-input
            v-model="v$.email.$model"
            :class="v$.email.$error || error ? 'error-input' : ''"
            outlined
            placeholder="name@example.com"
            @input="error = null"
          />
          <div class="error" v-if="v$.email.$error">
            <p>An E-mail should be in the correct format: name@example.com</p>
          </div>
          <div class="error" v-if="error">
            <p>The email you entered is invalid. Please try again.</p>
          </div>
        </div>
        <button :disabled="v$.$invalid" class="green_button" style="width:100%" @click.prevent="send">
          Submit
        </button>
        <button class="white_button" style="width:100%" @click.prevent="$router.push('/')">
          Cancel
        </button>
        <div class="error" v-if="failure">
          <p>Something went wrong, try again later</p>
        </div>
      </template>
      <template v-else>
        <p class="title">Recovery link has been sent!</p>
        <p class="grey-color">
          We have sent an email to <strong>{{ email }}</strong>. Please check your inbox and follow
          the instructions to reset your password.
        </p>
        <p class="try-again grey-color">Didn't get an E-mail? <span class="pink-link" @click="sended = false">Try again</span></p>
        <button class="green_button" style="width:100%" @click.prevent="$router.push('/')">
          Okay
        </button>
      </template>
    </div>
  </layout-with-header>
</template>

<script>
import { sendResetPassword } from '@/api'
import LayoutWithHeader from '@/layouts/LayoutWithHeader'
import { useVuelidate } from '@vuelidate/core'
import { required, email, maxLength } from '@vuelidate/validators'
const validateEmail = (email) => {
  const emailParts = email.split('@')
  const username = emailParts[0]
  // Проверка имени пользователя
  const usernameRegex = /^[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9]$/
  if (!usernameRegex.test(username)) {
    return false
  }
  return true
}
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: 'forgotPassword',
  data: () => ({
    email: null,
    error: null,
    failure: false,
    sended: false,
  }),
  components: {
    LayoutWithHeader,
  },
  async mounted () {
    if (this.$route.params?.email) {
      this.email = this.$route.params.email
    }
  },
  methods: {
    async send () {
      this.error = null
      if (!this.v$.$invalid) {
        try {
          await sendResetPassword({ email: this.email })
          this.sended = true
        } catch (e) {
          if (e.message === 'Something went wrong, try again later') {
            this.failure = true
          } else this.error = e.message
        }
      }
    },
  },
  validations () {
    return {
      email: {
        required,
        email,
        maxLength: maxLength(320),
        validateEmail,
      },
    }
  },
}
</script>

<style lang="scss">
.fogot-pass-form {
  width: 544px;
  margin: auto;
  padding: 56px;
  border-radius: 12px;
  background-color: white;
  margin: 128px auto;
  color: #1D1F29;
  p {
    font-size: 16px;
  }
  strong {
    color: #1D1F29;
  }
  .grey-color {
    color: #828282;
  }
  .try-again {
    margin-top: 32px;
  }
  .error {
    color: #c10015;
  }
  .subtitle {
    font-size: 16px;
    margin: 32px 0;
  }
  .green_button {
    margin-top: 32px;
  }
  .white_button {
    margin-top: 16px;
  }
}
@media (max-width: 544px) {
  .fogot-pass-form {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .fogot-pass-form {
    padding: 32px 16px 32px 16px;
    margin: 0;
  }
}
</style>
