<template>
  <div>
    <modal-component
      class="seam-allowance-modal"
      :show="show"
      :showIcon="false"
      @close="$emit('close')"
    >
      <template v-slot:extra>
        <div class="select-format">
          <h3>Seam Allowance</h3>
          <div class="select-format__units">
            cm
            <div :class="`toggle ${units === Measurement.INCH ? 'active' : ''}`" @click="setUnit">
              <div class="toggle-circle" ></div>
            </div>
            in
          </div>
        </div>
        <ButtonsGroup>
          <button
            v-for="btn in options"
            :key="btn.value" :class="[selectedValue === btn.value ? 'active' : '']" @click="selectVal(btn.value)">
            {{ btn.label }}
          </button>
          <button disabled :class="isCustom ? 'active' : ''" @click="selectCustom">Custom</button>
        </ButtonsGroup>
        <div v-if="isCustom" class="inputs">
          <InputField
            v-model="constructionSeams"
            placeholder="Enter your value"
          >
            <template v-slot:top-label>
              Construction seams
            </template>
            <template v-slot:start-icon>
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 21">
                <path fill="#09373d" d="M18 17.5 13.5 13c.9-1.1 1.4-2.5 1.4-4.1 0-3.6-3-6.6-6.6-6.6s-6.6 3-6.6 6.6c0 3.6 3 6.6 6.6 6.6 1.5 0 3-.5 4.1-1.4l4.5 4.5c.1.1.3.2.5.2s.4-.1.5-.2c.4-.4.4-.8.1-1.1zM3.2 8.8c0-2.8 2.3-5.1 5.1-5.1s5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1z"/>
              </svg>
            </template>
            <template v-slot:end-icon-1>
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" enable-background="new 0 0 19 19" viewBox="0 0 19 19">
                <path fill="#6f8789" fill-rule="evenodd" d="M9.8 2C5.5 2 2.1 5.5 2.1 9.7c0 4.3 3.5 7.7 7.7 7.7 4.3 0 7.7-3.5 7.7-7.7S14 2 9.8 2zm-9 7.7c0-4.9 4-9 9-9 4.9 0 9 4 9 9 0 4.9-4 9-9 9s-9-4-9-9zm9-3.5c-.5 0-.9.4-.9.9 0 .3-.3.6-.6.6s-.6-.3-.6-.6c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 .8-.4 1.5-1.1 1.9-.2.1-.3.2-.4.3-.1.1-.1.2-.1.2v1c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-1c0-.4.2-.8.4-1 .1-.3.3-.5.6-.6.3-.2.5-.5.5-.8-.1-.5-.5-.9-1-.9zm0 7.7c.5 0 .8-.4.8-.8 0-.5-.4-.8-.8-.8-.5-.1-.8.3-.8.7 0 .5.3.9.8.9z" clip-rule="evenodd"/>
                <defs>
                  <filter id="a" width="19.9" height="19.9" x="-.2" y="-.2" filterUnits="userSpaceOnUse">
                    <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
                  </filter>
                </defs>
                <mask id="b" width="19.9" height="19.9" x="-.2" y="-.2" maskUnits="userSpaceOnUse">
                  <path fill="#fff" fill-rule="evenodd" d="M9.8 2C5.5 2 2.1 5.5 2.1 9.7c0 4.3 3.5 7.7 7.7 7.7 4.3 0 7.7-3.5 7.7-7.7S14 2 9.8 2zm-9 7.7c0-4.9 4-9 9-9 4.9 0 9 4 9 9 0 4.9-4 9-9 9s-9-4-9-9zm9-3.5c-.5 0-.9.4-.9.9 0 .3-.3.6-.6.6s-.6-.3-.6-.6c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 .8-.4 1.5-1.1 1.9-.2.1-.3.2-.4.3-.1.1-.1.2-.1.2v1c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-1c0-.4.2-.8.4-1 .1-.3.3-.5.6-.6.3-.2.5-.5.5-.8-.1-.5-.5-.9-1-.9zm0 7.7c.5 0 .8-.4.8-.8 0-.5-.4-.8-.8-.8-.5-.1-.8.3-.8.7 0 .5.3.9.8.9z" clip-rule="evenodd" filter="url(#a)"/>
                </mask>
                <path fill="#6f8789" d="m10.9 9-.5-.9.5.9zm-.4.3-.7-.7.7.7zm-.9-.8-.8-.7.8.7zm.7-.6.5.9-.5-.9zM3.1 9.7C3.1 6 6.1 3 9.8 3V1C5 1 1.1 4.9 1.1 9.7h2zm6.7 6.7c-3.7 0-6.7-3-6.7-6.7h-2c0 4.8 3.9 8.7 8.7 8.7v-2zm6.7-6.7c0 3.7-3 6.7-6.7 6.7v2c4.8 0 8.7-3.9 8.7-8.7h-2zM9.8 3c3.7 0 6.7 3 6.7 6.7h2c0-4.8-3.9-8.7-8.7-8.7v2zm0-3.2c-5.5 0-10 4.5-10 10h2c0-4.4 3.6-8 8-8v-2zm9.9 9.9c0-5.5-4.5-10-10-10v2c4.4 0 8 3.6 8 8h2zm-9.9 10c5.5 0 10-4.5 10-10h-2c0 4.4-3.6 8-8 8v2zm-10-10c0 5.5 4.5 10 10 10v-2c-4.4 0-8-3.6-8-8h-2zM9.9 7.1s-.1.1 0 0l-.1-1.9c-1.1 0-1.9.8-1.9 1.9h2zM8.2 8.7c.9 0 1.6-.7 1.6-1.6h-2c0-.2.2-.4.4-.4v2zM6.6 7.1c0 .9.7 1.6 1.6 1.6v-2c.2 0 .4.2.4.4h-2zm3.2-3.2C8 3.9 6.6 5.3 6.6 7.1h2c0-.7.5-1.2 1.2-1.2v-2zM13 7.1c0-1.8-1.4-3.2-3.2-3.2v2c.7 0 1.2.5 1.2 1.2h2zm-1.6 2.7c.9-.5 1.6-1.5 1.6-2.7h-2c0 .4-.2.8-.6 1l1 1.7zm-.1.1c0 .1 0 0 0 0l.1-.1-1-1.7c-.2.1-.5.3-.6.5l1.5 1.3zm.1-.4c0 .1 0 .2-.1.3 0 .1-.1.1-.1.1L9.8 8.6c-.2.3-.4.6-.4.9h2zm0 1v-1h-2v1h2zm-1.6 1.7c.9 0 1.6-.7 1.6-1.6h-2c0-.2.2-.4.4-.4v2zm-1.6-1.7c0 .9.7 1.6 1.6 1.6v-2c.2 0 .4.2.4.4h-2zm0-1v1h2v-1h-2zm.6-1.7c-.3.4-.6 1-.6 1.7h2c0-.1 0-.2.2-.4L8.8 7.8zm1-.7c-.3.2-.6.4-1 .8l1.5 1.3c.1-.1.3-.3.5-.4l-1-1.7zm-.1 0s.1 0 0 0l1 1.7c.6-.3 1-1 1-1.7h-2zm.1.1s-.1-.1 0 0l1.9-.1c0-1.1-.9-1.9-1.9-1.9v2zM9.6 13c0-.1.1-.2.2-.2v2c1 0 1.8-.8 1.8-1.8h-2zm.2.2c-.1 0-.2-.1-.2-.2h2c0-1-.8-1.8-1.8-1.8v2zm.2-.2c0 .1-.1.2-.2.2v-2c-1 0-1.8.8-1.8 1.8h2zm-.2-.1c.1 0 .2.1.2.2H8c0 1 .8 1.8 1.8 1.8v-2z" mask="url(#b)"/>
              </svg>
            </template>
          </InputField>
          <InputField
            v-model="constructionSeams"
            placeholder="Enter your value"
          >
            <template v-slot:top-label>
              Finishing seams
            </template>
          </InputField>
          <InputField
            v-model="constructionSeams"
            placeholder="Enter your value"
          >
            <template v-slot:top-label>
              Hem
            </template>
          </InputField>
        </div>
      </template>
      <template v-slot:buttons>
        <ButtonPrimary
          class="one"
          @click.native="apply"
          :disabled="isValid"
        >
          Apply Changes
        </ButtonPrimary>
      </template>
    </modal-component>
  </div>
</template>

<script>
import { getSeamAllowanceLabel } from '@/components/seamAllowance'
import { mapGetters, mapState, mapActions } from 'vuex'
import ModalComponent from '@/components/Atoms/ModalComponent'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'
import { Measurement } from '@/helpers/measurement'
import InputField from '@/components/Atoms/InputField.vue'

export default {
  name: 'SeamAllowanceSelect',
  components: {
    ModalComponent, ButtonPrimary, ButtonsGroup, InputField,
  },

  props: {
    currentValue: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    category: {
      type: [String, Boolean],
      default: false,
    },
  },

  data: () => ({
    constructionSeams: null,
    isCustom: false,
    selectedValue: undefined,
  }),

  created () {
    this.Measurement = Measurement
    this.selectedValue = this.currentValue
  },

  computed: {
    isValid () {
      if (this.currentValue.toString() === this.selectedValue.toString()) return true
      return false
    },
    options () {
      if (this.category === 'wm_bodysuits' || this.category === 'wm_underwear') {
        return this.seamAllowanceOptions.filter(item => item !== 1.6).map(item => ({
          label: getSeamAllowanceLabel(item, this.units, this.getFractionBase),
          value: item,
        }))
      }
      return this.seamAllowanceOptions.map(item => ({
        label: getSeamAllowanceLabel(item, this.units, this.getFractionBase),
        value: item,
      }))
    },
    ...mapState([
      'units',
      'seamAllowanceOptions',
    ]),
    ...mapGetters([
      'getFractionBase',
    ]),
  },

  methods: {
    selectCustom () {
      this.selectedValue = null
      this.isCustom = true
    },
    selectVal (val) {
      this.isCustom = false
      this.selectedValue = val
    },
    setUnit () {
      if (this.units === Measurement.INCH) this.setUnits({ units: Measurement.CM })
      else this.setUnits({ units: Measurement.INCH })
    },
    ...mapActions([
      'setUnits',
    ]),
    apply () {
      this.$emit('apply', this.selectedValue)
    },
  },
}
</script>

<style lang="scss">
.seam-allowance-modal {
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
  }
  .modal-component {
    width: 569px;
    .extra {
      max-width: 410px;
    }
    .buttons .one {
      width: fit-content;
    }
  }
  .select-format {
    margin-bottom: 24px;
    h3 {
      margin-bottom: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__units {
      display: flex;
      align-items: center;
    }
  }
  .toggle {
    margin: 0 12px;
    width: 40px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px;
    position: relative;
    transition: background-color 0.3s;
    background-color: #B2C3C4;
  }
  .toggle-circle {
    width: 24px;
    height: 24px;
    background: white;
    border-radius: 50%;
    position: absolute;
    left: 0;
    transition: left 0.3s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }
  .toggle.active .toggle-circle {
    left: 16px;
  }
}
</style>
