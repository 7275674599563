<template>
  <div>
    <div class="select-block-radio">
      <div
        :class="`radio-item ${units === Measurement.INCH ? 'active' : ''}`"
        @click="setUnits({units: Measurement.INCH})"
      >
      in
      </div>
      <div
        :class="`radio-item ${units === Measurement.CM ? 'active' : ''}`"
        @click="setUnits({units: Measurement.CM})"
      >
      cm
      </div>
    </div>
  </div>
</template>

<script>
import { Measurement } from '@/helpers/measurement'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UnitsToggleComponent',

  created () {
    this.Measurement = Measurement
  },

  computed: {
    ...mapState([
      'units',
    ]),
  },

  methods: {
    ...mapActions([
      'setUnits',
    ]),
  },
}
</script>
