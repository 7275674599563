<template>
  <layout-with-header>
    <div class="container">
      <div class="login-form" @keyup.enter="auth">
        <p class="title">Log in</p>
        <p class="subtitle">Don't have an account? <a class="pink-link" href="/signup">Sign Up</a></p>
        <div class="input-block">
          <p class="label">E-mail</p>
          <q-input
            v-model="name"
            outlined
            placeholder="Login"
          />
        </div>
        <div class="input-block">
          <p class="label">Password</p>
          <div class="password">
            <svg v-if="isShowPass" @click="isShowPass = false" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_25_1587)">
                <path d="M8.3335 9.99992C8.3335 10.4419 8.50909 10.8659 8.82165 11.1784C9.13421 11.491 9.55814 11.6666 10.0002 11.6666C10.4422 11.6666 10.8661 11.491 11.1787 11.1784C11.4912 10.8659 11.6668 10.4419 11.6668 9.99992C11.6668 9.55789 11.4912 9.13397 11.1787 8.82141C10.8661 8.50885 10.4422 8.33325 10.0002 8.33325C9.55814 8.33325 9.13421 8.50885 8.82165 8.82141C8.50909 9.13397 8.3335 9.55789 8.3335 9.99992Z" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.5 10C15.5 13.3333 13 15 10 15C7 15 4.5 13.3333 2.5 10C4.5 6.66667 7 5 10 5C13 5 15.5 6.66667 17.5 10Z" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_25_1587">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <svg v-else @click="isShowPass = true" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_25_1588)">
                <path d="M8.82097 8.82251C8.50845 9.13513 8.33293 9.5591 8.33301 10.0011C8.33309 10.4432 8.50876 10.8671 8.82138 11.1796C9.13401 11.4921 9.55797 11.6676 10 11.6676C10.4421 11.6675 10.866 11.4918 11.1785 11.1792" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.9008 13.8942C12.7319 14.6256 11.3789 15.0091 10 15C7 15 4.5 13.3334 2.5 10C3.56 8.23336 4.76 6.93503 6.1 6.10503M8.48333 5.15002C8.98253 5.04897 9.49068 4.99871 10 5.00003C13 5.00003 15.5 6.66669 17.5 10C16.945 10.925 16.3508 11.7225 15.7183 12.3917" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 2.5L17.5 17.5" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_25_1588">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <q-input
              v-model="password"
              outlined
              :type="isShowPass ? 'text' : 'password'"
              placeholder="Password"
            />
          </div>
        </div>

  <!--      <q-input-->
  <!--        outlined-->
  <!--        dense-->
  <!--        v-model="password"-->
  <!--        type="password"-->
  <!--        placeholder="Password"-->
  <!--      />-->
        <div class="check">
          <label :class="`checkbox ${checked ? 'selected' : ''}`">
            <input
              v-model="checked"
              type="checkbox"
            />
            <svg v-if="checked" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </label>
          <p>Remember me</p>
        </div>
        <span class="forgot" @click="goForgotPage">Forgot Password?</span>
        <button
          :disabled="!name?.length || !password?.length"
          class="green_button"
          style="width:100%"
          @click.prevent="auth"
        >
          Log in
        </button>
        <p v-if="error" class="error">
          {{ error }}
        </p>
      </div>
    </div>
    <modal-component :show="deletedUser" :showIcon="false" @close="deletedUser = false">
      <template v-slot:title>Sorry, your account has been deleted</template>
      <template v-slot:description>Contact your administrator for assistance</template>
      <template v-slot:buttons>
        <ButtonSecondary class="small one" @click.native="deletedUser = false">Okay</ButtonSecondary>
      </template>
    </modal-component>
  </layout-with-header>
</template>

<script>
import { login } from '@/api'
import LayoutWithHeader from '@/layouts/LayoutWithHeader'
import ModalComponent from '@/components/Atoms/ModalComponent'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

export default {
  name: 'LoginPage',
  data: () => ({
    name: null,
    password: null,
    error: false,
    isShowPass: false,
    checked: true,
    deletedUser: false,
  }),
  components: {
    LayoutWithHeader,
    ModalComponent,
    ButtonSecondary,
  },
  methods: {
    goForgotPage () {
      this.$router.push({
        name: 'forgotPassword',
        params: {
          email: this.name,
        },
      })
    },
    async auth () {
      try {
        this.error = false
        await login({ email: this.name, password: this.password })
        this.$router.push('/')
      } catch (e) {
        if (e.message === 'deleted') this.deletedUser = true
        else this.error = e.message
      }
    },
  },
}
</script>

<style lang="scss">
.login-form {
  width: 544px;
  margin: auto;
  padding: 56px;
  border-radius: 12px;
  background-color: white;
  margin: 128px auto;
  .subtitle {
    margin-bottom: 32px;
  }
  .check {
    margin-top: 16px;
    margin-bottom: 56px;
  }
  .forgot {
    font-size: 16px;
    color: #1D1F29;
    border-bottom: 1px solid #1D1F29;
    cursor: pointer;
    &:hover {
      border-bottom: none;
    }
  }
  button {
    margin-top: 32px;
  }
  .error {
    color: #c10015;
  }
  p {
    font-size: 16px;
  }
}
@media (max-width: 544px) {
  .login-form {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .login-form {
    padding: 32px 16px 32px 16px;
    margin: 0;
  }
}
</style>
