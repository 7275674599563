<template>
  <modal-component
    :showIcon="isError"
    :show="show"
    type="error"
    @close="$emit('close')"
    :class="`yardage-modal ${tapes.length ? 'tapes' : ''}`"
  >
    <template v-slot:title v-if="isError">
      Sorry, we can't generate a marker for self fabric
    </template>
    <template v-slot:description v-if="isError">
      Please choose a wider fabric for this garment.
    </template>
    <template v-slot:extra v-if="!isError">
      <svg
        v-if="tapes.length"
        class="yardage-modal__back-icon"
        xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24"
        width="24"
        height="24"
        @click="back"
      >
        <path fill="#828282" d="M19 11.3H6.8l4.7-4.7c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0l-6 6c-.1.1-.1.2-.2.2-.1.2-.1.4 0 .6 0 .1.1.2.2.2l6 6c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-4.7-4.7H19c.4 0 .8-.3.8-.8s-.4-.6-.8-.6z"/>
      </svg>
      <div class="yardage-modal__title">
        <h3>Marker</h3>
        <units-toggle-component />
      </div>
      <div class="yardage-modal__loader-block" v-if="isLoading">
        <svg class="loader" xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="none" viewBox="0 0 42 42">
          <g stroke="#09373D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" clip-path="url(#a)">
            <path d="M21 10.5V5.25M10.5 21H5.25m8.312-7.437L9.8 9.8"/>
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h42v42H0z"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <template v-else>
        <div class="yardage-modal__items" v-if="!tapes.length">
          <div v-for="item in layouts" :key="item">
            <Dropdown
              :options="widthOptionsAvailable"
              :value="selected[item]"
              @input="select($event, item)"
              :label="item?.charAt(0).toUpperCase() + item?.slice(1).toLowerCase()"
              :option-label="units === 'cm' ? 'value' : 'name'"
              placeholder="Choose..."/>
          </div>
        </div>
        <div v-else class="yardage-modal__tapes" ref="printable">
          <div v-for="tape in tapes" :key="tape.id" class="yardage-modal__tapes-block">
            <p class="yardage-modal__tapes__name">{{ tape.name }}</p>
            <p class="yardage-modal__tapes__text">
              Yield: {{ tape.formattedLength }} at {{ tape.formattedWidth }} width fabric
            </p>
            <div  :style="{width: tape.svgWidthPercent + '%'}" v-html="tape.svg" />
          </div>
        </div>
        <iframe ref="printFrame" style="display: none;"></iframe>
      </template>
    </template>
    <template v-slot:buttons v-if="!isError">
      <ButtonPrimary :disabled="isLoading" class="one" @click.native="getYardage" v-if="!tapes.length">
        Apply
      </ButtonPrimary>
      <ButtonPrimary class="one" v-else @click.native="print">Print</ButtonPrimary>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/Atoms/ModalComponent'
import Dropdown from '@/components/Atoms/Dropdown.vue'
import UnitsToggleComponent from '@/components/Units/UnitsToggleComponentNew'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'

import { fetchYardageLayers, fetchYardage } from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'Yardage',
  components: {
    ModalComponent,
    Dropdown,
    UnitsToggleComponent,
    ButtonPrimary,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isError: false,
    isLoading: false,
    selected: {},
    layouts: null,
    widthOptionsAvailable: [
      { value: '51 cm', name: '20"' },
      { value: '88 cm', name: '35–36”' },
      { value: '99 cm', name: '39”' },
      { value: '104 cm', name: '41”' },
      { value: '111 cm', name: '44–45”' },
      { value: '127 cm', name: '50”' },
      { value: '132 cm', name: '52–54”' },
      { value: '147 cm', name: '58–60”' },
      { value: '167 cm', name: '66”' },
      { value: '182 cm', name: '72”' },
      { value: '243 cm', name: '96”' },
      { value: '274 cm', name: '108”' },
    ],
    paramsChanged: null,
    consNames: {
      'MAIN FABRIC': 'Self fabric',
      'FUSIBLE INTERFACING': 'Interfacing',
      'LINING': 'Lining',
      'Self fabric': 'MAIN FABRIC',
      'Interfacing': 'FUSIBLE INTERFACING',
      'Lining': 'LINING',
      'RIB TRIMMING': 'RIB TRIMMING',
    },
    yardage: null,
  }),
  computed: {
    ...mapState(['units']),
    tapes () {
      const tapes = []
      if (this.yardage) {
        this.yardage.tapes.forEach(el => {
          const tape = tapes.find(t => el.fabric_uid === t.id)
          if (!tape) {
            const meterWidth = this.getMeterLength(el.width)
            let meterWidthStr = meterWidth + 'm'
            if (meterWidth < 1) {
              meterWidthStr = (meterWidth * 100) + 'cm'
            }
            const formattedWidth = this.units === 'cm' ? meterWidthStr : this.selected[this.consNames[el.fabric_uid]].name
            const formatTapeLength = this.units === 'cm' ? `${this.getMeterLength(el.length)}m` : `${this.getYardsLength(el.length)}yd`
            tapes.push({
              svg: el.svg.replace('width="100%"', '').replace('height="100%"', ''),
              formattedWidth: formattedWidth,
              formattedLength: formatTapeLength,
              id: el.fabric_uid,
              name: this.consNames[el.fabric_uid],
              length: el.length,
            })
          }
        })
        const maxLength = Math.max(...tapes.map(t => t.length))
        tapes.forEach(t => {
          this.$set(t, 'svgWidthPercent', t.length >= maxLength ? 100 : t.length / maxLength * 100)
        })
      }
      return tapes
    },
  },
  methods: {
    back () {
      this.yardage = null
    },
    print () {
      const printContents = this.$refs.printable.innerHTML
      const iframe = this.$refs.printFrame
      const doc = iframe.contentDocument || iframe.contentWindow.document
      doc.open()
      doc.write(`
        <html>
          <head>
            <title>patternfast</title>
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `)
      doc.close()
      iframe.contentWindow.focus()
      iframe.contentWindow.print()
    },
    getMeterLength (length) {
      return (+length / 100).toFixed(2)
    },
    getYardsLength (length) {
      return (+length * 0.010936).toFixed(2)
    },
    async getYardage () {
      this.isLoading = true
      let layers = []
      for (let i in this.selected) {
        layers.push({
          width: Number(this.selected[i].value.replace(' cm', '')),
          uid: this.consNames[i],
        })
      }
      let aa = await fetchYardage({
        ...this.paramsChanged,
        layers: layers,
      })
      if (aa === 'error') {
        this.isError = true
      } else this.yardage = aa
      this.isLoading = false
    },
    select (val, item) {
      this.$set(this.selected, item, val)
    },
  },
  async mounted () {
    let paramsEl = {}
    let algorithms = []
    for (let key in this.params.designElementsWithUserSettings) {
      if (this.params.designElementsWithUserSettings[key]?.[0]?.params) {
        paramsEl = Object.assign(paramsEl, this.params.designElementsWithUserSettings[key][0].params)
      }
      if (this.params.designElementsWithUserSettings[key]?.[0]?.algorithms) {
        algorithms = algorithms.concat(this.params.designElementsWithUserSettings[key]?.[0]?.algorithms)
      }
    }
    this.paramsChanged = {
      element_ids: this.params.element_ids,
      setting_codes: this.params.setting_codes,
      mannequin_uid: this.params.mannequin_uid,
      algorithms: algorithms,
      params: paramsEl,
    }
    this.layouts = await fetchYardageLayers(this.paramsChanged)
    this.layouts = this.layouts.map(el => {
      let val = this.widthOptionsAvailable[4]
      if (this.consNames[el] === 'Interfacing') val = this.widthOptionsAvailable[0]
      this.$set(this.selected, this.consNames[el], val)
      return this.consNames[el]
    })
  },
}
</script>

<style lang="scss">
.yardage-modal.tapes.modal-component-overlay .modal-component {
  height: 90%;
  position: relative;
}
.yardage-modal.modal-component-overlay .modal-component {
  max-height: 90%;
  width: 442px;
  .extra {
    height: calc(100% - 122px);
    max-width: 100%;
    display: flex;
    padding: 0 32px;
    flex-direction: column;
  }
  .buttons .one {
    @media (max-width: 480px) {
      width: 100%;
    }
  }
}
.yardage-modal {
  &__loader-block {
    display: flex;
    justify-content: center;
  }
  &__back-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
  }
  &__tapes-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__tapes {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__name {
      color: var(--Text-Text_Primary) !important;
      font-size: 14px !important;
    }
    &__text {
      font-size: 12px !important;
      margin-bottom: 16px !important;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    h3 {
      margin-bottom: 0 !important;
    }
    .select-block-radio .radio-item {
      width: 20px;
      min-width: 20px;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
</style>
