<template>
  <div
    class="FilterItem"
    :class="{FilterItem__isOpened: isVisible}"
  >
    <label>{{ title }}</label>
    <div
      class="FilterItem__drop"
      @click="toggleVisibility"
    >
      <p class="text">{{ selectedValuesNames?.join(', ') || 'Choose...' }}</p>
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0002 8.83325L10.6668 12.1666L7.3335 8.83325" stroke="#1D1F29" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <transition name="fadeIn" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div
        v-show="isVisible"
        class="FilterItem__mainContainer"
      >
        <!-- <div
          v-if="filterOptions.length > 5"
          class="FilterItem__search"
        >
          <input
            class="FilterItem__textInput"
            type="text"
            placeholder="Search..."
            v-model="filterByName"
          >
        </div> -->
        <!-- <q-scroll-area
          :style="scrollContainerStyle"
        > -->
        <div class="FilterItem__optionsList" :style="scrollContainerStyle">
          <div
            v-for="item in filteredOptions"
            :key="item.id"
            class="check"
          >
            <div :class="`checkbox ${selectedItems.includes(item.id) ? 'selected' : ''}`">
              <input v-model="selectedItems" type="checkbox" :id="item.id" :name="item.id" :value="item.id" />
              <svg v-if="selectedItems.includes(item.id)" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <label :for="item.id">{{ item.name }}</label>
          </div>
        </div>
        <!-- </q-scroll-area> -->
        <div class="FilterItem__actions">
          <ButtonPrimary class="small one" @click.native="applySelected">
            Apply
          </ButtonPrimary>
          <ButtonSecondary class="small one" @click.native="clear">
            Clear all
          </ButtonSecondary>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

export default {
  name: 'FilterItem',
  components: {
    ButtonPrimary,
    ButtonSecondary,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    filterOptions: {
      type: Array,
      required: true,
    },
    selectedValues: {
      type: Array,
      required: true,
    },
    activeAttrs: {
      type: Array,
      required: false,
    },
  },

  data: () => ({
    isVisible: false,
    selectedItems: [],
    filterByName: '',
    checkboxSizePx: 30,
  }),

  computed: {
    selectedValuesNames () {
      if (this.filteredOptions.length && this.selectedValues.length) {
        return this.filteredOptions.map(el => {
          if (this.selectedValues.includes(el.id)) {
            return el.name
          }
        })
      } else return null
    },
    scrollContainerStyle () {
      return {
        height: this.filteredOptions.length > 7 ? '40vh' : this.checkboxSizePx * this.filteredOptions.length + 30 + 'px',
      }
    },
    filteredOptions () {
      let opts = this.filterOptions
      if (this.activeAttrs.length) {
        opts = opts.filter(opt => this.activeAttrs.indexOf(opt.id) > -1)
      }
      if (!this.filterByName) {
        return opts
      }
      const nameNormalized = this.filterByName.toLowerCase()

      return opts.filter(item => item.name.toLowerCase().includes(nameNormalized))
    },
  },

  methods: {
    toggleVisibility () {
      this.isVisible = !this.isVisible
      if (!this.isVisible) {
        // При скрытии нужно сбрасывать чекбоксы
        this.syncSelected()
      }
    },
    autoHide (evt) {
      if (!this.$el.contains(evt.target) && this.isVisible) {
        this.hide()
      }
    },
    hide () {
      if (this.isVisible) {
        this.isVisible = false
        // При скрытии нужно сбрасывать чекбоксы
        this.syncSelected()
      }
    },
    syncSelected () {
      this.selectedItems = this.selectedValues
    },
    applySelected () {
      this.$emit('items-selected', [...this.selectedItems])
      this.hide()
    },
    clear () {
      this.selectedItems = []
      this.applySelected()
    },
  },

  created () {
    this.syncSelected()
  },

  watch: {
    filterOptions () {
      this.syncSelected()
    },
    selectedValues () {
      this.syncSelected()
    },
    isVisible (val) {
      if (val) {
        document.addEventListener('click', this.autoHide, false)
      } else {
        document.removeEventListener('click', this.autoHide, false)
      }
    },
  },

  // mounted () {
  //   document.addEventListener('click', this.autoHide, false)
  // },
  // destroyed () {
  //   document.removeEventListener('click', this.autoHide, false)
  // },
}
</script>

<style lang="scss">
  @import 'src/styles/global-z-indexes';
  @import 'src/styles/variables';

  $opened-list-bg:         #ffffff;
  $opened-list-box-shadow: 0 0 5px lightgrey;

  .FilterItem {
    position: relative;
    display:  flex;
    flex-direction: column;
    gap: 8px;
    z-index:  $z-item-filter-container;
    width: fit-content;
    .check {
      margin-bottom: 0;
      padding: 8px;
      .checkbox {
        input {
          display: block;
          opacity: 0;
          z-index: 11;
          cursor: pointer;
        }
        svg {
          position: absolute;
        }
      }
      label {
        cursor: pointer;
        color: var(--Text-Text_Primary);
      }
    }
    label {
      color: var(--Button-States-Pressed);
    }

    &__isOpened {
      z-index: $z-item-filter-container + 1;
    }

    &__drop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 10px 12px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--Button-States-Pressed);
      font-size: 14px;
      background: var(--Basic-White);
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
      }
      svg {
        min-width: 20px;
      }

      .FilterItem__isOpened & {
        svg { transform: rotate(180deg) translateY(-2px); }
      }
    }

    &__mainContainer {
      border-radius: 8px;
      border: 1px solid var(--Dividers);
      width:      200px;
      position:   absolute;
      top: calc(100% + 8px);
      left: 0;
      background: var(--Basic-White);
    }

    &__optionsList {
      padding-right: 8px;
      scrollbar-width: thin;
      overflow-y: scroll;
      display:        flex;
      flex-direction: column;
      color: var(--Text-Text_Primary);
      font-size:      14px;
      gap: 8px;
    }

    &__isOpened {
      filter: drop-shadow($opened-list-box-shadow);
    }

    &__isOpened &__title {
      position:         relative;
      background-color: $opened-list-bg;
    }

    // &__search {
    //   height: 40px;
    // }

    // &__textInput {
    //   padding: 0 10px;
    //   outline: none;
    //   border:  none;
    // }

    &__actions {
      border-top: 1px solid var(--Dividers);
      display:         flex;
      justify-content: space-between;
      padding: 10px;
    }
  }
</style>
