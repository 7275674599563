import { parse } from 'svg-parser'
import UUID from 'uuid/v4'
import ReverseTools from 'svg-path-reverse'
import memoize from 'lodash.memoize'
import {svgPathProperties} from 'svg-path-properties'
import { MATCH_DELTA, matchPoints, pointsDistance } from './point'
import { makeAbsolute, parseSVG } from 'svg-path-parser'
import { groupCollapsed, groupEnd, log } from '../utils/logger'
import { simplifyPath } from './simplification'
import pointInPolygon from 'point-in-svg-polygon'

/**
 * Get starting and ending point of a SVG.Path
 * @param {String} path SVG.Path d attribute
 * @return {[{x: Number, y: Number}, {x: Number, y: Number}]}
 */
export const extractEdgePoints = path => {
  try {
    const props = svgPathProperties(path)
    if (props && props.getTotalLength && props.getPointAtLength) {
      const length = props.getTotalLength()
      const firstCommand = props.getPointAtLength(0)
      const lastCommand = props.getPointAtLength(length)
      return [firstCommand, lastCommand]
    } else {
      log('failed to get points', path)
      return [{x: 0, y: 0}, {x: 0, y: 0}]
    }
  } catch (e) {
    log('failed to get points', path)
    return [{x: 0, y: 0}, {x: 0, y: 0}]
  }
}

/**
 * Reverse path attribute d
 * @param {String} path - SVG.Path d attribute
 * @return {String}
 */
export const reversePath = path => {
  const absolutePath = ReverseTools.normalize(path)
  let reversedPath = ReverseTools.reverseNormalized(absolutePath)
  if (!reversedPath.match(/^(m|\s+m)/i)) {
    reversedPath = 'M' + reversedPath
  }
  return reversedPath
}

/**
 * Extrude an array of paths from svg
 * @param {String} svg Contents of svg file
 * @return {[{attributes: {d: String}}]}
 */
export const paths = (svg) => {
  if (svg) {
    const svgObject = parse(svg)
    if (svgObject.name === 'svg') {
      return svgObject.children.filter(el => el.name === 'path')
    }
    return [svgObject]
  }
  return []
}

/**
 * Get path length
 * @param {String} path SVG.Path data attribute
 * @returns {Number}
 */
export const pathLength = path => {
  const props = svgPathProperties(path)
  return props.getTotalLength()
}

/**
 * Remove Z from end of SVG.Path data attribute
 * Main purpose is to prepare path for concatenation
 * @param {String} path SVG.Path data attribute
 * @returns {String}
 */
export const cutPathEnd = path => path.replace(/(\s+z|z)$/i, '')

/**
 * Replace start of path to start of line command (M -> L)
 * Purpose is to prepare path for concatenation
 * @param {String} path SVG.Path data attribute
 * @returns {String}
 */
export const cutPathHead = path => path.replace(/^(m|\s+m)/i, 'L')

/**
 * Check if point array already contains given point
 * @param {[{x: Number, y: Number}]} contourPoints
 * @param {{x: Number, y: Number}} point
 * @param delta
 * @returns {boolean}
 */
const pointsContainsPoint = (contourPoints, point, delta = MATCH_DELTA) => {
  let match = false
  for (let i = 0; i < contourPoints.length; i++) {
    const currentPoint = contourPoints[i]
    if (matchPoints(currentPoint, point, delta)) {
      match = true
      break
    }
  }
  return match
}

/**
 * Check if contour contains given point
 * @param {
 *    {
 *      attributes: {d: String}
 *    }
 * } contour
 * @param {{x: Number, y: Number}} point
 * @param delta
 * @returns {boolean}
 */
export const isPointOnPath = (contour, point, delta = MATCH_DELTA) => {
  return pointsContainsPoint(pathPoints(contour), point, delta)
}

/**
 * Extract path points
 * @param {
 *    {
 *      attributes: {d: String}
 *    }
 * } contour
 * @returns {[{x: Number, y: Number}]}
 */
export const pathPoints = (contour) => {
  let points = []
  const contourProps = svgPathProperties(contour)
  if (contourProps) {
    const length = contourProps.getTotalLength()
    if (length) {
      for (let i = 0; i < length; i++) {
        const currentPoint = contourProps.getPointAtLength(i)
        points.push(currentPoint)
      }
    }
  }
  return points
}

/**
 * Calculate path bounding box
 * @param {{attributes: {d:String}}|String} d
 * @returns {[Number, Number, Number, Number]}
 */
export const pathBounds = d => {
  const commandString = typeof d === 'string' ? d : d.attributes.d
  const path = makeAbsolute(parseSVG(commandString))
  let bounds = [Infinity, Infinity, -Infinity, -Infinity]

  for (let i = 0, l = path.length; i < l; i++) {
    let {x, y} = path[i]

    if (x < bounds[0]) bounds[0] = x
    if (y < bounds[1]) bounds[1] = y
    if (x > bounds[2]) bounds[2] = x
    if (y > bounds[3]) bounds[3] = y
  }

  return bounds
}

/**
 * Get bounding boxes of path parts, left after intersection with point
 * When bounding boxes are calculated, lineclip algorithm can be applied to get splitted path
 * @param {{attributes: {d: String}}|String} path
 * @param {{x, y}} intersection
 */
export const slicedBounds = (path, intersection) => {
  const commandString = typeof d === 'string' ? path : path.attributes.d
  const [x1, y1, x2, y2] = pathBounds(commandString)
  const {x, y} = intersection
  if (Math.abs(y1 - y) < MATCH_DELTA && Math.abs(y2 - y) < MATCH_DELTA) {
    return [[x1, y1, x, y2], [x, y1, x2, y2]]
  } else {
    return [[x1, y1, x2, y], [x1, y, x2, y2]]
  }
}

/**
 * Check paths for each other injection
 * @param {{attributes: {d: String}}|String} injectable
 * @param {{attributes: {d: String}}|String} injectee injectee path
 * @param {Number} delta Precision
 * @return {number}
 */
export const comparePathEdges = (injectable, injectee, delta) => {
  const [start, end] = extractEdgePoints(typeof injectable === 'string' ? injectable : injectable.attributes.d)
  const [matchStart, matchEnd] = extractEdgePoints(typeof injectee === 'string' ? injectee : injectee.attributes.d)
  const deltaEntropy = +delta + (+delta / 2)
  if (pointsDistance(matchStart, start) <= deltaEntropy &&
    pointsDistance(matchEnd, end) <= deltaEntropy) {
    return -1 // reversed fit
  } else if (pointsDistance(matchStart, end) <= deltaEntropy &&
    pointsDistance(matchEnd, start) <= deltaEntropy) {
    return 1 // regular fit
  } else {
    return 0 // does not fit at all
  }
}

/**
 * Check if injected path edge points match with injectable path edge points
 * @param {[{attributes: {d: String}}]} injectablePaths - Array of SVG.Paths
 * @param {{attributes: {d: String}}} contour - SVG.Path d attribute
 * @param {Number} delta Precision of match search
 * @return {{doesAllow:Boolean, injectable:Array, ordinary:Array}}
 */
export const doesPathAllowsInjection = (injectablePaths, contour, delta = MATCH_DELTA) => {
  const ordinary = []
  const injectable = []
  injectablePaths.forEach(path => {
    const diff = comparePathEdges(path, contour, delta)
    const quadDiff = comparePathEdges(path, contour, +delta * 4)
    if (diff) {
      injectable.push(path)
      groupCollapsed('path fits')
      log('path', contour.attributes.d)
      if (diff === -1) {
        log('start to start and end to end')
      }
      if (diff === 1) {
        log('start to end and end to start')
      }
      groupEnd()
    } else if (quadDiff) {
      injectable.push(path)
      groupCollapsed('did not fit, but almost')
      if (quadDiff === -1) {
        log('start to start and end to end')
      }
      if (quadDiff === 1) {
        log('start to end and end to start')
      }
      groupEnd()
    } else {
      ordinary.push(path)
    }
  })
  return {doesAllow: !!injectable.length, injectable, ordinary}
}

/**
 * Simplify SVG.Path data attribute to lines
 * @param {
 *    {
 *      attributes: {d: String}
 *    }
 * } pathElement Simplified path object
 * @returns {{attributes: {d: (string|null|String)}}}
 */
const simplifier = pathElement => ({
  ...pathElement,
  attributes: {
    ...pathElement.attributes,
    d: simplifyPath(pathElement.attributes.d)
  }
})

/**
 * Apply simplification on array of compound paths and throw out too short ones
 * @param {[
 *    {
 *      attributes: {d: String}
 *    }
 * ]} pathArray
 * @returns {[
 *    {
 *      attributes: {d: String}
 *    }
 * ]}
 */
export const simplifyMapper = pathArray => {
  return pathArray.map(simplifier).filter(p => p.attributes.d)
}

/**
 * Check if point inside (or on) svg polygon
 * @param {String} polygon SVG<Path> d attribute
 * @param {[{x: Number, y: Number}]} points
 */
// eslint-disable-next-line no-unused-vars
const filterInsidePoints = (polygon, points) => contourPoints(points, polygon, false)

/**
 * Check if point outside and does not even belongs to svg polygon
 * @param {String|{attributes: {d: String}}} contour SVG<Path> d attribute
 * @param {[{x: Number, y: Number}]} points
 */
const filterNotOutsidePoints = (contour, points) => {
  const contourD = typeof contour === 'string' ? contour : contour.attributes.d
  return contourPoints(points, contourD, true)
}

/**
 * Memoize split to segments calculation function
 * @returns {Function}
 */
const splitSegments = memoize((d) => {
  return pointInPolygon.segments(d)
})

/**
 * Separate points with ones that contained inside of contour
 * @param {[{x: Number, y: Number}]} points
 * @param {[{}]|String} contour Ready segments or SVG.Path data attribute
 * @param {boolean} includeBound
 * @returns {Array}
 */
const contourPoints = (points, contour, includeBound) => {
  let segments
  if (contour && Array.isArray(contour)) {
    segments = contour
  } else {
    segments = splitSegments(contour)
  }

  let result = []
  const contourPoints = includeBound ? pathPoints(contour) : null

  for (let i = 0; i < points.length; i++) {
    const point = points[i]
    if (pointInPolygon.isInside([point.x, point.y], segments) ||
       (includeBound && pointsContainsPoint(contourPoints, point, 0.1))) {
      result.push(point)
    }
  }
  return result
}

/**
 * Get middle point of path
 * @param {{attributes: {d: String}}} path
 * @param {{getTotalLength: Function, getPointAtLength: Function}} pathParams
 * @param position - Position on path in [0,1]
 */
const pathPointAtLength = (path, pathParams, position = MATCH_DELTA) => {
  try {
    const pathHalfLength = Math.round(pathParams.getTotalLength() * position)
    if (pathParams.getTotalLength() > 0) {
      return pathParams.getPointAtLength(pathHalfLength)
    } else {
      throw new Error('part length is zero')
    }
  } catch (e) {
    log('failed to find middle point', path, e)
    const commands = makeAbsolute(parseSVG(path.attributes.d))
    return {
      x: (commands[0].x + commands.pop().x) * position,
      y: (commands[0].y + commands.pop().y) * position
    }
  }
}

/**
 * Get point array of path filtering duplicating by delta points
 * @param {{attributes: {d: String}}} path SVG.Path
 * @param {Number} count Path split count
 * @returns {Array}
 */
export const pathUniqPoints = (path, count = 100) => {
  const positions = Array.from({length: count}, (_, i) => i / count)
  const pathParams = svgPathProperties(path.attributes.d)

  let points = []
  let lastPoint = null
  for (let i = 0; i < positions.length; i++) {
    const position = positions[i]
    const point = pathPointAtLength(path, pathParams, position)
    if (lastPoint == null || !matchPoints(lastPoint, point, 0.1)) {
      points.push(point)
      lastPoint = point
    }
  }
  return points
}

/**
 * Calculate path strictly inside contour part weight
 * @param {String} contourD
 * @param {{attributes: {d: String}}} path
 * @param count
 * @returns {Array}
 */
export const pathInsideContourPositions = (contourD, path, count = 100) => {
  return filterInsidePoints(contourD, pathUniqPoints(path, count))
}

/**
 * Calculate path inside and on contour part weight
 * @param {String} contourD
 * @param {{attributes: {d: String}}} path
 * @param count
 * @returns {Array}
 */
export const pathNotOutsideContourPositions = (contourD, path, count = 100) => {
  return filterNotOutsidePoints(contourD, pathUniqPoints(path, count))
}

/**
 * Prepare path object to procession
 * @param {{attributes: {d: String}}} path
 * @return {
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: false,
 *      uuid: String
 *    }
 * }
 */
export const formatPathObject = path => {
  const simplePath = simplifyPath(path.attributes.d)
  const reversedPath = simplePath ? reversePath(simplePath) : null
  return Object.assign({}, path, {
    attributes: Object.assign({}, path.attributes, {
      d: simplePath,
      originalD: path.attributes.d,
      reversedD: reversedPath
    }),
    edges: extractEdgePoints(simplePath),
    shouldBeReversed: false,
    failedSteps: [],
    uuid: UUID()
  })
}
