<template>
  <layout-with-header>
    <div class="signUp" v-if="!isSended">
      <p class="title">Create your account</p>
      <p class="subtitle">Already have an account? <a class="pink-link" href="/login">Log in</a></p>
      <div class="input-block">
        <p class="label">Subscription Plan</p>
        <q-select
          class="label_mobile avenir-font-regular"
          dense
          behavior="menu"
          outlined
          v-model="valueOption"
          option-label="name"
          :options="options"
          dropdown-icon='expand_more'
        />
      </div>
      <div :class="`input-block ${v$.firstName.$error ? 'error-input' : ''}`">
        <p class="label">First Name</p>
        <q-input
          v-model="v$.firstName.$model"
          placeholder="Oliver"
          outlined
        />
        <div class="error" v-if="v$.firstName.$error">
          <p>First Name should include 1-50 symbols</p>
        </div>
      </div>
      <div class="input-block">
        <p class="label">Last Name</p>
        <q-input
            v-model="v$.lastName.$model"
            :class="v$.lastName.$error ? 'error-input' : ''"
            outlined
            placeholder="Smith"
        />
        <div class="error" v-if="v$.lastName.$error">
          <p>Last Name should include 1-50 symbols</p>
        </div>
      </div>
      <div class="input-block">
        <p class="label">E-mail</p>
        <q-input
            v-model="v$.email.$model"
            :class="v$.email.$error ? 'error-input' : ''"
            outlined
            placeholder="name@example.com"
        />
        <div class="error" v-if="v$.email.$error">
          <p>An E-mail should be in the correct format: name@example.com</p>
        </div>
      </div>
      <div class="input-block">
        <p class="label">Password</p>
        <div class="password">
          <svg v-if="isShowPass" @click="isShowPass = false" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25_1587)">
              <path d="M8.3335 9.99992C8.3335 10.4419 8.50909 10.8659 8.82165 11.1784C9.13421 11.491 9.55814 11.6666 10.0002 11.6666C10.4422 11.6666 10.8661 11.491 11.1787 11.1784C11.4912 10.8659 11.6668 10.4419 11.6668 9.99992C11.6668 9.55789 11.4912 9.13397 11.1787 8.82141C10.8661 8.50885 10.4422 8.33325 10.0002 8.33325C9.55814 8.33325 9.13421 8.50885 8.82165 8.82141C8.50909 9.13397 8.3335 9.55789 8.3335 9.99992Z" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.5 10C15.5 13.3333 13 15 10 15C7 15 4.5 13.3333 2.5 10C4.5 6.66667 7 5 10 5C13 5 15.5 6.66667 17.5 10Z" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_25_1587">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <svg v-else @click="isShowPass = true" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25_1588)">
              <path d="M8.82097 8.82251C8.50845 9.13513 8.33293 9.5591 8.33301 10.0011C8.33309 10.4432 8.50876 10.8671 8.82138 11.1796C9.13401 11.4921 9.55797 11.6676 10 11.6676C10.4421 11.6675 10.866 11.4918 11.1785 11.1792" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.9008 13.8942C12.7319 14.6256 11.3789 15.0091 10 15C7 15 4.5 13.3334 2.5 10C3.56 8.23336 4.76 6.93503 6.1 6.10503M8.48333 5.15002C8.98253 5.04897 9.49068 4.99871 10 5.00003C13 5.00003 15.5 6.66669 17.5 10C16.945 10.925 16.3508 11.7225 15.7183 12.3917" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.5 2.5L17.5 17.5" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_25_1588">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <q-input
            v-model="v$.password.$model"
            outlined
            :type="isShowPass ? 'text' : 'password'"
            :class="!validPassword && v$.password.$dirty ? 'error-input' : ''"
            placeholder="Create Password"
          />
        </div>
        <div class="pass_valid" v-if="v$.password.$dirty">
          <p class="title_valid">Password must:</p>
          <div class="check" v-for="item in validPass" :key="item.message">
            <label :class="'checkbox ' + (item.error ? 'selected' : 'error-check')">
              <input type="checkbox" disabled />
              <svg v-if="item.error" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </label>
            <p>{{item.message}}</p>
          </div>
        </div>
      </div>
      <div class="input-block">
        <p class="label">Phone Number</p>
        <vue-tel-input
          :class="
            'valid' in phoneWithCountry && !phoneWithCountry.valid
            ? 'error-phone-input'
            : focusedPhone ? 'focused-phone' : ''"
          v-model="phone"
          @focus="focusedPhone = true"
          @validate="validatePhone"
        />
        <div class="error" v-if="'valid' in phoneWithCountry && !phoneWithCountry.valid">
          <p>A Phone Number should be in the correct format</p>
        </div>
      </div>
      <div v-if="valueOption.value !== 'INDIE_EA' && valueOption.value !== 'STUDENT_EA'">
        <div class="input-block">
          <p class="label">Company</p>
          <q-input
              v-model="company"
              outlined
              placeholder="Company Name"
          />
        </div>
        <div class="input-block">
          <p class="label">Position</p>
          <q-input
              v-model="position"
              outlined
              placeholder="Job title / Occupation"
          />
        </div>
        <div class="input-block">
          <p class="label">Company revenue</p>
          <q-select
            class="label_mobile avenir-font-regular"
            dense
            outlined
            :display-value="companyRevenue ? companyRevenue.title : 'Select Option'"
            v-model="companyRevenue"
            option-label="title"
            :options="companyRevenueOptions"
            dropdown-icon='expand_more'
            behavior="menu"
          />
        </div>
      </div>
      <div class="check">
        <label :class="`checkbox ${checked ? 'selected' : ''}`">
          <input
            v-model="checked"
            type="checkbox"
          />
          <svg v-if="checked" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </label>
        <p>I agree with PatternFast’s <a class="pink-link" href="/terms-and-conditions" target="_blank">Terms of Service.</a></p>
      </div>
      <button :disabled="!valid" class="green_button" style="width:100%" @click.prevent="submit">
        Create Account
      </button>
      <template v-if="globalError">
        <p v-if="!globalError?.includes(';')" class="global-error">{{ globalError[0].toUpperCase() + globalError.slice(1) }}</p>
        <template v-else>
          <p v-for="item in globalError.split(';')" :key="item" class="global-error">{{ item[0].toUpperCase() + item.slice(1) }}</p>
        </template>
      </template>
    </div>
    <signup-problem :firstName="firstName" :lastName="lastName" :user="email" v-else />
  </layout-with-header>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required, email } from '@vuelidate/validators'
import { register } from '@/api'
import LayoutWithHeader from '@/layouts/LayoutWithHeader'
import signupProblem from '@/components/user/signupProblem'
import { billingPlans } from '../helpers/plans'

const validateEmail = (email) => {
  const emailParts = email.split('@')
  const username = emailParts[0]
  // Проверка имени пользователя
  const usernameRegex = /^[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9]$/
  if (!usernameRegex.test(username)) {
    return false
  }
  return true
}
export default {
  components: {
    LayoutWithHeader,
    signupProblem,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  name: 'EaseSelectOption',
  data: () => ({
    userId: null,
    isSended: false,
    globalError: false,
    focusedPhone: false,
    phoneWithCountry: {},
    companyRevenue: null,
    companyRevenueOptions: [
      { title: '$0-100k', min: 0, max: 100000 },
      { title: '$100k-500k', min: 100000, max: 500000 },
      { title: '$500k-1m', min: 500000, max: 1000000 },
      { title: '$1m-2m', min: 1000000, max: 2000000 },
      { title: '$2m-5m', min: 2000000, max: 5000000 },
      { title: '$5m-10m', min: 5000000, max: 10000000 },
      { title: '$10m+', min: 10000000, max: 0 },
    ],
    phone: null,
    position: null,
    company: null,
    isShowPass: false,
    checked: null,
    password: null,
    firstName: null,
    lastName: null,
    email: null,
    altEmail: null,
    valueOption: { name: 'Startup Early Adopter', value: 'STARTUP_EA' },
  }),
  validations () {
    return {
      password: {
        required,
      },
      firstName: {
        required,
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
        maxLength: maxLength(320),
        validateEmail,
      },
    }
  },
  computed: {
    options () {
      return billingPlans
        .filter(el => el.code !== 'INDIE_MTM' && el.code !== 'GROWING_MTM')
        .map(p => ({ name: p.title, value: p.code }))
    },
    validPass () {
      return {
        passLength: {
          message: 'Contain 8 to 30 characters',
          error: this.password?.length > 8 && this.password?.length < 30,
        },
        upperLowerCase: {
          message: 'Contain both lower and uppercase letters',
          error: /[a-z]/.test(this.password) && /[A-Z]/.test(this.password),
        },
        num: {
          message: 'Contain 1 number',
          error: /\d/.test(this.password),
        },
        spec: {
          message: "Contain 1 special charachter +!'$@%&/()=`#",
          error: /[+!'$@%&/()=`#]/.test(this.password),
        },
      }
    },
    validPassword () {
      const valid = this.validPass
      return valid.passLength.error && valid.upperLowerCase.error && valid.num.error && valid.spec.error
    },
    valid () {
      if (
        this.validPassword &&
        this.checked && this.phoneWithCountry?.valid && !this.v$.$invalid
      ) return true
      else return false
    },
  },
  methods: {
    validatePhone (val) {
      this.phoneWithCountry = val
    },
    async submit () {
      if (this.valid) {
        try {
          this.globalError = false
          let data = {
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            email: this.email,
            phoneNumber: this.phoneWithCountry?.number,
            countryCode: this.phoneWithCountry?.countryCode,
            plan: this.valueOption.value,
          }
          if (this.company) data.companyName = this.company
          if (this.position) data.jobTitle = this.position
          if (this.companyRevenue?.min) data.revenueMin = this.companyRevenue.min
          if (this.companyRevenue?.max) data.revenueMax = this.companyRevenue.max
          let reqData = await register(data)
          this.userId = reqData.user.id
          this.isSended = true
        } catch (e) {
          this.globalError = e.message
        }
      } else return null
    },
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$route.params.selected) {
      this.valueOption = this.options.find(el => el.value === this.$route.params.selected)
    }
  },
}
</script>

<style lang="scss">
.signUp {
  max-width: 544px;
  border-radius: 12px;
  padding: 56px;
  background-color: white;
  margin: 128px auto;
  .green_button {
    margin-top: 32px;
  }
  .global-error {
    color: #E30000;
  }
  .pass_valid {
    margin-top: 8px;
    .title_valid {
      margin-bottom: 8px;
    }
    .check {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }
  .check {
    margin-top: 58px;
    margin-bottom: 0;
  }
  .subtitle {
    margin-bottom: 32px;
  }
  p {
    font-size: 16px;
    margin-bottom: 0;
  }
  button {
    width: 100%;
    border-radius: 12px;
    min-height: 56px;
  }
}
@media (max-width: 480px) {
  .signUp {
    padding: 32px 16px 85px;
    margin: 0;
  }
}
</style>
