<template>
  <div
    class="toolbar"
    :class="{Toolbar_vertical: vertical}"
  >
    <div class="toolbar__tabs">
      <div class="toolbar__tabs__tab" @click="$emit('select', 'undo')">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32" width="32" height="32">
          <path
            :fill="undoAvailable ? '#09373d' : '#b2c3c4'"
            d="M25.3 9.4c-1.4-2-3.4-3.5-5.7-4.3-2.3-.8-4.8-.8-7.1 0-1.9.6-3.7 1.8-5 3.3V4c0-.4-.3-.8-.8-.8s-.8.4-.8.8v6.7c0 .4.3.8.8.8h6.7c.4 0 .8-.3.8-.8s-.3-.8-.8-.8H8.2c1.2-1.5 2.9-2.7 4.7-3.3 2-.7 4.2-.7 6.2 0s3.8 2 5 3.7c1.2 1.7 1.9 3.8 1.8 5.9 0 2.1-.8 4.2-2.1 5.9-1.3 1.7-3.1 2.9-5.1 3.5-2 .6-4.2.5-6.2-.3-2-.7-3.7-2.1-4.8-3.9-.2-.3-.7-.4-1-.2-.3.2-.4.7-.2 1 1.3 2 3.3 3.6 5.6 4.5 1.3.5 2.6.7 4 .7 1.1 0 2.1-.1 3.1-.4 2.3-.7 4.4-2.1 5.9-4s2.3-4.3 2.4-6.7c0-2.5-.8-4.9-2.2-6.9z"/>
        </svg>
        <div class="tooltiptext">Undo</div>
      </div>
      <div class="toolbar__tabs__tab" @click="$emit('select', 'redo')">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32" width="32" height="32">
          <path
            :fill="redoAvailable ? '#09373d' : '#b2c3c4'"
            d="M25.3 3.3c-.4 0-.8.3-.8.8v4.5c-1.3-1.5-3.1-2.7-5-3.3-2.3-.8-4.8-.8-7.1 0S8.1 7.5 6.7 9.6c-1.4 2-2.1 4.4-2.1 6.8 0 2.3.9 4.6 2.4 6.6 1.5 1.9 3.6 3.3 5.9 4 1 .3 2.1.4 3.1.4 1.4 0 2.7-.2 4-.7 2.3-.9 4.2-2.4 5.6-4.5.2-.3.1-.8-.2-1-.3-.2-.8-.1-1 .2-1.2 1.8-2.8 3.1-4.8 3.9-2 .7-4.2.8-6.2.3s-3.8-1.8-5.1-3.5c-1.3-1.7-2-3.7-2.1-5.9 0-2.1.6-4.2 1.8-5.9 1.2-1.7 3-3 5-3.7 2-.7 4.2-.7 6.2 0 1.9.6 3.5 1.8 4.7 3.3h-5.2c-.4 0-.8.3-.8.8s.3.8.8.8h6.7c.4 0 .8-.3.8-.8V4c-.1-.4-.5-.7-.9-.7z"/>
        </svg>
        <div class="tooltiptext">Redo</div>
      </div>
      <div class="toolbar__tabs__tab" @click="$emit('select', 'measurements')">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32" width="32" height="32">
          <path fill="#09373d" d="M28 17h-7.7V9c0-2.7-2.5-5.7-7.8-5.7-4.5 0-9.2 2.8-9.2 7.6 0 10.4 0 17.9 8.2 17.9h17.3V17H28zM12.5 4.8c4.3 0 6.3 2.1 6.3 4.2v.1c0 1-.4 1.9-1.2 2.8-.3.3-1 .7-1.9.9V7.6l-.6-.1c-.5-.1-4.6-.8-6.6.8-.7.5-1 1.3-1 2.2v4.9c-1.7-1.1-2.7-2.7-2.7-4.5 0-3.8 4-6.1 7.7-6.1zm2.6 9.7c1.6-.3 2.9-.8 3.6-1.6l.1-.1V17c-2.1 0-4.2-.1-6.3-.1-1.2 0-2.4-.2-3.5-.7v-2.6c1.6 1.1 4.2 1.2 6.1.9zm-6.1-4c0-.4.1-.8.5-1 1-.8 3.4-.7 4.7-.6v4.2c-1.9.2-3.6-.1-4.4-.9-.6-.4-.8-1-.8-1.7zm18.2 16.8h-2.7V25H23v2.3h-2.7v-5h-1.5v5h-2.6V25h-1.5v2.3h-2.6v-5h-1.5v4.9c-4.8-.3-5.7-4.3-5.8-12.2.8.9 1.8 1.8 3.1 2.3 1.4.6 3 1 4.5 1 2.3.1 4.8.1 7.1.1h7.7v8.9z"/>
        </svg>
        <div class="tooltiptext">Specs</div>
      </div>
      <div class="toolbar__tabs__tab" @click="$emit('select', 'fit')">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32" width="32" height="32">
          <path fill="#09373D" d="M28 19.3h-1.3c-.4 0-.8.3-.8.8s.3.8.8.8H28c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zm-6.7-2.7c-.4 0-.8.3-.8.8v1.9H4c-.4 0-.8.3-.8.8s.3.8.8.8h16.6v1.9c0 .4.3.8.8.8s.8-.3.8-.8v-5.3c-.1-.6-.5-.9-.9-.9zm-2.6-3.8H28c.4 0 .8-.3.8-.8s-.3-.8-.8-.8h-9.3c-.4 0-.8.3-.8.8s.4.8.8.8zM4 12.8h8.6v1.9c0 .4.3.8.8.8s.8-.3.8-.8V9.3c0-.4-.3-.8-.8-.8s-.8.3-.8.8v1.9H4c-.4 0-.8.3-.8.8s.4.8.8.8z"/>
        </svg>
        <div class="tooltiptext">Adjust</div>
      </div>
      <div class="toolbar__tabs__tab" @click="$emit('select', 'auto-grading')">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32" width="32" height="32">
          <path fill="#09373D" d="M24 21.9c-1.1 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6zm-8-2.7c-1.1 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1c1.1 0 2.1-.9 2.1-2.1s-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6zm-8-2.7c-1.1 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6zm16-10.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1s2.1-.9 2.1-2.1c0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6zm-8-2.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1 1.1 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6zm-8-2.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1s2.1-.9 2.1-2.1c0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6zm16-6.5c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1s-2.1 1-2.1 2.1 1 2.1 2.1 2.1zm0-2.7c.3 0 .6.3.6.6s-.3.6-.6.6-.6-.3-.6-.6.3-.6.6-.6zm-8-1.5c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1 1.1 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6zM8 5.9c-1.1 0-2.1 1-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1c0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6z"/>
        </svg>
        <div class="tooltiptext">Auto Grading</div>
      </div>
      <div class="toolbar__tabs__tab" @click="$emit('select', 'tech-pack')" :disable="patternsPreviewError">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32" width="32" height="32">
          <path fill="#09373D" d="m25.5 10.3-5.9-6.4c-.4-.4-1-.7-1.5-.7H8c-1.1 0-2.1.9-2.1 2.1v21.3c0 1.1.9 2.1 2.1 2.1h16c1.1 0 2.1-.9 2.1-2.1V11.7c0-.5-.2-1-.6-1.4zm-7-5.4 5.8 6.3h-5.7c-.3 0-.6-.3-.6-.6V4.8c.2 0 .4 0 .5.1zM24 27.3H8c-.3 0-.6-.3-.6-.6V5.3c0-.3.3-.5.6-.5h8.6v5.9c0 1.1.9 2.1 2.1 2.1h5.9v13.9c0 .3-.3.6-.6.6z"/>
          <path fill="#09373D" d="M20 21.9h-8c-.4 0-.8.3-.8.8s.3.8.8.8h8c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zm0-4h-8c-.4 0-.8.3-.8.8s.3.8.8.8h8c.4 0 .8-.3.8-.8s-.4-.8-.8-.8z"/>
        </svg>
        <div class="tooltiptext">Tech Pack</div>
      </div>
      <div class="toolbar__tabs__tab" @click="$emit('select', 'marker')">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 26 26" width="32" height="32">
          <path fill="#09373d" d="M19.5 9.6h-6.4V5C13.1 2.4 11 .2 8.3.2h-3C2.7.3.6 2.4.6 5v15.6c0 2.5 2.1 4.6 4.6 4.6h14.3c3.2 0 5.8-2.6 5.8-5.8v-4.2c0-3-2.6-5.6-5.8-5.6zM5.3 1.8h3.1c1.8 0 3.2 1.5 3.2 3.2v11.6c-.5-.3-1.2-.6-1.8-.6H5.2c-1.2 0-2.3.5-3.1 1.2V5c0-1.8 1.4-3.2 3.2-3.2zm18.5 17.7c0 2.3-1.9 4.2-4.2 4.2H5.2c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1h4.6c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8H5.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h4.8v-1.5H5.3c-1.1 0-2 .9-2 2s.9 2 2 2h4.5c1.8 0 3.2-1.4 3.3-3.2V11h6.4c2.3 0 4.2 1.9 4.2 4.2v4.3z"/>
        </svg>
        <div class="tooltiptext">Marker</div>
      </div>
      <div class="toolbar__tabs__tab disable">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32" width="32" height="32">
          <path fill="#09373D" d="M12.6 5.6c-1.8-1.8-4.8-1.8-6.6 0-1.8 1.8-1.8 4.8 0 6.6l14.1 14.1c.9.9 2.1 1.4 3.3 1.4 1.2 0 2.4-.5 3.3-1.4.9-.9 1.4-2.1 1.4-3.3 0-1.2-.5-2.4-1.4-3.3L12.6 5.6zm13 19.7c-1.2 1.2-3.3 1.2-4.5 0L7 11.2C5.8 9.9 5.8 7.9 7 6.7c1.2-1.2 3.2-1.2 4.5 0l14.1 14.1c1.3 1.3 1.3 3.3 0 4.5zM17.1 6l2.2 1 1.1 2.2c.1.2.4.4.6.4s.5-.1.6-.4L22.7 7l2.2-1c.2-.1.4-.4.4-.6 0-.3-.1-.5-.4-.6l-2.2-1.1-1.1-2.2c-.2-.5-1-.5-1.3 0l-1.1 2.2L17 4.8c-.2.1-.4.4-.4.6.2.2.3.4.5.6zm2.7-1.5.3-.3.9-1.7.9 1.7.3.3 1.7.9-1.7.9-.3.3-.9 1.6-.9-1.7c-.1-.1-.2-.3-.3-.3l-1.7-.9 1.7-.8zm9.1 6.9-1.3-.6-.6-1.3c-.2-.5-1-.5-1.3 0l-.6 1.3-1.3.6c-.2.1-.4.4-.4.6 0 .3.1.5.4.6l1.3.6.6 1.3c.1.2.4.4.6.4.3 0 .5-.1.6-.4l.6-1.3 1.3-.6c.2-.1.4-.4.4-.6.1-.3-.1-.5-.3-.6zm-1.8 1-.3.3-.4.8-.4-.8-.3-.3-.9-.4.8-.4.3-.3.4-.8.4.8.3.3.8.4-.7.4zm-16.9 8.3-1.3-.6-.6-1.3c-.2-.5-1-.5-1.3 0l-.6 1.3-1.3.6c-.2.1-.4.4-.4.6s.1.5.4.6l1.3.6.6 1.4c.1.2.4.4.6.4.3 0 .5-.1.6-.4l.6-1.3 1.3-.6c.2-.1.4-.4.4-.6s-.1-.6-.3-.7zm-1.8 1-.3.3-.4.8-.4-.8-.3-.3-.8-.4.8-.4c.1-.1.3-.2.3-.3l.4-.8.4.8.3.3.8.4-.8.4z"/>
        </svg>
        <div class="tooltiptext">AI designer</div>
      </div>
      <div :class="`toolbar__tabs__tab view ${isShowView ? 'active' : ''}`">
        <div @click="isShowView = !isShowView">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32" width="32" height="32">
            <path :fill="isShowView ? '#FF6770' : '#09373d'" d="M25.3 4.6H6.7c-1.1 0-2.1.9-2.1 2.1v18.7c0 1.1.9 2.1 2.1 2.1h18.7c1.1 0 2.1-.9 2.1-2.1V6.7c-.1-1.2-1-2.1-2.2-2.1zM6.1 25.3V6.7c0-.3.3-.6.6-.6h4.6v19.8H6.7c-.4 0-.6-.2-.6-.6zm19.8 0c0 .3-.3.6-.6.6H12.8V6.1h12.6c.3 0 .6.3.6.6v18.6z"/>
          </svg>
          <div class="tooltiptext">View</div>
        </div>
        <div v-if="isShowView" class="view-block" v-click-outside="() => isShowView = false">
          <p>View</p>
          <div class="view-block__switch">
            Flat Sketch
            <Switcher v-model="isShowSketch" @input="$emit('select', 'sketch')"/>
          </div>
          <div class="view-block__switch">
            Flat Pattern
            <Switcher v-model="isShowPattern" @input="$emit('select', 'pattern')"/>
          </div>
        </div>
      </div>
    </div>
    <q-tabs
      :vertical="vertical"
      align="left"
      class="Toolbar__tabs text-black self-stretch"
      :class="{'items-stretch content-stretch col-grow gt-md self-stretch': !vertical}"
      :outside-arrows="false"
      v-model="currentTab"
    >
      <div
        class="btn-mode q-px-md" v-if="insiderSettingsAvailable"
      >
        <q-btn
          title="Draft не работает на проде"
          :disable="currentEnv == 'PROD'"
          :color="draftModeEnabled && currentEnv != 'PROD' ? 'red' : 'secondary'"
          @click="toggleDraft()"
        >
          Draft
        </q-btn>
      </div>
      <div style="margin-left:10px" v-if="insiderSettingsAvailable">
        <q-select
          v-model="currentEnv"
          dense
          options-dense
          borderless
          behavior="menu"
          :options="envOptions"
          dropdown-icon='expand_more'
        >
        </q-select>
      </div>
      <q-btn v-if="$store.state.isLoggedIn && !$store.state.isProduction" title="Copy link to this design"
        color="secondary" @click="$emit('select', 'copy-link')">
        {{ isLinkCopied ? 'Copied' : 'Link' }}
      </q-btn>
      <div
        v-if="!vertical && !$q.screen.lt.sm && !$store.state.isProduction"
        class="q-px-md"
      >
        <q-btn
          color="secondary"
          @click="$router.push('/new-design')"
        >
          New
        </q-btn>
        <q-btn
          class="q-mx-md"
          color="secondary"
          @click="$emit('select', 'sewing')"
        >
          Sewing
        </q-btn>
      </div>
    </q-tabs>
  </div>
</template>

<script>
import Switcher from '@/components/Atoms/Switcher'

export default {
  name: 'Toolbar',
  components: {
    Switcher,
  },

  props: {
    vertical: Boolean,
    sketchActive: Boolean,
    patternActive: Boolean,
    displayNewDesignItem: Boolean,
    undoAvailable: Boolean,
    redoAvailable: Boolean,
    fabricAvailable: Boolean,
    patternsPreviewError: Boolean,
    isLinkCopied: Boolean,
  },

  data: () => ({
    isShowSketch: true,
    isShowPattern: true,
    isShowView: false,
    currentTab: undefined,
    forceDraft: undefined,
    forceProdCatalogue: undefined,
    envOptions: ['TEST', 'PROD'],
    currentEnv: undefined,
  }),

  watch: {
    currentTab (val) {
      if (val) {
        this.currentTab = undefined
      }
    },
    currentEnv (env, oldEnv) {
      if (!env || !oldEnv || env === oldEnv) {
        return
      }
      this.currentEnv = env
      console.log('[debug] change env called')
      document.cookie = `cat_env=${env.toLowerCase()}; Path=/; Expires=Thu, 01 Jan 2038 00:00:01 GMT;`
      window.pageDesigner.getPatternsPreview(true)
    },
  },

  methods: {
    toggleDraft () {
      document.cookie = `draft=${this.draftModeEnabled ? 0 : 1}; Path=/; Expires=Thu, 01 Jan 2038 00:00:01 GMT;`
      this.forceDraft = !this.draftModeEnabled
      window.pageDesigner.getPatternsPreview(true)
    },
  },

  mounted () {
    const m = document.cookie.match(/cat_env=(test|prod|dev)/)
    if (m && m[1]) {
      this.currentEnv = m[1].toUpperCase()
    } else {
      this.currentEnv = 'PROD'
    }
  },

  computed: {
    insiderSettingsAvailable () {
      return !this.$store.state.isProduction && this.$store.state.isLoggedIn
    },
    draftModeEnabled () {
      if (this.forceDraft !== undefined) {
        return this.forceDraft
      }
      return document.cookie.indexOf('draft=1') > -1
    },
  },
}
</script>

<style lang="scss">
@import "src/styles/mixins";
.toolbar {
  @include flex(center, space-between);
  flex-grow:   1;
  color: var(--Text-Text_Primary);

  &__tabs {
    display: flex;
    &__tab {
      padding: 14px;
      cursor: pointer;
      position: relative;
      .tooltiptext {
        top: calc(100% + 13px);
        display: none;
        background-color: #09373D;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 4px 12px;
        position: absolute;
        z-index: 1;
        transform: translateX(-50%);
        width: max-content;
        left: 50%;
        transition: opacity 0.3s;
        &::after {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #09373D transparent;
        }
      }
      &:hover {
        .tooltiptext {
          display: block;
        }
      }
    }
    &__tab.disable {
      cursor: no-drop;
      svg {
        opacity: 0.6;
      }
    }
    &__tab.view.active {
      background: var(--Active-States-Surfaces);
    }
    &__tab.view {
      position: relative;
      .view-block {
        z-index: 1;
        width: 214px;
        position: absolute;
        top: 73px;
        right: 0;
        border-radius: 12px;
        background: var(--Background);
        box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.15);
        border: 1px solid var(--Dividers);
        color: var(--Text-Text_Primary);
        p {
          @include font-styles(16px, 20px, 600);
          padding: 8px 16px;
          margin: 0;
          border-bottom: 1px solid var(--Dividers);
        }
        &__switch {
          @include font-styles(16px, 24px, 400);
          @include flex(baseline, space-between, row, 16px);
          margin: 8px 16px;
        }
      }
    }
  }
}
@media (max-width: 540px) {
  .toolbar {
    overflow-y: scroll;
    &__tabs__tab.view .view-block {
      position: fixed;
      top: 135px;
      right: 16px;
    }
  }
}
</style>
