import { v4 as uuid } from 'uuid'
import { defineStore } from 'pinia'
import { request } from '../../api'

export const useFitChartsStore = defineStore('fitCharts', {
  state: () => ({
    byMan: null,
    types: [
      { key: 'FEMALE', title: 'Womenswear' },
      { key: 'MALE', title: 'Menswear' },
      { key: 'GIRL', title: 'Girlswear' },
      { key: 'BOY', title: 'Boyswear' },
      { key: 'GIRL-TEEN', title: 'Girls junior' },
      { key: 'BOY-TEEN', title: 'Boys junior' },
    ],
    unit: 'in',
    customCharts: {
      list: [],
      count: 0,
    },
    standartCharts: {
      list: [],
      count: 0,
    },

    dataKey: 'newChart',
    template: null,

    newChart: {
      step: 'a',
      title: '',
      type: 'FEMALE',
      isValid: false,
      sizes: {},
      sizesList: [],
      currentCell: {},
    },
    editChart: {
      step: 'a',
      title: '',
      type: 'FEMALE',
      isValid: false,
      sizes: {},
      sizesList: [],
      currentCell: {},
    },

    previewChart: null,

    // TODO: https://stackoverflow.com/a/55514229
    params: {
      offset: 0,
      limit: 50,
      type: 'FEMALE',
      sortField: '',
      sortDirection: 'asc',
      search: '',
    },
    delChartModal: false,
    chartId: null,
    isCustom: true,
    selectedMannequinId: null,
    selectedChart: null,
  }),
  getters: {
    sizeChartIsValid (state) {
      return state[this.dataKey].isValid && state[this.dataKey].title.length > 0
    },
    selectedMannequin (state) {
      return state.selectedChart?.mannequins?.find(el => el.mannequinId === state.selectedMannequinId)
    },
  },
  actions: {
    async getByMan (mannequinId) {
      try {
        const { data } = await request.get(`/dev/mannequins/${mannequinId}`)
        this.byMan = data
        return data
      } catch (e) {
        console.error(e.message)
      }
    },
    async createMannequin (params, type) {
      try {
        let { data } = await request.post(
          '/dev/mannequins', JSON.stringify({
            type: type,
            sizeParams: params,
          }),
          { headers: { 'Content-Type': 'application/json; charset=UTF-8' } },
        )
        return data
      } catch (e) {
        console.error(e.message)
      }
    },
    delChartModalToggle () {
      this.delChartModal = !this.delChartModal
      this.chartId = null
    },
    async setSortOrder (order) {
      this.params.sortDirection = order
      this.isCustom ? await this.getList() : await this.getListStandart()
    },
    async search (search) {
      this.params.search = search
      this.isCustom ? await this.getList() : await this.getListStandart()
    },
    async sort (sort) {
      this.params.sortField = sort
      this.isCustom ? await this.getList() : await this.getListStandart()
    },
    async getList () {
      try {
        const { data } = await request.get(
          '/fit-charts',
          { params: this.params },
        )
        this.customCharts.list = data
        this.customCharts.count = data.length
      } catch (e) {
        console.error(e.message)
      }
    },

    async getListStandart () {
      try {
        const { data } = await request.get(
          '/fit-charts-standart',
          { params: this.params },
        )
        this.standartCharts.list = data
      } catch (e) {
        console.error(e.message)
      }
    },

    async getOneSelected (id) {
      this.isCustom ? await this.getOne(id) : await this.getOneStandart(id)
    },

    setNewMennequin (newM, oldM) {
      this.selectedChart.mannequins.map(el => {
        if (el.mannequinId === oldM) { el.newMennequinId = newM }
        return el
      })
    },

    async getOne (id) {
      try {
        const { data } = await request.get('/fit-charts/' + id)
        this.previewChart = null
        this.previewChart = data
        this.selectedChart = data
      } catch (e) {
        console.error(e.message)
      }
    },

    async getOneStandart (id) {
      try {
        const { data } = await request.get('/fit-charts-standart/' + id)
        this.previewChart = null
        this.previewChart = data
        this.selectedChart = data
      } catch (e) {
        console.error(e.message)
      }
    },

    async fromTemplate (refs) {
      this[this.dataKey].sizesList = this.template.mannequins.map(m => m.mannequinId)
      // this[this.dataKey].title = this.template.name
      this[this.dataKey].type = this.template.type

      const sizes = this.template.mannequins
      setTimeout(() => {
        this[this.dataKey].sizesList.forEach((id, i) => {
          const data = {
            name: sizes[i].name,
            sizeParams: sizes[i].params,
          }
          refs[id][0].importSize(data)
        })
      }, 0)
    },

    async getForEdit (id, refs) {
      try {
        const { data: oneFitChart } = await request.get('/fit-charts/' + id)
        this[this.dataKey].sizesList = oneFitChart.mannequins.map(m => m.mannequinId)
        this[this.dataKey].title = oneFitChart.name
        this[this.dataKey].type = oneFitChart.type

        const sizes = oneFitChart.mannequins
        setTimeout(() => {
          this[this.dataKey].sizesList.forEach((id, i) => {
            const data = {
              name: sizes[i].name,
              sizeParams: sizes[i].params,
            }
            refs[id][0].importSize(data)
          })
        }, 0)
      } catch (e) {
        console.error(e.message)
      }
    },
    async getForEditStandart (id, refs) {
      try {
        const { data: oneFitChart } = await request.get('/fit-charts-standart/' + id)
        this[this.dataKey].sizesList = oneFitChart.mannequins.map(m => m.mannequinId)
        this[this.dataKey].title = oneFitChart.name
        this[this.dataKey].type = oneFitChart.type

        const sizes = oneFitChart.mannequins
        setTimeout(() => {
          this[this.dataKey].sizesList.forEach((id, i) => {
            const data = {
              name: sizes[i].name,
              sizeParams: sizes[i].params,
            }
            refs[id][0].importSize(data)
          })
        }, 0)
      } catch (e) {
        console.error(e.message)
      }
    },

    async setMannequinType (key, routeName) {
      this.params.type = key
      routeName === 'fitChartsStandart' ? await this.getListStandart() : await this.getList()
    },

    setSize (size, key) {
      this[this.dataKey].sizes[key] = size
      this.sizesCheckValid()
    },
    addNewSize (i) {
      const key = uuid()
      this[this.dataKey].sizesList.splice(i, 0, key)
    },
    removeSize (i) {
      const key = this[this.dataKey].sizesList[i]
      delete this[this.dataKey].sizes[key]
      this[this.dataKey].sizesList.splice(i, 1)
    },
    setCurrentCell (cell) {
      this[this.dataKey].currentCell = cell
    },
    sizesCheckValid (state) {
      for (const i in this[this.dataKey].sizesList) {
        const key = this[this.dataKey].sizesList[i]
        const fields = this[this.dataKey].sizes[key].fields
        for (const key in fields) {
          const field = fields[key]
          this[this.dataKey].isValid = field.isValid
          if (this[this.dataKey].isValid === false) break
        }
        if (this[this.dataKey].isValid === false) break
      }
    },
    async saveNewChart () {
      try {
        const mannequins = []

        this[this.dataKey].sizesList.forEach(key => {
          const size = this[this.dataKey].sizes[key]
          const data = {
            sizeParams: [],
            name: size.name,
            mannequinType: this[this.dataKey].type,
          }
          for (const fieldKey in size.fields) {
            const field = size.fields[fieldKey]
            data.sizeParams.push({
              uid: field.param,
              value: field.cm,
            })
          }
          mannequins.push(data)
        })

        const sizechartData = {
          name: this[this.dataKey].title,
          type: this[this.dataKey].type,
          mannequins,
        }

        await request.post(
          '/fit-charts', JSON.stringify(sizechartData),
          { headers: { 'Content-Type': 'application/json; charset=UTF-8' } },
        )
      } catch (e) {
        console.error(e.message)
      }
    },
    async saveNewChartStandart () {
      try {
        const mannequins = []

        this[this.dataKey].sizesList.forEach(key => {
          const size = this[this.dataKey].sizes[key]
          const data = {
            sizeParams: [],
            name: size.name,
            mannequinType: this[this.dataKey].type,
          }
          for (const fieldKey in size.fields) {
            const field = size.fields[fieldKey]
            data.sizeParams.push({
              uid: field.param,
              value: field.cm,
            })
          }
          mannequins.push(data)
        })

        const sizechartData = {
          name: this[this.dataKey].title,
          type: this[this.dataKey].type,
          mannequins,
        }

        await request.post(
          '/fit-charts-standart', JSON.stringify(sizechartData),
          { headers: { 'Content-Type': 'application/json; charset=UTF-8' } },
        )
      } catch (e) {
        console.error(e.message)
      }
    },
    async updateChart (id) {
      try {
        const mannequins = []

        this[this.dataKey].sizesList.forEach(key => {
          const size = this[this.dataKey].sizes[key]
          const data = {
            sizeParams: [],
            name: size.name,
            mannequinType: this[this.dataKey].type,
          }
          for (const fieldKey in size.fields) {
            const field = size.fields[fieldKey]
            data.sizeParams.push({
              uid: field.param,
              value: field.cm,
            })
          }

          mannequins.push(data)
        })

        const sizechartData = {
          name: this[this.dataKey].title,
          type: this[this.dataKey].type,
          mannequins,
        }

        await request.put(
          '/fit-charts/' + id, JSON.stringify(sizechartData),
          { headers: { 'Content-Type': 'application/json; charset=UTF-8' } },
        )
      } catch (e) {
        console.error(e.message)
      }
    },
    async updateChartStandart (id) {
      try {
        const mannequins = []

        this[this.dataKey].sizesList.forEach(key => {
          const size = this[this.dataKey].sizes[key]
          const data = {
            sizeParams: [],
            name: size.name,
            mannequinType: this[this.dataKey].type,
          }
          for (const fieldKey in size.fields) {
            const field = size.fields[fieldKey]
            data.sizeParams.push({
              uid: field.param,
              value: field.cm,
            })
          }

          mannequins.push(data)
        })

        const sizechartData = {
          name: this[this.dataKey].title,
          type: this[this.dataKey].type,
          mannequins,
        }

        await request.put(
          '/fit-charts-standart/' + id, JSON.stringify(sizechartData),
          { headers: { 'Content-Type': 'application/json; charset=UTF-8' } },
        )
      } catch (e) {
        console.error(e.message)
      }
    },
    async deleteChart () {
      try {
        await request.delete('/fit-charts/' + this.chartId)
        await this.getList()
        this.delChartModalToggle()
        this.chartId = null
      } catch (e) {
        console.error(e.message)
      }
    },
    async deleteChartStandart (payload) {
      const really = confirm('Sure?')
      if (really) {
        try {
          await request.delete('/fit-charts-standart/' + payload._id)
          await this.getListStandart()
          this.chartId = null
        } catch (e) {
          console.error(e.message)
        }
      }
    },
  },
})
