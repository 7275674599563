import { defineStore } from 'pinia'
import { request } from '../../api'

export const useUserStore = defineStore('user', {
  state: () => ({
    isLoggedIn: false,
    data: null,
    updateForm: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      companyName: '',
      jobTitle: '',
    },
    errors: {},
    phoneIsValid: true,
    companyTypes: [
      { title: 'Indie/Student', value: 'INDIE' },
      { title: 'Fashion Startup', value: 'STARTUP' },
      { title: 'Growing Brand', value: 'GROWING' },
    ],
    companyRevenues: [
      { title: '$0-100k', value: '0-100000' },
      { title: '$100k-500k', value: '100000-500000' },
      { title: '$500k-1m', value: '500000-1000000' },
      { title: '$1m-2m', value: '1000000-2000000' },
      { title: '$2m-5m', value: '2000000-5000000' },
      { title: '$5m-10m', value: '5000000-10000000' },
      { title: '$10m+', value: '10000000-10000000' },
    ],
  }),
  getters: {
    userPic () {
      const userPic = {
        type: '',
        data: '',
      }
      if (this.data.avatar) {
        userPic.type = 'image'
        userPic.data = this.data.avatar
      } else {
        userPic.type = 'text'
        userPic.data = `${this.data.firstName[0].toUpperCase()}${this.data.lastName[0].toUpperCase()}`
      }
      return userPic
    },
    companyType () {
      const type = this.companyTypes.find(t => t.value === this.data.company.plan)
      return type?.title || ''
    },
    companyRevenue () {
      const revenue = this.companyRevenues.find(r => r.value === `${this.data.company.revenue_min}-${this.data.company.revenue_max}`)
      return revenue?.title || '$0'
    },
  },
  actions: {
    async get () {
      try {
        const { data } = await request.get('/user/current')
        this.data = data
        this.updateForm.firstName = data.firstName
        this.updateForm.lastName = data.lastName
        this.updateForm.email = data.email
        this.updateForm.phoneNumber = data.phoneNumber
        this.updateForm.companyName = data.company.name
        this.updateForm.jobTitle = data.company?.managerJobTitle || ''
        this.updateForm.revenue_min = data.company.revenue_min
        this.updateForm.revenue_max = data.company.revenue_max
        this.updateForm.revenue = `${data.company.revenue_min}-${data.company.revenue_max}`
        this.isLoggedIn = true
      } catch (e) {
        this.isLoggedIn = false
        console.error(e.message)
      }
    },

    validate () {
      this.errors = {}
      if (this.updateForm.firstName.length < 1 || this.updateForm.firstName.length > 50) {
        this.errors.firstName = 'First Name should include 1-50 symbols'
        return false
      }

      if (this.updateForm.lastName.length < 1 || this.updateForm.lastName.length > 50) {
        this.errors.lastName = 'Last Name should include 1-50 symbols'
        return false
      }

      // if (!this.updateForm.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      //   this.errors.email = 'An E-mail should be in the correct format: name@example.com'
      //   throw new Error('An E-mail should be in the correct format: name@example.com')
      // }

      if (!this.phoneIsValid) {
        this.errors.phoneNumber = 'A Phone Number should be in the correct format: +12345678901'
        return false
      }

      // if (this.updateForm.companyName.length < 1 || this.updateForm.companyName.length > 50) {
      //   this.errors.companyName = 'Company Name should include 1-50 symbols'
      //   return false
      // }

      // if (this.updateForm.jobTitle.length < 1 || this.updateForm.jobTitle.length > 50) {
      //   this.errors.jobTitle = 'Job Title should include 1-50 symbols'
      //   return false
      // }

      return true
    },

    async updateInfo () {
      const isValid = this.validate()
      if (!isValid) return

      console.log(this.updateForm)
      const { firstName, lastName, phoneNumber, companyName, jobTitle: managerJobTitle, revenue_min: revenueMin, revenue_max: revenueMax } = this.updateForm

      await request.put(
        '/user',
        JSON.stringify({ firstName, lastName, phoneNumber, companyName, managerJobTitle, revenueMin, revenueMax }),
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        },
      )
    },
    async getChangePassEmail () {
      await request.post(
        '/user/send-reset-password',
        JSON.stringify({
          email: this.data.email,
        }),
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        },
      )
    },
    async uploadUserPic (data) {
      await request.put(
        '/user',
        JSON.stringify({
          avatar: data,
        }),
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        },
      )
    },
  },
})
