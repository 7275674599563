<template>
  <div>
    <q-ajax-bar
      class="AjaxLoading__bar"
      color="secondary"
      size="3px"
      position="top"
    />
  </div>
</template>

<script>
export default {
  name: 'AjaxLoading',
}
</script>

<style lang="scss">
  @import "src/styles/global-z-indexes";

  .AjaxLoading {
    &__bar {
      z-index: $z-ajax-bar;
    }
  }
</style>
