import 'quasar/dist/quasar.ie.polyfills'
import '@quasar/extras/material-icons/material-icons.css'
import Vue from 'vue'
import {
  Quasar,
  ClosePopup,
  Ripple,
  QDrawer,
  QLayout,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QBtnGroup,
  QHeader,
  QFooter,
  QPageContainer,
  QPage,
  QInput,
  QCheckbox,
  QSelect,
  QFile,
  QMenu,
  QList,
  QItem,
  QDate,
  QItemSection,
  QIcon,
  QResizeObserver,
  QScrollArea,
  QDialog,
  QCard,
  QCardSection,
  QCardActions,
  QMarkupTable,
  QTabs,
  QTab,
  QSpace,
  QSpinnerDots,
  QAjaxBar,
  QItemLabel,
} from 'quasar'

Vue.use(Quasar, {
  config: {
    ripple: {},
  },
  components: {
    QToolbar,
    QToolbarTitle,
    QBtn,
    QBtnGroup,
    QHeader,
    QFooter,
    QLayout,
    QDrawer,
    QPageContainer,
    QPage,
    QInput,
    QCheckbox,
    QSelect,
    QFile,
    QMenu,
    QList,
    QItem,
    QDate,
    QItemSection,
    QIcon,
    QResizeObserver,
    QScrollArea,
    QDialog,
    QCard,
    QCardSection,
    QCardActions,
    QMarkupTable,
    QTabs,
    QTab,
    QSpace,
    QSpinnerDots,
    QAjaxBar,
    QItemLabel,
  },
  directives: {
    ClosePopup,
    Ripple,
  },
  plugins: {},
})
