<template>
  <section class="hero">
    <div class="video-container">
      <video autoplay muted loop playsinline :poster="require('@/assets/home/hero.jpg')">
        <source :src="require('@/assets/home/hero.mp4')" type="video/mp4">
        <source :src="require('@/assets/home/hero.webm')" type="video/webm">
      </video>
    </div>
    <!-- <figure>
      <img :src="require('@/assets/home/hero.jpg')" alt="" />
    </figure> -->
    <div class="container">
      <div class="top-block">
        <p>Create collections in minutes, not&nbsp;months</p>
        <h1>AI for Instantly Manufacturable Apparel Design and&nbsp;Development</h1>
        <ButtonSecondary v-if="$store.state.isLoggedIn" @click.native="$router.push({ name: 'userCollections' })">Start Designing</ButtonSecondary>
        <ButtonSecondary v-else @click.native="$router.push({ name: 'demo' })">Request a Demo</ButtonSecondary>
      </div>
      <div class="bottom-block">
        Whether you are a large fashion brand or an Indie designer, our platform lets you create 100+ billion of customizable, digital production-ready MTM and standard sized blocks in a few clicks.
      </div>
    </div>
  </section>
</template>

<script>
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

export default {
  name: 'TheHero',
  components: {
    ButtonSecondary,
  },
}
</script>

<style lang="scss">
.home-page {
  .hero {
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);

    figure {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .video-container {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      z-index: -1;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      padding:  132px 80px 80px 80px;
      height: 812px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .top-block {
      width: 100%;
      max-width: 730px;

      p {
        color: var(--Text-Text_White);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.18px;
        margin: 0;
        margin-bottom: 10px;
      }

      h1 {
        color: var(--Text-Text_White);
        font-family: Montserrat;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.5px;
        margin: 0;
        margin-bottom: 25px;

      }
    }

    .bottom-block {
      width: 100%;
      max-width: 400px;
      align-self: flex-end;

      color: var(--Basic-White);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: -0.54px;
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .hero {
      figure {
        img {
          object-position: 62%;
        }
      }
      .container {
        padding:  40px 20px;
        align-items: flex-end;
      }

      .top-block {
        h1 {
          font-size: 30px;
          letter-spacing: -0.9px;
        }
      }

      .bottom-block {
        display: none;
      }
    }
  }
}
</style>
