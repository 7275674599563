import {svgPathProperties} from 'svg-path-properties'
import render from './renderCommands'
import { DEBUG_SIMPLIFICATION } from './../utils/logger'

const log = (...args) => {
  if (DEBUG_SIMPLIFICATION) {
    console.log(...args)
  }
}

/**
 * Simplify path by replacing curves and other heavy stuff to lines
 * @param {String} path - SVG.Path data string
 * @param force
 */
export const simplifyPath = (path, force = false) => {
  log('[point] path before simplify', path)
  const hadEnd = /z$/i.test(path.trim())
  const curveCommandsCount = path.replace(/([MLZ\d,.\s]+)/ig, '').split('').filter((point, pos, arr) => {
    const foundPos = arr.findIndex(c => c.x === point.x && c.y === point.y)
    return foundPos === pos
  })
  if (curveCommandsCount.length || force) {
    const commandsList = path.replace(/[MLCZ]/ig, ' ').split(' ')
    const lastPoint = [commandsList[commandsList.length - 2], commandsList[commandsList.length - 1]]
    log('[point] lastPoint', lastPoint)
    const props = svgPathProperties(path)
    let points = []
    for (let i = 0; i < props.getTotalLength(); i++) {
      const point = props.getPointAtLength(i)
      points.push(point)
    }
    points = simplify(points, 0.01, 1)
    log('[point] points after simplify', points)
    if (points.length > 1 && window && window.fixEndPoints) {
      log('[point] new last point', points[points.length - 1])
      points[points.length - 1].x = parseFloat(lastPoint[0])
      points[points.length - 1].y = parseFloat(lastPoint[1])      
      log('[point] fixed last point', points[points.length - 1])
    }
    return points.length > 1
      ? render([  
        {
          code: 'M',
          ...points[0]
        },
        ...points.map(p => ({
          code: 'L',
          ...p
        })),
        ...(hadEnd
          ? [{code: 'Z'}]
          : [])
      ])
      : null
  } else {
    return path
  }
}

/**
 * Simplify SVG.Path data attribute to lines
 * @param {
 *    {
 *      attributes: {d: String}
 *    }
 * } pathElement Simplified path object
 * @returns {{attributes: {d: (string|null|String)}}}
 */
const simplifier = pathElement => ({
  ...pathElement,
  attributes: {
    ...pathElement.attributes,
    d: simplifyPath(pathElement.attributes.d)
  }
})

/**
 * Apply simplification on array of compound paths and throw out too short ones
 * @param {[
 *    {
 *      attributes: {d: String}
 *    }
 * ]} pathArray
 * @returns {[
 *    {
 *      attributes: {d: String}
 *    }
 * ]}
 */
export const simplifyMapper = pathArray => {
  return pathArray.map(simplifier).filter(p => p.attributes.d)
}

/**
 * Get square distance between two points
 * @param {{x: Number, y: Number}} p1
 * @param {{x: Number, y: Number}} p2
 * @returns {number}
 */
const getSqDist = (p1, p2) => {
  const dx = p1.x - p2.x
  const dy = p1.y - p2.y

  return dx * dx + dy * dy
}

/**
 * Square distance from a point to a segment
 * @param {{x: Number, y: Number}} p
 * @param {{x: Number, y: Number}} p1
 * @param {{x: Number, y: Number}} p2
 * @returns {number}
 */
const getSqSegDist = (p, p1, p2) => {
  let x = p1.x
  let y = p1.y
  let dx = p2.x - x
  let dy = p2.y - y

  if (dx !== 0 || dy !== 0) {
    const t = ((p.x - x) * dx + (p.y - y) * dy) / (dx * dx + dy * dy)

    if (t > 1) {
      x = p2.x
      y = p2.y
    } else if (t > 0) {
      x += dx * t
      y += dy * t
    }
  }

  dx = p.x - x
  dy = p.y - y

  return dx * dx + dy * dy
}

/**
 * Basic distance-based simplification
 * @param {[{x: Number, y: Number}]} points
 * @param {Number} sqTolerance epsilon
 * @returns {[{x: Number, y: Number}]}
 */
const simplifyRadialDist = (points, sqTolerance) => {
  let prevPoint = points[0]
  let newPoints = [prevPoint]
  let point

  for (let i = 1, len = points.length; i < len; i++) {
    point = points[i]

    if (getSqDist(point, prevPoint) > sqTolerance) {
      newPoints.push(point)
      prevPoint = point
    }
  }

  if (prevPoint !== point) newPoints.push(point)

  return newPoints
}

/**
 * Douglas-Peucker algorithm step
 * @param {[{x: Number, y: Number}]} points
 * @param {Number} first Index of first point
 * @param {Number} last Index of last point
 * @param {Number} sqTolerance epsilon
 * @param {[{x: Number, y: Number}]} simplified
 */
const simplifyDPStep = (points, first, last, sqTolerance, simplified) => {
  let maxSqDist = sqTolerance
  let index

  for (let i = first + 1; i < last; i++) {
    const sqDist = getSqSegDist(points[i], points[first], points[last])

    if (sqDist > maxSqDist) {
      index = i
      maxSqDist = sqDist
    }
  }

  if (maxSqDist > sqTolerance) {
    if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified)
    simplified.push(points[index])
    if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified)
  }
}

/**
 * Simplification using Ramer-Douglas-Peucker algorithm
 * @param {[{x: Number, y: Number}]} points
 * @param {Number} sqTolerance epsilon
 * @returns {[{x: Number, y: Number}]}
 */
const simplifyDouglasPeucker = (points, sqTolerance) => {
  const last = points.length - 1

  const simplified = [points[0]]
  simplifyDPStep(points, 0, last, sqTolerance, simplified)
  simplified.push(points[last])

  return simplified
}

/**
 * Combination of Ramer-Douglas-Peucker and radial distance algorithm for best performance
 * @param {[{x: Number, y: Number}]} points
 * @param {Number} tolerance epsilon
 * @param highestQuality
 * @returns {{x: Number, y: Number}[]|*}
 */
const simplify = (points, tolerance, highestQuality) => {
  if (points.length <= 2) return points

  const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1

  points = highestQuality ? points : simplifyRadialDist(points, sqTolerance)
  points = simplifyDouglasPeucker(points, sqTolerance)

  return points
}
