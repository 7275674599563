<template>
  <div class="empty-grid">
    <div class="icon">
      <slot name="icon"></slot>
    </div>
    <h3><slot name="title" /></h3>
    <p><slot name="text" /></p>
    <ButtonPrimary @click.native="$emit('create-new')">
      <template v-slot:icon v-if="buttonPlusIcon">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M10 4.75a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25v4.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5.5a.75.75 0 0 0-.75-.75z"/>
        </svg>
      </template>
      <slot name="button-text" />
    </ButtonPrimary>
  </div>
</template>

<script>
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'

export default {
  name: 'EmptyCollections',
  components: {
    ButtonPrimary,
  },
  props: {
    buttonPlusIcon: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.empty-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 80px 0;
  border: 1px solid var(--Dividers);
  border-radius: 12px;
  background: var(--Surface);
  margin-top: 40px;

  .icon {
    width: 88px;
    height: 88px;
    border-radius: 88px;
    background: var(--Dividers);
    margin-bottom: 24px;

    svg {
      display: block;
      width: 32px;
      height: 100%;
      margin: 0 auto;
      fill: var(--primary);
    }
  }

  h3 {
    color: var(--Text-Text_Primary);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    max-width: 564px;
    margin-bottom: 8px;
    text-align: center;
  }
  p {
    color: var(--Text-Text_Secondary);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    max-width: 564px;
    margin-bottom: 24px;
  }
}

@media (max-width: 760px) {
  .empty-grid {
    padding: 80px 16px;
  }
}
</style>
