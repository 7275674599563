export const DEBUG = 0 // process.env.NODE_ENV === 'development'
export const DEBUG_SIMPLIFICATION = 0

export const log = (...args) => {
  if (DEBUG) {
    console.log(...args)
  }
}

export const group = (...args) => {
  if (DEBUG && console.group) {
    console.group(...args)
  }
}

export const groupCollapsed = (...args) => {
  if (DEBUG && console.groupCollapsed) {
    console.groupCollapsed(...args)
  }
}

export const groupEnd = () => {
  if (DEBUG && console.groupEnd) {
    console.groupEnd()
  }
}
