import { MATCH_DELTA, matchPoints } from './point'
import { log } from './../utils/logger'
import UUID from 'uuid/v4'
import { extractEdgePoints } from './index'
let runTime = 0
/**
 * Find a contour between several paths
 * @param {[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      isDependency: Boolean
 *      uuid: String
 *    }
 * ]} paths
 * @param delta
 * @return {[[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      isDependency: Boolean
 *      uuid: String
 *    }
 * ]]} paths
 */
export const findContour = (paths, delta = MATCH_DELTA, params = {}) => {
  let contours = []
  runTime = Date.now()
  if (window && window.debugFindContour) {
    let debugPaths = [...paths]
    debugPaths.forEach(path => {
      path = {...path}
      const id = getId(path)
      log('[contour]', 'checking', id)
      let nextSteps = []
      nextSteps = checkNextStep([path], debugPaths.filter(p => p !== path))
      if (nextSteps.length) {
        // log('[contour]', 'next found', nextSteps.map(getId))
        const nextChosen = nextSteps[0]
        // log('[contour]', 'next chosen', getId(nextChosen))
        if (!path.next) {
          path.next = nextChosen
          nextChosen.prev = path
        }
      }
    })
    log('[contour]', 'paths linked list', debugPaths.map(p => ({id: getId(p), prev: getId(p.prev), next: getId(p.next), edges: p.edges})))
    window.debugPaths = debugPaths
    /* paths.forEach(path => {
      let possibleContour = []
      const checkCircle = p => {
        if (!p.next) {
          return false
        } else {
          possibleContour.push(p)
          if (p.next === path) {
            return true
          } else {
            return checkCircle(p.next)
          }
        }      
      }
      if (checkCircle(path)) {
        contours.push(possibleContour)
      }
    })
    } */
  }
  paths.forEach(path => {
    const pathWay = findContourRecursive([path], paths, delta)
    if (pathWay) {
      contours.push(pathWay)
    }
  })
  const getUidString = contour => contour.map(c => c.uid)
    .sort()
    .join()
  log('[contour] found contours', contours)
  if (params.skipFiltering) {
    return contours
  }
  return contours
    .filter(contour => contour.find(p => p.isDependency))
    .filter((contour, pos, arr) => arr.findIndex(c => getUidString(c) === getUidString(contour)) === pos)
}

/**
 * Find paths with a matching point between given paths
 * @param {[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      uuid: String
 *    }
 * ]} preContour
 * @param {[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      uuid: String
 *    }
 * ]} paths
 * @param delta
 * @returns {[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      uuid: String
 *    }
 * ]|Boolean} paths
 */
export const findContourRecursive = (preContour, paths, delta) => {
  const currentStepsUuids = preContour.map(c => c.uuid)
  const failedStepsUuids = preContour && preContour[preContour.length - 1] ? preContour[preContour.length - 1].failedSteps : []
  if (Date.now() - runTime > 10 * 1000) return false
  // все контуры, совпавшие концом с проверяемым
  let possibleSteps = checkNextStep(preContour, paths
    .filter(p => !currentStepsUuids.includes(p.uuid)))
    .filter(p => !failedStepsUuids.includes(p.uuid))
  if (possibleSteps.length) {
    let failedSteps = 0
    for (let i = 0; i < possibleSteps.length; i++) {
      const step = possibleSteps[i]      
      // log('[contour] checking next step', step, preContour)
      log('[contour] checking next step', getId(step), preContour.map(getId))
      const pathWay = findContourRecursive([...preContour, step], paths, delta)
      if (pathWay) {
        // сюда заходим, если добавление и проверка следующих контуров привела к закрытому контуру в итоге (т.е. контур найден, считаем)
        log('[contour] next step way', pathWay)
        return pathWay
      } else {
        failedSteps++
      }
    }
    // если проверка пошла не в ту сторону, проверим предующее возможное ответвление
    if (failedSteps === possibleSteps.length - 1) {
      const previousSteps = preContour.slice(0, preContour.length - 2)
      const currentLastStep = preContour[preContour.length - 1]
      const previousLastStep = Object.assign({}, preContour[preContour.length - 2], {
        failedSteps: [...preContour[preContour.length - 2].failedSteps, currentLastStep.uuid]
      })
      return findContourRecursive([...previousSteps, previousLastStep], paths, delta)
    }
  } else if (preContour.length > 1) {
    // если контур закрыт и мы не можем присоединить к нему еще, то это результат
    if (checkIsClosedContour(preContour, delta)) {
      return preContour
    } else {
      // опять проверяем на неудачное ответвление
      const previousSteps = preContour.slice(0, preContour.length - 2)
      const currentLastStep = preContour[preContour.length - 1]
      const previousLastStep = Object.assign({}, preContour[preContour.length - 2], {
        failedSteps: [...preContour[preContour.length - 2].failedSteps, currentLastStep.uuid]
      })
      return findContourRecursive([...previousSteps, previousLastStep], paths, delta)
    }
  }
  return false
}

const getId = path => {
  if (!path) {
    return null
  }
  if (path.attributes['data-source-id']) {
    return path.attributes['data-source-id'].trim()
  } else if (path.attributes.id) {
    return (path.attributes.id + ' ' + (path.attributes['data-source-node'] || '')).trim()
  } else if (path.attributes['data-source-node']) {
    return path.attributes['data-source-node']
  }
  return path.attributes.d.trim()
}

/**
 * Find ones between given paths, that have a matching point
 * @param {[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      uuid: String
 *    }
 * ]} preContour
 * @param {[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      uuid: String
 *    }
 * ]} paths
 */
const checkNextStep = (preContour, paths, debug = false) => {
  const lastPath = preContour[preContour.length - 1]
  const point = lastPath.shouldBeReversed
    ? lastPath.edges[0]
    : lastPath.edges[1]
  // log('[contour] preContour lastPath', getId(lastPath))
  if (debug) {
    log('[contour] point', point)
  }
  // console.groupCollapsed()
  const matchingStart = paths.filter(p => {
    if (debug) {
      log('[contour] checking match points at start', getId(p), p.edges[0])
    }
    return matchPoints(point, p.edges[0], MATCH_DELTA * 4)
  })
  const matchingEnd = paths.filter(p => {
    if (debug) {
      log('[contour] checking match points at end', getId(p), p.edges[1])
    }
    return matchPoints(point, p.edges[1], MATCH_DELTA * 4)
  })
  // console.groupEnd()
  return [
    ...matchingStart,
    ...matchingEnd.map(p => Object.assign({}, p, {shouldBeReversed: true}))
  ]
}

/**
 *
 * @param {[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      uuid: String
 *    }
 * ]} preContour
 * @param delta
 * @return boolean
 */
const checkIsClosedContour = (preContour, delta) => {
  const lastPath = preContour[preContour.length - 1]
  const lastPathEnd = lastPath.shouldBeReversed
    ? lastPath.edges[0]
    : lastPath.edges[1]
  const firstPath = preContour[0]
  return matchPoints(firstPath.edges[0], lastPathEnd, delta) || matchPoints(firstPath.edges[0], lastPathEnd, delta * 4)
}

/**
 *
 * @param {[
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      isDependency: Boolean,
 *      uuid: String
 *    }
 * ]} contour
 * @return {
 *    {
 *      attributes: {d: String, reversedD: String, originalD: String},
 *      edges: Array<{x:Number, y: Number}>,
 *      shouldBeReversed: Boolean,
 *      isDependency: Boolean,
 *      mergedUuids: Array<String>,
 *      uuid: String
 *    }
 * }
 */

export const mergeContourPaths = contour => {
  const basePaths = contour.filter(p => !p.isDependency)
  let basePath = {}
  if (basePaths.length) {
    basePath = basePaths.reduce((base, p) => {
      return Object.assign({}, base, p, {attributes: Object.assign({}, base.attributes || {}, p.attributes)})
    }, {})
  } else {
    basePath = contour[0]
  }
  const newD = (contour.reduce((d, p) => {
    return d + (p.shouldBeReversed
      ? p.attributes.reversedD
      : p.attributes.d).trim()
      .replace(/^m/i, 'L')
      .replace(/z$/i, '')
  }, '') + 'Z')
    .replace(/^l/i, 'M')
    const sourceIds = contour.map(p => {
      if (p.attributes) {
        if (p.attributes['data-source-id']) {
          return p.attributes['data-source-id']
        } else if (p.attributes.id) {
          return p.attributes.id
        }
      }
    }).join(',')
  return Object.assign({}, basePath, {
    attributes: Object.assign({}, basePath.attributes, {
      d: newD,
      originalD: newD,
      reversedD: newD,
      'data-contour': 1,
      'data-source-ids': sourceIds
    }),
    edges: extractEdgePoints(newD),
    uuid: UUID(),
    mergedUuids: contour.map(c => c.uuid)
  })
}
