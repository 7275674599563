<template>
  <div>
    <q-select
      class="label_mobile custom avenir-font-bold"
      v-model="value"
      v-bind="$attrs"
      :options="options"
      :label="value && name ? name : 'Required'"
      :stack-label="!value"
      :color="value ? 'primary' : 'negative'"
      :label-color="value ? 'primary' : 'negative'"
      :behavior="$q.screen.lt.sm ? 'dialog' : 'menu'"
      dropdown-icon='expand_more'
      borderless
      @input="inputUpdated"
    />
  </div>
</template>

<script>

export default {
  name: 'OptionSelectComponent',

  props: {
    name: {
      type: String,
      default: undefined,
    },
    initialValue: {
      type: Number,
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
      validator: list => list.every(item => 'value' in item && 'label' in item),
    },
  },

  data: () => ({
    value: undefined,
  }),

  created () {
    this.setInputValue()
  },

  watch: {
    initialValue () {
      this.setInputValue()
    },
  },

  methods: {
    setInputValue () {
      if (this.initialValue !== undefined) {
        this.value = this.options.find(item => item.value === this.initialValue)
      }
    },
    inputUpdated () {
      this.$emit('changed', this.value.value)
    },
  },
}
</script>

<style>
.custom .q-field__native {
  font-weight: bold !important;
}
</style>
