<template>
  <div class="button-sort">
    <ButtonSecondary class="small" @click.native="dropdown = !dropdown">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path :d="icons[order]"/>
        </svg>
      </template>
    </ButtonSecondary>
    <ul v-if="dropdown" v-click-outside="() => dropdown = false">
      <li @click="setOrder('asc')" :class="[order === 'asc' ? 'selected' : '']">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path :d="icons.asc"/>
        </svg>
        <span>Ascending</span>
      </li>
      <li @click="setOrder('desc')" :class="[order === 'desc' ? 'selected' : '']">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path :d="icons.desc"/>
        </svg>
        <span>Descending</span>
      </li>
    </ul>
  </div>
</template>

<script>
import ButtonSecondary from './ButtonSecondary.vue'

export default {
  name: 'ButtonSort',
  components: {
    ButtonSecondary,
  },
  data: () => ({
    order: 'asc',
    dropdown: false,
    icons: {
      asc: 'M3.666 5.666a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h11a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-11zM16.5 9.713a.75.75 0 0 0-.375.101.75.75 0 0 0-.156.12l-2.75 2.75a.75.75 0 0 0 0 1.06.75.75 0 0 0 1.062 0l1.469-1.469v5.522a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-5.522l1.469 1.47a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.061l-2.75-2.75a.75.75 0 0 0-.144-.11.75.75 0 0 0-.02-.013.75.75 0 0 0-.142-.061.75.75 0 0 0-.008-.002.75.75 0 0 0-.047-.014.75.75 0 0 0-.145-.017.75.75 0 0 0-.025-.004zm-12.834.537a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h8.25a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-8.25zm0 4.584a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h5.5a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-5.5z',
      desc: 'M3.666 5.666a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h11a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-11zM16.5 8.412a.75.75 0 0 0-.75.75v5.524l-1.469-1.47a.75.75 0 0 0-.531-.22.75.75 0 0 0-.531.22.75.75 0 0 0 0 1.061l2.75 2.75a.75.75 0 0 0 .156.12.75.75 0 0 0 .75 0 .75.75 0 0 0 .156-.12l2.75-2.75a.75.75 0 0 0 0-1.06.75.75 0 0 0-1.062 0l-1.469 1.469V9.162a.75.75 0 0 0-.75-.75zM3.666 10.25a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h8.25a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-8.25zm0 4.584a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h5.5a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-5.5z',
    },
  }),
  methods: {
    setOrder (order) {
      this.order = order
      this.dropdown = false
      this.$emit('sort-order', this.order)
    },
  },
}
</script>

<style lang="scss">
  .button-sort {
    position: relative;

    ul {
      position: absolute;
      width: 180px;
      left: 0;
      top: 48px;
      z-index: 1;

      border-radius: 8px;
      border: 1px solid var(--Dividers);
      background: var(--Basic-White);
      box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.10);

      li {
        list-style: none;
        display: flex;
        column-gap: 10px;
        padding: 8px 16px;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 0 !important;

        &, & > * {
          transition: all ease-in-out 300ms;
        }

        &:hover, &.selected {
          background: var(--Surface-Hover);

          span {
            color: var(--Button-States-Hover);
          }

          svg {
            fill: var(--Button-States-Hover);
          }
        }

        svg {
          width: 22px;
          height: 22px;
          fill: var(--Icons-Icon_Primary);
        }

        span {
          color: var(--Text-Text_Primary);
          font-family: "Open Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }
</style>
