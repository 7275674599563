const hexToRgb = function (hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (!result) {
    return null
  }

  let colors = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  }

  return `rgb(${colors.r}, ${colors.g}, ${colors.b})`
}

const colors = [
  { code: '#000000', name: 'Black', sort: 1000 },
  { code: '#800000', name: 'Maroon', sort: 1000 },
  { code: '#008000', name: 'Green', sort: 1000 },
  { code: '#808000', name: 'Olive', sort: 1000 },
  { code: '#000080', name: 'Navy', sort: 1000 },
  { code: '#800080', name: 'Purple', sort: 1000 },
  { code: '#008080', name: 'Teal', sort: 1000 },
  { code: '#c0c0c0', name: 'Silver', sort: 1000 },
  { code: '#808080', name: 'Grey', sort: 1000 },
  { code: '#ff0000', name: 'Red', sort: 1000 },
  { code: '#00ff00', name: 'Lime', sort: 1000 },
  { code: '#ffff00', name: 'Yellow', sort: 1000 },
  { code: '#0000ff', name: 'Blue', sort: 1000 },
  { code: '#ff00ff', name: 'Fuchsia', sort: 1000 },
  { code: '#00ffff', name: 'Aqua', sort: 1000 },
  { code: '#ffffff', name: 'White', sort: 1000 },
  { code: '#00005f', name: 'Navy Blue', sort: 1000 },
  { code: '#000087', name: 'Dark Blue', sort: 1000 },
  { code: '#0000af', name: 'Blue 3', sort: 1000 },
  { code: '#0000d7', name: 'Blue 3', sort: 1000 },
  { code: '#005f00', name: 'Dark Green', sort: 1000 },
  { code: '#005f5f', name: 'Deep Sky Blue 4', sort: 1000 },
  { code: '#005f87', name: 'Deep Sky Blue 4', sort: 1000 },
  { code: '#005faf', name: 'Deep Sky Blue 4', sort: 1000 },
  { code: '#005fd7', name: 'Dodger Blue 3', sort: 1000 },
  { code: '#005fff', name: 'Dodger Blue 2', sort: 1000 },
  { code: '#008700', name: 'Green 4', sort: 1000 },
  { code: '#00875f', name: 'Spring Green 4', sort: 1000 },
  { code: '#008787', name: 'Turquoise 4', sort: 1000 },
  { code: '#0087af', name: 'Deep Sky Blue 3', sort: 1000 },
  { code: '#0087d7', name: 'Deep Sky Blue 3', sort: 1000 },
  { code: '#0087ff', name: 'Dodger Blue 1', sort: 1000 },
  { code: '#00af00', name: 'Green 3', sort: 1000 },
  { code: '#00af5f', name: 'Spring Green 3', sort: 1000 },
  { code: '#00af87', name: 'Dark Cyan', sort: 1000 },
  { code: '#00afaf', name: 'Light Sea Green', sort: 1000 },
  { code: '#00afd7', name: 'Deep Sky Blue 2', sort: 1000 },
  { code: '#00afff', name: 'Deep Sky Blue 1', sort: 1000 },
  { code: '#00d700', name: 'Green 3', sort: 1000 },
  { code: '#00d75f', name: 'Spring Green 3', sort: 1000 },
  { code: '#00d787', name: 'Spring Green 2', sort: 1000 },
  { code: '#00d7af', name: 'Cyan 3', sort: 1000 },
  { code: '#00d7d7', name: 'Dark Turquoise', sort: 1000 },
  { code: '#00d7ff', name: 'Turquoise 2', sort: 1000 },
  { code: '#00ff5f', name: 'Spring Green 2', sort: 1000 },
  { code: '#00ff87', name: 'Spring Green 1', sort: 1000 },
  { code: '#00ffaf', name: 'Medium Spring Green', sort: 1000 },
  { code: '#00ffd7', name: 'Cyan 2', sort: 1000 },
  { code: '#5f0000', name: 'Dark Red', sort: 1000 },
  { code: '#5f005f', name: 'Deep Pink 4', sort: 1000 },
  { code: '#5f0087', name: 'Purple 4', sort: 1000 },
  { code: '#5f00af', name: 'Purple 4', sort: 1000 },
  { code: '#5f00d7', name: 'Purple 3', sort: 1000 },
  { code: '#5f00ff', name: 'Blue Violet', sort: 1000 },
  { code: '#5f5f00', name: 'Orange 4', sort: 1000 },
  { code: '#5f5f5f', name: 'Grey 37', sort: 1000 },
  { code: '#5f5f87', name: 'Medium Purple 4', sort: 1000 },
  { code: '#5f5faf', name: 'Slate Blue 3', sort: 1000 },
  { code: '#5f5fd7', name: 'Slate Blue 3', sort: 1000 },
  { code: '#5f5fff', name: 'Royal Blue 1', sort: 1000 },
  { code: '#5f8700', name: 'Chartreuse 4', sort: 1000 },
  { code: '#5f875f', name: 'Dark Sea Green 4', sort: 1000 },
  { code: '#5f8787', name: 'Pale Turquoise 4', sort: 1000 },
  { code: '#5f87af', name: 'Steel Blue', sort: 1000 },
  { code: '#5f87d7', name: 'Steel Blue 3', sort: 1000 },
  { code: '#5f87ff', name: 'Cornflower Blue', sort: 1000 },
  { code: '#5faf00', name: 'Chartreuse 3', sort: 1000 },
  { code: '#5faf5f', name: 'Dark Sea Green 4', sort: 1000 },
  { code: '#5faf87', name: 'Cadet Blue', sort: 1000 },
  { code: '#5fafaf', name: 'Cadet Blue', sort: 1000 },
  { code: '#5fafd7', name: 'Sky Blue 3', sort: 1000 },
  { code: '#5fafff', name: 'Steel Blue 1', sort: 1000 },
  { code: '#5fd700', name: 'Chartreuse 3', sort: 1000 },
  { code: '#5fd75f', name: 'Pale Green 3', sort: 1000 },
  { code: '#5fd787', name: 'Sea Green 3', sort: 1000 },
  { code: '#5fd7af', name: 'Aquamarine 3', sort: 1000 },
  { code: '#5fd7d7', name: 'Medium Turquoise', sort: 1000 },
  { code: '#5fd7ff', name: 'Steel Blue 1', sort: 1000 },
  { code: '#5fff00', name: 'Chartreuse 2', sort: 1000 },
  { code: '#5fff5f', name: 'Sea Green 2', sort: 1000 },
  { code: '#5fff87', name: 'Sea Green 1', sort: 1000 },
  { code: '#5fffaf', name: 'Sea Green 1', sort: 1000 },
  { code: '#5fffd7', name: 'Aquamarine 1', sort: 1000 },
  { code: '#5fffff', name: 'Dark Slate Gray 2', sort: 1000 },
  { code: '#870000', name: 'Dark Red', sort: 1000 },
  { code: '#87005f', name: 'Deep Pink 4', sort: 1000 },
  { code: '#870087', name: 'Dark Magenta', sort: 1000 },
  { code: '#8700af', name: 'Dark Magenta', sort: 1000 },
  { code: '#8700d7', name: 'Dark Violet', sort: 1000 },
  { code: '#8700ff', name: 'Purple', sort: 1000 },
  { code: '#875f00', name: 'Orange 4', sort: 1000 },
  { code: '#875f5f', name: 'Light Pink 4', sort: 1000 },
  { code: '#875f87', name: 'Plum 4', sort: 1000 },
  { code: '#875faf', name: 'Medium Purple 3', sort: 1000 },
  { code: '#875fd7', name: 'Medium Purple 3', sort: 1000 },
  { code: '#875fff', name: 'Slate Blue 1', sort: 1000 },
  { code: '#878700', name: 'Yellow 4', sort: 1000 },
  { code: '#87875f', name: 'Wheat 4', sort: 1000 },
  { code: '#878787', name: 'Grey 53', sort: 1000 },
  { code: '#8787af', name: 'Light Slate Grey', sort: 1000 },
  { code: '#8787d7', name: 'Medium Purple', sort: 1000 },
  { code: '#8787ff', name: 'Light Slate Blue', sort: 1000 },
  { code: '#87af00', name: 'Yellow 4', sort: 1000 },
  { code: '#87af5f', name: 'Dark Olive Green 3', sort: 1000 },
  { code: '#87af87', name: 'Dark Sea Green', sort: 1000 },
  { code: '#87afaf', name: 'Light Sky Blue 3', sort: 1000 },
  { code: '#87afd7', name: 'Light Sky Blue 3', sort: 1000 },
  { code: '#87afff', name: 'Sky Blue 2', sort: 1000 },
  { code: '#87d700', name: 'Chartreuse 2', sort: 1000 },
  { code: '#87d75f', name: 'Dark Olive Green 3', sort: 1000 },
  { code: '#87d787', name: 'Pale Green 3', sort: 1000 },
  { code: '#87d7af', name: 'Dark Sea Green 3', sort: 1000 },
  { code: '#87d7d7', name: 'Dark Slate Gray 3', sort: 1000 },
  { code: '#87d7ff', name: 'Sky Blue 1', sort: 1000 },
  { code: '#87ff00', name: 'Chartreuse 1', sort: 1000 },
  { code: '#87ff5f', name: 'Light Green', sort: 1000 },
  { code: '#87ff87', name: 'Light Green', sort: 1000 },
  { code: '#87ffaf', name: 'Pale Green 1', sort: 1000 },
  { code: '#87ffd7', name: 'Aquamarine 1', sort: 1000 },
  { code: '#87ffff', name: 'Dark Slate Gray 1', sort: 1000 },
  { code: '#af0000', name: 'Red 3', sort: 1000 },
  { code: '#af005f', name: 'Deep Pink 4', sort: 1000 },
  { code: '#af0087', name: 'Medium Violet Red', sort: 1000 },
  { code: '#af00af', name: 'Magenta 3', sort: 1000 },
  { code: '#af00d7', name: 'Dark Violet', sort: 1000 },
  { code: '#af00ff', name: 'Purple', sort: 1000 },
  { code: '#af5f00', name: 'Dark Orange 3', sort: 1000 },
  { code: '#af5f5f', name: 'Indian Red', sort: 1000 },
  { code: '#af5f87', name: 'Hot Pink 3', sort: 1000 },
  { code: '#af5faf', name: 'Medium Orchid 3', sort: 1000 },
  { code: '#af5fd7', name: 'Medium Orchid', sort: 1000 },
  { code: '#af5fff', name: 'Medium Purple 2', sort: 1000 },
  { code: '#af8700', name: 'Dark Goldenrod', sort: 1000 },
  { code: '#af875f', name: 'Light Salmon 3', sort: 1000 },
  { code: '#af8787', name: 'Rosy Brown', sort: 1000 },
  { code: '#af87af', name: 'Grey 63', sort: 1000 },
  { code: '#af87d7', name: 'Medium Purple 2', sort: 1000 },
  { code: '#af87ff', name: 'Medium Purple 1', sort: 1000 },
  { code: '#afaf00', name: 'Gold 3', sort: 1000 },
  { code: '#afaf5f', name: 'Dark Khaki', sort: 1000 },
  { code: '#afaf87', name: 'Navajo White 3', sort: 1000 },
  { code: '#afafaf', name: 'Grey 69', sort: 1000 },
  { code: '#afafd7', name: 'Light Steel Blue 3', sort: 1000 },
  { code: '#afafff', name: 'Light Steel Blue', sort: 1000 },
  { code: '#afd700', name: 'Yellow 3', sort: 1000 },
  { code: '#afd75f', name: 'Dark Olive Green 3', sort: 1000 },
  { code: '#afd787', name: 'Dark Sea Green 3', sort: 1000 },
  { code: '#afd7af', name: 'Dark Sea Green 2', sort: 1000 },
  { code: '#afd7d7', name: 'Light Cyan 3', sort: 1000 },
  { code: '#afd7ff', name: 'Light Sky Blue 1', sort: 1000 },
  { code: '#afff00', name: 'Green Yellow', sort: 1000 },
  { code: '#afff5f', name: 'Dark Olive Green 2', sort: 1000 },
  { code: '#afff87', name: 'Pale Green 1', sort: 1000 },
  { code: '#afffaf', name: 'Dark Sea Green 2', sort: 1000 },
  { code: '#afffd7', name: 'Dark Sea Green 1', sort: 1000 },
  { code: '#afffff', name: 'Pale Turquoise 1', sort: 1000 },
  { code: '#d70000', name: 'Red 3', sort: 1000 },
  { code: '#d7005f', name: 'Deep Pink 3', sort: 1000 },
  { code: '#d70087', name: 'Deep Pink 3', sort: 1000 },
  { code: '#d700af', name: 'Magenta 3', sort: 1000 },
  { code: '#d700d7', name: 'Magenta 3', sort: 1000 },
  { code: '#d700ff', name: 'Magenta 2', sort: 1000 },
  { code: '#d75f00', name: 'Dark Orange 3', sort: 1000 },
  { code: '#d75f5f', name: 'Indian Red', sort: 1000 },
  { code: '#d75f87', name: 'Hot Pink 3', sort: 1000 },
  { code: '#d75faf', name: 'Hot Pink 2', sort: 1000 },
  { code: '#d75fd7', name: 'Orchid', sort: 1000 },
  { code: '#d75fff', name: 'Medium Orchid 1', sort: 1000 },
  { code: '#d78700', name: 'Orange 3', sort: 1000 },
  { code: '#d7875f', name: 'Light Salmon 3', sort: 1000 },
  { code: '#d78787', name: 'Light Pink 3', sort: 1000 },
  { code: '#d787af', name: 'Pink 3', sort: 1000 },
  { code: '#d787d7', name: 'Plum 3', sort: 1000 },
  { code: '#d787ff', name: 'Violet', sort: 1000 },
  { code: '#d7af00', name: 'Gold 3', sort: 1000 },
  { code: '#d7af5f', name: 'Light Goldenrod 3', sort: 1000 },
  { code: '#d7af87', name: 'Tan', sort: 1000 },
  { code: '#d7afaf', name: 'Misty Rose 3', sort: 1000 },
  { code: '#d7afd7', name: 'Thistle 3', sort: 1000 },
  { code: '#d7afff', name: 'Plum 2', sort: 1000 },
  { code: '#d7d700', name: 'Yellow 3', sort: 1000 },
  { code: '#d7d75f', name: 'Khaki 3', sort: 1000 },
  { code: '#d7d787', name: 'Light Goldenrod 2', sort: 1000 },
  { code: '#d7d7af', name: 'Light Yellow 3', sort: 1000 },
  { code: '#d7d7d7', name: 'Grey 84', sort: 1000 },
  { code: '#d7d7ff', name: 'Light Steel Blue 1', sort: 1000 },
  { code: '#d7ff00', name: 'Yellow 2', sort: 1000 },
  { code: '#d7ff5f', name: 'Dark Olive Green 1', sort: 1000 },
  { code: '#d7ff87', name: 'Dark Olive Green 1', sort: 1000 },
  { code: '#d7ffaf', name: 'Dark Sea Green 1', sort: 1000 },
  { code: '#d7ffd7', name: 'Honeydew 2', sort: 1000 },
  { code: '#d7ffff', name: 'Light Cyan 1', sort: 1000 },
  { code: '#ff005f', name: 'Deep Pink 2', sort: 1000 },
  { code: '#ff0087', name: 'Deep Pink 1', sort: 1000 },
  { code: '#ff00af', name: 'Deep Pink 1', sort: 1000 },
  { code: '#ff00d7', name: 'Magenta 2', sort: 1000 },
  { code: '#ff5f00', name: 'Orange Red 1', sort: 1000 },
  { code: '#ff5f5f', name: 'Indian Red 1', sort: 1000 },
  { code: '#ff5f87', name: 'Indian Red 1', sort: 1000 },
  { code: '#ff5faf', name: 'Hot Pink', sort: 1000 },
  { code: '#ff5fd7', name: 'Hot Pink', sort: 1000 },
  { code: '#ff5fff', name: 'Medium Orchid 1', sort: 1000 },
  { code: '#ff8700', name: 'Dark Orange', sort: 1000 },
  { code: '#ff875f', name: 'Salmon 1', sort: 1000 },
  { code: '#ff8787', name: 'Light Coral', sort: 1000 },
  { code: '#ff87af', name: 'Pale Violet Red 1', sort: 1000 },
  { code: '#ff87d7', name: 'Orchid 2', sort: 1000 },
  { code: '#ff87ff', name: 'Orchid 1', sort: 1000 },
  { code: '#ffaf00', name: 'Orange 1', sort: 1000 },
  { code: '#ffaf5f', name: 'Sandy Brown', sort: 1000 },
  { code: '#ffaf87', name: 'Light Salmon 1', sort: 1000 },
  { code: '#ffafaf', name: 'Light Pink 1', sort: 1000 },
  { code: '#ffafd7', name: 'Pink 1', sort: 1000 },
  { code: '#ffafff', name: 'Plum 1', sort: 1000 },
  { code: '#ffd700', name: 'Gold 1', sort: 1000 },
  { code: '#ffd75f', name: 'Light Goldenrod 2', sort: 1000 },
  { code: '#ffd787', name: 'Light Goldenrod 2', sort: 1000 },
  { code: '#ffd7af', name: 'Navajo White 1', sort: 1000 },
  { code: '#ffd7d7', name: 'Misty Rose 1', sort: 1000 },
  { code: '#ffd7ff', name: 'Thistle 1', sort: 1000 },
  { code: '#ffff5f', name: 'Light Goldenrod 1', sort: 1000 },
  { code: '#ffff87', name: 'Khaki 1', sort: 1000 },
  { code: '#ffffaf', name: 'Wheat 1', sort: 1000 },
  { code: '#ffffd7', name: 'Cornsilk 1', sort: 1000 },
  { code: '#080808', name: 'Grey 3', sort: 1000 },
  { code: '#121212', name: 'Grey 7', sort: 1000 },
  { code: '#1c1c1c', name: 'Grey 11', sort: 1000 },
  { code: '#262626', name: 'Grey 15', sort: 1000 },
  { code: '#303030', name: 'Grey 19', sort: 1000 },
  { code: '#3a3a3a', name: 'Grey 23', sort: 1000 },
  { code: '#444444', name: 'Grey 27', sort: 1000 },
  { code: '#4e4e4e', name: 'Grey 30', sort: 1000 },
  { code: '#585858', name: 'Grey 35', sort: 1000 },
  { code: '#626262', name: 'Grey 39', sort: 1000 },
  { code: '#6c6c6c', name: 'Grey 42', sort: 1000 },
  { code: '#767676', name: 'Grey 46', sort: 1000 },
  { code: '#8a8a8a', name: 'Grey 54', sort: 1000 },
  { code: '#949494', name: 'Grey 58', sort: 1000 },
  { code: '#9e9e9e', name: 'Grey 62', sort: 1000 },
  { code: '#a8a8a8', name: 'Grey 66', sort: 1000 },
  { code: '#b2b2b2', name: 'Grey 70', sort: 1000 },
  { code: '#bcbcbc', name: 'Grey 74', sort: 1000 },
  { code: '#c6c6c6', name: 'Grey 78', sort: 1000 },
  { code: '#d0d0d0', name: 'Grey 82', sort: 1000 },
  { code: '#dadada', name: 'Grey 85', sort: 1000 },
  { code: '#e4e4e4', name: 'Grey 89', sort: 1000 },
  { code: '#eeeeee', name: 'Grey 93', sort: 1000 }]

export {
  hexToRgb,
  colors,
}
