const state = {
  sizeSwitcher: 'pattern',
}
const getters = {}
const mutations = {
  SET_SWITCHER (state, data) {
    state.sizeSwitcher = data
  },
}
const actions = {
  async setGlobalSwitcher ({ commit }, data) {
    commit('SET_SWITCHER', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
