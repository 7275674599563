import { defineStore } from 'pinia'
import MiniSearch from 'minisearch'

let miniSearch

export const useColorsStore = defineStore('Colors', {
  state: () => ({
    search: '',
    colors: [],
    res: [],
    error: false,
    palette: [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
    colorwaysStep: 1,
  }),
  getters: {
    count (state) {
      return state.res.length
    },
    palletteNotEmpty (state) {
      return state.palette.filter(c => c !== undefined).length > 0
    },
  },
  actions: {
    async getColors () {
      const req = await fetch('/colors.json')
      const res = await req.json()
      this.colors = res.map((c, i) => ({ ...c, id: `${c.HEX}-${i}`, HEX: `#${c.HEX}` }))
      miniSearch = new MiniSearch({
        fields: ['HEX', 'LAB', 'Name', 'TCX', 'sRGB'],
        storeFields: ['HEX', 'LAB', 'Name'],
        searchOptions: {
          boost: { Name: 2 },
          prefix: true,
          fuzzy: 0.2,
        },
      })
      miniSearch.addAll(this.colors)
      this.res = miniSearch.search(MiniSearch.wildcard)
    },
    doSearch (v) {
      this.res = v === ''
        ? miniSearch.search(MiniSearch.wildcard)
        : this.res = miniSearch.search(v)
    },
    setUnsetPalette (color) {
      for (let i = 0; i < this.palette.length; i++) {
        if (this.palette[i] === undefined) {
          this.palette[i] = color
          this.palette = [...this.palette]
          break
        }
        if (this.palette[i] === color) {
          this.palette.splice(i, 1)
          this.palette.push(undefined)
          this.palette = [...this.palette]
          break
        }
      }
    },
    resetPalette () {
      this.palette = new Array(10).fill(undefined)
    },
    isSelected (color) {
      return this.palette.includes(color)
    },
  },
})
