<template>
  <div class="FabricCalculatorAdvanced relative-position">
    <service-error-dialog
      v-if="hasLoadingErrors"
      @try-again="submit"
      @close="$emit('close')"
    />
    <div
      v-if="loadingInProgress"
      class="FabricCalculatorAdvanced__loader col row items-center q-py-md full-height"
    >
      <q-spinner-dots
        class="col"
        color="primary"
        size="2em"
      />
    </div>

    <div
      v-else-if="isSelectDisplays"
      class="FabricCalculatorAdvanced__selector q-pa-md"
    >
      <q-select
        v-for="layer in designLayerWidths"
        :key="layer.uid"
        v-model="selectedWidths[layer.uid]"
        :behavior="$q.screen.lt.sm ? 'dialog' : 'menu'"
        :options="layer.fabricWidthsOptions"
        :label="`${layer.name} width`"
        emit-value
        map-options
      />
    </div>

    <div v-else-if="isResultDisplays"
         class="col FabricCalculatorAdvanced__result full-height"
    >
      <q-scroll-area
          v-if="designLayers.length"
          style="position: absolute; top: 16px; bottom: 16px; left: 16px; right: 16px"
      >
        <div class="q-py-md"
             v-for="(result, key) in results"
             :key="key"
             ref="contents"
        >
          <div v-if="result.fabricLengthInCm && result.fabricWidthInCm"
               class="col-shrink FabricCalculatorAdvanced__resultDescription q-mb-md text-center"
          >
            {{ resultDescription(result) }}
          </div>
          <div class="FabricCalculatorAdvanced__svgContainer"
               :style="{ width: resultStyles[result.fabricLengthInCm] }"
               v-html="result.svg"></div>
        </div>
      </q-scroll-area>
      <p v-else class="FabricCalculatorAdvanced__message">Advanced calculator is not available. This design has no layers.</p>
    </div>

  </div>

</template>

<script>
import ServiceErrorDialog from '@/components/Dialog/ServiceErrorDialog'
import { Measurement } from '@/helpers/measurement'
import { mapGetters, mapState } from 'vuex'
import { fetchAdvancedFabricCalculatorResults, fetchFabricLayersForDesign } from '@/api'
import { advancedLayers } from '@/components/Fabric/FabricWidths'
import { designOptionsValidator } from '@/pages/designerConstructor'

const AVAILABLE_VIEWS = Object.freeze({
  SELECT: 'select',
  RESULT: 'result',
})

export default {
  name: 'FabricCalculatorAdvanced',

  components: {
    ServiceErrorDialog,
  },

  props: {
    designOptions: {
      type: Object,
      required: true,
      validator: val => designOptionsValidator(val),
    },
  },

  data: () => ({
    AVAILABLE_VIEWS,
    currentView: AVAILABLE_VIEWS.SELECT,
    selectedWidths: {},
    /**
     * @property {{uid: String, fabricWidthsInCm: Array}[]}
     */
    layersWidths: advancedLayers(),
    /**
     * @property {{name: String, uid: String}[]}
     */
    designLayers: [],
    results: undefined,
    loadingInProgress: false,
    hasLoadingErrors: false,
  }),

  computed: {
    selectedLayers () {
      return Object.keys(this.selectedWidths).map(uid => ({
        fabricTypeUid: uid,
        fabricWidth: this.selectedWidths[uid],
      }))
    },
    currentDisplayMode () {
      if (this.loadingInProgress) {
        return 'loading'
      }
      if (this.hasLoadingErrors) {
        return 'error'
      }
      if (this.currentView === this.AVAILABLE_VIEWS.SELECT) {
        return 'select'
      }
      if (this.currentView === this.AVAILABLE_VIEWS.RESULT) {
        return 'result'
      }
      return undefined
    },
    isSelectDisplays () {
      return this.currentDisplayMode === 'select'
    },
    isResultDisplays () {
      return this.currentDisplayMode === 'result'
    },
    designLayerWidths () {
      return this.designLayers
        .filter(designLayer => this.layersWidths.find(layer => layer.uid === designLayer.uid))
        .map(item => {
          let designWidths = this.layersWidths.find(layer => layer.uid === item.uid)
          // т.к. uid отправленные не совпадают с uid полученными, получаем с api параметр fabricWidthsInCm
          let fabricWidthsOptions = this.convertFabricWidthsOptions(designWidths.fabricWidthsInCm)
          return {
            name: item.name,
            uid: item.uid,
            fabricWidthsOptions,
          }
        })
    },
    resultStyles () {
      let longestLength = this.results.reduce(
        (acc, item) => (item.fabricLengthInCm > acc ? item.fabricLengthInCm : acc),
        0,
      )
      // длина контейнера с изображеним пропорциональна длинам других контейнеров.
      // если самая большая длина больше метра - картинка занимает 100% ширины контейнера.
      // Если меньше - условная ширина внешнего блока принмаиется за метр.

      return Object.fromEntries(this.results.map(result => {
        let width
        if (longestLength > 100) {
          width = result.fabricLengthInCm * 100 / longestLength + '%'
        } else {
          width = result.fabricLengthInCm + '%'
        }
        return [result.fabricLengthInCm, width]
      }))
    },
    ...mapState([
      'units',
    ]),
    ...mapGetters(['getPatternsFontName']),
  },

  async created () {
    this.$emit('current-view', this.currentDisplayMode)
    await this.loadLayersForDesign()
  },

  watch: {
    currentDisplayMode (val) {
      this.$emit('current-view', val)
    },
    // при необходимости отслеживания создать computed свойство
    // elementIds () {
    //   this.loadLayersForDesign()
    // },
  },

  methods: {
    selectedWidthLabel (selectedWidth) {
      let label
      this.designLayerWidths.find(item => {
        let option = item.fabricWidthsOptions.find(wOption => wOption.value === selectedWidth)
        if (option) {
          label = option.label
          return true
        }
      })
      return label
    },
    resultDescription ({ fabricWidthInCm, fabricLengthInCm }) {
      if (this.units === Measurement.CM) {
        return `Fabric yield: ${fabricLengthInCm / 100}m at ${this.selectedWidthLabel(
          fabricWidthInCm)} width fabric`
      }
      if (this.units === Measurement.INCH) {
        // todo: вынести ярды в Measurement
        let inchesYield = new Measurement(fabricLengthInCm).getValue(Measurement.INCH)
        let yards = Math.round(inchesYield * 0.0278 * 100) / 100
        return `Fabric yield: ${yards}yd at ${this.selectedWidthLabel(fabricWidthInCm)} width fabric`
      }
      return undefined
    },
    convertFabricWidthsOptions (fabricWidthsInCm) {
      if (this.units === Measurement.CM) {
        return fabricWidthsInCm.map(item => ({
          value: item.value,
          label: `${item.value} cm`,
        }))
      } else {
        return fabricWidthsInCm.map(item => ({
          value: item.value,
          label: `${item.inchLabel}`,
        }))
      }
    },
    async loadLayersForDesign () {
      this.loadingInProgress = true
      try {
        this.designLayers = await fetchFabricLayersForDesign(
          {
            ...this.designOptions,
          },
        )
        this.resetSelectedWidths()
        if (!this.designLayers.length) {
          this.currentView = AVAILABLE_VIEWS.RESULT
        }
      } catch (e) {
        this.$emit('error', { title: 'Error', message: e.message })
      } finally {
        this.loadingInProgress = false
      }
    },
    resetSelectedWidths () {
      let entries = Object.values(this.designLayers)
        .map(item => {
          let foundWidth = this.layersWidths.find(width => width.uid === item.uid)
          let minWidthVal = foundWidth.fabricWidthsInCm.reduce((acc, item) => {
            return item.value < acc || acc === undefined ? item.value : acc
          }, undefined)
          return [item.uid, minWidthVal]
        })
      this.selectedWidths = Object.fromEntries(entries)
    },
    resetResult () {
      this.results = undefined
      this.hasLoadingErrors = false
      this.loadingInProgress = false
    },
    async submit () {
      this.resetResult()
      this.currentView = AVAILABLE_VIEWS.RESULT
      await this.loadFabrics(this.selectedWidth)
    },
    async loadFabrics (widths) {
      this.loadingInProgress = true
      this.hasLoadingErrors = false
      try {
        this.results = await fetchAdvancedFabricCalculatorResults(
          {
            ...this.designOptions,
            layers: this.selectedLayers,
            fabricWidths: widths,
            fontName: this.getPatternsFontName,
          },
        )
      } catch (e) {
        this.$emit('error', { title: 'Error', message: e.message })
        this.hasLoadingErrors = true
      }
      this.loadingInProgress = false
    },
    getPrintData () {
      let result = ''
      this.$refs['contents'].forEach(item => {
        result += item.innerHTML
      })
      return result
    },
  },
}
</script>

<style lang="scss">
  .FabricCalculatorAdvanced {
    &__loader {
      position:         absolute;
      width:            100%;
      height:           100%;
      z-index:          250;
      background-color: rgba(255, 255, 255, 0.7);
    }

    &__resultDescription {
      font-size: 15px;
    }

    &__svgContainer svg {
      max-height: 100vh;
    }

    &__message {
      margin: 3em;
      text-align: center;
    }
  }

  @media print {
    .FabricCalculatorAdvanced {
      &__svgContainer {
        /*page-break-after: always;*/

        svg {
          max-height: 80vh;
        }
      }
    }
  }
</style>
