<template>
  <div>
    <q-btn-group outline>
      <q-btn
        :outline="units !== Measurement.INCH"
        :color="units === Measurement.INCH ? 'black' : 'primary'"
        @click.prevent="setUnits({units: Measurement.INCH})"
        :class="units === Measurement.INCH ? 'black-button' : 'outline-button'"
      >
        inch
      </q-btn>
      <q-btn
        :outline="units !== Measurement.CM"
        :color="units === Measurement.CM ? 'black' : 'primary'"
        @click.prevent="setUnits({units: Measurement.CM})"
        :class="units === Measurement.CM ? 'black-button' : 'outline-button'"
      >
        cm
      </q-btn>
    </q-btn-group>
  </div>
</template>

<script>
import { Measurement } from '@/helpers/measurement'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UnitsToggleComponent',

  created () {
    this.Measurement = Measurement
  },

  computed: {
    ...mapState([
      'units',
    ]),
  },

  methods: {
    ...mapActions([
      'setUnits',
    ]),
  },
}
</script>

<style lang="scss">
@import "src/styles/variables";
</style>
