<template>
  <ModalComponent
    type="error"
    :show="expiredDemoMode"
    :showIcon="false"
  >
    <template v-slot:title>Your demo version has expired ...</template>
    <template v-slot:description>
      Please add a payment method and select a subscription type to continue using PatternFast features!
    </template>
    <template v-slot:buttons>
      <ButtonPrimary class="small one" @click.native="$router.push({name: 'user_billing'})">
        Go to Billing
      </ButtonPrimary>
    </template>
  </ModalComponent>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
export default {
  name: 'expiredDemoMode',
  components: {
    ButtonPrimary,
    ModalComponent,
  },
  computed: {
    ...mapGetters('user', ['demoMode', 'daysRemaining']),
    expiredDemoMode () {
      return this.demoMode && this.daysRemaining === 0
    },
  },
}
</script>
