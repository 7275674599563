<template>
  <div class="SewingComponent bg-white full-height relative-position print-hide">
    <q-btn class="SewingComponent__closeButton" icon="close" flat round dense v-close-popup/>

    <div class="row full-height">
      <div class="SewingComponent__information col column q-pa-md">
        <div class="modal-header q-pa-md">Sewing Information</div>
        <template v-if="!si">
          <div class="SewingComponent__loader row">
            <q-spinner-dots
              class="col-2 offset-5"
              color="primary"
              size="2em"
            />
          </div>
        </template>
        <div v-if="si" class="SewingComponent__contentContainer col-grow q-pa-md" style="position: relative">
          <q-scroll-area style="position: absolute; top: 16px; bottom: 16px; left: 16px; right: 16px">
            <div class="SewingComponent__main">
              <div class="logo-block">
                <div class="logo-row" v-html="si.logo"></div>
                <div class="download-links" v-if="isDebugMode">
                  <span class="text">Download PDF: </span>
                  <a href="#" @click.prevent="downloadPDF('portrait')">Portrait</a>
                  <span class="delimiter">|</span>
                  <a href="#" @click.prevent="downloadPDF('landscape')">Landscape</a>
                </div>
              </div>
              <div class="page page-1">
                <div class="col-left">
                  <div class="property-row">
                    <label>Pattern name: </label>
                    <span class="value">{{ designOptions.designName }}</span>
                  </div>
                  <div class="property-row">
                    <label>Design details: </label>
                    <span class="value">{{ si.details }}</span>
                  </div>
                  <div class="property-row">
                    <label>Suggested materials: </label>
                    <span class="value">{{ si.materials }}</span><br>
                    <span>See Pattern Layout pages for estimated fabric yardage.</span>
                  </div>
                  <div class="property-row" v-if="displayFit">
                    <label>Pattern fit: </label>
                    <span class="value">{{ displayFit }}</span>
                  </div>
                </div>
                <div class="col-right sketch">
                  <div class="sketch-title">Technical sketch</div>
                  <div class="sketch-container">
                    <div class="side front">
                      <div class="title">Front</div>
                      <div class="image-wrapper" v-html="si.sketch_front"></div>
                    </div>
                    <div class="side back">
                      <div class="title">Back</div>
                      <div class="image-wrapper" v-html="si.sketch_back"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page page-2">
                <div class="col-left">
                  <template v-if="si.pieces && si.pieces.length">
                    <div class="label-row">Pattern pieces:</div>
                    <ul class="pieces-list">
                      <li v-for="piece in si.pieces" :key="piece">{{ piece }}</li>
                    </ul>
                  </template>
                  <ul class="measurements-list">
                    <li>Print actual size (100% Scale)</li>
                    <li>Seam allowance: {{ displaySeamAllowance }}</li>
                    <li v-if="false">Hem: 1 1/4" (3.2 cm)</li>
                  </ul>
                </div>
                <div class="col-right">
                  <div class="image-wrapper" v-html="si.pieces_image"></div>
                </div>
              </div>
              <div class="page page-3">
                <div class="title">Cutting and Marking Instructions:</div>
                <ul class="cutting">
                  <li>Pre-wash the fabric if needed to pre-shrink (unless labeled &lt;pre-shrunk&gt;)</li>
                  <li>Press your fabric before cutting.</li>
                  <li>Select with nap layout for fabrics with a nap, one-way prints, pile, or fabric surface shading/luster.</li>
                  <li>Due to most knits having surface shading, a with nap layout must be used.</li>
                  <li>
                    For single layer pattern layout, place fabric right side up. (For fabrics with pile, use a mirrored pattern that has been created for tracing &amp; marking with the fabric laying face side down).
                  </li>
                  <li>
                    If your fabric has plaid or repeat pattern design add an additional 1/2 - 1 yard to your fabric
                    calculations.
                  </li>
                  <li>
                    Cutting Layouts show approximate position of pattern pieces, as pattern placement may vary according
                    to your actual fabric width. Before
                    cutting your fabric, place all pieces on fabric according to the selected layout and pin. Cut out
                    the pattern pieces using a sharp dressmaker’s
                    shears.
                  </li>
                  <li>
                    Transfer Markings and construction lines to the fabrics’s wrong side, using chalk, pattern pencil,
                    tracing paper and dressmaker wheel. Markings that need to show on the right side of fabric should be thread traced.
                  </li>
                  <li>Cut Notches inward. Do not exceed the seam allowance line.</li>
                </ul>
                <div class="measurements">
                  <div class="body">
                    <div class="title">Body Measurements:</div>
                    <ul>
                      <li v-for="item in si.body_measurements" :key="item.name">
                        {{ item.name + ': ' + item.in + '" (' + item.cm.toFixed(2) + ' cm)' }}
                      </li>
                    </ul>
                  </div>
                  <div class="pattern">
                    <div class="title">
                      Pattern Measurements<br>
                      (Circumferences are Divided by 2):
                    </div>
                    <ul class="col-1">
                      <li v-for="item in si.specs"
                          :key="item.name">
                        {{ item.name + ': ' + item.in + '" (' + item.cm.toFixed(2) + ' cm)' }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="seams">
                  <div class="title">Seams and Seam Allowances:
                    <span style="font-weight:normal;font-style:italic;margin-left:10px;font-size:.9em">See “Sewing Tips; Seams” for more guidance on seaming</span>
                  </div>
                  <ul>
                    <li>Pin or baste seams, right side together, matching notches.</li>
                    <li>We recommend that you fit the garment before stitching.</li>
                  </ul>
                </div>
              </div>
              <div class="page-4" v-if="designFabric === 'knits'">
                <div class="title">Fabric Stretch Calculator:</div>
                <div class="image-container">
                  <img src="@/assets/fabric_stretch.png"/>
                </div>
                <div class="steps">
                  <div class="step">
                    Step 1: Cut 4" (10cm) wide fabric square, align with the rectangle above.
                  </div>
                  <div class="step">
                    Step 2: Hold the left edge or pin it to the paper, aligned with the edge of the chart. Stretch the
                    right edge to
                    the maximum, pulling toward the closest line, representing the stretch factor.
                    Find the stretch of the fabric, based on the matching rectangle.
                  </div>
                </div>
              </div>
              <div class="page-5">
                <h1 v-if="computedTapes && computedTapes.length">Pattern Layout and Cutting Suggestions:</h1>
                <div class="tape-block" v-for="(tape, i) in computedTapes" :key="i" :data-index="i">
                  <div class="title">
                    {{ tape.fabricTypeUid || 'Fabric' }} {{ tape.orig_width}}, {{ tape.isFolded ? 'folded' : 'single layer'
                    }}. With or Without Nap. Yardage needed: {{ tape.display_length }}
                  </div>
                  <div class="image-wrap" :style="{width: tape.containerWidthPercent + '%'}" v-html="tape.orig_svg"></div>
                </div>
              </div>
              <div class="page-6">
                <h1 v-if="si.instructions && si.instructions.length">Sewing Instructions:</h1>
                <div class="instruction-step" v-for="(step, i) in si.instructions" :key="i" :data-id="step.id">
                  <div class="title">
                    Step {{ i + 1 }}:
                    <a
                      v-if="$store.state.isLoggedIn"
                      target="_blank"
                      :href="getInstructionsLink(step.id)">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z" fill="#1F1E1D"/>
                      </svg>
                    </a>
                  </div>
                  <div class="content" v-html="step.content"></div>
                  <div v-if="step.images.length" class="images">
                    <div v-for="(img, key) in step.images" :key="key" class="image-wrap">
                      <img :src="img"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-scroll-area>
        </div>
      </div>
    </div>

    <printable-component v-if="printMode">
      <div
        v-for="instruction in sewingInstructions"
        :key="instruction.id"
        class="SewingComponent__paragraph q-pb-md q-pr-md"
      >
        {{ instruction.content }}
        <img
          v-for="(image, i) in instruction.images"
          :key="i"
          class="SewingComponent__image"
          :src="image.airtable_url || image.image_path"
          alt=""
        >
      </div>
    </printable-component>

  </div>
</template>

<script>
import { fetchSewingInstructions, getSewingInstructionsPDF } from '@/api'
import PrintableComponent from '@/components/Printable/PrintableComponent'
import { print } from '@/components/Printable/print-mixin'
import { fitSelect, getFabricTypeSelectOptionsList } from '@/components/Ease/easeSelects'
import { mapGetters } from 'vuex'
import { designOptionsValidator } from '@/pages/designerConstructor'
import { setViewBox } from '@/helpers/svg'

const INCH_CM = 2.54

export default {
  name: 'SewingComponent',

  components: {
    PrintableComponent,
  },

  mixins: [print],

  data: () => ({
    si: undefined,
    downloadingPDF: false,
  }),

  props: {
    designOptions: {
      type: Object,
      required: true,
      validator: val => designOptionsValidator(val),
    },
    designFabric: {
      type: String,
      required: true,
      validator: item => ['woven', 'knit'].includes(item),
    },
    sketchSides: {
      type: Object,
      default: () => {
      },
    },
  },

  created () {
    window.sewing = this
  },

  mounted () {
    if (!this.si) {
      this.fetchSewingInstructions()
    }
  },

  methods: {
    async fetchSewingInstructions () {
      try {
        this.si = await fetchSewingInstructions({
          ...this.designOptions,
          categoryCode: this.designOptions.designCategory,
          fabricCode: this.designFabric,
          fabricType: this.designOptions.fabricType,
          fontName: this.getPatternsFontName,
        })
      } catch (e) {
        this.$emit('error', e.message || 'Cannot load instructions')
        return
      }
      this.si.sketch_front = setViewBox(this.si.sketch_front || this.sketchSides.front)
      this.si.sketch_back = setViewBox(this.si.sketch_back || this.sketchSides.back)
    },
    convertToFraction (number, base) {
      const inches = number / base
      let intPart = Math.floor(inches)
      let decPart = Math.round((number - intPart * base) / base * 8)
      if (decPart === 8) {
        intPart += 1
        decPart = 0
      }
      let decString = decPart > 0 ? decPart + '/8' : ''
      if (decString === '2/8') {
        decString = '1/4'
      } else if (decString === '4/8') {
        decString = '1/2'
      } else if (decString === '6/8') {
        decString = '3/4'
      }
      return (intPart > 0 ? intPart : '') + ((decString) ? (intPart > 0 ? ' ' : '') + decString : '')
    },
    async downloadPDF (orientation) {
      if (this.downloadingPDF) {
        return
      }
      this.downloadingPDF = true
      try {
        await getSewingInstructionsPDF({
          ...this.designOptions,
          categoryCode: this.designOptions.designCategory,
          orientation,
          fabricCode: this.designFabric,
          fabricType: this.designOptions.fabricType,
          postData: {
            sketchFront: this.si.sketch_front,
            sketchBack: this.si.sketch_back,
            name: this.designOptions.designName,
          },
        })
      } catch (e) {
        this.$emit('error', e.message || 'Cannot load instructions PDF')
        return
      }
      this.downloadingPDF = false
    },
    getInstructionsLink (stepId) {
      return `https://admin${this.isSandbox ? '-sandbox' : ''}.tailornova.com/instruction/` + stepId
    },
  },

  computed: {
    elementIds () {
      return this.designOptions.elementIds
    },
    displayFabric () {
      const options = getFabricTypeSelectOptionsList(this.designFabric)
      const option = options.find(opt => opt.value === this.designOptions.fabricType)
      if (option) {
        return option.label
      } else {
        return this.designFabric
      }
    },
    displayFit () {
      const option = fitSelect.find(el => el.value === this.designOptions.fit)
      if (option) {
        return option.label
      } else {
        return ''
      }
    },
    displaySeamAllowance () {
      const sa = this.designOptions.seamAllowance
      const saIn = this.convertToFraction(sa, INCH_CM)
      return `${saIn}" (${sa} cm)`
    },
    downloadLink () {
      return `/api/v1/sewing-instructions?elementIds=${this.this.designOptions.elementIds}&categoryCode=${this.designOptions.designCategory}&mannequinId=${this.designOptions.mannequinId}&format=pdf`
    },
    computedTapes () {
      if (!this.si || !this.si.tapes) {
        return []
      }
      let maxLength = Math.max(...this.si.tapes.map(t => t.fabricLengthInCm))
      const maxContainerWidthPercent = maxLength > 100 ? 100 : 70
      return this.si.tapes.map(tape => {
        tape.containerWidthPercent = (tape.fabricLengthInCm / maxLength) * maxContainerWidthPercent
        return tape
      })
    },
    isDebugMode () {
      return this.$store.state.isLoggedIn
    },
    isSandbox () {
      return document.location.host.indexOf('sandbox') > -1
    },
    ...mapGetters(['getPatternsFontName']),
  },

  watch: {
    elementIds () {
      this.si = null
    },
  },
}
</script>

<style lang="scss">
  @import "src/styles/variables";

  .SewingComponent {

    &__closeButton {
      position: absolute;
      right:    24px;
      top:      24px;
    }

    &__paragraph {
      white-space: pre-wrap;
    }

    &__image {
      max-width: 100%;
      width:     100%;
    }

    &__technicalSketch {
      background-color: $light-gray-color;
    }

    &__loader {
      margin-top: 150px;
    }

    &__main {
      .logo-block {
        display:          flex;
        justify-content:  space-between;
        margin-bottom:    20px;
        align-items:      center;
      }

      .logo-row {
        width:      50%;
        text-align: right;
      }

      .download-links {
        padding-left: 1rem;

        span.text {
          margin-right: 3px;
        }

        span.delimiter {
          margin: 0 2px;
        }

        a {
          text-decoration: underline;
          cursor:          pointer;

          &:hover {
            text-decoration: none;
          }
        }
      }

      font-size: 15px;

      .page {
        margin-bottom: 30px;
      }

      .page-1 {
        display: flex;

        .col-left {
          padding-top: 15px;
          padding-right: 15px;

          .property-row {
            margin-bottom: 30px;

            > label {
              font-weight: bold;
              margin-right: 2px;
              display: inline;
            }
          }
        }

        .col-right {
          flex: 1;
          padding-right: 20px;
          position:      relative;
          left:          -25px;

          .sketch-title {
            font-weight: bold;
            text-align: center;
            margin-bottom: 25px;
          }

          .sketch-container {
            display: flex;
            flex:    1;

            .side {
              flex: 1;
              text-align: center;

              .title {
                font-weight: bold;
                text-transform: uppercase;
                font-size: .8em;
              }
              .image-wrapper {
                padding: 10px;
                svg {
                  width: 145px;
                }
              }
            }
          }
        }
        .col-right.sketch {
          padding-top: 15px;
        }
      }

      .page-2 {
        display: flex;

        .col-left {
          flex: 0 0 40%;

          .label-row {
            font-weight: bold;
            margin-bottom: 15px;
          }

          ul.pieces-list {
            margin-bottom: 45px;

            li {
              margin-bottom: 5px;
            }
          }

          ul.measurements-list {
            font-weight: bold;

            li {
              margin-bottom: 5px;
            }
          }
        }

        .col-right {
          flex: 0 0 55%;
        }
      }

      .page-3 {
        .title {
          font-weight:   bold;
          margin-bottom: 15px;
        }

        ul.cutting {
          margin-bottom: 30px;

          li {
            list-style-type: disc;
            margin-bottom:   5px;
            margin-left:     25px;
            margin-right:    10px;
          }
        }

        .measurements {
          padding-left:  25px;
          display:       flex;
          font-size:     .9em;
          margin-bottom: 25px;

          .body {
            flex: 0 0 27%;

            ul {
              li {
                margin-bottom:   5px;
                list-style-type: none;
              }
            }
          }

          .pattern {
            flex: 1;

            .title, li {
              float:         left;
              width:         50%;
              margin-bottom: 5px;
            }

            ul {
              li {
                list-style-type: none;
              }
            }
          }
        }

        .seams {
          padding-left: 25px;
        }
      }

      .page-4 {
        div.title {
          font-weight: bold;
          margin:      20px;
          font-size:   20px;
        }

        .image-container {
          img {
            max-width: 95%;
          }

          margin-bottom: 20px;
        }

        .steps {
          .step {
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }

      .page-5, .page-6 {
        h1 {
          font-size:     22px;
          font-weight:   bold;
          text-align:    center;
          margin-top:    15px;
          margin-bottom: 0;
        }
      }

      .page-5 {
        .tape-block {
          margin-bottom: 30px;

          .title {
            margin-bottom: 12px;
            text-align:    center;
          }

          .image-wrap {
            max-width: 96%;
            margin-left: 3%;
          }
        }
      }

      .page-6 {
        padding: 0 10px;

        .title {
          font-weight:   bold;
          font-size:     1em;
          margin-bottom: 15px;
          display: flex;
          align-items: center;

          a {
            margin-left: 10px;
          }
        }

        .instruction-step {
          margin-bottom: 25px;

          .image-wrap {
            text-align: center;
            margin:     10px 0;

            img {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
@media (max-width: 800px) {
  .SewingComponent__main {
    .page-1 {
      flex-wrap: wrap;
      .col-left {
        padding: 0;
      }
      .col-right {
        padding: 0;
        left: 0;
        .sketch-container {
          flex-wrap: wrap;
        }
      }
    }
    .page-2 {
      flex-direction: column;
      .col-left {
        padding-bottom: 1rem;
      }
    }
    .page-3 .measurements {
      flex-direction: column;
      .body {
        padding-bottom: 2rem;
      }
      .pattern .title,
      .pattern li {
        width: 100%;
      }
    }
    .page-5, .page-6 {
        h1 {
          line-height: 29px;
          margin-bottom: 15px;
        }
      }
  }
}
</style>
