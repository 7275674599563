<template>
  <div :class="`elements__row ${item.status === 'NEW' ? 'change' : ''}`">
    <div class="name_info">
      <figure>
        <img :src="item.img" v-if="item.img">
        <div :class="`userpic-placeholder col${item._id % 10}`" v-else>
          <span>{{ item.firstName.charAt(0) }}{{ item.lastName.charAt(0) }}</span>
        </div>
      </figure>
      <p class="info_text">{{ item.firstName }} {{ item.lastName }}</p>
      <div v-if="item.status === 'NEW'" class="tag">
        Invited
      </div>
    </div>
    <div class="email_info">
      <p class="mob_title">Email</p>
      <p class="info_text">{{ item.email }}</p>
    </div>
    <div class="phone_info">
      <p class="mob_title">Phone number</p>
      <p class="info_text">{{ item.phoneNumber }}</p>
    </div>
    <div class="menu">
      <div class="menu__button" @click="isShowMenu = true">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24" width="24">
          <circle cx="12" cy="18" r="1.8"/>
          <circle cx="12" cy="12" r="1.8"/>
          <path d="M12 4.3c-1 0-1.8.7-1.8 1.7S11 7.8 12 7.8 13.8 7 13.8 6 13 4.3 12 4.3z"/>
        </svg>
      </div>
      <div
        v-if="isShowMenu"
        class="menu__items"
        v-click-outside="() => isShowMenu = false"
      >
        <div v-if="item.status === 'ACTIVE'" class="menu__items__item" @click="isDelete = true">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 22 22" width="22">
            <path d="M15.6 20H6.4c-.9 0-1.7-.7-1.7-1.7V5.5c0-.4.3-.8.8-.8h11c.4 0 .8.3.8.8v12.8c-.1 1-.8 1.7-1.7 1.7zM6.2 6.3v12.1c0 .1.1.2.2.2h9.2c.1 0 .2-.1.2-.2V6.3H6.2z"/>
            <path d="M18.3 6.3H3.7c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h14.7c.4 0 .8.3.8.8s-.5.8-.9.8z" class="st0"/>
            <path d="M14.7 6.3H7.3c-.4 0-.8-.3-.8-.8V3.7c.1-1 .8-1.7 1.7-1.7h5.5c.9 0 1.7.7 1.7 1.7v1.8c0 .4-.3.8-.7.8zM8.1 4.8h5.8V3.7c0-.1-.1-.2-.2-.2H8.2c-.1 0-.2.1-.2.2v1.1z"/>
          </svg>
          Delete member
        </div>
        <template v-if="item.status === 'NEW'">
          <div class="menu__items__item" @click="copyInviteLink">
            <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 22 22" width="22">
              <path d="M14.7 11.8H7.3c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h7.3c.4 0 .8.3.8.8s-.3.8-.7.8z"/>
              <path d="M15.6 15.4h-1.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h1.8c1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.3-2.9-2.9-2.9h-1.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h1.8c2.4 0 4.4 2 4.4 4.4s-2 4.6-4.4 4.6zm-7.4 0H6.4C4 15.4 2 13.4 2 11s2-4.4 4.4-4.4h1.8c.5 0 .8.3.8.7s-.3.8-.8.8H6.4c-1.6 0-2.9 1.3-2.9 2.9 0 1.6 1.3 2.9 2.9 2.9h1.8c.4 0 .8.3.8.8s-.3.7-.8.7z"/>
            </svg>
            Copy invite link
          </div>
          <div class="menu__items__item" @click="teamStore.cancelInvitation(item._id)">
            <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 22 22" width="22">
              <path d="M16.5 17.3c-.2 0-.4-.1-.5-.2L5 6c-.3-.3-.3-.7 0-1s.7-.3 1 0l11 11c.3.3.3.8 0 1.1-.1.1-.3.2-.5.2z"/>
              <path d="M5.5 17.3c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L16 5c.3-.3.8-.3 1.1 0s.3.8 0 1.1L6 17c-.1.2-.3.3-.5.3z"/>
            </svg>
            Cancel invitation
          </div>
        </template>
      </div>
    </div>
    <modal-component :show="isDelete" type="error" @close="isDelete = false">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 33">
          <path d="M22.7 29.3H9.3c-1.1 0-2.1-.9-2.1-2.1V8.5c0-.4.3-.8.8-.8h16c.4 0 .8.3.8.8v18.7c0 1.1-1 2.1-2.1 2.1zM8.8 9.3v17.9c0 .3.3.6.6.6h13.3c.3 0 .6-.3.6-.6V9.3H8.8z"/>
          <path d="M26.7 9.3H5.3c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h21.3c.4 0 .8.3.8.8s-.3.8-.7.8z"/>
          <path d="M21.3 9.3H10.7c-.4 0-.8-.3-.8-.8V5.8c0-1.1.9-2.1 2.1-2.1h8c1.1 0 2.1.9 2.1 2.1v2.7c0 .4-.4.8-.8.8zm-9.9-1.5h9.2v-2c0-.3-.3-.6-.6-.6h-8c-.3 0-.6.3-.6.6v2z"/>
        </svg>
      </template>
      <template v-slot:title>Are you sure you want to delete your team member?</template>
      <template v-slot:description>By deleting them, you will keep all the designs and collections they created.</template>
      <template v-slot:buttons>
        <ButtonPrimary class="small one" style="width:fit-content" @click.native="deleteUser">Okay</ButtonPrimary>
      </template>
    </modal-component>
    <modal-component :show="globalError" type="error" @close="globalError = false">
      <template v-slot:title>Something went wrong, <br> try again</template>
    </modal-component>
    <modal-component :show="teamStore.cancelInvitationError" type="error" @close="teamStore.cancelInvitationError = false">
      <template v-slot:title>Something went wrong, <br> try again</template>
    </modal-component>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useTeamStore } from '@/store/pinia/userTeam'
import ModalComponent from '@/components/Atoms/ModalComponent'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'

export default {
  components: {
    ModalComponent,
    ButtonPrimary,
  },
  props: {
    item: {
      type: Object,
      requred: true,
    },
  },
  data: () => ({
    isShowMenu: false,
    isDelete: false,
    globalError: false,
  }),
  computed: {
    ...mapStores(useTeamStore),
  },
  methods: {
    async deleteUser () {
      try {
        this.globalError = false
        await this.teamStore.deleteUser(this.item._id)
        this.isDelete = false
      } catch (e) {
        this.isDelete = false
        this.globalError = true
      }
    },
    async copyInviteLink () {
      try {
        await this.teamStore.copyInviteLink(this.item._id)
        this.isShowMenu = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
