let print = {
  data: () => ({
    printMode: false,
  }),

  methods: {
    $print_afterPrint () {
      this.printMode = false
    },
    print () {
      if (!window?.print) {
        return
      }

      this.printMode = true
      window.addEventListener('afterprint', this.$print_afterPrint, { once: true })
      this.$nextTick(() => {
        // без паузы в отправленном на печать документе Не отображаются картинки
        setTimeout(() => { window.print() }, 200)
      })
    },
  },
}

export { print }
