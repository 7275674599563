import VueRouter from 'vue-router'
import Home from '@/pages/Home'
import Pricing from '@/pages/Pricing'
import RequestDemo from '@/pages/RequestDemo'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import TermsAndConditions from '@/pages/TermsAndConditions'
import PageDesigner from '@/pages/PageDesigner'
import PageMeasurements from '@/pages/PageMeasurements'
import PageSizeRun from '@/pages/PageSizeRun'
import LoginPage from '@/pages/Login'
import signUp from '@/pages/signUp'
import forgotPassword from '@/pages/forgotPassword'
import confirmEmail from '@/pages/confirmEmail'
import UserCollections from '@/pages/user/UserCollections'
import UserCollection from '@/pages/user/UserCollection'
import FitModels from '@/pages/user/FitModels'
import FitModelUser from '@/pages/user/FitModel'
import FitModelNewEdit from '@/pages/user/FitModelNewEdit'
import FitCharts from '@/pages/user/FitCharts'
import FitChart from '@/pages/user/FitChart'
import FitChartStandart from '@/pages/user/FitChartStandart'
import FitChartsNewEdit from '@/pages/user/FitChartsNewEdit'
import UserProfile from '@/pages/user/UserProfile.vue'
import billing from '@/pages/user/billing'
import layout from '@/pages/user/layout'
import UserTeam from '@/pages/user/UserTeam'
import JoinTeam from '@/pages/user/JoinTeam'
import AutoGrading from '@/pages/AutoGrading'
import TechPack from '@/pages/TechPack'
import EasePage from '@/pages/EasePage'
import NewDesign from '@/pages/NewDesign'
import Specs from '@/pages/Specs'
import FitModel from '@/pages/FitModel'
import NotFound from '@/pages/NotFound'

import { store } from '@/store'
import { getCurrentUser } from '@/api'
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Pricing,
    },
    {
      path: '/request-demo',
      name: 'demo',
      component: RequestDemo,
    },
    {
      path: '/privacy-policy',
      name: 'privacy',
      component: PrivacyPolicy,
    },
    {
      path: '/terms-and-conditions',
      name: 'terms',
      component: TermsAndConditions,
    },
    {
      path: '/designer/:designId',
      name: 'DataBaseView',
      component: PageDesigner,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/designer/:designId/m/:mannequinId',
      name: 'DataBaseView',
      component: PageDesigner,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/login',
      name: 'signIn',
      component: LoginPage,
    },
    {
      path: '/signup',
      name: 'signUp',
      component: signUp,
    },
    {
      path: '/confirm-email/:token',
      name: 'confirmEmail',
      component: confirmEmail,
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: forgotPassword,
    },
    {
      path: '/designer',
      component: PageDesigner,
      name: 'PageDesigner',
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/measurements',
      component: PageMeasurements,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/size-run',
      name: 'size-run',
      component: PageSizeRun,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/new-design',
      name: 'new-design',
      component: NewDesign,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/user',
      name: 'user',
      component: layout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'collections',
          name: 'userCollections',
          component: UserCollections,
        },
        {
          path: 'collections/:id',
          name: 'userCollection',
          component: UserCollection,
        },
        {
          path: 'fit-models',
          name: 'fitModels',
          component: FitModels,
        },
        {
          path: 'fit-models/:id',
          name: 'fitModelUser',
          component: FitModelUser,
        },
        {
          path: 'fit-model-new',
          name: 'fitModelNew',
          component: FitModelNewEdit,
        },
        {
          path: 'fit-model-edit/:id',
          name: 'fitModelEdit',
          component: FitModelNewEdit,
        },
        {
          path: 'fit-charts-custom',
          name: 'fitChartsCustom',
          component: FitCharts,
        },
        {
          path: 'fit-charts-standart',
          name: 'fitChartsStandart',
          component: FitCharts,
        },
        {
          path: 'fit-charts/:id',
          name: 'fitChart',
          component: FitChart,
        },
        {
          path: 'fit-charts-standart/:id',
          name: 'fitChartStandart',
          component: FitChartStandart,
        },
        {
          path: 'fit-charts-new',
          name: 'fitChartsNew',
          component: FitChartsNewEdit,
        },
        {
          path: 'fit-charts-new-standart',
          name: 'fitChartsNewStandart',
          component: FitChartsNewEdit,
        },
        {
          path: 'fit-charts-edit/:id',
          name: 'fitChartsEdit',
          component: FitChartsNewEdit,
        },
        {
          path: 'fit-charts-edit-standart/:id',
          name: 'fitChartsEditStandart',
          component: FitChartsNewEdit,
        },
        {
          path: 'profile',
          name: 'userProfile',
          component: UserProfile,
        },
        {
          path: 'profile/:action',
          name: 'userProfile',
          component: UserProfile,
        },
        {
          path: 'user-team',
          name: 'user_team',
          component: UserTeam,
        },
        {
          path: 'billing',
          name: 'user_billing',
          component: billing,
        },
      ],
    },
    {
      path: '/join-team/:token',
      name: 'join-team',
      component: JoinTeam,
    },
    {
      path: '/auto-grading',
      name: 'auto-grading',
      component: AutoGrading,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/tech-pack',
      name: 'tech-pack',
      component: TechPack,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/ease',
      name: 'ease-page',
      component: EasePage,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/specs',
      name: 'specs',
      component: Specs,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '/fit-model',
      name: 'FitModel',
      component: FitModel,
      meta: {
        requiresAuth: true,
        requiresDemo: true,
      },
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.isLoggedIn) {
      if (!store.state.user.info) {
        const userInfo = await getCurrentUser()
        store.dispatch('user/setUserInfo', userInfo)
      }
      const expiredDemoMode = store.getters['user/demoMode'] && store.getters['user/daysRemaining'] === 0
      if (expiredDemoMode && to.matched.some(record => record.meta.requiresDemo)) {
        next({ name: 'user_billing' })
        return
      } else {
        next()
        return
      }
    }
    next('/login')
  } else {
    next()
  }
})

export { router }
