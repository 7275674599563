<template>
  <div class="relative-position col column">
    <service-error-dialog
      v-if="hasLoadingErrors"
      @try-again="submit"
      @close="$emit('close')"
    />
    <service-error-dialog
      v-if="tapeWidthSmall"
      title="Service error"
      message="It's not possible to create a fabric layout for this tape width. Please change the fabric width."
      @try-again="showSelect"
      @close="$emit('close')"
    />
    <div
      v-if="loadingInProgress"
      class="FabricCalculatorBasic__loader col row items-center full-height q-py-md"
    >
      <q-spinner-dots
        class="col"
        color="primary"
        size="2em"
      />
    </div>

    <div
      v-else-if="isSelectDisplays"
      class="FabricCalculatorBasic__selector q-pa-md"
    >
      <q-select
        v-model="selectedWidth"
        :options="fabricWidthsOptions"
        :behavior="$q.screen.lt.sm ? 'dialog' : 'menu'"
        label="Fabric width"
        emit-value
        map-options
      />
    </div>

    <template v-else-if="isResultDisplays">
      <div v-if="resultFabricLengthInCm"
           class="col-shrink FabricCalculatorBasic__resultDescription q-py-md text-center"
      >
        {{ resultDescription }}
      </div>
      <div
        class="col FabricCalculatorBasic__result"
        v-html="result"
      ></div>
    </template>

  </div>

</template>

<script>
import ServiceErrorDialog from '@/components/Dialog/ServiceErrorDialog'
import { Measurement } from '@/helpers/measurement'
import { mapGetters, mapState } from 'vuex'
import { fetchFabricCalculatorResults } from '@/api'
import { basicWidths } from '@/components/Fabric/FabricWidths'
import { designOptionsValidator } from '@/pages/designerConstructor'

const AVAILABLE_VIEWS = Object.freeze({
  SELECT: 'select',
  RESULT: 'result',
})

export default {
  name: 'FabricCalculatorBasic',

  components: {
    ServiceErrorDialog,
  },

  props: {
    designOptions: {
      type: Object,
      required: true,
      validator: val => designOptionsValidator(val),
    },
  },

  data: () => ({
    AVAILABLE_VIEWS,
    currentView: 'select',
    selectedWidth: 88,
    fabricWidthsInCm: basicWidths(),
    result: undefined,
    resultFabricLengthInCm: undefined,
    loadingInProgress: false,
    hasLoadingErrors: false,
    tapeWidthSmall: false,
  }),

  computed: {
    isSelectDisplays () {
      return this.currentDisplayMode === 'select'
    },
    isResultDisplays () {
      return this.currentDisplayMode === 'result'
    },
    currentDisplayMode () {
      if (this.loadingInProgress) {
        return 'loading'
      }
      if (this.hasLoadingErrors) {
        return 'error'
      }
      if (this.currentView === this.AVAILABLE_VIEWS.SELECT || this.tapeWidthSmall) {
        return 'select'
      }
      if (this.currentView === this.AVAILABLE_VIEWS.RESULT) {
        return 'result'
      }
      return undefined
    },

    fabricWidthsOptions () {
      if (this.units === Measurement.CM) {
        return this.fabricWidthsInCm.map(item => ({
          value: item.value,
          label: `${item.value} cm`,
        }))
      } else {
        return this.fabricWidthsInCm.map(item => ({
          value: item.value,
          label: `${item.inchLabel}`,
        }))
      }
    },
    selectedWidthLabel () {
      return this.fabricWidthsOptions
        .find(item => item.value === this.selectedWidth)
        ?.label
    },
    resultDescription () {
      if (this.units === Measurement.CM) {
        return `Fabric yield: ${this.resultFabricLengthInCm / 100}m at ${this.selectedWidthLabel} width fabric`
      }
      if (this.units === Measurement.INCH) {
        // todo: вынести ярды в Measurement
        let inchesYield = new Measurement(this.resultFabricLengthInCm).getValue(Measurement.INCH)
        let yards = Math.round(inchesYield * 0.0278 * 100) / 100
        return `Fabric yield: ${yards}yd at ${this.selectedWidthLabel} width fabric`
      }
      return undefined
    },
    ...mapState([
      'units',
    ]),
    ...mapGetters(['getPatternsFontName']),
  },

  created () {
    this.showSelect()
    this.$emit('current-view', this.currentDisplayMode)
  },

  watch: {
    currentDisplayMode (val) {
      this.$emit('current-view', val)
    },
    selectedWidth (val) {
      this.$emit('selected-width', val)
    },
  },
  // watch: {
  //
  //   displayDialog (val) {
  //     if (val) {
  //       this.resetResult()
  //       this.showSelect()
  //     } else {
  //       this.currentView = this.AVAILABLE_VIEWS.DISABLED
  //     }
  //   },
  // },

  methods: {
    getPrintData () {
      return this.result
    },
    resetResult () {
      this.result = undefined
      this.resultFabricLengthInCm = undefined
      this.hasLoadingErrors = false
      this.loadingInProgress = false
      this.tapeWidthSmall = false
    },
    showSelect () {
      this.resetResult()
      this.currentView = AVAILABLE_VIEWS.SELECT
    },
    async submit () {
      this.resetResult()
      this.currentView = AVAILABLE_VIEWS.RESULT
      await this.loadFabric(this.selectedWidth)
    },
    async loadFabric (width) {
      this.loadingInProgress = true
      this.hasLoadingErrors = false
      this.tapeWidthSmall = false
      try {
        let { svg, fabricLengthInCm } = await fetchFabricCalculatorResults(
          {
            ...this.designOptions,
            fabricWidth: width,
            fontName: this.getPatternsFontName,
          },
        )
        this.result = svg
        this.resultFabricLengthInCm = fabricLengthInCm
      } catch (e) {
        if (e.message === 'tape width is small') {
          this.tapeWidthSmall = true
        } else this.hasLoadingErrors = true
      }
      this.loadingInProgress = false
    },
  },
}
</script>

<style lang="scss">
  .FabricCalculatorBasic {
    &__loader {
      position:         absolute;
      width:            100%;
      height:           100%;
      z-index:          250;
      background-color: rgba(255, 255, 255, 0.7);
    }

    &__resultDescription {
      font-size: 15px;
    }
  }
</style>
