<template>
  <div class="layout">
    <TheHeader :scrollY="0" />
    <div class="page">
      <slot></slot>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/Home/TheHeader.vue'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  data: () => ({
    isOpenMenu: false,
  }),
  components: {
    TheHeader,
    TheFooter,
  },
  methods: {
    openMenu () {
      this.isOpenMenu = !this.isOpenMenu
      const elem = document.getElementById("app")
      elem.classList.toggle("hiddenScroll")
    },
  },
}
</script>

<style lang="scss">
.layout {
  min-height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';

  .page {
    grid-area: main;
    background-color: #FAFBFB;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  footer.info-pages {
    grid-area: footer;
  }
}
@media (max-width: 480px) {
  .layout {
    .page {
      background-color: white;
    }
    .header .header-block {
      padding: 16px;
      .links {
        display: none;
      }
      .links.open {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 30px;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 2;
        padding: 50px 10px;
      }
      .mobile-menu__icon {
        display: block;
        z-index: 3;
      }
    }
  }
}
</style>
