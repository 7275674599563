import { Measurement } from '@/helpers/measurement'
import { getNearestValue } from '@/components/seamAllowance'

export default {
  SET_UNITS (state, units) {
    Measurement.checkUnit(units)
    state.units = units
  },
  SET_SEAM_ALLOWANCE (state, { val, selectNearest = true }) {
    if (selectNearest !== true || state.seamAllowanceOptions.includes(val)) {
      state.seamAllowance = val
      return
    }

    state.seamAllowance = getNearestValue(state.seamAllowanceOptions, val)
  },
  SET_SEAM_ALLOWANCE_OPTIONS (state, options) {
    state.seamAllowanceOptions = options
    if (options.includes(state.seamAllowance)) {
      return
    }
    if (options.length === 0) {
      return
    }
    // текущее значение недоступно в списке. Выберем наиболее близкое, но не 0. 0 выберем, если других значений нет.
    if (options.length === 1 && Number(options.slice(0, 1)[0]) === 0) {
      state.seamAllowance = 0
      return
    }
    // подбираем наиболее близкое значение из новых опций
    state.seamAllowance = getNearestValue(options, state.seamAllowance)
  },
  SET_FRACTION_BASE (state, base) {
    if (![8, 10].includes(base)) {
      throw Error('Invalid fraction base value')
    }
    state.fractionBase = base
  },
  SET_LOGO (state, logo) {
    state.logo = logo
  },
  SET_DESIGN_CATEGORY_PREFIXES (state, options) {
    state.designCategoryPrefixes = options
  },
  SET_MANNEQUIN_CATEGORIES (state, options) {
    state.mannequinCategories = options
  },
}
