<template>
  <div class="layout">
    <TheHeader :scrollY="0" class="account-header" />
    <activate-plan />
    <div class="page">
      <slot></slot>
    </div>
    <TheFooter class="account-footer" />
  </div>
</template>

<script>
import TheHeader from '@/components/Home/TheHeader.vue'
import activatePlan from '@/components/stripe/activatePlan'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  data: () => ({
    isOpenMenu: false,
  }),
  components: {
    TheHeader,
    activatePlan,
    TheFooter,
  },
  methods: {
    openMenu () {
      this.isOpenMenu = !this.isOpenMenu
      const elem = document.getElementById("app")
      elem.classList.toggle("hiddenScroll")
    },
  },
}
</script>

<style lang="scss">
.layout {
  min-height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    'header'
    'plan'
    'main'
    'footer';

  .account-header {
    grid-area: header;
    position: static;
  }

  .page {
    grid-area: main;
    background-color: #FAFBFB;
    position: relative;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer {
    grid-area: footer;
    padding: 24px 16px;
    font-size: 16px;
    .footer-block {
      max-width: 1440px;
      margin: auto;
      display: grid;
      grid-template-columns: auto 1fr auto;
      .lic {
        grid-column: 1 / 4;
        text-align: center;
        margin-top: 64px;
        margin-bottom: 0;
        font-size: 14px;
      }
      .mail-footer {
        display: flex;
        align-items: center;
      }
      .links-footer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a {
          margin-left: 16px;
          float: inline-end;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .layout {
    .page {
      background-color: white;
    }
    .footer {
      background-color: #FAFBFB;
      padding: 16px;
      font-size: 14px;
      .footer-block {
        grid-template-rows: 1fr 1fr;
        .links-footer {
          flex-direction: column;
          align-items: end;
          grid-row: 1 / 3;
          grid-column: 2/3;
          a {
            margin-bottom: 8px;
            text-align: right;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .lic {
          grid-column: 1 / 2;
          text-align: left;
          margin: 0;
          display: flex;
          align-items: end;
        }
      }
    }
  }
}
</style>
