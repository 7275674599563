<template>
  <div class="buttons-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonsGroup',
}
</script>

<style lang="scss">
  .buttons-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    button {
      width: 100%;
      height: 40px;
      padding: 10px 16px;
      border: 1px solid var(--Dividers);
      background: var(--Background);

      color: var(--Text-Text_Secondary);
      text-align: center;
      white-space: nowrap;

      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      transition: all ease-in-out 300ms;

      &:first-child {
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }

      &:hover {
        color: var(--Text-Text_Primary);
        border: 1px solid var(--Basic-Black);
      }

      &.active, &:active {
        color: var(--Active-State-Strokes);
        border: 1px solid var(--Active-State-Strokes);
      }

      &[disabled] {
        border: 1px solid var(--Dividers, #E0E9EA);
        color: var(--Button-States-Disabled_Txt);
      }
    }
  }
</style>
