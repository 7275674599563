<template>
    <label
      :class="['select-field', isFocused ? 'focused' : '']"
      ref="field"
      @click="focus()"
    >
      <span class="top-label" v-if="$slots['top-label']">
        <slot name="top-label" />
      </span>
      <div class="field">
        <input
          readonly
          v-bind="$attrs"
          v-model="label"
        />
        <div class="end icon" @click="focus()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path fill="#1d1f29" d="M13.334 7.584a.75.75 0 0 0-.531.219L10 10.605 7.197 7.803a.75.75 0 0 0-1.06 0 .75.75 0 0 0 0 1.06l3.332 3.334a.75.75 0 0 0 1.06 0l3.334-3.334a.75.75 0 0 0 0-1.06.75.75 0 0 0-.529-.219z" color="#000" style="-inkscape-stroke:none"/>
          </svg>
        </div>
      </div>
      <div
        v-if="isFocused"
        :class="['dropdown', dropPosition]"
      >
        <div
          v-for="option in options"
          :key="option.title"
          class="option"
          @click.stop="setOption(option)"
        >
          {{ option.title }}
        </div>
      </div>
      <span class="bottom-label" v-if="$slots['bottom-label']">
        <slot name="bottom-label" />
      </span>
    </label>
</template>

<script>
export default {
  name: 'SelectField',
  props: ['value', 'options'],
  data: () => ({
    val: '',
    label: '',
    dropPosition: 'bottom',
    isFocused: false,
  }),
  watch: {
    value (val) {
      this.val = this.value
      this.setLabel(this.val)
    },
  },
  methods: {
    focus () {
      const elRect = this.$refs.field.getBoundingClientRect()
      const elPosition = window.innerHeight - elRect.bottom
      this.dropPosition = elPosition <= 262 ? 'top' : 'bottom'
      this.isFocused = !this.isFocused
      this.$emit('focus')
    },
    setOption (option) {
      this.val = option.value === undefined ? option.title : option.value
      this.setLabel(this.val)
      this.$emit('change', this.val)
    },
    setLabel (val) {
      const selected = this.options.find(el => {
        let option = el.value === undefined ? el.title : el.value
        option = option.toString()
        return option === val.toString()
      })
      this.label = selected?.title === undefined ? selected?.value : selected?.title
    },
    clickOutside (e) {
      const isChild = this.$refs.field.contains(e.target)
      if (!isChild) this.isFocused = false
    },
  },
  beforeMount () {
    this.val = this.value
    this.setLabel(this.val)
    document.addEventListener('click', this.clickOutside)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickOutside)
  },
}
</script>

<style lang="scss">
.select-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  color: var(--Text-Text_Secondary);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  position: relative;
  cursor: pointer;

  & * {
    transition: all ease-in-out 300ms;
  }

  .dropdown {
    left: 0;
    position: absolute;
    width: 100%;
    min-width: 120px;
    height: fit-content;
    max-height: 245px;
    overflow-y: auto;
    z-index: 2;

    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: var(--Basic-White);
    box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.10);

    &.top {
      bottom: 48px;
    }
    &.bottom {
      top: 72px;
    }

    .option {
      padding: 8px 16px;
      color: var(--Text-Text_Primary, #1D1F29);

      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      cursor: pointer;

      &:hover {
        background: var(--Surface-Hover);
        color: var(--Button-States-Hover);
      }
    }
  }

  .field {
    position: relative;

    .icon {
      position: absolute;
      z-index: 1;
      width: 20px;
      height: 20px;
      top: 10px;

      &.start {
        left: 12px;
      }
      &.end {
        right: 12px;
      }

      svg {
        display: block;
        margin: 0;
        width: 100%;
        height: 100%;
        fill: var(--Icons-Icons_Black);
      }
    }
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: var(--Background);
    padding: 10px 12px;
    outline: none;

    padding-right: 40px;

    color: var(--Text-Text_Primary);

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    user-select: none;
    cursor: pointer;

    &::placeholder {
      color: var(--Text-Text_Secondary);
    }

    &::-moz-selection {
      color: var(--Text-Text_Primary);
      background: transparent;
    }

    &::selection {
      color: var(--Text-Text_Primary);
      background: transparent;
    }
  }

  &:hover {
    color: var(--Button-States-Hover);
    input { border: 1px solid var(--Button-States-Hover); }
    .icon svg { fill: var(--Button-States-Hover); }
  }

  &[disabled] {
    opacity: 1 !important;
    color: var(--Button-States-Disabled_Txt);
    .icon svg {
      fill: var(--Button-States-Disabled_Txt);
    }
    input {
      opacity: 1 !important;
      color: var(--Button-States-Disabled_Txt);
      border: 1px solid var(--Dividers);
    }
  }

  &.focused {
    color: var(--Basic-Black);
    .icon svg { fill: var(--Basic-Black); }
    input { border: 1px solid var(--Basic-Black); }
    .end.icon {
      transform: scaleY(-1);
    }
  }

  &.errored {
    color: var(--System-Error);
    input { border: 1px solid var(--System-Error); }
    .icon svg { fill: var(--System-Error); }
  }
}
</style>
