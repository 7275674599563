<template>
  <div class="not-found">
    <TheHeader :scrollY="0" />
    <section>
      <figure>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="1857" height="1563" viewBox="0 0 1857 1563">
          <path stroke="#13606a" stroke-dasharray="15, 15" stroke-width="3" d="M138.84 1492.191H0l135.16-163.833L1302.088 0M138.84 1492.191v-66.394m0 66.394v71.633m0-71.633h301.753m135.159-163.832-135.159 163.832h138.84m1277.703 0H579.433m0 0v-66.393m0 66.393v71.633"/>
          <path stroke="#13606a" stroke-dasharray="15, 15" stroke-width="3" d="M399.164 1444.263a90.189 118.217 0 0 1-90.189 118.218 90.189 118.217 0 0 1-90.189-118.218 90.189 118.217 0 0 1 90.19-118.217 90.189 118.217 0 0 1 90.187 118.217z"/>
        </svg>
      </figure>
      <h1>Oh no! Page not found</h1>
      <ButtonPrimary @click.native="$router.push('/')">Go to Homepage</ButtonPrimary>
    </section>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/Home/TheHeader.vue'
import TheFooter from '@/components/Home/TheFooter.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'

export default {
  name: 'notFound',
  components: {
    TheHeader,
    TheFooter,
    ButtonPrimary,
  },
}
</script>

<style lang="scss">
  .not-found {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    header {
      position: static;
    }

    section {
      min-height: 420px;
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      figure {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
        max-height: 240px;

        svg {
          position: absolute;
          bottom: 0;
          left: calc(50% - 320px);
          display: block;
        }
      }

      h1 {
        color: var(--Text-Text_Primary, #1D1F29);

        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin: 44px 0 16px 0;
      }
    }
  }
  @media (max-width: 760px) {
    .not-found {
      section {
        min-height: 280px;
        figure {
          svg {
            width: 150%;
            height: auto;
            left: 25%;
          }
        }
        h1 {
          margin: 40px 0 16px 0;
        }
        button {
          flex-shrink: 0;
          margin-bottom: 40px;
        }
      }
    }
  }
</style>
