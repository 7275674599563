<template>
  <div id="app" :class="{'overflow-hidden': !initialDataLoaded}">
    <transition name="fade">
      <page-loader v-if="!initialDataLoaded"/>
    </transition>

    <router-view
      :class="{'print-hide': isPrintMode}"
      @error="displayAlert($event.title, $event.message)"
    />

    <portal-target
      name="print"
      @change="handlePrint"
    ></portal-target>
    <alert-dialog ref="alert"/>
    <ajax-loading/>
  </div>
</template>

<script>
import AlertDialog from '@/components/Alert/AlertDialog'
import PageLoader from '@/components/Loaders/PageLoader'
import AjaxLoading from '@/components/AjaxLoading'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    AjaxLoading,
    PageLoader,
    AlertDialog,
  },

  head: {
    link () {
      let links = []
      links.push({
        id: 'customStylesheet',
        rel: 'stylesheet',
        href: '/applications-assets/ditto/styles.css',
      })
      return links
    },
    script: [
      { type: 'text/javascript', src: 'https://js.stripe.com/v3/', async: true },
    ],
  },

  computed: {
    ...mapState(['isLoggedIn']),
  },

  created () {
    this.$q.screen.setSizes({
      sm: 450,
      md: 800,
      lg: 964,
      xl: 1200,
    })
  },

  data: () => ({
    initialDataLoaded: true,
    isPrintMode: false,
    beforePrintValues: undefined,
  }),

  // watch: {
  //   isLoggedIn (val) {
  //     if (!val) this.$router.push('/login')
  //   },
  // },

  methods: {
    displayAlert (title, message) {
      if (message === '401') this.$router.push('/login')
      else this.$refs.alert.addMessage(title, message)
    },
    handlePrint (state) {
      this.isPrintMode = state
      const body = window.document.querySelector('body')
      if (state && !this.beforePrintValues) {
        this.beforePrintValues = {
          bodyPosition: body.style.getPropertyValue('position'),
          bodyPositionPriority: body.style.getPropertyPriority('position'),
        }
        body.style.setProperty('position', 'static', 'important')
      }

      if (!!this.beforePrintValues && !state) {
        if (this.beforePrintValues?.bodyPosition && this.beforePrintValues?.bodyPositionPriority) {
          body.style.setProperty(
            'position',
            this.beforePrintValues.bodyPosition,
            this.beforePrintValues.bodyPositionPriority,
          )
        } else {
          body.style.removeProperty('position')
        }
        this.beforePrintValues = undefined
      }
    },
  },
}
</script>
