<template>
  <div class="color-ways">
    <button class="close" @click="close()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <path d="M5 4.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L8.937 10 4.47 14.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.062 0L10 11.062l4.469 4.47a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.063L11.062 10l4.47-4.469a.75.75 0 0 0 0-1.062A.75.75 0 0 0 15 4.25a.75.75 0 0 0-.531.219L10 8.937 5.531 4.47A.75.75 0 0 0 5 4.25z"></path>
      </svg>
      <span>Exit</span>
    </button>
    <section class="step s1" v-if="ColorsStore.colorwaysStep === 1">
      <h2>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M5.994 2.008a1.205 1.205 0 0 0-1.105.88L1.875 14.136a3.678 3.678 0 0 0 2.594 4.49c.307.082.617.12.922.121a.75.75 0 0 0 .025.004h11.643c.649 0 1.191-.542 1.191-1.191v-4.952a.75.75 0 0 0-.049-.261c-.006-.15-.041-.3-.107-.442l-2.092-4.488a1.188 1.188 0 0 0-.674-.611 1.185 1.185 0 0 0-.908.035l-3.426 1.598.979-3.65a1.203 1.203 0 0 0-.842-1.46L6.348 2.047a1.166 1.166 0 0 0-.354-.04zm.264 1.568 4.185 1.121L7.51 15.645a2.153 2.153 0 0 1-1.102 1.367l-.074.035a.75.75 0 0 0-.016.012 2.144 2.144 0 0 1-.81.191h-.092a2.2 2.2 0 0 1-.559-.074 2.155 2.155 0 0 1-1.533-2.653L6.258 3.576zm8.515 4.754 1.83 3.928-7.58 3.535 1.465-5.465 4.285-1.998zm1.977 5.514v3.406H9.447l7.303-3.406zm-11.332.572a.751.751 0 0 0-.752.75.751.751 0 0 0 .752.752.751.751 0 0 0 .75-.752.751.751 0 0 0-.75-.75z"/>
        </svg>
        <span>Colorways</span>
      </h2>
      <h1>Choose the colors you would like to see in the design</h1>
      <p>Don't know which color will best match your design? Choose up <span>to 10 colors</span> and we'll show you how your design will look with each of them.</p>
      <InputField placeholder="Search TCX, sRGB, HEX, LAB..." v-model="ColorsStore.search" @input="ColorsStore.doSearch" class="search-field">
        <template v-slot:start-icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21" class="search-icon">
            <path d="M8.334 2.25c-3.627 0-6.584 2.957-6.584 6.584s2.957 6.582 6.584 6.582a6.547 6.547 0 0 0 4.088-1.432l4.547 4.547a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.062l-4.547-4.547a6.547 6.547 0 0 0 1.432-4.088c0-3.627-2.955-6.584-6.582-6.584zm0 1.5a5.071 5.071 0 0 1 5.082 5.084 5.07 5.07 0 0 1-5.082 5.082A5.071 5.071 0 0 1 3.25 8.834 5.073 5.073 0 0 1 8.334 3.75z"/>
          </svg>
        </template>
      </InputField>
      <div class="pallete">
        <div :class="['one-color', { selected: ColorsStore.isSelected(color.HEX) }]" v-for="color in ColorsStore.res" :key="color.id" @click="ColorsStore.setUnsetPalette(color.HEX)">
          <div class="example" :style="{ backgroundColor: color.HEX }"></div>
          <h6>{{ color.LAB }}</h6>
          <h5>{{ color.HEX }}</h5>
        </div>
      </div>
      <div class="bottom-panel">
        <h3>Color palette</h3>
        <div class="selected-colors">
          <template v-for="(color, index) in ColorsStore.palette">
            <div v-if="color === undefined" class="color empty" :key="'e' + index"></div>
            <div v-else class="color filled" :style="{ backgroundColor: color }" :key="'f' + index">
              <button @click="ColorsStore.setUnsetPalette(color)">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 10H15" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </template>
        </div>
        <div class="buttons">
          <button class="reset" @click="ColorsStore.resetPalette()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path d="M4.166 1.75a.75.75 0 0 0-.75.75v4.166a.75.75 0 0 0 .75.75h4.168a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H5.723a5.909 5.909 0 0 1 6.134-1.533 5.918 5.918 0 0 1-.236 11.308 5.914 5.914 0 0 1-6.584-2.468A.75.75 0 0 0 4 13.002a.75.75 0 0 0-.22 1.039 7.417 7.417 0 1 0 1.136-9.44V2.5a.75.75 0 0 0-.75-.75z"/>
            </svg>
            Reset all
          </button>
          <ButtonPrimary
            :disabled="!ColorsStore.palletteNotEmpty"
            class="apply"
            @click.native="ColorsStore.colorwaysStep = 2"
          >
            Apply
          </ButtonPrimary>
        </div>
      </div>
    </section>
    <section class="step s2" v-if="ColorsStore.colorwaysStep === 2">
      <h2>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M5.994 2.008a1.205 1.205 0 0 0-1.105.88L1.875 14.136a3.678 3.678 0 0 0 2.594 4.49c.307.082.617.12.922.121a.75.75 0 0 0 .025.004h11.643c.649 0 1.191-.542 1.191-1.191v-4.952a.75.75 0 0 0-.049-.261c-.006-.15-.041-.3-.107-.442l-2.092-4.488a1.188 1.188 0 0 0-.674-.611 1.185 1.185 0 0 0-.908.035l-3.426 1.598.979-3.65a1.203 1.203 0 0 0-.842-1.46L6.348 2.047a1.166 1.166 0 0 0-.354-.04zm.264 1.568 4.185 1.121L7.51 15.645a2.153 2.153 0 0 1-1.102 1.367l-.074.035a.75.75 0 0 0-.016.012 2.144 2.144 0 0 1-.81.191h-.092a2.2 2.2 0 0 1-.559-.074 2.155 2.155 0 0 1-1.533-2.653L6.258 3.576zm8.515 4.754 1.83 3.928-7.58 3.535 1.465-5.465 4.285-1.998zm1.977 5.514v3.406H9.447l7.303-3.406zm-11.332.572a.751.751 0 0 0-.752.75.751.751 0 0 0 .752.752.751.751 0 0 0 .75-.752.751.751 0 0 0-.75-.75z"/>
        </svg>
        <span>Colorways</span>
      </h2>
      <h1>Your designs are ready!</h1>
      <p>Choose one of the colors to continue editing your design</p>
      <div class="cards">
        <div
          v-for="(s, index) in sketches"
          :key="s.color"
          class="one-card"
          :class="{ selected: selected.index === index }"
          @click="selectUnselect(s, index)"
        >
          <div class="top-panel">
            <h3>Design {{ index + 1 }}</h3>
            <button class="edit-menu-button" @click.stop="showHideMenu(index)" v-if="!savedColors.includes(index)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 25 24">
                <path d="M12.4 4.25c-.957 0-1.75.792-1.75 1.75s.793 1.75 1.75 1.75c.958 0 1.75-.792 1.75-1.75s-.792-1.75-1.75-1.75zm0 6c-.957 0-1.75.792-1.75 1.75s.793 1.75 1.75 1.75c.958 0 1.75-.792 1.75-1.75s-.792-1.75-1.75-1.75zm0 6c-.957 0-1.75.792-1.75 1.75s.793 1.75 1.75 1.75c.958 0 1.75-.792 1.75-1.75s-.792-1.75-1.75-1.75z"/>
              </svg>
              <ul class="dropdown" ref="menu" v-if="submenu === index" v-click-outside="() => submenu = -1">
                <li @click.stop="continueEditing(s.color)">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
                    <path d="M14.666 3.3c-.428 0-.855.163-1.178.485l-3.011 3.012a.75.75 0 0 0-.008.006.75.75 0 0 0-.006.008l-7.326 7.326a.75.75 0 0 0-.221.529v3.668a.75.75 0 0 0 .75.75h3.668a.75.75 0 0 0 .53-.22l7.333-7.335 3.018-3.017a1.679 1.679 0 0 0 0-2.358l-2.37-2.369a1.665 1.665 0 0 0-1.179-.484zm.002 1.493c.041 0 .081.017.117.053l2.37 2.369c.07.071.07.165 0 .236L14.667 9.94l-2.607-2.607 2.488-2.486a.168.168 0 0 1 .119-.053zM11 8.395 13.607 11l-6.584 6.584H4.416v-2.607L11 8.395z" color="#000" style="-inkscape-stroke:none"/>
                  </svg>
                  <span>Edit mode</span>
                </li>
                <li @click.stop="save(s.color, index)">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 22 22">
                    <path d="M 6.4160156,2.9160156 C 5.5044312,2.9160156 4.75,3.670437 4.75,4.5820312 V 18.453125 c 0,1.289379 1.5189539,2.101924 2.5917969,1.386719 l 3.5664061,-2.376953 c 0.05771,-0.03846 0.125883,-0.03846 0.183594,0 l 3.566406,2.376953 C 15.731019,20.555113 17.25,19.742525 17.25,18.453125 V 4.5820312 c 0,-0.9115844 -0.754423,-1.6660156 -1.666016,-1.6660156 z m 0,1.5 h 9.1679684 c 0.101007,0 0.166016,0.065081 0.166016,0.1660156 V 18.453125 c 0,0.174799 -0.114383,0.235602 -0.259766,0.138672 l -3.566406,-2.376953 c -0.558088,-0.371938 -1.289568,-0.371938 -1.847656,0 L 6.5097656,18.591797 C 6.3642698,18.688791 6.25,18.627944 6.25,18.453125 V 4.5820312 c 0,-0.1009246 0.065081,-0.1660156 0.1660156,-0.1660156 z"/>
                  </svg>
                  <span>Save</span>
                </li>
              </ul>
            </button>
            <div v-else class="saved">Saved</div>
          </div>
          <div class="sketch" v-html="s.sketch" />
        </div>
      </div>
      <div class="bottom-panel">
        <div class="buttons">
          <ButtonSecondary
            @click.native="ColorsStore.colorwaysStep = 1"
          >
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path d="M9.166 4.25a.75.75 0 0 0-.53.219l-5 5a.75.75 0 0 0 0 1.062l5 5a.75.75 0 0 0 1.061 0 .75.75 0 0 0 0-1.062L5.98 10.75h9.855a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75H5.979l3.718-3.719a.75.75 0 0 0 0-1.062.75.75 0 0 0-.531-.219z"/>
              </svg>
            </template>
            Back to colors
          </ButtonSecondary>
          <ButtonPrimary
            :disabled="selected.index === undefined"
            @click.native="continueEditing()"
          >
            Continue editing
          </ButtonPrimary>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InputField from '../Atoms/InputField.vue'
import ButtonPrimary from '../Atoms/ButtonPrimary.vue'
import ButtonSecondary from '../Atoms/ButtonSecondary.vue'
import { mapStores } from 'pinia'
import { useColorsStore } from '../../store/pinia/Colors'

export default {
  name: 'ColorWays',
  components: {
    InputField,
    ButtonPrimary,
    ButtonSecondary,
  },
  props: {
    sketchSides: {
      type: Object,
      default: () => ({}),
    },
    savedColors: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    selected: {},
    submenu: -1,
  }),
  computed: {
    ...mapStores(useColorsStore),
    sketches () {
      const colors = this.ColorsStore.palette.filter(c => c !== undefined)
      const sketches = colors.map(c => {
        const color = this.sketchSides.both
          .split('data-fill-color="')[1]
          .split('"')[0]

        return {
          color: c,
          sketch: this.sketchSides.both.replaceAll(color, c),
        }
      })
      return sketches
    },
  },
  methods: {
    close () {
      const app = document.querySelector('#app')
      app.classList.remove('overflow-hidden')

      this.ColorsStore.$reset()
      this.$emit('close')
    },
    selectUnselect (s, index) {
      if (this.selected.color === s.color) {
        this.selected = {}
      } else {
        this.selected = {
          color: s.color,
          index,
        }
      }
    },
    showHideMenu (index) {
      this.submenu = this.submenu === index ? -1 : index
    },
    continueEditing (c) {
      const color = c || this.selected.color
      this.$emit('set-color', color)
      this.close()
    },
    save (color, index) {
      this.$emit('set-color', color)
      this.$emit('save-design', index)
    },
  },
  async beforeMount () {
    await this.ColorsStore.getColors()
    this.menus = this.ColorsStore.palette.map(() => false)
  },
}
</script>

<style lang="scss">
.color-ways {
  background: var(--Background);
  position: fixed;
  left: 0;
  top: 66px;
  width: 100%;
  height: calc(100% - 66px);
  z-index: 1;

  .close {
    position: absolute;
    top: 24px;
    left: 16px;
    background: transparent;
    border: none;

    display: flex;
    align-items: center;
    column-gap: 4px;

    color: var(--Primary-Shades-Primary);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--Primary-Shades-Primary);
    }
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    height: 100%;

    h2 {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-bottom: 16px;

      color: var(--Text-Text_Primary, #1D1F29);
      text-align: center;

      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      svg {
        width: 20px;
        height: 20px;
        display: block;
        margin: 0;
        fill: var(--Icons-Icon_Primary);
      }
    }

    h1 {
      color: var(--Text-Text_Primary);
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      max-width: 442px;
      margin: 0;
      margin-bottom: 8px;
    }

    p {
      color: var(--Text-Text_Secondary);
      text-align: center;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      max-width: 442px;
      margin: 0;
      margin-bottom: 32px;

      span {
        color: var(--Text-Text_Primary);
      }
    }

    .search-field {
      max-width: 442px;
      margin-bottom: 40px;
    }

    .pallete {
      flex-grow: 1;
      overflow-y: auto;
      padding: 0 12px 40px 12px;

      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .one-color {
        width: 98px;
        height: 116px;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 4px;
        border: 1px solid var(--Dividers);
        background: var(--Background);

        text-align: center;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;

        cursor: pointer;

        &.selected {
          border-color: var(--Active-State-Strokes);
          h5 {
            color: var(--Active-State-Strokes);
          }
        }

        .example {
          aspect-ratio: 90 / 75;
          width: 100%;
          height: auto;
          border-radius: 8px;
          margin-bottom: 4px;
        }

        h6 {
          color: var(--Text-Text_Secondary);
          font-size: 8px;
          text-transform: uppercase;
          line-height: normal;
          margin-bottom: 4px;
        }
        h5 {
          color: var(--Text-Text_Primary);
          font-size: 10px;
          line-height: normal;
        }
      }
    }

    .bottom-panel {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 16px;
      border-top: 1px solid var(--Dividers);

      h3 {
        color: var(--Text-Text_Primary);

        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin-right: 110px;
      }

      .selected-colors {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;
        flex-grow: 1;

        .color {
          width: 48px;
          height: 48px;
          border-radius: 8px;
          position: relative;

          &.empty {
            border: 1px dashed var(--Dividers);
            background: var(--Surface);
          }
          &.filled {
            button {
              position: absolute;
              width: 24px;
              height: 24px;
              top: -8px;
              right: -8px;

              border-radius: 4px;
              border: 1px solid var(--Secondary-Button-Stroke);
              background: var(--Basic-White);

              svg {
                height: 100%;
                margin: 0 auto;
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 24px;

        .reset {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 4px;
          background: transparent;
          border: none;

          color: var(--Primary-Shades-Primary);
          text-align: center;
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          svg {
            width: 20px;
            height: 20px;
            fill: var(--Icons-Icon_Primary);
          }
        }

        .apply {
          width: 200px;
        }
      }
    }
    .cards {
      width: 100%;
      max-width: 1165px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto auto 1fr;
      gap: 40px;
      flex-grow: 1;
      padding-bottom: 40px;

      .one-card {
        width: 200px;
        height: 240px;
        padding: 8px;
        border-radius: 12px;
        border: 1px solid var(--Dividers);
        background: var(--Background);

        display: flex;
        flex-direction: column;
        gap: 8px;
        cursor: pointer;

        transition: all ease-in-out 250ms;

        &.selected {
          border: 1px solid var(--Active-State-Strokes) !important;
        }

        &:hover {
          border: 1px solid var(--Button-States-Hover);

          .top-panel {
            button {
              opacity: 1;
            }
          }
        }

        .top-panel {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid var(--Dividers);
          padding: 4px 0 8px 0;

          h3 {
            color: var(--Text-Text_Primary);
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }

          button {
            border: none;
            background: transparent;
            opacity: 0;
            transition: all ease-in-out 250ms;
            & > svg {
              width: 24px;
              height: 24px;
              fill: var(--Button-States-Hover);
            }
          }
          .dropdown {
            width: 200px;
            position: absolute;
            top: 35px;
            right: 9px;
            z-index: 1;

            margin: 0;
            padding: 0;

            border-radius: 8px;
            border: 1px solid var(--Dividers);
            background: var(--Basic-White);
            box-shadow: 0 6px 8px 0 rgba(146, 158, 159, 0.10);

            & * { transition: all ease-in-out 300ms; }

            li {
              list-style: none;
              padding: 8px 16px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;

              &:hover {
                background: var(--Surface-Hover);

                svg {
                  fill: var(--Button-States-Hover);
                }
                span {
                  color: var(--Button-States-Hover);
                }
              }
            }

            svg {
              width: 22px;
              height: 22px;
              margin-right: 8px;
              fill: var(--Icons-Icon_Primary);
            }

            span {
              color: var(--Text-Text_Primary);
              font-family: "Open Sans";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }
          }
          .saved {
            color: var(--Button-States-Disabled_Txt);
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
        .sketch {
          flex-grow: 1;
          overflow: hidden;
          svg {
            width: 100%;
            height: 100% !important;
            display: block;
            margin: 0;
          }
        }
      }
    }

    &.s2 {
      .bottom-panel {
        justify-content: center;
        .buttons {
          column-gap: 16px;
          .btn {
            width: 213px;
          }
        }
      }
    }
  }
}
@media (max-width: 760px) {
    .color-ways {
      section {
        padding-top: 74px;

        h2 {
          margin-bottom: 10px;
        }

        h1 {
          color: var(--Text-Text_Primary);
          font-size: 18px;
          line-height: normal;
          padding: 0 16px;
        }

        p {
          margin-bottom: 24px;
          padding: 0 16px;
        }

        .bottom-panel {
          flex-direction: column;

          h3 {
            display: none;
          }
          .selected-colors {
            width: 100%;
            overflow-x: scroll;
            margin-bottom: 16px;

            .color {
              flex-shrink: 0;
            }
          }
          .buttons {
            width: 100%;
            justify-content: space-between;

            .apply {
              width: 163px;
            }
          }
        }
        .cards {
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          padding-left: 16px;
          padding-right: 16px;
          height: auto;
          overflow-y: auto;

          .one-card {
            width: auto;
            .top-panel {
              button {
                opacity: 1;
              }
            }
          }
        }

        &.s2 {
          .bottom-panel {
            .buttons {
              .btn {
                width: 100%;
                font-weight: 400 !important;
              }
            }
          }
        }
      }
    }
  }
</style>
