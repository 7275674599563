const student = {
  title: 'Student Early Adopter',
  description: 'Individual plan limited to 1',
  price: 49,
  oldPrice: 99,
  code: 'STUDENT_EA',
  index: 0,
  scope: [
    { text: 'Access to billions of 2D flats design templates', isAvailable: true },
    { text: 'Access to billions of smart-fit pattern blocks', isAvailable: true },
    { text: 'Unlimited patterns + sketches downloads of unique design configurations', isAvailable: true },
    { text: 'Unlimited PDF/PLT/DXF-AAMA downloads', isAvailable: true },
    { text: 'Smart Adjustments', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'Unlimited Custom FitModels', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'Single size Marker layout', isAvailable: true },
    { text: 'Standard Technical Support', isAvailable: true },
    { text: 'Automated Standard Size Grading', isAvailable: false },
    { text: 'Unlimited Tech Packs with all size runs', isAvailable: false },
    { text: 'Custom Fit Charts', isAvailable: false },
  ],
}

const indie = {
  title: 'Indie Early Adopter',
  description: 'Individual plan limited to 1',
  price: 79,
  oldPrice: 199,
  code: 'INDIE_EA',
  index: 1,
  scope: [
    { text: 'Access to billions of 2D flats design templates', isAvailable: true },
    { text: 'Access to billions of smart-fit pattern blocks', isAvailable: true },
    { text: 'Unlimited patterns + sketches downloads of unique design configurations', isAvailable: true },
    { text: 'Unlimited PDF/PLT/DXF-AAMA downloads', isAvailable: true },
    { text: 'Smart Adjustments', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'Unlimited Custom FitModels', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'Single size Marker layout', isAvailable: true },
    { text: 'Standard Technical Support', isAvailable: true },
    { text: 'Automated Standard Size Grading', isAvailable: true },
    { text: 'Unlimited Tech Packs with all size runs', isAvailable: true },
    { text: 'Custom Fit Charts', isAvailable: false },
  ],
}

const fashionStartup = {
  title: 'Startup Early Adopter',
  description: 'B2B Plan for teams with up to 3 seats',
  price: 199,
  oldPrice: 799,
  code: 'STARTUP_EA',
  index: 2,
  scope: [
    { text: 'Access to billions of 2D flats design templates', isAvailable: true },
    { text: 'Access to billions of smart-fit pattern blocks', isAvailable: true },
    { text: 'Unlimited patterns + sketches downloads of unique design configurations', isAvailable: true },
    { text: 'Unlimited PDF/PLT/DXF-AAMA downloads', isAvailable: true },
    { text: 'Smart Adjustments', isAvailable: true },
    { text: 'Unlimited Custom FitModels', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'Automated Standard Size Grading', isAvailable: true },
    { text: 'Unlimited Tech Packs with all size runs', isAvailable: true },
    { text: 'Single size Marker layout', isAvailable: true },
    { text: 'Standard Technical Support', isAvailable: true },
    { text: 'Custom Fit Charts', isAvailable: true },
  ],
}

const manufacturer = {
  title: 'Manufacturer Early Adopter',
  description: 'B2B Plan for teams with up to 5 seats',
  price: 799,
  oldPrice: 1799,
  code: 'GROWING_EA',
  index: 3,
  scope: [
    { text: 'Access to billions of 2D flats design templates', isAvailable: true },
    { text: 'Access to billions of smart-fit pattern blocks', isAvailable: true },
    { text: 'Unlimited patterns + sketches downloads of unique design configurations', isAvailable: true },
    { text: 'Unlimited PDF/PLT/DXF-AAMA downloads', isAvailable: true },
    { text: 'Smart Adjustments', isAvailable: true },
    { text: 'Unlimited Custom FitModels', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'Automated Standard Size Grading', isAvailable: true },
    { text: 'Unlimited Tech Packs with all size runs', isAvailable: true },
    { text: 'Single size Marker layout', isAvailable: true },
    { text: 'Priority Technical Support', isAvailable: true },
    { text: 'Custom Fit Charts', isAvailable: true },
  ],
}

const enterprise = {
  title: 'Enterprise / Custom',
  description: 'Large-scale Organization Plan',
  price: -1,
  code: '',
  index: 4,
  scope: [
    { text: 'Contact us for a personalized solution!<br>Our team will work with you to create a custom plan that fits your unique business needs.' },
  ],
}

const starter = {
  title: 'MTM Starter',
  description: 'B2B plan for small startup',
  price: 199,
  code: 'INDIE_MTM',
  index: 5,
  scope: [
    { text: 'Access to billions of 2D flats design templates', isAvailable: true },
    { text: 'Access to billions of smart-fit pattern blocks', isAvailable: true },
    { text: 'Unlimited patterns + sketches downloads of unique design configurations', isAvailable: true },
    { text: 'Unlimited PDF/PLT/DXF-AAMA downloads', isAvailable: true },
    { text: 'Smart Adjustments', isAvailable: true },
    { text: 'Unlimited Custom FitModels', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'MTM Pattern Customization', isAvailable: true },
    { text: 'Unlimited single size Tech Packs', isAvailable: true },
    { text: 'Single size Marker layout', isAvailable: true },
    { text: 'Standard Technical Support', isAvailable: true },
    { text: 'MTM Order processing dashboard*', isAvailable: false },
    { text: 'API Scan to MTM Pattern Customization', isAvailable: false },
  ],
}

const pro = {
  title: 'MTM Pro',
  description: 'B2B plan for medium operating business',
  price: 1799,
  code: 'GROWING_MTM',
  index: 6,
  scope: [
    { text: 'Access to billions of 2D flats design templates', isAvailable: true },
    { text: 'Access to billions of smart-fit pattern blocks', isAvailable: true },
    { text: 'Unlimited patterns + sketches downloads of unique design configurations', isAvailable: true },
    { text: 'Unlimited PDF/PLT/DXF-AAMA downloads', isAvailable: true },
    { text: 'Smart Adjustments', isAvailable: true },
    { text: 'Unlimited Custom FitModels', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'MTM Pattern Customization', isAvailable: true },
    { text: 'Unlimited single size Tech Packs', isAvailable: true },
    { text: 'Single size Marker layout', isAvailable: true },
    { text: 'Standard Technical Support', isAvailable: true },
    { text: 'Priority Technical Support', isAvailable: true },
    { text: 'MTM Order processing dashboard*', isAvailable: true },
    { text: 'API Scan to MTM Pattern Customization', isAvailable: false },
  ],
}

const mtmEnterprise = {
  title: 'MTM Enterprise ',
  description: 'B2B plan for enterprise companies',
  price: -1,
  code: '',
  index: 7,
  scope: [
    { text: 'Access to billions of 2D flats design templates', isAvailable: true },
    { text: 'Access to billions of smart-fit pattern blocks', isAvailable: true },
    { text: 'Unlimited patterns + sketches downloads of unique design configurations', isAvailable: true },
    { text: 'Unlimited PDF/PLT/DXF-AAMA downloads', isAvailable: true },
    { text: 'Smart Adjustments', isAvailable: true },
    { text: 'Unlimited Custom FitModels', isAvailable: true },
    { text: 'Standard Size Fit Charts', isAvailable: true },
    { text: 'MTM Pattern Customization', isAvailable: true },
    { text: 'Unlimited single size Tech Packs', isAvailable: true },
    { text: 'Single size Marker layout', isAvailable: true },
    { text: 'Personal Account Manager', isAvailable: true },
    { text: 'Standard Technical Support', isAvailable: true },
    { text: 'Priority Technical Support', isAvailable: true },
    { text: 'MTM Order processing dashboard*', isAvailable: true },
    { text: 'API Scan to MTM Pattern Customization', isAvailable: true },
  ],
}

const plans = [
  {
    title: 'Individual',
    variants: {
      student,
      indie,
    },
  },
  {
    title: 'Mass Production',
    variants: {
      fashionStartup,
      manufacturer,
      enterprise,
    },
  },
  {
    title: 'MTM / MTO',
    variants: {
      starter,
      pro,
      enterprise: mtmEnterprise,
    },
  },
]

const billingPlans = [student, indie, fashionStartup, manufacturer, starter, pro]

export default plans
export {
  billingPlans,
}
