<template>
  <div class="ComponentWrapperBase">
    <div class="ComponentWrapperBase__block column">
      <div class="ComponentWrapperBase__header">
        <div class="ComponentWrapperBase__title">
          {{ title }}
        </div>

        <div class="ComponentWrapperBase__menu">
          <slot name="menu"></slot>
        </div>

      </div>

      <div class="ComponentWrapperBase__top q-gutter-sm q-pb-md">
        <slot name="top"></slot>
      </div>

      <div
        class="ComponentWrapperBase__content"
        :class="contentClass"
      >
        <slot></slot>
      </div>

      <div class="ComponentWrapperBase__bottom q-gutter-x-sm q-pt-md">
        <slot name="bottom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentWrapperBase',

  props: {
    title: {
      type: String,
      default: '',
    },
    contentClass: {
      type: Object,
      default: undefined,
    },
  },
}
</script>

<style lang="scss">
  @import "src/styles/variables";
  @import "src/styles/mixins";

  $border-color: $toolbar-border;
  .center .ComponentWrapperBase__block {
      max-width: 684px;
      margin: auto;
  }
  .ComponentWrapperBase {
    padding:      16px;
    border-right: 1px solid $border-color;

    .PageDesigner__attributeSettingsSelect {
      min-width: calc(100% / 3 - 8px);
      @media (max-width: 480px) {
        min-width: 100%;
      }
    }

    &__block {
      height: 100%;
    }
    &__header {
      display:         flex;
      align-items:     center;
      justify-content: space-between;

      .q-btn {
        font-size: 18px;
      }
    }

    &__title {
      @include font-styles(18px, 27px, 600, Montserrat);
      margin-bottom: 12px;
    }

    &__content {
      flex-grow: 1;
    }
  }
</style>
