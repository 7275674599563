<template>
  <div class="sketch-description">
    <p class="sketch-description__label">Design details</p>
    <span v-for="(item, i) in descItems" :key="i">
      <span v-if="item.prefix">{{ item.prefix }}</span>
      <a
        :is="item.elemId ? 'a' : 'span'"
        @click.prevent="onDescItemClick(item)"
        href="#"
      >{{ item.text }}</a>
      <span v-if="descItems[i + 1]">{{ item.sep }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SketchDescription',

  props: {
    descItems: {
      type: Array,
      required: true,
    },
  },

  methods: {
    onDescItemClick (item) {
      if (item.elemId) {
        this.$emit('click', {
          elementId: item.elemId,
          nodeCode: item.nodeCode
        })
      }
    },
  },
}
</script>

<style lang="scss">
.sketch-description {
  &__label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
  }
}
</style>
