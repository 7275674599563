<template>
  <portal to="print">
    <div class="PrintableComponent print-only q-pa-lg-md">
      <slot></slot>
    </div>
  </portal>
</template>

<script>
/**
 * Компонент рендерит слой, который виден только в media print
 * Не создавать более 1 компонента единовременно
 * Создавать слой только перед печатью и удалять после события afterprint, т.к. он меняет свойства body
 */

export default {
  name: 'PrintableComponent',
}
</script>

<style lang="scss">
  @import "src/styles/global-z-indexes";

  .PrintableComponent {
    width:            100%;
    min-height:       100vh;
    z-index:          $z-printable;
    background-color: white;
  }
</style>
