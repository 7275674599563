<template>
  <div class="table-row SpecMeasurementElement">
    <div class="table-row__collumn name">
      <div class="col nameText">
        {{ name }}
      </div>
      <svg width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"
        class="icon"
        @click="openDescription()"
      >
        <path fill="#6F8789" d="M10.75 1.5c-5.10863 0-9.25 4.14137-9.25 9.25 0 5.1086 4.14137 9.25 9.25 9.25 5.1086 0 9.25-4.1414 9.25-9.25 0-5.10863-4.1414-9.25-9.25-9.25ZM0 10.75C0 4.81294 4.81294 0 10.75 0 16.6871 0 21.5 4.81294 21.5 10.75c0 5.9371-4.8129 10.75-10.75 10.75C4.81294 21.5 0 16.6871 0 10.75ZM10.75 6.5c-.6213 0-1.125.50368-1.125 1.125 0 .41421-.33579.75-.75.75s-.75-.33579-.75-.75C8.125 6.17525 9.30025 5 10.75 5c1.4497 0 2.625 1.17525 2.625 2.625 0 .96334-.5194 1.80457-1.2897 2.26037-.1968.11643-.3592.24353-.465.36823-.1028.121-.1203.2-.1203.2464v1.25c0 .4142-.3358.75-.75.75s-.75-.3358-.75-.75V10.5c0-.50589.2215-.91636.4766-1.21699.252-.29699.5663-.52381.8449-.68862.3329-.197.5535-.55784.5535-.96939 0-.62132-.5037-1.125-1.125-1.125Zm0 9.25c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1Z"/>
      </svg>
    </div>
    <div class="name">
      {{ value }}
      <modal-component
        class="image-modal"
        :showIcon="false"
        :show="displayDescription" @close="displayDescription = false"
      >
        <template v-slot:title v-if="imageLoadError">Sorry, no help image were found.</template>
        <template v-slot:extra v-else>
          <img :style="`max-height:${imgHeight}px;`" :src="imageUrl()" />
        </template>
      </modal-component>
    </div>
  </div>
</template>

<script>
import ModalComponent from '@/components/Atoms/ModalComponent'

export default {
  name: 'SpecMeasurementElement',
  components: { ModalComponent },
  props: {
    name: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      required: true,
    },
    type: String,
    isSizeParam: Boolean,
  },

  data: () => ({
    displayDescription: false,
    imageLoadError: false,
  }),

  computed: {
    imgHeight () {
      return window.innerHeight - 150
    },
  },

  methods: {
    imageUrl () {
      if (this.isSizeParam) {
        let catalog = null
        if (this.type === 'FEMALE' || this.type === 'MALE') catalog = this.type
        else if (this.type?.includes('TEEN')) catalog = "TEEN"
        else catalog = "CHILD"
        try {
          return require(`@/assets/fitModelDescription/${catalog}/${this.code}.png`)
        } catch (a) {
          console.log('err')
          this.imageLoadError = true
        }
      } else {
        this.imageLoadError = true
      }
    },
    openDescription () {
      this.imageLoadError = false
      this.displayDescription = true
    },
  },
}
</script>

<style lang="scss">
  .SpecMeasurementElement {
    .image-modal.modal-component-overlay .modal-component {
      width: auto;
      max-width: 80%;
      max-height: 90%;
      .extra {
        max-width: 100%;
        img {
          max-width: 100%;
          object-fit: contain;
        }
      }
    }
    font-size: 14px;
    &__imageContainer {
      img {
        width:      100%;
        height:     100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    &__imageContainerVertical {
      text-align: center;
      img {
        max-width:      100%;
        height:     100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    @media (max-width: 450px) {
      &__imageContainerVertical {
        img {
          max-height: 350px;
        }
      }
    }
  }
</style>
