<template>
  <div class="FitEditorComponent row bg-white col-grow">
    <div class="FitEditorComponent__column col column q-pa-md">
      <div class="block-header main-header avenir-font-bold">
        Body Measurements
      </div>
      <div
        v-if="canSelectEditMode"
        class="row full-width q-px-md q-py-sm"
      >
        <div class="col avenir-font-regular">
          Edit mode
        </div>
        <div class="col flex justify-end avenir-font-regular">
          <q-btn-group outline>
            <q-btn
              :outline="currentEditMode !== EDIT_MODES.BASIC"
              :color="currentEditMode === EDIT_MODES.BASIC ? 'black' : 'primary'"
              @click.prevent="selectMode(EDIT_MODES.BASIC)"
              :class="currentEditMode === EDIT_MODES.BASIC ? 'black-button' : 'outline-button'"
            >
              Basic
            </q-btn>
            <q-btn
              :outline="currentEditMode !== EDIT_MODES.ALL"
              :color="currentEditMode === EDIT_MODES.ALL ? 'black' : 'primary'"
              @click.prevent="selectMode(EDIT_MODES.ALL)"
              :class="currentEditMode === EDIT_MODES.ALL ? 'black-button' : 'outline-button'"
            >
              Additional
            </q-btn>
          </q-btn-group>
        </div>
      </div>
      <fit-form
        v-if="mannequinType"
        class="col-grow"
        :mannequin-name="mannequinName"
        :params="sortedParams"
        :edit-available="editMode !== EDIT_MODES.NONE"
        :mannequin-type="mannequinType"
        :mannequin-type-editable="mannequinTypeEditable"
        :mannequin-id="mannequinId"
        :saved-manneqins-ids="savedManneqinsIds"
        :saved-manneqins-names="savedManneqinsNames"
        :isEditPage="isEditPage"
        @loadQueryParams="loadQueryParams"
        @mannequin-type-updated="changeMannequinType"
        @save="saveMeasurements"
        @cancel="$emit('cancel')"
        @error="$emit('error', $event)"
        @changed="saveMeasurements"
        @errorValidations="$emit('errorValidations', $event)"
      />
    </div>
    <div
      v-if="displayPreview"
      class="FitEditorComponent__column col q-pa-md"
    >
      <div class="block-header q-pa-md">Preview</div>
      <fit-preview class="q-px-md"/>
    </div>
  </div>
</template>

<script>
import FitForm from '@/components/Fit/FitForm'
import FitPreview from '@/components/Fit/FitPreview'
import { EDIT_MODES } from '@/components/Fit/measurements'
import { mapActions, mapGetters, mapState } from 'vuex'
import { MeasurementsMannequin, MeasurementsStandartSize } from '@/components/Fit/fitEditorProps'
import { MANNEQUIN_TYPES } from '@/components/Fit/mannequinTypes'

export default {
  name: 'FitEditorComponent',
  components: {
    FitForm,
    FitPreview,
  },
  props: {
    displayPreview: Boolean,
    srcMeasurements: {
      type: [MeasurementsStandartSize, MeasurementsMannequin],
    },
    mannequinName: {
      type: String,
      default: undefined,
    },
    editMode: {
      type: Number,
      required: true,
      validator: item => Object.values(EDIT_MODES).includes(item),
    },
    savedManneqinsIds: {
      type: Array,
      default () {
        return []
      },
    },
    savedManneqinsNames: {
      type: Array,
      default () {
        return []
      },
    },
    mannequinTypeEditable: Boolean,
    isEditPage: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    mannequinTypeSelected: undefined,
  }),

  computed: {
    mannequinId () {
      if (this.srcMeasurements instanceof MeasurementsMannequin) {
        return this.srcMeasurements.mannequinId
      }
      return undefined
    },
    standartSize () {
      if (this.srcMeasurements instanceof MeasurementsStandartSize) {
        return this.srcMeasurements.size.toUpperCase()
      }
      return undefined
    },
    mannequinData () {
      if (!this.mannequinId) {
        return undefined
      }
      if (!this.mannequinWithSizeParams[this.mannequinId]) {
        this.fetchSizeParamsByMannequinId({ mannequinId: this.mannequinId })
      }
      return this.mannequinWithSizeParams[this.mannequinId]
    },
    mannequinType () {
      if (this.mannequinTypeSelected) {
        return this.mannequinTypeSelected
      }
      if (this.mannequinId) {
        return this.mannequinData?.mannequinType
      }
      if (this.srcMeasurements instanceof MeasurementsStandartSize) {
        return this.srcMeasurements.type
      }
      return MANNEQUIN_TYPES.FEMALE
    },
    defaultParams () {
      return this.defaultSizeParams?.[this.mannequinType]
    },
    defaultStandartSizeParams () {
      if (!this.standartSize) {
        return undefined
      }
      return this.standartSizes[this.mannequinType]?.[this.standartSize]
    },
    // параметры размеров для манекена
    mannequinSizeParams () {
      if (!this.mannequinId) {
        return undefined
      }
      return this.mannequinData?.sizeParams
    },
    // возвращает параметры по-умолчанию, если standartSize не задан, параметры со значениями standartSize, если задан и параметры со значениями манекена, если задан манекен
    sizeParams () {
      // в случае, если переданный манекен не успел загрузиться, тип манекена будет неизвестен
      if (!this.mannequinType) {
        return []
      }
      if (!this.defaultParams) {
        // асинхронный метод
        this.fetchDefaultSizeParams({ mannequinType: this.mannequinType })
        return []
      }
      if (this.standartSize) {
        if (!this.defaultStandartSizeParams) {
          // асинхронный метод
          this.fetchStandartSize({ size: this.standartSize, mannequinType: this.mannequinType })
          return []
        }

        return this.defaultParams.map(param => {
          let sizeParam = this.defaultStandartSizeParams.find(standart => standart.uid === param.uid)
          if (!sizeParam) {
            return param
          }
          return { ...param, ...sizeParam }
        })
      }
      if (this.mannequinId) {
        return this.mannequinSizeParams
      }

      return this.defaultParams
    },
    canSelectEditMode () {
      return this.editMode === EDIT_MODES.ANY
    },
    currentEditMode () {
      if (this.canSelectEditMode) {
        return this.userSelectedMode
      }
      return this.editMode
    },
    availableMeasurements () {
      if (this.currentEditMode === EDIT_MODES.BASIC) {
        return this.sizeParams.filter(item => item.isMain)
      }
      return this.sizeParams
    },
    sortedParams () {
      let params = this.availableMeasurements
      return params.sort((a, b) => {
        let aUid = this.mannequinType === "MALE" && a.uid.split('_')[1] === '53' ? '30' : a.uid.split('_')[1]
        let bUid = this.mannequinType === "MALE" && b.uid.split('_')[1] === '53' ? '30' : b.uid.split('_')[1]
        return aUid - bUid
      })
    },
    ...mapState('fitEditor', ['userSelectedMode', 'defaultSizeParams', 'standartSizes']),
    ...mapGetters('fitEditor', ['mannequinWithSizeParams']),
  },

  created () {
    this.EDIT_MODES = EDIT_MODES
    window.fitEditor = this
  },

  methods: {
    loadQueryParams (params) {
      this.$emit('loadQueryParams', params)
    },
    async changeMannequinType (type) {
      if (!this.mannequinTypeEditable) {
        return false
      }
      this.mannequinTypeSelected = type
    },
    async selectMode (mode) {
      try {
        await this.selectEditMode(mode)
      } catch (e) {
        this.$emit('error', { title: 'Invalid mode selected', message: 'Cannot select this mode' })
      }
    },

    async saveMeasurements ({ fitOptions, name, mannequinType }) {
      let mainOptions = fitOptions
        .filter(item => (
          this.sizeParams.find(option => option.uid === item.uid)?.isMain
        ))
      switch (this.editMode) {
        case EDIT_MODES.NONE:
          throw Error('Saving is not available for this edit mode')
        case EDIT_MODES.BASIC:
          if (this.isEditPage) this.$emit('changed', { measurements: mainOptions, name, mannequinType })
          else {
            this.$emit('save', {
              measurements: mainOptions,
              name,
              mannequinType,
            })
          }
          break
        default:
          if (this.isEditPage) this.$emit('changed', { measurements: fitOptions, name, mannequinType })
          else this.$emit('save', { measurements: fitOptions, name, mannequinType })
      }
      const someValuesChanged = fitOptions.some(opt => {
        const optBeforeEdit = this.sizeParams.find(par => par.uid === opt.uid)
        return !optBeforeEdit || optBeforeEdit.value !== opt.value
      })
      if (someValuesChanged) {
        this.markMannequinChanged()
      }
    },
    ...mapActions('fitEditor',
      ['selectEditMode', 'fetchSizeParamsByMannequinId', 'fetchDefaultSizeParams', 'fetchStandartSize', 'markMannequinChanged']),
  },
}
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .FitEditorComponent {
    &__column {
      &:not(:last-child) {
        border-right: 1px solid $border-color-light;
      }
    }
    .main-header {
      margin: -16px -16px 16px;
      padding: 16px 32px;
      font-weight: 600;
      text-align: center;
    }
  }
</style>
