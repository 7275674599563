export const MATCH_DELTA = 0.5
export const EXTENDED_MATCH_DELTA = 5

/**
 * Does points match or close to it
 * @param {{x: Number, y: Number}} target
 * @param {{x: Number, y: Number}} matchee
 * @param delta
 * @returns {boolean}
 */
export const matchPoints = (target, matchee, delta = MATCH_DELTA) => {
  return Math.abs(target.x - matchee.x) <= delta &&
    Math.abs(target.y - matchee.y) <= delta
}

/**
 * Calculate points distance
 * @param {{x: Number, y: Number}} firstPoint
 * @param {{x: Number, y: Number}} secondPoint
 * @return {number}
 */
// eslint-disable-next-line no-unused-vars
export const pointsDistance = (firstPoint, secondPoint) => {
  return Math.sqrt(Math.pow(secondPoint.x - firstPoint.x, 2) + Math.pow(secondPoint.y - firstPoint.y, 2))
}
