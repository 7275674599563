<template>
  <div class="base-dropdown" v-click-outside="() => isOpen = false">
    <p class="base-dropdown__label">{{ label }}</p>
    <button :class="`base-dropdown__button ${disabled ? 'disable' : ''}`" @click="isOpen = !isOpen">
      <div class="button_text">
        {{ value?.[optionLabel] || placeholder }}
      </div>
      <svg :class="isOpen ? 'open' : ''" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20" width="20" height="20">
        <path fill="#1d1f29" d="M10 12.4c-.2 0-.4-.1-.5-.2L6.1 8.9c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l2.8 2.8 2.8-2.8c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-3.3 3.3c-.2.1-.4.2-.6.2z"/>
      </svg>
    </button>
    <div
      v-if="isOpen"
      class="base-dropdown__content"
      @scroll="handleScroll"
    >
      <slot name="option" />
      <div
        v-for="(option, index) in options"
        :key="index"
        @click.stop="selectOption(option)"
        :class="`base-dropdown__content__item
          ${value?.[optionLabel] === option?.[optionLabel] ? 'active' : ''}
          ${option?.disable ? 'disable' : ''}`
        "
      >
        {{ option?.[optionLabel] }}
      </div>
      <div class="base-dropdown__content__loading" v-if="loading">Loading ...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: ['options', 'label', 'placeholder', 'value', 'optionLabel', 'disabled', 'loading', 'needLoading'],
  data: () => ({
    isOpen: false,
  }),
  methods: {
    selectOption (option) {
      this.$emit('input', option)
      this.isOpen = false
    },
    handleScroll (event) {
      if (this.needLoading) {
        const { scrollTop, scrollHeight, clientHeight } = event.target
        if (scrollHeight - scrollTop <= clientHeight + 20 && !this.loading) {
          this.$emit('ended')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.base-dropdown {
  position: relative;
  &__label {
    font-size: 12px !important;
    color:  var(--Text-Text_Secondary) !important;
    margin-bottom: 8px !important;
  }
  &__button.disable {
    cursor: no-drop;
    pointer-events: none;
    opacity: 0.5;
    &:hover {
      border-color: var(--Dividers);
    }
  }
  &__button {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    color: var(--Text-Text_Primary);
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: transparent;
    padding: 9px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button_text {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis
    }
    svg {
      width: 20px;
      transition: all 0.3s ease-in-out;
    }
    svg.open {
      transform: rotate(180deg);
    }
    &:hover {
      border-color: var(--Button-States-Hover);
    }
  }
  &__content {
    z-index: 1;
    width: 100%;
    position: absolute;
    margin-top: 8px;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    background: var(--Background);
    box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.10);
    max-height: 246px;
    overflow: scroll;
    &__item {
      border-radius: 8px;
      padding: 8px 16px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background: var(--Surface-Hover);
        color: var(--Button-States-Hover);
      }
    }
    &__item.active {
      background: var(--Surface-Hover);
      color: var(--Button-States-Hover);
    }
    &__item.disable {
      cursor: no-drop;
      pointer-events: none;
      opacity: 0.5;
      &:hover {
        background: var(--Background);
        color: inherit;
      }
    }
    &__loading {
      padding: 8px 16px;
      border-top: 1px solid var(--Surface-Hover);
      font-size: 12px;
    }
    &__search {
      padding: 8px;
    }

  }
}
</style>
