const advancedLayers = () => ([
  {
    uid: 'MAIN FABRIC',
    fabricWidthsInCm: [
      {
        value: 114,
        inchLabel: '45”',
      },
      {
        value: 152,
        inchLabel: '60”',
      },
    ],
  },
  {
    uid: 'FUSIBLE INTERFACING',
    fabricWidthsInCm: [
      {
        value: 51,
        inchLabel: '20”',
      },
    ],
  },
  {
    uid: 'LINING',
    fabricWidthsInCm: [
      {
        value: 114,
        inchLabel: '45”',
      },
      {
        value: 152,
        inchLabel: '60”',
      },
    ],
  },
])

const basicWidths = () => ([
  {
    value: 88,
    inchLabel: '35–36”',
  },
  {
    value: 99,
    inchLabel: '39”',
  },
  {
    value: 104,
    inchLabel: '41”',
  },
  {
    value: 111,
    inchLabel: '44–45”',
  },
  {
    value: 127,
    inchLabel: '50”',
  },
  {
    value: 132,
    inchLabel: '52–54”',
  },
  {
    value: 147,
    inchLabel: '58–60”',
  },
  {
    value: 167,
    inchLabel: '66”',
  },
  {
    value: 182,
    inchLabel: '72”',
  },
  {
    value: 243,
    inchLabel: '96”',
  },
  {
    value: 274,
    inchLabel: '108”',
  },
])

export {
  advancedLayers,
  basicWidths,
}
