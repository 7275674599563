<template>
  <div
    class="ElementOptionItem cursor-pointer"
    :class="{ElementOptionItem__selected: isSelected}"
    @click="$emit('select', element)"
    :title="element.name"
  >

    <slot></slot>
    <div v-if="!hideName" class="ElementOptionItem__name">
      {{ element.name }}
    </div>

  </div>
</template>

<script>

import { hasProperties } from '@/helpers'

export default {
  name: 'ElementOptionItem',

  props: {
    element: {
      type: Object,
      required: true,
      validator: el => hasProperties(el, ['name']),
    },
    isSelected: Boolean,
    hideName: Boolean,
  },

  computed: {
    hasOptions () {
      return this.element.options?.length > 0
    },
  },
}
</script>

<style lang="scss">
  @import "src/styles/variables";

  .ElementOptionItem {
    position:        relative;
    display:         inline-flex;
    flex-direction:  column;
    justify-content: center;
    align-content:   center;
    align-items:     center;
    width:           105px;
    height:          170px;
    user-select:     none;
    border:          1px solid transparent;
    border-radius:   5px;

    &__selected {
      border-color: var(--q-color-secondary);
    }

    &__name {
      max-width:      80%;
      line-height:    20px;
      overflow:       hidden;
      text-overflow:  ellipsis;
      font-size:      10px;
      color:          $primary-text-color;
      text-transform: uppercase;
      text-align:     center;
      white-space:    nowrap;
    }
  }
</style>
