<template>
  <div class="fit-models">
    <h1>
      <span>Fit models</span>
      <ButtonIcon class="primary small" @click.native="$router.push({ name: 'fitModelNew' })">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M10 4.75a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25v4.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5.5a.75.75 0 0 0-.75-.75z"/>
        </svg>
      </ButtonIcon>
    </h1>
    <TopPanel
      @search="fitModelsStore.search"
      @sort-order="fitModelsStore.setSortOrder"
      @sort-option="fitModelsStore.sort"
      @create-new="$router.push({ name: 'fitModelNew' })"
      :add-new-button-disabled="demoMode"
      :sort-options="sortOptions"
    >
      <template v-slot:left-side>
        <h2>Fit models</h2>
      </template>
      <template v-slot:button-text>Add New</template>
    </TopPanel>
    <EmptyGrid v-if="demoMode" :buttonPlusIcon="false" @create-new="$router.push({ name: 'user_billing' })">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 33">
          <path d="m27.8 10.3-5.7-5.7c-.8-.8-2.1-.8-2.9 0l-15 15.1c-.4.4-.6.9-.6 1.5s.2 1.1.6 1.5l5.7 5.7c.4.4.9.6 1.5.6.5 0 1.1-.2 1.5-.6L28 13.3c.4-.4.6-.9.6-1.5-.1-.6-.4-1.1-.8-1.5zm-1 1.9L11.7 27.3c-.2.2-.6.2-.8 0l-5.7-5.7c-.1-.1-.2-.2-.2-.4s.1-.3.2-.4l3.2-3.2 2.3 2.3c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-2.3-2.3 2.7-2.7 2.3 2.3c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-2.3-2.3L16 10l2.3 2.3c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L17.1 9l3.2-3.2c.1-.1.3-.2.4-.2.2 0 .3.1.4.2l5.7 5.7c.1.1.2.3.2.4 0 0-.1.2-.2.3z"/>
        </svg>
      </template>
      <template v-slot:title>Custom fit models are not available for your subscription</template>
      <template v-slot:text>Please, upgrade your account</template>
      <template v-slot:button-text>Upgrade account</template>
    </EmptyGrid>
    <EmptyGrid @create-new="$router.push({ name: 'fitModelNew' })" v-else-if="fitModelsStore.fitModels.count === 0">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 33">
          <path d="M5.334 5.084c-1.142 0-2.084.94-2.084 2.082v18.668c0 1.142.942 2.082 2.084 2.082h21.332c1.142 0 2.084-.94 2.084-2.082v-16a2.096 2.096 0 0 0-2.084-2.084H16.312l-2.058-2.057c-.39-.39-.92-.609-1.473-.609H5.334zm0 1.5h7.447a.58.58 0 0 1 .412.17l.996.996H4.75v-.584c0-.331.253-.582.584-.582zM4.75 9.25h21.916c.331 0 .584.253.584.584v16a.572.572 0 0 1-.584.582H5.334a.572.572 0 0 1-.584-.582V9.25zm10.584 3.834a4.094 4.094 0 0 0-4.084 4.082 4.096 4.096 0 0 0 4.084 4.084c.853 0 1.648-.265 2.305-.717l1.83 1.83a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-1.832-1.832c.452-.658.719-1.451.719-2.305a4.092 4.092 0 0 0-4.082-4.082zm0 1.5a2.57 2.57 0 0 1 2.582 2.582 2.571 2.571 0 0 1-2.582 2.584 2.573 2.573 0 0 1-2.584-2.584 2.571 2.571 0 0 1 2.584-2.582z"/>
        </svg>
      </template>
      <template v-slot:title>You haven't created any fit model yet</template>
      <template v-slot:text>Here you can create your new fit model and come up with names for them. </template>
      <template v-slot:button-text>Create your first fit model</template>
    </EmptyGrid>
    <ElementsGrid v-else>
      <OneFitModel
        v-for="fitChart in fitModelsStore.fitModels.list"
        :key="fitChart._id"
        :fit-chart="fitChart"
        @edit="$router.push({ name: 'fitModelEdit', params: { id: fitChart._id } })"
        @delete="delModal"
      />
    </ElementsGrid>
    <ModalComponent @close="fitModelsStore.delChartModalToggle()" :show="fitModelsStore.delChartModal" type="error">
      <template v-slot:title>Are you sure you want to delete this fit model? </template>
      <template v-slot:description>You can not undo this action</template>
      <template v-slot:buttons>
        <ButtonSecondary @click.native="fitModelsStore.delChartModalToggle()">Cancel</ButtonSecondary>
        <ButtonPrimary @click.native="fitModelsStore.deleteChart()">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
              <path d="M8 1.75c-.866 0-1.584.718-1.584 1.584v.916H3.834a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h.916v10.916c0 .866.718 1.584 1.584 1.584h8.332c.866 0 1.584-.718 1.584-1.584V5.75h.918a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-2.586v-.916c0-.866-.716-1.584-1.582-1.584H8zm0 1.5h5c.055 0 .082.03.082.084v.916H7.916v-.916c0-.055.03-.084.084-.084zm-1.75 2.5h8.5v10.916c0 .055-.03.084-.084.084H6.334c-.055 0-.084-.03-.084-.084V5.75z"/>
            </svg>
          </template>
          Delete model
        </ButtonPrimary>
      </template>
    </ModalComponent>
    <ExpiredDemoMode />
    <PaginationComponent
        :limit="fitModelsStore.params.limit"
        :offset="fitModelsStore.params.offset"
        :total="fitModelsStore.fitModels.count"
        @setOffset="setOffset"
      />
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFitModelsStore } from '@/store/pinia/fitModels'

import TopPanel from '@/components/UserCollections/TopPanel.vue'
import ButtonIcon from '@/components/Atoms/ButtonIcon.vue'
import EmptyGrid from '@/components/Atoms/EmptyGrid.vue'
import ElementsGrid from '@/components/Atoms/ElementsGrid.vue'
import OneFitModel from '@/components/fitCharts/OneFitModel.vue'
import PaginationComponent from '@/components/Atoms/PaginationComponentLimit.vue'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ExpiredDemoMode from '@/components/ExpiredDemoMode.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FitModels',
  components: {
    ExpiredDemoMode,
    TopPanel,
    ButtonIcon,
    EmptyGrid,
    ElementsGrid,
    OneFitModel,
    PaginationComponent,
    ModalComponent,
    ButtonPrimary,
    ButtonSecondary,
  },
  computed: {
    ...mapStores(useFitModelsStore),
    ...mapGetters('user', ['demoMode']),
  },
  data: () => ({
    sortOptions: [
      { name: 'Sort by', value: '' },
      { name: 'Name', value: 'name' },
      { name: 'Updated', value: 'updatedAt' },
    ],
  }),
  methods: {
    delModal (payload) {
      this.fitModelsStore.delChartModalToggle()
      this.fitModelsStore.chartId = payload._id
    },
    async setOffset (offset) {
      this.fitModelsStore.params.offset = offset
      await this.fitModelsStore.getList()
    },
  },
  async beforeMount () {
    await this.fitModelsStore.getList()
  },
}
</script>

<style lang="scss">
.fit-models {
  padding: 0 16px 40px 40px;

  h1, h2 {
    color: var(--text_color);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-bottom: 16px;

    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      display: none;
    }
  }

  h2 {
    display: inline-block;
    margin-bottom: 0;
  }

  .buttons-group.custom-standart {
    max-width: 200px;
  }
  .buttons-group.standart {
    margin-top: 16px;
  }
}

@media (max-width: 760px) {
  .fit-charts {
    padding: 0 0 40px 0 !important;

    h1 {
      display: flex;
      font-size: 24px !important;
      button {
        display: block;
      }
    }

    .buttons-group.custom-standart {
      width: 100%;
      max-width: none;
    }

    .buttons-group.standart {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
      row-gap: 8px;

      button {
        border-radius: 8px;
      }
    }

    .button-sort {
      margin-right: 0 !important;
    }

    .user-top-panel {
      .right-side {
        column-gap: 8px;

        .search-field {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
