import { v4 as uuid } from 'uuid'
import { defineStore } from 'pinia'
import { request } from '../../api'

export const useFitModelsStore = defineStore('fitModels', {
  state: () => ({
    types: [
      { key: 'FEMALE', title: 'Womenswear' },
      { key: 'MALE', title: 'Menswear' },
      { key: 'GIRL', title: 'Girlswear' },
      { key: 'BOY', title: 'Boyswear' },
      { key: 'GIRL-TEEN', title: 'Girls junior' },
      { key: 'BOY-TEEN', title: 'Boys junior' },
    ],
    unit: 'in',
    fitModels: {
      list: [],
      count: 0,
    },

    dataKey: 'newChart',

    newChart: {
      step: 'b2',
      title: '',
      type: 'FEMALE',
      isValid: false,
      sizes: {},
      sizesList: [],
      currentCell: {},
    },
    editChart: {
      step: 'c2',
      title: '',
      type: 'FEMALE',
      isValid: false,
      sizes: {},
      sizesList: [],
      currentCell: {},
    },

    previewChart: null,

    params: {
      offset: 0,
      limit: 20,
      sortField: '',
      sortDirection: 'asc',
      q: '',
    },
    delChartModal: false,
    chartId: null,
    modelsForEdit: {},
    selectedModel: null,
    paramsPageFitModel: {
      offset: 0,
      limit: 20,
      sortField: '',
      sortDirection: 'asc',
      q: '',
      type: null,
    },
    countPageFitModel: null,
    loading: false,
  }),
  getters: {
    sizeChartIsValid (state) {
      return state[this.dataKey].isValid && state[this.dataKey].title.length > 0
    },
  },
  actions: {
    delChartModalToggle () {
      this.delChartModal = !this.delChartModal
      this.chartId = null
    },
    async setSortOrder (order) {
      this.params.sortDirection = order
      await this.getList()
    },
    async search (search) {
      this.params.search = search
      await this.getList()
    },
    async sort (sort) {
      this.params.sortField = sort
      await this.getList()
    },
    async getListFitModelPage () {
      try {
        this.loading = true
        const { data: { rows, count } } = await request.get(
          '/fit-models',
          { params: this.paramsPageFitModel },
        )
        this.countPageFitModel = count
        if (!this.modelsForEdit[this.paramsPageFitModel.type]) {
          this.modelsForEdit[this.paramsPageFitModel.type] = []
        }
        rows.forEach(el => {
          this.modelsForEdit[this.paramsPageFitModel.type].push({
            ...el.mannequins[0],
            name: el.name,
          })
        })
        this.loading = false
      } catch (e) {
        this.loading = false
        console.error(e.message)
      }
    },
    async getList () {
      try {
        const { data: { rows, count } } = await request.get(
          '/fit-models',
          { params: this.params },
        )
        this.fitModels.list = rows
        this.fitModels.count = count
      } catch (e) {
        console.error(e.message)
      }
    },
    async getOne (id) {
      try {
        const { data } = await request.get('/fit-models/' + id)
        this.previewChart = null
        this.previewChart = data
      } catch (e) {
        console.error(e.message)
      }
    },

    async getForEdit (id, refs) {
      try {
        const { data: oneFitChart } = await request.get('/fit-models/' + id)
        this[this.dataKey].sizesList = oneFitChart.mannequins.map(m => m.mannequinId)
        this[this.dataKey].title = oneFitChart.name
        this[this.dataKey].type = oneFitChart.type

        const sizes = oneFitChart.mannequins
        setTimeout(() => {
          this[this.dataKey].sizesList.forEach((id, i) => {
            const data = {
              name: sizes[i].name,
              sizeParams: sizes[i].params,
            }
            refs[id][0].importSize(data)
          })
        }, 0)
      } catch (e) {
        console.error(e.message)
      }
    },

    async setMannequinType (key) {
      this.params.type = key
      await this.getList()
    },

    setSize (size, key) {
      this[this.dataKey].sizes[key] = size
      this.sizesCheckValid()
    },
    addNewSize (i) {
      const key = uuid()
      this[this.dataKey].sizesList.splice(i, 0, key)
    },
    removeSize (i) {
      const key = this[this.dataKey].sizesList[i]
      delete this[this.dataKey].sizes[key]
      this[this.dataKey].sizesList.splice(i, 1)
    },
    setCurrentCell (cell) {
      this[this.dataKey].currentCell = cell
    },
    sizesCheckValid (state) {
      for (const i in this[this.dataKey].sizesList) {
        const key = this[this.dataKey].sizesList[i]
        const fields = this[this.dataKey].sizes[key].fields
        for (const key in fields) {
          const field = fields[key]
          this[this.dataKey].isValid = field.isValid
          if (this[this.dataKey].isValid === false) break
        }
        if (this[this.dataKey].isValid === false) break
      }
    },
    async saveNewChartWithParams (title, type, mannequins, newMan) {
      try {
        const sizechartData = {
          name: title,
          type: type,
          mannequins,
        }
        let { data } = await request.post(
          '/fit-models', JSON.stringify(sizechartData),
          { headers: { 'Content-Type': 'application/json; charset=UTF-8' } },
        )
        this.selectedModel = {
          ...data.mannequins[0],
          name: data.name,
          newMennequinId: newMan,
        }
        this.modelsForEdit[type].push(this.selectedModel)
        return data
      } catch (e) {
        console.error(e.message)
      }
    },
    async saveNewChart () {
      try {
        const mannequins = []

        this[this.dataKey].sizesList.forEach(key => {
          const size = this[this.dataKey].sizes[key]
          const data = {
            sizeParams: [],
            name: size.name,
            mannequinType: this[this.dataKey].type,
          }
          for (const fieldKey in size.fields) {
            const field = size.fields[fieldKey]
            data.sizeParams.push({
              uid: field.param,
              value: field.cm,
            })
          }
          mannequins.push(data)
        })

        const sizechartData = {
          name: this[this.dataKey].title,
          type: this[this.dataKey].type,
          mannequins,
        }

        await request.post(
          '/fit-models', JSON.stringify(sizechartData),
          { headers: { 'Content-Type': 'application/json; charset=UTF-8' } },
        )
      } catch (e) {
        console.error(e.message)
      }
    },
    async updateChart (id) {
      try {
        const mannequins = []

        this[this.dataKey].sizesList.forEach(key => {
          const size = this[this.dataKey].sizes[key]
          const data = {
            sizeParams: [],
            name: size.name,
            mannequinType: this[this.dataKey].type,
          }
          for (const fieldKey in size.fields) {
            const field = size.fields[fieldKey]
            data.sizeParams.push({
              uid: field.param,
              value: field.cm,
            })
          }

          mannequins.push(data)
        })

        const sizechartData = {
          name: this[this.dataKey].title,
          type: this[this.dataKey].type,
          mannequins,
        }

        await request.put(
          '/fit-models/' + id, JSON.stringify(sizechartData),
          { headers: { 'Content-Type': 'application/json; charset=UTF-8' } },
        )
      } catch (e) {
        console.error(e.message)
      }
    },
    async deleteChart () {
      try {
        await request.delete('/fit-models/' + this.chartId)
        await this.getList()
        this.delChartModalToggle()
        this.chartId = null
      } catch (e) {
        console.error(e.message)
      }
    },
  },
})
