
const clear = element => !!element
/**
 * Render command array into SVG.Path d attribute string
 * @param {Array} commands
 * @return {string}
 */
export default commands => {
  return (commands || []).filter(clear).map(command => {
    switch (command.code) {
      case 'M':
        return `M${command.x} ${command.y} `
      case 'L':
      case 'H':
      case 'V':
        return `L${command.x} ${command.y} `
      case 'C':
        return `C${command.x1} ${command.y1},${command.x2} ${command.y2},${command.x} ${command.y} `
      case 'S':
        return `S${command.x2} ${command.y2},${command.x} ${command.y} `
      case 'Q':
        return `Q${command.x1} ${command.y1},${command.x} ${command.y} `
      case 'T':
        return `T${command.x} ${command.y} `
      case 'A':
        return `A${command.rx} ${command.ry} ${command.xAxisRotation} ${command.largeArc} ${command.sweep} ${command.x} ${command.y} `
      case 'Z':
        return 'Z'
      default:
        return ''
    }
  }).filter(clear).join('')
}
