<template>
  <div class="pricing-page">
    <TheHeader :scrollY="0" />
    <section>
      <div class="container">
        <h2>Pricing</h2>
        <h1>Get started today</h1>
        <ButtonsGroup>
          <button :class="[tab === 0 ? 'active' : '']" @click="tab = 0">Individual</button>
          <button :class="[tab === 1 ? 'active' : '']" @click="tab = 1">Mass Production</button>
          <button :class="[tab === 2 ? 'active' : '']" @click="tab = 2">MTM / MTO</button>
        </ButtonsGroup>
        <div class="row student-indie" v-if="tab === 0">
          <div class="column">
            <h3>Student Early Adopter</h3>
            <p>Individual plan limited to 1</p>
            <div class="price">
              <span class="old">$99</span>
              <span> $49</span> / month
            </div>
            <ul>
              <li class="yes">Access to billions of 2D flats design templates</li>
              <li class="yes">Access to billions of smart-fit pattern blocks</li>
              <li class="yes">Unlimited patterns + sketches downloads of unique design configurations</li>
              <li class="yes">Unlimited PDF/PLT/DXF-AAMA downloads</li>
              <li class="yes">Smart Adjustments</li>
              <li class="yes">Unlimited Custom FitModels</li>
              <li class="yes">Standard Size Fit Charts</li>
              <li class="yes">Single size Marker layout</li>
              <li class="yes">Standard Technical Support</li>
              <li class="no">Automated Standard Size Grading</li>
              <li class="no">Unlimited Tech Packs with all size runs</li>
              <li class="no">Custom Fit Charts</li>
            </ul>
            <ButtonSecondary class="small" @click.native="$router.push({ name: 'signUp', params: {selected: 'STUDENT_EA'} })">
              Get started for free
            </ButtonSecondary>
          </div>
          <div class="column">
            <h3>Indie Early Adopter</h3>
            <p>Individual plan limited to 1</p>
            <div class="price">
              <span class="old">$199</span>
              <span> $79</span> / month
            </div>
            <ul>
              <li class="yes">Access to billions of 2D flats design templates</li>
              <li class="yes">Access to billions of smart-fit pattern blocks</li>
              <li class="yes">Unlimited patterns + sketches downloads of unique design configurations</li>
              <li class="yes">Unlimited PDF/PLT/DXF-AAMA downloads</li>
              <li class="yes">Smart Adjustments</li>
              <li class="yes">Unlimited Custom FitModels</li>
              <li class="yes">Standard Size Fit Charts</li>
              <li class="yes">Single size Marker layout</li>
              <li class="yes">Standard Technical Support</li>
              <li class="yes">Automated Standard Size Grading</li>
              <li class="yes">Unlimited Tech Packs with all size runs</li>
              <li class="no">Custom Fit Charts</li>
            </ul>
            <ButtonSecondary class="small" @click.native="$router.push({ name: 'signUp', params: {selected: 'INDIE_EA'} })">Get started for free</ButtonSecondary>
          </div>
        </div>

        <div class="row" v-if="tab === 1">
          <div class="column">
            <h3>Startup Early Adopter</h3>
            <p>B2B Plan for teams with up to 3 seats</p>
            <div class="price">
              <span class="old">$799</span>
              <span> $199</span> / month
            </div>
            <ul>
              <li class="yes">Access to billions of 2D flats design templates</li>
              <li class="yes">Access to billions of smart-fit pattern blocks</li>
              <li class="yes">Unlimited patterns + sketches downloads of unique design configurations</li>
              <li class="yes">Unlimited PDF/PLT/DXF-AAMA downloads</li>
              <li class="yes">Smart Adjustments</li>
              <li class="yes">Unlimited Custom FitModels</li>
              <li class="yes">Standard Size Fit Charts</li>
              <li class="yes">Automated Standard Size Grading</li>
              <li class="yes">Unlimited Tech Packs with all size runs</li>
              <li class="yes">Single size Marker layout</li>
              <li class="yes">Standard Technical Support</li>
              <li class="yes">Custom Fit Charts</li>
            </ul>
            <ButtonSecondary class="small" @click.native="$router.push({ name: 'signUp', params: {selected: 'STARTUP_EA'} })">Get started for free</ButtonSecondary>
          </div>
          <div class="column">
            <h3>Manufacturer Early Adopter</h3>
            <p>B2B Plan for teams with up to 5 seats</p>
            <div class="price">
              <span class="old">$1799</span>
              <span> $799</span> / month
            </div>
            <ul>
              <li class="yes">Access to billions of 2D flats design templates</li>
              <li class="yes">Access to billions of smart-fit pattern blocks</li>
              <li class="yes">Unlimited patterns + sketches downloads of unique design configurations</li>
              <li class="yes">Unlimited PDF/PLT/DXF-AAMA downloads</li>
              <li class="yes">Smart Adjustments</li>
              <li class="yes">Unlimited Custom FitModels</li>
              <li class="yes">Standard Size Fit Charts</li>
              <li class="yes">Automated Standard Size Grading</li>
              <li class="yes">Unlimited Tech Packs with all size runs</li>
              <li class="yes">Single size Marker layout</li>
              <li class="yes">Priority Technical Support</li>
              <li class="yes">Custom Fit Charts</li>
            </ul>
            <ButtonSecondary class="small" @click.native="$router.push({ name: 'signUp', params: {selected: 'GROWING_EA'} })">Get started for free</ButtonSecondary>
          </div>
          <div class="column custom">
            <div>
              <h3>Enterprise / Custom</h3>
              <p>Large-scale Organization Plan</p>
            </div>
            <div class="description">Contact us for a personalized solution!<br>Our team will work with you to create a custom plan that fits your unique business needs.</div>
            <ButtonSecondary class="small" @click.native="formModal = !formModal">Contact us</ButtonSecondary>
          </div>
        </div>

        <div class="row" v-if="tab === 2">
          <!-- <div class="column">
            <h3>MTM Starter</h3>
            <p>B2B plan for small startup</p>
            <div class="price">
              <span>$199</span> / month
            </div>
            <ul>
              <li class="yes">Access to billions of 2D flats design templates</li>
              <li class="yes">Access to billions of smart-fit pattern blocks</li>
              <li class="yes">Unlimited patterns + sketches downloads of unique design configurations</li>
              <li class="yes">Unlimited PDF/PLT/DXF-AAMA downloads</li>
              <li class="yes">Smart Adjustments</li>
              <li class="yes">Unlimited Custom FitModels</li>
              <li class="yes">Standard Size Fit Charts</li>
              <li class="yes">MTM Pattern Customization</li>
              <li class="yes">Unlimited single size Tech Packs</li>
              <li class="yes">Single size Marker layout</li>
              <li class="yes">Standard Technical Support</li>
              <li class="no">MTM Order processing dashboard</li>
              <li class="no">API Scan to MTM Pattern Customization</li>
            </ul>
            <ButtonSecondary class="small" @click.native="$router.push({ name: 'signUp' })">Get started for free</ButtonSecondary>
          </div>
          <div class="column">
            <h3>MTM Pro</h3>
            <p>B2B plan for medium operating business</p>
            <div class="price">
              <span>$1799</span> / month
            </div>
            <ul>
              <li class="yes">Access to billions of 2D flats design templates</li>
              <li class="yes">Access to billions of smart-fit pattern blocks</li>
              <li class="yes">Unlimited patterns + sketches downloads of unique design configurations</li>
              <li class="yes">Unlimited PDF/PLT/DXF-AAMA downloads</li>
              <li class="yes">Smart Adjustments</li>
              <li class="yes">Unlimited Custom FitModels</li>
              <li class="yes">Standard Size Fit Charts</li>
              <li class="yes">MTM Pattern Customization</li>
              <li class="yes">Unlimited single size Tech Packs</li>
              <li class="yes">Single size Marker layout</li>
              <li class="yes">Standard Technical Support</li>
              <li class="yes">Priority Technical Support</li>
              <li class="yes">MTM Order processing dashboard</li>
              <li class="no">API Scan to MTM Pattern Customization</li>
            </ul>
            <ButtonSecondary class="small" @click.native="$router.push({ name: 'signUp' })">Get started for free</ButtonSecondary>
          </div> -->
          <div class="column">
            <h3>MTM Enterprise </h3>
            <p>B2B plan for enterprise companies</p>
            <div class="price custom">
              Custom price
            </div>
            <ul>
              <li class="yes">Access to billions of 2D flats design templates</li>
              <li class="yes">Access to billions of smart-fit pattern blocks</li>
              <li class="yes">Unlimited patterns + sketches downloads of unique design configurations</li>
              <li class="yes">Unlimited PDF/PLT/DXF-AAMA downloads</li>
              <li class="yes">Smart Adjustments</li>
              <li class="yes">Unlimited Custom FitModels</li>
              <li class="yes">Standard Size Fit Charts</li>
              <li class="yes">MTM Pattern Customization</li>
              <li class="yes">Unlimited single size Tech Packs</li>
              <li class="yes">Single size Marker layout</li>
              <li class="yes">Personal Account Manager</li>
              <li class="yes">Standard Technical Support</li>
              <li class="yes">Priority Technical Support</li>
              <li class="yes">MTM Order processing dashboard</li>
              <li class="yes">API Scan to MTM Pattern Customization</li>
            </ul>
            <ButtonSecondary class="small" @click.native="formModal = !formModal">Contact us</ButtonSecondary>
          </div>
        </div>
      </div>
    </section>
    <ModalComponent :show-icon="false" :show="formModal" class="contact-form-modal" @close="formModal = !formModal">
      <template v-slot:title>Let's talk</template>
      <template v-slot:description>Fill in at least the required fields</template>
      <template v-slot:extra>
        <div class="enterprise-form">
          <InputField type="text" v-model="form.name">
            <template v-slot:top-label>Full name*</template>
          </InputField>
          <InputField type="text" v-model="form.email">
            <template v-slot:top-label>Email*</template>
          </InputField>
          <InputField type="text" v-model="form.subject">
            <template v-slot:top-label>Subject</template>
          </InputField>
          <InputField type="text" v-model="form.company">
            <template v-slot:top-label>Company</template>
          </InputField>
          <TextareaField v-model="form.message">
            <template v-slot:top-label>Message</template>
          </TextareaField>
          <div class="cancel">
            <ButtonSecondary class="small" @click.native="formModal = !formModal">Cancel</ButtonSecondary>
          </div>
          <div class="send">
            <ButtonPrimary
              :disabled="!formIsValid"
              class="small"
              @click.native="send()"
            >
              Send
            </ButtonPrimary>
          </div>
        </div>
      </template>
    </ModalComponent>
    <ModalComponent :show="formComplete" @close="formComplete = !formComplete">
      <template v-slot:title>Thank you!</template>
      <template v-slot:description>You message sent</template>
    </ModalComponent>
    <SeeInAction />
    <TheFooter />
  </div>
</template>

<script>
import { request } from '@/api'
import TheHeader from '@/components/Home/TheHeader.vue'
import ButtonsGroup from '@/components/Atoms/ButtonsGroup.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import InputField from '@/components/Atoms/InputField.vue'
import TextareaField from '@/components/Atoms/TextareaField.vue'
import SeeInAction from '@/components/Home/SeeInAction.vue'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  name: 'Pricing',
  components: {
    TheHeader,
    ButtonsGroup,
    ButtonSecondary,
    ButtonPrimary,
    ModalComponent,
    InputField,
    TextareaField,
    SeeInAction,
    TheFooter,
  },
  data: () => ({
    tab: 0,
    formModal: false,
    formComplete: false,
    form: {
      name: '',
      email: '',
      subject: '',
      company: '',
      message: '',
    },
  }),
  computed: {
    nameIsValid () {
      return this.form.name.length > 0
    },
    emailIsValid () {
      const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
      return emailRegExp.test(this.form.email)
    },
    formIsValid () {
      return this.nameIsValid && this.emailIsValid
    },
  },
  methods: {
    async send () {
      try {
        await request.post(
          '/user/contact-email?enterprise=1',
          JSON.stringify(this.form),
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          },
        )
        this.formModal = false
        this.formComplete = true
      } catch (e) {
        console.warn(e.message)
      }
    },
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
.pricing-page {
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    padding: 132px 80px 154px 80px;

    & > h2 {
      color: var(--Text-Text_Secondary);
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.18px;
      margin: 0;
      margin-bottom: 10px;
    }

    & > h1 {
      color: var(--Text-Text_Primary);
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.5px;
      margin: 0;
      margin-bottom: 25px;
    }

    .buttons-group {
      max-width: 322px;
      margin-bottom: 62px;
    }
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    row-gap: 25px;

    &.student-indie {
      justify-content: center;
      column-gap: 30px;
    }

    .column {
      width: 100%;
      max-width: 400px;
      padding: 25px;
      border-radius: 20px;
      border: 1px solid var(--Dividers);
      background: var(--Background);

      transition: background ease-in-out 300ms;

      &:hover {
        background: var(--Surface);

        button {
          background: var(--Primary-Shades-Primary);
          color: var(--Primary-Button-Text);
        }
      }

      &.custom {
        align-self: baseline;
        .description {
          margin: 73px 0 40px 0;
        }
      }

      h3 {
        color: var(--Text-Text_Primary);
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin: 0;
        margin-bottom: 5px;
      }
      p {
        color: #385052;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        margin-bottom: 20px;
      }

      .price {
        color: var(--Text-Text_Secondary);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;

        &.custom {
          color: var(--Text-Text_Primary);
          font-size: 18px;
          font-weight: 600;
          line-height: 150%;
          margin-bottom: 28px;
        }
        .old {
          font-size: 20px;
          color: #6F8789;
          font-style: normal;
          text-decoration: line-through;
        }

        span {
          color: var(--Text-Text_Primary);
          font-size: 30px;
          font-weight: 600;
          letter-spacing: -0.9px;
        }
      }

      ul {
        border-top: 1px solid var(--Dividers);
        margin-bottom: 30px;
        flex-grow: 1;

        li {
          margin-top: 15px;
          list-style: none;
          padding-left: 32px;

          background-position: left center;
          background-repeat: no-repeat;

          &.yes {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADASURBVHgB7ZNBDoIwEEVnBA16BYTgzhMoCzV6I28AN1N27Iw3QIR9lyagtTUxaUyJ1LKQpG/VZpr303YGwGD4eyzoACfcBkPXP9nelNTF9SzWBqAJlyN9HAAo292TzzqCBqKcYrW7pWnWWUAbOUf6ROPlej9ZbGLQlDcGcCjSSBaiIudIu6gu89T2fL6MR26AVXk5/iJvDHiFFHkihljeLFOVc75+shOuIqQYMy9hp4mKHKDFoL1vgoBzVbnB0BOeSy5vpwAuBxEAAAAASUVORK5CYII=)
          }

          &.no {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADHSURBVHgB7ZRBDoMgEEVnSDdt0/QWorfjBuIJmt7OcIomlXTHdGjKDhHQnb6ExMTvfJk/AHCwT6auU++21SX6SUoVeyeiXzgHgqjPMbGsQeceSHSHEj5N01spKWXii3uNZS3UkDJZXTxlUlIcIQNv4hA1r+EXGufDS1+NGWArwk5K2yJyhU4IjD0vccoR+Z6Htvz/SHMmcBtHDWuJBZozwtXFNzPJGcVqk5I5DyZzd1E0ZCJ6cZjqYswTFjjzWWATIESCg33yBTDnkVqdtxcHAAAAAElFTkSuQmCC)
          }

        }
      }
    }
  }
  .modal-component-overlay.contact-form-modal {
    .modal-component {
      width: 100%;
      max-width: 840px;

      .extra {
        max-width: 612px;
      }
    }
    .enterprise-form {
      display: grid;
      gap: 16px;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto auto;
      grid-template-areas:
        '. .'
        '. .'
        'msg msg'
        '. .';
    }
    .textarea-field {
      grid-area: msg;
    }
    .cancel {
      display: flex;
      justify-content: end;
    }

    .cancel .btn, .send .btn {
      width: 100%;
      max-width: 140px;
    }
  }
}
@media (max-width: 480px) {
  .pricing-page  {
    .container {
      padding: 122px 20px 63px 20px;

      & > h1 {
        font-size: 30px;
      }

      .buttons-group {
        max-width: 100%;
        margin-bottom: 40px;
      }
    }
    .row {
      flex-direction: column;
      row-gap: 40px;

      .column.custom {
        flex-direction: column;

        .description {
          max-width: 100%;
          margin: 0 0 20px 0;
        }

        button {
          width: 100%;
        }
      }
    }

    .buttons-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      button {
        border-radius: 8px;
      }
    }

    .modal-component-overlay {
      .enterprise-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .input-field {
        width: 100%;
      }

      .cancel, .send {
        width: calc(50% - 8px);
      }

      .cancel .btn, .send .btn {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
