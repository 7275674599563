<template>
  <div class="user-collection">
    <button class="collection-back-button" @click="$router.push('/user/collections')">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 20 20">
        <path d="M11.969 3.637 6.137 9.47a.75.75 0 0 0 0 1.06l5.832 5.832a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L7.727 10l5.302-5.303a.75.75 0 0 0 0-1.06.75.75 0 0 0-1.06 0z" color="#000" style="-inkscape-stroke:none"/>
      </svg>
      <span>Back to collections</span>
    </button>
    <TopPanel
      @search="userCollectionsStore.searchByDesigns($event, $route.params.id)"
      @sort-order="userCollectionsStore.setSortOrderDesigns($event, $route.params.id)"
      @sort-option="userCollectionsStore.sortDesigns($event, $route.params.id)"
      @create-new="createNewDesign"
      :sort-options="sortOptions"
    >
      <template v-slot:left-side>
        <h1 v-if="userCollectionsStore.oneCollection !== null">
          <span>{{ userCollectionsStore.oneCollection?.title }}</span>
          <ButtonIcon class="primary small" @click.native="$router.push('/')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
              <path d="M10 4.75a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25v4.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5.5a.75.75 0 0 0-.75-.75z"/>
            </svg>
          </ButtonIcon>
          <div class="created-at mobile" v-if="userCollectionsStore.oneCollection?.user !== userStore.data?.id">
            <span class="by">Created by</span>
            <span class="name">
              {{userCollectionsStore.oneCollection.user.firstName}} {{userCollectionsStore.oneCollection.user.lastName}}
            </span>
            <figure>
              <div :class="`userpic-placeholder col${userCollectionsStore.oneCollection.user._id % 10}`">
                <span>
                  {{userCollectionsStore.oneCollection.user.firstName?.charAt(0)}}{{userCollectionsStore.oneCollection.user.lastName?.charAt(0)}}
                </span>
              </div>
            </figure>
          </div>
        </h1>
      </template>
      <template v-slot:button-text>
        Create design
      </template>
    </TopPanel>
    <div class="created-at" v-if="userCollectionsStore.oneCollection?.user !== userStore.data?.id">
      <span class="by">Created by</span>
      <span class="name">
        {{userCollectionsStore.oneCollection.user.firstName}} {{userCollectionsStore.oneCollection.user.lastName}}
      </span>
      <figure>
        <div :class="`userpic-placeholder col${userCollectionsStore.oneCollection.user._id % 10}`">
          <span>
            {{userCollectionsStore.oneCollection.user.firstName?.charAt(0)}}{{userCollectionsStore.oneCollection.user.lastName?.charAt(0)}}
          </span>
        </div>
      </figure>
    </div>
    <EmptyGrid v-if="userCollectionsStore.designsCount === 0" @create-new="createNewDesign">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 33">
          <path d="M5.334 5.084c-1.142 0-2.084.94-2.084 2.082v18.668c0 1.142.942 2.082 2.084 2.082h21.332c1.142 0 2.084-.94 2.084-2.082v-16a2.096 2.096 0 0 0-2.084-2.084H16.312l-2.058-2.057c-.39-.39-.92-.609-1.473-.609H5.334zm0 1.5h7.447a.58.58 0 0 1 .412.17l.996.996H4.75v-.584c0-.331.253-.582.584-.582zM4.75 9.25h21.916c.331 0 .584.253.584.584v16a.572.572 0 0 1-.584.582H5.334a.572.572 0 0 1-.584-.582V9.25zm10.584 3.834a4.094 4.094 0 0 0-4.084 4.082 4.096 4.096 0 0 0 4.084 4.084c.853 0 1.648-.265 2.305-.717l1.83 1.83a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06l-1.832-1.832c.452-.658.719-1.451.719-2.305a4.092 4.092 0 0 0-4.082-4.082zm0 1.5a2.57 2.57 0 0 1 2.582 2.582 2.571 2.571 0 0 1-2.582 2.584 2.573 2.573 0 0 1-2.584-2.584 2.571 2.571 0 0 1 2.584-2.582z"/>
        </svg>
      </template>
      <template v-slot:title>There are no designs in this collection yet</template>
      <template v-slot:text>Add the first design in this collection</template>
      <template v-slot:button-text>Create New Design</template>
    </EmptyGrid>
    <template v-if="userCollectionsStore.designsCount > 0">
      <ElementsGrid>
        <OneDesign
          v-for="design in userCollectionsStore.designs"
          :key="design.id"
          :design="design"
          :collection-id="$route.params.id"
          @edit="editModal"
          @delete="delModal"
        />
      </ElementsGrid>
      <PaginationComponent
        :limit="userCollectionsStore.designsParams.limit"
        :offset="userCollectionsStore.designsParams.offset"
        :total="userCollectionsStore.designsCount"
        @setOffset="setOffset"
      />
    </template>
    <ModalComponent @close="userCollectionsStore.renameDesignModalToggle()" :show="userCollectionsStore.renameDesignModal" >
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 32 32">
          <path d="M21.334 5.14a2.08 2.08 0 0 0-1.475.606l-4.39 4.39L4.803 20.804a.75.75 0 0 0-.219.531v5.332a.75.75 0 0 0 .75.75h5.332a.75.75 0 0 0 .531-.219L21.863 16.53l4.39-4.388a2.096 2.096 0 0 0 0-2.948l-3.446-3.447a2.076 2.076 0 0 0-1.473-.605zm0 1.49c.148 0 .295.06.412.177l3.447 3.447a.574.574 0 0 1 0 .826l-3.859 3.86-4.271-4.272 3.859-3.861a.581.581 0 0 1 .412-.176zM16 11.73 20.273 16l-9.918 9.916H6.084v-4.271L16 11.729z"/>
        </svg>
      </template>
      <template v-slot:title>Rename design</template>
      <template v-slot:extra>
        <label class="edit-collection-field">
          <span>Design Name</span>
          <input type="text" placeholder="" v-model="userCollectionsStore.designName">
        </label>
      </template>
      <template v-slot:buttons>
        <ButtonSecondary class="small" @click.native="userCollectionsStore.renameDesignModalToggle()">Cancel</ButtonSecondary>
        <ButtonPrimary
          class="small"
          :disabled="userCollectionsStore.designName.length === 0"
          @click.native="userCollectionsStore.updateDesign()"
        >
          rename
        </ButtonPrimary>
      </template>
    </ModalComponent>
    <ModalComponent @close="userCollectionsStore.delDesignModalToggle()" type="error" :show="userCollectionsStore.delDesignModal" >
      <template v-slot:title>Delete design</template>
      <template v-slot:description>Are you sure you want to delete this design?</template>
      <template v-slot:buttons>
        <ButtonPrimary class="small" @click.native="userCollectionsStore.deleteDesign($route.params.id)">
          <template v-slot:icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 21 20">
              <path d="M8 1.75c-.866 0-1.584.718-1.584 1.584v.916H3.834a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h.916v10.916c0 .866.718 1.584 1.584 1.584h8.332c.866 0 1.584-.718 1.584-1.584V5.75h.916a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-2.582v-.916c0-.866-.718-1.584-1.584-1.584H8zm0 1.5h5c.055 0 .084.03.084.084v.916H7.916v-.916c0-.055.03-.084.084-.084zm-1.75 2.5h8.5v10.916c0 .055-.03.084-.084.084H6.334c-.055 0-.084-.03-.084-.084V5.75z" color="#000" style="-inkscape-stroke:none"/>
            </svg>
          </template>
          Delete
        </ButtonPrimary>
        <ButtonSecondary class="small" @click.native="userCollectionsStore.delDesignModalToggle()">Cancel</ButtonSecondary>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useUserCollectionsStore } from '@/store/pinia/userCollections'
import { useUserStore } from '@/store/pinia/user'

import ButtonIcon from '@/components/Atoms/ButtonIcon.vue'
import TopPanel from '@/components/UserCollections/TopPanel.vue'
import EmptyGrid from '@/components/Atoms/EmptyGrid.vue'
import ElementsGrid from '@/components/Atoms/ElementsGrid.vue'
import OneDesign from '@/components/UserCollections/OneDesign.vue'
import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import PaginationComponent from '@/components/Atoms/PaginationComponentLimit.vue'

export default {
  name: 'UserCollection',
  components: {
    ButtonIcon,
    TopPanel,
    EmptyGrid,
    ElementsGrid,
    OneDesign,
    ModalComponent,
    ButtonPrimary,
    ButtonSecondary,
    PaginationComponent,
  },
  computed: {
    ...mapStores(useUserCollectionsStore),
    ...mapStores(useUserStore),
  },
  data: () => ({
    sortOptions: [
      { name: 'Sort by', value: '' },
      { name: 'Name', value: 'title' },
      { name: 'Updated', value: 'updatedAt' },
    ],
  }),
  methods: {
    createNewDesign () {
      this.$router.push('/new-design')
    },
    delModal (payload) {
      this.userCollectionsStore.delDesignModalToggle()
      this.userCollectionsStore.designId = payload.id
    },
    editModal (payload) {
      this.userCollectionsStore.renameDesignModalToggle()
      this.userCollectionsStore.designId = payload.id
      this.userCollectionsStore.designName = payload.title
      this.userCollectionsStore.changeDesign = payload
    },
    async setOffset (offset) {
      this.userCollectionsStore.designsParams.offset = offset
      await this.userCollectionsStore.getDesigns(this.$route.params.id)
    },
  },
  async beforeMount () {
    await Promise.all([
      this.userCollectionsStore.getDesigns(this.$route.params.id),
      this.userCollectionsStore.getCollection(this.$route.params.id),
    ])
    this.userCollectionsStore.selectCollection(this.userCollectionsStore.oneCollection)
  },
}
</script>

<style lang="scss">
.user-collection {
  padding: 0 16px 40px 40px;

  .collection-back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: transparent;
    margin-bottom: 16px;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--Primary-Shades-Primary);
      margin-right: 4px;
    }
    span {
      color: var(--Primary-Shades-Primary);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }

  h1 {
    color: var(--text_color);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
      display: none;
    }
  }

  .created-at {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 40px;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    .by {
      color: var(--Text-Text_Secondary);
      margin-right: 12px;
    }
    .name {
      color: var(--Text-Text_Primary);
      margin-right: 4px;
    }
    figure {
      width: 26px;
      height: 26px;
      border-radius: 26px;
      overflow: hidden;
      margin: 0;
      .userpic-placeholder.col0 {
        background: var(--avatar-background-shades-1);
      }
      .userpic-placeholder.col1 {
        background: var(--avatar-background-shades-2);
      }
      .userpic-placeholder.col2 {
        background: var(--avatar-background-shades-3);
      }
      .userpic-placeholder.col3 {
        background: var(--avatar-background-shades-4);
      }
      .userpic-placeholder.col4 {
        background: var(--avatar-background-shades-5);
      }
      .userpic-placeholder.col5 {
        background: var(--avatar-background-shades-6);
      }
      .userpic-placeholder.col6 {
        background: var(--avatar-background-shades-7);
      }
      .userpic-placeholder.col7 {
        background: var(--avatar-background-shades-8);
      }
      .userpic-placeholder.col8 {
        background: var(--avatar-background-shades-9);
      }
      .userpic-placeholder.col9 {
        background: var(--avatar-background-shades-10);
      }
      .userpic-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: var(--Text-Text_White);
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &.mobile {
      display: none;
      margin: 0;
    }
  }
}

@media (max-width: 760px) {
  .user-collection {
    padding: 0 0 40px 0 !important;

    h1 {
      font-size: 24px !important;
      button {
        display: block;
      }
    }

    .created-at {
        display: none;
      &.mobile {
        display: flex;
      }
    }
  }
}
</style>
