<template>
  <div class="my_team">
    <h1>
      <span>My team</span>
      <ButtonPrimary
        class="small add-desktop"
        @click.native="addNewUser = true"
        :disabled="demoMode || addNewUser"
      >
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
            <path d="M10 4.75a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25v4.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5.5a.75.75 0 0 0-.75-.75z"/>
          </svg>
        </template>
        <slot name="button-text">Add member</slot>
      </ButtonPrimary>
      <ButtonIcon class="primary small" @click.native="addNewUser = true">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
          <path d="M10 4.75a.75.75 0 0 0-.75.75v4.25H5a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h4.25v4.25a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-4.25H15a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-4.25V5.5a.75.75 0 0 0-.75-.75z"/>
        </svg>
      </ButtonIcon>
    </h1>
    <!-- <TopPanel
      @search="teamStore.search"
      @sort-order="teamStore.setSortOrder"
      @sort-option="teamStore.sort"
      @create-new="addNewUser = true"
      :add-new-button-disabled="demoMode || addNewUser"
      :sort-options="sortOptions"
    >
      <template v-slot:left-side>
        <h1>My team</h1>
      </template>
      <template v-slot:button-text>Add member</template>
    </TopPanel> -->
    <div class="elements">
      <div class="elements__row elements__title">
        <p>Name</p>
        <p>Email</p>
        <p>Phone number</p>
      </div>
      <div v-if="addNewUser" class="elements__row add_user">
        <div class="add_user__name">
          <p class="mob_title">Name</p>
          <div class="inputs">
            <InputField
              v-model="v$.firstName.$model"
              placeholder="First name *"
              :error="v$.firstName.$error"
            >
              <template v-slot:end-icon v-if="v$.firstName.$error">
                <div class="error_icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 21 20">
                    <path d="M10.7 18.3c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3zm0-15.1c-3.8 0-6.8 3.1-6.8 6.8s3.1 6.8 6.8 6.8c3.8 0 6.8-3.1 6.8-6.8s-3.1-6.8-6.8-6.8z"/>
                    <path d="M10.7 11.6c-.4 0-.8-.3-.8-.8v-5c0-.4.3-.8.8-.8.4 0 .7.3.7.8v5c0 .4-.3.8-.7.8zm0 2.5c-.1 0-.2 0-.3-.1s-.2-.1-.2-.2c-.1-.1-.1-.2-.2-.2v-.3c0-.2.1-.4.2-.5.3-.3.8-.3 1.1 0 .1.1.2.3.2.5s-.1.4-.2.5c-.2.1-.3.2-.4.2 0 .1-.1.1-.2.1z"/>
                  </svg>
                  <div class="error">
                    First name should include 1-50 symbols
                  </div>
                </div>
              </template>
            </InputField>
            <InputField
              v-model="v$.lastName.$model"
              placeholder="Last name *"
              :error="v$.lastName.$error"
            >
              <template v-slot:end-icon v-if="v$.lastName.$error">
                <div class="error_icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 21 20">
                    <path d="M10.7 18.3c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3zm0-15.1c-3.8 0-6.8 3.1-6.8 6.8s3.1 6.8 6.8 6.8c3.8 0 6.8-3.1 6.8-6.8s-3.1-6.8-6.8-6.8z"/>
                    <path d="M10.7 11.6c-.4 0-.8-.3-.8-.8v-5c0-.4.3-.8.8-.8.4 0 .7.3.7.8v5c0 .4-.3.8-.7.8zm0 2.5c-.1 0-.2 0-.3-.1s-.2-.1-.2-.2c-.1-.1-.1-.2-.2-.2v-.3c0-.2.1-.4.2-.5.3-.3.8-.3 1.1 0 .1.1.2.3.2.5s-.1.4-.2.5c-.2.1-.3.2-.4.2 0 .1-.1.1-.2.1z"/>
                  </svg>
                  <div class="error">
                    Last name should include 1-50 symbols
                  </div>
                </div>
              </template>
            </InputField>
          </div>
        </div>
        <div class="add_user__email">
          <p class="mob_title">Email</p>
          <InputField
            v-model="v$.email.$model"
            placeholder="Enter email *"
            :error="v$.email.$error || allEmails.includes(v$.email.$model)"
          >
            <template v-slot:end-icon v-if="v$.email.$error || allEmails.includes(v$.email.$model)">
              <div class="error_icon">
                <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 21 20">
                  <path d="M10.7 18.3c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3zm0-15.1c-3.8 0-6.8 3.1-6.8 6.8s3.1 6.8 6.8 6.8c3.8 0 6.8-3.1 6.8-6.8s-3.1-6.8-6.8-6.8z"/>
                  <path d="M10.7 11.6c-.4 0-.8-.3-.8-.8v-5c0-.4.3-.8.8-.8.4 0 .7.3.7.8v5c0 .4-.3.8-.7.8zm0 2.5c-.1 0-.2 0-.3-.1s-.2-.1-.2-.2c-.1-.1-.1-.2-.2-.2v-.3c0-.2.1-.4.2-.5.3-.3.8-.3 1.1 0 .1.1.2.3.2.5s-.1.4-.2.5c-.2.1-.3.2-.4.2 0 .1-.1.1-.2.1z"/>
                </svg>
                <div class="error">
                  <span v-if="allEmails.includes(v$.email.$model)">This member has already been added</span>
                  <span v-else>An E-mail should be in the correct format: name@example.com</span>
                </div>
              </div>
            </template>
          </InputField>
        </div>
        <div class="add_user__phone">
          <p class="mob_title">Phone number</p>
          <div class="input-block">
            <vue-tel-input
              :class="
                'valid' in phoneWithCountry && !phoneWithCountry.valid
                ? 'error-phone-input'
                : focusedPhone ? 'focused-phone' : ''"
              v-model="phone"
              @focus="focusedPhone = true"
              @validate="validatePhone"
            />
            <div class="error_icon" v-if="'valid' in phoneWithCountry && !phoneWithCountry.valid">
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 21 20">
                <path d="M10.7 18.3c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3zm0-15.1c-3.8 0-6.8 3.1-6.8 6.8s3.1 6.8 6.8 6.8c3.8 0 6.8-3.1 6.8-6.8s-3.1-6.8-6.8-6.8z"/>
                <path d="M10.7 11.6c-.4 0-.8-.3-.8-.8v-5c0-.4.3-.8.8-.8.4 0 .7.3.7.8v5c0 .4-.3.8-.7.8zm0 2.5c-.1 0-.2 0-.3-.1s-.2-.1-.2-.2c-.1-.1-.1-.2-.2-.2v-.3c0-.2.1-.4.2-.5.3-.3.8-.3 1.1 0 .1.1.2.3.2.5s-.1.4-.2.5c-.2.1-.3.2-.4.2 0 .1-.1.1-.2.1z"/>
              </svg>
              <div class="error">
                A Phone Number should be in the correct format
              </div>
            </div>
          </div>
        </div>
        <div class="add_user__buttons">
          <ButtonSecondary class="small" @click.native="addNewUser=false">Cancel</ButtonSecondary>
          <ButtonPrimary :disabled="this.v$.$invalid" class="small" @click.native="addUser()">Add</ButtonPrimary>
        </div>
      </div>
      <div v-for="(item, index) in teamStore.team" :key="item">
        <teamRow :item="item" :class="index === teamStore.team.length - 1 ? 'last' : ''" />
      </div>
    </div>
    <EmptyGrid v-if="demoMode" :buttonPlusIcon="false" @create-new="$router.push({ name: 'user_billing' })">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32">
          <path d="M20 26.1c-.4 0-.8-.3-.8-.8 0-2.5-3.3-4.6-7.2-4.6s-7.2 2.1-7.2 4.6c0 .4-.3.8-.8.8s-.8-.3-.8-.8c0-3.4 3.9-6.1 8.8-6.1s8.8 2.7 8.8 6.1c0 .4-.4.8-.8.8zm2.7-6.7c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1l5.3-5.3c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-5.3 5.3c-.3.1-.4.2-.6.2z"/>
          <path d="M28 19.4c-.2 0-.4-.1-.5-.2l-5.3-5.3c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l5.3 5.3c.3.3.3.8 0 1.1-.2.1-.4.2-.6.2zm-16-2.6c-3.4 0-6.1-2.7-6.1-6.1 0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1 0 3.3-2.7 6.1-6.1 6.1zm0-10.7c-2.5 0-4.6 2.1-4.6 4.6 0 2.5 2.1 4.6 4.6 4.6 2.5 0 4.6-2.1 4.6-4.6 0-2.6-2.1-4.6-4.6-4.6z"/>
        </svg>
      </template>
      <template v-slot:title>My team are not available for your subscription</template>
      <template v-slot:text>Please, upgrade your account</template>
      <template v-slot:button-text>Upgrade account</template>
    </EmptyGrid>
    <EmptyGrid @create-new="addNewUser = true" v-else-if="teamStore.team?.length === 0">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32">
          <path d="M20 26.1c-.4 0-.8-.3-.8-.8 0-2.5-3.3-4.6-7.2-4.6s-7.2 2.1-7.2 4.6c0 .4-.3.8-.8.8s-.8-.3-.8-.8c0-3.4 3.9-6.1 8.8-6.1s8.8 2.7 8.8 6.1c0 .4-.4.8-.8.8zm2.7-6.7c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1l5.3-5.3c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-5.3 5.3c-.3.1-.4.2-.6.2z"/>
          <path d="M28 19.4c-.2 0-.4-.1-.5-.2l-5.3-5.3c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l5.3 5.3c.3.3.3.8 0 1.1-.2.1-.4.2-.6.2zm-16-2.6c-3.4 0-6.1-2.7-6.1-6.1 0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1 0 3.3-2.7 6.1-6.1 6.1zm0-10.7c-2.5 0-4.6 2.1-4.6 4.6 0 2.5 2.1 4.6 4.6 4.6 2.5 0 4.6-2.1 4.6-4.6 0-2.6-2.1-4.6-4.6-4.6z"/>
        </svg>
      </template>
      <template v-slot:title>Your team is empty</template>
      <template v-slot:text>Send an invitation on email by clicking the button below.</template>
      <template v-slot:button-text>Add team member</template>
    </EmptyGrid>
    <modal-component :show="isSended" :showIcon="false" @close="isSended = false">
      <template v-slot:title>We have sent an invitation on {{ email }}</template>
      <template v-slot:buttons>
        <ButtonSecondary class="small one" @click.native="isSended = false">Okay</ButtonSecondary>
      </template>
    </modal-component>
    <modal-component :show="emailError" type="error" @close="emailError = false">
      <template v-slot:title>This user has already been added to PatternFast</template>
      <template v-slot:description>Contact your team member to resolve this issue</template>
      <template v-slot:buttons>
        <ButtonPrimary class="small one" style="width:fit-content" @click.native="emailError = false">Okay</ButtonPrimary>
      </template>
    </modal-component>
    <modal-component :show="globalError" type="error" @close="globalError = false">
      <template v-slot:title>Something went wrong, <br> try again</template>
    </modal-component>
    <modal-component :show="teamCountError" @close="teamCountError = false">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32">
          <path d="M21.3 23.4c-.2 0-.4-.1-.5-.2L16 18.4l-4.8 4.8c-.3.3-.8.3-1.1 0s-.3-.8 0-1.1l5.3-5.3c.3-.3.8-.3 1.1 0l5.3 5.3c.3.3.3.8 0 1.1-.1.1-.3.2-.5.2zm-10.6-8c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1l5.3-5.3c.3-.3.8-.3 1.1 0l5.3 5.3c.3.3.3.8 0 1.1s-.8.3-1.1 0L16 10.4l-4.8 4.8c-.1.1-.3.2-.5.2z"/>
        </svg>
      </template>
      <template v-slot:title>Upgrade your subscription
        {{ isGrowingPlan ? '' : 'to Manufacture' }}
      </template>
      <template v-slot:description>To invite more people to your team, upgrade your account</template>
      <template v-slot:extra v-if="!isGrowingPlan">
        <div class="teamCount_info">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24" width="24">
            <path fill="#09373d" d="M10.2 17c-.2 0-.4-.1-.5-.2l-4.2-4.2c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l3.7 3.7 8-8c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-8.5 8.5c-.3.1-.5.2-.7.2z"/>
          </svg>
          <p>Unlimited commercial licence</p>
        </div>
        <div class="teamCount_info">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24" width="24">
            <path fill="#09373d" d="M10.2 17c-.2 0-.4-.1-.5-.2l-4.2-4.2c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l3.7 3.7 8-8c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-8.5 8.5c-.3.1-.5.2-.7.2z"/>
          </svg>
          <p>Up to 5 users</p>
        </div>
        <ButtonPrimary class="small" @click.native="$router.push({ name: 'user_billing' })">Upgrade to Manufacture</ButtonPrimary>
        <ButtonSecondary class="small" @click.native="teamCountError = false">Cancel</ButtonSecondary>
      </template>
      <template v-slot:buttons v-else>
        <ButtonPrimary class="small" @click.native="$router.push({ name: 'user_billing' })">Upgrade</ButtonPrimary>
        <ButtonSecondary class="small" @click.native="teamCountError = false">Cancel</ButtonSecondary>
      </template>
    </modal-component>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useTeamStore } from '@/store/pinia/userTeam'
import { useUserStore } from '@/store/pinia/user'
import ButtonIcon from '@/components/Atoms/ButtonIcon.vue'
// import TopPanel from '@/components/UserCollections/TopPanel.vue'
import EmptyGrid from '@/components/Atoms/EmptyGrid.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import { mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required, email } from '@vuelidate/validators'
import InputField from '@/components/Atoms/InputField.vue'
import ModalComponent from '@/components/Atoms/ModalComponent'
import teamRow from '@/components/userTeam/teamRow'

const validateEmail = (email) => {
  const emailParts = email.split('@')
  const username = emailParts[0]
  // Проверка имени пользователя
  const usernameRegex = /^[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9]$/
  if (!usernameRegex.test(username)) {
    return false
  }
  return true
}

export default {
  name: 'UserCollections',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    ButtonIcon,
    // TopPanel,
    EmptyGrid,
    ButtonPrimary,
    ButtonSecondary,
    InputField,
    ModalComponent,
    teamRow,
  },
  computed: {
    isGrowingPlan () {
      return this.userStore.data.company.plan === 'GROWING'
    },
    ...mapStores(useTeamStore),
    ...mapStores(useUserStore),
    ...mapGetters('user', ['demoMode']),
    allEmails () {
      return this.teamStore.team.map(el => el.email)
    },
  },
  data: () => ({
    addNewUser: false,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    focusedPhone: false,
    phoneWithCountry: {},
    isSended: false,
    globalError: false,
    emailError: false,
    teamCountError: false,
    // sortOptions: [
    //   { name: 'Sort by', value: '' },
    //   { name: 'Name', value: 'name' },
    //   { name: 'Created', value: 'created' },
    // ],
  }),
  methods: {
    async addUser () {
      if (this.userStore.data.company.plan === 'STARTUP' && this.teamStore.team.length >= 3) {
        this.teamCountError = true
        return
      }
      if (this.isGrowingPlan && this.teamStore.team.length >= 5) {
        this.teamCountError = true
        return
      }
      if (!this.v$.$invalid) {
        try {
          this.globalError = false
          let data = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
          }
          if (this.phoneWithCountry?.number) data.phoneNumber = this.phoneWithCountry?.number
          if (this.phoneWithCountry?.countryCode) data.countryCode = this.phoneWithCountry?.countryCode
          await this.teamStore.addUser(data)
          this.addNewUser = false
          this.isSended = true
        } catch (e) {
          if (e.message.includes('duplicate key error collection: admin.users')) {
            this.emailError = true
          } else this.globalError = true
        }
      }
    },
    validatePhone (val) {
      this.phoneWithCountry = val
    },
  },
  validations () {
    return {
      firstName: {
        required,
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
        maxLength: maxLength(320),
        validateEmail,
      },
    }
  },
  async beforeMount () {
    await this.teamStore.getList()
  },
}
</script>

<style lang="scss">
.my_team {
  padding: 0 16px 40px 40px;
  .extra {
    .teamCount_info {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
      p {
        color: var(--Text-Text_Primary);
        font-size: 14px;
      }
    }
    button {
      &:first-child {
        margin-top: 24px;
      }
      width: 100%;
      margin-top: 24px;
      &:last-child {
        margin-top: 16px;
      }
    }
  }

  .elements {
    border-radius: 12px;
    border: 1px solid var(--Dividers);
    margin-top: 30px;
    &__row.last {
      border-bottom: none;
    }
    &__row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 176px;
      border-bottom: 1px solid var(--Dividers);
      .mob_title {
        display: none;
      }
      p {
        color: var(--Text-Text_Primary);
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
        padding: 15px 16px;
      }
      .menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        &__button {
          padding: 16px;
          cursor: pointer;
          height: 56px;
        }
        &__items {
          border-radius: 8px;
          border: 1px solid var(--Dividers);
          position: absolute;
          background: var(--Basic-White);
          box-shadow: 0px 6px 8px 0px rgba(146, 158, 159, 0.10);
          z-index: 1;
          font-size: 16px;
          right: 16px;
          top: calc(100% - 8px);
          width: 200px;
          &__item {
            border-radius: 8px;
            padding: 8px 16px;
            width: max-content;
            min-width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
              margin-right: 16px;
            }
            &:hover {
              background: var(--Surface-Hover);
            }
          }
        }
      }
      .name_info {
        display: flex;
        align-items: center;
        padding: 15px 16px;
        gap: 8px;
        p {
          padding: 0;
        }
        .tag {
          border-radius: 40px;
          background: var(--Surface-Hover);
          padding: 4px 16px;
          font-size: 12px;
          color: var(--Text-Text_Secondary);
        }
        figure {
          width: 26px;
          height: 26px;
          border-radius: 26px;
          overflow: hidden;
          margin: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .userpic-placeholder.col0 {
            background: var(--avatar-background-shades-1);
          }
          .userpic-placeholder.col1 {
            background: var(--avatar-background-shades-2);
          }
          .userpic-placeholder.col2 {
            background: var(--avatar-background-shades-3);
          }
          .userpic-placeholder.col3 {
            background: var(--avatar-background-shades-4);
          }
          .userpic-placeholder.col4 {
            background: var(--avatar-background-shades-5);
          }
          .userpic-placeholder.col5 {
            background: var(--avatar-background-shades-6);
          }
          .userpic-placeholder.col6 {
            background: var(--avatar-background-shades-7);
          }
          .userpic-placeholder.col7 {
            background: var(--avatar-background-shades-8);
          }
          .userpic-placeholder.col8 {
            background: var(--avatar-background-shades-9);
          }
          .userpic-placeholder.col9 {
            background: var(--avatar-background-shades-10);
          }
          .userpic-placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: var(--Text-Text_White);
            font-family: "Open Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    &__row.change {
      .info_text {
        color: var(--Button-States-Disabled_Txt);
      }
    }
    .error_icon {
      position: relative;
      svg {
        cursor: pointer;
        background: white;
      }
      .error {
        display: none;
        font-size: 12px;
        top: calc(100% + 13px);
        background-color: #09373D;
        color: #fff;
        text-align: center;
        border-radius: 8px;
        padding: 4px 12px;
        position: absolute;
        z-index: 1;
        transform: translateX(-50%);
        width: max-content;
        left: 50%;
        transition: opacity 0.3s;
        &::after {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #09373D transparent;
        }
      }
      &:hover {
        .error {
          display: block;
        }
      }
    }
    .input-block {
      position: relative;
      padding: 8px;
      margin: 0;
      svg {
        fill: var(--System-Error)
      }
      .error_icon {
        position: absolute;
        top: 50%;
        right: 12px;
        width: 21px;
        height: 20px;
        transform: translate(0, -50%);
        .error {
          top: calc(119%);
        }
      }
    }
    .add_user {
      &__email {
        padding: 8px;
      }
      &__name {
        .inputs {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 8px;
          padding: 8px;
        }
      }
      &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 8px;
        gap: 16px;
      }
    }
    &__title p {
      font-weight: 600;
      padding: 16px;
    }
  }
  .empty-grid {
    border: none;
  }
  h1 {
    color: var(--text_color);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 32px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      display: none;
    }
  }
  // h1.mobile {
  //   display: none;
  //   margin-bottom: 32px;
  // }
}
@media (max-width: 1280px) {
  .my_team {
    .elements {
      border: none;
      .mob_title {
        display: block;
        font-weight: 600;
      }
      &__title {
        display: none;
      }
      .add_user {
        grid-template-columns: 1fr;
        .mob_title {
          border-bottom: 1px solid var(--Dividers);
        }
        &__buttons {
          padding: 16px;
          button { width: 100%; }
        }
        &__email {
          padding: 0;
          .input-field {
            padding: 8px;
            border-bottom: 1px solid var(--Dividers);
          }
        }
        &__phone .input-block {
          border-bottom: 1px solid var(--Dividers);
        }
        &__name {
          .inputs {
            border-bottom: 1px solid var(--Dividers);
          }
        }
      }
      &__row {
        grid-template-columns: 1fr 1fr;
        border-radius: 12px;
        border: 1px solid var(--Dividers, #E0E9EA);
        margin-bottom: 16px;
        &:last-child {
          border: 1px solid var(--Dividers, #E0E9EA);
        }
        .name_info {
          order: 1;
          border-bottom: 1px solid var(--Dividers);
        }
        .menu {
          order: 2;
          border-bottom: 1px solid var(--Dividers);
        }
        .email_info {
          order: 3;
          border-right: 1px solid var(--Dividers);
          grid-column: 1/3;
          display: grid;
          grid-template-columns: 1fr 1fr;
          border-bottom: 1px solid var(--Dividers);
        }
        .phone_info {
          order: 4;
          grid-column: 1/3;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}
@media (max-width: 760px) {
  .my_team {
    padding: 0 0 40px 0 !important;
    .left-side h1 {
      display: none;
    }
    h1 {
      font-size: 24px !important;
      button {
        display: block;
      }
      .add-desktop {
        display: none;
      }
    }
    // h1.mobile {
    //   display: flex;
    // }
  }
}
</style>
