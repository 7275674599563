import Fraction from 'fraction.js'

class Measurement {
  static CM = 'cm'
  static INCH = 'in'

  static DEFAULT_UNIT = 'in'
  static DEFAULT_BASE = 10

  static INCH_MULTIPLIER = 1 / 2.54

  static MAX_DENOMINATOR = 8

  _innerUsedUnit = Measurement.CM

  value = undefined

  constructor (value, unit = this._innerUsedUnit) {
    this.setValue(value, unit)
  }

  setValue (value, unit = this._innerUsedUnit) {
    Measurement.checkUnit(unit)

    if (value === undefined || value === '') {
      this.value = undefined
      return
    }
    this.value = Number.parseFloat(value) / this.getMultiplier(unit)
  }

  getValue (unit = this._innerUsedUnit, round = true) {
    Measurement.checkUnit(unit)

    if (this.value === undefined) {
      return undefined
    }

    if (!round) {
      return this.value * this.getMultiplier(unit)
    }

    return Math.floor(this.value * this.getMultiplier(unit) * 100) / 100
  }

  getMultiplier (unit) {
    Measurement.checkUnit(unit)
    if (unit === this._innerUsedUnit) {
      return 1
    }
    if (unit === Measurement.INCH) {
      return Measurement.INCH_MULTIPLIER
    }
  }

  static checkUnit (unit) {
    let availableUnits = [Measurement.CM, Measurement.INCH]
    if (availableUnits.includes(unit)) {
      return true
    }
    throw new Error('Invalid unit')
  }

  static fractionsToDecimals (value) {
    try {
      const fraction = new Fraction(value)
      return fraction.toString()
    } catch {
      return undefined
    }
  }

  static decimalsToFractions (value) {
    const maxD = Measurement.MAX_DENOMINATOR
    try {
      const fraction = new Fraction(value)
      const roundedFraction = new Fraction(Math.round(maxD * fraction.valueOf()), maxD)
      return roundedFraction.toFraction(true)
    } catch {
      return undefined
    }
  }

  static cmToIn (value) { return parseFloat((value / 2.54).toFixed(2)) }
  static inToCm (value) { return parseFloat((value * 2.54).toFixed(2)) }

  static applyFractionBase (value, base = 10, useFloor = false) {
    if (base === 10) {
      return value
    }
    let isNegative = value < 0
    value = Math.abs(Math.round(value * 1000) / 1000)

    let whole = Math.floor(value)
    let decimal = Math.abs(value - whole)
    let rationalOct = useFloor ? Math.floor(decimal * 8) : Math.round(decimal * 8)
    if (rationalOct === 0) {
      return (isNegative ? '-' : '') + String(whole)
    }
    if (rationalOct === 8) {
      return (isNegative ? '-' : '') + String(whole + 1)
    }

    let result = ''
    if (whole > 0) {
      result += String(whole) + ' '
    }
    return (isNegative ? '-' : '') + result + String(rationalOct) + '/8'
  }
}

export { Measurement }
