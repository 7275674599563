<template>
  <layout-with-header>
    <div class="joinTeam" v-if="!teamStore.checkTokenError && !teamStore.checkTokenLoading">
      <p class="title">Welcome,
        {{ teamStore.checkTokenData.firstName ? teamStore.checkTokenData.firstName : $route.query.firstName }}
        {{ teamStore.checkTokenData.lastName ? teamStore.checkTokenData.lastName : $route.query.lastName }}!</p>
      <p class="subtitle">Please create a password for your account</p>
      <div class="input-block">
        <p class="label">Password</p>
        <div class="password">
          <svg v-if="isShowPass" @click="isShowPass = false" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25_1587)">
              <path d="M8.3335 9.99992C8.3335 10.4419 8.50909 10.8659 8.82165 11.1784C9.13421 11.491 9.55814 11.6666 10.0002 11.6666C10.4422 11.6666 10.8661 11.491 11.1787 11.1784C11.4912 10.8659 11.6668 10.4419 11.6668 9.99992C11.6668 9.55789 11.4912 9.13397 11.1787 8.82141C10.8661 8.50885 10.4422 8.33325 10.0002 8.33325C9.55814 8.33325 9.13421 8.50885 8.82165 8.82141C8.50909 9.13397 8.3335 9.55789 8.3335 9.99992Z" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.5 10C15.5 13.3333 13 15 10 15C7 15 4.5 13.3333 2.5 10C4.5 6.66667 7 5 10 5C13 5 15.5 6.66667 17.5 10Z" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_25_1587">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <svg v-else @click="isShowPass = true" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25_1588)">
              <path d="M8.82097 8.82251C8.50845 9.13513 8.33293 9.5591 8.33301 10.0011C8.33309 10.4432 8.50876 10.8671 8.82138 11.1796C9.13401 11.4921 9.55797 11.6676 10 11.6676C10.4421 11.6675 10.866 11.4918 11.1785 11.1792" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.9008 13.8942C12.7319 14.6256 11.3789 15.0091 10 15C7 15 4.5 13.3334 2.5 10C3.56 8.23336 4.76 6.93503 6.1 6.10503M8.48333 5.15002C8.98253 5.04897 9.49068 4.99871 10 5.00003C13 5.00003 15.5 6.66669 17.5 10C16.945 10.925 16.3508 11.7225 15.7183 12.3917" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.5 2.5L17.5 17.5" stroke="#1D1F29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_25_1588">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <q-input
            v-model="v$.password.$model"
            outlined
            :type="isShowPass ? 'text' : 'password'"
            :class="!validPassword && v$.password.$dirty ? 'error-input' : ''"
            placeholder="Create Password"
          />
        </div>
        <div class="pass_valid" v-if="v$.password.$dirty">
          <p class="title_valid">Password must:</p>
          <div class="check" v-for="item in validPass" :key="item.message">
            <label :class="'checkbox ' + (item.error ? 'selected' : 'error-check')">
              <input type="checkbox" disabled />
              <svg v-if="item.error" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </label>
            <p>{{item.message}}</p>
          </div>
        </div>
      </div>
      <div class="check">
        <label :class="`checkbox ${remember ? 'selected' : ''}`">
          <input
            v-model="remember"
            type="checkbox"
          />
          <svg v-if="remember" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </label>
        <p>Remember me</p>
      </div>
      <div class="check">
        <label :class="`checkbox ${checked ? 'selected' : ''}`">
          <input
            v-model="checked"
            type="checkbox"
          />
          <svg v-if="checked" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3L3 5L7 1" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </label>
        <p>I agree with PatternFast’s <a class="bold-link" href="/terms-and-conditions" target="_blank">Terms of Service.</a></p>
      </div>
      <button :disabled="!validPassword || !checked" class="green_button" style="width:100%" @click.prevent="createAccount">
        Sign in
      </button>
    </div>
    <modal-component :show="globalError" type="error" @close="globalError = false">
      <template v-slot:title>Something went wrong, <br> try again</template>
    </modal-component>
    <modal-component :show="teamStore.checkTokenError" :closeButton="false" :showIcon="false">
      <template v-slot:title>Unfortunately, the link is no longer active</template>
      <template v-slot:description>Contact your administrator for assistance</template>
      <template v-slot:buttons>
        <ButtonSecondary class="small one" @click.native="$router.push('/')">Okay</ButtonSecondary>
      </template>
    </modal-component>
  </layout-with-header>
</template>

<script>
import { mapStores } from 'pinia'
import { useTeamStore } from '@/store/pinia/userTeam'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import LayoutWithHeader from '@/layouts/LayoutWithHeader'
import ModalComponent from '@/components/Atoms/ModalComponent'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

export default {
  name: 'JoinTeamPage',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    LayoutWithHeader,
    ModalComponent,
    ButtonSecondary,
  },
  data: () => ({
    isShowPass: false,
    checked: null,
    password: null,
    remember: false,
    globalError: false,
  }),
  validations () {
    return {
      password: {
        required,
      },
    }
  },
  computed: {
    ...mapStores(useTeamStore),
    validPass () {
      return {
        passLength: {
          message: 'Contain 8 to 30 characters',
          error: this.password?.length > 8 && this.password?.length < 30,
        },
        upperLowerCase: {
          message: 'Contain both lower and uppercase letters',
          error: /[a-z]/.test(this.password) && /[A-Z]/.test(this.password),
        },
        num: {
          message: 'Contain 1 number',
          error: /\d/.test(this.password),
        },
        spec: {
          message: "Contain 1 special charachter +!'$@%&/()=`#",
          error: /[+!'$@%&/()=`#]/.test(this.password),
        },
      }
    },
    validPassword () {
      const valid = this.validPass
      return valid.passLength.error && valid.upperLowerCase.error && valid.num.error && valid.spec.error
    },
  },
  methods: {
    async createAccount () {
      if (this.validPassword && this.checked) {
        try {
          await this.teamStore.createAccount(this.password, this.$route.params.token)
          this.$router.push('/')
        } catch (e) {
          this.globalError = true
        }
      }
    },
  },
  async beforeMount () {
    await this.teamStore.checkToken(this.$route.params.token)
  },
}
</script>

<style lang="scss" scoped>
.joinTeam {
  max-width: 442px;
  border-radius: 20px;
  padding: 48px;
  background-color: white;
  margin: 128px auto;
  .title {
    margin-bottom: 4px !important;
  }
  .subtitle {
    color: var(--Text-Text_Secondary);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 32px;
  }
  .input-block {
    margin-bottom: 32px;
  }
  .bold-link {
    font-weight: 600;
  }
}
</style>
