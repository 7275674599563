import { render, staticRenderFns } from "./PaginationComponentLimit.vue?vue&type=template&id=04e1aa22"
import script from "./PaginationComponentLimit.vue?vue&type=script&lang=js"
export * from "./PaginationComponentLimit.vue?vue&type=script&lang=js"
import style0 from "./PaginationComponentLimit.vue?vue&type=style&index=0&id=04e1aa22&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports