<template>
  <button class="icon-button">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'buttonIcon',
}
</script>

<style lang="scss">
  .icon-button {
    width: 56px;
    height: 56px;
    padding: 18px;
    border-radius: 8px;

    svg {
      display: block;
      width: 20px;
      height: 20px;
    }

    &.small {
      width: 40px;
      height: 40px;
      padding: 10px;
    }
  }

  .icon-button.primary {
    background: var(--Primary-Shades-Primary);
    border: none;

    svg {
      fill: var(--Primary-Button-Text);
    }

    &:hover {
      background: var(--Button-States-Hover);
    }

    &:active {
      background: var(--Button-States-Pressed);
    }

    &:disabled {
      background: var(--Button-States-Disabled_Surface);
      color: var(--Button-States-Disabled_Txt);

      svg {
        fill: var(--Button-States-Disabled_Txt);
      }
    }
  }

  .icon-button.secondary {
    background: var(--Basic-White);
    border: 1px solid var(--Secondary-Button-Stroke);

    svg {
      fill: var(--Primary-Shades-Primary);
    }

    &:hover {
      border-color: var(--primary);
    }

    &:active {
      border-color: var(--primary);
      background: var(--Surface);
    }

    &:disabled {
      background: var(--Basic-White);
      color: var(--Button-States-Disabled_Txt);
      border-color: var(--Secondary-Button-Stroke);

      svg {
        fill: var(--Button-States-Disabled_Txt);
      }
    }
  }
</style>
