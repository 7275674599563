<template>
  <div class="terms">
    <TheHeader :scrollY="0" />
    <section>
      <div class="container">
        <h1>Terms and Conditions</h1>
        <p>Effective Date: February 12, 2025</p>
        <h3>1. Introduction and Definitions</h3>
        <p>These Terms and Conditions ("Terms") govern your access to and use of PatternFast's services, software, and website (collectively, the "Services") provided by PatternFast, Inc. ("PatternFast," "we," "us," or "our"), a corporation registered at 1900 South Norfolk Street, Suite 350, San Mateo, CA 94403 (Tax ID: 462555212).</p>
        <h3>2. Contract Formation</h3>
        <h4>2.1 Agreement to Terms</h4>
        <p>By accessing or using our Services, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Services.</p>
        <h4>2.2 Contract Establishment</h4>
        <p>A binding contract ("Contract") is formed when you:</p>
        <ul>
          <li>Complete the online registration form ("Registration")</li>
          <li>Click "accept" or similar affirmative action</li>
          <li>Receive a confirmation email from us</li>
          <li>Begin using the Services</li>
        </ul>
        <p>whichever occurs first.</p>
        <h4>2.3 Complete Agreement</h4>
        <p>These Terms constitute the entire agreement between you and PatternFast. Any additional terms proposed by you will only apply if explicitly accepted by us in writing.</p>
        <h3>3. Service Subscription and Billing</h3>
        <h4>3.1 Subscription Plans</h4>
        <p>We offer both monthly and annual subscription plans. The specific features and limitations of each plan are detailed during Registration.</p>
        <h5>3.1.1 Plan Restrictions</h5>
        <ul>
          <li><strong>Student Early Adopters Plan:</strong> Users subscribed to the Student Early Adopters plan do not have commercial rights to use flat patterns for commercial purposes.</li>
          <li><strong>Student Plan:</strong> Users subscribed to the Student Plan do not have commercial rights to use flat patterns for commercial purposes.</li>
          <li><strong>Demo Mode:</strong> Any flat patterns downloaded during demo mode cannot be used for commercial purposes.</li>
        </ul>
        <h4>3.2 Billing Cycles</h4>
        <ul>
          <li><strong>Monthly Plans:</strong> Billing occurs on your initial Payment Date and renews automatically each month</li>
          <li><strong>Annual Plans:</strong> Billing occurs on your initial Payment Date and renews automatically each year</li>
          <li><strong>Payment Methods:</strong> We accept credit card payments and bank transfers as specified during Registration</li>
        </ul>
        <h4>3.3 Price Adjustments</h4>
        <p>We reserve the right to modify our pricing after your first year of service, with notice provided at least 30 days before any change takes effect.</p>
        <h3>4. Term and Termination</h3>
        <h4>4.1 Subscription Duration</h4>
        <p>Your subscription continues until terminated in accordance with these Terms.</p>
        <h4>4.2 Cancellation</h4>
        <p>You may cancel your subscription at any time through your account profile. Upon cancellation:</p>
        <ul>
          <li>No refunds will be issued for amounts already paid</li>
          <li>Service access continues until the end of your current billing period</li>
          <li>Any outstanding installment payments become immediately due</li>
          <li>Your data will be handled according to our data retention policies</li>
        </ul>
        <h4>4.3 Termination for Cause</h4>
        <p>We may terminate or suspend your access to the Services immediately if:</p>
        <ul>
          <li>You breach these Terms materially or repeatedly</li>
          <li>You fail to pay any fees when due</li>
          <li>We are required to do so by law</li>
          <li>We believe your usage poses a security risk</li>
        </ul>
        <h3>5. Service Delivery and Performance</h3>
        <h4>5.1 Service Standards</h4>
        <p>We will provide the Services:</p>
        <ul>
          <li>With reasonable skill and care</li>
          <li>In accordance with industry standards</li>
          <li>In compliance with applicable laws and regulations</li>
        </ul>
        <h4>5.2 Service Limitations</h4>
        <p>You acknowledge that:</p>
        <ul>
          <li>The Services are provided "as is"</li>
          <li>We do not guarantee uninterrupted or error-free operation</li>
          <li>We may modify features or functionality with reasonable notice</li>
          <li>Technical support is provided as specified in your subscription plan</li>
        </ul>
        <h4>5.3 Maintenance and Updates</h4>
        <p>We may temporarily suspend Services:</p>
        <ul>
          <li>For scheduled maintenance with advance notice</li>
          <li>For emergency maintenance without notice</li>
          <li>To implement security updates or improvements</li>
        </ul>
        <h3>6. User Obligations</h3>
        <h4>6.1 Acceptable Use</h4>
        <p>You agree not to:</p>
        <ul>
          <li>Reverse engineer or decompile the Services</li>
          <li>Use the Services to develop competing products</li>
          <li>Remove or alter any proprietary notices</li>
          <li>Attempt unauthorized access to our systems</li>
          <li>Upload malicious code or content</li>
          <li>Violate any applicable laws or regulations</li>
        </ul>
        <h4>6.2 Account Security</h4>
        <p>You are responsible for:</p>
        <ul>
          <li>Maintaining the confidentiality of your account credentials</li>
          <li>All activities that occur under your account</li>
          <li>Notifying us immediately of any unauthorized access</li>
        </ul>
        <h3>7. Intellectual Property Rights</h3>
        <h4>7.1 Ownership</h4>
        <ul>
          <li>We retain all intellectual property rights in the Services</li>
          <li>You retain all rights to your content and data</li>
          <li>We grant you a limited license to use the Services during your subscription</li>
        </ul>
        <h4>7.2 License Terms</h4>
        <p>Your license to use the Services is:</p>
        <ul>
          <li>Non-exclusive</li>
          <li>Non-transferable</li>
          <li>Revocable</li>
          <li>Limited to your internal business purposes</li>
        </ul>
        <h5>7.2.1 Commercial Use Restrictions</h5>
        <ul>
          <li>Users with Student Early Adopters plan may not use flat patterns for commercial purposes.</li>
          <li>Users with Student Plan may not use flat patterns for commercial purposes.</li>
          <li>Flat patterns downloaded during demo mode may not be used for commercial purposes.</li>
        </ul>
        <h4>7.3 Intellectual Property Claims</h4>
        <p>We will defend you against claims that the Services infringe third-party intellectual property rights, provided that:</p>
        <ul>
          <li>You promptly notify us of any claims</li>
          <li>You allow us to control the defense</li>
          <li>You provide reasonable assistance in the defense</li>
          <li>The claim does not arise from your modifications or misuse</li>
        </ul>
        <h3>8. Limitation of Liability</h3>
        <h4>8.1 Warranty Disclaimer</h4>
        <p>Except as expressly stated in these Terms, we make no warranties about the Services and disclaim all implied warranties to the maximum extent permitted by law.</p>
        <h4>8.2 Liability Limits</h4>
        <p>Our liability for any claim arising from these Terms will not exceed the amount you paid for the Services in the 12 months preceding the claim.</p>
        <h3>9. Governing Law and Jurisdiction</h3>
        <p>These Terms are governed by the laws of the State of California. Any disputes will be resolved exclusively in the courts of San Mateo County, California.</p>
        <h3>10. Modifications to Terms</h3>
        <p>We reserve the right to modify these Terms at any time. We will notify you of any material changes via email or through the Services. Your continued use of the Services after such modifications constitutes acceptance of the updated Terms.</p>
        <h3>11. Contact Information</h3>
        <p>For questions about these Terms, please contact us at:</p>
        <ul>
          <li>Email: <a href= "mailto:legal@patternfast.com">legal@patternfast.com</a></li>
          <li>Address: 1900 South Norfolk Street, Suite 350, San Mateo, CA 94403</li>
          <li>Phone: <a href="tel:8888833840">(888) 883-3840</a></li>
        </ul>
      </div>
    </section>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/Home/TheHeader.vue'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  name: 'TermsAndConditions',
  components: {
    TheHeader,
    TheFooter,
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
.terms {
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    padding: 132px 80px 154px 80px;

    & > h5 {
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin: 0;
      margin-bottom: 5px;
      margin-top: 16px;
    }

    & > h4 {
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin: 0;
      margin-bottom: 5px;
      margin-top: 16px;
    }

    & > h3 {
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin: 0;
      margin-bottom: 5px;
      margin-top: 16px;
    }

    & > h2 {
      color: var(--Text-Text_Secondary);
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.18px;
      margin: 0;
      margin-bottom: 10px;
    }

    & > h1 {
      color: var(--Text-Text_Primary);
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.5px;
      margin: 0;
      margin-bottom: 25px;
    }

    & > p {
      width: 100%;
      margin: 0;
      margin-bottom: 8px;
    }
    & > ul {
      padding-left: 13px;
      margin-bottom: 8px;
    }
  }
}
@media (max-width: 480px) {
  .terms  {
    .container {
      padding: 122px 20px 63px 20px;

      & > h1 {
        font-size: 30px;
      }
      & > h3 {
        font-size: 18px;
      }
      & > h4 {
        font-size: 14px;
      }
      & > h5 {
        font-size: 12px;
      }
    }
  }
}
</style>
