// const STORAGE_KEY = 'designConstructorHistory'

let history = []
let currentPosition = 0

// let existingHistory = localStorage?.getItem(STORAGE_KEY)
// if (existingHistory) {
//   try {
//     let historyParsed = JSON.parse(existingHistory)
//     if (Array.isArray(historyParsed)) {
//       history = historyParsed
//       let existingPosition = localStorage?.getItem(STORAGE_KEY + '_pos')
//       if (
//         existingPosition === undefined ||
//         !Number.isNaN(Number(existingPosition))
//       ) {
//         existingPosition = historyParsed.length
//       }
//       currentPosition = existingPosition
//     }
//   } catch (e) {
//     console.log(e)
//   }
// }

const state = {
  stepsMax: 30,
  currentPosition,
  history,
}

const mutations = {
  PUSH_STEP (state, { name, data }) {
    state.history.splice(
      state.currentPosition,
      state.history.length - state.currentPosition,
      { name, data: JSON.stringify(data) },
    )
    if (state.history.length > state.stepsMax) {
      state.history.splice(0, state.history.length - state.stepsMax)
    }
    state.currentPosition = state.history.length

    // localStorage.setItem(STORAGE_KEY, JSON.stringify(state.history))
    // localStorage.setItem(STORAGE_KEY + '_pos', state.currentPosition)
  },
  INCREASE_STEP (state, steps) {
    if (state.currentPosition + steps > state.history.length) {
      throw new Error('Cannot redo')
    }
    state.currentPosition = state.currentPosition + steps
    // localStorage.setItem(STORAGE_KEY + '_pos', state.currentPosition)
  },
  DECREASE_STEP (state, steps) {
    if (state.currentPosition - steps < 0) {
      throw new Error('Cannot undo')
    }
    state.currentPosition = state.currentPosition - steps
  },
  CLEAN_HISTORY (state) {
    state.history = []
    state.currentPosition = 0
  },
}

const getters = {
  undoAvailable: state => state.currentPosition > 1,
  redoAvailable: state => state.history.length > state.currentPosition,
  currentStepName: state => state.history[state.currentPosition - 1]?.name,
  nextStepName: state => state.history[state.currentPosition]?.name,
}

const actions = {
  async push ({ commit }, { name, data }) {
    commit('PUSH_STEP', { name, data })
  },
  async undo ({ state, commit }, steps = 1) {
    commit('DECREASE_STEP', steps)
    return JSON.parse(state.history[state.currentPosition - 1].data)
  },
  async redo ({ state, commit }, steps = 1) {
    commit('INCREASE_STEP', steps)
    return JSON.parse(state.history[state.currentPosition - 1].data)
  },
  async cleanHistory ({ commit }) {
    commit('CLEAN_HISTORY')
  },
  // async cleanStorage () {
  //   localStorage.removeItem(STORAGE_KEY)
  //   localStorage.removeItem(STORAGE_KEY + '_pos')
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
