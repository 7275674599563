<template>
  <layout-base ref="layout" v-if="!$route.query.onlySketch">
    <template v-slot:header>
      <toolbar :sketch-active="displaySketch" :pattern-active="displayPattern"
        :display-new-design-item="canChangeCategory" :undo-available="undoAvailable" :redo-available="redoAvailable"
        :undo-tooltip="undoAvailable ? `Undo '${currentStepName}'` : undefined"
        :redo-tooltip="redoAvailable ? `Redo '${nextStepName}'` : undefined" :fabric-available="fabricDialogAvailable"
        :patternsPreviewError="patternsPreviewError"
        :isLinkCopied="isLinkCopied"
        @select="toolbarHandle($event)" />
    </template>

    <template v-slot:leftPanel>
      <sidebar-nodes-list :nodes="nodes" :design-attr-ids="designAttrIds" :active-code="activeNodeCode"
        :disabled="designUpdateInProgress" @select="selectNode($event)"></sidebar-nodes-list>
      <div class="area" />
      <div
        class="sidebar-element colors-element"
        :class="[activeOptionsList === 'colors' ? 'active' : '']"
        @click="toggleColorsPanel()"
      >
        <img :src="require(`@/assets/Colorways.png`)" />
        Colorways
      </div>
    </template>

    <div class="PageDesigner">
      <div v-if="displayNodeElementsList || activeOptionsList"
        class="PageDesigner__item PageDesigner__optionsListContainer">
        <elements-panel v-if="displayNodeElementsList" ref="elementsPanel" :nodes-list="activeNodes"
          :active-node="activeNode" :active-element-ids="designElementIdsByNodes[activeNodeCode] || []"
          :compatible-element-ids-str="compatibleElementsIdsForActiveNode" @select-node="selectNode"
          @select-element="selectElement(activeNodeCode, $event)"
          @unselect-all-elements="unselectAll(activeNodeCode)"
          @close="unselectNode" @error="error($event.title, $event.message)" />

        <sidebar-options-panel v-if="activeOptionsList === ELEMENT_OPTIONS.FABRIC.id" :items="fabricsList"
          @close="closeOptions">
          <template v-slot:item="{ item: el }">
            <element-option-item class="ElementOptionItem__fabricItem" :element="el" hide-name
              :is-selected="el.id === fabricId" @select="setActiveFabric($event)">
              <img class="ElementOptionItem__fabricItemImg" :src="el.imageUrl" />
            </element-option-item>
          </template>
        </sidebar-options-panel>

        <sidebar-options-panel v-if="activeOptionsList === ELEMENT_OPTIONS.COLOR.id" :items="elementColors"
          @close="closeOptions">
          <element-option-item class="ElementOptionItem__colorItem" :element="{ name: 'Default' }"
            :is-selected="activeColorSelected === undefined" @select="setActiveColor({ colorCode: undefined })">
            <div class="ElementOptionItem__colorItemIcon" :style="{ 'background-color': defaultSketchColor || undefined }">
            </div>
          </element-option-item>
          <template v-slot:item="{ item: el }">
            <element-option-item class="ElementOptionItem__colorItem" :element="el"
              :is-selected="el.colorCode === activeColorSelected" @select="setActiveColor($event)">
              <div class="ElementOptionItem__colorItemIcon" :style="{ 'background-color': el.colorCode }"></div>
            </element-option-item>
          </template>
        </sidebar-options-panel>

        <ColorsPanel
          v-if="activeOptionsList === 'colors'"
          :active-color="activeColorSelected"
          @close="activeOptionsList = undefined"
          @create-colorways="createColorWays()"
          @set-color="setColor"
        />
      </div>

      <ColorWays
        v-if="colorWays"
        :sketchSides="sketchSides"
        :saved-colors="colors.saved"
        @set-color="activeColorSelected = $event"
        @save-design="saveDesignFromColorWays"
        @close="colorWays = false"
      />

      <transition name="fade">
        <div v-if="displaySketch" class="PageDesigner__item">
          <technical-sketch-container v-if="designElementsWithUserSettings && designCategory" :nodes="nodes"
            :design-elements="designElementsWithUserSettings" :design-category="designCategory"
            :color="activeColorSelected || defaultSketchColor" :fabric-image="fabric ? fabric.imageUrl : undefined"
            :is-loading="designUpdateInProgress" :design-options="designOptions" :ease="easeByMan.ease"
            :design-details-desc-items="designDetailsDescItems" @node-selected="selectNode($event.nodeCode, true)"
            @sketch-updated="sketchSides = $event"
            :class="
              !displayPattern && !displayNodeElementsList ?
              'center' :
              displayPattern && displayNodeElementsList ? 'all' : ''
            "
          >
            <template v-slot:top>
              <attribute-settings-select v-for="setting in designSettingsHide"
                :key="setting.nodeCode + '-' + setting.settings.attrType"
                class="PageDesigner__attributeSettingsSelect inline-block" :label="setting.settings.name"
                :active-element="designSettingsActive[setting.settings.attrType]" :attributes="setting.settings.attrs"
                :disabled="selectElementSettingInProgress"
                @change="selectElementSetting(setting.settings.attrType, $event)" />
            </template>
          </technical-sketch-container>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="displayPattern" class="PageDesigner__item">
          <flat-pattern
            :patterns-preview="patternsPreview"
            :is-loading="patternPreviewUpdateInProgress"
            :class="
              !displaySketch && !displayNodeElementsList ?
              'center' :
              displaySketch && displayNodeElementsList ? 'all' : ''
            "
            @showSeamAllowance="displaySeamAllowanceModal = true"
            @sizeRun="openLink('size-run')"
            @internalLines="setInternalLines"
          >
            <template v-slot:top>
              <div class="Flat_pattern-top_actions">
                <div class="sizes">
                  <Dropdown
                    v-if="isCustomModel"
                    :options="fitModelsStore.modelsForEdit[mannequinType]"
                    v-model="fitModelsStore.selectedModel"
                    @input="selectSize"
                    label="Fit model"
                    option-label="name"
                    placeholder="Choose..."/>
                  <Dropdown
                    v-else
                    :options="fitChartsStore.selectedChart?.mannequins"
                    v-model="fitChartsStore.selectedMannequin"
                    @input="selectSize"
                    label="Pattern Size"
                    option-label="name"
                    placeholder="Choose..."/>
                  <ButtonSecondary
                    class="small"
                    @click.native="openLink('FitModel')"
                  >
                  <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 20" width="20" height="20">
                      <path fill="#09373d" d="m17.6 5.9-3.5-3.5c-.6-.6-1.6-.6-2.2 0l-9.4 9.4c-.6.6-.6 1.6 0 2.2L6 17.5c.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5l9.4-9.4c.6-.5.6-1.5 0-2.2zm-1.1 1.2-9.4 9.4H7L3.5 13v-.1l1.8-1.8 1.2 1.2c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L6.3 10l1.3-1.3 1.2 1.2c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L8.7 7.6 10 6.3l1.2 1.2c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L11 5.2l1.8-1.8h.2L16.5 7c.1 0 .1.1 0 .1z"/>
                    </svg>
                  </template>
                    {{ $q.screen.lt.sm ? '' : 'Edit fit model'}}
                  </ButtonSecondary>
                </div>
                <ButtonPrimary
                  class="small save"
                  @click.native="displaySaveDesignModal = true"
                >
                  Save Design
                </ButtonPrimary>
              </div>
            </template>
            <template v-slot:noPreview>
              <div v-if="patternsPreviewError" class="full-height column justify-center text-center">
                <div>
                  <p class="text-body1">Oops! We could not process calculations, sorry!</p>
                  <q-btn color="secondary" @click="getPatternsPreview">
                    Try again
                  </q-btn>
                </div>

              </div>
            </template>
            <template v-slot:bottom>
              <div class="Flat_pattern__download">
                <ButtonSecondary
                  class="small"
                  @click.native="demoMode && !downloadLeft ? null : displayDownloadModal = true"
                  :disabled="demoMode && !downloadLeft"
                >
                  <div class="download-demo" v-if="demoMode && !downloadLeft">
                    Upgrade your plan<br>to download the pattern
                  </div>
                  Download
                <template v-slot:lastIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 20 21" width="20" height="21">
                    <path d="M15 17H5c-.4 0-.8.3-.8.8s.3.8.8.8h10c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zm-5.5-2.1c.1.1.2.1.2.2.1 0 .2.1.3.1.1 0 .2 0 .3-.1.1 0 .1-.1.2-.1l4.2-4.2c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0l-2.9 2.9V2.7c.1-.4-.3-.7-.7-.7s-.8.3-.8.7v9.9L6.4 9.7c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l4.2 4.1z"/>
                  </svg>
                  <div class="download-demo-left" v-if="demoMode">
                    {{ downloadLeft }} left
                  </div>
                </template>
                </ButtonSecondary>
              </div>
            </template>
          </flat-pattern>
        </div>
      </transition>

      <q-dialog v-model="displaySewingModal" content-class="print-hide">
        <sewing-component class="PageDesigner__sewingComponentModal"
          :style="`${screenHeight < 550 ? 'min-height: 100%;' : 'min-height: 500px;'} max-width: 100%; ${$q.screen.lt.sm ? 'width:100%; min-height: 100%;' : ''}`"
          :design-options="designOptions" :sketch-sides="sketchSides" :design-fabric="designFabric"
          @error="message => handleInstructionsError(message)"></sewing-component>
      </q-dialog>

      <fabric-dialog v-if="fabricDialogAvailable" :design-options="designOptions" :display-dialog="displayFabricModal"
        @error="error($event.title, $event.message)" @hide="displayFabricModal = false" />

      <downloadPatternModal
        :displayModal="displayDownloadModal"
        :loading="downloadPatternsLoading"
        @download="downloadPatterns"
        @close="displayDownloadModal = false" />

      <modal-component
        :show="displaySaveDesignModal"
        :showIcon="false"
        @close="displaySaveDesignModal = false"
        class="save-design-modal"
      >
        <template v-slot:title>
          Save design
        </template>
        <template v-slot:description>
          Before saving the design, <br>please name it
        </template>
        <template v-slot:extra>
          <InputField
            v-model="designName"
            placeholder="Enter name"
          >
            <template v-slot:top-label>
              Name your design
            </template>
          </InputField>
        </template>
        <template v-slot:buttons>
          <ButtonPrimary class="one" :disabled="!designName?.length" @click.native="saveDesign">
            Save Design
          </ButtonPrimary>
        </template>
      </modal-component>
      <Yardage
        v-if="displayMarkerModal"
        :show="displayMarkerModal"
        @close="displayMarkerModal = false"
        :params="{
          element_ids: designElementIdsFlatList,
          setting_codes: changedSettingCodes,
          mannequin_uid: fitChartsStore.selectedMannequin.apiUid,
          designElementsWithUserSettings: designElementsWithUserSettings
        }"
      />
      <modal-component
        :show="displayModalUpradePlan"
        type="success"
        @close="displayModalUpradePlan = false"
      >
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 33" width="32" height="33"
            v-html="svgForModalUpradePlan"
          ></svg>
        </template>
        <template v-slot:title>
          {{ titleForModalUpradePlan }}
        </template>
        <template v-slot:description>
          Upgrade your plan to experience all the<br>possibilities of PatternFast
        </template>
        <template v-slot:buttons>
          <ButtonSecondary @click.native="displayModalUpradePlan = false">
            Cancel
          </ButtonSecondary>
          <ButtonPrimary @click.native="$router.push({ name: 'user_billing' })">
            Upgrade
          </ButtonPrimary>
        </template>
      </modal-component>
      <seam-allowance-select
        v-if="displaySeamAllowanceModal"
        style="max-width:450px;"
        :current-value="seamAllowance"
        @apply="setSeamAllowance"
        :category="designCategory"
        :show="displaySeamAllowanceModal"
        @close="displaySeamAllowanceModal = false"
      />

      <q-dialog v-model="displayDebugParams">
        <debug-params-modal :debugParams="debugParams" @apply="displayDebugParams = false"></debug-params-modal>
      </q-dialog>

      <q-dialog v-model="changeElementAgreeModal">
        <dialog-card-base
          title="All applied eases will be reset. Would you like to proceed to the next design?"
          contentContainerClass="normalBlock"
          class="PageDesigner__changeElementAgreeModal"
        >
          <div class="row full-width justify-center">
            <q-btn
              size="md"
              color="secondary"
              class="black-button"
              no-caps
              @click.prevent="delEase">
              Yes
            </q-btn>
            <q-btn
              outline
              color="primary"
              size="md"
              no-caps
              style="margin-left:10px;"
              class="outline-button"
              @click.prevent="changeElementAgreeModal = false">
              No
            </q-btn>
          </div>
        </dialog-card-base>
      </q-dialog>
    </div>
  </layout-base>
</template>

<script>
import downloadPatternModal from '@/components/downloadPatternModal'
import LayoutBase from '@/layouts/LayoutBase'
import FlatPattern from '@/components/FlatPattern'
import Toolbar from '@/components/Toolbar'
import Yardage from '@/components/yardage/Yardage'
import SidebarNodesList from '@/components/Sidebar/SidebarNodesList'
import ModalComponent from '@/components/Atoms/ModalComponent'
import InputField from '@/components/Atoms/InputField.vue'
import ElementOptionItem from '@/components/Elements/ElementOptionItem'
import FabricDialog from '@/components/Fabric/FabricDialog'
import { ELEMENT_OPTIONS } from '@/components/elementOptionNames'
import SewingComponent from '@/components/Sewing/SewingComponent'
import DialogCardBase from '@/components/Dialog/DialogCardBase'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import Dropdown from '@/components/Atoms/Dropdown.vue'
import {
  createMannequin,
  fetchCategoryElementsData,
  fetchDefaultDesign,
  fetchDesign,
  fetchApplicationDetails,
  fetchDesignerTokenData,
  fetchElementImages,
  fetchFabrics,
  fetchNodes,
  fetchDebug,
  fetchPatternsPreview,
  downloadPattern,
  fetchUpdateDesign,
  saveDesign,
  fetchMannequin,
} from '@/api'
import TechnicalSketchContainer from '@/components/TechnicalSketchContainer'
import ElementsPanel from '@/components/Elements/ElementsPanel'
import ColorsPanel from '@/components/Elements/ColorsPanel'
import ColorWays from '@/components/Elements/ColorWays'
import SidebarOptionsPanel from '@/components/Sidebar/SidebarOptionsPanel'
import pDebounce from 'p-debounce'
import {
  extractAttributeIdsFromDesignElements,
  getCompatibleNodesList,
  getDesignFabric,
  getDesignSettingsCompatibleValues,
  getElementsCompatibleWithCode,
  getNodeAttributeByCode,
  getNodeSettingsActiveValues,
  transformServerDesignResponseToApplication,
} from '@/pages/designerConstructor'
import { colors } from '@/helpers/colors'
import { mapActions, mapGetters, mapState } from 'vuex'
import { defaultValues, getFabricTypeDefaultValue } from '@/components/Ease/easeSelects'
import SeamAllowanceSelect from '@/components/SeamAllowance/SeamAllowanceSelect'
import AttributeSettingsSelect from '@/components/AttributeSettings/AttributeSettingsSelect'
import { setViewBox } from '@/helpers/svg'
import DebugParamsModal from '../components/Design/DebugParamsModal.vue'
import { useUserCollectionsStore } from '@/store/pinia/userCollections'
import { mapStores } from 'pinia'
import { useFitChartsStore } from '@/store/pinia/fitCharts'
import { useFitModelsStore } from '@/store/pinia/fitModels'
import { settingCodesChange } from '@/helpers/settingCodes'

export default {
  name: 'PageDesigner',
  components: {
    Yardage,
    ButtonPrimary,
    ButtonSecondary,
    Dropdown,
    AttributeSettingsSelect,
    SeamAllowanceSelect,
    SidebarOptionsPanel,
    ElementsPanel,
    ColorsPanel,
    TechnicalSketchContainer,
    DialogCardBase,
    SewingComponent,
    SidebarNodesList,
    ElementOptionItem,
    FabricDialog,
    Toolbar,
    FlatPattern,
    LayoutBase,
    DebugParamsModal,
    ModalComponent,
    InputField,
    ColorWays,
    downloadPatternModal,
  },

  data: () => ({
    downloadPatternsLoading: false,
    fitChartsId: null,
    mannequinIdNew: null,
    isCustomModel: false,
    displayMarkerModal: false,
    displayModalUpradePlan: false,
    titleForModalUpradePlan: '',
    svgForModalUpradePlan: '',
    displayDownloadModal: false,
    InternalLines: false,
    selectedSize: null,
    changeElementAgreeModal: false,
    newSelectedElement: null,
    userSizes: null,
    tokenSrc: false,
    screenHeight: document.documentElement.clientHeight,
    designElements: {},
    designElementsUserSelected: [],
    // атрибуты, настройки для которых выбраны пользователем.
    elementsSettingsUserSelected: {},
    // отдельные элементы с примененными настройками и обновленными изображениями
    elementsCustomized: {},
    activeNodeCode: undefined,
    activeOptionsList: undefined,
    fabricId: undefined,
    defaultSketchColor: '#ffffff',
    activeColorSelected: undefined,
    displaySketch: true,
    displayPattern: true,
    displayNewDesignModal: false,
    displaySewingModal: false,
    displayFabricModal: false,
    displaySeamAllowanceModal: false,
    displaySaveDesignModal: false,
    designName: '',
    designCategory: undefined,
    canChangeCategory: false,
    nodes: [],
    designUpdateInProgress: false,
    designSaveInProgress: false,
    measurementsSaveInProgress: false,
    selectElementSettingInProgress: false,
    patternPreviewUpdateInProgress: false,
    sketchSides: undefined,
    patternsPreview: undefined,
    patternsPreviewError: false,
    standartSize: undefined,
    mannequinName: undefined,
    mannequinId: undefined,
    mannequinType: undefined,
    fabricType: undefined,
    fit: defaultValues.FIT_SELECT,
    defaultFit: defaultValues.FIT_SELECT,
    ease: {},
    fabricsList: [],
    designTypes: {
      'wm_dresses': 'dress',
      'wm_tops': 'top',
      'wm_jumpsuits': 'jumpsuit',
      'wm_pants': 'pants',
      'wm_skirts': 'skirt',
    },
    paramsDebugListener: null,
    refreshListener: null,
    displayDebugParams: false,
    debugParams: {},
    isLinkCopied: false,
    mannequinEase: null,
    colorWays: false,
    colors: {
      inProgress: -1,
      saved: [],
    },
  }),

  async created () {
    window.addEventListener('resize', this.handleResize)
    this.ELEMENT_OPTIONS = ELEMENT_OPTIONS
    this.historyFields = Object.freeze([
      'nodes',
      // без восстановления текущей активной ноды компонент elementsPanel может оказаться пустым при откате истории
      'activeNodeCode',
      'designCategory',
      'designElements',
      'designElementsUserSelected',
      'elementsSettingsUserSelected',
      'elementsCustomized',
      'activeColorSelected',
      'fabricId',
      'fit',
      'fabricType',
      'ease',
      'seamAllowance',
    ])
    try {
      await fetchFabrics()
        .then(data => {
          if (!data?.fabrics) {
            this.error('Error', 'Cannot load fabrics list')
          }
          this.fabricsList = data.fabrics
        })
    } catch (e) {
      this.error('Error', e.message)
    }
    this.cleanUserSelectedElementsDebounced = pDebounce(this.cleanUserSelectedElements, 50)
    try {
      await this.loadApplicationDetails()
      // загрузка начальных параметров из get
      let params = await this.getInitialParams()
      console.log('initial params ', params)
      await this.fillInitialData(params)
    } catch (e) {
      this.error('Error', e.message)
    }
  },

  watch: {
    fabricTypeDefaultValue () {
      this.fillInitialFabricTypeValue()
    },
    activeNodeCode (val) {
      if (val !== undefined) {
        this.activeOptionsList = undefined
      }
    },
    activeOptionsList (val) {
      if (val !== undefined) {
        this.activeNodeCode = undefined
      }
    },
    async designElementIdsFlatList (val) {
      await this.cleanUserSelectedElementsDebounced()
    },
  },

  computed: {
    ...mapStores(useUserCollectionsStore),
    ...mapStores(useFitChartsStore),
    ...mapStores(useFitModelsStore),
    designAlgorithms () {
      let alg = []
      for (let key in this.designElementsWithUserSettings) {
        alg = alg.concat(this.designElementsWithUserSettings[key][0].algorithms)
      }
      return alg
    },
    designParams () {
      let par = {}
      for (let key in this.designElementsWithUserSettings) {
        par = Object.assign(par, this.designElementsWithUserSettings[key][0].params)
      }
      let parsNew = par
      let isDelPar2002 = false
      if (this.designSettingsActiveFlat.length) {
        this.designSettingsActiveFlat.forEach(el => {
          if (el.includes('facing:') && !el.includes('neckline_facing:')) {
            isDelPar2002 = true
          }
          if (settingCodesChange[el]) {
            parsNew = Object.assign(parsNew, settingCodesChange[el])
          }
        })
      }
      if (isDelPar2002) {
        delete parsNew[2002]
      }
      return parsNew
    },
    sizesByCategory () {
      if (this.selectedSize) {
        if (this.$store.state.fitModel.sizeSwitcher === 'custom' && this.userSizes?.length) {
          return this.userSizes
        }
      }
      return []
    },
    easeByMan () {
      if (this.mannequinEase?.[this.mannequinIdNew]) {
        return this.mannequinEase[this.mannequinIdNew]
      } else {
        return {
          ease: this.ease,
          fabricType: this.fabricType,
          fit: this.fit,
        }
      }
    },
    designOptions () {
      return {
        elementIds: this.designElementIdsFlatList,
        mannequinId: this.mannequinId,
        seamAllowance: this.seamAllowance,
        fit: this.easeByMan.fit,
        fabricType: this.easeByMan.fabricType,
        designCategory: this.designCategory,
        eases: this.easeByMan.ease,
        settingCodes: this.designSettingsActiveFlat,
        designName: this.designName || 'Untitled',
        internalLines: this.InternalLines ? 1 : 2,
      }
    },
    saveDesignDataReady () {
      return !this.patternsPreviewError &&
        this.designCategory &&
        this.designElementIdsFlatList &&
        this.mannequinId &&
        this.fabricType !== undefined &&
        this.fit !== undefined
    },
    fabricDialogAvailable () {
      return this.mannequinId && this.designElementIdsFlatList.length > 0
    },
    // элементы дизайна с настройками, выбранными пользователем
    designElementsWithUserSettings () {
      if (Object.keys(this.elementsCustomized).length === 0) {
        return this.designElements
      }
      let result = {}
      Object.keys(this.designElements)
        .forEach(nodeCode => {
          result[nodeCode] = this.designElements[nodeCode].map(el => {
            if (!(el.id in this.elementsCustomized)) {
              return el
            }
            let element = { ...el }
            let custom = this.elementsCustomized[el.id]
            element.svgFront = custom.svgFront
            element.svgBack = custom.svgBack
            // todo: пока заменяем все зачения атрибутов на выбранные пользователем, в будущем атрибуты будут удалены отсюда
            element.attributes = element.attributes.map(attr => {
              if (!custom.customAttributes[attr.type]) {
                return attr
              }
              return custom.customAttributes[attr.type]
            })
            return element
          })
        })
      return result
    },
    // плоский список всех id атрибутов в текущем дизайне
    designAttrIds () {
      return extractAttributeIdsFromDesignElements(this.designElementsWithUserSettings)
    },
    // список настроек дизайна, доступных для изменения пользователем
    designSettings () {
      return getDesignSettingsCompatibleValues(this.designElementsWithUserSettings, this.nodes)
    },
    designSettingsHide () {
      this.designSettingsNormalized.forEach(el => {
        let canSelect = el.settings.attrs.find(seting => seting.code === this.designSettingsActive[el.settings.attrType])
        if (!canSelect) {
          this.selectElementSetting(null, el.settings.attrs[0].code)
        }
      })
      let topSlits = this.designSettingsNormalized.find(el => el.nodeCode === 'wm_tp_silhouettes' && el.settings.attrType === 'top_slits_type')
      let skirtFrontSlits = this.designSettingsNormalized.find(el => el.nodeCode === 'wm_sk_silhouettes' && el.settings.attrType === 'skirt_front_slits')
      let skirtLining = this.designSettingsNormalized.find(el => el.nodeCode === 'wm_sk_silhouettes' && el.settings.attrType === 'skirt_lining')
      let dressLining = this.designSettingsNormalized.find(el => el.nodeCode === 'wm_dr_silhouettes' && el.settings.attrType === 'dress_lining')
      let skirtSlits = this.designSettingsNormalized.find(el => el.nodeCode === 'wm_sk_silhouettes' && el.settings.attrType === 'skirt_slits')
      const dressLength = this.designSettingsNormalized.find(el => el.nodeCode === 'wm_dr_silhouettes' && el.settings.attrType === 'length_type')
      const dressFacing = this.designSettingsNormalized.find(el => el.nodeCode === 'wm_dr_front_necks' && el.settings.attrType === 'neckline_facing')
      const jmFacing = this.designSettingsNormalized.find(el => el.nodeCode === 'wm_jm_front_necks' && el.settings.attrType === 'neckline_facing')
      const closures = Object.keys(this.designElementsWithUserSettings).filter(el => el.includes('closures'))
      let backVentOrSlit = this.designElementsWithUserSettings?.[closures]?.[0].attributes.find(el => {
        return el.code === 'vent:back_slit' || el.code === 'vent:back_vent'
      })
      let skirtFrontVentOrSlit = this.designElementsWithUserSettings?.[closures]?.[0].attributes.find(el => {
        return el.code === 'skirt_front_vent:front_vent' || el.code === 'skirt_front_vent:front_slit'
      })
      const elements = Object.keys(this.designElementsWithUserSettings)
      const isSkirtLining = this.designSettingsActive.skirt_lining !== 'skirt_lining:no_lining'
      const isFronSlits = this.designSettingsActive.skirt_front_slits !== 'skirt_front_slits:no_slits'
      if (dressLining) {
        const dressFrontNeckParams = this.designElementsWithUserSettings?.['wm_dr_front_necks']?.[0]?.params
        const dressBacktNeckParams = this.designElementsWithUserSettings?.['wm_dr_back_necks']?.[0]?.params
        const dressAlg = this.designElementsWithUserSettings?.['wm_dr_silhouettes']?.[0]?.algorithms
        const par = {
          5: [101, 102, 103, 104, 202, 203, 204, 302, 303, 304],
          6: [101, 102, 103, 104, 202, 203, 204, 302, 303, 304],
        }
        dressLining.settings.attrs.forEach(el => {
          if (el.code === 'dress_lining:with_lining') {
            if (
              (dressAlg.includes('WDR11011') &&
              par[5].includes(dressFrontNeckParams[5]) && par[6].includes(dressBacktNeckParams[6])) ||
              !dressAlg.includes('WDR11011')
            ) {
              el.disable = false
            } else {
              el.disable = true
              el.title = "Lining don't work with neck"
            }
          }
        })
      }
      if (jmFacing) {
        const jmParams = this.designElementsWithUserSettings['wm_jm_silhouettes']?.[0]?.params
        jmFacing.settings.attrs.forEach(el => {
          if (el.code === 'neckline_facing:no_facing' && jmParams?.[16] === 2) {
            el.disable = true
            el.title = "Facing don't work"
          } else if (el.code === 'neckline_facing:with_facing' && jmParams?.[16] === 5) {
            el.disable = true
            el.title = "Facing don't work"
          } else {
            el.disable = false
          }
        })
      }
      if (dressFacing) {
        const dressFrontNeckParams = this.designElementsWithUserSettings?.['wm_dr_silhouettes']?.[0]?.params
        let isCollar = false
        for (let nodeCode in this.designElements) {
          const els = this.designElements[nodeCode]
          if (els.some(el => el.algorithms.some(alg => alg.indexOf('CLR') === 0))) {
            isCollar = true
            break
          }
        }
        const isDressHalter = this.designElementsWithUserSettings['wm_dr_silhouettes']?.[0].attributes.find(el => {
          return el.code === 'sleeve:halter_round_neck'
        })
        dressFacing.settings.attrs.forEach(el => {
          if (el.code === 'neckline_facing:no_facing' && dressFrontNeckParams[16] === 3 && isCollar) {
            el.disable = true
            el.title = "Facing don't work with Button Down"
          } else if (el.code === 'neckline_facing:with_facing' && isDressHalter) {
            el.disable = true
            el.title = "Facing don't work with halter collar"
          } else {
            el.disable = false
          }
        })
      }
      if (skirtLining) {
        skirtLining.settings.attrs.forEach(el => {
          if (isFronSlits && skirtFrontSlits) {
            el.disable = true
            el.title = "Lining don't work with front slits"
          } else el.disable = false
        })
      }
      if (skirtFrontSlits) {
        const isSideSlits = this.designSettingsActive.skirt_slits !== 'skirt_slits:no_slits'
        skirtFrontSlits.settings.attrs.forEach(el => {
          if (isSideSlits && skirtSlits) {
            el.disable = true
            el.title = "Front slits don't work with side slits"
          } else if (isSkirtLining && skirtLining) {
            let noSlits = 'skirt_front_slits:no_slits'
            if (this.designSettingsActive['skirt_front_slits'] !== noSlits) {
              this.selectElementSetting(null, noSlits)
            }
            el.disable = true
            el.title = "Front slits don't work with lining"
          } else {
            el.disable = false
          }
        })
      }
      if (skirtSlits) {
        skirtSlits.settings.attrs.forEach(el => {
          if (el.code !== 'skirt_slits:no_slits') {
            if (isFronSlits && skirtFrontSlits) {
              el.disable = true
              el.title = "Side slits don't work with front slits"
            } else if (elements.includes('wm_sk_ruffles')) {
              el.disable = true
              el.title = "Side slits don't work with ruffles"
            } else if (backVentOrSlit || skirtFrontVentOrSlit) {
              el.disable = true
              el.title = `Side slits don't work with ${skirtFrontVentOrSlit ? skirtFrontVentOrSlit.name : backVentOrSlit.name}`
            } else {
              el.disable = false
            }
          }
        })
      }
      if (topSlits) {
        topSlits.settings.attrs.forEach(el => {
          if (el.code === 'top_slits:with_side_slits') {
            if (elements.includes('wm_tp_ruffles')) {
              el.disable = true
              el.title = "Side slits don't work with pepums"
            } else {
              el.disable = false
            }
          }
        })
      }
      if (dressLength) {
        dressLength.settings.attrs.forEach(el => {
          if (el.code === 'length:micro_mini' || el.code === 'length:mini') {
            if (backVentOrSlit) {
              el.disable = true
              el.title = el.name + " don't work with " + backVentOrSlit.name
            } else {
              el.disable = false
            }
          }
        })
      }
      return this.designSettingsNormalized
    },
    designSettingsNormalized () {
      let result = []
      for (let i in this.designSettings) {
        const settings = this.designSettings[i].settings
        let canAddFilter = true
        if (!Array.isArray(settings.attrType)) {
          if (settings.attrType === 'neckline_facing') {
            let silhouettesKeys = Object.keys(this.designElements)?.filter(el => el.includes('silhouettes'))
            if (
              (this.designElements[silhouettesKeys]?.length && this.designElements[silhouettesKeys]?.[0]?.params[1006])
            ) {
              const facingParam = this.designElements[silhouettesKeys]?.[0].params[1006]
              const bitmask = (facingParam >>> 0).toString(2)
              const noFacing = [0, 4].filter(el => bitmask[el] !== '0')
              const hasFacing = [1, 2, 3].filter(el => bitmask[el] !== '0')
              settings.attrs = settings.attrs.filter(el => {
                if (el.code.includes('with_facing') && hasFacing.length) return el
                if (el.code.includes('no_facing') && noFacing.length) return el
              })
            }
            // если планка и воротник, не отображаем фильтр обтачек
            let isCollar = false
            let isPlacket = false
            for (let nodeCode in this.designElements) {
              const els = this.designElements[nodeCode]
              if (els.some(el => el.algorithms.some(alg => alg.indexOf('CLR') === 0))) {
                isCollar = true
                break
              }
            }
            for (let nodeCode in this.designElements) {
              const els = this.designElements[nodeCode]
              let hasPar16 = false
              for (let k in els) {
                const el = els[k]
                if (el.params && el.params[16] === 1) {
                  hasPar16 = true
                  break
                }
              }
              if (hasPar16) {
                isPlacket = true
                break
              }
            }
            if (isPlacket && isCollar) {
              canAddFilter = false
            }
          }
          if (canAddFilter) {
            result.push(this.designSettings[i])
          }
        } else {
          settings.attrType.forEach((type, j) => {
            if (type === 'dress_lining') {
              if (this.designElements.wm_dr_silhouettes.length && this.designElements.wm_dr_silhouettes[0].params[1007]) {
                const liningParam = this.designElements.wm_dr_silhouettes[0].params[1007]
                // const liningParam = 0
                // понять, есть ли выбранная обтачка в этой битовой маске
                let selectedFacing = 0
                // если комб - 4
                // если пройма - 2
                // если неклайн - 1
                // если неклайн и пройма - 3
                // найти рукав
                const sleeveFacingParam = this.designElements.wm_dr_sleeves[0].params[2002]
                let neckFacing = 0
                const necklineFacingAttr = this.designElementsWithUserSettings.wm_dr_front_necks[0].attributes.find(a => a.type === 'neckline_facing')
                if (necklineFacingAttr && necklineFacingAttr.code.indexOf('with_facing') > -1) {
                  neckFacing = 1
                }
                if (sleeveFacingParam === 2) {
                  selectedFacing = 4
                } else if (sleeveFacingParam === 1 && neckFacing === 1) {
                  selectedFacing = 3
                } else if (sleeveFacingParam === 1) {
                  selectedFacing = 2
                } else if (neckFacing === 1) {
                  selectedFacing = 1
                }
                const bitMaskMap = {
                  0: 16,
                  1: 8,
                  2: 4,
                  3: 2,
                  4: 1,
                }
                const currentBitValue = bitMaskMap[selectedFacing]
                if (!(liningParam & currentBitValue)) {
                  canAddFilter = false
                }
              } else {
                canAddFilter = false
              }
            }
            if (canAddFilter) {
              const attrs = settings.attrs.filter(a => a.code.indexOf(type.replace('_type', '')) === 0)
              if (attrs.length) {
                result.push({
                  nodeCode: this.designSettings[i].nodeCode,
                  settings: {
                    attrType: type,
                    attrs,
                    name: settings.name[j],
                  },
                })
              }
            }
          })
        }
      }
      return result
    },
    // Текущие настройки. Тут все настройки, не только выбранные пользователем
    designSettingsActive () {
      return getNodeSettingsActiveValues(this.designElementsWithUserSettings, this.designSettingsNormalized)
    },
    designSettingsActiveFlat () {
      return Object.values(this.designSettingsActive)
    },
    // тип материала. На данный момент woven и knit
    designFabric () {
      const keys = Object.keys(this.designElementsWithUserSettings)
      switch (this.designCategory) {
        case 'wm_dresses':
          return getDesignFabric(this.designElementsWithUserSettings['wm_dr_silhouettes'])
        case 'wm_tops':
          return getDesignFabric(this.designElementsWithUserSettings['wm_tp_silhouettes'])
        case 'wm_jumpsuits':
          return getDesignFabric(this.designElementsWithUserSettings['wm_jm_silhouettes'])
        case 'wm_pants':
          return getDesignFabric(this.designElementsWithUserSettings['wm_pn_silhouettes'])
        case 'wm_skirts':
          return getDesignFabric(this.designElementsWithUserSettings['wm_sk_silhouettes'])
        case 'mn_shirts':
          return getDesignFabric(this.designElementsWithUserSettings['mn_sh_silhouettes'])
        case 'mn_tshirts':
          return getDesignFabric(this.designElementsWithUserSettings['mn_ts_silhouettes'])
        case 'mn_pants':
          return getDesignFabric(this.designElementsWithUserSettings['mn_pn_silhouettes'])
        default:
          if (keys.length > 0) {
            const silKey = keys.find(k => k.includes('silhouettes'))
            return getDesignFabric(this.designElementsWithUserSettings[silKey || keys[0]])
          } else {
            return undefined
          }
      }
    },
    fabricTypeDefaultValue () {
      return getFabricTypeDefaultValue(this.designFabric)
    },
    fabric () {
      if (!this.fabricId) {
        return undefined
      }
      return this.fabricsList.find(item => item.id === this.fabricId)
    },
    displayNodeElementsList () {
      return !!this.activeNode
    },
    // активная нода
    activeNode () {
      if (!this.activeNodeCode) {
        return undefined
      }
      return this.nodes.find(item => item.code === this.activeNodeCode)
    },
    higherPriorityNodesCodes () {
      let currentNodePriority = this.activeNode?.priority
      if (!currentNodePriority) {
        return []
      }
      const mutualFilteringNodes = [
        ['wm_dr_back_closures', 'wm_dr_front_necks'],
        ['wm_tp_back_closures', 'wm_tp_front_necks'],
      ]
      const codes = this.nodes.filter(item => item.priority < currentNodePriority).map(item => item.code)
      const pair = mutualFilteringNodes.find(el => el.includes(this.activeNode?.code))
      if (pair) {
        const other = pair.filter(el => el !== this.activeNode?.code)
        if (other && other.length) {
          other.forEach(code => {
            if (!codes.includes(code)) {
              codes.push(code)
            }
          })
        }
      }
      return codes
    },
    compatibleElementsIdsForActiveNode () {
      if (this.higherPriorityNodesCodes.length === 0) {
        return ''
      }
      return this.higherPriorityNodesCodes.reduce((acc, nodeCode) => {
        if (!this.designElementsWithUserSettings[nodeCode]?.length) {
          return acc
        }
        this.designElementsWithUserSettings[nodeCode].forEach(item => {
          acc.push(item.id)
        })
        return acc
      }, [])
        .sort()
        // отдаем строку вместо массива, потому что иначе vue отображает обновление даже при одинаковом содержимом
        .join(',')
    },
    // акивная нода и нода-бэксайд для нее, если есть, либо основная нода для нее, если сама эта нода является бэксайд
    activeNodes () {
      if (!this.activeNode) {
        return []
      }
      if (!this.activeNode.backsideCode) {
        return [
          ...this.nodes.filter(item => item.backsideCode === this.activeNode.code),
          this.activeNode,
        ]
      }

      return [
        this.activeNode,
        ...this.nodes.filter(item => item.code === this.activeNode.backsideCode),
      ]
    },
    elementColors () {
      return colors.map(item => ({
        colorCode: item.code, // hexToRgb(item.code),
        name: item.name,
      }))
    },
    designElementIdsByNodes () {
      let elements = { ...this.designElementsWithUserSettings }
      Object.keys(elements).forEach(nodeCode => {
        elements[nodeCode] = elements[nodeCode].map(item => item.id)
      })

      return elements
    },
    designElementIdsFlatList () {
      let elements = Object.keys(this.designElementIdsByNodes).reduce((acc, nodeCode) => ([
        ...acc,
        ...this.designElementIdsByNodes[nodeCode],
      ]), [])
      elements.sort()
      return elements
    },

    designDetailsDescItems () {
      const items = []
      const f = code => this.nodes.find(n => n.code.indexOf(code) > -1)
      if (f('mn_')) {
        const configs = [
          [
            {
              code: 'mn_sh_silhouettes',
              items: [
                ['attr', 'fabric_type', ''],
                ['field', 'name', ' '],
              ],
            },
            {
              code: 'mn_sh_necklines',
              items: [
                ['field', 'name', ', with '],
              ],
            },
            {
              code: 'mn_sh_sleeves',
              items: [
                ['attr', 'shirt_sleeve_length', ' and ', ' length sleeves'],
              ],
            },
          ],
          [
            {
              code: 'mn_ts_silhouettes',
              items: [
                ['attr', 'fabric_type', ''],
              ],
            },
            {
              code: 'mn_ts_necklines',
              items: [
                ['field', 'name', ' '],
              ],
            },
            {
              code: 'mn_ts_silhouettes',
              items: [
                ['field', 'name', ' '],
              ],
            },
            {
              code: 'mn_ts_sleeves',
              items: [
                ['attr', 'shirt_sleeve_length', ', with ', ' length sleeves'],
              ],
            },
            {
              code: 'mn_ts_pockets',
              items: [
                ['field', 'name', ' and '],
              ],
            },
          ],
          [
            {
              code: 'mn_pn_silhouettes',
              items: [
                ['attr', 'fabric_type', ''],
                ['field', 'name', ' '],
              ],
            },
            {
              code: 'mn_pn_waist_bands',
              items: [
                ['field', 'name', ' and ', ' waistband'],
              ],
            },
          ],
        ]
        const config = configs.find(c => f(c[0].code))
        if (!config) {
          return []
        }
        config.forEach((itemsGroup, i) => {
          const node = f(itemsGroup.code)
          const el = this.designElements[itemsGroup.code]?.[0]
          if (node && el) {
            let name = ''
            itemsGroup.items.forEach((configItem, j) => {
              let prefix = j === 0 ? '' : configItem[2]
              let suffix = configItem?.[3] || ''
              if (configItem[0] === 'attr') {
                let attr = this.getElemAttrByType(el, configItem[1])
                if (attr) {
                  name += prefix + attr.code === 'fabric:knits' ? 'knit' : attr.name.toLowerCase() + suffix
                }
              } else if (configItem[0] === 'field') {
                name += prefix + el[configItem[1]].trim().toLowerCase() + suffix
              }
            })
            if (name && i === 0) {
              name = name[0].toUpperCase() + name.substring(1)
            }
            items.push(this.makeDescItem(name, el, node, '', itemsGroup.items[0][2]))
          }
        })
        return items
      }

      for (let node of this.nodes) {
        const elem = this.designElements[node.code]?.[0]
        if (!elem) {
          continue
        }

        const nodeType = node.code.substring(6)
        let attr
        let attrTypes
        let parts = []
        let elemName = elem.name.trim().toLowerCase()

        switch (nodeType) {
          case 'silhouettes':
            switch (node.code) {
              case 'wm_tp_silhouettes':
                attrTypes = ['fabric_type', 'top_length_type', 'top_silhouette_type']
                break
              case 'wm_jm_silhouettes':
                attrTypes = ['silhouette', 'pants_length_type', 'fabric_type']
                break
              case 'wm_pn_silhouettes':
                attrTypes = ['pants_style_type', 'pants_length_type', 'fabric_type']
                break
              case 'wm_sk_silhouettes':
                attrTypes = ['silhouette_type', 'length_type', 'fabric_type']
                break
              case 'mn_ts_silhouettes':
                attrTypes = ['fabric_type']
                break
              default:
                attrTypes = ['silhouette_type', 'length_type', 'fabric_type']
            }

            for (let attrType of attrTypes) {
              let attr = this.getElemAttrByType(elem, attrType)
              if (attr) {
                parts.push(attr.code === 'fabric:knits' ? 'knit' : attr.name.toLowerCase())
              }
            }
            if (parts.length) {
              items.push(this.makeDescItem(parts.join(' '), elem, node, ' '))
            }

            switch (node.code) {
              case 'wm_dr_silhouettes':
              case 'wm_jm_silhouettes':
                attr = this.getElemAttrByType(elem, 'variation_type')
                break
              case 'wm_pn_silhouettes':
                attr = this.getElemAttrByType(elem, 'waist')
                break
              case 'mn_sh_silhouettes':
              case 'mn_ts_silhouettes':
                items.push(this.makeDescItem(elem.name.toLowerCase(), elem, node, '', ''))
                break
              default:
                attr = null
            }

            items.push(
              this.makeDescItem(this.designTypes[this.designOptions.designCategory] || '', null, null, attr ? ' with ' : ', '),
            )
            if (attr) {
              items.push(this.makeDescItem(attr.name.toLowerCase(), elem, node))
            }
            break

          case 'front_necks':
            items.push(this.makeDescItem(elem.name.toLowerCase(), elem, node, this.designElements.wm_dr_silhouettes ? ', ' : ' and '))
            break

          case 'back_necks':
            if (!this.designElements.wm_dr_silhouettes) {
              items.push(this.makeDescItem(elem.name.toLowerCase(), elem, node))
            }
            break

          case 'sleeves':
            attr = this.getElemAttrByType(elem, 'sleeve_length_type')
            if (!attr) {
              attr = this.getElemAttrByType(elem, 'shirt_sleeve_length')
            }
            if (!attr) {
              continue
            }

            if (attr.code.endsWith('sleeveless')) {
              items.push(this.makeDescItem('sleeveless', elem, node))
            } else {
              let text = attr.name.toLowerCase() + ' length'

              attr = this.getElemAttrByType(elem, 'sleeve_variation_type')
              if (attr) {
                text += ' ' + attr.name.toLowerCase()
              }
              if (!text.endsWith('sleeves')) {
                text += ' sleeves'
              }
              items.push(this.makeDescItem(text, elem, node))
            }
            break

          case 'ruffles':
            if (!elemName.startsWith('no ruffle')) {
              items.push(this.makeDescItem(elemName, elem, node, ', ', 'with '))
            }
            break

          case 'waist_bands':
            if (!this.designElements.wm_sk_silhouettes) {
              items.push(this.makeDescItem(elemName, elem, node, ', ', 'with '))
            }
            break

          case 'necklines':
            items.push(this.makeDescItem(elemName, elem, node, ', with ', 'with '))
            break

          case 'pockets':
            if (node.code === 'mn_ts_pockets') {
              items.push(this.makeDescItem(elemName, elem, node, '', 'and '))
            }
        }
      }

      if (items[0]?.text) {
        items[0].text = items[0].text[0].toUpperCase() + items[0].text.substring(1)
      }

      return items
    },
    designDetailsString () {
      if (!this.designDetailsDescItems || !this.designDetailsDescItems.length) {
        return ''
      }
      let resultStrings = []
      const itemsCount = this.designDetailsDescItems.length
      this.designDetailsDescItems.forEach((item, i) => {
        if (item.prefix) {
          resultStrings.push(item.prefix)
        }
        if (item.text) {
          resultStrings.push(item.text)
        }
        if (item.sep && i !== itemsCount - 1) {
          resultStrings.push(item.sep)
        }
      })
      return resultStrings.join('')
    },
    hasSideClosure () {
      return this.checkSideClosureForElements(this.designElements)
    },
    changedSettingCodes () {
      let settingCodes = this.designSettingsActiveFlat
      const attrName = Object.keys(this.designElementsWithUserSettings).filter(key => key.includes('front_neck'))?.[0]
      const attrNameSleeve = Object.keys(this.designElementsWithUserSettings).filter(key => key.includes('_sleeves'))?.[0]
      if (attrName && attrNameSleeve) {
        let selectedFacing = 'facing:no'
        // если комб - 4
        // если пройма - 2
        // если неклайн - 1
        // если неклайн и пройма - 3
        // найти рукав
        const sleeveFacingParam = this.designElements[attrNameSleeve]?.[0]?.params[2002]
        let neckFacing = 0

        const necklineFacingAttr = this.designElementsWithUserSettings[attrName]?.[0].attributes?.find(a => a.type === 'neckline_facing')
        if (necklineFacingAttr && necklineFacingAttr.code.indexOf('with_facing') > -1) {
          neckFacing = 1
        }
        if (sleeveFacingParam === 2) {
          selectedFacing = 'facing:all_in_one'
        } else if (sleeveFacingParam === 1 && neckFacing === 1) {
          selectedFacing = 'facing:neckline_armhole'
        } else if (sleeveFacingParam === 1) {
          selectedFacing = 'facing:armhole'
        } else if (neckFacing === 1) {
          selectedFacing = 'facing:neckline'
        }
        if (!settingCodes.includes(selectedFacing)) {
          settingCodes.push(selectedFacing)
        }
      }
      return settingCodes
    },
    ...mapState(['seamAllowance']),
    ...mapState('fitEditor', ['mannequinSizes']),
    ...mapGetters(['getPatternsFontName']),
    ...mapGetters('fitEditor', ['mannequinWithSizeParams']),
    ...mapGetters('user', ['demoMode', 'downloadLeft']),
    ...mapGetters('designConstructorHistory', ['undoAvailable', 'redoAvailable', 'currentStepName', 'nextStepName']),
  },

  methods: {
    async setInternalLines (val) {
      this.InternalLines = val
      await this.getPatternsPreview()
    },
    async selectSize (val) {
      this.mannequinName = val.name
      this.mannequinIdNew = val.mannequinId
      if (val.newMennequinId) this.mannequinId = val.newMennequinId
      else {
        const { mannequinId } = await createMannequin(val.params, val.type)
        this.mannequinId = mannequinId
        if (!this.isCustomModel) {
          this.fitChartsStore.setNewMennequin(mannequinId, val.mannequinId)
          this.fitChartsStore.selectedMannequinId = val.mannequinId
        } else {
          this.fitModelsStore.selectedModel = {
            ...val,
            newMennequinId: mannequinId,
          }
          this.fitModelsStore.modelsForEdit[this.mannequinType].map(el => {
            if (el.mannequinId === val.mannequinId) { el.newMennequinId = mannequinId }
            return el
          })
        }
      }
      if (!this.mannequinEase?.[val.newMennequinId]) {
        this.ease = {}
        this.fabricType = this.fabricTypeDefaultValue
        this.fit = this.defaultFit
      }
      await this.getPatternsPreview()
    },
    setColor (color) {
      localStorage.setItem('activeColor', JSON.stringify({ color }))
      this.activeColorSelected = color
    },
    createColorWays () {
      this.colorWays = true
      const app = document.querySelector('#app')
      app.classList.add('overflow-hidden')
    },
    saveDesignFromColorWays (colorIndex) {
      this.displaySaveDesignModal = true
      this.colors.inProgress = colorIndex
    },
    setSketchColor (sketchString) {
      if (sketchString === undefined) return ''
      if (this.activeColorSelected) {
        try {
          const color = sketchString
            .split('data-fill-color="')[1]
            .split('"')[0]

          return sketchString.replaceAll(color, this.activeColorSelected)
        } catch (e) {
          console.warn(e.message)
          return sketchString
        }
      } return sketchString
    },
    handleResize () {
      this.screenHeight = document.documentElement.clientHeight
    },
    async setDesignCategory (category) {
      this.designCategory = category
      if (category) {
        await this.loadNodes(category)
      }
    },
    async loadApplicationDetails () {
      try {
        let data = await fetchApplicationDetails()
        if (data.designCategoryPrefixes) {
          this.setDesignCategoryPrefixes(data.designCategoryPrefixes)
        }
        if (data.mannequinCategories) {
          this.setMannequinCategories(data.mannequinCategories)
        }
      } catch (e) {
        // todo: тут возможно стоит сделать редирект на страницу ошибки, так как данные не могут буть получены если сессия невалидна
        console.log(e)
      }
    },
    async setDataInitial (data) {
      console.log('data', data)
      if (data.internalLines) this.InternalLines = Number(data.internalLines) === 1
      if (data.designCategory) this.setDesignCategory(data.designCategory)
      if ('isCustom' in data) {
        if (typeof data.isCustom === 'boolean') this.fitChartsStore.isCustom = data.isCustom
        else this.fitChartsStore.isCustom = data.isCustom === 'true'
      }
      if (data.fitChartsId) {
        this.fitChartsId = data.fitChartsId
        await this.fitChartsStore.getOneSelected(this.fitChartsId)
      }
      if (!this.fitChartsStore.selectedChart?.mannequins?.length) {
        this.fitChartsStore.isCustom = false
        await this.fitChartsStore.getListStandart()
        await this.fitChartsStore.getOneSelected(this.fitChartsStore.standartCharts.list[0]?._id)
      }
      this.activeColorSelected = data.color && !data.fabricId ? data.color : undefined
      if (data.seamAllowance) {
        this.setSeamAllowance(Number(data.seamAllowance), false)
      } else this.setSeamAllowance(0, false)
      if (data.designName) this.designName = data.designName

      if (!data.mannequinIdNew && data.mannequinParams) {
        let dataNewMan = await this.fitChartsStore.createMannequin(data.mannequinParams, data.mannequinType)
        data.mannequinIdNew = dataNewMan.mannequinId
      }
      if (!data.mannequinId && data.mannequinParams) {
        const { mannequinId } = await createMannequin(data.mannequinParams, data.mannequinType)
        data.mannequinId = mannequinId
      }

      if (!data.mannequinId && data.mannequinIdNew) {
        let manParams = await this.fitChartsStore.getByMan(data.mannequinIdNew)
        const { mannequinId } = await createMannequin(manParams.sizeParams, manParams.type)
        data.mannequinId = mannequinId
        data.mannequinType = manParams.type
      }

      if (data.mannequinId) this.mannequinId = data.mannequinId

      if (data.mannequinId && !data.mannequinIdNew) {
        let { sizeParams, mannequinType } = await fetchMannequin(data.mannequinId)
        let dataNewMan = await this.fitChartsStore.createMannequin(sizeParams, mannequinType)
        data.mannequinIdNew = dataNewMan.mannequinId
      }

      if (data.mannequinType) this.mannequinType = data.mannequinType

      if (data.mannequinIdNew) {
        this.mannequinIdNew = data.mannequinIdNew
        this.fitChartsStore.setNewMennequin(data.mannequinId, data.mannequinIdNew)
        this.fitChartsStore.selectedMannequinId = data.mannequinIdNew
      }

      this.fabricId = Number(data.fabricId) || undefined
      this.mannequinEase = {}
      if (data.mannequinEase) {
        if (
          data.mannequinEase[this.mannequinIdNew]?.ease ||
          data.mannequinEase[this.mannequinIdNew]?.fabricType ||
          data.mannequinEase[this.mannequinIdNew]?.fit
        ) {
          this.mannequinEase = data.mannequinEase
        } else {
          for (let key in data.mannequinEase) {
            this.mannequinEase[key] = {
              ease: { ...data.mannequinEase[key] },
              fabricType: data.fabricType,
              fit: data.fit,
            }
          }
        }
      } else {
        this.mannequinEase[this.mannequinIdNew] = {
          ease: data.ease || {},
          fabricType: data.fabricType,
          fit: data.fit,
        }
      }
      this.ease = this.mannequinEase[this.mannequinIdNew]?.ease
      this.fabricType = this.mannequinEase[this.mannequinIdNew]?.fabricType
      this.fit = this.mannequinEase[this.mannequinIdNew]?.fit
      this.isCustomModel = Number(data.isCustomModel) || 0
      if (this.isCustomModel) {
        this.fitModelsStore.paramsPageFitModel.type = data.mannequinType
        if (!this.fitModelsStore.modelsForEdit[data.mannequinType]?.length) {
          await this.fitModelsStore.getListFitModelPage()
          this.fitModelsStore.selectedModel = this.fitModelsStore.modelsForEdit[data.mannequinType].find(el => {
            return el.mannequinId === data.mannequinIdNew
          })
        }
        if (!this.fitModelsStore.selectedModel) {
          let dataNewMan = await this.fitChartsStore.getByMan(data.mannequinIdNew)
          let size = {
            ...dataNewMan,
            name: 'Custom',
            params: dataNewMan.sizeParams,
            newMennequinId: this.mannequinId,
          }
          this.fitModelsStore.selectedModel = size
          this.fitModelsStore.modelsForEdit[data.mannequinType].push(this.fitModelsStore.selectedModel)
        }
        this.fitChartsStore.selectedMannequinId = this.fitChartsStore.selectedChart?.mannequins[0]
      } else {
        if (!this.fitChartsStore.selectedMannequin) {
          let dataNewMan = await this.fitChartsStore.getByMan(this.mannequinIdNew)
          let size = {
            ...dataNewMan,
            name: 'Custom',
            params: dataNewMan.sizeParams,
            newMennequinId: this.mannequinId,
          }
          this.fitChartsStore.selectedChart.mannequins.push(size)
        }
      }
      if (!this.mannequinId) {
        this.error('Error', 'Fit model not loaded')
      }
      if (!this.mannequinIdNew) {
        this.error('Error', 'Fit model 1 not loaded')
      }
      if (!this.mannequinType) {
        this.error('Error', 'Mannequin type param not found')
      }
    },
    async fillInitialData (params) {
      if (this.$route.params.designId) {
        this.designUpdateInProgress = true
        const design = await fetchDesign(this.$route.params.designId)
        console.log('design', design)
        await this.setDataInitial({
          designCategory: design.categoryCode,
          isCustom: design.fitChart.type === 'CUSTOM',
          fitChartsId: design.fitChart.id,
          mannequinIdNew: design.mannequinId,
          color: design.color,
          fabricId: design.fabricId,
          mannequinEase: design.mannequinEase,
          ease: design.ease,
          fabricType: design.fabricType,
          fit: design.fit,
          seamAllowance: design.seamAllowance,
          collection: design.collection,
        })
        try {
          let data = await fetchCategoryElementsData(design.categoryCode, design.elements, design.elementsSettings)
          this.designElements = transformServerDesignResponseToApplication(data)
          await this.setMultipleElementsSettings(design.elementsSettings)
          this.designUpdateInProgress = false
        } catch (e) {
          this.designUpdateInProgress = false
          console.error(e)
          throw new Error('Some elements are missing, cannot load design')
        }
        this.getPatternsPreview()
      } else {
        let category = null
        let elementsIds = null
        let settingIds = null
        const customPars = JSON.parse(params.customParams)
        if (this.$route.params.isNew) {
          await this.setDataInitial({
            designCategory: this.$route.params.designCategory,
            isCustom: this.$route.params.fitChartsIsCustom,
            fitChartsId: this.$route.params.fitChartsId,
            mannequinIdNew: this.$route.params.mannequinIdNew,
            mannequinId: this.$route.params.mannequinId,
            mannequinType: this.$route.params.mannequinType,
            mannequinName: this.$route.params.mannequinName,
            ease: {},
            fabricType: customPars.fabricType,
            fit: customPars.fit,
          })
          category = this.$route.params.designCategory
        } else if (this.$route.query.isTlLink) {
          let customParams = JSON.parse(this.$route.query.params)
          await this.setDataInitial({
            mannequinParams: customParams.mannequinParams,
            mannequinType: this.$route.query.type,
            mannequinEase: {},
            ease: customParams.ease,
            fabricType: customParams.fabricType,
            fit: customParams.fit,
            designCategory: this.$route.query.category,
          })
          category = this.$route.query.category
          elementsIds = customParams.elementIds
          settingIds = customParams.settingCodes
        } else if (this.$route.query.params) {
          let customParams = JSON.parse(this.$route.query.params)
          await this.setDataInitial({
            mannequinId: customParams.mannequinId,
            mannequinName: customParams.mannequinName,
            mannequinType: this.$route.query.type,
            mannequinEase: customParams.mannequinEase,
            mannequinIdNew: this.$route.query.mannequinIdNew,
            isCustom: this.$route.query.fitChartsIsCustom,
            fitChartsId: this.$route.query.fitChartsId,
            isCustomModel: this.$route.query.isCustomModel || 0,
            designCategory: this.$route.query.category,
            seamAllowance: customParams.seamAllowance,
            fabricType: customParams.fabricType,
            fit: customParams.fit,
            ease: customParams.ease,
            internalLines: customParams.internalLines,
          })
          category = this.$route.query.category
          elementsIds = customParams.elementIds
          settingIds = customParams.settingCodes
        } else if (params.customParams) {
          let customParams = JSON.parse(params.customParams)
          await this.setDataInitial({
            mannequinEase: customParams.mannequinEase,
            ease: customParams.ease,
            fabricType: customParams.fabricType,
            fit: customParams.fit,
            designCategory: params.designCategory,
            color: params.fillColor,
            mannequinId: params.mannequinId,
            mannequinType: params.mannequinType,
            seamAllowance: customParams.seamAllowance,
          })
          category = params.designCategory
          elementsIds = customParams.elementIds
          settingIds = customParams.settingCodes
        }
        if (elementsIds) {
          let data = await fetchCategoryElementsData(category, elementsIds)
          this.designElements = transformServerDesignResponseToApplication(data)
          this.initWithDefaultSettings()
          if (settingIds) {
            await this.setMultipleElementsSettings(settingIds)
          }
          await this.getPatternsPreview()
        } else {
          await this.loadDefaultDesignForCategory(category)
        }
      }
      if (!params.measurements) {
        this.error('Invalid parameters', 'Parameters must include "measurements" option.')
      }
      if (params.units?.unit && params.units?.format) {
        this.setUnitsIfNoUserPreferences({
          units: params.units?.unit,
          fractionBase: params.units.format === 'decimal' ? 10 : 8,
        })
      }
      // сейчас объект permissions является обязательным. Он всегда содержит changeMannequinType. Если параметр будет удален, он вновь может стать опциональным
      let permissions = params.permissions || {}
      if ('changeCategory' in permissions) {
        this.canChangeCategory = !!permissions.changeCategory && this.$store.state.isLoggedIn
      }
    },
    fillInitialFabricTypeValue () {
      if (this.fabricType !== undefined) {
        return
      }
      this.fabricType = this.fabricTypeDefaultValue
    },
    cleanUserSelectedElements () {
      this.designElementsUserSelected = this.designElementsUserSelected.filter(elementId => (
        this.designElementIdsFlatList.includes(elementId)
      ))
    },
    async getInitialParams () {
      let data = await fetchDesignerTokenData()
      if (data.tokenSrc) {
        console.dir("tokenSrc: ", data.tokenSrc)
        if (data.tokenSrc.customData !== null) {
          this.tokenSrc = true
        }
      }
      return data.designer
    },
    async loadNodes (categoryCode) {
      try {
        this.nodes = await fetchNodes(categoryCode)
      } catch (e) {
        this.error('Error', e.message)
      }
    },
    initWithDefaultSettings () {
      this.designElementsUserSelected = []
      this.elementsSettingsUserSelected = {}
      this.elementsCustomized = {}
      this.designUpdateInProgress = false
      // возможно, тут нужно менять fabricType независимо от начального значения
      this.fillInitialFabricTypeValue()
    },

    async loadDefaultDesignForCategory (categoryCode) {
      try {
        this.designUpdateInProgress = true
        let response = await fetchDefaultDesign(categoryCode)
        this.designElements = transformServerDesignResponseToApplication(response)
        this.initWithDefaultSettings()
        await this.getPatternsPreview()
        this.addToHistory('Load default design')
      } catch (e) {
        this.designUpdateInProgress = false
        this.error('Error', e.message)
      }
    },
    toolbarHandle (item) {
      switch (item) {
        case 'copy-link':
          this.copyLink()
          break
        case 'undo':
          this.historyUndo()
          break
        case 'redo':
          this.historyRedo()
          break
        case 'measurements':
          this.openLink('specs')
          break
        case 'fit':
          this.openLink('ease-page')
          break
        case 'auto-grading':
          if (this.demoMode) {
            this.titleForModalUpradePlan = 'Autograding is not available'
            this.svgForModalUpradePlan = `<path d="M24 22.6c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1 1.1 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6c0 .4-.3.6-.6.6zm-8-2.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1s2.1-.9 2.1-2.1c0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6c0 .4-.3.6-.6.6zm-8-2.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1 1.1 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6c0 .4-.3.6-.6.6zm16-10.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1 1.1 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6c0 .4-.3.6-.6.6zm-8-2.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1s2.1-.9 2.1-2.1c0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6c0 .4-.3.6-.6.6zm-8-2.7c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1 1.1 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6c0 .4-.3.6-.6.6zm16-6.5c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1-1.1 0-2.1.9-2.1 2.1 0 1.2 1 2.1 2.1 2.1zm0-2.7c.3 0 .6.3.6.6s-.3.6-.6.6-.6-.3-.6-.6.3-.6.6-.6zm-8-1.5c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1s2.1-.9 2.1-2.1c0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6c0 .4-.3.6-.6.6zM8 6.6c-1.1 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1 1.1 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zm0 2.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6c0 .4-.3.6-.6.6z"/>`
            this.displayModalUpradePlan = true
          } else this.openLink('auto-grading')
          break
        case 'sewing':
          this.displaySewingModal = true
          break
        case 'fabric':
          this.displayFabricModal = true
          break
        case 'sketch':
          this.displaySketch = !this.displaySketch
          break
        case 'pattern':
          this.displayPattern = !this.displayPattern
          break
        case 'tech-pack':
          if (this.demoMode) {
            this.titleForModalUpradePlan = 'Tech pack is not available'
            this.svgForModalUpradePlan = `
              <path d="M20 21.9h-8c-.4 0-.8.3-.8.8s.3.8.8.8h8c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zm0-4h-8c-.4 0-.8.3-.8.8s.3.8.8.8h8c.4 0 .8-.3.8-.8s-.4-.8-.8-.8z"/>
              <path d="M26.1 12v-.3c0-.5-.2-1-.6-1.4l-5.9-6.4c-.4-.4-1-.7-1.5-.7H8c-1.1 0-2.1.9-2.1 2.1v21.3c0 1.1.9 2.1 2.1 2.1h16c1.1 0 2.1-.9 2.1-2.1V12zm-7.6-7.1 5.8 6.3h-5.7c-.3 0-.6-.3-.6-.6V4.8c.2 0 .4 0 .5.1zM24 27.3H8c-.3 0-.6-.3-.6-.6V5.3c0-.3.3-.5.6-.5h8.6v5.9c0 1.1.9 2.1 2.1 2.1h5.9v13.9c0 .3-.3.6-.6.6z"/>
            `
            this.displayModalUpradePlan = true
          } else this.openLink('tech-pack')
          break
        case 'marker':
          if (this.demoMode) {
            this.titleForModalUpradePlan = 'Marker is not available'
            this.svgForModalUpradePlan = `
              <path fill="#09373d" d="M23.5 13.3h-6.4V8.7C17.2 6.1 15 4 12.4 4H9.3C6.7 4 4.6 6.1 4.6 8.7v15.6c0 2.5 2.1 4.6 4.6 4.6h14.3c3.2 0 5.8-2.6 5.8-5.8v-4.2c0-3-2.6-5.6-5.8-5.6zM9.3 5.5h3.1c1.8 0 3.2 1.5 3.2 3.2v11.6c-.5-.3-1.2-.6-1.8-.6H9.2c-1.2 0-2.3.5-3.1 1.2V8.7c0-1.8 1.4-3.2 3.2-3.2zm18.5 17.7c0 2.3-1.9 4.2-4.2 4.2H9.2c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1h4.6c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8H9.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h4.8v-1.5H9.3c-1.1 0-2 .9-2 2s.9 2 2 2h4.5c1.8 0 3.2-1.4 3.3-3.2v-8.4h6.4c2.3 0 4.2 1.9 4.2 4.3v4.2z"/>
            `
            this.displayModalUpradePlan = true
          } else this.displayMarkerModal = true
          break
        case 'ai-designer':
          if (this.demoMode) {
            this.titleForModalUpradePlan = 'AI designer is not available'
            this.svgForModalUpradePlan = `
              <path d="M12.6 6.4c-1.8-1.8-4.8-1.8-6.6 0S4.1 11.1 6 13l14.1 14.1c.9.9 2.1 1.4 3.3 1.4 1.2 0 2.4-.5 3.3-1.4.9-.9 1.4-2.1 1.4-3.3s-.5-2.4-1.4-3.3L12.6 6.4zm13 19.6c-1.2 1.2-3.2 1.2-4.5 0L7 11.9c-1.2-1.2-1.2-3.2 0-4.5s3.2-1.2 4.5 0l14.1 14.1c1.3 1.3 1.3 3.3 0 4.5zM17.1 6.7l2.2 1.1 1.1 2.2c.1.2.4.4.6.4s.5-.1.6-.4l1.1-2.2 2.2-1.1c.2-.1.4-.4.4-.6 0-.3-.1-.5-.4-.6l-2.2-1.1-1.1-2.2c-.2-.5-1-.5-1.3 0l-1.1 2.2L17 5.5c-.2.1-.4.4-.4.6.2.2.3.5.5.6zm2.7-1.5c.2-.1.3-.2.3-.3l.9-1.7.9 1.7c0 .1.1.2.3.3l1.7.9-1.7.9c-.2 0-.3.1-.3.2L21 8.9l-.9-1.7c0-.1-.1-.2-.3-.3L18.1 6l1.7-.8zm9.1 6.9-1.3-.6-.6-1.3c-.2-.5-1-.5-1.3 0l-.6 1.3-1.3.6c-.2.1-.4.4-.4.6 0 .3.1.5.4.6l1.3.6.6 1.3c.1.2.4.4.6.4.3 0 .5-.1.6-.4l.6-1.3 1.3-.6c.2-.1.4-.4.4-.6.1-.2-.1-.5-.3-.6zm-1.8 1-.3.3-.4.8-.4-.8-.3-.3-.8-.4.8-.4.3-.3.4-.8.4.8.3.3.8.4-.8.4zm-16.9 8.3-1.3-.6-.6-1.3c-.2-.5-1-.5-1.3 0l-.6 1.3-1.3.6c-.2.1-.4.4-.4.6 0 .3.1.5.4.6l1.3.6.6 1.4c.2.2.4.4.7.4.3 0 .5-.1.6-.4l.6-1.3 1.3-.6c.2-.1.4-.4.4-.6 0-.3-.2-.5-.4-.7zm-1.8 1.1-.3.3-.4.8-.4-.8-.3-.3-.8-.4.8-.4.3-.3.4-.8.4.8.3.3.8.4-.8.4z"/>
            `
            this.displayModalUpradePlan = true
          }
          break
      }
      this.hideToolbarOnMobile()
    },
    hideNavOnMobile () {
      if (this.$q.screen.lt.sm) {
        return this.$refs.layout.hideLeftSidebar?.()
      }
    },
    hideToolbarOnMobile () {
      if (this.$q.screen.lt.md) {
        return this.$refs.layout.hideRightSidebar?.()
      }
    },
    selectNode (code, noClose = false) {
      if (this.activeNodeCode === code) {
        if (noClose) {
          return
        }
        this.activeNodeCode = undefined

        return
      }
      this.activeNodeCode = code
      this.hideNavOnMobile()
    },
    unselectNode () {
      this.activeNodeCode = undefined
    },
    async setMultipleElementsSettings (attrCodes = []) {
      this.selectElementSettingInProgress = true
      let promises = []
      try {
        for (let code of attrCodes) {
          let attr = getNodeAttributeByCode(this.nodes, code)
          if (!attr) {
            continue
          }
          // атрибут добавлен, необходимо найти элементы дизайна и обновить им изображения и параметры, если те неактуальны
          promises.push(this.updateElementsAttribute(attr))
        }

        await Promise.all(promises)
      } catch (e) {
        console.log(e)
      }
      this.selectElementSettingInProgress = false
    },
    // метод не вызывает обновление patternsPreview
    // обновление элементов, поддерживающих данную настройку
    async updateElementsAttribute ({ code, type, name }) {
      this.$set(this.elementsSettingsUserSelected, type, code)
      // ищем в текущем дизайне все элементы, которые надо обновить
      let elems = getElementsCompatibleWithCode(this.designElementsWithUserSettings, code, this.nodes)
      // обновляем данные каждого элемента
      let promises = []
      let updateSingleElement = async (elem) => {
        // добавить к коду другие активные настройки этой же ноды
        let resultCode = code
        const otherNodeSettings = this.designSettingsNormalized.filter(item => {
          return item.nodeCode === elem.nodeCode && item.settings.attrType.replace('_type', '').indexOf(code.split(':')[0]) === -1
        })
        if (otherNodeSettings) {
          otherNodeSettings.forEach(item => {
            const elements = this.designElementsWithUserSettings[elem.nodeCode]
            for (let i in elements) {
              const settingAttr = elements[i].attributes.find(a => a.code.indexOf(item.settings.attrType.replace('_type', '')) === 0)
              if (settingAttr) {
                if (this.elementsSettingsUserSelected[settingAttr.type]) settingAttr.code = this.elementsSettingsUserSelected[settingAttr.type]
                resultCode += ',' + settingAttr.code
              }
            }
          })
        }
        window._elem = elem
        let images = await fetchElementImages(elem.id, resultCode)
        if (!images) {
          this.$delete(this.designElements, elem.nodeCode)
          return
        }
        if (!(elem.id in this.elementsCustomized)) {
          this.$set(this.elementsCustomized, elem.id, {
            customAttributes: { [type]: { code, type, name } },
            svgBack: images.svgBack,
            svgFront: images.svgFront,
          })
        } else {
          this.elementsCustomized[elem.id].svgBack = images.svgBack
          this.elementsCustomized[elem.id].svgFront = images.svgFront
          this.$set(this.elementsCustomized[elem.id]['customAttributes'], type, { code, type, name })
        }
        this.$store.commit('nodesElements/SET_CUSTOMIZED_ELEMENTS', { ...this.elementsCustomized })
      }

      for (let elem of elems) {
        // кастомный элемент с таким кодом уже загружен, обновление не требуется
        if (this.elementsCustomized[elem.id]?.customAttributes[type]?.code === code) {
          continue
        }
        promises.push(updateSingleElement(elem))
      }
      await Promise.all(promises)
    },
    async selectElementSetting (attrType, attrCode) {
      this.selectElementSettingInProgress = true
      this.designUpdateInProgress = true
      try {
        let attr = getNodeAttributeByCode(this.nodes, attrCode)
        if (!attr) {
          throw new Error('Attribute not found in current nodes')
        }
        await this.updateElementsAttribute(attr)
        this.designUpdateInProgress = false
        await this.getPatternsPreview()
        this.addToHistory(`Select "${attr.name}"`)
      } catch (e) {
        this.designUpdateInProgress = false
        this.error('Error', e.message)
      }
      this.selectElementSettingInProgress = false
    },
    closeOptions () {
      this.activeOptionsList = undefined
    },
    toggleColorsPanel () {
      this.activeOptionsList = this.activeOptionsList === 'colors' ? undefined : 'colors'
    },
    isElementInDesign (elementId) {
      return Object
        .keys(this.designElementsWithUserSettings)
        .some(nodeCode => (
          this.designElementsWithUserSettings[nodeCode].some(item => item.id === elementId)
        ))
    },
    delEase () {
      this.ease = {}
      this.fit = defaultValues.FIT_SELECT
      this.fabricType = getFabricTypeDefaultValue(this.designFabric)
      this.mannequinEase = null
      this.selectElement(
        this.newSelectedElement.nodeCode,
        this.newSelectedElement.element,
        this.newSelectedElement.selectedByUser,
      )
      this.changeElementAgreeModal = false
    },
    async selectElement (nodeCode, element, selectedByUser = true) {
      if (
        Object.keys(this.easeByMan.ease).length > 0 ||
        this.easeByMan.fit !== defaultValues.FIT_SELECT ||
        this.easeByMan.fabricType !== getFabricTypeDefaultValue(this.designFabric)
      ) {
        this.changeElementAgreeModal = true
        this.newSelectedElement = {
          nodeCode: nodeCode,
          element: element,
          selectedByUser: selectedByUser,
        }
        return
      }
      let node = this.nodes.find(item => item.code === nodeCode)
      if (!node) {
        return
      }
      const id = element.id
      if (node.isMultiple) {
        let isUpdated = await this.toggleMultipleElement(nodeCode, element, selectedByUser)
        if (isUpdated) {
          this.addToHistory(`Select ${element.name}`)
        }
        this.hideElementsPanelInMobile()
        return
      }
      // если элемент уже присутствует, не нужно добавлять повторно
      if (!this.designElementIdsByNodes[nodeCode]?.includes(id)) {
        let isUpdated = await this.addSingleElementToDesign(nodeCode, element, selectedByUser)
        if (isUpdated) {
          this.addToHistory(`Select ${element.name}`)
        }
        this.hideElementsPanelInMobile()
      }
      this.fabricType = getFabricTypeDefaultValue(this.designFabric)
    },
    hideElementsPanelInMobile () {
      if (this.$q.screen.lt.md) {
        this.unselectNode()
      }
    },
    hideOptionsPanelInMobile () {
      if (this.$q.screen.lt.md) {
        this.closeOptions()
      }
    },
    unselectAll (nodeCode) {
      this.$delete(this.designElements, nodeCode)
      this.getPatternsPreview()
      this.addToHistory('Unselect element')
    },
    async toggleMultipleElement (nodeCode, element, selectedByUser) {
      const elementId = element.id
      if (this.isElementInDesign(elementId)) {
        this.removeElementFromDesign(elementId)
        return true
      }
      try {
        return await this.updateDesign(this.designCategory, elementId, selectedByUser)
      } catch (e) {
        console.log(e)
        this.error('Error', 'Unexpected server error')
      }
    },
    async addSingleElementToDesign (nodeCode, element, selectedByUser) {
      try {
        return await this.updateDesign(this.designCategory, element, selectedByUser)
      } catch (e) {
        console.log(e)
        this.error('Error', 'Unexpected server error')
      }
    },
    removeElementFromDesign (elementId) {
      Object.keys(this.designElements).forEach(nodeCode => {
        let idx = this.designElements[nodeCode].findIndex(item => item.id === elementId)

        if (idx > -1) {
          if (this.designElements[nodeCode].length === 1) {
            this.$delete(this.designElements, nodeCode)
          } else {
            this.designElements[nodeCode].splice(idx, 1)
          }
        }
      })
    },
    async updateDesign (designCategory, newElement, addedByUser, replaceAttrs) {
      const newElementId = newElement.id
      this.designUpdateInProgress = true
      const allDesignElements = this.designElementIdsFlatList
      const userSelectedElements = this.designElementsUserSelected.filter(
        elemId => String(newElementId) !== String(elemId))
      let defaultElements = allDesignElements
        .filter(elemId => (
          !userSelectedElements.includes(elemId) &&
          String(newElementId) !== String(elemId)
        ))
      if (newElement.defaultElementsIndex) {
        const defaultElementsDecoded = JSON.parse(newElement.defaultElementsIndex)
        if (defaultElementsDecoded && defaultElementsDecoded.length) {
          // заменить в списке элементов с нодами как тут
          defaultElementsDecoded.forEach(item => {
            let [nodeCode, elementId] = item.split(':')
            if (this.designElements[nodeCode] && this.designElements[nodeCode].length) {
              this.designElements[nodeCode].forEach(el => {
                const idx = defaultElements.indexOf(+el.id)
                if (idx > -1) {
                  defaultElements[idx] = +elementId
                }
              })
            }
          })
        }
      }
      try {
        const response = await fetchUpdateDesign(designCategory, newElementId, defaultElements, userSelectedElements,
          this.designSettingsActiveFlat, replaceAttrs)
        const updatedDesignElements = transformServerDesignResponseToApplication(response)

        const updatedDesignNodes = Object.keys(updatedDesignElements)
        const availableNodes = getCompatibleNodesList(
          this.nodes,
          extractAttributeIdsFromDesignElements(updatedDesignElements),
        )
        const requiredNodes = availableNodes.filter(node => node.isOptional === false)
        const hasAllRequiredElements = requiredNodes.every(node => updatedDesignNodes.includes(node.code))

        // если в новом дизайне не должно быть in-seam карманов, а они выбраны, то карман удалить
        if (this.checkSideClosureForElements(updatedDesignElements)) {
          const pockets = updatedDesignElements.wm_dr_pockets
          if (pockets && pockets[0].name.toLowerCase().indexOf('in-seam') > -1) {
            delete updatedDesignElements.wm_dr_pockets
          }
        }

        if (!hasAllRequiredElements) {
          const debugObj = {
            newDesignAttributeIds: extractAttributeIdsFromDesignElements(updatedDesignElements),
            compatibleNodesAttributes: availableNodes.map(el => ({
              groupName: el.groupName,
              excludedAttrIds: el.excludedAttrIds,
              requiredAttrIds: el.requiredAttrIds,
            })),
            requiredNodes: requiredNodes.map(el => ({ code: el.code })),
            missingNodes: requiredNodes.filter(node => !updatedDesignNodes.includes(node.code))
              .map(el => ({ code: el.code })),
          }
          console.log(debugObj)
          window.debugObj = debugObj
          this.error('Cannot add element', 'Cannot add this element to the current design')
          this.designUpdateInProgress = false
          return false
        }

        this.designElements = updatedDesignElements
        if (addedByUser) {
          this.designElementsUserSelected = [...this.designElementsUserSelected, newElementId]
        }

        // if women dress select back closure vent or slit & length mini or micro_mini
        for (let key in updatedDesignElements) {
          if (updatedDesignElements[key][0]?.id === newElement.id && updatedDesignElements.wm_dr_silhouettes) {
            let backVentOrSlit = updatedDesignElements[key][0]?.attributes.find(el => {
              return el.code === 'vent:back_slit' || el.code === 'vent:back_vent'
            })
            if (backVentOrSlit &&
              (this.elementsSettingsUserSelected.length_type === 'length:mini' ||
              this.elementsSettingsUserSelected.length_type === 'length:micro_mini')
            ) {
              return true
            }
          }
        }
        await this.setMultipleElementsSettings(Object.values(this.elementsSettingsUserSelected))
        // обновление лекал должно происходить только если обновлены элементы нод со свойством isDecor.
        // todo: узнать ноды всех обновленных элементов
        // получить список новых элементов, найти их ноды (они есть как ключи designElements), проверить каждую ноду на isDecor
        // проверка по текущей ноде недостаточна, т.к. могут быть обновлены элементы других нод

        // разблокируем интерфейс и сохраняем историю не дожидаясь обновления лекал
        this.getPatternsPreview()
      } catch (e) {
        // axios cancel - запрос отменен.
        if (e.name === 'cancelledBySameRequest') {
          // designUpdateInProgress в данном случае отменяется отменившей запрос функцией
          return false
        }
        this.designUpdateInProgress = false
        throw e
      }
      this.designUpdateInProgress = false
      return true
    },
    async getDebugParams () {
      let internalLines = this.InternalLines ? 1 : 2
      return await fetchDebug(
        internalLines,
        this.designElementIdsFlatList,
        this.mannequinId,
        this.easeByMan.fabricType,
        this.easeByMan.fit,
        this.seamAllowance,
        this.easeByMan.ease,
        this.designSettingsActiveFlat,
        this.getPatternsFontName,
        true,
        true,
      )
    },
    async getPatternsPreview (force = false) {
      this.patternPreviewUpdateInProgress = true
      let elementIds = this.designElementIdsFlatList
      let mannequinId = this.mannequinId
      let internalLines = this.InternalLines ? 1 : 2
      try {
        this.patternsPreview = await fetchPatternsPreview(
          internalLines,
          elementIds,
          mannequinId,
          this.easeByMan.fabricType,
          this.easeByMan.fit,
          this.seamAllowance,
          this.easeByMan.ease,
          this.designSettingsActiveFlat,
          this.getPatternsFontName,
          force,
        )
        this.patternsPreviewError = false
      } catch (e) {
        if (e.name === 'cancelledBySameRequest') {
          // patternPreviewUpdateInProgress не возвращаем в false, так как он был установлен другим запросом
          return
        }
        this.patternsPreview = undefined
        this.patternsPreviewError = true
        this.error('Error', e.message)
      }
      this.patternPreviewUpdateInProgress = false
    },
    // async getPatternsPreview (force = false) {
    //   this.patternPreviewUpdateInProgress = true
    //   let internalLines = this.InternalLines ? 1 : 2
    //   try {
    //     this.patternsPreview = await fetchPatternsPreview(
    //       internalLines,
    //       this.mannequinIdNew,
    //       this.easeByMan.fabricType,
    //       this.easeByMan.fit,
    //       this.seamAllowance,
    //       this.easeByMan.ease,
    //       this.changedSettingCodes,
    //       this.designAlgorithms,
    //       this.designParams,
    //     )
    //     this.patternsPreviewError = false
    //   } catch (e) {
    //     if (e.name === 'cancelledBySameRequest') {
    //       // patternPreviewUpdateInProgress не возвращаем в false, так как он был установлен другим запросом
    //       return
    //     }
    //     this.patternsPreview = undefined
    //     this.patternsPreviewError = true
    //     this.error('Error', e.message)
    //   }
    //   this.patternPreviewUpdateInProgress = false
    // },
    setActiveFabric ({ id }) {
      this.fabricId = id
      this.activeColorSelected = undefined
      this.hideOptionsPanelInMobile()
      this.addToHistory('Select fabric')
    },
    setActiveColor ({ colorCode, name }) {
      this.activeColorSelected = colorCode
      this.fabricId = undefined
      this.addToHistory(colorCode !== undefined ? `Select color ${name}` : 'Unselect color')
      this.hideOptionsPanelInMobile()
    },
    async saveDesign () {
      if (this.designSaveInProgress) {
        return
      }
      const elementIds = this.designElementIdsFlatList

      this.designSaveInProgress = true

      this.sketchSides.both = this.setSketchColor(this.sketchSides?.both)
      this.sketchSides.front = this.setSketchColor(this.sketchSides?.front)
      this.sketchSides.back = this.setSketchColor(this.sketchSides?.back)

      // let mannequinEase = ''
      // if (this.mannequinEase) {
      //   mannequinEase = {}
      //   for (let key in this.mannequinEase) {
      //     mannequinEase[key] = this.mannequinEase[key].ease
      //   }
      // }
      try {
        let { data } = await saveDesign({
          designName: this.designName,
          designCategory: this.designCategory,
          elementIds,
          settingCodes: this.designSettingsActiveFlat,
          ease: this.easeByMan.ease || {},
          sketch: this.sketchSides?.both || '',
          sketchFront: this.sketchSides?.front ? setViewBox(this.sketchSides.front) : '',
          sketchBack: this.sketchSides?.back ? setViewBox(this.sketchSides.back) : '',
          patternsPreview: this.patternsPreview,
          color: this.activeColorSelected,
          fabricId: this.fabricId,
          mannequinId: this.mannequinIdNew,
          mannequinName: this.mannequinName,
          fabricType: this.easeByMan.fabricType,
          fit: this.easeByMan.fit,
          seamAllowance: this.seamAllowance,
          fabricCode: this.designFabric.toUpperCase(),
          designDetails: this.designDetailsString,
          mannequinEase: JSON.stringify(this.mannequinEase),
          collectionId: this.userCollectionsStore.selectedCollection?.id || this.$route.query.collection,
          fitChart: {
            type: this.fitChartsStore.isCustom ? 'CUSTOM' : 'STANDART',
            id: this.fitChartsId,
          },
        })
        if (!data) {
          this.designSaveInProgress = false
          this.error('Error', 'Invalid response. Cannot save design')
          return
        } else {
          if (this.colorWays) {
            this.colors.saved = [...this.colors.saved, this.colors.inProgress]
            this.colors.inProgress = -1
          } else {
            this.$router.push(`/user/collections/${data.collection}`)
          }
        }
      } catch (e) {
        this.error('Error', e.message)
      }
      this.displaySaveDesignModal = false
      this.designSaveInProgress = false
    },
    error (title, message) {
      this.$emit('error', { title, message })
    },
    setSeamAllowance (val, updatePatternsPreview = true) {
      this.setSeamAllowanceStore(val)
      this.displaySeamAllowanceModal = false
      this.addToHistory('Select seam allowance')
      if (updatePatternsPreview) {
        this.getPatternsPreview()
      }
    },
    async addToHistory (name) {
      let data = {}
      this.historyFields.forEach(param => {
        data[param] = this[param]
      })
      // для компонентов
      data['nodesElementsComponent'] = await this.getCurrentState()
      this.push({ name, data })
    },
    async restoreHistoryState (data) {
      await this.restoreState(data['nodesElementsComponent'])
      this.historyFields.forEach(param => {
        if (param === 'seamAllowance') {
          this.setSeamAllowanceStore(data[param])
        } else {
          this[param] = data[param]
        }
      })
      this.$nextTick(() => {
        if (this.$refs['elementsPanel']) {
          this.$refs['elementsPanel'].scrollToActiveElement()
        }
      })
      await this.getPatternsPreview()
    },
    async historyUndo () {
      let data = await this.undo()
      await this.restoreHistoryState(data)
    },
    async historyRedo () {
      let data = await this.redo()
      await this.restoreHistoryState(data)
    },
    handleInstructionsError (message) {
      this.error('Error', message)
      this.displaySewingModal = false
    },
    /**
     * @param {object} elem
     * @param {string} attrType
     * @return {object|undefined}
     */
    getElemAttrByType (elem, attrType) {
      const customElement = this.elementsCustomized[+elem.id]
      if (customElement) {
        const customAttr = customElement?.customAttributes[attrType]
        if (customAttr) {
          return customAttr
        }
      }
      return elem.attributes.find(attr => attr.type === attrType)
    },
    /**
     * @param {string} text
     * @param {object} [elem]
     * @param {object} [node]
     * @param {string} [sep]
     * @param {string} [prefix]
     * @return {{elemId: number|undefined, nodeCode: string|undefined, text: string, sep: string, prefix: string}}
     */
    makeDescItem (text, elem = null, node = null, sep = ', ', prefix = '') {
      return {
        elemId: elem?.id,
        nodeCode: node?.code,
        text: text,
        sep: sep,
        prefix: prefix,
      }
    },
    openLink (link) {
      const params = {
        elementIds: this.designElementIdsFlatList,
        seamAllowance: this.seamAllowance,
        settingCodes: this.designSettingsActiveFlat,
        fontName: this.getPatternsFontName,
        mannequinEase: this.mannequinEase ? this.mannequinEase : '',
        internalLines: this.InternalLines ? 1 : 2,
        mannequinId: this.mannequinId,
      }
      if (link === 'auto-grading' || link === 'tech-pack') {
        params.settingCodes = this.changedSettingCodes
      }
      if (link === 'auto-grading') {
        params.algorithms = this.designAlgorithms
        params.params = this.designParams
        params.size = window.pageDesigner.standartSize
        params.fabricType = this.fabricTypeDefaultValue
        params.fit = this.defaultFit
        this.$router.push({
          name: 'auto-grading',
          query: {
            params: JSON.stringify(params),
            type: this.mannequinType,
            category: this.designCategory,
            designFabric: this.designFabric,
            isCustomModel: this.isCustomModel,
            fitChartsId: this.fitChartsId,
            mannequinIdNew: this.mannequinIdNew,
            fitChartsIsCustom: this.fitChartsStore.isCustom,
            t: +Date.now(),
          },
        })
      } else if (link === 'size-run') {
        params.fabricType = this.fabricTypeDefaultValue
        params.fit = this.defaultFit
        const url = this.$router.resolve({
          name: 'size-run',
          query: {
            params: JSON.stringify(params),
            type: this.mannequinType,
            category: this.designCategory,
            t: +Date.now(),
          },
        }).href
        window.open(url, '_blank')
      } else if (link === 'tech-pack') {
        params.algorithms = this.designAlgorithms
        params.params = this.designParams
        params.size = window.pageDesigner.standartSize
        params.fabricType = this.fabricTypeDefaultValue
        params.fit = this.defaultFit
        localStorage.setItem('bothSketch', document.getElementById('both')?.outerHTML)
        this.$router.push({
          name: 'tech-pack',
          query: {
            params: JSON.stringify(params),
            type: this.mannequinType,
            category: this.designCategory,
            isCustomModel: this.isCustomModel,
            fitChartsId: this.fitChartsId,
            mannequinIdNew: this.mannequinIdNew,
            fitChartsIsCustom: this.fitChartsStore.isCustom,
          },
        })
      } else if (link === 'ease-page') {
        let option = {
          ...this.designOptions,
          eases: {},
          fabricType: this.fabricTypeDefaultValue,
          fit: this.defaultFit,
          algorithms: this.designAlgorithms,
          params: this.designParams,
          internalLines: this.InternalLines ? 1 : 2,
        }
        this.$router.push({
          name: 'ease-page',
          query: {
            designOptions: JSON.stringify(option),
            designFabric: this.designFabric,
            type: this.mannequinType,
            category: this.designCategory,
            mannequinEase: JSON.stringify(this.mannequinEase),
            page: 'main',
            isCustomModel: this.isCustomModel,
            fitChartsId: this.fitChartsId,
            mannequinIdNew: this.mannequinIdNew,
            fitChartsIsCustom: this.fitChartsStore.isCustom,
          },
        })
      } else if (link === 'specs') {
        this.$router.push({
          name: 'specs',
          query: {
            designOptions: JSON.stringify({
              ...this.designOptions,
              internalLines: this.InternalLines ? 1 : 2,
            }),
            mannequinEase: JSON.stringify(this.mannequinEase),
            type: this.mannequinType,
            isCustomModel: this.isCustomModel,
            fitChartsId: this.fitChartsId,
            mannequinIdNew: this.mannequinIdNew,
            fitChartsIsCustom: this.fitChartsStore.isCustom,
          },
        })
      } else if (link === 'FitModel') {
        this.$router.push({
          name: 'FitModel',
          params: {
            designOptions: JSON.stringify({ ...this.designOptions }),
            mannequinEase: JSON.stringify(this.mannequinEase),
            type: this.mannequinType,
            size: this.isCustomModel ? this.fitModelsStore.selectedModel.name : this.fitChartsStore.selectedMannequin.name,
            isCustomModel: this.isCustomModel,
            fitChartsId: this.fitChartsId,
            mannequinIdNew: this.mannequinIdNew,
          },
        })
      }
    },
    ...mapActions({
      setUnitsIfNoUserPreferences: 'setUnitsIfNoUserPreferences',
      setSeamAllowanceStore: 'setSeamAllowance',
    }),
    checkSideClosureForElements (elements) {
      let hasFrontClosure = false
      let hasBackClosure = false
      for (let nodeCode in elements) {
        const params = elements[nodeCode][0].params
        for (let number in params) {
          if (+number === 16 && ![0, 5].includes(+params[number])) {
            hasFrontClosure = true
            return false
          } else if (+number === 22 && +params[number] > 0) {
            hasBackClosure = true
            return false
          }
        }
      }
      return !hasFrontClosure && !hasBackClosure
    },
    async downloadPatterns (data) {
      // let internalLines = this.InternalLines ? 1 : 2
      let ext = data.format.toLowerCase()
      if (ext.includes("-")) {
        ext = ext.split("-")[0]
      }
      this.downloadPatternsLoading = true
      let params = await this.getDebugParams()
      console.log('params', params)
      if (params) {
        const parameterizedKits = params.parameterizedKits
        let content = await downloadPattern(
          // internalLines,
          this.mannequinIdNew,
          parameterizedKits,
          // this.easeByMan.fabricType,
          // this.easeByMan.fit,
          // this.seamAllowance,
          // this.easeByMan.ease,
          // this.designSettingsActiveFlat,
          // this.designAlgorithms,
          // this.designParams,
          ext,
          data.cutVerticallySymmetric,
          data.pageSize,
        )
        if (ext === 'plt') ext = 'zip'
        let file = new File([content], 'patterns.' + ext, { type: "text/plain:charset=UTF-8" })
        let url = window.URL.createObjectURL(file)
        let a = document.createElement("a")
        a.style = "display: none"
        a.href = url
        a.download = file.name
        a.click()
        window.URL.revokeObjectURL(url)
      }
      this.downloadPatternsLoading = false
    },
    copyToClipboard (content) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(content)
      } else {
        const textArea = document.createElement("textarea")
        textArea.value = content
        textArea.style.position = "absolute"
        textArea.style.left = "-999999px"
        document.body.prepend(textArea)
        textArea.select()
        try {
          document.execCommand('copy')
        } catch (error) {
          console.error(error)
        } finally {
          textArea.remove()
        }
      }
    },
    copyLink () {
      let url = this.getCurrentDesignURL()
      url = window.location.protocol + '//' + window.location.host + '/designer' + url.replaceAll(' ', '%20')
      this.copyToClipboard(url)
      setTimeout(() => {
        this.isLinkCopied = false
      }, 700)
      this.isLinkCopied = true
    },
    getCurrentDesignURL () {
      let elements = []
      for (let nodeCode in window.pageDesigner.designElements) {
        elements.push({ ...window.pageDesigner.designElements[nodeCode][0], nodeCode })
      }
      const category = window.pageDesigner.designCategory
      const mannequinType = window.pageDesigner.mannequinType
      const fitChartsIsCustom = window.pageDesigner.fitChartsStore.isCustom
      const mannequinIdNew = window.pageDesigner.mannequinIdNew
      const fitChartsId = window.pageDesigner.fitChartsId
      const isCustomModel = window.pageDesigner.isCustomModel
      const params = {
        elementIds: elements.map(el => el.id),
        fabricType: window.pageDesigner.easeByMan.fabricType,
        fit: window.pageDesigner.easeByMan.fit,
        seamAllowance: window.pageDesigner.seamAllowance,
        ease: window.pageDesigner.easeByMan.ease,
        settingCodes: window.pageDesigner.designSettingsActiveFlat,
        mannequinId: window.pageDesigner.mannequinId,
      }
      const json = JSON.stringify(params)
      const encodedParams = encodeURIComponent(json)
      return `/?params=${encodedParams}` +
        `&type=${mannequinType}&category=${category}&fitChartsIsCustom=${fitChartsIsCustom}&mannequinIdNew=${mannequinIdNew}&fitChartsId=${fitChartsId}&isCustomModel=${isCustomModel}`
    },
    ...mapActions('designConstructorHistory', ['push', 'undo', 'redo', 'cleanHistory']),
    ...mapActions('nodesElements', ['getCurrentState', 'restoreState']),
    ...mapActions(['setDesignCategoryPrefixes', 'setMannequinCategories']),
  },
  mounted () {
    window.pageDesigner = this
    if (!this.paramsDebugListener) {
      this.paramsDebugListener = window.addEventListener('keyup', e => {
        const isNotInput = e.target && !['input', 'textarea'].includes(e.target.tagName.toLowerCase())
        if (isNotInput && (['h', 'H', 'р', 'Р'].includes(e.key) || (e.key === 'F1' && e.ctrlKey))) {
          this.getDebugParams().then(res => {
            this.displayDebugParams = true
            this.debugParams = res
          })
          e.stopPropagation()
          e.preventDefault()
        }
      })
    }
    if (!this.refreshListener) {
      this.refreshListener = e => {
        const isNotInput = e.target && !['input', 'textarea'].includes(e.target.tagName.toLowerCase())
        if (isNotInput && (['r', 'R', 'к', 'К'].includes(e.key)) && !e.ctrlKey) {
          window.location.href = this.getCurrentDesignURL()
          e.stopPropagation()
          e.preventDefault()
        }
      }
      window.addEventListener('keyup', this.refreshListener)
    }
    if (localStorage.getItem('activeColor') === null) localStorage.setItem('activeColor', JSON.stringify({ color: undefined }))
    const { color } = JSON.parse(localStorage.getItem('activeColor'))
    this.activeColorSelected = color
  },
}
</script>

<style lang="scss">
@import "src/styles/variables";
@import "src/styles/global-z-indexes";
.Flat_pattern {
  &__download {
    display: flex;
    justify-content: flex-end;
    @media (max-width: $breakpoint-sm) {
      button {
        width: 100%;
        &:hover .download-demo {
          display: none !important;
        }
      }
    }
    button {
      .download-demo {
        display: none;
        position: absolute;
        right: calc(100% + 13px);
        padding: 8px 12px;
        background: #09373D;
        border-radius: 8px;
        color: white;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 100%; /* Перемещаем в правую часть */
          margin-top: -5px; /* Центрируем по вертикали */
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent #09373D; /* Меняем цвет для правой стороны */
        }
      }
      .download-demo-left {
        padding: 4px 8px;
        background: var(--Active-States-Surfaces);
        color: var(--Active-State-Strokes);
        font-weight: 400;
        border-radius: 3px;
      }
      &:hover {
        position: relative;
        .download-demo {
          display: block;
        }
      }
    }
  }
  &-top_actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;
    flex-wrap: wrap;
    .sizes {
      display: flex;
      align-items: flex-end;
      gap: 8px;
      .base-dropdown__button {
        width: 120px;
      }
    }
    @media (max-width: $breakpoint-sm) {
      .sizes {
        width: 100%;
        .base-dropdown {
          width: 100%;
        }
      }
      .save {
        width: 100%;
      }
    }
  }
}

.colors-element {
  cursor: pointer;

  &.active {
    color: var(--Button-States-Pressed);
    background: var(--Surface);
    border-right: none;
  }
}

.PageDesigner {
  height: 100%;
  flex-grow: 1;
  display: flex;

  @media screen and (max-width: $breakpoint-sm),
  (max-height: 550px) {
    flex-direction: column;

    // &__optionsListContainer {
    //   position: absolute;
    //   z-index: $z-sidebar-elements-panel;
    //   height: 100%;
    //   width: 100%;
    // }
  }

  .radio-button-block.active {
    .radio-button-item {
      border-color: #FF6770;
      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #FF6770;
      }
    }
  }
  .radio-button-block {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--Dividers);
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .radio-button-item {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #E0E9EA;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    label {
      margin-left: 16px;
      font-size: 16px;
    }
  }
  .save-design-modal {
    .buttons button {
      width: fit-content !important;
      @media (max-width: $breakpoint-sm) {
        width: 100% !important;
      }
    }
  }

  &__nodesList {
    flex-grow: 1;
  }

  &__item {
    flex: 1 1;
    display: flex;
    align-items: stretch;
    padding: 0;

    &>* {
      flex-grow: 1;
    }
  }

  &__sewingComponentModal {
    min-width: 80%;

    @media screen and (min-width: $breakpoint-md) {
      min-width: 1000px;
    }
  }

  &__fitEditorModal {
    height: 680px;
  }

  &__newDesignModal,
  .q-dialog__inner--minimized>&__newDesignModal {
    width: 800px;
  }
}

.PageDesigner__changeElementAgreeModal .main-header {
  margin: -16px -16px 16px;
  padding: 16px 45px;
  background: #fff;
}
.PageDesigner__changeElementAgreeModal .modal-header {
  font-family: AvenirNext,sans-serif;
  font-style: normal;
  font-weight: 700;
}

.ElementOptionItem {
  &__fabricItem {
    width: 20%;
    height: auto;
    padding: 5px;
  }

  &__fabricItemImg {
    max-width: 100%;
    /*width:            80px;*/
    background-color: #ffffff;
  }

  &__colorItem {
    width: 65px;
    height: 85px;
  }

  &__colorItemIcon {
    width: 36px;
    height: 36px;
    border-width: 2px;
    border-color: #4b5b5e;
    border-style: solid;
    border-radius: 50%;
    background-color: #ffffff;
  }
}
</style>
