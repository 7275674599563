<template>
  <layout-user>
    <div :class="`tech_pack_page ${loading || error || success ? 'centered' : ''}`">
      <div class="back" @click="goBack">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path fill="#09373d" d="M11.7 14.1c-.2 0-.4-.1-.5-.2l-3.3-3.3c-.3-.3-.3-.8 0-1.1l3.3-3.3c.3-.3.8-.3 1.1 0s.3.8 0 1.1L9.4 10l2.8 2.8c.3.3.3.8 0 1.1-.2.1-.3.2-.5.2z"/>
        </svg>
        Back
      </div>
      <template v-if="!loading && !error && !success">
        <h1>Tech pack</h1>
        <p class="subtitle">To create your custom Tech Pack, please fill out the form below:</p>
        <div>
          <div class="switch-blocks">
            <div class="switch-block">
              <label>Fit charts</label>
              <div class="select-block-radio">
                <div
                  :class="`radio-item ${selectedSizeChartType === 'standart' ? 'active' : ''}`"
                  @click="sizeChartTypeChange('standart')"
                >
                  Standard
                </div>
                <div
                  :class="`radio-item ${selectedSizeChartType === 'custom' ? 'active' : ''}`"
                  @click="sizeChartTypeChange('custom')"
                >
                  Custom
                </div>
              </div>
            </div>
            <div class="switch-block">
              <label>Measurements</label>
              <div class="select-block-radio">
                <div
                  :class="`radio-item ${selectedMeasurements === 'in' ? 'active' : ''}`"
                  @click="selectedMeasurements = 'in'"
                >
                in
                </div>
                <div
                  :class="`radio-item ${selectedMeasurements === 'cm' ? 'active' : ''}`"
                  @click="selectedMeasurements = 'cm'"
                >
                cm
                </div>
              </div>
            </div>
          </div>
          <div class="inputs-block">
            <div class="input-block">
              <p class="label">Fit chart</p>
              <q-select
                :disable="fitCharts.length === 0"
                class="label_mobile avenir-font-regular"
                dense
                behavior="menu"
                outlined
                placeholder="fdf"
                v-model="fitChart"
                option-label="name"
                :options="fitCharts"
                dropdown-icon='expand_more'
                label="Add New Stock Symbol"
              />
            </div>
            <div class="input-block red">
              <p class="label">Sample size</p>
              <q-select
                :disable="mannequins.length === 0"
                class="label_mobile avenir-font-regular"
                dense
                behavior="menu"
                outlined
                placeholder="Choose sample size"
                v-model="mannequin"
                option-label="name"
                :options="mannequins"
                dropdown-icon='expand_more'
                label="Choose sample size"
                popup-content-class="red"
                options-selected-class="pink"
              >
                <template v-slot:selected>
                  <div>
                    {{ mannequin ? mannequin.name : 'Choose sample size' }}
                  </div>
                </template>
              </q-select>
            </div>
            <div class="input-block">
              <p class="label">Designer</p>
              <q-input
                v-model="designer"
                outlined
                placeholder="Enter designer name"
              />
            </div>
            <div class="input-block">
              <p class="label">Season</p>
              <q-input
                v-model="season"
                outlined
                placeholder="Enter design season"
              />
            </div>
            <div class="input-block">
              <p class="label">Style</p>
              <q-input
                type="number"
                v-model="style_number"
                outlined
                placeholder="Enter style number"
              />
            </div>
            <div class="input-block date-picker">
              <p class="label">Date</p>
              <div class="bg" @click="showDatePiker = false" v-if="showDatePiker" />
              <q-input
                v-model="formattedDate"
                outlined
                placeholder="Enter style number"
                disabled
                readonly
                @click.prevent="showDatePiker = !showDatePiker"
              >
                <template v-slot:append>
                  <i
                    @click="showDatePiker = !showDatePiker"
                    :class="`q-select__dropdown-icon q-icon notranslate material-icons ${showDatePiker ? 'rotate-180' : ''}`"
                  >
                    expand_more
                  </i>
                </template>
              </q-input>
              <q-date v-model="formattedDate" v-if="showDatePiker" mask="MM/DD/YYYY" />
            </div>
            <div class="input-block">
              <p class="label">Fabric self</p>
              <q-input
                v-model="fabric_self"
                outlined
                placeholder="Enter fabric name for self"
              />
            </div>
            <div class="input-block">
              <p class="label">Contrast</p>
              <q-input
                v-model="contrast"
                outlined
                placeholder="Enter fabric name for contrast"
              />
            </div>
            <div class="input-block">
              <p class="label">Contrast 2</p>
              <q-input
                v-model="contrast_2"
                outlined
                placeholder="Enter fabric name for contrast 2"
              />
            </div>
            <div class="input-block">
              <p class="label">Lining</p>
              <q-input
                v-model="lining"
                outlined
                placeholder="Enter fabric name for lining"
              />
            </div>
            <div class="input-block row2">
              <p class="label">Description</p>
              <q-input
                v-model="description"
                outlined
                placeholder="Enter design description"
              />
            </div>
            <div class="input-block row2 text-block">
              <p class="label">Add Comments</p>
              <q-input
                v-model="comments"
                type="textarea"
                outlined
                placeholder="Add a comment..."
                float-label="Comments"
                :max-height="100"
                rows="7"
              />
            </div>
            <div class="row2 button-block">
              <ButtonPrimary
                :disabled="!mannequin"
                @click.native="submit"
              >
                Apply
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="loading-block">
        <template v-if="loading">
          <h3>Thank you for submitting <br> the tech pack form!</h3>
          <p class="loading-block__subtitle">Your request is now being processed... </p>
          <span class="loader"></span>
          <ButtonSecondary style="width:170px" @click.native="stopDownload">Cancel</ButtonSecondary>
        </template>
        <template v-if="success">
          <svg class="loading-block__icon success" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 33">
            <path fill="#09373d" d="M13.7 22.9c-.2 0-.4-.1-.5-.2L7.5 17c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l5.1 5.1 10.8-10.8c.3-.3.8-.3 1.1 0s.3.8 0 1.1L14.2 22.7c-.2.1-.4.2-.5.2z"/>
          </svg>
          <h3>Your tech pack were successfully downloaded!</h3>
          <ButtonPrimary
            style="width:170px"
            @click.native="goBack"
          >
            Okay
          </ButtonPrimary>
        </template>
        <template v-if="error">
          <svg class="loading-block__icon error" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
            <path fill="#F52727" d="M16 2.668C8.645 2.668 2.666 8.645 2.666 16S8.646 29.334 16 29.334c7.355 0 13.334-5.98 13.334-13.334 0-7.355-5.98-13.332-13.334-13.332zm0 1.5A11.821 11.821 0 0 1 27.834 16c0 6.544-5.29 11.834-11.834 11.834A11.823 11.823 0 0 1 4.166 16C4.166 9.456 9.456 4.168 16 4.168zm0 4.416a.75.75 0 0 0-.75.75v7.998a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75V9.334a.75.75 0 0 0-.75-.75zm0 11.998a.75.75 0 0 0-.75.75v.002a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-.002a.75.75 0 0 0-.75-.75z"/>
          </svg>
          <h3>Something went wrong</h3>
          <p>Download failed. Please try again</p>
          <div class="loading-block__buttons">
            <ButtonSecondary style="width:170px" @click.native="goBack">Cancel</ButtonSecondary>
            <ButtonPrimary style="width:170px" @click.native="submit">
              <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M15.8 7.4h-4.2c-.4 0-.8-.3-.8-.8s.3-.8.8-.8H15V2.5c0-.4.3-.8.8-.8s.8.3.8.8v4.2c0 .4-.4.7-.8.7z"/>
                <path d="M10 17.4c-.7 0-1.4-.1-2-.3-1.5-.4-2.9-1.4-3.8-2.6-1-1.2-1.5-2.8-1.5-4.4C2.6 8.6 3 7 3.9 5.7c1-1.3 2.3-2.2 3.8-2.7s3.1-.5 4.6 0c1.5.5 2.8 1.4 3.7 2.8.2.3.2.8-.2 1-.3.2-.8.2-1-.2-.7-1-1.8-1.8-3-2.2-1.2-.4-2.4-.4-3.7 0-1.2.4-2.2 1.2-3 2.2-.7 1-1.1 2.3-1.1 3.5 0 1.3.5 2.5 1.2 3.5.8 1 1.9 1.7 3.1 2.1 1.2.3 2.5.3 3.7-.1 1.2-.4 2.2-1.3 2.9-2.3.2-.3.7-.4 1-.2.3.2.4.7.2 1-.9 1.3-2.1 2.4-3.6 2.9-.7.3-1.6.4-2.5.4z"/>
              </svg>
            </template>
            Try again
          </ButtonPrimary>
          </div>
        </template>
      </div>
    </div>
  </layout-user>
</template>

<script>
import { mapStores } from 'pinia'
import { useFitChartsStore } from '@/store/pinia/fitCharts'

import LayoutUser from '@/layouts/LayoutUser'
import { calculateEdgePoints } from '@/helpers/svg'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

import {
  downloadTechPack,
  downloadStop,
  fetchDebug,
} from '@/api'

export default {
  components: {
    LayoutUser,
    ButtonPrimary,
    ButtonSecondary,
  },
  data: () => ({
    selectedMeasurements: 'in',
    selectedSizeChartType: 'standart',
    designer: null,
    season: null,
    style_number: null,
    lining: null,
    fabric_self: null,
    contrast: null,
    contrast_2: null,
    description: null,
    comments: null,
    formattedDate: null,
    showDatePiker: false,
    options: [
      { name: 'Choose fit chart', value: 'default' },
    ],
    fitChart: { name: 'Choose fit chart', value: 'default' },
    mannequin: null,
    loading: false,
    success: false,
    error: false,
    bothSketch: null,
    frontSketch: null,
    backSketch: null,
    dateOption: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
  }),
  computed: {
    ...mapStores(useFitChartsStore),
    fitCharts () {
      if (this.selectedSizeChartType === 'custom') return this.fitChartsStore.customCharts.list
      else return this.fitChartsStore.standartCharts.list
    },
    mannequins () {
      return this.fitChart?.mannequins || []
    },
  },
  watch: {
    formattedDate (val) {
      if (!val) {
        let date = new Date()
        this.formattedDate = date.toLocaleDateString('en-US', this.dateOption)
      }
    },
  },
  async mounted () {
    const params = new URLSearchParams(window.location.search)
    this.fitChartsStore.params.type = params.get('type')
    let sketch = localStorage.getItem("bothSketch")
    const res = await Promise.all([
      this.makeSketch(sketch, 'both'),
      this.makeSketch(sketch, 'front'),
      this.makeSketch(sketch, 'back'),
      this.fitChartsStore.getListStandart(),
      this.fitChartsStore.getList(),
    ])
    this.bothSketch = res[0]
    this.frontSketch = res[1]
    this.backSketch = res[2]
    let date = new Date()
    this.formattedDate = date.toLocaleDateString('en-US', this.dateOption)
    if (typeof this.$route.query.fitChartsIsCustom === 'boolean') this.fitChartsStore.isCustom = this.$route.query.fitChartsIsCustom
    else this.fitChartsStore.isCustom = this.$route.query.fitChartsIsCustom === 'true'
    this.fitChartsStore.selectedMannequinId = this.$route.query.mannequinIdNew
    if (!this.fitChartsStore.selectedChart) {
      await this.fitChartsStore.getOneSelected(this.$route.query.fitChartsId)
    }
    this.fitChart = this.fitChartsStore.selectedChart
    this.mannequin = this.fitChartsStore.selectedMannequin
  },
  methods: {
    async sizeChartTypeChange (sizeChartType) {
      this.selectedSizeChartType = sizeChartType
      this.fitChart = { name: 'Default custom size', value: 'default' }
      this.mannequin = null
    },
    async stopDownload () {
      await downloadStop('/tech-pack')
      this.loading = false
      this.error = false
    },
    makeSketch (bothSvg, svgSide) {
      return new Promise((resolve, reject) => {
        const length = 1800
        const edgePoints = calculateEdgePoints(bothSvg)
        const widthImg = edgePoints.x1 - edgePoints.x
        const heightImg = edgePoints.y1 - edgePoints.y

        const svgPreload = (side, scale) => {
          const svg = new DOMParser().parseFromString(bothSvg, "text/html").getElementsByTagName('svg')[0]
          let gBack = svg.getElementsByClassName('back-view')[0]
          let gFront = svg.getElementsByClassName('front-view')[0]
          gBack.removeAttribute("transform")
          svg.removeChild(side === 'Back' ? gBack : gFront)
          svg.removeAttribute("style")
          svg.setAttribute('height', length / 1.2 * scale)
          svg.setAttribute('width', length / 2 * scale)
          svg.setAttribute('viewBox', `${edgePoints.x - widthImg * 0.15 / 2} ${edgePoints.y - heightImg * 0.15 / 2} ${widthImg + widthImg * 0.15} ${heightImg + heightImg * 0.15}`)
          const blob = new Blob([svg.outerHTML], { type: 'image/svg+xml' })
          return blob
        }
        let blobBack = null
        if (svgSide === 'back') blobBack = svgPreload('Front', 1)
        else blobBack = svgPreload('Front', 0.7)
        const blobFront = svgPreload('Back', 1)
        const win = window.URL || window.webkitURL || window
        const urlFront = win.createObjectURL(blobFront)
        const urlBack = win.createObjectURL(blobBack)
        const canvas = document.createElement("canvas")
        document.body.appendChild(canvas)
        canvas.style = 'display:none'
        const ctx = canvas.getContext('2d')

        if (svgSide === 'both') {
          const imgFront = new Image()
          const imgBack = new Image()
          imgFront.src = urlFront
          imgBack.src = urlBack
          canvas.width = length
          canvas.height = length
          ctx.fillStyle = "white"
          ctx.fillRect(0, 0, length, length)
          imgFront.onload = () => {
            imgBack.onload = () => {
              if (widthImg < heightImg) {
                ctx.drawImage(imgFront, (length - (imgFront.width + imgBack.width + 100)) / 2, 0)
                ctx.drawImage(imgBack, imgFront.width + 100 + ((length - (imgFront.width + imgBack.width + 100)) / 2), length - imgBack.height)
              } else {
                ctx.drawImage(imgFront, 0, (length - (imgFront.height / 2 + imgBack.height)) / 2)
                ctx.drawImage(imgBack, length - imgBack.width, (length - (imgFront.height / 2 + imgBack.height)) / 2 + imgFront.height / 2)
              }
              win.revokeObjectURL(urlFront)
              win.revokeObjectURL(urlBack)
              const result = canvas.toDataURL('image/png')
              document.body.removeChild(canvas)
              resolve(result)
            }
          }
          imgFront.onerror = reject
          imgBack.onerror = reject
        } else {
          let image = new Image()
          if (svgSide === 'front') {
            image.src = urlFront
          } else {
            image.src = urlBack
          }
          image.onload = () => {
            canvas.width = image.width
            canvas.height = image.height
            ctx.drawImage(image, 0, 0)

            let minX = image.width
            let minY = image.height
            let maxX = 0
            let maxY = 0
            const imageData = ctx.getImageData(0, 0, image.width, image.height).data
            for (let y = 0; y < image.height; y++) {
              for (let x = 0; x < image.width; x++) {
                const index = (y * image.width + x) * 4
                if (imageData[index + 3] > 0) {
                  if (x < minX) minX = x
                  if (x > maxX) maxX = x
                  if (y < minY) minY = y
                  if (y > maxY) maxY = y
                }
              }
            }
            const cropWidth = maxX - minX + 1
            const cropHeight = maxY - minY + 1
            const squareSize = Math.max(cropWidth, cropHeight)
            const croppedCanvas = document.createElement("canvas")
            croppedCanvas.width = squareSize
            croppedCanvas.height = squareSize
            const croppedCtx = croppedCanvas.getContext('2d')
            croppedCtx.fillStyle = "white"
            croppedCtx.fillRect(0, 0, squareSize, squareSize)
            croppedCtx.drawImage(canvas, minX, minY, cropWidth, cropHeight, (squareSize - cropWidth) / 2, (squareSize - cropHeight) / 2, cropWidth, cropHeight)

            win.revokeObjectURL(urlFront)
            document.body.removeChild(canvas)
            resolve(croppedCanvas.toDataURL('image/png'))
          }
          image.onerror = reject
        }
      })
    },
    goBack () {
      const rp = JSON.parse(this.$route.query.params)
      if (rp.params) delete rp.params
      if (rp.algorithms) delete rp.algorithms
      this.$router.push(
        {
          path: '/designer',
          query: {
            params: JSON.stringify(rp),
            type: this.$route.query.type,
            category: this.$route.query.category,
            size: rp.size,
            isCustomModel: this.$route.query.isCustomModel,
            fitChartsId: this.$route.query.fitChartsId,
            mannequinIdNew: this.$route.query.mannequinIdNew,
            fitChartsIsCustom: this.$route.query.fitChartsIsCustom,
          },
        },
      )
    },
    async submit () {
      this.loading = true
      this.error = false
      this.success = false
      const [month, day, year] = this.formattedDate.split('/')
      const isoDate = `${year}-${month}-${day}T00:00:00Z`
      const rp = JSON.parse(this.$route.query.params)
      // let internalLines = this.InternalLines ? 1 : 2
      let params = await fetchDebug(
        rp.internalLines,
        rp.elementIds,
        rp.mannequinId,
        rp.mannequinEase[this.mannequin.mannequinId]?.fabricType || rp.fabricType,
        rp.mannequinEase[this.mannequin.mannequinId]?.fit || rp.fit,
        rp.seamAllowance,
        rp.mannequinEase[this.mannequin.mannequinId]?.ease || {},
        rp.settingCodes,
        rp.fontName,
        true,
        true,
      )
      if (params) {
        let postData = {
          ...params.parameterizedKits,
          unit: this.selectedMeasurements,
          // params: rp.params,
          // settingCodes: rp.settingCodes,
          // elementIds: rp.elementIds,
          // algorithms: rp.algorithms,
          fitChartType: this.selectedSizeChartType,
          fitChart: this.fitChart,
          mannequin: this.mannequin,
          season: this.season,
          styleNumber: Number(this.style_number),
          description: this.description,
          date: isoDate,
          designer: this.designer,
          fabricSelf: this.fabric_self,
          contrast: this.contrast,
          contrast2: this.contrast_2,
          lining: this.lining,
          comments: this.comments,
          sketchFront: this.frontSketch,
          sketchBack: this.backSketch,
          sketchBoth: this.bothSketch,
          // seamAllowance: rp.seamAllowance,
          // fabricType: rp.fabricType,
          // fit: rp.fit,
        }
        let response = await downloadTechPack(postData)
        if (this.$store.state.user?.info?.id === 3) this.error = true
        else {
          if (response === 'error') this.error = true
          else if (response === 'ok') this.success = true
        }
      } else {
        this.error = true
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
.tech_pack_page.centered {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .back {
    position: absolute;
    top: 16px;
    left: 16px;
  }
}
.tech_pack_page {
  padding: 16px;
  max-width: 1440px;
  margin: auto;
  color: var(--text_color, #1D1F29);
  font-size: 16px;
  .pink {
    background: #FFF3F3 !important;
  }
  .error-block {
    width: 645px;
    button {
      margin-top: 30px;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  .loading-block {
    width: 572px;
    padding: 56px;
    border-radius: 20px;
    border: 1px solid var(--Dividers);
    background: var(--Surface);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: stretch;
      column-gap: 16px;
    }
    button {
      margin-top: 32px;
    }
    &__subtitle {
      color: var(--Text-Text_Secondary);
      margin-top: 8px;
    }
    &__icon {
      width: 64px;
      height: 64px;
      border-radius: 64px;
      box-sizing: border-box;
      padding: 16px;
      margin-bottom: 32px;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }

      &.success {
        background: var(--Dividers);

        svg {
          fill: var(--Icons-Icon_Primary);
        }
      }

      &.error {
        background: var(--Active-States-Surfaces);
        svg {
          fill: var(--System-Error);
        }
    }
    }
    h3 {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
    p {
      margin-bottom: 0;
      margin-top: 8px;
      color: var(--Text-Text_Secondary);
    }
    .loader {
      width: 100%;
      height: 7px;
      display: inline-block;
      position: relative;
      background: #EDEDED;
      overflow: hidden;
      border-radius: 20px;
      margin: 32px 0;
    }
    .loader::after {
      content: '';
      width: 180px;
      height: 7px;
      border-radius: 24px;
      background: var(--Active-State-Strokes);
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      animation: animloader 2s linear infinite;
    }
  }
  .button-block {
    display: flex;
    margin: 24px 0 40px;
    button {
      width:119px;
    }
  }
  .inputs-block {
    max-width: 867px;
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 27px;
    .row2 {
      grid-column: 1 / 3;
    }
  }
  .date-picker {
    .q-field--outlined.q-field--readonly .q-field__control:before {
      border-style: solid !important;
    }
    label {
      position: relative;
      z-index: 2;
      cursor: pointer;
      input {
        cursor: pointer !important;
      }
    }
    .bg {
      opacity: 0.5;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .q-date {
      position: absolute;
      z-index: 2;
      margin-top: 5px;
      &__header {
        background-color: #09373D !important;
      }
      .q-btn.bg-primary {
        background: #09373D !important;
      }
    }
  }
  h1 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin: 16px 0 8px;
  }
  .subtitle {
    font-size: 14px;
    color: var(--Text-Text_Secondary);
    margin-bottom: 0;
  }
  .back {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    width: fit-content;
    svg {
      margin-right: 4px;
    }
  }
  .switch-blocks {
    display: flex;
    align-items: center;
    margin: 24px 0;
    .switch-block:first-child {
      margin-right: 40px;
    }
    .switch-block:last-child {
      .select-block-radio .radio-item {
        min-width: 55px;
      }
    }
  }
}
@media (max-width: 480px) {
  .tech_pack_page {
    .switch-blocks {
      margin: 4px 0 32px;
      justify-content: space-between;
      .switch-block:first-child {
        margin-right: 5px;
      }
      .switch-block:last-child .select-block-radio .radio-item {
        width: 55px;
      }
    }
    .inputs-block {
      grid-template-columns: repeat(1, 1fr);
      .row2 {
        grid-column: 1;
      }
    }
    .button-block button {
      width: 100%;
    }
  }
}
</style>
