const fabricTypeSelect = {
  WOVEN: Object.freeze([
    {
      label: 'Non-stretch Woven',
      value: 1,
      defaultStretchFactor: '0%',
    },
    {
      label: 'Low Stretch Woven',
      value: 2,
      defaultStretchFactor: '2%',
    },
    {
      label: 'Medium Stretch Woven',
      value: 3,
      defaultStretchFactor: '5%',
    },
    {
      label: 'High Stretch Woven',
      value: 4,
      defaultStretchFactor: '8%',
    },
  ]),
  KNIT: Object.freeze([
    {
      label: 'Low Stretch Knit',
      value: 1,
      defaultStretchFactor: '4%',
    },
    {
      label: 'Medium Stretch Knit',
      value: 2,
      defaultStretchFactor: '8%',
    },
    {
      label: 'High Stretch Knit',
      value: 3,
      defaultStretchFactor: '12%',
    },
    {
      label: 'Very High Stretch Knit',
      value: 4,
      defaultStretchFactor: '15%',
    },
  ]),
  SWIMWEAR: Object.freeze([
    {
      label: 'Low Stretch Knit',
      value: 1,
      defaultStretchFactor: '5%',
    },
    {
      label: 'Medium Stretch Knit',
      value: 2,
      defaultStretchFactor: '9%',
    },
    {
      label: 'High Stretch Knit',
      value: 3,
      defaultStretchFactor: '17%',
    },
    {
      label: 'Very High Stretch Knit',
      value: 4,
      defaultStretchFactor: '23%',
    },
  ]),
}

const fitSelect = [
  {
    label: 'Very Fitted',
    value: 1,
  },
  {
    label: 'Fitted',
    value: 2,
  },
  {
    label: 'Semi-Fitted',
    value: 3,
  },
]

const defaultValues = Object.freeze({
  FABRIC_TYPE_WOVEN: 1,
  FABRIC_TYPE_KNIT: 2,
  FIT_SELECT: 2,
})

const getFabricTypeDefaultValue = function (designFabric) {
  switch (designFabric) {
    case 'woven':
      return defaultValues.FABRIC_TYPE_WOVEN
    case 'knit':
      return defaultValues.FABRIC_TYPE_KNIT
    default:
      return undefined
  }
}

const getFabricTypeSelectOptionsList = function (designFabric) {
  switch (designFabric) {
    case 'woven':
      return fabricTypeSelect.WOVEN
    case 'knit':
      return fabricTypeSelect.KNIT
    case 'swimwear':
      return fabricTypeSelect.SWIMWEAR
    default:
      return []
  }
}

export {
  fitSelect,
  defaultValues,
  getFabricTypeDefaultValue,
  getFabricTypeSelectOptionsList,
}
