<template>
  <q-card
    class="bg-white column no-wrap DialogCardBaseNew"
  >
    <q-btn
      class="DialogCardBaseNew__closeButton"
      icon="close"
      flat
      round
      dense
      v-close-popup
    />

    <q-card-section
      v-if="title"
      class="DialogCardBaseNew__title"
    >
      <p class="title">
        {{ title }}
      </p>
    </q-card-section>

    <q-card-section class="col column q-pa-none" :class="contentContainerClass">
      <slot></slot>
    </q-card-section>

    <q-card-actions
      v-if="hasActionsContent"
      class="DialogCardBaseNew__actions col-shrink column"
      align="center"
    >
      <slot name="actions"></slot>
    </q-card-actions>

  </q-card>
</template>

<script>
export default {
  name: 'DialogCardBaseNew',

  props: {
    title: {
      type: String,
      default: undefined,
    },
    noPaddings: Boolean,
    contentContainerClass: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    hasActionsContent () {
      return !!this.$slots.actions?.[0]
    },
  },
}
</script>

<style lang="scss">
  .q-dialog__inner--minimized {
    & > .DialogCardBaseNew {
      width: 457px;
    }
  }
  .DialogCardBaseNew {
    border-radius: 12px !important;
    padding: 56px;
    color: var(--text_color, #1D1F29);
    p {
      font-size: 16px;
      text-align: center;
      line-height: 20px;
    }
    .green_button {
      width: 247px;
      margin-top: 24px;
    }
    .white-button, .white_button {
      width: 247px;
      margin-top: 16px;
    }
    .card_title {
      text-align: center;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
    .card_subtitle {
      font-size: 16px;
      color: #6F8789;
    }
    .card_buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      button {
        width: calc(50% - 8px);
        margin: 0;
      }
    }
    &__title {
      // flex-grow: 1;
      padding: 0;
      p {
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
      }
    }

    &__closeButton {
      position: absolute;
      right:    24px;
      top:      24px;
      z-index:  1;
    }
  }
  .PageDesigner__fitEditorModal .DialogCardBaseNew__closeButton{
    top: 16px;
  }
@media (max-width: 480px) {
  .q-dialog__inner--minimized {
    & > .DialogCardBaseNew {
      width: 90%;
      max-width: 90%;
    }
  }
  .DialogCardBaseNew {
    padding: 32px;
    &__title {
      font-size: 20px;
    }
  }
}
</style>
