<template>
  <div class="elements-grid">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ElementsGrid',
}
</script>

<style lang="scss">
.elements-grid {
  margin-top: 40px;
  row-gap: 40px;
  column-gap: calc((100% - (200px * 5)) / 4);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 760px) {
  .elements-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 24px !important;
    row-gap: 15px !important;
    column-gap: 15px !important;

    & > * {
      width: 100% !important;
    }
  }
}
</style>
