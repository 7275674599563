<template>
  <div class="ease-page-comp">
    <div class="input-block fit-block" v-if="designOptions.designCategory !== 'wm_bodysuits'">
      <p class="label">Fit</p>
      <q-select
        :disable="fitOptions.length === 1"
        class="label_mobile avenir-font-regular"
        dense
        behavior="menu"
        outlined
        placeholder="Fit"
        v-model="fit"
        option-label="label"
        :options="fitOptions"
        dropdown-icon='expand_more'
        label="Add New Stock Symbol"
      />
    </div>
    <div class="settings-block">
      <div class="stretch-factor-block">
        <p class="title-3">Stretch factor</p>
        <div class="flex">
          <div class="input-block fabric-type">
            <p class="label">Fabric Type</p>
            <q-select
              :disable="fabricTypeOptions.length === 1"
              class="label_mobile avenir-font-regular"
              dense
              behavior="menu"
              outlined
              placeholder="Fabric Type"
              v-model="fabricType"
              option-label="label"
              :options="fabricTypeOptions"
              dropdown-icon='expand_more'
              label="Add New Stock Symbol"
            />
          </div>
          <!-- <div class="input-block stretch-factor">
            <p class="label">Stretch factor</p>
            <q-input
              v-model="stretchFactor"
              type="number"
              outlined
              :placeholder="stretchFactorPlaceholder"
            />
          </div> -->
        </div>
      </div>
      <!-- <div class="shrinkage">
        <p class="title-3">Shrinkage</p>
        <div class="flex">
          <div class="input-block horizontal-shrinkage">
            <p class="label">Horizontal</p>
            <q-input
              v-model="horizontalShrinkage"
              outlined
              type="number"
              placeholder="Off"
              @input="handler($event, 'horizontalShrinkage', 0, 10)"
            />
            <p
              class="range"
              :style="error['horizontalShrinkage'] ? 'color:red;' : ''"
            >
              0% - 10%
            </p>
          </div>
          <div class="input-block vertical-shrinkage">
            <p class="label">Vertical</p>
            <q-input
              v-model="verticalShrinkage"
              outlined
              type="number"
              placeholder="Off"
              @input="handler($event, 'verticalShrinkage', 0, 10)"
            />
            <p
              class="range"
              :style="error['verticalShrinkage'] ? 'color:red;' : ''"
            >0% - 10%</p>
          </div>
        </div>
      </div> -->
      <!-- <div class="pattern-compression">
        <p class="title-3">Full pattern compression</p>
        <div class="input-block">
          <p class="label">Horizontal</p>
          <q-input
            v-model="horizontalCompression"
            outlined
            @input="handler($event, 'horizontalCompression', 0, 5)"
            placeholder="Off"
          />
          <p
            class="range"
            :style="error['horizontalCompression'] ? 'color:red;' : ''"
          >0% - 5%</p>
        </div>
      </div> -->
    </div>
    <div class="advanced">
      <h4>Advanced POM adjustment</h4>
      <div class="units">
        <p>Measurements</p>
        <units-toggle-component />
      </div>
    </div>
    <div class="table" ref="table">
      <div class="table-row title-row pointName">
        <p>Point of Measure</p>
        <p>Custom ease</p>
        <p>Preset ease</p>
        <p>Pattern with ease</p>
      </div>
      <ease-option
        ref="easeOptions"
        v-for="option in options"
        :key="option.code"
        :code="option.code"
        :min-value="
          (changedRange[option.code] && changedRange[option.code].min) ?
          changedRange[option.code].min :
          (firstRange[option.code] && firstRange[option.code].min === option.minValue) ?
          firstRange[option.code].min :
          option.minValue"
        :max-value="
          (changedRange[option.code] && changedRange[option.code].max) ?
          changedRange[option.code].max :
          firstRange[option.code] && firstRange[option.code].min === option.minValue ?
          firstRange[option.code].max :
          option.maxValue"
        :name="option.name"
        :pattern-value="option.patternValue"
        :placeholder-value="option.originalValue"
        :initial-value="easeUserValues[option.code]"
        :mark-with-star="
          easeUserValues[option.code] !== undefined ||
          allUserValue[option.code] !== undefined ||
          designOptions.eases[option.code] !== undefined
        "
        :src-unit="option.unit"
        :design-category="designOptions.designCategory"
        :all-user-value="Number(((designOptions.eases[option.code] ? designOptions.eases[option.code] - zeroEases[option.code] : 0) + (isEditPage ? allUserValue[option.code] || 0 : 0)).toFixed(2))"
        :is-change-range="
          changedRange[option.code] ?
          false :
          (firstRange[option.code] && firstRange[option.code].min === option.minValue)
        "
        @change="setEase(option.code, $event)"
        @validationError="makeError"
      />
    </div>
    <div :class="`buttons ${fixedBlockStyle}`" ref="buttons">
      <div class="buttons__container">
        <div class="reset_button" @click.prevent="allReset">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.58332 6.66667H4.41666V2.5" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.65878 13.6309C5.43531 14.8266 6.57472 15.7419 7.90976 16.2424C9.24479 16.7429 10.7051 16.8022 12.0763 16.4116C13.4475 16.021 14.6574 15.2011 15.5283 14.0722C16.3992 12.9434 16.8853 11.565 16.9151 10.1396C16.945 8.71415 16.5171 7.31668 15.6942 6.15234C14.8713 4.98801 13.6969 4.11815 12.3432 3.67048C10.9896 3.2228 9.52803 3.22089 8.17321 3.66502C6.81839 4.10915 5.64165 4.97593 4.81573 6.13811" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Reset
        </div>
        <div class="button-main">
          <!-- <button class="white_button" @click.prevent="$emit('close')">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.75 15L5.75 5" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.75 5L5.74997 15" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Close
          </button> -->
          <ButtonSecondary class="" @click.native="$emit('close')">
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 21 20">
                <path d="m11.8 10 4.5-4.5c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0l-4.5 4.5-4.4-4.4c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1L9.7 10l-4.5 4.5c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l4.5-4.5 4.5 4.5c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L11.8 10z"/>
              </svg>
            </template>
            Close
          </ButtonSecondary>
          <ButtonPrimary @click.native="apply">
            Submit
          </ButtonPrimary>
          <!-- <button class="green_button" @click.prevent="apply">
            Submit
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EaseOption from '@/components/EaseNew/EaseOption'
import UnitsToggleComponent from '@/components/Units/UnitsToggleComponentNew'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

import {
  defaultValues,
  fitSelect,
  getFabricTypeDefaultValue,
  getFabricTypeSelectOptionsList,
} from '@/components/Ease/easeSelects'
import { fetchEases } from '@/api'
import { stretchCalculatorUrl } from '@/filesLinks'
import { designOptionsValidator } from '@/pages/designerConstructor'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EaseComponent',

  components: {
    UnitsToggleComponent,
    EaseOption,
    ButtonPrimary,
    ButtonSecondary,
  },

  props: {
    designOptions: {
      type: Object,
      required: true,
      validator: val => designOptionsValidator(val),
    },
    designFabric: {
      type: String,
      required: true,
      validator: item => ['woven', 'knit', 'swimwear'].includes(item),
    },
    isEditPage: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    tableData: null,
    validationError: false,
    options: [],
    easeUserValues: {},
    fitOptions: fitSelect,
    fabricType: undefined,
    fit: undefined,
    loadingInProgress: false,
    stretchCalculatorUrl,
    largeHeight: document.documentElement.clientHeight > 1300,
    // isShowAdvancedOptions: false,
    firstEases: {},
    allUserValue: {},
    zeroEases: {},
    firstRange: {},
    firstPattern: {},
    changedRange: {},
    stretchFactor: null,
    stretchFactorPlaceholder: null,
    verticalShrinkage: null,
    horizontalShrinkage: null,
    horizontalCompression: null,
    error: {},
    scrolledY: 0,
  }),

  async created () {
    this.fabricType = this.fabricTypeOptions.find(el => el.value === this.designOptions.fabricType)
    this.fit = fitSelect.find(el => Number(el.value) === Number(this.designOptions.fit))
    await this.loadEases()
    this.options.forEach(el => {
      this.firstEases[el.code] = el.originalValue
    })
  },

  computed: {
    ...mapState([
      'units',
    ]),
    fabricTypeOptions () {
      return getFabricTypeSelectOptionsList(this.designFabric)
    },
    // удалить значения из ввода, которые отсутствуют в опциях. например, при загрузке старого ввода для новых значений
    easesFiltered () {
      let userValues = this.easeUserValues
      let options = this.options
      let userValuesFiltered = {}
      Object.keys(userValues).forEach(easeCode => {
        let foundOption = options.find(option => option.code === easeCode)
        if (foundOption && userValues[easeCode] !== undefined) {
          userValuesFiltered[easeCode] = +userValues[easeCode].toFixed(2)
        }
      })

      return userValuesFiltered
    },
    fixedBlockStyle () {
      // const tableElement = this.$refs.table
      // const buttonsElement = this.$refs.buttons
      const scrollPosition = this.scrolledY + window.innerHeight
      const tableBottom = this.tableData?.getBoundingClientRect().bottom + this.scrolledY
      // console.log(tableElement?.offsetHeight)
      // console.log(tableElement?.offsetTop)
      // console.log(this.scrolledY)
      // if (
      //   this.scrolledY + window.innerHeight < tableBottom - 150
      // ) {
      let bottomDistance
      if (scrollPosition > tableBottom - 160) {
        bottomDistance = tableBottom - scrollPosition + 160
        // console.log('--', bottomDistance)
        // return ''
      } else {
        // console.log('++', bottomDistance)
        bottomDistance = 0
        // return 'stop'
      }
      if (!bottomDistance || bottomDistance > 0) {
        // this.$emit('stop', false)
        return ''
      } else {
        // this.$emit('stop', true)
        return 'stop'
      }
      // console.log(bottomDistance)
      // return bottomDistance
      // if (
      //   this.scrolledY > tableElement?.offsetTop + (tableElement?.offsetHeight) - window.innerHeight + buttonsElement?.offsetHeight
      // ) {
      //   return 'stop'
      // } return ''
    },
  },

  watch: {
    fixedBlockStyle (val) {
      this.$emit('stop', val)
    },
    async 'designOptions.mannequinId' () {
      await this.changeMan()
    },
    validationError (val) {
      this.$emit('errorValidations', val)
    },
    fabricType (val, oldval) {
      this.stretchFactorPlaceholder = val.defaultStretchFactor
      this.changedEmit()
    },
    fit (val, oldval) {
      this.changedEmit()
    },
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.tableData = this.$refs.table
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll () {
      this.scrolledY = window.scrollY
    },
    handler (event, val, min, max) {
      const value = Number(event)
      if (value && (value < min || value > max)) {
        this.error[val] = true
      } else {
        this.error[val] = false
      }
    },
    makeOptions () {
      let val = {}
      this.options.forEach(el => {
        if (
          Object.keys(this.designOptions.eases).includes(el.code) &&
          !Object.keys(this.allUserValue).includes(el.code) &&
          !Object.keys(this.easesFiltered).includes(el.code)
        ) {
          val[el.code] = this.designOptions.eases[el.code]
        } else if (
          (Object.keys(this.allUserValue).includes(el.code) ||
          Object.keys(this.easesFiltered).includes(el.code)) &&
          !Object.keys(this.designOptions.eases).includes(el.code)
        ) {
          val[el.code] = this.firstEases[el.code] + (this.easesFiltered[el.code] || 0) + (this.allUserValue[el.code] || 0)
        } else if (
          (Object.keys(this.allUserValue).includes(el.code) ||
          Object.keys(this.easesFiltered).includes(el.code)) &&
          Object.keys(this.designOptions.eases).includes(el.code)
        ) {
          if (!this.firstEases[el.code]) {
            val[el.code] = this.designOptions.eases[el.code] + (this.easesFiltered[el.code] || 0) + (this.isEditPage ? this.allUserValue[el.code] || 0 : 0)
          } else {
            val[el.code] = this.firstEases[el.code] + (this.easesFiltered[el.code] || 0) + (this.allUserValue[el.code] || 0)
          }
        }
      })
      return val
    },
    changedEmit () {
      const val = this.makeOptions()
      if (this.isEditPage) {
        this.$emit('changed', {
          ease: val,
          fabricType: this.fabricType.value,
          fit: this.fit.value,
        })
      }
    },
    makeError (val) {
      if (val) {
        this.validationError = true
      } else this.validationError = false
    },
    async loadEases () {
      this.loadingInProgress = true
      try {
        // если опции не были загружены - передаем текущие занчения изов
        let easesForRequest = this.options.length > 0 ? this.easesFiltered : this.designOptions.eases
        let { eases } = await fetchEases({
          ...this.designOptions,
          fabricType: this.fabricType.value,
          fit: this.fit.value,
          eases: easesForRequest,
        })
        this.options = eases
        let zeroEases = await fetchEases({
          ...this.designOptions,
          fabricType: this.fabricType.value,
          fit: this.fit.value,
          eases: {},
        })
        zeroEases.eases.forEach(el => {
          this.firstRange[el.code] = {
            min: el.minValue,
            max: el.maxValue,
          }
          const ease = eases.find(ease => ease.code === el.code)
          if (
            (el.patternValue - ease.patternValue > 0.1 || ease.patternValue - el.patternValue > 0.1) &&
            !el.originalValue
          ) {
            const diff = ease.patternValue - el.patternValue
            this.changedRange[el.code] = {
              min: ease.minValue - diff,
              max: ease.maxValue - diff,
            }
            ease.minValue = ease.minValue - diff
            ease.maxValue = ease.maxValue - diff
          }
          this.zeroEases[el.code] = el.originalValue
          this.firstPattern[el.code] = el.patternValue
        })
      } catch (e) {
        this.$emit('error', { title: 'Error', message: e.message })
      }
      this.loadingInProgress = false
    },
    setEase (optionId, value) {
      this.$set(this.easeUserValues, optionId, value)
      this.changedEmit()
    },
    async apply () {
      let userValuesFiltered = this.easesFiltered

      this.loadingInProgress = true
      try {
        const allVal = this.makeOptions()
        Object.keys(allVal).forEach(el => {
          allVal[el] = Number(allVal[el].toFixed(2))
        })
        let { eases } = await fetchEases({
          ...this.designOptions,
          fabricType: this.fabricType.value,
          fit: this.fit.value,
          eases: allVal,
        })
        this.options.forEach(el => {
          if (userValuesFiltered[el.code] || userValuesFiltered[el.code] === 0) {
            this.allUserValue[el.code] = (this.allUserValue[el.code] || 0) + userValuesFiltered[el.code]
          }
        })
        const newVal = {}
        eases.forEach(el => {
          if (
            (
              el.patternValue - this.firstPattern[el.code] > 0.1 ||
              this.firstPattern[el.code] - el.patternValue > 0.1
            ) &&
            !this.allUserValue[el.code]
          ) {
            if (!el.originalValue) {
              const diff = el.patternValue - this.firstPattern[el.code]
              el.minValue = el.minValue - diff
              el.maxValue = el.maxValue - diff
              this.changedRange[el.code] = {
                min: el.minValue,
                max: el.maxValue,
              }
            }
          }
          if (allVal[el.code] || allVal[el.code] === 0) {
            newVal[el.code] = Number(allVal[el.code].toFixed(2))
          }
          if (!this.firstEases[el.code]) {
            this.firstEases[el.code] = el.originalValue
          } else if (this.firstEases[el.code] !== el.originalValue && !this.allUserValue[el.code]) {
            this.firstEases[el.code] = el.originalValue
          }
          if (!this.firstRange[el.code]) {
            this.firstRange[el.code] = {
              min: el.minValue,
              max: el.maxValue,
            }
          }
        })
        this.$emit('apply', {
          ease: newVal,
          fabricType: this.fabricType,
          fit: this.fit,
        })
        this.markMannequinChanged()
        this.options = eases
        this.reset()
      } catch (e) {
        this.$emit('error', { title: 'Error', message: e.message })
      }
      this.loadingInProgress = false
    },
    async changeMan () {
      this.reset()
      // if (Number(this.designOptions.fabricType)) {
      this.fabricType = this.fabricTypeOptions.find(el => el.value === this.designOptions.fabricType)
      // } else {
      //   this.fabricType = this.designOptions.fabricType
      // }
      // if (Number(this.designOptions.fit)) {
      this.fit = fitSelect.find(el => Number(el.value) === Number(this.designOptions.fit))
      // } else {
      //   this.fit = this.designOptions.fit
      // }
      this.options = []
      this.changedRange = {}
      this.allUserValue = {}
      this.changedEmit()
      await this.loadEases()
      this.options.forEach(el => {
        this.firstEases[el.code] = el.originalValue
      })
    },
    async allReset () {
      this.reset()
      this.changedRange = {}
      this.allUserValue = {}
      this.designOptions.eases = {}
      this.fabricType = this.fabricTypeOptions.find(el => el.value === getFabricTypeDefaultValue(this.designFabric))
      this.fit = fitSelect.find(el => Number(el.value) === Number(defaultValues.FIT_SELECT))
      this.changedEmit()
      this.$emit('apply', {
        ease: {},
        fabricType: this.fabricType,
        fit: this.fit,
      })
      await this.loadEases()
      this.options.forEach(el => {
        this.firstEases[el.code] = el.originalValue
        this.firstRange[el.code] = {
          min: el.minValue,
          max: el.maxValue,
        }
      })
    },
    reset () {
      this.easeUserValues = {}
      this.$refs['easeOptions'].forEach(input => {
        input.reset()
      })
    },
    ...mapActions('fitEditor', ['markMannequinChanged']),
  },

}
</script>

<style lang="scss">
  .ease-page-comp {
    .select-block-radio .radio-item {
      min-width: 20px;
    }
    .stretch-factor-block {
      .input-block {
        width: 88px;
        &:first-child {
          width: 220px;
        }
      }
    }
    .shrinkage, .pattern-compression {
      .input-block {
        width: 88px;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    .fit-block {
      width: 322px;
      margin-bottom: 32px;
    }
    .settings-block {
      display: grid;
      gap: 40px;
      grid-template-columns: auto auto auto;
      .title-3 {
        margin-bottom: 16px;
      }
      .range {
        margin-top: 8px;
      }
      .fabric-type,
      .horizontal-shrinkage {
        margin-right: 16px;
      }
    }
    .advanced {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .units {
        font-size: 14px;
        margin-bottom: 4px;
        p {
          display: none;
          margin-bottom: 8px;
        }
      }
    }
    .table {
      margin: 20px 0 180px;
      border-radius: 12px;
      border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
      .table-row {
        border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
        padding: 8px 16px;
        display: grid;
        grid-template-columns: minmax(25%, 30%) minmax(15%, 20%) minmax(15%, 20%) minmax(15%, 20%);
        gap: 16px;
        &:last-child {
          border: none;
        }
        .preset, .pattern {
          display: flex;
          align-items: center;
        }
        .input-block {
          margin-bottom: 0;
        }
      }
      .title-row {
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        padding: 16px;
        p {
          margin: 0;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    .buttons {
      position: fixed;
      border-top: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #FAFBFB;
      &__container {
        display: flex;
        justify-content: center;
        padding: 24px 16px;
        max-width: 1440px;
        margin: auto;
        position: relative;
      }
      .reset_button {
        cursor: pointer;
        position: absolute;
        left: 16px;
        height: 48px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
      button {
        width: 306px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
          margin-right: 24px;
        }
      }
      .button-main {
        display: flex;
      }
    }
    .buttons.stop {
      position: absolute;
      padding: 24px 0;
      bottom: 0;
    }
  }
  @media (max-width: 964px) {
    .ease-page-comp {
      .buttons__container {
        justify-content: space-between;
      }
      .buttons {
        border-top: none;
        justify-content: space-between;
        flex-wrap: wrap;
        .reset_button {
          position: initial;
          margin-right: 16px;
        }
        .button-main {
          justify-content: flex-end;
        }
      }
      .table {
        border: none;
        .table-row {
          border-radius: 12px;
          border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
          grid-template-columns: 1fr 1fr 1fr;
          padding: 0;
          margin-bottom: 16px;
          .preset, .pattern {
            flex-direction: column;
            align-items: baseline;
            .name-table {
              margin-bottom: 22px;
            }
          }
          &:last-child {
            margin-bottom: 0;
            border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
          }
          .name {
            grid-column: 1 / 4;
            border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
            padding: 16px;
          }
          .custom {
            padding: 0 0 16px 16px;
          }
          .pattern {
            padding: 0 16px 16px 0;
          }
        }
        .pointName {
          display: none;
        }
      }
    }
  }
  @media (max-width: 760px) {
    .ease-page-comp {
      .table .table-row:hover {
        background-color: transparent;
      }
      .buttons {
        .button-main {
          button {
            width: fit-content;
            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 564px) {
    .ease-page-comp {
      .select-block-radio .radio-item {
        min-width: 50%;
      }
      .fit-block {
        width: 100%;
      }
      .settings-block {
        .range {
          margin-bottom: 0;
        }
        div {
          grid-column: 1 / 4;
        }
        .stretch-factor-block {
          .input-block {
            width: 100%;
            margin-right: 0;
            margin-bottom: 0;
            &:first-child {
              margin-bottom: 24px;
            }
          }
        }
        .shrinkage, .pattern-compression {
          .input-block {
            &:first-child {
              margin-right: 16px;
            }
            width: calc(50% - 8px);
            margin-bottom: 0;
          }
        }
      }
      .advanced {
        flex-wrap: wrap;
        margin-top: 32px;
        .units {
          width: 100%;
          margin-top: 16px;
          p {
            display: block;
          }
        }
        .title-3 {
          margin-bottom: 16px;
        }
      }
      .table {
        border: none;
        margin-top: 38px;
        .table-row {
          border-radius: 12px;
          border: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
          grid-template-columns: 1fr 1fr 1fr;
          gap: 0;
          padding: 0;
          margin-bottom: 16px;
          .input-block {
            max-width: 104px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .name-table {
            color: #6F8789;
          }
          .preset, .pattern {
            flex-direction: row;
            align-items: center;
            .name-table {
              margin-bottom: 0;
            }
          }
          div p {
            margin: 0;
          }
          .name {
            grid-column: 1 / 4;
            border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
            padding: 16px;
            .nameText {
              font-weight: 600;
            }
          }
          .preset {
            grid-column: 1 / 4;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
            padding: 18px 16px;
          }
          .custom {
            grid-column: 1 / 4;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--contour, rgba(15, 104, 116, 0.20));
            justify-content: space-between;
          }
          .pattern {
            grid-column: 1 / 4;
            padding: 18px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    .ease-page-comp {
      .buttons__container {
        flex-wrap: wrap;
      }
      .buttons {
        .button-main {
          width: 100%;
          button {
            width: 100%;
          }
        }
        background-color: #fff;
      }
    }
  }
</style>
