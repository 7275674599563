<template>
  <div class="billing_page">
    <h1>Billing</h1>
    <div class="text-desc">
      <template v-if="demoMode">
        <p class="desc">
          We use the highest standards of security to protect your information, so you can have peace of mind while enjoying all the benefits.
        </p>
        <p class="click" v-if="subscriptionStatus !== 'ACTIVE'">
          Click below to connect your card and unlock everything we have to offer.
        </p>
      </template>
      <template v-else>
        <p v-if="periodEndDate" class="payment-info">Next payment on {{ periodEndDate }}</p>
        <p v-if="lastCardNum" class="payment-info">Using card **** {{ lastCardNum }}</p>
      </template>
    </div>
    <activate-plan isButtonAddPayment />
    <div class="plans">
      <h2>Plans</h2>
      <ButtonsGroup>
        <button :class="[tab === 0 ? 'active' : '']" @click="tab = 0">Individual</button>
        <button :class="[tab === 1 ? 'active' : '']" @click="tab = 1">Mass Production</button>
        <button :class="[tab === 2 ? 'active' : '']" @click="tab = 2">MTM / MTO</button>
      </ButtonsGroup>
      <div class="tab-content" v-if="tab === 0">
        <PlanComponent
          :class="[subscriptionPlan === plans[0].code ? 'current' : '']"
          :title="plans[0].title"
          :description="plans[0].description"
          :price="plans[0].price"
          :oldPrice="plans[0].oldPrice"
          :scope="plans[0].scope"
          :button-text="buttonText(plans[0])"
          @action="openModal(plans[0])"
        />
        <PlanComponent
          :class="[subscriptionPlan === plans[1].code ? 'current' : '']"
          :title="plans[1].title"
          :description="plans[1].description"
          :price="plans[1].price"
          :oldPrice="plans[1].oldPrice"
          :scope="plans[1].scope"
          :button-text="buttonText(plans[1])"
          @action="openModal(plans[1])"
        />
      </div>
      <div class="tab-content" v-if="tab === 1">
        <PlanComponent
          :class="[subscriptionPlan === plans[2].code ? 'current' : '']"
          :title="plans[2].title"
          :description="plans[2].description"
          :price="plans[2].price"
          :oldPrice="plans[2].oldPrice"
          :scope="plans[2].scope"
          :button-text="buttonText(plans[2])"
          @action="openModal(plans[2])"
        />
        <PlanComponent
          :class="[subscriptionPlan === plans[3].code ? 'current' : '']"
          :title="plans[3].title"
          :description="plans[3].description"
          :price="plans[3].price"
          :oldPrice="plans[3].oldPrice"
          :scope="plans[3].scope"
          :button-text="buttonText(plans[3])"
          @action="openModal(plans[3])"
        />
      </div>
      <div class="tab-content" v-if="tab === 2">
        <PlanComponent
          :class="[subscriptionPlan === plans[4].code ? 'current' : '']"
          :title="plans[4].title"
          :description="plans[4].description"
          :price="plans[4].price"
          :scope="plans[4].scope"
          button-text="Coming Soon"
          button-disabled
        />
        <PlanComponent
          :class="[subscriptionPlan === plans[5].code ? 'current' : '']"
          :title="plans[5].title"
          :description="plans[5].description"
          :price="plans[5].price"
          :scope="plans[5].scope"
          button-text="Coming Soon"
          button-disabled
        />
      </div>
    </div>
    <div class="details">
      <h2>Compare in details</h2>
      <div class="details-table">
        <div class="heading">Features</div>
        <div class="heading">Student</div>
        <div class="heading">Indie</div>
        <div class="heading">Fashion startup</div>
        <div class="heading">Manufacturer</div>
        <div class="heading">MTM Starter</div>
        <div class="heading">MTM Pro</div>

        <div class="label">2D flats design templates</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Fit pattern blocks</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Patterns + sketches downloads</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Unlimited pattern downloads</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Adjustments</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Custom FitModels</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Single size Marker layout</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Standart Fit Charts</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Custom Fit Charts</div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="no"></div>
        <div class="no"></div>

        <div class="label">Automated Standart Size Grading</div>
        <div class="no"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="no"></div>
        <div class="no"></div>

        <div class="label">Automated Custom Size Grading</div>
        <div class="no"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="no"></div>
        <div class="no"></div>

        <div class="label">Single size Tech Packs</div>
        <div class="no"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="no"></div>
        <div class="no"></div>

        <div class="label">Tech Packs with all size runs</div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">MTM Pattern castomization</div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">MTM Order processing dashboard</div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="yes"></div>

        <div class="label">API Scan to MTM Pattern Customization</div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="yes"></div>

        <div class="label">Standard Technical Support</div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="yes"></div>

        <div class="label">Priority Technical Support</div>
        <div class="no"></div>
        <div class="no"></div>
        <div class="yes"></div>
        <div class="yes"></div>
        <div class="no"></div>
        <div class="yes"></div>

        <div class="label">Up to how many users</div>
        <div class="text">1</div>
        <div class="text">1</div>
        <div class="text">3</div>
        <div class="text">5</div>
        <div class="text">1</div>
        <div class="text">5</div>

        <div class="label nb">License</div>
        <div class="text nb">Personal</div>
        <div class="text nb">Personal</div>
        <div class="text nb">Commercial</div>
        <div class="text nb">Commercial</div>
        <div class="text nb">Commercial</div>
        <div class="text nb">Commercial</div>
      </div>

      <div class="details-table-mobile">
        <div class="label">2D flats design templates</div>
        <div class="heading">Student</div>
        <div class="yes"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="yes"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="yes brr"></div>

        <div class="label">Fit pattern blocks</div>
        <div class="heading">Student</div>
        <div class="yes"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="yes"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="yes brr"></div>

        <div class="label">Patterns + sketches downloads</div>
        <div class="heading">Student</div>
        <div class="yes"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="yes"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="yes brr"></div>

        <div class="label">Unlimited pattern downloads</div>
        <div class="heading">Student</div>
        <div class="yes"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="yes"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="yes brr"></div>

        <div class="label">Adjustments</div>
        <div class="heading">Student</div>
        <div class="yes"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="yes"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="yes brr"></div>

        <div class="label">Custom FitModels</div>
        <div class="heading">Student</div>
        <div class="yes"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="yes"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="yes brr"></div>

        <div class="label">Single size Marker layout</div>
        <div class="heading">Student</div>
        <div class="yes"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="yes"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="yes brr"></div>

        <div class="label">Standart Fit Charts</div>
        <div class="heading">Student</div>
        <div class="yes"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="yes"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="yes brr"></div>

        <div class="label">Custom Fit Charts</div>
        <div class="heading">Student</div>
        <div class="no"></div>
        <div class="heading">Indie</div>
        <div class="no"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="no"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="no brr"></div>

        <div class="label">Automated Standart Size Grading</div>
        <div class="heading">Student</div>
        <div class="no"></div>
        <div class="heading">Indie</div>
        <div class="yes"></div>
        <div class="heading">Fashion startup</div>
        <div class="yes"></div>
        <div class="heading">Manufacturer</div>
        <div class="yes"></div>
        <div class="heading">MTM Starter</div>
        <div class="no"></div>
        <div class="heading blr">MTM Pro</div>
        <div class="no brr"></div>

        <div class="label">Up to how many users</div>
        <div class="heading">Student</div>
        <div class="text">1</div>
        <div class="heading">Indie</div>
        <div class="text">1</div>
        <div class="heading">Fashion startup</div>
        <div class="text">3</div>
        <div class="heading">Manufacturer</div>
        <div class="text">5</div>
        <div class="heading">MTM Starter</div>
        <div class="text">1</div>
        <div class="heading blr">MTM Pro</div>
        <div class="text brr">5</div>

        <div class="label nb">License</div>
        <div class="heading">Student</div>
        <div class="text nb">Personal</div>
        <div class="heading">Indie</div>
        <div class="text nb">Personal</div>
        <div class="heading">Fashion startup</div>
        <div class="text nb">Commercial</div>
        <div class="heading">Manufacturer</div>
        <div class="text nb">Commercial</div>
        <div class="heading">MTM Starter</div>
        <div class="text nb">Commercial</div>
        <div class="heading blr">MTM Pro</div>
        <div class="text nb brr">Commercial</div>
      </div>
    </div>
    <q-dialog v-model="upgradePlan">
      <dialog-card-base-new
        contentContainerClass="normalBlock"
        class="upgradePlanModal"
      >
        <div class="upgradePlanModal__block">
          <template v-if="changeError">
            <div class="main-icon error">
              <svg
                width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16.0003" r="12.5833" stroke="#F52727" stroke-width="1.5"/>
                <path d="M16 9.33301V17.333" stroke="#F52727" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M16 21.333L16 21.3331" stroke="#F52727" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </div>
            <p class="title">
              Ooops... <br> Something went wrong
            </p>
            <p class="subtitle" v-if="activePlanNumber > newPlan?.index">
              Error while downgrading to {{ newPlan?.title }}. Please try again
            </p>
            <p class="subtitle" v-else-if="activePlanNumber === newPlan?.index">
              Error while canceling subscription. Please try again
            </p>
            <p class="subtitle" v-else>
              Error while upgrading to {{ newPlan?.title }}. Please try again
            </p>
            <div class="buttons">
              <button
                class="small_button white_button"
                @click="close"
              >
                Maybe later
              </button>
              <button
                class="green_button small_button"
                @click="change"
              >
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.1665 6.66667H16.3332V2.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.0913 13.6306C15.3148 14.8263 14.1754 15.7416 12.8403 16.2421C11.5053 16.7426 10.045 16.8019 8.67377 16.4113C7.30256 16.0207 6.09269 15.2008 5.22178 14.0719C4.35088 12.9431 3.86482 11.5647 3.83496 10.1393C3.8051 8.71384 4.23302 7.31637 5.05589 6.15204C5.87876 4.98771 7.05323 4.11785 8.40688 3.67017C9.76053 3.22249 11.2221 3.22058 12.5769 3.66471C13.9317 4.10885 15.1084 4.97563 15.9343 6.1378" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Try again
              </button>
            </div>
          </template>
          <template v-else-if="changeSuccess">
            <div class="main-icon">
              <svg
                width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" d="M14.3391 5.11172C15.2962 4.29609 16.7038 4.29609 17.6609 5.11172L19.2889 6.49905C19.699 6.84857 20.209 7.0598 20.7461 7.10266L22.8783 7.27281C24.1318 7.37284 25.1272 8.36823 25.2272 9.62174L25.3973 11.7539C25.4402 12.291 25.6514 12.801 26.0009 13.2111L27.3883 14.8391C28.2039 15.7962 28.2039 17.2038 27.3883 18.1609L26.0009 19.7889C25.6514 20.199 25.4402 20.709 25.3973 21.2461L25.2272 23.3783C25.1272 24.6318 24.1318 25.6272 22.8783 25.7272L20.7461 25.8973C20.209 25.9402 19.699 26.1514 19.2889 26.5009L17.6609 27.8883C16.7038 28.7039 15.2962 28.7039 14.3391 27.8883L12.7111 26.5009C12.301 26.1514 11.791 25.9402 11.2539 25.8973L9.12174 25.7272C7.86823 25.6272 6.87284 24.6318 6.77281 23.3783L6.60266 21.2461C6.5598 20.709 6.34857 20.199 5.99905 19.7889L4.61172 18.1609C3.79609 17.2038 3.79609 15.7962 4.61172 14.8391L5.99905 13.2111C6.34857 12.801 6.5598 12.291 6.60266 11.7539L6.77281 9.62174C6.87284 8.36823 7.86823 7.37284 9.12174 7.27281L11.2539 7.10266C11.791 7.0598 12.301 6.84857 12.7111 6.49905L14.3391 5.11172Z" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_2" d="M20 13.833L14.6667 19.1663L12 16.4997" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p class="title" v-if="activePlanNumber > newPlan?.index">
              Your plan was successfully downgraded to {{ newPlan?.title }}!
            </p>
            <p class="title" v-else-if="activePlanNumber === newPlan?.index">
              Your subscription is cancelled
            </p>
            <p class="title" v-else>Your plan was successfully upgraded to {{ newPlan?.title }}!</p>
            <p class="subtitle" v-if="activePlanNumber === newPlan?.index">We always welcome your return!</p>
            <button
              class="green_button small_button"
              style="max-width: fit-content; padding: 10px 16px;"
              @click="close"
            >
              I’ve got it
            </button>
          </template>
          <template v-else>
            <div
              :class="`main-icon ${activePlanNumber > newPlan?.index || activePlanNumber === newPlan?.index ? 'error' : ''}`"
            >
              <svg
                v-if="activePlanNumber > newPlan?.index || activePlanNumber === newPlan?.index"
                width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle id="Ellipse 1" cx="16" cy="16.0003" r="12.5833" stroke="#F52727" stroke-width="1.5"/>
                <path id="Vector 5" d="M16 9.33301V17.333" stroke="#F52727" stroke-width="1.5" stroke-linecap="round"/>
                <path id="Vector 6" d="M16 21.333L16 21.3331" stroke="#F52727" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
              <svg
                v-else
                width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6666 22.6663L16 17.333L21.3333 22.6663" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6666 14.6663L16 9.33301L21.3333 14.6663" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p class="title" v-if="activePlanNumber > newPlan?.index">
              Are you sure you want <br> to downgrade your current plan?
            </p>
            <p class="title" v-else-if="activePlanNumber === newPlan?.index">
              Are you sure you want <br> to unsubscribe?
            </p>
            <p class="title" v-else>You're upgrading <br> your subscription <br>to Manufacture!</p>
            <p v-if="activePlanNumber > newPlan?.index" class="subtitle">
              Thus, you will lose access to <br> the following features:
            </p>
            <p v-else-if="activePlanNumber === newPlan?.index" class="subtitle">
              You will be able to use your plan until your paid subscription ends, after which you will lose the following features:
            </p>
            <p v-else class="subtitle">
              Your plan will be updated as soon as the difference in plans is paid. This will happen
              automatically once you confirm that you want to upgrade your plan</p>
            <div class="adv">
              <div v-for="item in changePlanAdv" :key="item.index">
                <svg v-if="item.error" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 18L6 6" stroke="#F52727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18 6L6 18" stroke="#F52727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 12.0005L10.2426 16.2431L18.7279 7.75781" stroke="#09373D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p>{{ item.text }}</p>
              </div>
            </div>
            <button
              class="green_button small_button"
              style="width:100%; padding: 10px 16px;"
              @click="activePlanNumber > newPlan?.index || activePlanNumber === newPlan?.index ? close() : change()"
            >
              {{
                activePlanNumber > newPlan?.index || activePlanNumber === newPlan?.index
                  ? 'Keep my current plan' : `Upgrade to ${newPlan?.title}`
              }}
            </button>
            <button
              class="small_button white_button"
              style="width:100%; padding: 10px 16px;"
              @click="activePlanNumber > newPlan?.index || activePlanNumber === newPlan?.index ? change() : close()"
            >
              {{
                activePlanNumber === newPlan?.index
                  ? 'Unsubscribe' : activePlanNumber > newPlan?.index
                    ? 'Downgrade' : 'Cancel'
              }}
            </button>
          </template>
        </div>
      </dialog-card-base-new>
    </q-dialog>
  </div>
</template>

<script>
import { billingPlans } from '@/helpers/plans'
import activatePlan from '@/components/stripe/activatePlan'
import { mapGetters } from 'vuex'
import { changePlan } from '@/api'
import DialogCardBaseNew from '@/components/Dialog/DialogCardBaseNew'
import ButtonsGroup from '@/components/Atoms/ButtonsGroup'
import PlanComponent from '@/components/PlanComponent'

export default {
  components: {
    activatePlan,
    DialogCardBaseNew,
    ButtonsGroup,
    PlanComponent,
  },
  computed: {
    ...mapGetters('user', ['subscriptionStatus', 'subscriptionPlan', 'lastCardNum', 'periodEndDate', 'demoMode']),
    activePlanNumber () {
      return this.plans.findIndex(el => el.code === this.subscriptionPlan)
    },
    changePlanAdv () {
      if (this.upgradePlan) {
        if (this.activePlanNumber === 0) {
          if (this.activePlanNumber === this.newPlan?.index) {
            return [
              { error: true, text: 'Have licence' },
            ]
          } else {
            return [
              { text: 'Add or manage team members' },
              { text: 'Have full commercial rights' },
            ]
          }
        } else if (this.activePlanNumber === 1 && this.newPlan?.index === 2) {
          return [
            { text: 'Unlimited commercial licence' },
            { text: 'Up to 5 users' },
          ]
        } else if (this.activePlanNumber === 2 && this.newPlan?.index === 1) {
          return [
            { error: true, text: 'Unlimited commercial licence' },
            { error: true, text: 'Down to 3 users' },
          ]
        } else {
          return [
            { error: true, text: 'Add or manage team members' },
            { error: true, text: 'Have licence' },
            { error: true, text: 'Have full commercial rights' },
          ]
        }
      } return []
    },
  },
  watch: {
    upgradePlan (val) {
      if (!val) {
        this.newPlan = null
        this.changeError = false
        this.changeSuccess = false
      }
    },
  },
  methods: {
    openModal (plan) {
      this.upgradePlan = true
      this.newPlan = plan
    },
    async change () {
      let changed = await changePlan(this.newPlan.code)
      if (changed === 'error') this.changeError = true
      else this.changeSuccess = true
    },
    close () {
      this.upgradePlan = false
      this.changeError = false
      this.changeSuccess = false
    },
    buttonText (plan) {
      return this.activePlanNumber === plan.index
        ? 'Cancel subscription'
        : this.activePlanNumber > plan.index
          ? 'Downgrade plan'
          : 'Upgrade plan'
    },
  },
  data: () => ({
    tab: 0,
    changeError: false,
    changeSuccess: false,
    lastPlan: null,
    newPlan: null,
    upgradePlan: false,
    plans: billingPlans,
  }),
  beforeMount () {
    const plansTab = [0, 0, 1, 1, 2, 2]
    if (plansTab[this.activePlanNumber]) {
      this.tab = plansTab[this.activePlanNumber]
    }
  },
}
</script>

<style lang="scss">
  .upgradePlanModal {
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      width: 100%;
      button {
        width: calc(50% - 8px);
        padding: 10px 16px;
        margin: 0;
      }
      .green_button {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: 8px;
        }
      }
    }
    &__block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .adv {
      width: 100%;
      div {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        p {
          margin: 0;
          font-size: 14px;
        }
        svg {
          margin-right: 8px;
        }
      }
    }
    .main-icon {
      border-radius: 50%;
      background: var(--Dividers, #E0E9EA);
      height: 64px;
      width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .main-icon.error {
      background: var(--Dividers, #FFF3F3);
    }
    .title {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 8px;
      margin-top: 24px;
    }
    .subtitle {
      color: #6F8789;
    }
  }
  .billing_page {
    padding: 0 16px 0 40px;

    .details {
      color: var(--text_color);
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin: 48px 0;

      .details-table {
        display: grid;
        grid-template-columns: auto repeat(6, 1fr);
        border-radius: 12px;
        border: 1px solid var(--Dividers);
        margin-top: 24px;

        color: var(--Text-Text_Primary);

        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        & > div {
          padding: 16px;
          border-bottom: 1px solid var(--Dividers);

          &.nb {
            border-bottom: none;
          }
        }

        .heading {
          font-weight: 600;
          white-space: nowrap;
        }

        .label {
          width: 100%;
          max-width: 280px;
        }

        .yes {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADASURBVHgB7ZNBDoIwEEVnBA16BYTgzhMoCzV6I28AN1N27Iw3QIR9lyagtTUxaUyJ1LKQpG/VZpr303YGwGD4eyzoACfcBkPXP9nelNTF9SzWBqAJlyN9HAAo292TzzqCBqKcYrW7pWnWWUAbOUf6ROPlej9ZbGLQlDcGcCjSSBaiIudIu6gu89T2fL6MR26AVXk5/iJvDHiFFHkihljeLFOVc75+shOuIqQYMy9hp4mKHKDFoL1vgoBzVbnB0BOeSy5vpwAuBxEAAAAASUVORK5CYII=);
          background-position: 16px center;
          background-repeat: no-repeat;
        }

        .no {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADHSURBVHgB7ZRBDoMgEEVnSDdt0/QWorfjBuIJmt7OcIomlXTHdGjKDhHQnb6ExMTvfJk/AHCwT6auU++21SX6SUoVeyeiXzgHgqjPMbGsQeceSHSHEj5N01spKWXii3uNZS3UkDJZXTxlUlIcIQNv4hA1r+EXGufDS1+NGWArwk5K2yJyhU4IjD0vccoR+Z6Htvz/SHMmcBtHDWuJBZozwtXFNzPJGcVqk5I5DyZzd1E0ZCJ6cZjqYswTFjjzWWATIESCg33yBTDnkVqdtxcHAAAAAElFTkSuQmCC);
          background-position: 16px center;
          background-repeat: no-repeat;
        }
      }
      .details-table-mobile {
        display: none;
      }

    }
    p {
      margin: 0;
    }
    h1 {
      color: var(--text_color, #1D1F29);
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 600;
      line-height: normal;
      margin: 0;
      padding-bottom: 11px;
    }
    h2 {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
    }
    .text-desc {
      font-size: 14px;
      max-width: 450px;
      .desc {
        margin-bottom: 10px;
        color: var(--Text-Text_Secondary);
      }
      .click {
        font-weight: 600;
      }
      .payment-info {
        font-weight: 600;
      }
    }
    .green_button {
      width: 247px;
      margin-top: 32px;
    }
    .white_button {
      width: 247px;
      margin-top: 32px;
    }
    .plans {
      h2 {
        color: var(--Text-Text_Primary);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin: 0;
        margin-top: 48px;
        margin-bottom: 16px;
      }
      .buttons-group {
        max-width: 445px;
        margin-bottom: 36px;
      }
      .tab-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        column-gap: 30px;
      }
    }
  }
@media (max-width: 1200px) {
  .billing_page .plans {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    .plan:last-child {
      grid-column: span 2;
    }
  }
}
@media (max-width: 768px) {
  .billing_page .plans .plan {
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .billing_page {
    padding: 0;

    h1 {
      font-size: 24px;
      padding-bottom: 16px;
    }
    .desc {
      font-size: 12px;
    }

    .buttons-group {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      button {
        border-radius: 8px;
      }
    }
    .plans {
      .tab-content {
        flex-direction: column;
        row-gap: 32px;
      }
      h2 {
        margin-top: 32px;
      }
    }

    .details {
      .details-table {
        display: none;
      }
      .details-table-mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 8px;

        & > div {
          padding: 16px;
          border: 1px solid var(--Dividers);
          color: var(--Text-Text_Primary);
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }

        .label {
          grid-column-start: 1;
          grid-column-end: 3;
          margin-top: 16px;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          font-weight: 600;
        }

        .blr {
          border-bottom-left-radius: 12px;
        }
        .brr {
          border-bottom-right-radius: 12px;
        }

        .heading {
          border-right: none;
          border-top: none;
        }
        .yes {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADASURBVHgB7ZNBDoIwEEVnBA16BYTgzhMoCzV6I28AN1N27Iw3QIR9lyagtTUxaUyJ1LKQpG/VZpr303YGwGD4eyzoACfcBkPXP9nelNTF9SzWBqAJlyN9HAAo292TzzqCBqKcYrW7pWnWWUAbOUf6ROPlej9ZbGLQlDcGcCjSSBaiIudIu6gu89T2fL6MR26AVXk5/iJvDHiFFHkihljeLFOVc75+shOuIqQYMy9hp4mKHKDFoL1vgoBzVbnB0BOeSy5vpwAuBxEAAAAASUVORK5CYII=);
          background-position: center center;
          background-repeat: no-repeat;
          border-top: none;
        }

        .no {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADHSURBVHgB7ZRBDoMgEEVnSDdt0/QWorfjBuIJmt7OcIomlXTHdGjKDhHQnb6ExMTvfJk/AHCwT6auU++21SX6SUoVeyeiXzgHgqjPMbGsQeceSHSHEj5N01spKWXii3uNZS3UkDJZXTxlUlIcIQNv4hA1r+EXGufDS1+NGWArwk5K2yJyhU4IjD0vccoR+Z6Htvz/SHMmcBtHDWuJBZozwtXFNzPJGcVqk5I5DyZzd1E0ZCJ6cZjqYswTFjjzWWATIESCg33yBTDnkVqdtxcHAAAAAElFTkSuQmCC);
          background-position: center center;
          background-repeat: no-repeat;
          border-top: none;
        }
        .text {
          text-align: center;
        }
      }
    }
    .green_button, .white_button {
      margin-top: 16px;
      font-size: 14px;
      padding: 12px;
    }
    .plans {
      grid-template-columns: 1fr;
      gap: 16px;
      margin-top: 32px;
      .plan:last-child {
        grid-column: 1;
      }
      &__tag {
        font-size: 14px;
      }
      &__title {
        font-size: 20px;
      }
      &__desc, &__text {
        font-size: 11px;
      }
      &__price {
        display: none;
      }
      &__price-mobile {
        display: block;
      }
      &__advantage-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: column;
      }
      &__advantage-block.row-3 {
        grid-template-rows: auto auto auto;
      }
    }
    .details {
      margin: 32px 0;
    }
    .details__block {
      border: 1px solid var(--Dividers, #E0E9EA);
      border-top: none;
      flex-wrap: wrap;
      padding: 0;
      .details__title {
        width: 100%;
        border-bottom: 1px solid var(--Dividers, #E0E9EA);
        padding: 16px;
        text-align: center;
      }
      .details__plan {
        width: calc(100% / 3);
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      &:nth-child(2) {
        border: none;
        flex-wrap: nowrap;
        padding: 16px 0;
        position: relative;
        .details__title {
          display: none;
        }
        .details__plan {
          padding: 0 4px;
          text-align: center;
        }
        &::after {
          content: '';
          height: 1px;
          background-color: #E0E9EA;
          width: calc(100% + 32px);
          position: absolute;
          bottom: 0;
          margin-left: -16px;
        }
      }
    }
  }
}
</style>
