const hasProperties = function (obj, props = []) {
  return props.every(prop => Object.prototype.hasOwnProperty.call(obj, prop))
}

function getUrlParams (url) {
  if (typeof url === 'undefined') {
    url = window.location.search
  }
  url = url.split('#')[0] // Discard fragment identifier.
  let urlParams = {}
  let queryString = decodeURIComponent(url.split('?')[1])
  if (!queryString) {
    if (url.search('=') !== false) {
      queryString = url
    }
  }
  if (queryString) {
    let keyValuePairs = queryString.split('&')
    for (let i = 0; i < keyValuePairs.length; i++) {
      let keyValuePair = keyValuePairs[i].split('=')
      let paramName = keyValuePair[0]
      let paramValue = keyValuePair[1] || ''
      urlParams[paramName] = paramValue.replace(/\+/g, ' ')
    }
  }
  return urlParams
}

const postRedirect = function (url, token) {
  let form = document.createElement('form')
  let tokenInput = document.createElement('input')

  form.method = 'POST'
  form.action = url
  form.setAttribute('class', 'hidden')

  tokenInput.value = token
  tokenInput.name = 'token'
  form.appendChild(tokenInput)

  document.body.appendChild(form)

  form.submit()
}

const serializeFilters = function (filtersObject) {
  let keys = Object.keys(filtersObject)
  keys.sort()
  return keys
    .filter(key => filtersObject[key].length > 0)
    .map(key => {
      let items = [...filtersObject[key]]
      items.sort()
      return items.join(',')
    })
}

export {
  hasProperties,
  getUrlParams,
  postRedirect,
  serializeFilters,
}
