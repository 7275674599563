import { makeAbsolute, parseSVG } from 'svg-path-parser'
import some from 'lodash.some'
import every from 'lodash.every'
import memoize from 'lodash.memoize'
import lineclip from 'lineclip'
import { intersect, shape } from 'svg-intersections'
import Bbox from 'svg-path-bounding-box'
import { bezier } from './utils/bezier'
import {ShapeInfo, Intersection} from 'kld-intersections'

import {
  findIntersectingCommand,
  splitCommandByPoint,
  fixWrongCommand
} from './path/commands'
import render from './path/renderCommands'
import {
  paths as splitToPaths,
  pathLength,
  reversePath,
  cutPathEnd,
  cutPathHead,
  isPointOnPath,
  slicedBounds,
  formatPathObject,
  comparePathEdges,
  pathInsideContourPositions,
  pathUniqPoints,
  pathNotOutsideContourPositions,
  extractEdgePoints
} from './path/index'
import { matchPoints, MATCH_DELTA } from './path/point'
import { clear, visualizePath, visualizeRect, visualizePoints } from './path/renderPoints'
import { svgPathProperties } from 'svg-path-properties'
import { log, DEBUG } from './utils/logger'
import { findContour, mergeContourPaths } from './path/contour'
import {simplifyPath} from "./path/simplification";

intersect.plugin(bezier)

let splitContoursCalls = 0
let displaySide = 'svgFront'
let findBbox = memoize((path) => Bbox(path))
export const pathArea = pathLength
/**
 * Combine dependencies and elements paths
 * @param injectable - Svg string
 * @param d A SVG<Path> "d" attribute
 * @param slice int - leave trailing peaces of intersection or slice them up
 * @return {*} A SVG<Path> "d" attribute
 */
export const injector = (injectable, d, slice) => {
  let newPath = d
  const injectablePaths = splitToPaths(injectable)
  let skipped = []
  injectablePaths.forEach(injectable => {
    const injected = injectToPath(injectable.attributes.d, newPath, slice)
    if (newPath === injected) {
      skipped.push(injectable.attributes.d)
    }
    newPath = injected
  })
  if (skipped.length && newPath !== d) {
    newPath = skipped.join(' ') + newPath
  }
  return newPath
}

/**
 * Inject dependency to path
 * @param injectableD - A SVG<Path> "d" attribute
 * @param pathD - A SVG<Path> "d" attribute, where to inject
 * @param {Boolean} slice - leave trailing peaces of intersection or slice them up
 * @param delta
 * @return {*} A SVG<Path> "d" attribute, injection result
 */
const injectToPath = (injectableD, pathD, slice, delta = MATCH_DELTA) => {
  const injectable = render(makeAbsolute(parseSVG(injectableD)))
  const path = render(makeAbsolute(parseSVG(pathD)))
  let injected = path
  try {
    const [start, end] = extractEdgePoints(injectable)
    const [matchStart, matchEnd] = extractEdgePoints(path)
    // check full match first
    if (matchPoints(matchEnd, start, 4 * delta) && matchPoints(matchStart, end, 4 * delta)) {
      injected = cutPathEnd(injectable) + cutPathEnd(cutPathHead(path)) + 'Z'
    } else if (matchPoints(matchEnd, end, 4 * delta) && matchPoints(matchStart, start, 4 * delta)) {
      injected = cutPathEnd(reversePath(injectable)) + cutPathEnd(cutPathHead(path)) + 'Z'
    } else {
      // check with reduced precision
      const diff = comparePathEdges(injectable, path, delta)
      if (diff) {
        if (diff === 1) {
          injected = cutPathEnd(injectable) + cutPathEnd(cutPathHead(path)) + 'Z'
        }
        if (diff === -1) {
          injected = cutPathEnd(reversePath(injectable)) + cutPathEnd(cutPathHead(path)) + 'Z'
        }
      }
    }
  } catch (e) {
    log(e, injectable, path)
  }
  return injected
}
/**
 * Does path object have an attribute data-fillable
 * This attribute indicates filling possibility of a path to draw it opaque (white color filled)
 * @param path
 * @returns {number}
 */
const isPathFillable = path => +path.attributes['data-fillable']

/**
 * Does path object have an attribute data-fillable or data-element or data-keep
 * Presence of attribute data-element shows that path is belongs to some element inside of other
 * (like skirt inside of dress, or waistband inside pants) and cannot be deleted
 * Attribute data-keep makes path skipped in other situations
 * @param path
 * @returns {number}
 */
const isPathShouldBeSkipped = path => isPathFillable(path) || path.attributes['data-element'] || +path.attributes['data-keep']

/**
 * Is path eligible for contour creation, cut and included in paintable area creation
 * @param path
 * @returns {number}
 */
const isPathShouldBeLeft = path => !isPathShouldBeSkipped(path) && !path.attributes['data-only-slice']

/**
 * Is path should be cut-only, not included in paintable area creation
 * @param path
 * @returns {number}
 */
const isPathShouldBeCutOnly = path => !isPathShouldBeSkipped(path) && (isPathSelfClosed(path.attributes.d) || path.attributes['data-only-slice'])

/**
 * Change incoming paths to seams and areas, that might be painted
 * @param {[{attributes: {d: String}}]} paths Original path from database
 * @param {[{element: {svgFront: String, svgBack: String}, contourPart: Boolean}]} dependencies Paths to be injected (such as neckline)
 * @param {String} side
 * @returns {Array}
 */
const _preparePaths = (paths, dependencies, side, params = {}) => {
  if (DEBUG && side === 'svgFront') clear()
  let skippedPaths = [
    ...paths.filter(isPathShouldBeSkipped)
  ]
  let leftOutPaths = paths.filter(isPathShouldBeCutOnly)
  let final
  log(side)
  displaySide = side
  log('[contour] init contour search', paths, dependencies)
  const initialPaths = paths.filter(isPathShouldBeLeft)
    .filter(path => params.allowSelfClosed || !isPathSelfClosed(path.attributes.d))
    .map(formatPathObject)
    .filter(path => path.attributes.d)
  log('[contour] mapping initial paths', initialPaths)
  let processingPaths = [
    ...initialPaths,
    ...dependencies.reduce((list, dep) => {
      if (!dep.element || !dep.element[side]) return list
      let depPaths = splitToPaths(dep.element[side])
      depPaths = depPaths.filter(p => !p.attributes['data-remove'])
      if (!depPaths || !depPaths.length) return list
      depPaths = depPaths.map(p => Object.assign({}, p, {
        attributes: Object.assign({}, p.attributes, {'data-only-slice': !dep.contourPart})}))
      skippedPaths.push(...depPaths.filter(isPathShouldBeSkipped))
      leftOutPaths.push(...depPaths.filter(isPathShouldBeCutOnly))
      return [
        ...list,
        ...depPaths
          .filter(isPathShouldBeLeft)
          .filter(path => !isPathSelfClosed(path.attributes.d))
          .map(formatPathObject)
          .map(p => Object.assign({}, p, {isDependency: true}))
          .filter(path => path.attributes.d)
      ]
    }, [])
  ]
  // Find possible contours to process through-going seams
  log('[contour] processing paths for contour', processingPaths)
  // удалить пути, которые не должны включаться в контур (например, линию талии)
  const contourPaths = processingPaths.filter(p => !(p.attributes && p.attributes['data-not-contour']))
  const possibleContours = findContour(contourPaths, MATCH_DELTA, {skipFiltering: params.allowSelfClosed})
  if (!possibleContours.length) {
    log('[contour] could not find any contours', contourPaths)
  }
  if (window) {
    window[side.replace('svg', '').toLowerCase()] = {
      processingPaths,
      possibleContours,
      leftOutPaths,
      skippedPaths
    }
  }
  const separatedPaths = {
    contours: [],
    ordinary: [...processingPaths, ...leftOutPaths, ...skippedPaths.filter(isPathFillable)]
  }
  possibleContours.forEach(possibleContour => {
    if (possibleContour && possibleContour.length) {
      const finalContour = mergeContourPaths(possibleContour)
      visualizePath(finalContour.attributes.d, side, 'pink', 'finalContour')
      separatedPaths.contours.push(finalContour)
      separatedPaths.ordinary = separatedPaths.ordinary.filter(o => !o.uuid || !finalContour.mergedUuids.includes(o.uuid))
      log('[contour] found ', finalContour)
    }
  })
  if (separatedPaths.contours.length) {
    /**
     * @var {{attributes: {d: String}}} path Path object
     */
    // console.time('[TIMER] Throwing out')
    separatedPaths.ordinary = cutPathsToFitContour(separatedPaths.contours, separatedPaths.ordinary)
    // console.timeEnd('[TIMER] Cutting')

    // step third - split contour to areas (to make it paintable)
    // console.time('[TIMER] Splitting')
    const delta = MATCH_DELTA * 4
    const throughSeams = separatedPaths.ordinary
      .filter(isPathShouldBeLeft)
      .filter(path => some(separatedPaths.contours, contour => isSeamCrossCutting(contour, path, delta)))
    splitContoursCalls = 0
    const [splitedContours, removedSeams] = splitContours(separatedPaths.contours, throughSeams, delta)
    // console.timeEnd('[TIMER] Splitting')
    separatedPaths.contours = splitedContours
    // throw out seams, already present in new fillable contours
    log('to be removed', removedSeams)
    removedSeams.forEach(seam => seam && seam.attributes && visualizePath(seam.attributes.d, displaySide, '#790eff', 'DELETED'))
    separatedPaths.ordinary = separatedPaths.ordinary.filter(seam => removedSeams.indexOf(seam) === -1)
    separatedPaths.contours.forEach(contour => {
      contour.attributes['data-contour'] = 1
    })
  }
  final = [
    ...separatedPaths.contours,
    ...separatedPaths.ordinary
  ]
  final.push(...skippedPaths.filter(p => !isPathFillable(p)))
  return final
}

export const preparePaths = DEBUG
  ? _preparePaths
  : memoize(_preparePaths, (paths, dependencies, side, params = {}) => {
    return JSON.stringify({
      paths,
      dependencies,
      side,
      params
    })
  })

export const cutPathsToFitContour = (pathsWithContour, cutablePaths, forceCustomIntersections = false) => {
  const contours = pathsWithContour.filter(p => +p.attributes['data-contour'])
  if (!contours.length) return cutablePaths
  let processedPaths = [...cutablePaths]
  // отфильтровываем пути, которые полностью находятся за контуром
  processedPaths = processedPaths.filter(path => {
    const pointCount = pathUniqPoints(path).length
    return !every(contours, contour => {
      const contourD = fixWrongCommand(contour.attributes.d)
      const insideness = pathNotOutsideContourPositions(contourD, path).length
      const throwOut = (insideness < pointCount / 20) && !path.attributes['data-element']
      if (throwOut) {
        log('[THROW OUT] throwing out, because', pointCount - insideness, pointCount * 0.75)
        if (DEBUG) {
          visualizePath(path.attributes.d, displaySide, 'lightblue')
        }
      }
      return throwOut
    })
  })
  // console.timeEnd('[TIMER] Throwing out')
  // cut seams, intersecting with contour
  // console.time('[TIMER] Cutting')
  // режем то, что частично попадает в контур
  processedPaths = processedPaths.map(path => {
    let newPath = {...path}
    contours.forEach(contour => {
      // find intersection, then split path to parts and find intersection exactly
      // find if end or start is outside of path
      const contourD = fixWrongCommand(contour.attributes.d)
      const insideness = pathInsideContourPositions(contourD, newPath).length
      const pointCount = pathUniqPoints(newPath).length
      if (pointCount > insideness) {
        if (newPath.attributes['data-no-cut']) {
            // если нельзя резать, выкидываем полностью
            if (!newPath.attributes['data-keep']) {
              newPath.toRemove = true
            }
        } else {
          const direction = definePathDirection(newPath.attributes.d)
          if (direction < 0) {
            const reversedPath = reversePath(newPath.attributes.d)
            newPath = pathFromData(newPath, reversedPath.replace(/Z/ig, ''))
            log('path is reversed', newPath)
            newPath = cutSeamByIntersectionWithContour(newPath, contour, forceCustomIntersections)
          } else {
            newPath = cutSeamByIntersectionWithContour(newPath, contour, forceCustomIntersections)
          }
          log('path cut to', newPath.attributes.d)
        }
      }
    })
    return newPath
  })
  return processedPaths.filter(p => !p.toRemove)
}

/**
 * Cut seam by point of intersection with contour
 * @param {{attributes: {d: String}}} seam SVG.Path element
 * @param {{attributes: {d: String}}} contour SVG.Path element
 * @return {{attributes: {d: String}}}
 */
const cutSeamByIntersectionWithContour = (seam, contour, forceCustomIntersections = false) => {
  try {
		const logPoints = log
		const contD = fixWrongCommand(contour.attributes.d)
		const seamD = fixWrongCommand(seam.attributes.d)
		logPoints('seam: ', seamD)
		const contourShape = shape('path', {d: contD})
		const seamShape = shape('path', {d: seamD})
		const intersection = intersect(contourShape, seamShape)
		const pointsLib = intersection.points
		let points = pointsLib
		logPoints('poinsLib: ', pointsLib)
		if (!pointsLib.length || (typeof window === 'undefined' && pointsLib.length < 2)) {
			let pointsCustom = findPathIntersection(contour, seam)

			if (pointsCustom.length > 1) {
				pointsCustom = filterIntersectionPoints(pointsCustom, contour, seam)
			}
			logPoints('pointsCustom', pointsCustom)

			const contourShape1 = ShapeInfo.path(contD)
			const seamShape1 = ShapeInfo.path(seamD)
			const intersection1 = Intersection.intersect(contourShape1, seamShape1)
			const pointsLib1 = intersection1.points.length ? intersection1.points : []
			logPoints('pointsLib1', pointsLib1)
			if (forceCustomIntersections && pointsCustom.length >= pointsLib.length && pointsCustom.length >= pointsLib1.length) {
				points = pointsCustom
			} else {
				points = [pointsLib, pointsLib1, pointsCustom].map((el, i) => {
					el.index = i;
					return el
				}).sort((a, b) => b.length !== a.length ? b.length - a.length : a.i - b.i)[0]
			}
		}
		logPoints('points of intersection before clearing', points)
		log('points before filter: ', points)
    if (points.length > 1) {
      points = filterIntersectionPoints(points, contour, seam)
    }
		log('points after filter: ', points)
		logPoints('points of intersection after clearing', points)
    visualizePoints(points, displaySide, 'black', 'intersection')
    let processedSeam = pathFromData(seam, seam.attributes.d)
    if (points.length) {
      log('points to process', points)
      const notCutDirection = seam.attributes['data-not-cut-direction']
      if (points.length > 1 && notCutDirection) {
        console.log('[DEBUG] poins', points)
        if (['top', 'bottom'].includes(notCutDirection)) {
          const centerPoint = points.reduce((sum, p) => sum + p.y, 0) / points.length
          points = points.filter(p => {
            if (notCutDirection === 'bottom') {
              return p.y <= centerPoint
            } else if (notCutDirection === 'top') {
              return p.y > centerPoint
            }
          })
          console.log('[DEBUG] poins after filter', points)
        }
      }
      points.forEach(point => {
        const parts = cutPathByPoint(processedSeam, point)
        log('parts after split', parts)
        if (parts.length === 1) {
          processedSeam = pathFromData(processedSeam, parts[0].attributes.d)
        }
        if (parts.length === 2) {
          const [leftPart, rightPart] = parts
          const leftPartInsideness = pathInsideContourPositions(contour.attributes.d, leftPart)
          const rightPartInsideness = pathInsideContourPositions(contour.attributes.d, rightPart)
          log('inside points', leftPartInsideness, rightPartInsideness)
          if (rightPartInsideness.length < leftPartInsideness.length) {
            visualizePath(rightPart.attributes.d, displaySide, 'orange', 'thrown out RIGHT part')
            log('middle of left command inside, choosing', leftPart)
            processedSeam = pathFromData(processedSeam, leftPart.attributes.d)
          } else if (leftPartInsideness.length < rightPartInsideness.length) {
            visualizePath(leftPart.attributes.d, displaySide, 'brown', 'thrown out LEFT part')
            log('middle of right command inside, choosing', rightPart)
            processedSeam = pathFromData(processedSeam, rightPart.attributes.d)
          } else if (leftPartInsideness.length && rightPartInsideness.length) {
            log('we screwed', leftPartInsideness, rightPartInsideness)
            if (leftPartInsideness.length === 1 && rightPartInsideness.length === 1) {
              if (isPointOnPath(contour.attributes.d, leftPartInsideness[0]) && !isPointOnPath(contour.attributes.d, rightPartInsideness[0])) {
                processedSeam = pathFromData(processedSeam, rightPart.attributes.d)
              }
              if (!isPointOnPath(contour.attributes.d, leftPartInsideness[0]) && isPointOnPath(contour.attributes.d, rightPartInsideness[0])) {
                processedSeam = pathFromData(processedSeam, leftPart.attributes.d)
              }
            }
          }
        }
      })
    }
		log('after cut: ', processedSeam.attributes.d)
    return processedSeam
  } catch (e) {
		log(e)
    log('cut failed', e)
    return seam
  }
}

/**
 * Convert lineclip algorithm result to SVG Path commands
 * @param result
 * @returns {*}
 */
const lineclipResultToCommands = result => {
  return result.reduce((l, p) => [
    ...l,
    ...(p.length > 2
      ? p
      : p[0].length
        ? p
        : [p])
  ], [])
    .map((c, i) => ({
      x: c[0],
      y: c[1],
      code: i
        ? 'L'
        : 'M'
    }))
}

/**
 * Test bbox rect to throw out too narrow ones
 * @param {Array} rect
 * @return {boolean}
 */
const checkRect = rect => {
  return !matchPoints({
    x: rect[0],
    y: rect[1]
  }, {
    x: rect[2],
    y: rect[3]
  }) && Math.abs(rect[1] - rect[3]) > MATCH_DELTA
}

/**
 * Cut path by point on path
 * @param {{attributes: {d: String}}} path
 * @param {{x: Number, y: Number}} point
 * @returns {[{attributes: {d: String}}]}
 */
const cutPathByPoint = (path, point) => {
  const commands = makeAbsolute(parseSVG(path.attributes.d))

  const clearedPoints = commands.map(c => ([c.x, c.y]))
    .filter((command, pos, arr) => {
      const foundPos = arr.findIndex(c => c[0] === command[0] && c[1] === command[1])
      return foundPos + 1 !== pos
    })
  const [leftBbox, rightBbox] = slicedBounds(path, point)
  log('bboxes', leftBbox, rightBbox)
  visualizeRect(leftBbox, displaySide, 'yellow', 'left bbox')
  visualizeRect(rightBbox, displaySide, 'lightgreen', 'right bbox')
  let operationResult = []
  if (clearedPoints.length) {
    log('cleared points', clearedPoints)
    if (checkRect(leftBbox)) {
      const leftPart = lineclip(clearedPoints, leftBbox)
      log('leftBBox cut', leftBbox, leftPart)
      if (leftPart[0] && leftPart[0].length > 1) {
        operationResult.push(pathFromCommands(path, lineclipResultToCommands(leftPart)))
      }
    } else {
      log('leftBBox too narrow', leftBbox)
    }
    if (checkRect(rightBbox)) {
      const rightPart = lineclip(clearedPoints, rightBbox)
      log('rightBBox cut', rightBbox, rightPart)
      if (rightPart[0] && rightPart[0].length > 1) {
        operationResult.push(pathFromCommands(path, lineclipResultToCommands(rightPart)))
      }
    } else {
      log('rightBBox too narrow', rightBbox)
    }
    if (!operationResult.length) {
      log('linecut operation failed', leftBbox, rightBbox, clearedPoints, point)
      operationResult.push(path)
    }
  }
  if (!operationResult.length) {
    const intersectingCommandIndex = findIntersectingCommand(commands, point, 1)
    log('cutting intersection')
    if (intersectingCommandIndex > -1) {
      log('found command, performing cut by ', point)
      const sliced = splitCommandByPoint(commands[intersectingCommandIndex], point)
      log('performed cut, command', commands[intersectingCommandIndex], ' sliced to', sliced)
      return [
        pathFromCommands(path, fixCommands([
          ...commands.slice(0, intersectingCommandIndex),
          selectCommandFromInterval(commands[intersectingCommandIndex - 1], point, sliced)
        ], true)),
        pathFromCommands(path, fixCommands([
          selectCommandFromInterval(point, commands[intersectingCommandIndex], sliced),
          ...commands.slice(intersectingCommandIndex + 1)
        ], true))
      ]
    } else {
      log('did not find intersection', point)
      return [path]
    }
  } else {
    log('linecut operation result', path, operationResult)
    return operationResult
  }
}

/**
 * Get points of intersection of contour with seam
 * @param {{attributes: {d: String}}} contour
 * @param {{attributes: {d: String}}} seam
 * @param {Number} delta
 * @param {Number} step For step, default = 1
 * @returns {Array}
 */
const findPathIntersection = (contour, seam, delta = 1, step = 1) => {
  const contourProps = svgPathProperties(contour.attributes.d)
  const seamProps = svgPathProperties(seam.attributes.d)
  const points = []
  const contourPoints = []
  const seamPoints = []
  for (let i = 0; i < contourProps.getTotalLength(); i += step) {
    contourPoints.push(contourProps.getPointAtLength(i))
  }
  for (let j = 0; j < seamProps.getTotalLength(); j += step) {
    seamPoints.push(seamProps.getPointAtLength(j))
  }
  for (let i = 0; i < contourPoints.length; i++) {
    for (let j = 0; j < seamPoints.length; j++) {
      if (matchPoints(contourPoints[i], seamPoints[j], MATCH_DELTA)) {
        points.push(seamPoints[j])
      }
    }
  }
  return points
}

/**
 * Clear meaningless points of intersection (i.e. duplicates and placed right the contour)
 * @param {[{x: Number, y: Number}]} points
 * @param {
 *    {
 *      attributes: {d: String}
 *    }
 * } contour
 * @param {
 *    {
 *      attributes: {d: String}
 *    }
 * } seam
 * @returns {*}
 */
const filterIntersectionPoints = (points, contour, seam) => {
  const [start, end] = extractEdgePoints(seam.attributes.d)
  return points.filter(point => {
    return !matchPoints(start, point, 4) && !matchPoints(end, point, 4) && isPointOnPath(contour.attributes.d, point, 1)
  })
}

/**
 * Split polygon by intersecting path
 * @param {Object} contour SVG.Path element
 * @param {Object} contour.attributes Attributes of SVG.Path element
 * @param {String} contour.attributes.d SVG<Path> d attribute
 * @param {Object} seam SVG.Path element
 * @param {Object} seam.attributes Attributes of SVG.Path element
 * @param {String} seam.attributes.d SVG<Path> d attribute
 * @param {Number} delta Precision
 * @returns {*}
 */
const cutContourBySeam = (contour, seam, delta) => {
  const [start, end] = extractEdgePoints(seam.attributes.d)
  const commands = makeAbsolute(parseSVG(contour.attributes.d))
  const startMatchingCommand = findIntersectingCommand(commands, start, delta)
  const endMatchingCommand = findIntersectingCommand(commands, end, delta)
  let headTailPart = []
  let middlePart = []
  const minIndex = Math.min(startMatchingCommand, endMatchingCommand)
  const maxIndex = Math.max(startMatchingCommand, endMatchingCommand)
  const pointAtMin = (minIndex === startMatchingCommand
    ? start
    : end)
  const pointAtMax = (maxIndex === startMatchingCommand
    ? start
    : end)
  const commandAtMin = commands[minIndex]
  const commandAtMax = commands[maxIndex]
  if (commandAtMin && commandAtMax) {
    const slicedCommandAtMin = splitCommandByPoint(commandAtMin, pointAtMin)
    const slicedCommandAtMax = splitCommandByPoint(commandAtMax, pointAtMax)
    const headContourCommands = commands.slice(0, minIndex)
    const tailContourCommands = commands.slice(maxIndex + 1, last(commands).code === 'Z'
      ? -1
      : commands.length)
    if (tailContourCommands.length) {
      headContourCommands[0] = {
        code: 'M',
        x: last(tailContourCommands).x,
        y: last(tailContourCommands).y
      }
    }
    const headTailSeamCommands = correctSeam(pointAtMin, seam)
    const headSliceCommand = selectCommandFromInterval(last(headContourCommands), pointAtMin, slicedCommandAtMin)
    const firstPointAfterTailSlicedCommand = tailContourCommands.length
      ? startPointOfCommand(tailContourCommands[0])
      : (commands[maxIndex + 1])
        ? startPointOfCommand(commands[maxIndex + 1])
        : {
          x: 0,
          y: 0
        }
    const tailSliceCommand = selectCommandFromInterval(pointAtMax, firstPointAfterTailSlicedCommand, slicedCommandAtMax)
    if (headContourCommands.length) {
      headTailPart.push(...headContourCommands)
    }
    if (headSliceCommand) {
      headTailPart.push(headSliceCommand)
    }
    headTailPart.push(...headTailSeamCommands)
    if (tailSliceCommand) {
      headTailPart.push(tailSliceCommand)
    }
    if (tailContourCommands.length) {
      headTailPart.push(...tailContourCommands)
    }

    const middleContourCommands = commands.slice(minIndex + 1, maxIndex)
    const middleSeamCommands = correctSeam(pointAtMax, seam)
    const middleHeadSliceCommand = selectCommandFromInterval(pointAtMin, startPointOfCommand(middleContourCommands[0]), slicedCommandAtMin)
    const middleTailSliceCommand = selectCommandFromInterval(last(middleContourCommands), pointAtMax, slicedCommandAtMax)

    if (middleSeamCommands) {
      middlePart.push({code: 'M', ...pointAtMin})
    }
    if (middleHeadSliceCommand) {
      middlePart.push(middleHeadSliceCommand)
    }
    middlePart.push(...middleContourCommands)
    if (middleTailSliceCommand) {
      middlePart.push(middleTailSliceCommand)
    }
    middlePart.push(...middleSeamCommands)
    const headTailPath = pathFromCommands(contour, fixCommands(headTailPart))
    const middlePath = pathFromCommands(contour, fixCommands(middlePart))
    const lengthDiff = Math.abs((pathLength(headTailPath.attributes.d) + pathLength(middlePath.attributes.d)) - (pathLength(contour.attributes.d) + 2 * pathLength(seam.attributes.d)))
    if (lengthDiff > 10) {
      log(lengthDiff)
      return [contour]
    } else {
      return [headTailPath, middlePath]
    }
  } else {
    return [contour]
  }
}

/**
 * Starting point of SVG.Path command
 * @param command
 * @returns {{x: Number, y: Number}}
 */
export const startPointOfCommand = command => (command
  ? {
    x: command.x0,
    y: command.y0
  }
  : {
    x: 0,
    y: 0
  })

/**
 * Get last element of array
 * @param {Array} arr
 */
export const last = arr => arr[arr.length - 1]

/**
 * Get array of SVG.Path commands in reversed or normal way based on control point match with last known point
 * @param {Object} checkStartPoint Point to match edge of path
 * @param {Number} checkStartPoint.x
 * @param {Number} checkStartPoint.y
 * @param {Object} seam SVG.Path element
 * @param {Object} seam.attributes Attributes of SVG.Path element
 * @param {String} seam.attributes.d SVG<Path> d attribute
 * @param {Boolean} isStart Is given path edge the start of given path
 * @returns {Array} Command sequence
 */
const correctSeam = (checkStartPoint, seam, isStart = true) => {
  const [start, end] = extractEdgePoints(seam.attributes.d)
  let commands = []
  if (!matchPoints(isStart
      ? start
      : end, checkStartPoint, 1)) {
    commands = makeAbsolute(parseSVG(reversePath(seam.attributes.d)))
  } else {
    commands = makeAbsolute(parseSVG(seam.attributes.d))
  }
  if (last(commands).code === 'Z') {
    return commands.slice(0, -1)
  }
  return commands.filter(c => c.code !== 'M')
}

/**
 * Select correct part of sliced command
 * @param {Object} start Start of interval
 * @param {Number} start.x
 * @param {Number} start.y
 * @param {Object} end End of interval
 * @param {Number} end.x
 * @param {Number} end.y
 * @param {Array} sliced Slice command part
 * @param delta
 * @returns {Object} Matching part of command
 */
const selectCommandFromInterval = (start, end, sliced, delta = 4) => {
  const matchCommand = command => {
    return command && start && end && (matchPoints(startPointOfCommand(command), start, delta) &&
      matchPoints(command, end, delta))
  }
  const normal = sliced.find(matchCommand)
  if (!normal) {
    const reversedCommands = sliced.map(reverseCommand)
    const reversed = reversedCommands.find(matchCommand)
    if (reversed) {
      return reversed
    } else {
      return null
    }
  } else {
    return normal
  }
}

/**
 * Create SVG.Path d attribute from array of commands
 * @param command
 * @returns {string}
 */
const createPathFromCommand = command => render([
  {
    code: 'M',
    x: command.x0,
    y: command.y0
  }, command
])

/**
 * Reverse command
 * @param {{code: String, x0: Number, y0: Number}} command
 * @returns {{code: String, x0: Number, y0: Number}}
 */
const reverseCommand = command => {
  const code = command.code
  const newCommands = makeAbsolute(parseSVG(reversePath(createPathFromCommand(command))))
  return newCommands.find(c => c.code === code)
}

/**
 * Create new path element from old path and new path commands
 * @param {{attributes: {d: String}}} path SVG.Path element
 * @param {[{code: String, x0: Number, y0: Number}]} commands
 * @param {boolean} cutEnd
 * @returns {{attributes: {d: String}}}
 */
const pathFromCommands = (path, commands, cutEnd = false) => {
  return pathFromData(path, cutEnd
    ? cutPathEnd(render(commands))
    : render(commands))
}

/**
 * Remove M and Z commands from middle of path body
 * @param {Array} commands
 * @param {Boolean} endless
 * @returns {Array}
 */
export const fixCommands = (commands, endless = false) => {
  let fixed = []
  const firstCommand = commands[commands.length - 1]
  if (firstCommand.code !== 'M') {
    fixed.push({
      code: 'M',
      x: firstCommand.x0,
      y: firstCommand.y0
    })
  }
  fixed.push(...commands.map((command, i) => {
    if (i > 1 && command.code === 'M') {
      command.code = 'L'
    }
    return command
  }))
  if (!endless) {
    const lastCommand = commands[commands.length - 1]
    if (lastCommand.code !== 'Z') {
      fixed.push({
        code: 'Z',
        x: lastCommand.x,
        y: lastCommand.y
      })
    }
  }
  return fixed
}

/**
 * Define if path(seam) cuts through other path (contour) with two points of intersection
 * TODO: create workaround for seams, that close to, but yet not cutting contour
 * @param {Object} contour SVG.Path element
 * @param {Object} contour.attributes Attributes of SVG.Path element
 * @param {String} contour.attributes.d SVG<Path> d attribute
 * @param {Object} seam SVG.Path element
 * @param {Object} seam.attributes Attributes of SVG.Path element
 * @param {String} seam.attributes.d SVG<Path> d attribute
 * @param {Number} delta Precision
 * @returns {boolean}
 */
const isSeamCrossCutting = (contour, seam, delta) => {
  const [start, end] = extractEdgePoints(seam.attributes.d)

  return start && end
    ? isPointOnPath(contour.attributes.d, start, delta) && isPointOnPath(contour.attributes.d, end, delta)
    : false
}

/**
 * Deprecated function to define path orientation
 * @param {string} path Svg path d attribute
 * @returns {number}
 */
export const definePathDirection = (path) => {
  const props = svgPathProperties(path)
  const length = props.getTotalLength()
  const pt14 = props.getPointAtLength(1 / 4 * length)
  const pt34 = props.getPointAtLength(3 / 4 * length)
  return Math.atan2(pt14.y - pt34.y, pt14.x - pt34.x)
}

/**
 * Create new path from old path and new d attribute string
 * @param {Object} path SVG.Path element
 * @param {Object} path.attributes Attributes of SVG.Path element
 * @param {String} path.attributes.d SVG<Path> d attribute
 * @param {String} d
 * @returns {{attributes: {d: String}}}
 */
const pathFromData = (path, d) => {
  const clone = JSON.parse(JSON.stringify(path))
  return {
    ...clone,
    attributes: {
      ...clone.attributes,
      d
    }
  }
}

/**
 * Recursive algorithm, splitting given contours with given seams
 * @param {Array} contours
 * @param {Array} paths
 * @param {Number} delta Precision
 * @returns {Array}
 */
const splitContours = (contours, paths, delta) => {
  splitContoursCalls++
  let seams = paths
  let nextContours = []
  let removedSeams = []
  contours.forEach(contour => {
    const seamIndex = seams.findIndex(path => isSeamCrossCutting(contour, path, delta))
    if (seamIndex > -1) {
      const slicedContours = cutContourBySeam(contour, seams[seamIndex], delta)
      if (slicedContours.length === 2) {
        removedSeams.push(seams[seamIndex])
      }
      seams = [...seams.slice(0, seamIndex), ...seams.slice(seamIndex + 1)]
      nextContours.push(...slicedContours)
    } else {
      nextContours.push(contour)
    }
  })
  if (splitContoursCalls < 10 && seams.length && removedSeams.length && some(seams, seam => some(contours, contour => isSeamCrossCutting(contour, seam, delta)))) {
    const [nextStepContours, nextStepRemoved] = splitContours(nextContours, seams, delta)
    nextContours = nextStepContours
    removedSeams.push(...nextStepRemoved)
  }
  return [nextContours, removedSeams]
}

/**
 * Remove duplicating paths
 * @param {[{attributes: {d: String}}]} paths Paths to be injected (such as neckline)
 */
const uniqPaths = paths => {
  return paths.filter((path, pos, arr) => arr.findIndex(c => c.attributes.d === path.attributes.d) === pos)
}

/**
 * Change incoming paths to seams and areas, that might be painted
 * @param {[{attributes: {d: String}}]} paths Original path from database
 * @param {[{attributes: {d: String}}]} dependencies Paths to be injected (such as neckline)
 * @returns {Array}
 * @throws Error
 */
export const injectDependencyAndCutSeams = (paths, dependencies, params = {}) => {
  const initialPaths = paths.filter(isPathShouldBeLeft)
    .filter(path => !isPathSelfClosed(path.attributes.d))
    .map(formatPathObject)
    .filter(path => path.attributes.d)
  let skippedPaths = uniqPaths([
    ...paths.filter(isPathShouldBeSkipped),
    ...dependencies.filter(isPathShouldBeSkipped)
  ])
  let selfClosingPaths = [
    ...paths.filter(isPathShouldBeCutOnly),
    ...dependencies.filter(isPathShouldBeCutOnly)
  ]

  let final
  if (!dependencies.length) {
    throw new Error('No paths to insert')
  }
  if (!initialPaths.length) {
    throw new Error('No paths to insert to')
  }
  let processingPaths = [
    ...initialPaths,
    ...dependencies
      .filter(isPathShouldBeLeft)
      .filter(path => !isPathSelfClosed(path.attributes.d))
      .map(p => Object.assign({}, formatPathObject(p), {isDependency: true}))
      .filter(path => path.attributes.d)
  ]

  log('[contour] processing paths for contour', processingPaths)
  const possibleContours = findContour(processingPaths, MATCH_DELTA * 4, {skipFiltering: params.skipFiltering})  
  if (!possibleContours.length) {
    log([...dependencies].map(e => e.attributes))
    throw new Error('No contours generated')
  }
  const separatedPaths = {
    contours: [],
    ordinary: [...processingPaths, ...selfClosingPaths]
  }
  possibleContours.forEach(possibleContour => {
    if (possibleContour && possibleContour.length) {
      const finalContour = mergeContourPaths(possibleContour)
      separatedPaths.contours.push(finalContour)
      separatedPaths.ordinary = separatedPaths.ordinary.filter(o => !o.uuid || !finalContour.mergedUuids.includes(o.uuid))
    }
  })
  if (separatedPaths.contours.length) {
    /**
     * @var {{attributes: {d: String}}} path Path object
     */
    separatedPaths.ordinary = cutPathsToFitContour(separatedPaths.contours, uniqPaths(separatedPaths.ordinary))
  }
  final = [
    ...separatedPaths.contours,
    ...separatedPaths.ordinary
  ]
  final.push(...skippedPaths)
  return final
}

export const injectDependencyAndCutSeamsMemoized = DEBUG
  ? injectDependencyAndCutSeams
  : memoize(injectDependencyAndCutSeams, (paths, dependencies) => {
    return paths.map(p => p.attributes.d).sort().join('') + dependencies.map(p => p.attributes.d).sort().join('')
  })

/**
 * Fix processed picture by cutting overlapping seams
 * @param paths
 * @returns {*[]|*}
 */
export const fixPicture = paths => {
  const contour = paths.filter(path => path.attributes.stroke === '#000000').sort((a, b) => {
    const aLength = svgPathProperties(a.attributes.d)
      .getTotalLength()
    const bLength = svgPathProperties(b.attributes.d)
      .getTotalLength()
    return bLength - aLength
  })[0]
  if (contour) {
    let newPaths = paths.filter(p => p.attributes.d !== contour.attributes.d)
    // cut seams, intersecting with contour
    newPaths = newPaths.map(path => {
      /**
       * @var {{attributes: {d: String}}} newPath Path object
       */
      let newPath = {...path}
      const direction = definePathDirection(newPath.attributes.d)
      if (direction < 0) {
        const reversedPath = reversePath(newPath.attributes.d)
        newPath = pathFromData(newPath, reversedPath.replace(/Z/ig, ''))
        newPath = cutSeamByIntersectionWithContour(newPath, contour)
      } else {
        newPath = cutSeamByIntersectionWithContour(newPath, contour)
      }
      return newPath
    })

    return [contour, ...newPaths]
  }
  return paths
}

/**
 * Check if path is closed
 * @param {String} d
 * @returns {boolean}
 */
export const isPathClosed = d => {
  const [start, end] = extractEdgePoints(d)
  const totalLength = svgPathProperties(d)
    .getTotalLength()
  return !!(d.match(/(z|\s+?z)$/i) ||
    matchPoints(start, end, MATCH_DELTA) ||
    matchPoints(start, end, MATCH_DELTA * 4)) && totalLength > 10
}

/**
 * Check if path is closed and big enough
 * @param d
 * @returns {boolean}
 */
export const isPathSelfClosed = d => {
  const [start, end] = extractEdgePoints(d)
  const box = findBbox(d)
  return (d.match(/(z|\s+?z)$/i) && (box.width > MATCH_DELTA / 2 || box.height > MATCH_DELTA / 2)) ||
    matchPoints(start, end, MATCH_DELTA) ||
    matchPoints(start, end, MATCH_DELTA * 4)
}

export const combineThumbAndDress = (thumb, dress, closure = null, isSarafan = false, forceCustomIntersections = false) => {
	const log = msg => null
	let dressCont = dress.find(p => p.attributes.isMain)
	let dressPath = dressCont.attributes.d
	let points = extractEdgePoints(dressPath)
	log('dress edge points: ')
	log(points)
	let dressDirection = points[0].x > points[1].x ? 'left' : 'right'
	log('dress direction:')
	log(dressDirection)
	// если есть этот элемент, thumb представляет собой замкнутый контур, в котором часть надо заменить контуром платья
	let bodice = thumb.find(p => p.attributes.id && p.attributes.id.toLowerCase().indexOf('bodice') > -1)
	if (bodice && !isSarafan) {
		let bodiceEdges = extractEdgePoints(bodice.attributes.d)
		log('bodice edge points: ')
		log(bodiceEdges)
		let bodiceDirection = bodiceEdges[0].x > bodiceEdges[1].x ? 'left' : 'right'
		log('bodiceDirection: ')
		log(bodiceDirection)
		if (dressDirection !== bodiceDirection) {
			dressPath = reversePath(dressCont.attributes.d).replace('Z', '').trim()
			log('dress reversed')
		}
		let dressEdges = points.sort((a, b) => a.x - b.x)
		let commands = makeAbsolute(parseSVG(bodice.attributes.d));

		// определение точек пересечения контура с платьем с погрешностью до 1
		let leftPoint = dressEdges[0]
		let leftCommandIndex = findIntersectingCommand(commands, leftPoint, 1)
		let leftCommand = commands[leftCommandIndex]
		log('leftCommand')
		log(leftCommand)

		let distanceToStart = Math.sqrt(Math.pow(leftPoint.x - leftCommand.x0, 2) + Math.pow(leftPoint.y - leftCommand.y0, 2))
		let distanceToEnd = Math.sqrt(Math.pow(leftPoint.x - leftCommand.x, 2) + Math.pow(leftPoint.y - leftCommand.y, 2))

		let leftCommandDirection = leftCommand.y < leftCommand.y0 ? 'up' : 'down'
		log('left command direction:')
		log(leftCommandDirection)

		let distanceToTop = leftCommandDirection === 'up' ? distanceToEnd : distanceToStart
		let distanceToBottom = leftCommandDirection === 'up' ? distanceToStart : distanceToEnd
		log('distance to top: ')
		log(distanceToTop)
		log('distance to bottom:')
		log(distanceToBottom)
		// если точка пересечения находится ближе к верху, чем к низу данной линии, то линия не нужна, справа аналогично
		if (distanceToTop < distanceToBottom) {
			log('cutting left command')
			if (leftCommandDirection === 'up') {
				if (commands[leftCommandIndex + 1]) {
					leftCommandIndex += 1
				}
			} else {
				if (commands[leftCommandIndex - 1]) {
					leftCommandIndex -= 1
				}
			}
			leftCommand = commands[leftCommandIndex]
		}
		let rightPoint = dressEdges[1]
		let rightCommandIndex = findIntersectingCommand(commands, rightPoint, 1)
		let rightCommand = commands[rightCommandIndex]

		log('right command: ')
		log(rightCommand)

		distanceToStart = Math.sqrt(Math.pow(rightPoint.x - rightCommand.x0, 2) + Math.pow(rightPoint.y - rightCommand.y0, 2))
		distanceToEnd = Math.sqrt(Math.pow(rightPoint.x - rightCommand.x, 2) + Math.pow(rightPoint.y - rightCommand.y, 2))

		let rightCommandDirection = rightCommand.y < rightCommand.y0 ? 'up' : 'down'
		log('right command direction: ')
		log(rightCommandDirection)
		distanceToTop = rightCommandDirection === 'up' ? distanceToEnd : distanceToStart
		distanceToBottom = rightCommandDirection === 'up' ? distanceToStart : distanceToEnd

		log('distance to top: ')
		log(distanceToTop)
		log('distance to bottom: ')
		log(distanceToBottom)

		if (distanceToTop < distanceToBottom) {
			log('cutting right command')
			if (rightCommandDirection === 'up') {
				if (commands[rightCommandIndex + 1]) {
					rightCommandIndex += 1
				}
			} else {
				if (commands[rightCommandIndex - 1]) {
					rightCommandIndex -= 1
				}
			}
			rightCommand = commands[rightCommandIndex]
		}
		// определяем контур платья для вставки
		let dressCommands = makeAbsolute(parseSVG(dressPath))

		// М команда в платье не нужна, потому что все команды абсолютные
		if (dressCommands[0].command === 'moveto') {
			dressCommands.shift()
		}
		// меняем координаты граничных точек платья, чтобы контуры точно соответствовали. Это приведет к незначительному искривлению платья. Так нельзя сделать с контуром вариации, потому что тогда линии швов сместятся и ассиметрия будет бросаться в глаза

		let startCommand = bodiceDirection === 'right' ? leftCommand : rightCommand
		let endCommand = bodiceDirection === 'right' ? rightCommand : leftCommand

		dressCommands[0].x0 = startCommand.x
		dressCommands[0].y0 = startCommand.y

		dressCommands[dressCommands.length - 1].x = endCommand.x0
		dressCommands[dressCommands.length - 1].y = endCommand.y0

		let startCommands, endCommands
		if (bodiceDirection === 'left') {
			startCommands = commands.slice(0, rightCommandIndex + 1)
			endCommands = commands.slice(leftCommandIndex)
		} else {
			startCommands = commands.slice(0, leftCommandIndex + 1)
			endCommands = commands.slice(rightCommandIndex)
		}
		// горловина должна образовывать единый контур с остальным силуэтом (т.е. один аттрибут path), иначе функция обрезки швов не работает и может отрезать не с той стороны горловины
		let neckline = thumb.find(p => !p.attributes.isMain)
		let necklineCommands = []
		if (neckline) {
			let necklinePath = neckline.attributes.d
			let necklineEdges = extractEdgePoints(necklinePath)
			log('neckline edges')
			log(necklineEdges)
			let necklineDirection = necklineEdges[0].x < necklineEdges[1].x ? 'right' : 'left'
			if (necklineDirection === bodiceDirection) {
				log('reversing neckline')
				necklinePath = reversePath(necklinePath)
			}
			necklineCommands = makeAbsolute(parseSVG(necklinePath))
		}
		let resultCommands = [...necklineCommands, ...startCommands, ...dressCommands, ...endCommands]
		bodice.attributes.d = render(resultCommands)

		// теперь горловина - часть контура и ее можно убрать
		thumb.splice(thumb.indexOf(neckline), 1)

		// атрибут нужен для ф-ии обрезки швов
		bodice.attributes['data-contour'] = 1

		// при наличия closure центральный шов убирается
		if (closure) {
      let frontSeam = thumb.find(el => el.attributes.id && el.attributes.id.replace(/_x5F_/g, '_').toLowerCase().indexOf('center_front_seam') > -1)
			if (frontSeam) {
				thumb.splice(thumb.indexOf(frontSeam), 1)
			}
		}
		if (closure) {
			thumb = [...thumb, ...closure]
		}
		let toCut = thumb.filter(p => p !== bodice)
		return [bodice, ...cutPathsToFitContour([bodice], toCut, forceCustomIntersections)]
	} else {
		dressCont.attributes['data-fill'] = 'none'
		let result
		if (isSarafan) {
			let contToCut = thumb.find(p => p.attributes.class.toLowerCase().indexOf('st2') > -1)
			if (contToCut) {
				thumb.splice(thumb.indexOf(contToCut), 1)
			}
			let neckline = thumb.find(p => !p.attributes.isMain)
			if (neckline) {
				let dressCommands = makeAbsolute(parseSVG(dressCont.attributes.d))
				log('dress commands')
				log(dressCommands)
				let necklinePath = neckline.attributes.d
				let necklineEdges = extractEdgePoints(necklinePath)
				let necklineDirection = necklineEdges[0].x < necklineEdges[1].x ? 'right' : 'left'
				if (necklineDirection === dressDirection) {
					necklinePath = reversePath(necklinePath)
				}
				let necklineCommands = makeAbsolute(parseSVG(necklinePath))
				if (necklineCommands[0].command === 'moveto') {
					necklineCommands.shift()
				}
				dressCont.attributes.d = render([...dressCommands, ...necklineCommands])
				log('dress combined with neckline: ')
				log(dressCont.attributes.d)
				thumb.splice(thumb.indexOf(neckline), 1)
			}
			if (closure) {
				thumb = [...thumb, ...closure]
			}
			log('dress countour')
			log(dressCont)
			result = [...[dressCont], ...cutPathsToFitContour([dressCont], thumb, forceCustomIntersections)]
			log('result: ')
			log(result)
		} else {
			result = [...thumb, ...[dressCont]]
			if (closure) {
				result = [...result, ...cutPathsToFitContour(result, closure, forceCustomIntersections)]
			}
		}
		return result
	}
}

export const renderPaths = (elements, stripGrayLines, onlyElements = false) => {
  const paths = elements.reduce((content, element) => {
    const stroke = element.attributes.stroke || (element.attributes.isMain && '#020202')
    return content + (!stripGrayLines || (stripGrayLines && (element.attributes.isMain || +element.attributes['data-keep']))
      ? '<path d="' +
      element.attributes.d + '"' +
      (element.attributes.id
        ? ' id="' + element.attributes.id + '"'
        : '') +
      (element.attributes['data-element']
        ? ' data-element="' + element.attributes['data-element'] + '"'
        : '') +
      (element.attributes['stroke-dasharray']
        ? ' stroke-dasharray="' + element.attributes['stroke-dasharray'] + '"'
        : '') +
      (element.attributes['stroke-width']
        ? ' stroke-width="' + element.attributes['stroke-width'] + '"'
        : '') +
      (element.attributes['data-contour']
        ? ' data-contour="' + element.attributes['data-contour'] + '"'
        : '') +
      (element.attributes['data-source-ids']
        ? ' data-source-ids="' + element.attributes['data-source-ids'] + '"'
        : '') +
      (+element.attributes['data-keep']
        ? ' data-keep="' + (element.attributes['data-keep'] ? 1 : 0) + '"'
        : '') +
      (element.attributes['data-fillable']
        ? ' data-fillable="' + element.attributes['data-fillable'] + '"'
        : '') +
      (element.attributes['data-ignore-back']
        ? ' data-ignore-back="' + element.attributes['data-ignore-back'] + '"'
        : '') +
      (element.attributes['data-thin']
        ? ' data-thin="' + element.attributes['data-thin'] + '"'
        : '') +
      (!element.attributes['data-fillable'] && !element.attributes['data-thin'] && !element.attributes['data-fill']
        ? ' fill="white" stroke="' + stroke + '"'
        : '') +
      (element.attributes['data-fill']
        ? ' fill="' + element.attributes['data-fill'] + '" stroke="' + stroke + '"'
        : '') +
      ' data-main="' + (element.attributes.isMain
        ? '1'
        : '0') + '"/>'
      : '')
  }, '')
  return onlyElements
    ? paths
    : `<svg>${paths}</svg>`
}