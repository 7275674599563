<template>
  <div class="pagination-wrapper">
    <div class="pagination-panel" v-if="total > perPage">
      <button
        :class="['tick', 'prev', page === 1 ? 'inactive' : '']"
        :disabled="page === 1"
        @click="setPage(page - 1)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 25 24">
          <path d="M14.5 7.25a.75.75 0 0 0-.531.219l-4 4a.75.75 0 0 0 0 1.062l4 4a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.062L11.562 12l3.47-3.469a.75.75 0 0 0 0-1.062.75.75 0 0 0-.532-.219Z" color="#000" style="-inkscape-stroke:none"/>
        </svg>
      </button>
      <template v-if="totalPages < 10">
        <button
          v-for="num in totalPages"
          :key="num"
          :class="['page-num', num === page ? 'active': '']"
          @click="setPage(num)"
        >
          {{ num }}
        </button>
      </template>
      <template v-else>
        <template v-for="(num, i) in pagesArray">
          <button
            v-if="num !== '...'"
            :key="num"
            :class="['page-num', num === page ? 'active': '']"
            @click="setPage(num)"
          >
            {{ num }}
          </button>
          <div v-if="num === '...'" :key="`${num}-${i}`" class="pages-placeholder">...</div>
        </template>
      </template>
      <button
        :class="['tick', 'next', page === totalPages ? 'inactive' : '']"
        :disabled="page === totalPages"
        @click="setPage(page + 1)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 25 24">
          <path d="M10.5 7.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L13.438 12l-3.47 3.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.063 0l4-4a.75.75 0 0 0 0-1.062l-4-4a.75.75 0 0 0-.531-.219Z" color="#000" style="-inkscape-stroke:none"/>
        </svg>
      </button>
    </div>
    <div class="pagination-panel-mobile" v-if="total > perPage">
      <ButtonSecondary
        :disabled="page === 1"
        class="small"
        @click.native="setPage(page - 1)"
      >
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 25 24">
            <path d="M14.5 7.25a.75.75 0 0 0-.531.219l-4 4a.75.75 0 0 0 0 1.062l4 4a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.062L11.562 12l3.47-3.469a.75.75 0 0 0 0-1.062.75.75 0 0 0-.532-.219Z" color="#000" style="-inkscape-stroke:none"/>
          </svg>
        </template>
      </ButtonSecondary>
      <select v-if="totalPages > 2" @change="setPage($event.target.value)">
        <option
          v-for="pageNum in totalPages"
          :key="`m-${pageNum}`"
          :value="pageNum"
          :selected="pageNum === page"
        >
          {{ pageNum }}
        </option>
      </select>
      <ButtonSecondary
        :disabled="page === totalPages"
        class="small"
        @click.native="setPage(page + 1)"
      >
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="none" viewBox="0 0 25 24">
            <path d="M10.5 7.25a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062L13.438 12l-3.47 3.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.063 0l4-4a.75.75 0 0 0 0-1.062l-4-4a.75.75 0 0 0-.531-.219Z" color="#000" style="-inkscape-stroke:none"/>
          </svg>
        </template>
      </ButtonSecondary>
    </div>
  </div>
</template>

<script>
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

const maxVisiblePages = 7

export default {
  name: 'PaginationComponent',
  components: {
    ButtonSecondary,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  watch: {
    totalPages (v) {
      if (this.page > v) this.setPage(v)
    },
  },
  computed: {
    totalPages () {
      return Math.ceil(this.total / this.perPage)
    },
    pagesArray () {
      const pages = []
      pages.push(1)

      if (this.page > Math.floor(maxVisiblePages / 2) + 1) {
        pages.push('...')
      }

      let start = Math.max(2, this.page - Math.floor(maxVisiblePages / 2))
      let end = Math.min(this.page + Math.floor(maxVisiblePages / 2), this.totalPages - 1)

      if (end - start < maxVisiblePages - 2) {
        if (this.page < this.totalPages / 2) {
          end = Math.min(start + maxVisiblePages - 3, this.totalPages - 1)
        } else {
          start = Math.max(end - (maxVisiblePages - 3), 2)
        }
      }

      for (let i = start; i <= end; i++) {
        pages.push(i)
      }

      if (end < this.totalPages - 1) {
        pages.push('...')
      }

      pages.push(this.totalPages)
      return pages
    },
  },
  methods: {
    setPage (pageNum) {
      this.$emit('setPage', parseInt(pageNum))
    },
  },
}
</script>

<style lang="scss">
.pagination-panel {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  .tick {
    border: none;
    background: transparent;
    width: 24px;
    height: 24px;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      fill: var(--Icons-Icon_Primary);
    }
    &.inactive {
      svg {
        fill: var(--Button-States-Disabled_Txt);
      }
    }
  }
  .page-num, .pages-placeholder {
    width: 40px;
    height: 36px;
    border-radius: 40px;
    background-color: transparent;
    border: none;
    text-align: center;
    line-height: 36px;

    color: var(--text_color);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    transition: all ease-in-out 300ms;
  }

  .page-num:hover, .page-num.active {
    background: var(--Active-States-Surfaces);
    color: var(--accent);
  }
}

.pagination-panel-mobile {
  display: none;
}

@media (max-width: 760px) {
  .pagination-panel {
    display: none;
  }
  .pagination-panel-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    button {
      width: 40px;
      padding: 0 !important;
    }

    select {
      width: 104px;
      height: 40px;
      padding: 10px 12px;
      padding-right: 32px;
      border-radius: 8px;
      border: 1px solid var(--Dividers);
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3334 8.33325L10.0001 11.6666L6.66675 8.33325' stroke='%231D1F29' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-position: calc(100% - 12px) center;
      background-repeat: no-repeat;
      background-color: var(--Background);

      color: var(--Text-Text_Primary);
      text-align: center;

      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      appearance: none;
    }
  }
}
</style>
