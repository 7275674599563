import './quasar'
import '@/styles/app.scss'
import Vue from 'vue'
import App from '@/App'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import { store } from '@/store'
import { createPinia, PiniaVuePlugin } from 'pinia'
import PortalVue from 'portal-vue'
import VueHead from 'vue-head'
import { router } from '@/router'
import VueMeta from 'vue-meta'

import VueTelInput from 'vue-tel-input'
import vClickOutside from 'v-click-outside'

import 'vue-tel-input/dist/vue-tel-input.css'

Vue.use(vClickOutside)
Vue.use(VueHead)
Vue.use(PortalVue)
Vue.use(Vuex)
Vue.use(PiniaVuePlugin)
Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(VueTelInput, {
  mode: 'international',
  dropdownOptions: { showSearchBox: true },
  validCharactersOnly: true,
  inputOptions: { showDialCode: true },
})

Vue.config.productionTip = false
const pinia = createPinia()

new Vue({
  store,
  pinia,
  router,
  render: h => h(App),
}).$mount('#app')
