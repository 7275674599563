import Bezier from 'bezier-js'
import {matchPoints} from './point'
import {isPointOnPath} from './index'
import {log} from '../utils/logger'
import render from './renderCommands'

/**
 * Get start and end points of command
 * @param {{x0:Number, y0: Number, x:Number, y: Number}} command
 * @return {[{x: Number, y:Number}, {x: Number, y:Number}]}
 */
export const extractCommandEdgePoints = command => {
  return [{x: command.x0, y: command.y0}, {x: command.x, y: command.y}]
}
/**
 * Split given command by intersection point
 * TODO: add quadratic bezier curve splitting
 *
 * @param {Object} command
 * @param {String} command.code
 * @param {Number} command.x0
 * @param {Number} command.y0
 * @param {Number} command.x
 * @param {Number} command.y
 * @param {Object} point
 * @param {Number} point.x
 * @param {Number} point.y
 * @return {[*,*]}
 */
export const splitCommandByPoint = (command, point) => {
  let left = {...command}
  let right = {...command}
  switch (command.code) {
    case 'L':
    case 'Z':
    case 'H':
    case 'V':
    case 'M':
      left.x = point.x
      left.y = point.y
      right.x0 = point.x
      right.y0 = point.y
      break
    case 'C':
      const curve = new Bezier(command.x0, command.y0, command.x1, command.y1, command.x2, command.y2, command.x, command.y)

      const curveParts = splitCurve(curve, point)
      if (curveParts) {
        if (curveParts.left) {
          left.x0 = curveParts.left.points[0].x
          left.y0 = curveParts.left.points[0].y
          left.x1 = curveParts.left.points[1].x
          left.y1 = curveParts.left.points[1].y
          left.x2 = curveParts.left.points[2].x
          left.y2 = curveParts.left.points[2].y
          left.x = curveParts.left.points[3].x
          left.y = curveParts.left.points[3].y
        }
        if (curveParts.right) {
          right.x0 = curveParts.right.points[0].x
          right.y0 = curveParts.right.points[0].y
          right.x1 = curveParts.right.points[1].x
          right.y1 = curveParts.right.points[1].y
          right.x2 = curveParts.right.points[2].x
          right.y2 = curveParts.right.points[2].y
          right.x = curveParts.right.points[3].x
          right.y = curveParts.right.points[3].y
        }
      } else {
        log('splitting failed')
      }
      break
  }
  return [left, right]
}

const splitCurve = (curve, point) => {
  const length = curve.length()
  const partCount = length > 1 ? 1000 * length : 1000
  let t = null
  for (let i = 1; i < partCount; i++) {
    if (matchPoints(curve.get(i / partCount), point)) {
      t = i / partCount
      break
    }
  }
  if (t) {
    return curve.split(t)
  }
  return null
}

export const findIntersectingCommand = (commands, point, delta) => {
  return commands.findIndex((command) => {
    const commandPath = render([{code: 'M', x: command.x0, y: command.y0}, command])
    return !!isPointOnPath(commandPath, point, delta)
  })
}

/**
 * Fix duplicated for some reason H or V commands
 * @param {String} path
 * @returns {String}
 */
export const fixWrongCommand = (path) => (path || '')
  .replace(/H\s?(\d+\.\d+)\s(\d+\.\d+)\s/g, 'H $1 ')
  .replace(/V\s?(\d+\.\d+)\s(\d+\.\d+)\s/g, 'V $1 ')
