import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import { serializeFilters } from '@/helpers'
import qs from 'qs'
import { store } from '@/store'
// import { settingCodesChange, fitChange, fabricTypeChange } from './helpers/settingCodes'
const location = window?.location?.origin + '/designer'

const cache = setupCache({
  maxAge: ((
    'VUE_APP_HTTP_CACHE_LIFETIME' in process.env &&
    !Number.isNaN(Number(process.env?.VUE_APP_HTTP_CACHE_LIFETIME))
  ) ? Number(process.env?.VUE_APP_HTTP_CACHE_LIFETIME) : 24 * 60 * 60) * 1000,
  invalidate: async (cfg, req) => {
    const method = req.method.toLowerCase()
    if (method !== 'get') {
      await cfg.store.removeItem(cfg.uuid)
    }
  },
  exclude: {
    query: false,
  },
  // debug: process.env.NODE_ENV !== 'production',
  // todo: пока в заголовках нет информации о жизни кеша
  // readHeaders: true,
})

// без этого метода svg данные не передаются корректно
const jsonToPostTransformer = function (data) {
  if (typeof data === 'object') {
    const params = new URLSearchParams()
    Object.keys(data).forEach(key => {
      params.append(key, data[key])
    })
    return params
  }
  return data
}

let cancelTokens = {}

let baseURL = process.env.VUE_APP_BASE_URL.startsWith('/')
  ? `${window.location.protocol}//${window.location.hostname}${process.env.VUE_APP_BASE_URL}`
  : process.env.VUE_APP_BASE_URL

const selfURL = `${window.location.protocol}//${window.location.hostname}`

const _mtoken = '_mtoken=0R9sptvHHUv5RaN6rHUmHIQU82jKP2xGGDIULJJ4JsZBxWryJdC2i8uqgm4jxqYgAt8X9q1sBKkz%2BfnrDir5XHDw%2FC3Wc7IGspC3fk5a4ETq%2FjvdaLBq%2F4oj5fwMdQU6dm%2FZXz5u950zkNpbzp6JrZd%2BC448kTZMciIXsJ6slXN0beHhty8dGNGbwId1ZDVBdKtadblBpDv2%2BinT5f9NP3o%2B3tdmEk9WzM5op09xapkiEvRk87GJNx7qsAnvULich6hqNtqcacr4SEBSATyqXccx0%2Fx3YcDrvciE8DHtwIsq8%2F2or6pnz1qeAPOrlims0oNV96H1amrqI7TfS5P5RYq%2F59e3JGN0KMO5lqEUGrwX%2BApuOqmPCF8NGPV7Ermgd1PikVwHBtirwn9dlXc6wrMNHEubX69s71AVLt6cQMI4HGLe1e9dWCnm3FNuwFj9T%2Bf6mL%2BA%2BuKlPKilg12SeqJouDHIVlF9b5IekCNOt3Lxl5zY559rpCyQ3Ei09va63n1%2BnW0R4LvX61eHCprlxdXbFl8l8d66CZ2vW76bmkBkCC8PaviyRK3ZRYo3puHNpJPgBrt4KrW8Jae08j9KOVa2LTgli991fhqyunWvWvEOD%2BK97uPfKJEByVpx3cI1n6l6DZ4eKDUk5cUHiULzf%2FuxGQEZNsqcUcd8fey6a4tSHBPARcQCioQMPKm%2FC482ydIEPfJIC0pA1L7DvA3wiYdChCLCgbIPHAAix19wV%2B5dpSO%2Fahoc4fweBmnORd1pfrJXyO2wQN2Ca7On%2FNMnpjRf%2FqrtRSZS9cvlTeSZAQGJno%2Fc1mWPglNIJrkPLZR2SYqX2xLIIc%2BrXbev4ePeqCpQLioJHn0TX54eOPUXZauJzraTbBRJYmabOzouZST50DLj2uGvheTA2mFI3IYapE8BXnyP0ErLFrOg60Sf5K4qxkeGAZbg6D%2FjpLzV5BuQz9Eoj2HY1pJ6%2FyoFmTq0aicjrs2Dl%2BJ018FyY%2FeQNAKIcU8FJ2jXeRZB1trXSkGAEK6OE%2FrIGCbJJZdM0yqIqaxMSrHJsOEsMrWofeNg74cC0vTV7TT64YWUgHG3Tprr2bzJGq%2BC0nb5k7ZUbOMty9GoX2vv25N7b%2F0Qbz9U1SOoEw4A0R25wzKAhyjONqxfWSQMx1okmP9OQXUF139RkeXWg4yaqIVeIqKEr2V5q4xwjGq9%2BwXmNUcrysBojd5qbyq%2BKIMnC8uqDQ5Txfbef7Yjw6E%2Fqj3i0EdSMtKs79BFncUZuk%2FCIg5uxf8fK2qqyoOmnOqbLsPg9UW%2ByrDDYFLMsGhP2Ysw9dOkxv4L73ViFrm6mbJQg%2FJtF0YTecp92OAQqQw%2F6ntWBbf7R4k2pxJsRwADe6Ed3vgA9HiMYn5p4RIFjfoQxGj6bOaG0vzHkVA2XHSbLpi05NcVQbHl0sN%2BO0aWYJfZo6dZDfa97F1W%2FOEo%2BwGEa09wcKudwrbIvE2IB4LRv3LHk4CIx1E0MaeBP31m26KbZSEmLGi3tWCoabVkUqt48V5DhZSBDX24iiC6z08JpnJm2y8w%2Fb%2B5BOuLCQLP'
const getCookie = (name, defaultValue = '') => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  } else {
    return defaultValue
  }
}

let baseInstance = axios.create({
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  adapter: localStorage?.getItem?.('token') ? null : cache.adapter,
  transformRequest: [
    jsonToPostTransformer,
    ...axios.defaults.transformRequest,
  ],
})
baseInstance.interceptors.request.use(
  (config) => {
    let passCookies = [
      '_mhash=' + localStorage?.getItem?.('token'),
      _mtoken,
      'draft=' + getCookie('draft', 0),
      'cat_env=' + getCookie('cat_env', 'prod'),
    ]
    config.headers["Authorization"] = 'Bearer ' + localStorage?.getItem?.('token')
    config.headers["X-Cookie"] = passCookies.join('; ')
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

baseInstance.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          let { data } = await axios.post(`${selfURL}/api/user/refresh-token`)
          store.state.isLoggedIn = true
          localStorage.setItem('token', data.token)
          originalConfig.headers["Authorization"] = 'Bearer ' + data.token
          return baseInstance(originalConfig)
        } catch (_error) {
          store.state.isLoggedIn = false
          localStorage.setItem('token', null)
          return Promise.reject(_error)
        }
      }
    }
    return Promise.reject(err)
  },
)

const getStripeKey = async function () {
  try {
    let { data } = await baseInstance.get(`${baseURL}/stripe-key`)
    return data.key
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot get stripe key'))
  }
}

const register = async function (postData) {
  try {
    let { data } = await axios.post(`${baseURL}/user`, JSON.stringify(postData), {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    return data
  } catch (e) {
    let error = null
    if (e.response.data?.errors) {
      for (let key in e.response.data?.errors) {
        error = error ? error + ';' + e.response.data?.errors[key] : e.response.data?.errors[key]
      }
    } else error = 'Something went wrong, try again later'
    throw Error(error)
  }
}

const signupProblem = async function (postData) {
  try {
    let { data } = await axios.post(`${baseURL}/user/signup-problem`, JSON.stringify(postData), {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    return data
  } catch (e) {
    throw Error('Something went wrong, try again later')
  }
}
const login = async function (postData) {
  try {
    let { data } = await axios.post(`${baseURL}/user/login`, JSON.stringify(postData), {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    store.state.isLoggedIn = true
    localStorage.setItem('token', data.token)
    await createToken()
    const userInfo = await getCurrentUser()
    store.dispatch('user/setUserInfo', userInfo)
    return data
  } catch (e) {
    store.state.isLoggedIn = false
    if (e.response.status === 403) {
      if (e.response.data.message === 'Deleted user') {
        throw Error('deleted')
      }
      throw Error('Complete registration')
    } else if (e.response.status === 401) {
      throw Error('Wrong login or password')
    } else {
      throw Error('Something went wrong, try again later')
    }
  }
}
const logout = async function () {
  try {
    await axios.post(`${selfURL}/api/user/logout`, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    store.state.isLoggedIn = false
    localStorage.setItem('token', null)
  } catch (e) {
    throw Error('Something went wrong, try again later')
  }
}
const createToken = async function () {
  let { data } = await baseInstance.post(`${selfURL}/api/user/create-token`)
  return data
}
const sendResetPassword = async function (postData) {
  try {
    let { data } = await axios.post(`${baseURL}/user/send-reset-password`, JSON.stringify(postData), {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    return data
  } catch (e) {
    if (e.response?.data?.message) {
      throw Error(e.response.data.message)
    } else {
      throw Error('Something went wrong, try again later')
    }
  }
}

const resetPassword = async (postData) => {
  try {
    let { data } = await axios.post(`${baseURL}/user/reset-password`, JSON.stringify(postData), {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    store.state.isLoggedIn = true
    localStorage.setItem('token', data.token)
    await createToken()
    const userInfo = await getCurrentUser()
    store.dispatch('user/setUserInfo', userInfo)
    return data
  } catch (e) {
    if (e.response?.data?.error) {
      throw Error(e.response.data.error)
    } else {
      throw Error('Something went wrong, try again later')
    }
  }
}

const confirmEmail = async (postData) => {
  try {
    let { data } = await axios.post(`${baseURL}/user/confirm-email`, JSON.stringify(postData), {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    store.state.isLoggedIn = true
    localStorage.setItem('token', data.token)
    await createToken()
    const userInfo = await getCurrentUser()
    store.dispatch('user/setUserInfo', userInfo)
    return data
  } catch (e) {
    if (e.response?.data?.error) {
      throw Error(e.response.data.error)
    } else {
      throw Error('Something went wrong, try again later')
    }
  }
}

const getCurrentUser = async function () {
  try {
    let { data } = await baseInstance.get('/user/current')
    if (data.status === 'DELETED') {
      await logout()
      this.$router.push('/')
    } else {
      store.state.user.info = data
      return data
    }
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load user info'))
  }
}

const downloadSizeRun = async function (postData) {
  cancelTokens['/size-run'] = axios.CancelToken.source()
  try {
    let data = await baseInstance.post('/size-run', JSON.stringify(postData), {
      responseType: 'blob',
      headers: {
        'Accept': 'application/zip',
        'Content-Type': 'application/json; charset=UTF-8',
      },
      cancelToken: cancelTokens['/size-run'].token,
    })
    const blob = data.data
    var link = document.createElement('a')
    link.download = 'sizerun.zip'
    link.href = window.URL.createObjectURL(blob)
    link.click()
    return 'ok'
  } catch (e) {
    if (axios.isCancel(e)) {
      return ''
    } else {
      return 'error'
    }
  }
}

const downloadTechPack = async function (postData) {
  cancelTokens['/tech-pack'] = axios.CancelToken.source()
  try {
    let data = await baseInstance.post('/tech-pack', JSON.stringify(postData), {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      cancelToken: cancelTokens['/tech-pack'].token,
    })
    const blob = data.data
    var link = document.createElement('a')
    link.download = 'tech-pack.xlsx'
    link.href = window.URL.createObjectURL(blob)
    link.click()
    return 'ok'
  } catch (e) {
    if (axios.isCancel(e)) {
      return ''
    } else {
      return 'error'
    }
  }
}

const downloadStop = (page) => {
  if (page in cancelTokens) {
    cancelTokens[page].cancel()
    delete cancelTokens[page]
  }
}

const getSubscription = async function () {
  try {
    let { data } = await baseInstance.post('/subscriptions')
    return data
  } catch (e) {
    return 'error'
  }
}

const checkSubscription = async function (id) {
  try {
    let { data } = await baseInstance.get(`/subscriptions/${id}/check`)
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot check subscription'))
  }
}

const checkToken = async function (token) {
  try {
    const info = await request.get(`/user/check-token/${token}`)
    return info
  } catch (e) {
    throw Error(e)
  }
}

const changePlan = async function (planCode) {
  try {
    let { data } = await baseInstance.put(
      '/company/change-plan',
      JSON.stringify({
        plan: planCode,
      }),
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      },
    )
    return data
  } catch (e) {
    return 'error'
  }
}

// const billingConfig = async function () {
//   try {
//     let { data } = await baseInstance.get('/config')
//     console.log('data', data)
//     return data
//   } catch (e) {
//     throw Error(handleHttpError(e, 'Cannot load user info'))
//   }
// }

const handleHttpError = async (e, defaultMessage, action) => {
  if (typeof e.response?.data === 'string') {
    console.error(e.response.data)
  }
  if (e.response?.data?.errorMessage) {
    let message = e.response.data.errorMessage
    const m = message.match(/Incomplete parametrization.+/)
    if (m && m?.length > 0) {
      window.lastError = m[0]
    } else {
      window.lastError = message
    }
    console.error(message, e.response?.data?.stackTrace || '')
  }

  return defaultMessage
}

const transformEasesParam = function (eases = {}) {
  return Object.keys(eases)
    .map(key => `${key}:${eases[key]}`)
    .join(',')
}

const fetchRootCategories = async function () {
  try {
    let { data } = await baseInstance.get('categories', {
      params: {
        parentCode: '',
      },
    })

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load categories'))
  }
}

const fetchStandartSizes = async function (noCache = null) {
  try {
    let { data } = await baseInstance.get('mannequins/get-standart' + (noCache ? '?t=' + +Date.now() : ''))
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load standats sizes'))
  }
}

const fetchCategories = async function (parentCode = null) {
  try {
    let params = parentCode ? { parentCode } : null
    let { data } = await baseInstance.get('categories', {
      params: params,
    })

    return parentCode ? data : data.filter(cat => !!cat.parentCode)
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load categories'))
  }
}

const fetchNodes = async function (categoryCode) {
  try {
    let { data } = await baseInstance.get(
      `categories/${categoryCode}/nodes`)

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load nodes'))
  }
}

const fetchNodeElements = async function (
  nodeCode,
  offset,
  limit = 20,
  getTotal = false,
  compatibleElementsIds = [],
  filters = {},
  search,
  // replaceAttrs = [],
  activeElement = 0,
) {
  try {
    let params = {
      nodeCode,
      offset,
      limit,
      countTotal: getTotal ? 1 : 0,
      search: search || '',
      activeElement,
    }
    if (compatibleElementsIds.length > 0) {
      params['compatibleElemIds'] = compatibleElementsIds.join(',')
    }
    filters = serializeFilters(filters)
    if (filters.length > 0) {
      params['filterAttrIds'] = filters
    }
    // params.replaceAttrs = JSON.stringify(replaceAttrs)
    let { data } = await baseInstance.get('elements', {
      params,
    })
    if (store.state.isProduction) {
      if (nodeCode === 'wm_dr_front_necks' || nodeCode === 'wm_tp_front_necks') {
        data.items = data.items.filter(el => !el.name.toLowerCase().includes('collar peter pan'))
      }
      if (nodeCode === 'wm_sk_pockets') {
        data.items = data.items.filter(el => el.id !== 79107 && el.id !== 80388)
      }
      if (nodeCode === 'wm_pn_silhouettes' || nodeCode === 'mn_pn_silhouettes') {
        data.items = data.items.filter(el => {
          if (!el.name.toLowerCase().includes('elastic cuffs') && !el.name.toLowerCase().includes('elastic cuffs')) {
            return el
          }
        })
      }
    }

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load node elements'))
  }
}

// todo: возможно, потребуется удалить
const fetchElementData = async function (elementId) {
  try {
    let { data } = await baseInstance.get(`elements/${elementId}`)

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load node elements'))
  }
}

const fetchElementImages = async function (elementId, settingCode = '') {
  try {
    let { data } = await baseInstance.get(`elements/${elementId}/images`, {
      params: {
        settingCode,
      },
    })

    return data
  } catch (e) {
    return null
  }
}

const fetchElementParts = async function (elementId) {
  try {
    let { data } = await baseInstance.get(`elements/${elementId}/parts`)

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load element parts'))
  }
}

const fetchInstructionsDebugInfo = async function (elementIds) {
  try {
    let { data } = await baseInstance.get(`sewing-instructions/debug-info?elements=` + elementIds.join(','))

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load element parts'))
  }
}

const fetchCategoryElementsData = async function (
  categoryCode, elemIds = [], settingCodes = []) {
  try {
    let { data } = await baseInstance.get(
      `categories/${categoryCode}/elements`,
      {
        params: {
          elemIds: elemIds.join(','),
          settingCodes: settingCodes.join(','),
        },
      })

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load category elements data'))
  }
}

const fetchDefaultDesign = async function (categoryCode) {
  try {
    let { data } = await baseInstance.get(
      `categories/${categoryCode}/default-design`)

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load default design'))
  }
}

const fetchUpdateDesign = async function (
  categoryCode,
  newElementId,
  defaultElementIds = [],
  userSelectedElementIds = [],
  settingCodes = [],
  // replaceAttrs = [],
) {
  const url = `categories/${categoryCode}/update-design`
  if (url in cancelTokens) {
    cancelTokens[url].cancel()
  }
  cancelTokens[url] = axios.CancelToken.source()
  try {
    let { data } = await baseInstance.get(
      url, {
        params: {
          addElemId: newElementId,
          defElemIds: defaultElementIds.join(','),
          setElemIds: userSelectedElementIds.join(','),
          settingCodes: settingCodes.join(','),
          // replaceAttrs: JSON.stringify(replaceAttrs),
        },
        cancelToken: cancelTokens[url].token,
        // cache: {
        //   ignoreCache: true,
        // },
      })
    delete cancelTokens[url]

    return data
  } catch (e) {
    if (e instanceof axios.Cancel) {
      let error = new Error('Request cancelled')
      error.name = 'cancelledBySameRequest'
      throw error
    }
    throw Error(handleHttpError(e, 'Cannot load default design'))
  }
}

const fetchDesignerTokenData = async function () {
  try {
    let { data } = await baseInstance.get(
      '/auth/token-details/designerConstructor')
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load initial data'))
  }
}

const fetchDesign = async function (designUid) {
  try {
    let { data } = await baseInstance.get(`/designs/${designUid}`)
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load design data'))
  }
}

const saveDesign = async function (
  {
    designName = '',
    designCategory,
    elementIds = [],
    settingCodes = [],
    ease,
    sketch,
    sketchFront,
    sketchBack,
    patternsPreview,
    color = undefined,
    fabricId = undefined,
    mannequinId,
    mannequinName,
    fabricType,
    fit,
    seamAllowance,
    fabricCode,
    designDetails,
    mannequinEase,
    collectionId,
    fitChart,
  }) {
  try {
    let params = {
      designCategory,
      elementIds: elementIds.join(','),
      settingCodes: settingCodes,
      designName: designName,
      ease: JSON.stringify(ease),
      sketch,
      sketchFront,
      sketchBack,
      patternsPreview,
      mannequinId,
      mannequinName: mannequinName || '',
      fabricType: String(fabricType),
      fit: String(fit),
      seamAllowance: String(seamAllowance),
      fabricCode,
      designDetails,
      mannequinEase,
      collectionId: String(collectionId),
      fitChart,
    }
    if (color) {
      params['color'] = color
    }
    if (fabricId) {
      params['fabricId'] = fabricId
    }
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let { data } = await baseInstance.post(
      '/designs',
      JSON.stringify(params),
      {
        headers: {
          'X-App-Link': url?.replaceAll(' ', '%20'),
          'X-User-Id': store.state.user.info?.id || 0,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      },
    )
    return { data }
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot save design'))
  }
}

const cancelDesignCreate = async function () {
  try {
    let { data: { returnUrl, token } } = await baseInstance.post(
      '/designer/save',
      {
        cancelDesignCreate: 1,
      })

    return {
      returnUrl,
      token,
    }
  } catch (e) {
    throw Error(handleHttpError(e, 'An error has occurred.'))
  }
}

const fetchApplicationDetails = async function () {
  try {
    let { data: { name, logo, faviconSvgPath, externalStylesheets, customStyle, seamAllowance, patterns, designCategoryPrefixes, mannequinCategories } } = await baseInstance.get(
      '/application/details')
    return {
      name,
      logo,
      faviconSvgPath,
      externalStylesheets,
      customStyle,
      seamAllowance,
      patterns,
      designCategoryPrefixes,
      mannequinCategories,
    }
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load application information'))
  }
}
const fetchDebug = async function (
  internalLines,
  elementIds = [],
  mannequinId,
  fabricType,
  fit,
  seamAllowance,
  eases = {},
  settingCodes = [],
  fontName,
  force = false,
  debug = false,
) {
  let url = '/patterns/preview'
  if (force) {
    url += '?t=' + +(Date.now()) + '&r=' + Math.random()
  }
  if (url in cancelTokens) {
    cancelTokens[url].cancel()
  }
  cancelTokens[url] = axios.CancelToken.source()
  let params = {
    elemIds: elementIds.join(','),
    mannequinId,
    fabricType,
    fit,
    seamAllowance,
    // todo: также добавить в DesignsController patternsPreview
    eases: transformEasesParam(eases),
    settingCodes: settingCodes.join(','),
    fontName: fontName || '',
    debug_params: debug ? 1 : '',
    view: internalLines,
  }
  const json = JSON.stringify(params)
  const encodedParams = encodeURIComponent(json)
  let copyUrl = `${location}/?params=${JSON.stringify(encodedParams)}`
  try {
    let { data } = await baseInstance.get(
      url, {
        params: params,
        cancelToken: cancelTokens[url].token,
        headers: {
          'X-App-Link': copyUrl?.replaceAll(' ', '%20'),
          'X-User-Id': store.state.user.info?.id || 0,
        },
      },
    )
    delete cancelTokens[url]
    return data
  } catch (e) {
    if (e instanceof axios.Cancel) {
      let error = new Error('Request cancelled')
      error.name = 'cancelledBySameRequest'
      throw error
    }
    throw Error(handleHttpError(e, 'Cannot load patterns'))
  }
}

const downloadPattern = async function (
  // internalLines,
  mannequinId,
  allParams,
  // fabricType,
  // fit,
  // seamAllowance,
  // eases = {},
  // settingCodes = [],
  // algorithms = [],
  // pars = {},
  format,
  cutVerticallySymmetric,
  pageSize,
) {
  // let parsNew = pars
  // let isDelPar2002 = false
  // if (settingCodes.length) {
  //   settingCodes.forEach(el => {
  //     if (el.includes('facing:') && !el.includes('neckline_facing:')) {
  //       isDelPar2002 = true
  //     }
  //     if (settingCodesChange[el]) {
  //       parsNew = Object.assign(parsNew, settingCodesChange[el])
  //     }
  //   })
  // }
  // if (fitChange[fit]) {
  //   parsNew = Object.assign(parsNew, fitChange[fit])
  // }
  // if (fabricTypeChange[fabricType]) {
  //   parsNew = Object.assign(parsNew, fabricTypeChange[fabricType])
  // }
  // if (isDelPar2002) {
  //   delete parsNew[2002]
  // }
  let params = {
    mannequinId,
    ...allParams,
    // seamAllowance,
    // eases: eases,
    // algorithms: algorithms,
    // params: parsNew,
    // view: internalLines,
  }
  if (format === 'plt') {
    params.cutVerticallySymmetric = cutVerticallySymmetric
  }
  if (format === 'pdf') {
    params.cutVerticallySymmetric = cutVerticallySymmetric
    params.pageSize = pageSize
  }
  try {
    let { data } = await baseInstance.post('/cat/download-' + format,
      JSON.stringify(params),
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        responseType: 'blob',
      },
    )
    if (store.getters['user/demoMode']) {
      await getCurrentUser()
    }
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot download patterns'))
  }
}
const fetchPatternsPreview = async function (
  internalLines,
  elementIds = [],
  mannequinId,
  fabricType,
  fit,
  seamAllowance,
  eases = {},
  settingCodes = [],
  fontName,
  force = false,
  debug = false,
  rawErrors = false,
  size = {},
  format = 'svg',
) {
  let url = '/patterns/preview'
  if (force) {
    url += '?t=' + +(Date.now()) + '&r=' + Math.random()
  }
  if (url in cancelTokens) {
    cancelTokens[url].cancel()
  }
  cancelTokens[url] = axios.CancelToken.source()
  let params = {
    elemIds: elementIds.join(','),
    mannequinId,
    fabricType,
    fit,
    seamAllowance,
    // todo: также добавить в DesignsController patternsPreview
    eases: transformEasesParam(eases),
    settingCodes: settingCodes.join(','),
    fontName: fontName || '',
    debug_params: debug ? 1 : '',
    format,
    view: internalLines,
  }
  const json = JSON.stringify(params)
  const encodedParams = encodeURIComponent(json)
  let copyUrl = ''
  if (!size.type) {
    copyUrl = location + window?.pageDesigner?.getCurrentDesignURL()
  } else {
    copyUrl = `${location}/?params=${JSON.stringify(encodedParams)}` + `&type=${size.type}&category=${size.category}&size=${size.size}`
  }
  try {
    let { data } = await baseInstance.get(
      url, {
        params: params,
        cancelToken: cancelTokens[url].token,
        headers: {
          'X-App-Link': copyUrl?.replaceAll(' ', '%20'),
          'X-User-Id': store.state.user.info?.id || 0,
        },
      },
    )

    delete cancelTokens[url]
    return data
  } catch (e) {
    if (rawErrors) {
      throw e
    }
    if (e instanceof axios.Cancel) {
      let error = new Error('Request cancelled')
      error.name = 'cancelledBySameRequest'
      throw error
    }
    throw Error(handleHttpError(e, 'Cannot load patterns'))
  }
}
// const fetchPatternsPreview = async function (
//   internalLines,
//   mannequinId,
//   fabricType,
//   fit,
//   seamAllowance,
//   eases = {},
//   settingCodes = [],
//   algorithms = [],
//   pars = {},
//   force = false,
// ) {
//   let parsNew = pars
//   let isDelPar2002 = false
//   if (settingCodes.length) {
//     settingCodes.forEach(el => {
//       if (el.includes('facing:') && !el.includes('neckline_facing:')) {
//         isDelPar2002 = true
//       }
//       if (settingCodesChange[el]) {
//         parsNew = Object.assign(parsNew, settingCodesChange[el])
//       }
//     })
//   }
//   if (fitChange[fit]) {
//     parsNew = Object.assign(parsNew, fitChange[fit])
//   }
//   if (fabricTypeChange[fabricType]) {
//     parsNew = Object.assign(parsNew, fabricTypeChange[fabricType])
//   }
//   if (isDelPar2002) {
//     delete parsNew[2002]
//   }
//   let url = '/cat/preview'
//   if (force) {
//     url += '?t=' + +(Date.now()) + '&r=' + Math.random()
//   }
//   if (url in cancelTokens) {
//     cancelTokens[url].cancel()
//   }
//   cancelTokens[url] = axios.CancelToken.source()
//   let params = {
//     // elemIds: elementIds.join(','),
//     mannequinId,
//     seamAllowance,
//     eases: eases,
//     algorithms: algorithms,
//     params: parsNew,
//     view: internalLines,
//   }
//   // const json = JSON.stringify(params)
//   // const encodedParams = encodeURIComponent(json)
//   // let copyUrl = ''
//   // if (!size.type) {
//   // copyUrl = location + window?.pageDesigner?.getCurrentDesignURL()
//   // } else {
//   //   copyUrl = `${location}/?params=${JSON.stringify(encodedParams)}` + `&type=${size.type}&category=${size.category}&size=${size.size}`
//   // }
//   try {
//     let { data } = await baseInstance.post(
//       url, JSON.stringify(params),
//       {
//         cancelToken: cancelTokens[url].token,
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//           // 'X-App-Link': copyUrl?.replaceAll(' ', '%20'),
//           // 'X-User-Id': store.state.user.info?.id || 0,
//         },
//       },
//     )

//     delete cancelTokens[url]
//     return data
//   } catch (e) {
//     if (e instanceof axios.Cancel) {
//       let error = new Error('Request cancelled')
//       error.name = 'cancelledBySameRequest'
//       throw error
//     }
//     throw Error(handleHttpError(e, 'Cannot load patterns'))
//   }
// }

const fetchSizeParams = async function ({ standartSize, mannequinType }) {
  if (!mannequinType) {
    throw Error('Mannequin type is not defined')
  }
  try {
    if (standartSize === undefined) {
      // получение всех мерок
      let { data } = await baseInstance.get(`mannequins/size-params`, {
        params: {
          mannequinType,
        },
      })

      return data
    } else {
      let { data } = await baseInstance.get(`mannequins/size-params`, {
        params: {
          standartSize,
          mannequinType,
        },
      })

      return data
    }
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load size parameters'))
  }
}

const fetchMannequin = async function (mannequinId) {
  let url = location + window?.pageDesigner?.getCurrentDesignURL()
  try {
    let { data: { sizeParams, mannequinType } } = await baseInstance.get(
      `mannequins/get/${mannequinId}`, {
        cache: {
          maxAge: 0,
        },
        headers: {
          'X-App-Link': url?.replaceAll(' ', '%20'),
          'X-User-Id': store.state.user.info?.id || 0,
        },
      })

    return { sizeParams, mannequinType }
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot get mannequin measurements'))
  }
}

const createMannequin = async function (sizeParams, mannequinType) {
  try {
    let { data } = await baseInstance.post(`mannequins/create`,
      {
        sizeParams,
        mannequinType,
      },
      {
        transformRequest: axios.defaults.transformRequest,
      })

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot create mannequin'))
  }
}
const updateMannequin = async function (
  mannequinId, sizeParams, mannequinType) {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let { data } = await baseInstance.post(`mannequins/update`,
      {
        mannequinId,
        sizeParams,
        mannequinType,
      },
      {
        transformRequest: axios.defaults.transformRequest,
        headers: {
          'X-App-Link': url?.replaceAll(' ', '%20'),
          'X-User-Id': store.state.user.info?.id || 0,
        },
      })

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot update mannequin'))
  }
}

const saveMeasurements = async ({ sizeParams, mannequinName, mannequinType, easeOptions, fit, fabricType, designId, currentMode, mannequinId }) => {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let params = {}
    if (currentMode === 'measurements') {
      params.sizeParams = JSON.stringify(sizeParams)
      params.mannequinName = mannequinName || ''
      params.mannequinType = mannequinType
    } else {
      params.easeOptions = JSON.stringify(easeOptions)
      params.fit = fit
      params.fabricType = fabricType
      params.designId = designId
      params.mannequinId = mannequinId
      params.mannequinName = mannequinName || ''
    }
    let { data } = await baseInstance.post(`/measurements/save`, params, {
      headers: {
        'X-App-Link': url?.replaceAll(' ', '%20'),
        'X-User-Id': store.state.user.info?.id || 0,
      },
    })

    return data
  } catch (e) {
    if (currentMode === 'measurements') {
      throw Error(handleHttpError(e, 'Cannot save measurements'))
    } else {
      throw Error(handleHttpError(e, 'Cannot save eases'))
    }
  }
}

const getSavedMannequins = async function () {
  try {
    let { data } = await baseInstance.get(`/measurements/saved-mannequins`)

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot get saved mannequins'))
  }
}

const cancelMeasurementsSave = async function () {
  try {
    let { data: { returnUrl, token } } = await baseInstance.post(
      '/measurements/save',
      {
        cancelSave: 1,
      })

    return {
      returnUrl,
      token,
    }
  } catch (e) {
    throw Error(handleHttpError(e, 'An error has occurred.'))
  }
}

const fetchSpecs = async function (
  {
    elementIds = [],
    mannequinId,
    fabricType,
    fit,
    seamAllowance,
    eases = {},
    settingCodes = [],
  }) {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let { data } = await baseInstance.get('/specs', {
      params: {
        elemIds: elementIds.join(','),
        mannequinId,
        fabricType,
        fit,
        seamAllowance,
        eases: transformEasesParam(eases),
        settingCodes: settingCodes.join(','),
      },
      headers: {
        'X-App-Link': url?.replaceAll(' ', '%20'),
        'X-User-Id': store.state.user.info?.id || 0,
      },
    },
    )

    return data
  } catch (e) {
    console.log('Cannot load specs.')
    return { specs: [] }
    // throw Error(handleHttpError(e, 'Cannot load specs.'))
  }
}

const fetchEases = async function (
  {
    elementIds = [],
    mannequinId,
    fabricType,
    fit,
    seamAllowance,
    eases = {},
    settingCodes = [],
  }) {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let params = {
      elemIds: elementIds.join(','),
      mannequinId,
      fabricType,
      fit,
      seamAllowance,
      eases: transformEasesParam(eases),
      settingCodes: settingCodes.join(','),
    }
    let { data } = await baseInstance.get('/eases', {
      params,
      headers: {
        'X-App-Link': url?.replaceAll(' ', '%20'),
        'X-User-Id': store.state.user.info?.id || 0,
      },
    },
    )

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load eases.'))
  }
}

const fetchSewingInstructions = async function (
  {
    elementIds,
    mannequinId,
    categoryCode,
    eases = {},
    settingCodes = [],
    seamAllowance = '',
    fabricType,
    fabricCode = '',
    fit = '',
    fontName = '',
  }) {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let { data } = await baseInstance.get('/sewing-instructions', {
      params: {
        elementIds: elementIds.join(','),
        mannequinId,
        categoryCode,
        format: 'json',
        eases: transformEasesParam(eases),
        settingCodes: settingCodes.join(','),
        seamAllowance,
        fabricType,
        fabricCode,
        fit,
        fontName: fontName || '',
      },
      headers: {
        'X-App-Link': url?.replaceAll(' ', '%20'),
        'X-User-Id': store.state.user.info?.id || 0,
      },
    })
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load instructions.'))
  }
}

const getSewingInstructionsPDF = async function (
  {
    elementIds,
    mannequinId,
    categoryCode,
    orientation,
    eases = {},
    settingCodes = [],
    seamAllowance,
    fit,
    fabricCode,
    fabricType,
    postData,
  }) {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    const response = await baseInstance.post('/sewing-instructions',
      postData, {
        responseType: 'blob',
        timeout: 150000,
        params: {
          elementIds: elementIds.join(','),
          mannequinId,
          categoryCode,
          format: 'pdf',
          orientation,
          eases: transformEasesParam(eases),
          settingCodes: settingCodes.join(','),
          seamAllowance,
          fabricCode,
          fabricType,
          fit,
        },
        headers: {
          'X-App-Link': url?.replaceAll(' ', '%20'),
          'X-User-Id': store.state.user.info?.id || 0,
        },
      })
    const blob = response.data
    const filename = `sewing_instructions_${orientation}.pdf`
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename)
    } else {
      const URL = window.URL || window.webkitURL
      let downloadUrl = URL.createObjectURL(blob)
      if (filename) {
        const a = document.createElement('a')
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      } else {
        window.location.href = downloadUrl
      }
      window.setTimeout(function () {
        URL.revokeObjectURL(downloadUrl)
      }, 100)
    }
  } catch (e) {
    // todo
    console.error(e)
    throw Error(handleHttpError(e, 'Cannot load instructions.'))
  }
}

const fetchSewingInstructionsDesign = async function (designId, mannequinId) {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let { data } = await baseInstance.get(
      '/sewing-instructions/' + designId, {
        params: {
          mannequinId,
          format: 'json',
        },
        headers: {
          'X-App-Link': url?.replaceAll(' ', '%20'),
          'X-User-Id': store.state.user.info?.id || 0,
        },
      })
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load instructions.'))
  }
}

const fetchFabricLayersForDesign = async function (
  {
    elementIds = [],
    mannequinId,
    fabricType,
    fit,
    seamAllowance,
    eases = {},
    settingCodes = [],
  }) {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let { data } = await baseInstance.get('/patterns/fabric-types', {
      params: {
        elemIds: elementIds.join(','),
        mannequinId,
        fabricType,
        fit,
        seamAllowance,
        eases: transformEasesParam(eases),
        settingCodes: settingCodes.join(','),
      },
      headers: {
        'X-App-Link': url?.replaceAll(' ', '%20'),
        'X-User-Id': store.state.user.info?.id || 0,
      },
    })

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load fabric types'))
  }
}

const fetchFabricCalculatorResults = async function (
  {
    elementIds = [],
    mannequinId,
    fabricWidth,
    fabricType,
    fit,
    seamAllowance,
    eases = {},
    settingCodes = [],
    fontName = '',
  }) {
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let { data } = await baseInstance.get('/patterns/layout', {
      params: {
        elemIds: elementIds.join(','),
        mannequinId,
        fabricWidth,
        fabricType,
        fit,
        seamAllowance,
        eases: transformEasesParam(eases),
        settingCodes: settingCodes.join(','),
        fontName: fontName || '',
      },
      headers: {
        'X-App-Link': url?.replaceAll(' ', '%20'),
        'X-User-Id': store.state.user.info?.id || 0,
      },
    })
    return data
  } catch (e) {
    if (e.response.data.errorMessage.includes('too small')) {
      throw Error(handleHttpError(e, 'tape width is small'))
    } else throw Error(handleHttpError(e, 'Cannot load instructions.'))
  }
}

const fetchAdvancedFabricCalculatorResults = async function (
  {
    elementIds = [],
    mannequinId,
    fabricWidths,
    fabricType,
    fit,
    seamAllowance,
    eases = {},
    settingCodes = [],
    layers,
    fontName = '',
  }) {
  if (!Array.isArray(layers) || layers.length === 0) {
    throw Error('Layers must be an array')
  }
  layers.forEach(layer => {
    if (!layer.fabricTypeUid) {
      throw Error('Each layer must include "fabricTypeUid" field')
    }
    if (!layer.fabricWidth) {
      throw Error('Each layer must include "fabricWidth" field')
    }
  })
  try {
    let url = location + window?.pageDesigner?.getCurrentDesignURL()
    let { data } = await baseInstance.get('/patterns/advanced-layout', {
      params: {
        elemIds: elementIds.join(','),
        mannequinId,
        fabricWidths,
        fabricType,
        fit,
        seamAllowance,
        eases: transformEasesParam(eases),
        settingCodes: settingCodes.join(','),
        layers,
        fontName: fontName || '',
      },
      paramsSerializer: function (params) {
        return qs.stringify(params)
      },
      headers: {
        'X-App-Link': url?.replaceAll(' ', '%20'),
        'X-User-Id': store.state.user.info?.id || 0,
      },
    })

    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load instructions.'))
  }
}

const fetchFabrics = async function () {
  try {
    let { data } = await baseInstance.get('/fabrics')
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load fabrics.'))
  }
}

const fetchYardageLayers = async function (param) {
  try {
    let { data } = await baseInstance.post('/yardage/layers', JSON.stringify(param))
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load custom sizes'))
  }
}

const fetchYardage = async function (param) {
  try {
    let { data } = await baseInstance.post('/yardage', JSON.stringify(param))
    return data
  } catch (e) {
    return 'error'
  }
}

const fetchSizeChart = async function (type, page) {
  try {
    let { data } = await baseInstance.get('/size-charts', {
      params: {
        is_custom: 1,
        mannequin_type: type,
        page: page,
        per_page: 20,
      },
    })
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load custom sizes'))
  }
}

const fetchSizeChartParams = async function (id) {
  try {
    let { data } = await baseInstance.get('/mannequins/get/' + id)
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'Cannot load size params'))
  }
}

const saveNewChart = async function (name, type, uids) {
  try {
    let postData = {
      name: name,
      mannequin_type: type,
      mannequin_uids: uids,
    }
    let { data } = await baseInstance.post(
      '/size-charts',
      JSON.stringify(postData),
    )
    return data
  } catch (e) {
    throw Error(handleHttpError(e, 'An error has occurred.'))
  }
}

const getSizeParamImageUrl = image => `/mannequins/size-param-images/${image}`
const getSpecsImageUrl = specCode => `/specs/${specCode}/image`

export const request = baseInstance

export {
  getStripeKey,
  // billingConfig,
  saveNewChart,
  fetchSizeChart,
  fetchSizeChartParams,
  checkSubscription,
  checkToken,
  changePlan,
  getSubscription,
  getCurrentUser,
  downloadSizeRun,
  downloadStop,
  downloadTechPack,
  confirmEmail,
  resetPassword,
  sendResetPassword,
  register,
  signupProblem,
  login,
  logout,
  createToken,
  fetchApplicationDetails,
  fetchRootCategories,
  fetchStandartSizes,
  fetchCategories,
  fetchNodes,
  fetchNodeElements,
  fetchElementData,
  fetchElementImages,
  fetchCategoryElementsData,
  fetchDefaultDesign,
  fetchUpdateDesign,
  fetchDesignerTokenData,
  fetchDesign,
  saveDesign,
  cancelDesignCreate,
  downloadPattern,
  fetchPatternsPreview,
  fetchDebug,
  fetchSizeParams,
  fetchMannequin,
  createMannequin,
  updateMannequin,
  saveMeasurements,
  cancelMeasurementsSave,
  fetchSpecs,
  fetchEases,
  fetchSewingInstructions,
  fetchSewingInstructionsDesign,
  getSewingInstructionsPDF,
  getSizeParamImageUrl,
  getSpecsImageUrl,
  fetchFabricLayersForDesign,
  fetchAdvancedFabricCalculatorResults,
  fetchFabricCalculatorResults,
  fetchFabrics,
  getSavedMannequins,
  fetchElementParts,
  fetchInstructionsDebugInfo,
  fetchYardageLayers,
  fetchYardage,
}
