<template>
  <sidebar-base-panel
    @close="$emit('close')"
  >
    <template v-slot:searchInput>
      <q-input
        dense
        v-model="filterByName"
        placeholder="Search..."
      ></q-input>
    </template>

    <template v-slot:default>
      <slot name="default"></slot>
      <slot
        v-for="item in elementsFiltered"
        name="item"
        v-bind:item="item"
      ></slot>
    </template>
  </sidebar-base-panel>
</template>

<script>
import SidebarBasePanel from '@/components/Sidebar/SidebarBasePanel'

export default {
  name: 'SidebarOptionsPanel',

  components: {
    SidebarBasePanel,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    filterByName: '',
  }),

  computed: {
    elementsFiltered () {
      if (!this.filterByName) {
        return this.items
      }
      const nameNormalized = this.filterByName.toLowerCase()
      return this.items.filter(el => el.name.toLowerCase().includes(nameNormalized))
    },
  },

}
</script>
